import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get Distributor Data
 * Get branches under salesrep
 * Get Distributor users
 * Search for distributor users
 * Get branch data
 * Get branch admin data
 * Search for distributors
 * Search for branches
 * Delete TLD
 * Delete Branch
 * Export list of distributors
 * Export list of branches
 * Add new distributor user
 * Update existing distributor user
 * Delete distributor user
 * Send Mail
 * Get agency related information
 */
const DistributorsHandler = (function () {
  return {
    getDistributorData: function (username) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/DistributorData?username=${username}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBranchesUnderSalesrep: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/BranchesUnderSalesrep?username=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getDistributorUsers: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/DistributorUser?branchId=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    searchDistributorUsers: function (branchId, search) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/SearchDistributorUser?branchId=${branchId}&search=${search}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBranchData: function (data, username) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/BranchData?tld=${data}&username=${username}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBranchAdminData: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/BranchAdminData?username=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    searchDistributors: function (data, username) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/SearchDistributors?search=${data}&username=${username}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    searchBranches: function (search, tld, username) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/SearchBranches?search=${search}&tld=${tld}&username=${username}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    deleteTld: function (tld) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/DeleteTld?tld=${tld}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    deleteBranch: function (branchId) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/DeleteBranch?branchId=${branchId}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    exportDistributors: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Export/Distributor`;
        Api.post_doc(route, data)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    exportBranch: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Export/Branch`;
        Api.post_doc(route, data)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addDistributorUser: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/AddDistributorUser`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateDistributorUser: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/UpdateDistributorUser`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteDistributorUser: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/DeleteDistributorUser`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendMail: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Distributors/SendMail`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    agencyRelatedInfo: function () {
      return new Promise((resolve, reject) => {
        const route = `Distributors/AgencyRelatedInfo`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default DistributorsHandler;
