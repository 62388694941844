import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import ListGroup from "react-bootstrap/lib/ListGroup";
import ListGroupItem from "react-bootstrap/lib/ListGroupItem";
import Row from "react-bootstrap/lib/Row";
import Popover from "react-bootstrap/lib/Popover";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Pagination from "react-js-pagination";
import mixpanel from "mixpanel-browser";

import ProductListPopupHandler from "./ProductListPopupHandler.js";
import "./productListPopup.css";
import {
  DisplayMessages,
  ErrorMessages,
} from "../Utils/FetchConfigurableData.js";

/**
 * Component represent products list popup
 */
class ProductListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      activePage: 1,
      totalItemsCount: 0,
      offset: 8,
      isLoaded: false,
    };
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.searchString !== nextProps.searchString) {
      this.setState({
        productList: [],
        totalItemsCount: 0,
        isLoaded: false,
      });
      this.updateProductList(nextProps);
    }
  }

  updateProductList(props) {
    if (props.searchString) {
      let filter = {
        searchString: props.searchString,
        page: this.state.activePage,
        offset: this.state.offset,
      };
      ProductListPopupHandler.fetchProductsByProductName(filter)
        .then((response) => {
          this.setState({
            productList: response.data.products,
            totalItemsCount: response.data.total,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({ isLoaded: true });
          this.props.addNotification(
            ErrorMessages.Content_ProductByName,
            "error"
          );
        });
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleOnClose}>
        <Modal.Header>
          <h3
            style={{
              fontWeight: "bolder",
              paddingLeft: "15px",
            }}
            className="popup-blue-text"
          >
            Search Results
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <ListGroup>{this.generateProductList()}</ListGroup>
          </Row>
          <Row>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.offset}
              totalItemsCount={this.state.totalItemsCount}
              onChange={this.handlePageChange}
            />
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  handleOnClose() {
    this.setState({ activePage: 1 }, () => this.props.handleOnClose());
  }

  handlePageChange(pageNumber) {
    this.trackPagination(pageNumber);
    this.setState({ activePage: pageNumber }, () =>
      this.updateProductList(this.props)
    );
  }

  handleProductSelect(product) {
    this.trackProductClick(product.title);
    this.setState({ activePage: 1 }, () =>
      this.props.handleProductSelect(product.title, product)
    );
  }

  generateProductList() {
    if (
      this.state.productList === undefined ||
      this.state.productList.length <= 0
    ) {
      if (this.state.isLoaded) {
        return <h4>{DisplayMessages.ProductList_NoMatching}</h4>;
      } else {
        return <h4>{DisplayMessages.ProductList_ProductNames}</h4>;
      }
    } else {
      return this.state.productList.map((product) => {
        const popoverRight = (
          <Popover id="popover-positioned-right" title="Product Description">
            <strong>{product.title}</strong>
            <div
              dangerouslySetInnerHTML={{ __html: product.short_description }}
            />
          </Popover>
        );
        return (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={popoverRight}
            key={product.title}
          >
            <ListGroupItem
              className="product-list-item"
              onClick={() => this.handleProductSelect(product)}
            >
              {product.title}
            </ListGroupItem>
          </OverlayTrigger>
        );
      });
    }
  }

  // Mixpanel Tracking //

  trackProductClick(title) {
    mixpanel.track("Product Selection", {
      Action: "Button Click",
      Effect: `Product selected from filtered list`,
      "Product Name": title,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Product List Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}

export default ProductListPopup;
