import Api from "../Utils/api";
/**
 * API Calls to handle:
 * Check golden pricing availability
 * Get list of special pricing products
 * Get current cart customer
 * Get customer list with special priced products
 * Get product information
 * Get golden price of specific product
 * Get golden products list
 */
const yourPricingHandler = (function () {
  return {
    checkPricingAvailability: function () {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/GoldenPricingAvailability`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getSpecialPricingList: function (customer) {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/PricingList?customer=${customer}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getCurrentCartCustomer: function () {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/CurrentCartCustomer`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getCustomerList: function () {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/CustomerList`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    productInformation: function (input, customer) {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/ProductInformation?input=${input}&customer=${customer}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getProductGoldenPrice: function (input, customer) {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/GoldenProductPrice?input=${input}&customer=${customer}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getGoldenProducts: function (input, customer) {
      return new Promise((resolve, reject) => {
        const route = `YourPricing/ProductList?productId=${input}&customer=${customer}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default yourPricingHandler;
