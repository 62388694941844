import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import FilterOptions from "../FilterOptions/FilterOptions.js";
import CreateFilterButton from "../Utils/createFilterButton.js";
import FilterWizard2Components from "./FilterWizard2Components";
import "./filterWizard2.css";
import {
  DisplayMessages,
  ErrorMessages,
} from "../Utils/FetchConfigurableData.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 125,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

/**
 * Component represents the wattage/lumens specification popup in compare wizard
 */
class FilterWizard2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightSources: [],
      lampOptions: [],
      watts: this.props.selectedFilters.watts,
      lumen: this.props.selectedFilters.lumen,
      minWattage: this.props.selectedFilters.minWattage,
      selectedApplications: this.props.selectedFilters.selectedApplications,
      selectedLightSource: this.props.selectedFilters.selectedLightSource,
      selectedOption: "option1",
    };

    this.handleWattsChange = this.handleWattsChange.bind(this);
    this.handleLampChange = this.handleLampChange.bind(this);
    this.handleLumenChange = this.handleLumenChange.bind(this);
    this.handleWattageChange = this.handleWattageChange.bind(this);
    this.handleOnSubmitFilters = this.handleOnSubmitFilters.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnPrevious = this.handleOnPrevious.bind(this);
    this.onLightSourceFilterSelected =
      this.onLightSourceFilterSelected.bind(this);
    this.isLightSourceSelected = this.isLightSourceSelected.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.selectMH150Fixture = this.selectMH150Fixture.bind(this);
    this.deselectLightSource = this.deselectLightSource.bind(this);
    this.deselectLumenInput = this.deselectLumenInput.bind(this);
    this.deselectMinWattageInput = this.deselectMinWattageInput.bind(this);
    this.OptionChanged = this.OptionChanged.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.setState(
        {
          watts: nextProps.selectedFilters.watts,
          lumen: nextProps.selectedFilters.lumen,
          minWattage: nextProps.selectedFilters.minWattage,
          selectedLightSource: nextProps.selectedFilters.selectedLightSource,
          lampOptions: [],
        },
        this.setSelectedOption
      );
      FilterWizard2Components.getLightSources(
        nextProps.selectedFilters.selectedApplications
      )
        .then((lightSource) => {
          this.setState({
            lightSources: lightSource,
          });
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.FilterWizard_GetLightSources,
            "error"
          );
        });
      if (
        nextProps.selectedFilters.selectedApplications.find(
          (item) => item.group_number === "1"
        )
      ) {
        FilterWizard2Components.getLampOptions()
          .then((lampOptions) => {
            this.setState({
              lampOptions: lampOptions,
            });
          })
          .catch((error) => {
            this.props.addNotification(
              ErrorMessages.FilterWizard_GetLampOptions,
              "error"
            );
          });
      }
    }
  }

  setSelectedOption() {
    let option = "option1";
    if (this.state.lumen !== "" && this.state.lumen !== undefined) {
      option = "option2";
    }
    if (this.state.minWattage !== "" && this.state.minWattage !== undefined) {
      option = "option3";
    }
    this.setState({
      selectedOption: option,
    });
  }

  render() {
    return <div className="filter-wizard">{this.renderFilterWizard_2()}</div>;
  }

  renderFilterWizard_2() {
    return (
      <Modal show={this.props.show} onHide={this.handleOnClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <span style={{ color: "gray" }}>
              {DisplayMessages.FilterWizard_ModalTitle}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onKeyDown={this.handleKeyPress}>
            <Row>
              <Row>
                <Col md={1} sm={1} xs={1} />
                <Col md={10} sm={10} xs={10}>
                  <h3>
                    Answer any <b>one</b> of the following:
                  </h3>
                </Col>
              </Row>

              <Row className={this.showLightSources()}>
                <hr className="line-separator" />
                <br />
                <Row>
                  <Col md={1} sm={1} xs={1}>
                    <div style={{ textAlign: "center" }}>
                      <h4>
                        <input
                          type="radio"
                          value="option1"
                          name="filter"
                          aria-label="option1"
                          checked={this.state.selectedOption === "option1"}
                          onChange={this.OptionChanged}
                        />
                      </h4>
                    </div>
                  </Col>
                  <Col md={10} sm={10} xs={10}>
                    <div>
                      <h4>{DisplayMessages.FilterWizard_FixtureQuestion}</h4>
                    </div>
                  </Col>
                  <Col md={1} sm={1} xs={1}/>
                </Row>
                <Row style={{display: "inline"}}>
                  <Col md={1} sm={1} xs={1}/>
                  <Col md={10} sm={10} xs={10}>
                    <div>
                      <h5>Light Source:</h5>
                      <FilterOptions>
                        {this.generateLightSourceFilters()}
                      </FilterOptions>
                      {this.showReplacementWattageInputOption()}
                    </div>
                  </Col>
                  <Col md={1} sm={1} xs={1}/>
                </Row>
              </Row>
              <hr className="line-separator" />
              <Row>
                <Col md={1} sm={1} xs={1}>
                  <div style={{ textAlign: "center" }}>
                    <h4>
                      <input
                        type="radio"
                        value="option2"
                        name="filter"
                        aria-label="option2"
                        checked={this.state.selectedOption === "option2"}
                        onChange={this.OptionChanged}
                      />
                    </h4>
                  </div>
                </Col>
                <Col md={10} sm={10} xs={10}>
                  <div>
                    <h4>{DisplayMessages.FilterWizard_DesiredMinimumLumen}</h4>
                    <FormGroup
                      controlId="lumen"
                      validationState={this.getValidateState_Lumen()}
                    >
                      <FormControl
                        type="text"
                        value={this.state.lumen}
                        placeholder="Enter minimum lumen"
                        autoComplete="off"
                        disabled={this.state.selectedOption !== "option2"}
                        onChange={this.handleLumenChange}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md={1} sm={1} xs={1}/>
              </Row>
              <hr className="line-separator" />
              <Row>
                <Col md={1} sm={1} xs={1}>
                  <div style={{ textAlign: "center" }}>
                    <h4>
                      <input
                        type="radio"
                        value="option3"
                        name="filter"
                        aria-label="option3"
                        checked={this.state.selectedOption === "option3"}
                        onChange={this.OptionChanged}
                      />
                    </h4>
                  </div>
                </Col>
                <Col md={10} sm={10} xs={10}>
                  <div>
                    <h4>{DisplayMessages.FilterWizard_MinimumWattage}</h4>
                    <FormGroup
                      controlId="minWattage"
                      validationState={this.getValidateState_MinWattage()}
                    >
                      <FormControl
                        type="text"
                        value={this.state.minWattage}
                        placeholder="Enter minimum wattage"
                        autoComplete="off"
                        disabled={this.state.selectedOption !== "option3"}
                        onChange={this.handleWattageChange}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md={1} sm={1} xs={1}/>
              </Row>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Row className="footer-buttons">
            <Col
              md={5}
              sm={6}
              xs={6}
              style={{ textAlign: "left", padding: "0px" }}
            >
              <Button
                bsStyle="primary"
                className="button-blue"
                id="back-to-application-button"
                onClick={this.handleOnPrevious}
              >
                <span className="glyphicon glyphicon-circle-arrow-left" />
                <span> </span>
                <span>{DisplayMessages.FilterWizard_Previous}</span>
              </Button>
            </Col>
            <Col md={2} sm={12} xs={12}/>
            <Col
              md={5}
              sm={6}
              xs={6}
              style={{ textAlign: "right", padding: "0px" }}
            >
              <Button
                bsStyle="primary"
                className="button-blue"
                id="show-matching-products-button"
                onClick={this.handleOnSubmitFilters}
              >
                {DisplayMessages.FilterWizard_Next}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  showLightSources() {
    if (
      this.props.selectedFilters.selectedApplications.find(
        (item) => item.group_number === "0"
      )
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  showReplacementWattageInputOption() {
    if (
      this.props.selectedFilters.selectedApplications.find(
        (item) => item.group_number === "1"
      )
    ) {
      return (
        <div>
          <h5>Lamp:</h5>
          <Select
            maxMenuHeight={190}
            menuPlacement="auto"
            styles={customStyles}
            value={{
              value: this.state.watts,
              label: this.state.watts,
            }}
            isDisabled={
              !this.state.selectedLightSource.light_source_name ||
              this.state.selectedOption !== "option1"
            }
            onChange={this.handleLampChange}
            options={this.generateLampSelectionOptions()}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "lightgray",
                primary: "lightgray",
              },
            })}
          />
        </div>
      );
    } else {
      return (
        <FormGroup
          controlId="watts"
          validationState={this.getValidateState_Watts()}
        >
          <h5>Watts:</h5>
          <FormControl
            type="text"
            value={this.state.watts}
            autoComplete="off"
            placeholder="Enter watts"
            disabled={
              !this.state.selectedLightSource.light_source_name ||
              this.state.selectedOption !== "option1"
            }
            onChange={this.handleWattsChange}
          />
        </FormGroup>
      );
    }
  }

  OptionChanged(e) {
    this.setState({ selectedOption: e.target.value });
    if (e.target.value !== "option1") {
      this.deselectLightSource();
    }
    if (e.target.value !== "option2") {
      this.deselectLumenInput();
    }
    if (e.target.value !== "option3") {
      this.deselectMinWattageInput();
    }
  }

  getValidateState_Watts() {
    if (
      this.isValidValue(this.state.watts) ||
      this.state.watts === "" ||
      this.state.watts == null
    ) {
      return "success";
    } else {
      return "error";
    }
  }

  getValidateState_Lumen() {
    if (
      this.isValidValue(this.state.lumen) ||
      this.state.lumen === "" ||
      this.state.lumen == null
    ) {
      return "success";
    } else {
      return "error";
    }
  }

  getValidateState_MinWattage() {
    if (
      this.isValidValue(this.state.minWattage) ||
      this.state.minWattage === "" ||
      this.state.minWattage == null
    ) {
      return "success";
    } else {
      return "error";
    }
  }

  isValidValue(item) {
    return !isNaN(parseFloat(item)) && isFinite(item) && parseFloat(item) > 0;
  }

  handleWattsChange(e) {
    this.setState({ watts: e.target.value });
  }

  handleLampChange(e) {
    this.setState({ watts: e.value });
  }

  handleLumenChange(e) {
    this.setState({ lumen: e.target.value });
  }

  handleWattageChange(e) {
    this.setState({ minWattage: e.target.value });
  }

  generateLightSourceFilters() {
    return this.state.lightSources.map((lightSource) => {
      return CreateFilterButton(
        lightSource,
        this.onLightSourceFilterSelected,
        this.isLightSourceSelected,
        this.state.selectedOption,
        "Light Source"
      );
    });
  }

  generateLampSelectionOptions() {
    return this.state.lampOptions.map((element, i) => ({
      value: element,
      label: element,
    }));
  }

  isLightSourceSelected(lightSource) {
    return this.isSelected(lightSource);
  }

  onLightSourceFilterSelected(lightingSource) {
    if (this.state.selectedLightSource === lightingSource) {
      this.setState({
        selectedLightSource: {},
        watts: "",
      });
    } else {
      this.setState((prevState) => ({
        selectedLightSource: lightingSource,
        watts:
          prevState.watts === "" || prevState.watts == null
            ? prevState.lampOptions[0]
            : prevState.watts,
      }));
    }
  }

  isSelected(filterItem) {
    if (filterItem.id === this.state.selectedLightSource.id) {
      return "selected";
    } else {
      return "not-selected";
    }
  }

  handleOnSubmitFilters() {
    if (this.isFiltersValid()) {
      let filter = {
        selectedLightSource: this.state.selectedLightSource,
      };
      if (
        this.isValidValue(this.state.watts) &&
        this.state.selectedOption === "option1"
      ) {
        filter.watts = this.state.watts;
      }
      if (
        this.isValidValue(this.state.lumen) &&
        this.state.selectedOption === "option2"
      ) {
        filter.lumen = this.state.lumen;
      }
      if (
        this.isValidValue(this.state.minWattage) &&
        this.state.selectedOption === "option3"
      ) {
        filter.minWattage = this.state.minWattage;
      }

      this.props.handleOnSubmitFilters(filter);
    }
    this.trackShowMatchingProductsButtonClick();
  }

  isFiltersValid() {
    if (
      this.state.selectedLightSource.light_source_name &&
      this.state.selectedOption === "option1"
    ) {
      if (this.state.watts === "" || this.state.watts === undefined) {
        this.props.addNotification(
          ErrorMessages.FilterWizard_NoValidWatts,
          "warning"
        );
      }
      return (
        !this.allFieldsEmpty() &&
        this.allFieldsValid() &&
        this.state.watts !== "" &&
        this.state.watts !== undefined
      );
    } else {
      if (this.allFieldsEmpty()) {
        this.props.addNotification(
          ErrorMessages.FilterWizard_OnShowMatchingProducts,
          "warning"
        );
      }
      return this.allFieldsValid() && !this.allFieldsEmpty();
    }
  }

  allFieldsEmpty() {
    return (
      (this.state.watts === "" || this.state.watts === undefined) &&
      (this.state.lumen === "" || this.state.lumen === undefined) &&
      (this.state.minWattage === "" || this.state.minWattage === undefined)
    );
  }

  allFieldsValid() {
    if (this.getValidateState_Watts() !== "success") {
      this.props.addNotification(
        ErrorMessages.FilterWizard_InvalidWatts,
        "warning"
      );
    }
    if (this.getValidateState_Lumen() !== "success") {
      this.props.addNotification(
        ErrorMessages.FilterWizard_InvalidLumen,
        "warning"
      );
    }
    if (this.getValidateState_MinWattage() !== "success") {
      this.props.addNotification(
        ErrorMessages.FilterWizard_InvalidMinWattage,
        "warning"
      );
    }
    return (
      this.getValidateState_Watts() === "success" &&
      this.getValidateState_Lumen() === "success" &&
      this.getValidateState_MinWattage() === "success"
    );
  }

  handleKeyPress(target) {
    if (target.keyCode === 13) {
      this.handleOnSubmitFilters();
    }
  }

  handleOnPrevious() {
    this.trackBackToApplicationsButtonClick();
    if (this.allFieldsValid()) {
      let filter = {
        watts: this.state.watts,
        lumen: this.state.lumen,
        minWattage: this.state.minWattage,
        selectedLightSource: this.state.selectedLightSource,
      };
      this.props.handleOnPrevious(filter);
    }
  }

  handleOnClose() {
    this.setState({
      watts: this.props.selectedFilters.watts,
      lumen: this.props.selectedFilters.lumen,
      minWattage: this.props.selectedFilters.minWattage,
      selectedLightSource: this.props.selectedFilters.selectedLightSource,
    });
    this.props.handleOnClose();
  }

  /*  Tutorial overlay related code  */

  clearAllFilters() {
    let filter = {
      watts: "",
      lumen: "",
      minWattage: "",
      selectedLightSource: [],
    };
    this.props.handleOnPrevious(filter);
  }

  selectMH150Fixture() {
    let lightSourceMH = this.state.lightSources.find(
      (e) => e.light_source_name === "MH"
    );
    this.onLightSourceFilterSelected(lightSourceMH);
    this.setState({ watts: 100 });
  }

  deselectLightSource() {
    this.setState({
      selectedLightSource: {},
      watts: "",
    });
  }

  deselectLumenInput() {
    this.setState({
      lumen: "",
    });
  }

  deselectMinWattageInput() {
    this.setState({
      minWattage: "",
    });
  }

  /*  Tutorial overlay section end   */

  // Mixpanel Tracking //

  trackBackToApplicationsButtonClick() {
    mixpanel.track("Back To Applications", {
      Action: "Button Click",
      Effect: "Navigate back to application selection",
    });
  }

  trackShowMatchingProductsButtonClick() {
    mixpanel.track("Show Matching Products", {
      Action: "Button Click",
      Effect: "Display products with matching filter criteria",
      "Selected Lighting Source":
        this.state.selectedOption === "option1"
          ? this.state.selectedLightSource.light_source_name
          : "",
      Watts: this.state.watts,
      Lumens: this.state.lumen,
      "Min Wattage": this.state.minWattage,
    });
  }
}
export default FilterWizard2;
