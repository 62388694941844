import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import mixpanel from "mixpanel-browser";

import FilterOptions from "../FilterOptions/FilterOptions.js";
import CreateFilterButton from "../Utils/createFilterButton.js";
import FilterWizard1Components from "./FilterWizard1Components";
import "./filterWizard1.css";
import {
  ErrorMessages,
  DisplayMessages,
} from "../Utils/FetchConfigurableData.js";

/**
 * Component represents the product type filter popup in compare wizard
 */
class FilterWizard1 extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      lightingSpaces: [],
      applications: [],
      selectedLightingSpaces:
        this.props.selectedFilters.selectedLightingSpaces.map((item) => item),
      selectedApplications: this.props.selectedFilters.selectedApplications.map(
        (item) => item
      ),
      selectedOption: "option1",
    };

    this.onLightingSpaceFilterSelected =
      this.onLightingSpaceFilterSelected.bind(this);
    this.onApplicationFilterSelected =
      this.onApplicationFilterSelected.bind(this);
    this.isLightingSpaceSelected = this.isLightingSpaceSelected.bind(this);
    this.isApplicationSelected = this.isApplicationSelected.bind(this);
    this.handleOnNext = this.handleOnNext.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.selectLightingSpaces = this.selectLightingSpaces.bind(this);
    this.selectWallApplicationAndGotoNext =
      this.selectWallApplicationAndGotoNext.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.deselectApplications = this.deselectApplications.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    FilterWizard1Components.getLightingSpaces()
      .then((lightingSpaces) => {
        if (lightingSpaces.success && this._isMounted) {
          this.setState({
            lightingSpaces: lightingSpaces,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.FilterWizard_GetLightingSpaces,
          "error"
        );
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(
        {
          selectedLightingSpaces:
            this.props.selectedFilters.selectedLightingSpaces.map(
              (item) => item
            ),
          selectedApplications:
            this.props.selectedFilters.selectedApplications.map((item) => item),
        },
        () => {
          this.getApplicationsForLightingSpaces(
            this.state.selectedLightingSpaces
          );
        }
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <div className="filter-wizard">{this.renderFilterWizard_1()}</div>;
  }

  renderFilterWizard_1() {
    return (
      <Modal id="wizard1" show={this.props.show} onHide={this.handleOnClose}>
        <Modal.Header>
          <Modal.Title>
            <span style={{ color: "gray" }}>
              {DisplayMessages.FilterWizard_ModalTitle}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="filter-container">
            <Col md={10} sm={10} xs={10}>
              <div>
                <h4>{DisplayMessages.FilterWizard_LightingSpaceQuestion}</h4>
                <FilterOptions>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {this.generateLightingSpaceFilters()}
                    <Col md={1} xs={4} sm={6} />
                    <Col md={5} xs={4} sm={6} style={{ textAlign: "center" }}>
                      <Row>
                        <Button
                          onClick={() => {
                            this.trackCompareWizardTutorialButtonClick();
                            this.props.handleTutorialClick();
                          }}
                          className="button-blue extra-padding"
                        >
                          Tutorial
                        </Button>
                      </Row>
                      <span>(Learn how to use this tool)</span>
                    </Col>
                  </div>
                </FilterOptions>
              </div>
              <div className={this.showApplicationHeader()}>
                <hr className="line-separator" />
                <h4>What is the application?</h4>
                <FilterOptions>
                  {this.generateApplicationFilters()}
                </FilterOptions>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <div className={this.showApplicationHeader()}>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="button-blue extra-padding"
              onClick={this.handleOnNext}
            >
              Next
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
  showApplicationHeader() {
    if (this.state.selectedLightingSpaces.length <= 0) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }
  generateLightingSpaceFilters() {
    return this.state.lightingSpaces.map((lightingSpace) => {
      return CreateFilterButton(
        lightingSpace,
        this.onLightingSpaceFilterSelected,
        this.isLightingSpaceSelected,
        this.state.selectedOption,
        "Lighting Space"
      );
    });
  }
  generateApplicationFilters() {
    return this.state.applications.map((application) => {
      return CreateFilterButton(
        application,
        this.onApplicationFilterSelected,
        this.isApplicationSelected,
        this.state.selectedOption,
        "Application"
      );
    });
  }
  getApplicationsForLightingSpaces(selectedLightingSpaces) {
    if (selectedLightingSpaces.length > 0) {
      FilterWizard1Components.getApplications(selectedLightingSpaces)
        .then((applications) => {
          let temp_selectedApplications =
            this.state.selectedApplications.filter((selectedApplication) => {
              let flag = false;
              applications.forEach((application) => {
                if (
                  application.application_id ===
                  selectedApplication.application_id
                ) {
                  flag = true;
                }
              });
              return flag;
            });
          this.setState({
            selectedApplications: temp_selectedApplications,
            applications: applications,
          });
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.FilterWizard_GetApplications,
            "error"
          );
        });
    } else {
      this.setState({
        selectedApplications: [],
      });
    }
  }
  handleOnNext() {
    if (
      this.state.selectedLightingSpaces.length > 0 &&
      this.state.selectedApplications.length > 0
    ) {
      this.props.handleOnNext({
        selectedLightingSpaces: this.state.selectedLightingSpaces,
        selectedApplications: this.state.selectedApplications,
      });

      this.trackNextButtonClick("Progress to next filter space");
    } else {
      this.props.addNotification(ErrorMessages.FilterWizard_OnNext, "warning");

      this.trackNextButtonClick(ErrorMessages.FilterWizard_OnNext);
    }
  }

  onLightingSpaceFilterSelected(lightingSpace) {
    let temp_selectedLightingSpaces = this.state.selectedLightingSpaces;

    this.trackLightingSpaceSelection(lightingSpace);

    if (
      temp_selectedLightingSpaces.some(
        (item) => item.space_name === lightingSpace.space_name
      )
    ) {
      this.removeAllApplicationsOfLightingSpace(lightingSpace);
      temp_selectedLightingSpaces = temp_selectedLightingSpaces.filter(
        (item) => item.space_name !== lightingSpace.space_name
      );
    } else {
      temp_selectedLightingSpaces.push(lightingSpace);
    }

    this.getApplicationsForLightingSpaces(temp_selectedLightingSpaces);
    this.setState({ selectedLightingSpaces: temp_selectedLightingSpaces });
  }

  onApplicationFilterSelected(application) {
    let temp_selectedApplications = this.state.selectedApplications;
    this.trackApplicationSelection(application);

    if (temp_selectedApplications.find((item) => item.id === application.id)) {
      temp_selectedApplications = temp_selectedApplications.filter(
        (item) => item.id !== application.id
      );
    } else if (
      application.group_number === "0" ||
      application.group_number === "1"
    ) {
      if (
        application.group_number === "0" &&
        !temp_selectedApplications.find((item) => item.group_number === "1")
      ) {
        temp_selectedApplications = [];
        temp_selectedApplications.push(application);
      } else if (
        application.group_number === "1" &&
        !temp_selectedApplications.find((item) => item.group_number === "0")
      ) {
        temp_selectedApplications = temp_selectedApplications.filter(
          (item) => item.group_number === "1"
        );
        temp_selectedApplications.push(application);
      }
    } else if (
      !temp_selectedApplications.find(
        (item) => item.group_number === "0" || item.group_number === "1"
      )
    ) {
      temp_selectedApplications.push(application);
    } else {
      this.props.addNotification(
        ErrorMessages.FilterWizard_InvalidSpace,
        "warning"
      );
    }

    this.setState({
      selectedApplications: temp_selectedApplications,
    });
    this.props.handleOnApplicationSelectionChanged();
  }

  isLightingSpaceSelected(lightingSpace) {
    return this.isSelected(lightingSpace, this.state.selectedLightingSpaces);
  }
  isApplicationSelected(application) {
    return this.isSelected(application, this.state.selectedApplications);
  }
  isSelected(filterItem, stateArray) {
    let temp_selectedApplications = this.state.selectedApplications;
    if (stateArray.find((Item) => filterItem.id === Item.id)) {
      return "selected";
    } else if (
      filterItem.application_name &&
      filterItem.group_number !== "1" &&
      temp_selectedApplications.find((item) => item.group_number === "1")
    ) {
      return "disabled";
    } else if (
      filterItem.application_name &&
      filterItem.group_number !== "0" &&
      temp_selectedApplications.find((item) => item.group_number === "0")
    ) {
      return "disabled";
    } else {
      return "not-selected";
    }
  }
  removeAllApplicationsOfLightingSpace(lightingSpace) {
    this.setState({
      applications: [],
    });
  }

  handleOnClose() {
    this.setState({
      selectedLightingSpaces:
        this.props.selectedFilters.selectedLightingSpaces.map((item) => item),
      selectedApplications: this.props.selectedFilters.selectedApplications.map(
        (item) => item
      ),
    });
    this.props.handleOnClose();
  }

  /*  Tutorial overlay related code  */

  clearAllFilters() {
    this.deselectApplications();
    this.state.lightingSpaces.forEach((lightingSpace) => {
      if (this.isLightingSpaceSelected(lightingSpace) === "selected") {
        this.onLightingSpaceFilterSelected(lightingSpace);
      }
    });
  }
  selectLightingSpaces() {
    this.state.lightingSpaces.forEach((lightingSpace) =>
      this.onLightingSpaceFilterSelected(lightingSpace)
    );
  }
  selectWallApplicationAndGotoNext() {
    let wallApplication = this.state.applications.find(
      (e) => e.application_name === "Wall Lighting"
    );
    this.onApplicationFilterSelected(wallApplication);
    this.handleOnNext();
  }

  deselectApplications() {
    this.state.applications.forEach((application) => {
      if (this.isApplicationSelected(application) === "selected") {
        this.onApplicationFilterSelected(application);
      }
    });
  }

  /*  Tutorial overlay section end   */

  // Mixpanel Tracking //

  trackCompareWizardTutorialButtonClick() {
    mixpanel.track("Compare Wizard Tutorial", {
      Action: "Button Click",
      Effect: "Tutorial overlay is displayed",
    });
  }

  trackLightingSpaceSelection(lightingSpace) {
    mixpanel.track("Lighting Space Selection", {
      Action: "Button Click",
      Effect: "Select/Deselect lighting space",
      "Lighting Space": lightingSpace.space_name,
    });
  }

  trackApplicationSelection(application) {
    mixpanel.track("Application Selection", {
      Action: "Button Click",
      Effect: "Select/Deselect application",
      Application: application.application_name,
    });
  }

  trackNextButtonClick(message) {
    let applicationName = this.state.selectedApplications
      .map((app) => app.application_name)
      .join(", ");

    let spaceName = this.state.selectedLightingSpaces
      .map((l) => l.space_name)
      .join(", ");

    mixpanel.track("Compare Wizard - Next Filters", {
      Action: "Button Click",
      Effect: message,
      "Lighting Spaces Name": spaceName,
      "Applications Name": applicationName,
    });
  }
}
export default FilterWizard1;
