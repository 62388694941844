import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ContentLoading from "../ContentLoading/ContentLoading";
import "./downloadProgress.css";

export default class DownloadProgress extends Component {
  render() {
    return (
      <Modal show={this.props.show} dialogClassName="download-progress-modal">
        <Modal.Header>
          <h3
            style={{
              fontWeight: "bolder",
              paddingLeft: "15px",
              textAlign: "center",
            }}
            className="popup-blue-text"
          >
            Download in progress...
          </h3>
        </Modal.Header>
        <Modal.Body>
          <ContentLoading message={""} size={40} />
        </Modal.Body>
      </Modal>
    );
  }
}
