import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "dotenv/config.js";
import { BrowserRouter } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import App from "./App/app.js";

require("dotenv").config();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
registerServiceWorker();
