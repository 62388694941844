import React, { Component } from "react";
import {
  Modal,
  FormControl,
  FormGroup,
  ControlLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import "./orderSummary.css";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import OrderSummaryHandler from "./OrderSummaryHandler";

/**
 * Component represents the Submit PO popup
 */
const ordersMail = Miscellaneous.OrdersMail;
class SubmitPOPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitPopupClicked: this.props.show,
      csrMail: "",
      notesText: this.props.notesText,
      name: this.props.name,
      userName: this.props.userName,
      branchData: this.props.branchData,
      salesrepEmails: "",
      ccmail: this.props.userName,
      subject: "",
      attachmentName: "",
      files: [],
      fileNameList: [],
      buttonClicked: false,
      address: this.props.address,
      cart: this.props.cart,
      orderType: this.props.orderType,
      stockingType: this.props.stockingType,
      projectName: this.props.projectName,
      contractor: this.props.contractor,
      specAvailable: this.props.specAvailable,
      title: this.props.title,
      quoteNumber: this.props.quoteNumber,
      shipDate: this.props.shipDate,
    };

    this.handleCCMailChange = this.handleCCMailChange.bind(this);
    this.modifyNotes = this.modifyNotes.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.addToSummaryHistory = this.addToSummaryHistory.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.addAttachments = this.addAttachments.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.validateEmailIds = this.validateEmailIds.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState((prevState) => ({
      branchData: newProps.branchData,
      isSubmitPopupClicked: newProps.show,
      name: newProps.name,
      notesText:
        newProps.notesText === this.props.notesText
          ? prevState.notesText
          : newProps.notesText,
      userName: newProps.userName,
      salesrepEmails:
        newProps.branchData !== null
          ? newProps.branchData.salesrep.toString()
          : "",
      csrMail: newProps.branchData !== null ? newProps.branchData.csrMail : "",
      subject:
        newProps.branchData !== null
          ? "PO - " + newProps.branchData.tldName + " : " + prevState.name
          : "",
      address: newProps.address,
      cart: this.props.cart,
      orderType: newProps.orderType,
      stockingType: newProps.stockingType,
      projectName: newProps.projectName,
      contractor: newProps.contractor,
      specAvailable: newProps.specAvailable,
      title: newProps.title,
      quoteNumber: newProps.quoteNumber,
      shipDate: newProps.shipDate,
    }));
  }

  componentWillUnmount() {
    this.handleOnClose();
  }

  handleCCMailChange(e) {
    let ccmail = e.target.value;
    ccmail = ccmail.replaceAll(",,", ",");
    ccmail = ccmail.replaceAll(" ", "");
    this.setState({
      ccmail: ccmail,
    });
  }

  modifyNotes(e) {
    this.setState({
      notesText: e.target.value,
    });
    this.props.AdditionalNotesChange(e.target.value);
  }

  handleOnClose() {
    this.setState(
      {
        notesText: this.props.notesText,
        ccmail: this.props.userName,
        attachmentName: "",
        files: [],
        fileNameList: [],
        buttonClicked: false,
      },
      this.props.handleOnClose()
    );
  }

  addToSummaryHistory() {
    let updatedOrderSummary = this.state.cart.orderSummaryData.map((item) => {
      return {
        ...item,
        Price: item.Price.toString(),
      };
    });

    let cart = {
      ...this.state.cart,
      type: "Submit PO",
      orderSummaryData: updatedOrderSummary,
    };

    OrderSummaryHandler.addToSummaryHistory(cart)
      .then((response) => {
        if (response.success) {
          this.handleOnClose();
        } else {
          this.setState({
            buttonClicked: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          buttonClicked: false,
        });
        this.props.addNotification(
          ErrorMessages.SubmitPOPopup_AddToSummaryHistory,
          "error"
        );
      });
  }

  setToMail() {
    if (this.state.csrMail || this.state.salesrepEmails) {
      return this.state.csrMail + "," + this.state.salesrepEmails;
    } else {
      return ordersMail;
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleOnClose}
        className="popup-modal"
      >
        <Modal.Header>
          <Modal.Title
            style={{
              color: "#074391",
              fontWeight: "bolder",
              fontSize: "24px",
              textShadow: "0px 1px, 1px 0px, 1px 1px",
              paddingLeft: "20px",
            }}
          >
            Submit PO
            <br />
          </Modal.Title>
          <span
            style={{ fontSize: "12px", paddingLeft: "20px", display: "block" }}
          >
            {DisplayMessages.SubmitPOPopup_SubmitPOLine1}
          </span>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Row className="popup-row">
            <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
              <ControlLabel className="popup-labels">To</ControlLabel>
            </Col>
            <Col md={10} sm={10} xs={10}>
              <FormGroup>
                <FormControl
                  type="text"
                  disabled={true}
                  value={this.setToMail()}
                  spellCheck="false"
                />
              </FormGroup>
            </Col>
            <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          </Row>
          <Row className="popup-row">
            <Col md={1} sm={2} xs={2} style={{ padding: "0px" }} />
            <Col md={10} sm={10} xs={10}>
              <span style={{ fontWeight: "bold" }}>
                Use commas "," to separate emails in CC field
              </span>
            </Col>
            <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          </Row>
          <Row className="popup-row">
            <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
              <ControlLabel className="popup-labels">CC</ControlLabel>
            </Col>
            <Col md={10} sm={10} xs={10}>
              <FormGroup>
                <FormControl
                  type="text"
                  value={this.state.ccmail}
                  spellCheck="false"
                  onChange={this.handleCCMailChange}
                  onBlur={this.validateEmailIds}
                />
              </FormGroup>
            </Col>
            <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          </Row>
          <Row className="popup-row">
            <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
              <ControlLabel className="popup-labels">Subject</ControlLabel>
            </Col>
            <Col md={10} sm={10} xs={10}>
              <FormGroup>
                <FormControl
                  type="text"
                  disabled={true}
                  value={this.state.subject}
                  spellCheck="false"
                />
              </FormGroup>
            </Col>
            <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          </Row>
          <Row className="popup-row">
            <Col md={1} style={{ padding: "0px" }} />
            <Col md={2} sm={2} xs={2}>
              <input
                id="attachments"
                type="file"
                name="file"
                style={{ display: "none" }}
                onChange={this.addAttachments}
              />
              <Button
                bsStyle="primary"
                className="button-blue"
                onClick={this.openFileBrowser}
              >
                Attach PO
              </Button>
            </Col>
            <Col md={8} sm={8} xs={8}>
              {this.state.fileNameList.length > 0 ? (
                this.getAttachments()
              ) : (
                <span />
              )}
            </Col>
            <Col md={1} style={{ padding: "0px" }} />
          </Row>
          <br />
          <Row className="popup-row">
            <Col md={1} style={{ padding: "0px" }} />
            <Col md={10} sm={12} xs={12}>
              <FormControl
                componentClass="textarea"
                style={{ resize: "none", height: "100%", minHeight: "180px" }}
                rows="3"
                value={this.state.notesText}
                spellCheck="false"
                placeholder="Add notes, special requests, lighting layout needs..."
                onChange={this.modifyNotes}
                maxLength="500"
              />
            </Col>
            <Col md={1} style={{ padding: "0px" }} />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={this.state.buttonClicked}
            bsStyle="primary"
            className="button-blue"
            onClick={this.sendMail}
          >
            Submit PO
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  sendMail() {
    if (this.state.files.length !== 0) {
      let toMail = this.setToMail();
      let mailContent = {
        toMail: toMail.replaceAll(",", ";"),
        ccmail: this.state.ccmail.replaceAll(",", ";"),
        subject: this.state.subject,
        body: this.state.notesText,
        attachments: this.state.files,
      };

      this.setState({
        buttonClicked: true,
      });

      let form = new FormData();
      form.append("TLDName", this.state.branchData.tldName);
      form.append("BranchName", this.state.branchData.branchName);
      form.append("UserName", this.state.name);
      form.append("CustomerID", this.state.branchData.customerId);
      form.append("Email", sessionStorage.getItem("username"));
      form.append("Title", this.state.title);
      form.append("Address", this.state.address);
      form.append("OrderType", this.state.orderType);
      form.append("StockType", this.state.stockingType);
      form.append("ProjectName", this.state.projectName);
      form.append("DesiredShipDate", this.state.shipDate);
      form.append("QuoteNumber", this.state.quoteNumber);
      form.append(
        "Contractor",
        this.state.contractor === "" ? "" : this.state.contractor.label
      );
      form.append("SpecAvailable", this.state.specAvailable);
      form.append(
        "UserNotes",
        this.state.notesText ? this.state.notesText : "NA"
      );
      form.append("To", mailContent.toMail);
      form.append("CC", mailContent.ccmail);
      form.append("Subject", mailContent.subject);
      mailContent.attachments.forEach((item) => {
        form.append("Attachments", item[0]);
      });

      OrderSummaryHandler.sendPOMail(form)
        .then((response) => {
          if (response.success) {
            this.props.addNotification(
              ErrorMessages.SubmitPOPopup_EmailSuccess,
              "info"
            );
            this.trackSubmitPOSuccessButtonClick(mailContent);
            this.addToSummaryHistory();
          } else {
            this.trackSubmitPOFailedButtonClick(response.message);
            this.setState(
              {
                buttonClicked: false,
              },
              this.props.addNotification(response.message, "error")
            );
          }
        })
        .catch((error) => {
          this.setState(
            {
              buttonClicked: false,
            },
            this.props.addNotification(error, "error")
          );
          this.trackSubmitPOFailedButtonClick(
            ErrorMessages.SubmitPOPopup_SendPOMail
          );
          this.props.addNotification(
            ErrorMessages.SubmitPOPopup_SendPOMail,
            "error"
          );
        });
    } else {
      this.trackSubmitPOFailedButtonClick(
        ErrorMessages.SubmitPOPopup_AddAttachment
      );
      this.setState(
        {
          buttonClicked: false,
        },
        this.props.addNotification(
          ErrorMessages.SubmitPOPopup_AddAttachment,
          "warning"
        )
      );
    }
  }

  validateEmailIds(e) {
    let emailList = e.target.value.split(",");
    let ccEmailList = [];
    let pattern = Miscellaneous.RegexEmailId;
    const re = new RegExp(pattern, "i");
    emailList.forEach((email) => {
      email = email.trim();
      if (email.length > 0 && re.test(email)) {
        ccEmailList.push(email);
      }
      this.setState({
        ccmail: ccEmailList.toString(),
      });
    });
  }

  getAttachments() {
    return this.state.fileNameList.map((item, i) => {
      return (
        <Col md={2} sm={3} xs={4} key={item}>
          <div className="attachment-container">
            <div>
              <span
                className="glyphicon glyphicon-remove"
                style={{ float: "right" }}
                onClick={() => this.removeFile(i)}
              />
            </div>
            <div className="attachment-name">{item}</div>
            <img src={this.getDocumentTypeIcon(item)} alt="file" />
          </div>
        </Col>
      );
    });
  }

  removeFile(index) {
    let fileNameList = this.state.fileNameList;
    let files = this.state.files;
    fileNameList.splice(index, 1);
    files.splice(index, 1);
    this.setState({
      fileNameList: fileNameList,
      files: files,
    });
  }

  openFileBrowser() {
    let inputElement = document.getElementById("attachments");
    inputElement.click();
    this.trackAddAttachmentsButtonClick();
  }

  getDocumentTypeIcon(filePath) {
    let fileExtension = filePath.slice(
      filePath.lastIndexOf(".") + 1,
      filePath.length
    );
    switch (fileExtension) {
      case "pdf":
        return fetchResourceData(fixedLink.pdfIcon);
      case "doc":
      case "docx":
        return fetchResourceData(fixedLink.wordIcon);
      case "xls":
      case "xlsx":
        return fetchResourceData(fixedLink.excelIcon);
      case "ppt":
      case "pptx":
        return fetchResourceData(fixedLink.powerpointIcon);
      case "png":
      case "jpg":
        return fetchResourceData(fixedLink.imageIcon);
      default:
        return fetchResourceData(fixedLink.textIcon);
    }
  }

  addAttachments(e) {
    let files = this.state.files;
    let fileNameList = this.state.fileNameList;
    let path = e.target.value;
    let filename = path.substring(path.lastIndexOf("\\") + 1);
    if (filename !== "" && !fileNameList.find((item) => item === filename)) {
      files.push(e.target.files);
      fileNameList.push(filename);
    }
    this.setState({
      files: files,
      fileNameList: fileNameList,
    });
  }

  // Mixpanel Tracking //

  trackAddAttachmentsButtonClick() {
    mixpanel.track("Add Attachments", {
      Action: "Button Click",
      Effect: "Selected attachments will be added to mail",
    });
  }

  trackSubmitPOSuccessButtonClick(mailContent) {
    mixpanel.track("Submit PO", {
      Action: "Button Click",
      Effect: "Purchase Order will be sent",
      To: mailContent.toMail,
      CC: mailContent.ccmail,
      Subject: mailContent.subject,
    });
  }

  trackSubmitPOFailedButtonClick(message) {
    mixpanel.track("Submit PO", {
      Action: "Button Click",
      Effect: "Purchase Order will not be sent",
      Message: message,
    });
  }
}

export default SubmitPOPopup;
