import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";

/**
 * Component represents the filter Options section
 */
class FilterOptions extends Component {
  render() {
    return <Row>{this.props.children}</Row>;
  }
}
export default FilterOptions;
