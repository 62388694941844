import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Creatable from "react-select/creatable";
import { Row, Col, ControlLabel, FormControl } from "react-bootstrap";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler";
import "./selectCustomerPopup.css";
import { ErrorMessages, Miscellaneous } from "../Utils/FetchConfigurableData";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 125,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const priceType = ["Price1", "Price2"];
const priceString = "Price";

let customerName = "";

/**
 * Component represents the select customer popup
 */
class SelectCustomerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewCustomer: false,
      selectedCustomer: "",
      customerList: [],
      tierSelected: "Tier1",
      tierPricing: "Price1",
      customerTier: "Tier1",
      last_updated: null,
      constructionTier: "Price1",
      ledTier: "Price1",
      tierList: Miscellaneous.TiersList,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleTierSelect = this.handleTierSelect.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.setCustomerList = this.setCustomerList.bind(this);
  }

  componentWillReceiveProps(newProp) {
    if (newProp && newProp.show) {
      this.setCustomerList();
    }
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  setCustomerList() {
    ProductConfiguratorHandler.getCustomerList()
      .then((response) => {
        let customer = response.data.customers.list.find(
          (item) => item.CustomerName === customerName
        );
        let price =
          response.data.customers.tier === this.state.tierList[0] ||
          customerName === ""
            ? priceType[0]
            : priceType[1];
        this.setState({
          last_updated: response.data.lastUpdatedTime,
          isNewCustomer:
            customer === undefined && customerName !== "" ,
          tierSelected: this.setTierSelected(response),
          tierPricing: this.setTier(price, customer),
          customerTier: this.setCustomerTier(customer),
          searchSelectedTier: this.setTier(price, customer),
          ledTier: this.setTier(price, customer),
          constructionTier: this.setConstructionTier(price, customer),
          customerList: response.data.customers.list.map((item) => ({
            value:
              item.CustomerName +
              "-tier-" +
              item.Tier +
              "-tier-" +
              item.ConstructionTier,
            label: item.CustomerName,
          })),
        });
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.CustomerListMissing, "error");
      });
  }

  setTierSelected(response) {
    return customerName !== ""
      ? response.data.customers.tier
      : this.state.tierList[0];
  }

  setTier(price, customer) {
    return customer === undefined ? price : priceString + customer.Tier;
  }

  setConstructionTier(price, customer) {
    return customer === undefined
      ? price
      : priceString + customer.ConstructionTier;
  }

  setCustomerTier(customer) {
    return customer === undefined || customer.Tier === "1"
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          customer.ConstructionTier,
          "1",
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  render() {
    return (
      <div className="modal-dialog customer-select">
        <Modal show={this.props.show} onHide={this.handleExit}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              {this.props.title}
            </h3>
          </Modal.Header>
          <Modal.Body className="customer-select-body">
            {this.getCustomerList()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={this.handleClose}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getCustomerList() {
    return (
      <Row style={{ fontSize: "16px" }}>
        <Col md={12} sm={12} xs={12}>
          <Col md={7} sm={12} xs={12}>
            <ControlLabel>Select Customer</ControlLabel>
            <Creatable
              maxMenuHeight={190}
              value={{
                value: this.state.selectedCustomer,
                label:
                  this.state.selectedCustomer === null ||
                  this.state.selectedCustomer === ""
                    ? "Select..."
                    : this.state.selectedCustomer,
              }}
              styles={customStyles}
              isClearable
              onChange={this.handleCustomerSelect}
              options={this.state.customerList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
          <Col md={5} sm={12} xs={12}>
            <ControlLabel>Customer Tier </ControlLabel>
            {this.state.isNewCustomer ? (
              <Select
                maxMenuHeight={190}
                menuPlacement="auto"
                value={{
                  value: this.state.tierSelected,
                  label: this.state.tierSelected,
                }}
                styles={customStyles}
                onChange={this.handleTierSelect}
                options={this.state.tierList.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            ) : (
              <FormControl
                readOnly
                type="text"
                style={{ height: "20px", minHeight: "34px" }}
                value={this.state.customerTier}
              />
            )}
          </Col>
        </Col>
      </Row>
    );
  }

  handleCustomerSelect(event) {
    if (event === null) {
      sessionStorage.setItem("customerName", "");
      this.trackCustomerSelection("");

      this.setState((prevState) => ({
        isNewCustomer: false,
        selectedCustomer: "",
        tierPricing: priceType[0],
        customerTier: prevState.tierList[0],
        searchSelectedTier: priceType[0],
        ledTier: priceType[0],
        constructionTier: priceType[0],
      }));
      return;
    }
    let customerInfo = event.value.split("-tier-");

    if (customerInfo.length > 1) {
      sessionStorage.setItem("customerName", customerInfo[0]);
      this.trackCustomerSelection(customerInfo[0]);

      this.setState((prevState) => ({
        isNewCustomer: false,
        selectedCustomer: customerInfo[0],
        ledTier: priceString + customerInfo[1],
        constructionTier: priceString + customerInfo[2],
        tierPricing: priceString + customerInfo[1],
        searchSelectedTier: priceString + customerInfo[1],
        customerTier:
          customerInfo[1] === "1"
            ? prevState.tierList[0]
            : this.ternaryEvaluation(
                customerInfo[2],
                "1",
                prevState.tierList[1],
                prevState.tierList[2]
              ),
      }));
    } else {
      sessionStorage.setItem("customerName", event.value);
      this.trackCustomerSelection(event.value);

      this.setState((prevState) => ({
        isNewCustomer: true,
        selectedCustomer: event.value,
        tierPricing: priceType[0],
        customerTier: prevState.tierList[0],
        searchSelectedTier: priceType[0],
        ledTier: priceType[0],
        constructionTier: priceType[0],
        tierSelected: prevState.tierList[0],
      }));
    }
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";
  }

  handleTierSelect(e) {
    let tier = e.value;

    this.trackCustomerTierSelection(tier);
    ProductConfiguratorHandler.setCustomerTier(tier)
      .then((response) => {
        if (!response.success) {
          this.props.addNotification(
            ErrorMessages.CustomerTierNotRegistered,
            "error"
          );
        }
        this.setState((prevState) => ({
          tierSelected: tier,
          tierPricing:
            tier === prevState.tierList[0] ? priceType[0] : priceType[1],
          customerTier: tier,
        }));
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.CustomerTierMissing, "error");
      });
  }

  reset() {
    sessionStorage.setItem("customerName", "");
    customerName = "";
    this.setState((prevState) => ({
      selectedCustomer: "",
      customerTier: prevState.tierList[0],
    }));
  }

  handleExit() {
    this.reset();
    this.props.handleCustomerClose();
  }

  handleClose() {
    this.props.handleCustomerClose();
  }

  // Mixpanel Tracking //

  trackCustomerSelection(customer) {
    mixpanel.track("Customer Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer is displayed",
      Customer: customer,
    });
  }

  trackCustomerTierSelection(tier) {
    mixpanel.track("Customer Tier Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer tier is displayed",
      Tier: tier,
    });
  }
}

export default SelectCustomerPopup;
