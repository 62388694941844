import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { Row, Button } from "react-bootstrap";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./searchbox.css";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";

const SearchParameters = {
  productPage: 0,
  description: 1,
  stockCode: 2,
};

const searchPlaceholder = [
  "Enter minimum 2 characters",
  "Enter minimum 3 characters",
  "Enter minimum 4 characters",
];

const searchParameters = [
  { value: "productPage", label: "Product Page" },
  { value: "description", label: "Description" },
  { value: "stockCode", label: "Stock Code" },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 10,
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    cursor: "text",
    transition: "border-color ease-in-out 0.5s, box-shadow ease-in-out 0.5s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const defaultOption = { value: "0", label: "", searchString: "" };

const MenuList = (props) => {
  let input = props.selectProps.inputValue;
  if (props.options.length > 1) {
    return (
      <components.MenuList {...props}>
        {props.options.map((item) => {
          if (item.value === "0") {
            if (props.selectProps.searchType === 0) {
              return (
                <Link
                  key={item.value + item.label}
                  to={{
                    pathname: "/Products",
                    state: { s: item.searchString },
                  }}
                >
                  <div
                    className="viewAllOption"
                    onClick={() => {
                      props.selectProps.trackViewAllSearchResults(
                        "View All",
                        "Product Page",
                        item.searchString
                      );
                      props.selectProps.handleItemClick(item);
                    }}
                  >
                    <span>{item.label}</span>
                  </div>
                </Link>
              );
            } else {
              return (
                <Link
                  key={item.value + item.label}
                  to={{
                    pathname: "/SearchResults",
                    state: {
                      item: item,
                      s: input,
                      searchType: props.selectProps.searchType,
                    },
                  }}
                >
                  <div
                    className="viewAllOption"
                    onClick={() => {
                      props.selectProps.trackViewAllSearchResults(
                        "View All",
                        "Search Page",
                        input
                      );
                      props.selectProps.handleItemClick(item);
                    }}
                  >
                    <span>{item.label}</span>
                  </div>
                </Link>
              );
            }
          } else if (props.selectProps.searchType === 0) {
            return (
              <Link
                key={item.value + item.label}
                to={{ pathname: "/Products", state: { s: item.label } }}
              >
                <div
                  className="itemOption"
                  onClick={() => {
                    props.selectProps.trackSearchResults(
                      item,
                      "Selected",
                      "Product Page",
                      item.label
                    );
                    props.selectProps.handleItemClick(item);
                  }}
                >
                  <span>{item.label}</span>
                </div>
              </Link>
            );
          } else {
            return (
              <Link
                key={item.value + item.label}
                to={{
                  pathname: "/SearchResults",
                  state: {
                    item: item,
                    s: input,
                    searchType: props.selectProps.searchType,
                  },
                }}
              >
                <div
                  className="itemOption"
                  onClick={() => {
                    props.selectProps.trackSearchResults(
                      item,
                      "Selected",
                      "Search Page",
                      input
                    );
                    props.selectProps.handleItemClick(item);
                  }}
                >
                  <span style={{ paddingRight: "20px" }}>{item.value}</span>
                  <span style={{ paddingLeft: "20px" }}>{item.label}</span>
                </div>
              </Link>
            );
          }
        })}
      </components.MenuList>
    );
  } else {
    return (
      <components.MenuList {...props}>
        <div>{props.children}</div>
      </components.MenuList>
    );
  }
};

/**
 * Component represents the products search bar in the website header
 */
class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.addNotification = this.addNotification.bind(this);
    this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    this.searchTypeChangeHandler = this.searchTypeChangeHandler.bind(this);
    this.itemSearchHandler = this.itemSearchHandler.bind(this);
    this.noOptionsMessageHandler = this.noOptionsMessageHandler.bind(this);
    this.itemSelectedHandler = this.itemSelectedHandler.bind(this);
    this.stockCodeSearchHandler = this.stockCodeSearchHandler.bind(this);
    this.productSearchHandler = this.productSearchHandler.bind(this);
    this.enterPressedHandler = this.enterPressedHandler.bind(this);
    this.searchButtonHandler = this.searchButtonHandler.bind(this);
    this.forceSearchHandler = this.forceSearchHandler.bind(this);
    this.menuCloseHandler = this.menuCloseHandler.bind(this);

    this.state = {
      searchString: "",
      searchType: 0,
      isLoading: false,
      selectedOption: "",
      searchOptions: [],
      menuOpen: false,
      forceSearch: false,
    };
    this.timer = null;
    this._notificationSystem = null;
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div className="top-form top-search pull-right">
        <Row style={{ marginTop: "10px" }}>
          <Col md={11} sm={11} xs={11} className="no-padding">
            {this.toggleSearchBox()}
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Col md={11} sm={11} xs={11} className="no-padding">
            <Select
              ref={(c) => {
                this.selectRef = c;
              }}
              id="main-search-bar"
              maxMenuHeight="350px"
              menuPortalTarget={document.body}
              styles={customStyles}
              isSearchable
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                MenuList,
              }}
              value={this.state.selectedOption}
              searchType={this.state.searchType}
              loadingMessage={() => "Searching for Products"}
              noOptionsMessage={this.noOptionsMessageHandler}
              isLoading={this.state.isLoading}
              onKeyDown={this.enterPressedHandler}
              inputValue={this.state.searchString}
              menuIsOpen={this.state.menuOpen}
              onBlur={() => {
                this.setState({
                  menuOpen: false,
                  forceSearch: false,
                });
              }}
              onInputChange={this.handleSearchStringChange}
              placeholder={searchPlaceholder[this.state.searchType]}
              options={this.state.searchOptions}
              aria-label="Search"
              handleItemClick={(e) => this.itemSelectedHandler(e)}
              trackSearchResults={this.trackSearchResultsClick}
              trackViewAllSearchResults={this.trackViewAllSearchResultsClick}
            />
          </Col>
          <Col md={1} sm={1} xs={1} className="no-padding">
            {this.searchButtonHandler()}
          </Col>
        </Row>
        <br />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  forceSearchHandler() {
    this.trackSearchButtonClick();
    this.setState({ isLoading: false, menuOpen: false, forceSearch: true });
  }

  menuCloseHandler() {
    this.setState({ isLoading: false, menuOpen: false });
  }

  enterPressedHandler(e) {
    if (e.keyCode === 13) {
      let emptySearchProductsButton = document.getElementById(
        "button-empty-search-products"
      );
      let searchProductsButton = document.getElementById(
        "button-search-products"
      );
      let emptySearchDescriptionButton = document.getElementById(
        "button-empty-search-description"
      );
      let searchDescriptionButton = document.getElementById(
        "button-search-description"
      );
      let emptySearchStockCodeButton = document.getElementById(
        "button-empty-search-stockCode"
      );
      let searchStockCodeButton = document.getElementById(
        "button-search-stockCode"
      );

      if (this.state.searchType === 0) {
        if (this.state.searchString.length < 2) {
          emptySearchProductsButton.focus();
          emptySearchProductsButton.click();
        } else {
          searchProductsButton.focus();
          searchProductsButton.click();
        }
      } else if (this.state.searchType === 1) {
        if (this.state.searchString.length < 3) {
          emptySearchDescriptionButton.focus();
          emptySearchDescriptionButton.click();
        } else {
          searchDescriptionButton.focus();
          searchDescriptionButton.click();
        }
      } else if (this.state.searchString.length < 4) {
        emptySearchStockCodeButton.focus();
        emptySearchStockCodeButton.click();
      } else {
        searchStockCodeButton.focus();
        searchStockCodeButton.click();
      }
      e.preventDefault();
    }
  }

  searchButtonHandler() {
    if (this.state.searchType === 0) {
      if (this.state.searchString.length < 2) {
        return (
          <Button
            id="button-empty-search-products"
            className="glyphicon glyphicon-search button-blue"
            aria-label="Search"
            style={{ width: "100%", top: "0px" }}
            onClick={() => {
              this.trackInvalidSearchButtonClick();
              this.setState({ isLoading: true }, this.itemSearchHandler);
            }}
          />
        );
      } else {
        return (
          <Link
            id="button-search-products"
            to={{
              pathname: "/Products",
              state: {
                s: this.state.searchString,
              },
            }}
            onClick={this.forceSearchHandler}
          >
            <Button
              id="button-search-products"
              className="glyphicon glyphicon-search button-blue"
              aria-label="Search"
              style={{ width: "100%", top: "0px" }}
              onClick={this.menuCloseHandler}
            />
          </Link>
        );
      }
    } else if (this.state.searchType === 1) {
      if (this.state.searchString.length < 3) {
        return (
          <Button
            id="button-empty-search-description"
            className="glyphicon glyphicon-search button-blue"
            aria-label="Search"
            style={{ width: "100%", top: "0px" }}
            onClick={() => {
              this.trackInvalidSearchButtonClick();
              this.setState({ isLoading: true }, this.itemSearchHandler);
            }}
          />
        );
      } else {
        return (
          <Link
            id="button-search-description"
            to={{
              pathname: "/SearchResults",
              state: {
                s: this.state.searchString,
                item: defaultOption,
                searchType: this.state.searchType,
              },
            }}
            onClick={this.forceSearchHandler}
          >
            <Button
              className="glyphicon glyphicon-search button-blue"
              aria-label="Search"
              style={{ width: "100%", top: "0px" }}
              onClick={this.menuCloseHandler}
            />
          </Link>
        );
      }
    } else if (this.state.searchString.length < 4) {
      return (
        <Button
          id="button-empty-search-stockCode"
          aria-label="Search"
          className="glyphicon glyphicon-search button-blue"
          style={{ width: "100%", top: "0px" }}
          onClick={() => {
            this.trackInvalidSearchButtonClick();
            this.setState({ isLoading: true }, this.itemSearchHandler);
          }}
        />
      );
    } else {
      return (
        <Link
          id="button-search-stockCode"
          to={{
            pathname: "/SearchResults",
            state: {
              s: this.state.searchString,
              item: defaultOption,
              searchType: this.state.searchType,
            },
          }}
          onClick={this.forceSearchHandler}
        >
          <Button
            className="glyphicon glyphicon-search button-blue"
            aria-label="Search"
            style={{ width: "100%", top: "0px" }}
            onClick={this.menuCloseHandler}
          />
        </Link>
      );
    }
  }

  noOptionsMessageHandler() {
    if (this.state.searchType === 0) {
      if (this.state.searchString.length < 2) {
        return DisplayMessages.SearchBar_NoOptionsProductPage;
      } else {
        return DisplayMessages.SearchBar_NoMatchingProducts;
      }
    } else if (this.state.searchType === 1) {
      if (this.state.searchString.length < 3) {
        return DisplayMessages.SearchBar_NoOptionsDescriptions;
      } else {
        return DisplayMessages.SearchBar_NoMatchingProducts;
      }
    } else if (this.state.searchString.length < 4) {
      return DisplayMessages.SearchBar_NoOptionsStockCode;
    } else {
      return DisplayMessages.SearchBar_NoMatchingProducts;
    }
  }

  itemSelectedHandler(item) {
    this.selectRef.blur();
  }

  toggleSearchBox() {
    return (
      <Row>
        <div className="sltabs-search">
          <label className="sltab-tag">
            <strong>Search By:</strong>
          </label>
          <input
            type="radio"
            id="radio-product"
            value="productPage"
            name="searchType"
            checked={this.state.searchType === 0}
            onChange={this.searchTypeChangeHandler}
            aria-labelledby="Search-By-Product-Page"
          />
          <label className="sltab-search" htmlFor="radio-product">
            <strong>Product Page</strong>
          </label>
          <input
            type="radio"
            id="radio-stockCode"
            value="stockCode"
            name="searchType"
            aria-labelledby="Search-by-Stock-Code"
            checked={this.state.searchType === 2}
            onChange={this.searchTypeChangeHandler}
          />{" "}
          <label className="sltab-search" htmlFor="radio-stockCode">
            <strong>Stock Code</strong>
          </label>
          <input
            type="radio"
            id="radio-description"
            value="description"
            name="searchType"
            aria-labelledby="Search-by-Description"
            checked={this.state.searchType === 1}
            onChange={this.searchTypeChangeHandler}
          />{" "}
          <label className="sltab-search" htmlFor="radio-description">
            <strong>Description</strong>
          </label>
          <span className="glider-search" />
        </div>
      </Row>
    );
  }
  searchTypeChangeHandler(e) {
    let searchValue = e.target.value;
    let searchType = SearchParameters[searchValue];

    this.trackSearchTypeToggle(searchType);

    this.setState({
      isLoading: false,
      menuOpen: false,
      selectedOption: "",
      searchOptions: [],
      searchType: searchType,
      searchString: "",
    });
  }

  descriptionSearchHandler(stringLimit) {
    if (
      this.state.searchString.length >= stringLimit &&
      !this.state.forceSearch
    ) {
      ConfigHandler.dropdownDescription(
        encodeURIComponent(this.state.searchString)
      )
        .then((response) => {
          let searchList = response.data.list;
          let matchCount = response.data.total;
          if (matchCount > 0) {
            let searches = [];
            searches = searchList.map((item) => ({
              value: item.StockCode,
              label: item.Description,
            }));
            let allMatches = defaultOption;
            allMatches.searchString = this.state.searchString;
            allMatches.label =
              matchCount > 1
                ? "View all " + matchCount + " matches"
                : "View " + matchCount + " match";
            searches.push(allMatches);
            this.setState({
              isLoading: false,
              menuOpen: true,
              searchOptions: searches,
            });
          } else {
            this.setState({
              isLoading: false,
              menuOpen: true,
              searchOptions: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            menuOpen: true,
            searchOptions: [],
          });
          this.addNotification(ErrorMessages.SearchBar_DropdownFailed, "error");
        });
    } else {
      this.setState((prevState) => ({
        isLoading: false,
        menuOpen: !prevState.forceSearch,
        searchOptions: [],
      }));
    }
    this.selectRef.focus();
  }

  stockCodeSearchHandler(stringLimit) {
    if (
      this.state.searchString.length >= stringLimit &&
      !this.state.forceSearch
    ) {
      ConfigHandler.dropdownStockCode(
        encodeURIComponent(this.state.searchString)
      )
        .then((response) => {
          let searchList = response.data.list;
          let matchCount = response.data.total;
          if (matchCount > 0) {
            let searches = [];
            searches = searchList.map((item) => ({
              value: item.StockCode,
              label: item.Description,
            }));
            let allMatches = defaultOption;
            allMatches.searchString = this.state.searchString;
            allMatches.label =
              matchCount > 1
                ? "View all " + matchCount + " matches"
                : "View " + matchCount + " match";
            searches.push(allMatches);
            this.setState({
              isLoading: false,
              menuOpen: true,
              searchOptions: searches,
            });
          } else {
            this.setState({
              isLoading: false,
              menuOpen: true,
              searchOptions: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            menuOpen: true,
            searchOptions: [],
          });
          this.addNotification(ErrorMessages.SearchBar_DropdownFailed, "error");
        });
    } else {
      this.setState((prevState) => ({
        isLoading: false,
        menuOpen: !prevState.forceSearch,
        searchOptions: [],
      }));
    }
    this.selectRef.focus();
  }

  productSearchHandler(stringLimit) {
    if (
      this.state.searchString.length >= stringLimit &&
      !this.state.forceSearch
    ) {
      let filter = {
        searchString: encodeURIComponent(this.state.searchString),
        page: 1,
        offset: 16,
      };
      ConfigHandler.productSearch(filter)
        .then((response) => {
          let searchList = response.productList;
          let matchCount = response.total;
          if (matchCount > 0) {
            let searches = [];
            searches = searchList.slice(0, 7).map((item) => ({
              value: item.title,
              label: item.title,
            }));
            let allMatches = defaultOption;
            allMatches.searchString = this.state.searchString;
            allMatches.label =
              matchCount > 1
                ? "View all " + matchCount + " matches"
                : "View " + matchCount + " match";
            searches.push(allMatches);
            this.setState({
              isLoading: false,
              menuOpen: true,
              searchOptions: searches,
            });
          } else {
            this.setState({
              isLoading: false,
              menuOpen: true,
              searchOptions: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            menuOpen: true,
            searchOptions: [],
          });
          this.addNotification(ErrorMessages.SearchBar_DropdownFailed, "error");
        });
    } else {
      this.setState((prevState) => ({
        isLoading: false,
        menuOpen: !prevState.forceSearch,
        searchOptions: [],
      }));
    }
    this.selectRef.focus();
  }
  itemSearchHandler() {
    switch (this.state.searchType) {
      case 0:
        this.productSearchHandler(2);
        break;
      case 1:
        this.descriptionSearchHandler(3);
        break;
      case 2:
        this.stockCodeSearchHandler(4);
        break;
      default:
        this.productSearchHandler(2);
        break;
    }
  }

  handleSearchStringChange(input, meta) {
    let searchString = input.toString();
    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      this.setState(
        {
          searchString: searchString,
          isLoading: true,
          menuOpen: false,
          forceSearch: false,
        },
        () => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.itemSearchHandler();
          }, 2000);
        }
      );
    }
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  // Mixpanel Tracking //

  trackSearchTypeToggle(searchType) {
    mixpanel.track("Product - Search By", {
      Action: "Button Click",
      Effect: "Product Search By parameter changed",
      "Search Type": searchParameters[searchType].label,
    });
  }

  trackViewAllSearchResultsClick(type, page, searchString) {
    mixpanel.track("Search Results Dropdown", {
      Action: "Dropdown Selection",
      Effect: `View results based on search string`,
      "Search Type": `${page} - ${type}`,
      "Search String": searchString,
    });
  }

  trackSearchResultsClick(item, type, page, searchString) {
    mixpanel.track("Search Results Dropdown", {
      Action: "Dropdown Selection",
      Effect: `View results based on search string`,
      "Search Type": `${page} - ${type}`,
      "Search String": searchString,
      "Stock Code": item.value,
      Description: item.label,
    });
  }

  trackSearchButtonClick() {
    let searchType = searchParameters[this.state.searchType].label;
    mixpanel.track("Search Results", {
      Action: "Button Click",
      Effect: `Results based on search string`,
      "Search Type": searchType,
      "Search String": this.state.searchString,
    });
  }

  trackInvalidSearchButtonClick() {
    let searchType = searchParameters[this.state.searchType].label;
    mixpanel.track("Search Results", {
      Action: "Button Click",
      Effect: `No results - Minimum characters not met`,
      "Search Type": searchType,
      "Search String": this.state.searchString,
    });
  }
}
export default SearchBox;
