import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import { Col, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-table/react-table.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import DistributorsHandler from "./DistributorsHandler";
import BranchDetail from "./BranchDetail";
import "../Distributors/branchNavigation.css";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading";

/**
 * Component represents the branch navigation page for branch based users
 */
class DistributorBranchNavigation extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      branchData: [],
      rowdata: [],
      exportBranchesPopup: false,
      searchString: "",
      searchValid: false,
      tldId: "",
      tldVisibility: JSON.parse(sessionStorage.getItem("tldVisibility")),
      distributor: "",
      selectedBranch: null,
      selectedBranchId: "",
      username: "",
      isDataReceived: false,
      showAllBranches: false,
    };
    this.timer = null;

    this._notificationSystem = null;
    this.handleOnClose = this.handleOnClose.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    this.searchBranches = this.searchBranches.bind(this);
    this.searchBarVisible = this.searchBarVisible.bind(this);
    this.handleExportCSV = this.handleExportCSV.bind(this);
    this.goToBranchNavigation = this.goToBranchNavigation.bind(this);
    this.toggleBranches = this.toggleBranches.bind(this);
    this.branchDataList = this.branchDataList.bind(this);
    this.branchNameLayout = this.branchNameLayout.bind(this);
    this.branchNameHandler = this.branchNameHandler.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    let username = sessionStorage.getItem("username");
    this.setState(
      {
        role: userObj["role"],
        showAllBranches: userObj["role"] === "Distributor User",
        username: username,
        tldVisibility: JSON.parse(sessionStorage.getItem("tldVisibility")),
      },
      this.refreshPage
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  branchTableLoadingSection() {
    return (
      <ContentLoading message={DisplayMessages.BranchList_Loading} size={50} />
    );
  }

  paginationOptionsBuilder() {
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.trackPagination(page);
        onPageChange(page);
      };

      return (
        <li className="page-item">
          {/* eslint-disable-next-line */}
          <a href="#" key={page} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      pageButtonRenderer,
      sizePerPageList: [
        {
          text: "25",
          value: 25,
        },
      ],
    };

    return options;
  }

  refreshPage() {
    DistributorsHandler.getBranchAdminData(
      encodeURIComponent(this.state.username)
    )
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState(
            {
              distributor: response.data.tld.tldName,
              tldId: response.data.tld.id,
              searchString: "",
              searchValid: true,
              isDataReceived: true,
            },
            () => {
              if (this.state.tldVisibility) {
                this.setState({
                  branchData: response.data.list,
                });
              } else {
                this.setState({
                  selectedBranch: response.data.list[0],
                });
              }
            }
          );
        } else if (this._isMounted) {
          this.setState({
            searchValid: false,
            isDataReceived: true,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.DistributorBranchNavigation_BranchAdminData,
          "error"
        );
      });
  }

  searchBranches() {
    DistributorsHandler.searchBranches(
      encodeURIComponent(this.state.searchString),
      this.state.tldId,
      this.state.username
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            branchData: response.data,
            searchValid: true,
            isDataReceived: true,
          });
        } else {
          this.setState({
            searchValid: false,
            isDataReceived: true,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.DistributorBranchNavigation_SearchBranches,
          "error"
        );
      });
  }

  toggleBranches() {
    this.setState(
      (prevState) => ({
        showAllBranches: !prevState.showAllBranches,
      }),
      this.trackViewAllBranchesCheckbox
    );
  }

  branchDataList() {
    if (this.state.showAllBranches) {
      return this.state.branchData;
    } else {
      let homeBranchData = this.state.branchData.filter(
        (item) => item.home || item.star || item.admin
      );
      return homeBranchData;
    }
  }

  searchBarVisible() {
    if (
      this.state.searchValid ||
      this.state.searchString.length > 0 ||
      this.state.branchData.length > 0
    ) {
      return "flex-component";
    } else {
      return "hide-component";
    }
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
        },
      },
    };
    return (
      <div className="distributor-container">
        {this.state.selectedBranch === null ? (
          <div>
            <Row>
              <Row>
                <h2 className="big-blue-text">{this.state.distributor}</h2>
              </Row>
              <br />
              <Row
                className={this.searchBarVisible()}
                style={{
                  alignItems: "flex-end",
                }}
              >
                <Col md={7} sm={7} xs={7} className="add-export">
                  {this.state.role !== "Distributor User" ? (
                    <div>
                      <label
                        onChange={this.toggleBranches}
                        style={{ fontSize: "14px" }}
                      >
                        <input
                          type="checkbox"
                          value={this.state.showAllBranches}
                          defaultChecked={this.state.showAllBranches}
                        />{" "}
                        View All Branches
                      </label>
                    </div>
                  ) : null}
                </Col>
                <Col
                  md={5}
                  sm={12}
                  xs={12}
                  className="searchbox"
                  style={{ float: "right", padding: "0px", marginTop: "10px" }}
                >
                  <Col className="no-padding-branch">
                    <FormControl
                      type="text"
                      autoComplete="off"
                      placeholder="Search for a branch"
                      spellCheck="false"
                      value={this.state.searchString}
                      onChange={this.handleSearchStringChange}
                    />
                  </Col>
                </Col>
              </Row>
            </Row>
            <br />
            {this.branchTableSection()}
          </div>
        ) : (
          <BranchDetail
            goToBranchNavigation={this.goToBranchNavigation}
            addNotification={this.addNotification}
            selectedBranch={this.state.selectedBranch}
          />
        )}

        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  branchTableSection() {
    return this.state.isDataReceived
      ? this.branchTableDisplay()
      : this.branchTableLoadingSection();
  }

  goToBranchNavigation() {
    this.setState(
      {
        selectedBranch: null,
      },
      this.refreshPage
    );
  }
  branchTableDisplay() {
    if (!this.state.searchValid || this.state.branchData.length === 0) {
      return (
        <Row>
          <center>
            <h4>No Branches Found!</h4>
          </center>
        </Row>
      );
    } else {
      return (
        <Row className="loading-fade">
          <BootstrapTable
            keyField="branchId"
            data={this.branchDataList()}
            columns={this.getColumns()}
            defaultSorted={this.getDefaultSort()}
            pagination={paginationFactory(this.paginationOptionsBuilder())}
          />
        </Row>
      );
    }
  }

  getDefaultSort() {
    const defaultSort = [
      {
        dataField: "activeUsers",
        order: "desc",
      },
    ];

    return defaultSort;
  }

  handleSearchStringChange(e) {
    let searchString = e.target.value.toString().trim();
    this.setState(
      {
        searchString: searchString,
        isDataReceived: false,
      },
      () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (searchString !== "") {
            this.trackSearchBranchesFilter(searchString);
            this.searchBranches();
          } else {
            this.refreshPage();
          }
        }, 1000);
      }
    );
  }

  getColumns() {
    const columns = [
      {
        dataField: "branchName",
        text: "Branch Name",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "350px",
        },
        formatter: (cell, row) => {
          return this.branchNameLayout(cell, row);
        },
      },
      {
        dataField: "address",
        text: "Address",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "400px",
        },
      },
      {
        dataField: "branchAdmin",
        text: "Branch Admin",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "200px",
        },
      },
      {
        dataField: "csr",
        text: "CSR",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "200px",
        },
      },

      {
        dataField: "totalUsers",
        text: "Number of Users",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 15px",
          width: "100px",
        },
      },
      {
        dataField: "inactiveUsers",
        text: "Inactive Users",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 15px",
          width: "100px",
        },
      },
    ];

    return columns;
  }

  branchNameHandler(cell, row) {
    this.trackBranchButtonClick(cell);
    this.setState({
      selectedBranch: row,
    });
  }

  branchNameLayout(cell, row) {
    return (
      <span style={{ display: "flex" }}>
        <button
          className="blue-text"
          onClick={() => {
            this.branchNameHandler(cell, row);
          }}
        >
          <span>{cell}</span>
        </button>
        {row.home ? (
          <div>
            {row.isCSR ? (
              <span
                className="glyphicon glyphicon-home home-icons"
                title="You are the CSR of this branch"
              />
            ) : (
              <span
                className="glyphicon glyphicon-home home-icons"
                title="This is your home branch"
              />
            )}
          </div>
        ) : null}
        {row.admin ? (
          <div>
            <span
              className="glyphicon glyphicon-user admin-icons"
              title="You are the branch admin"
            />
          </div>
        ) : null}
        {row.star ? (
          <div>
            <span
              className="glyphicon glyphicon-star-empty star-icons"
              title="You are assigned to this branch"
            />
          </div>
        ) : null}
      </span>
    );
  }

  resetBranchTable() {
    if (this.state.searchString.length > 0) {
      this.searchBranches();
    } else {
      this.refreshPage();
    }
  }

  handleExportCSV() {
    if (!this.state.searchValid) {
      this.addNotification(
        ErrorMessages.DistributorBranchNavigation_NoBranch,
        "info"
      );
    } else {
      this.setState({ exportBranchesPopup: true });
    }
  }

  handleOnClose() {
    this.resetBranchTable();
  }

  // Mixpanel Tracking //

  trackSearchBranchesFilter(searchString) {
    mixpanel.track("Filter Branches", {
      Action: "Search",
      Effect: "Branch table will be filtered based on search string",
      "Search String": searchString,
    });
  }

  trackBranchButtonClick(branch) {
    mixpanel.track("Branch Select", {
      Action: "Link Click",
      Effect: "Navigate to branch overview page for selected branch",
      "Branch Name": branch,
    });

    mixpanel.track_pageview({
      Page: "Branch Overview Page",
    });
  }

  trackViewAllBranchesCheckbox() {
    mixpanel.track("View All Branches", {
      Action: "Checkbox Click",
      Effect: "Show/Hide All branches for current user",
      Visibility: this.state.showAllBranches,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Branch Table Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}
export default DistributorBranchNavigation;
