import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get estimate summary history list
 * Get estimate summary history item details
 * Change history row status
 * Update estimate summary history notes
 * Get product information
 * Get customer name
 * Add previous Orders to the current cart
 */
const OrderSummaryHistoryHandler = (function () {
  return {
    getEstimateHistory: function () {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEstimateHistoryDetail: function (id) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History/${id}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changeHistoryRowStatus: function (status, id) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History/UpdateStatus?status=${status}&id=${id}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateEstimateHistoryNotes: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History/UpdateNote`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProductInfo: function (data, id) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History/ProductInformation?desc=${data}&id=${id}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCustomerName: function () {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History/CustomerName`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addPreviousOrdersToSummary: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/History/AddPreviousOrdersToSummary`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data.value);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default OrderSummaryHistoryHandler;
