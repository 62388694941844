import Api from "../Utils/api";
/**
 * API Calls to handle:
 * Upload TLD information
 * Upload Branch information
 * Update Branch information
 * Update TLD information
 * Get TLD list
 * Get SalesAgency list
 * Get Sales Rep list
 * Get All Sales Rep list
 * Get CSR list
 * Get branch list
 * Get branch information
 * Get tier lists
 */
const DistributorManagementHandler = (function () {
  return {
    uploadTLDInfo: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(`TLD/TLDUpload`, form)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadBranchInfo: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(`Branch/BranchUpload`, form)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateBranchInfo: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(`Branch/BranchUpdate`, form)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateTLDInfo: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(`TLD/TLDUpdate`, form)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTldList: function () {
      return new Promise((resolve, reject) => {
        Api.get(`TLD/TldList`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSalesAgencyList: function () {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/SalesAgencyList`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSalesRepList: function (data) {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/SalesRepList?salesAgency=${data}`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllSalesRepList: function (data) {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/AllSalesRepList`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCSRList: function () {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/CSRList`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBranchList: function () {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/BranchList`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBranchInfo: function (data) {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/BranchInfo?branchName=${data}`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBranchFreeShippingDetails: function (data) {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/BranchInfoForUser?username=${data}`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTierList: function () {
      return new Promise((resolve, reject) => {
        Api.get(`Branch/ProductTierList`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();
export default DistributorManagementHandler;
