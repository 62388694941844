import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import "./privacyPolicy.css";
import PolicyPopup from "./PolicyPopup";
import fixedLink from "../Assets/fixedAssets.json";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";

const appLogo = fetchResourceData(fixedLink.appLogo);

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: this.props.displayName,
      appLogo: appLogo,
      consent: false,
      showPolicyPopup: false,
      resultData: this.props.resultData,
    };

    this.getConsent = this.getConsent.bind(this);
    this.goToHomePage = this.goToHomePage.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
  }

  componentDidMount() {
    this.setState({
      displayName: this.props.displayName,
      resultData: this.props.resultData,
    });

    this.userActivityTrackingEnabled();
  }

  clearTimeout() {
    if (this.privacyTimeout) clearTimeout(this.privacyTimeout);
  }

  runTimer() {
    this.privacyTimeout = setTimeout(this.refreshPage, 30 * 60 * 1000);
  }

  userActivityTrackingRemoved() {
    this.clearTimeout();

    for (let i in this.props.events) {
      window.removeEventListener(this.props.events[i], this.resetTimeout);
    }
  }

  resetTimeout() {
    this.clearTimeout();
    this.runTimer();
  }

  userActivityTrackingEnabled() {
    for (let i in this.props.events) {
      window.addEventListener(this.props.events[i], this.resetTimeout);
    }
    this.runTimer();
  }

  refreshPage() {
    window.location.reload();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.resultData !== undefined) {
      this.setState({
        displayName: newProps.displayName,
        resultData: newProps.resultData,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="privacy-container">
          <br />
          <br />
          <br />
          <img
            src={this.state.appLogo}
            alt="logo"
            style={{ height: "175px", width: "175px" }}
          />
          <br />
          <Row>
            <h1
              style={{
                fontWeight: "bold",
                textAlign: "center",
                color: "#074391",
              }}
            >
              {DisplayMessages.PrivacyPolicy_Header}
            </h1>
          </Row>
          <Row>
            <h2
              style={{
                textAlign: "center",
                color: "#074391",
              }}
            >
              {DisplayMessages.PrivacyPolicy_SubHeader}
            </h2>
          </Row>
          <br />
          <br />
          <Row>
            <h4 style={{ textAlign: "center", fontWeight: "550" }}>
              {DisplayMessages.PrivacyPolicy_WelcomeText.replace(
                "user",
                this.state.displayName
              )}
            </h4>
            <h4 style={{ textAlign: "center", fontWeight: "550" }}>
              {DisplayMessages.PrivacyPolicy_PortalLine}
              <span
                onClick={this.openPrivacyPolicy}
                style={{
                  color: "#40b9ff",
                  fontWeight: "bolder",
                  cursor: "pointer",
                }}
              >
                <u>privacy policy</u>
              </span>
              .
            </h4>
          </Row>
          <Row>
            <hr
              className="line-separator-privacy"
              style={{ fontWeight: "bolder" }}
            />
          </Row>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md={7} sm={7} xs={7}>
              <Row style={{ display: "flex", alignItems: "center" }}>
                <div className="privacy-round">
                  <input
                    type="checkbox"
                    id="checkbox"
                    onClick={this.getConsent}
                  />
                  <label htmlFor="checkbox" className="round-label" />
                </div>
                <span style={{ fontSize: "14px" }}>
                  {DisplayMessages.PrivacyPolicy_ConfirmText}
                </span>
              </Row>
            </Col>
            <Col md={3} sm={3} xs={3} />
            <Col md={2} sm={2} xs={2}>
              <Button
                className={
                  this.state.consent
                    ? "privacy-next-enabled"
                    : "privacy-next-disabled"
                }
                disabled={!this.state.consent}
                onClick={this.goToHomePage}
              >
                Next
              </Button>
            </Col>
          </Row>
          <br />
          <br />
        </div>
        <PolicyPopup
          show={this.state.showPolicyPopup}
          handleOnClose={this.handlePopupClose}
        />
      </div>
    );
  }

  openPrivacyPolicy() {
    this.trackPrivacyPolicyClick();
    this.setState({
      showPolicyPopup: true,
    });
  }

  getConsent() {
    this.setState(
      (prevState) => ({
        consent: !prevState.consent,
      }),
      this.trackPrivacyPolicyCheckbox
    );
  }

  goToHomePage() {
    let username = encodeURIComponent(sessionStorage.getItem("username"));
    ConfigHandler.completeFirstLogin(username)
      .then((response) => {
        if (response.success) {
          this.userActivityTrackingRemoved();
          this.props.goToHome(
            this.state.resultData,
            sessionStorage.getItem("username")
          );
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.PrivacyPolicy_HomePageNavigation,
          "error"
        );
      });
  }

  handlePopupClose() {
    this.trackOKButtonClick();
    this.setState({
      showPolicyPopup: false,
    });
  }

  // Mixpanel Tracking //

  trackPrivacyPolicyClick() {
    mixpanel.track("Privacy Policy", {
      Action: "Link Click",
      Effect: "Privacy Policy Popup opened",
    });
  }

  trackOKButtonClick() {
    mixpanel.track("Privacy Policy Popup", {
      Action: "Button Click",
      Effect: "Portal Resources Popup closed",
    });
  }

  trackPrivacyPolicyCheckbox() {
    mixpanel.track("Privacy Policy Confirmation", {
      Action: "Button Click",
      Effect: "Accept/Reject privacy policy",
      Accepted: this.state.consent,
    });
  }
}
export default PrivacyPolicy;
