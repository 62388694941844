import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Select from "react-select";
import { ButtonToolbar } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./UserManagement.css";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import UserManagementHandler from "./UserManagementHandler";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import DistributorManagementHandler from "../DistributorManagement/DistributorManagementHandler";
import DistributorsHandler from "../Distributors/DistributorsHandler";

const roleList = [
  { value: "360 Admin", label: "360 Admin" },
  { value: "Internal Salesrep", label: "Internal Salesrep" },
  { value: "Salesrep", label: "Salesrep" },
  { value: "Branch Admin", label: "Branch Admin" },
  { value: "Distributor User", label: "Distributor User" },
];

const yesNoList = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const branchBasedRoles = ["Distributor User", "Branch Admin"];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

/**
 * Component represents the add new user popup in user management sub section
 */
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      selectedRole: "360 Admin",
      selectedAgency: [],
      salesAgencyList: [],
      tldList: [],
      selectedTld: [],
      selectedTldLabel: "",
      orderTrackingLabel: "Yes",
      selectedOrderTracking: "yes",
      pricingAccessLabel: "Yes",
      selectedPricingAccess: "yes",
      tldVisibilityLabel: "Yes",
      selectedTldVisibility: "yes",
      goldenPriceAccessLabel: "No",
      selectedGoldenPriceAccess: "no",
      branchList: [],
      selectedBranch: "",
      selectedBranchLabel: "",
      emailid: "",
      phone: "",
      jobTitle: "",
      currentUser: "",
      allowEdit: true,
      csrMail: "",
    };
    this._notificationSystem = null;
    this.handleOnClose = this.handleOnClose.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.setRole = this.setRole.bind(this);
    this.generateSalesrepFields = this.generateSalesrepFields.bind(this);
    this.generateBranchAdminFields = this.generateBranchAdminFields.bind(this);
    this.setSalesrepVisibility = this.setSalesrepVisibility.bind(this);
    this.setBranchAdminVisibility = this.setBranchAdminVisibility.bind(this);
    this.setDistributorUserVisibility =
      this.setDistributorUserVisibility.bind(this);
    this.generateSalesAgencyList = this.generateSalesAgencyList.bind(this);
    this.generateTLDListForBranchAdmin =
      this.generateTLDListForBranchAdmin.bind(this);
    this.generateTLDListForDistributorUser =
      this.generateTLDListForDistributorUser.bind(this);
    this.setAgency = this.setAgency.bind(this);
    this.reset = this.reset.bind(this);
    this.setTLDForBranchAdmin = this.setTLDForBranchAdmin.bind(this);
    this.setTLDForDistributorUser = this.setTLDForDistributorUser.bind(this);
    this.setPricingAccess = this.setPricingAccess.bind(this);
    this.setOrderTracking = this.setOrderTracking.bind(this);
    this.setTldVisibility = this.setTldVisibility.bind(this);
    this.setGoldenPriceAccess = this.setGoldenPriceAccess.bind(this);
    this.getBranch = this.getBranch.bind(this);
    this.getEmptyBranch = this.getEmptyBranch.bind(this);
    this.setBranch = this.setBranch.bind(this);
    this.setEmailId = this.setEmailId.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.setJobTitle = this.setJobTitle.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.createUser = this.createUser.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    ConfigHandler.getDisplayName()
      .then((response) => {
        if (response.success) {
          this.setState({
            currentUser: response.message,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_CurrentUser, "error");
      });
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleOnClose}
          className="add-user-modal"
        >
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              Add New User
            </h3>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Row>
              <Col md={4}>
                <h4>
                  First Name<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    style={{ color: "black" }}
                    type="text"
                    maxLength={50}
                    value={this.state.firstName}
                    placeholder="Enter First Name"
                    onChange={this.setFirstName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>
                  Last Name<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.lastName}
                    placeholder="Enter Last Name"
                    onChange={this.setLastName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>
                  Email ID<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.emailid}
                    placeholder="Enter Email ID"
                    onChange={this.setEmailId}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>
                  Job Title<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.jobTitle}
                    placeholder="Enter Job Title"
                    onChange={this.setJobTitle}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>Phone Number</h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={20}
                    value={this.state.phone}
                    placeholder="Enter Phone Number"
                    onChange={this.setPhoneNumber}
                  />
                </FormGroup>
              </Col>
            </Row>
            {this.rolesDropdown()}
            {this.generateSalesrepFields()}
            {this.generateBranchAdminFields()}
            {this.generateDistributorUserFields()}

            <br />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <ButtonToolbar>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    style={{ width: "100%" }}
                    disabled={!this.state.allowEdit}
                    onClick={this.createUser}
                    className="button-blue"
                  >
                    Create
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    style={{ width: "100%" }}
                    onClick={this.cancel}
                    className="button-white"
                  >
                    Cancel
                  </Button>
                </Col>
              </ButtonToolbar>
            </Row>
          </Modal.Footer>
        </Modal>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  rolesDropdown() {
    return (
      <Row>
        <Col md={4}>
          <h4>
            Role<mark>*</mark>
          </h4>
        </Col>
        <Col md={6}>
          <Select
            maxMenuHeight={190}
            menuPlacement="auto"
            value={{
              value: this.state.selectedRole,
              label:
                this.state.selectedRole === null ||
                this.state.selectedRole === ""
                  ? "Select..."
                  : this.state.selectedRole,
            }}
            styles={customStyles}
            isSearchable
            onChange={this.setRole}
            options={roleList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "lightgray",
                primary: "lightgray",
              },
            })}
          />
        </Col>
      </Row>
    );
  }

  setJobTitle(e) {
    this.setState({
      jobTitle: e.target.value,
    });
  }

  cancel() {
    this.trackCancelButtonClick();
    this.reset();
    this.handleOnClose();
  }

  createUser() {
    this.verifyUserInformation();

    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.emailid.trim(),

      phoneNumber: this.state.phone,
      role: this.state.selectedRole,

      jobTitle: this.state.jobTitle,
      agency:
        this.state.selectedRole === roleList[2].value
          ? this.state.selectedAgency
          : "",
      tld: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedTld
        : "",
      branch: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedBranch
        : "",
      pricingAccess: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedPricingAccess
        : "",
      orderTracking: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedOrderTracking
        : "",
      tldVisibility: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedTldVisibility
        : "",
      goldenPriceAccess: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedGoldenPriceAccess
        : "",
    };
    UserManagementHandler.addUser(data)
      .then((response) => {
        if (response.success) {
          this.trackUserCreateButtonClick(data);
          this.setState(
            {
              allowEdit: false,
            },
            this.sendMail
          );
        } else {
          this.trackUserCreateFailedClick(response.message);
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.trackUserCreateFailedClick(ErrorMessages.AddUser_FailedToAddUser);
        this.addNotification(ErrorMessages.AddUser_FailedToAddUser, "error");
      });
  }

  verifyUserInformation() {
    let pattern = Miscellaneous.RegexEmailId;
    const re = new RegExp(pattern, "i");

    if (this.state.firstName === null || this.state.firstName.trim() === "") {
      this.trackUserCreateFailedClick(ErrorMessages.AddUser_FirstName);
      this.addNotification(ErrorMessages.AddUser_FirstName, "warning");
      return;
    }
    if (this.state.lastName === null || this.state.lastName.trim() === "") {
      this.trackUserCreateFailedClick(ErrorMessages.AddUser_LastName);
      this.addNotification(ErrorMessages.AddUser_LastName, "warning");
      return;
    }
    if (this.state.emailid === null || this.state.emailid.trim() === "") {
      this.trackUserCreateFailedClick(ErrorMessages.AddUser_Email);
      this.addNotification(ErrorMessages.AddUser_Email, "warning");
      return;
    }
    if (!re.test(this.state.emailid)) {
      this.trackUserCreateFailedClick(ErrorMessages.AddUser_ValidEmail);
      this.addNotification(ErrorMessages.AddUser_ValidEmail, "warning");
      return;
    }
    if (this.state.jobTitle === null || this.state.jobTitle.trim() === "") {
      this.trackUserCreateFailedClick(ErrorMessages.AddUser_JobTitle);
      this.addNotification(ErrorMessages.AddUser_JobTitle, "warning");
      return;
    }
    if (
      (this.state.selectedRole === roleList[3].value ||
        this.state.selectedRole === roleList[4].value) &&
      this.state.selectedBranch === ""
    ) {
      this.trackUserCreateFailedClick(ErrorMessages.AddUser_Branch);
      this.addNotification(ErrorMessages.AddUser_Branch, "warning");
    }
  }

  sendMail() {
    let data = {
      displayName: this.state.firstName + " " + this.state.lastName,
      currentUser: this.state.currentUser,
      emailid: this.state.emailid,
    };

    UserManagementHandler.sendMail(data)
      .then((response) => {
        if (response.success) {
          this.addNotification(DisplayMessages.AddUser_MailSent, "info");
          this.sendCSRMail();
        } else {
          this.addNotification(response.message, "error");
          this.reset();
          this.props.resetUserTable();
        }
      })
      .catch((error) => {
        this.addNotification(error.message, "error");
        this.reset();
      });
  }

  sendCSRMail() {
    if (
      this.state.selectedRole === branchBasedRoles[0] &&
      this.state.csrMail !== ""
    ) {
      let csrData = {
        mailType: "csr",
        isCreatedByAdmin: true,
        branchName: this.state.selectedBranchLabel,
        branchAdminName: this.state.currentUser,
        branchAdminEmail: sessionStorage.getItem("username"),
        CSREmail: this.state.csrMail,
        distributorName: this.state.firstName + " " + this.state.lastName,
        distributorEmail: this.state.emailid,
        jobTitle: this.state.jobTitle,
        phoneNumber: this.state.phone.length === 0 ? "NA" : this.state.phone,
        pricingAccess: this.state.pricingAccessLabel,
        orderTracking: this.state.orderTrackingLabel,
        tldVisibility: this.state.tldVisibilityLabel,
      };

      DistributorsHandler.sendMail(csrData).then((response) => {
        if (response.success) {
          this.addNotification(
            DisplayMessages.AddDistributorUser_CsrNotified,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
      });
    }

    this.reset();
    this.props.resetUserTable();
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  setEmailId(e) {
    this.setState({
      emailid: e.target.value,
    });
  }

  setPhoneNumber(e) {
    let phone = e.target.value;
    this.setState({
      phone: phone,
    });
  }

  generateSalesrepFields() {
    return (
      <div className={this.setSalesrepVisibility()}>
        <Row>
          <Col md={4}>
            <h4>
              Sales Agency<mark>*</mark>
            </h4>
          </Col>
          <Col md={6}>
            <Select
              maxMenuHeight={190}
              menuPlacement="auto"
              value={{
                value: this.state.selectedAgency,
                label:
                  this.state.selectedAgency === null ||
                  this.state.selectedAgency.length <= 0
                    ? "Select..."
                    : this.state.selectedAgency,
              }}
              styles={customStyles}
              isSearchable
              onChange={this.setAgency}
              options={this.state.salesAgencyList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
        </Row>
      </div>
    );
  }

  getBranch(tld) {
    UserManagementHandler.getBranch(tld)
      .then((response) => {
        if (response.data.length === 0) {
          this.addNotification(ErrorMessages.AddUser_NoBranch, "warning");
        }
        this.setState({
          branchList: response.data.map((item) => ({
            value: item.BranchId,
            label: item.BranchName,
            mail: item.CSRMail,
          })),
          selectedBranch:
            response.data[0] !== undefined ? response.data[0].BranchId : "",
          selectedBranchLabel:
            response.data[0] !== undefined ? response.data[0].BranchName : "",
          csrMail:
            response.data[0] !== undefined ? response.data[0].CSRMail : "",
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_GetBranch, "error");
      });
  }

  getEmptyBranch(tld) {
    UserManagementHandler.getEmptyBranch(tld)
      .then((response) => {
        if (response.data.length === 0) {
          this.addNotification(ErrorMessages.AddUser_NoBranch, "warning");
        }
        this.setState({
          branchList: response.data.map((item) => ({
            value: item.BranchId,
            label: item.BranchName,
          })),
          selectedBranch:
            response.data[0] !== undefined ? response.data[0].BranchId : "",
          selectedBranchLabel:
            response.data[0] !== undefined ? response.data[0].BranchName : "",
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_GetBranch, "error");
      });
  }

  setTLDForDistributorUser(event) {
    if (event == null) {
      this.setState({
        selectedTld: "",
        selectedTldLabel: "",
      });
    } else {
      this.getBranch(event.value);
      this.setState({
        selectedTldLabel: event.label,
        selectedTld: event.value,
      });
    }
  }

  setTLDForBranchAdmin(event) {
    if (event == null) {
      this.getEmptyBranch("");
      this.setState({
        selectedTld: "",
        selectedTldLabel: "",
      });
    } else {
      this.getEmptyBranch(event.value);
      this.setState({
        selectedTldLabel: event.label,
        selectedTld: event.value,
      });
    }
  }

  setAgency(event) {
    if (event == null) {
      this.setState({
        selectedAgency: "",
      });
    } else {
      this.setState({
        selectedAgency: event.value,
      });
    }
  }

  setBranch(event) {
    if (event == null) {
      this.setState({
        selectedBranch: "",
        selectedBranchLabel: "",
        csrMail: "",
      });
    } else {
      this.setState({
        selectedBranch: event.value,
        selectedBranchLabel: event.label,
        csrMail: event.mail !== undefined ? event.mail : "",
      });
    }
  }

  generateBranchAdminFields() {
    return (
      <div className={this.setBranchAdminVisibility()}>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Select TLD<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.branchAdminTLDDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Select Branch<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.branchAdminBranchDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              TLD Visibility<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.tldVisibilityDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Golden Price Access<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.goldenPriceAccessDropdown()}
          </Col>
        </Row>
      </div>
    );
  }

  generateDistributorUserFields() {
    return (
      <div className={this.setDistributorUserVisibility()}>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Select TLD<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.distributorUserTLDDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Select Branch<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.distributorUserBranchDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Pricing Access<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.pricingAccessDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Order Track Access<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.orderTrackingDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              TLD Visibility<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.tldVisibilityDropdown()}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>
              Golden Price Access<mark>*</mark>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {this.goldenPriceAccessDropdown()}
          </Col>
        </Row>
      </div>
    );
  }

  branchAdminTLDDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedTldLabel,
          label:
            this.state.selectedTldLabel === null ||
            this.state.selectedTldLabel === ""
              ? "Select..."
              : this.state.selectedTldLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setTLDForBranchAdmin}
        options={this.state.tldList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  distributorUserTLDDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedTldLabel,
          label:
            this.state.selectedTldLabel === null ||
            this.state.selectedTldLabel === ""
              ? "Select..."
              : this.state.selectedTldLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setTLDForDistributorUser}
        options={this.state.tldList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgrey",
            primary: "lightgrey",
          },
        })}
      />
    );
  }

  branchAdminBranchDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedBranchLabel,
          label:
            this.state.selectedBranchLabel === null ||
            this.state.selectedBranchLabel === ""
              ? "Select..."
              : this.state.selectedBranchLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setBranch}
        options={this.state.branchList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  distributorUserBranchDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedBranchLabel,
          label:
            this.state.selectedBranchLabel === null ||
            this.state.selectedBranchLabel === ""
              ? "Select..."
              : this.state.selectedBranchLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setBranch}
        options={this.state.branchList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgrey",
            primary: "lightgrey",
          },
        })}
      />
    );
  }

  orderTrackingDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.orderTrackingLabel,
          label:
            this.state.orderTrackingLabel === null ||
            this.state.orderTrackingLabel === ""
              ? "Select..."
              : this.state.orderTrackingLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setOrderTracking}
        options={yesNoList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgrey",
            primary: "lightgrey",
          },
        })}
      />
    );
  }

  pricingAccessDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.pricingAccessLabel,
          label:
            this.state.pricingAccessLabel === null ||
            this.state.pricingAccessLabel === ""
              ? "Select..."
              : this.state.pricingAccessLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setPricingAccess}
        options={yesNoList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgrey",
            primary: "lightgrey",
          },
        })}
      />
    );
  }

  tldVisibilityDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.tldVisibilityLabel,
          label:
            this.state.tldVisibilityLabel === null ||
            this.state.tldVisibilityLabel === ""
              ? "Select..."
              : this.state.tldVisibilityLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setTldVisibility}
        options={yesNoList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  goldenPriceAccessDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.goldenPriceAccessLabel,
          label:
            this.state.goldenPriceAccessLabel === null ||
            this.state.goldenPriceAccessLabel === ""
              ? "Select..."
              : this.state.goldenPriceAccessLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setGoldenPriceAccess}
        options={yesNoList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  setPricingAccess(event) {
    this.setState({
      selectedPricingAccess: event.value,
      pricingAccessLabel: event.label,
    });
  }

  setOrderTracking(event) {
    this.setState({
      selectedOrderTracking: event.value,
      orderTrackingLabel: event.label,
    });
  }

  setTldVisibility(event) {
    this.setState({
      selectedTldVisibility: event.value,
      tldVisibilityLabel: event.label,
    });
  }

  setGoldenPriceAccess(event) {
    this.setState({
      selectedGoldenPriceAccess: event.value,
      goldenPriceAccessLabel: event.label,
    });
  }

  setSalesrepVisibility() {
    if (
      this.state.selectedRole !== undefined &&
      this.state.selectedRole !== roleList[2].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  setBranchAdminVisibility() {
    if (
      this.state.selectedRole !== undefined &&
      this.state.selectedRole !== roleList[3].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  setDistributorUserVisibility() {
    if (
      this.state.selectedRole !== undefined &&
      this.state.selectedRole !== roleList[4].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  setRole(event) {
    switch (event.value) {
      case roleList[2].value:
        this.generateSalesAgencyList();
        break;
      case roleList[3].value:
        this.generateTLDListForBranchAdmin();
        break;

      case roleList[4].value:
        this.generateTLDListForDistributorUser();
        break;

      default:
        break;
    }

    this.setState({
      selectedRole: event.value,
    });
  }

  generateTLDListForBranchAdmin() {
    DistributorManagementHandler.getTldList()
      .then((response) => {
        this.setState(
          {
            tldList: response.data.map((item) => ({
              value: item.Id,
              label: item.TldName,
            })),
            selectedTld: response.data[0].Id,
            selectedTldLabel: response.data[0].TldName,
          },
          () => this.getEmptyBranch(this.state.selectedTld)
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_TldList, "error");
      });
  }

  generateTLDListForDistributorUser() {
    DistributorManagementHandler.getTldList()
      .then((response) => {
        this.setState(
          {
            tldList: response.data.map((item) => ({
              value: item.Id,
              label: item.TldName,
            })),
            selectedTld: response.data[0].Id,
            selectedTldLabel: response.data[0].TldName,
          },
          () => this.getBranch(this.state.selectedTld)
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_TldList, "error");
      });
  }

  generateSalesAgencyList() {
    DistributorManagementHandler.getSalesAgencyList()
      .then((response) => {
        this.setState({
          salesAgencyList: response.data.map((item) => ({
            value: item.Name,
            label: item.Name,
          })),

          selectedAgency: response.data[0].Name,
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_SalesAgencyList, "error");
      });
  }

  reset() {
    this.setState({
      firstName: "",
      lastName: "",
      selectedRole: "360 Admin",
      selectedAgency: [],
      salesAgencyList: [],
      tldList: [],
      selectedTld: [],
      selectedTldLabel: "",
      selectedTldVisibility: "yes",
      tldVisibilityLabel: "Yes",
      branchList: [],
      selectedBranch: "",
      selectedBranchLabel: "",
      emailid: "",
      phone: "",
      jobTitle: "",
      allowEdit: true,
    });
  }

  handleOnClose() {
    this.reset();
    this.props.handleOnClose();
  }

  setFirstName(e) {
    let firstName = e.target.value;
    firstName = firstName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState({
      firstName: firstName,
    });
  }

  setLastName(e) {
    let lastName = e.target.value;
    lastName = lastName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState({
      lastName: lastName,
    });
  }

  // Mixpanel Tracking //

  trackUserCreateButtonClick(data) {
    mixpanel.track("Create User Confirmation", {
      Action: "Button Click",
      Effect: "New user created",
      "First Name": data.firstName,
      "Last Name": data.lastName,
      Email: data.email,
      "Phone Number": data.phoneNumber,
      "Job Role": data.role,
      "Job Title": data.jobTitle,
      "Sales Agency": data.agency,
      TLD: data.tld,
      Branch: data.branch,
      "TLD Visibility": data.tldVisibility,
      "Golden Price Access": data.goldenPriceAccess,
    });
  }

  trackUserCreateFailedClick(message) {
    mixpanel.track("Create User", {
      Action: "Button Click",
      Effect: "New user creation failed",
      Message: message,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Create User Cancellation", {
      Action: "Button Click",
      Effect: "New user creation cancelled",
    });
  }
}
export default AddUser;
