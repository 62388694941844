import Api from "../Utils/api.js";
import Plugin from "../Utils/plugin.js";

/**
 * API Calls to handle:
 * Get products to compare
 * Get featured images for products
 * Get certification stickers
 * Export comparison results
 * Pin/Favourite comparison results
 */
const CompareDataHandler = (function () {
  return {
    getProductsToCompare: function (filters) {
      return new Promise((resolve, reject) => {
        let route = "CompareWizard/CompareResults";
        route += filters.spaceId ? `?spaceId=${filters.spaceId}` : ``;
        route += filters.applicationId
          ? `&applicationId=${filters.applicationId}`
          : ``;
        route += filters.lightSourceId
          ? `&lightSourceId=${filters.lightSourceId}`
          : ``;
        route += filters.lightSourceWatt
          ? `&lightSourceWatt=${filters.lightSourceWatt}`
          : ``;
        route += filters.lumen ? `&lumen=${filters.lumen}` : ``;
        route += filters.wattage ? `&wattage=${filters.wattage}` : ``;
        Api.get(route)
          .then((response) => {
            let resolveObj = {};
            resolveObj.productList = response.data.data.list.map(
              (item) => item
            );
            resolveObj.legends = response.data.data.legends.map((item) => item);
            resolveObj.noProducts = response.data.data.noProducts;
            resolveObj.success = response.data.success;
            resolve(resolveObj);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFeaturedImage: function (product) {
      return new Promise((resolve, reject) => {
        const route =
          "products/" + product.post_id + "/image/" + product.photo_id;
        Plugin.get(route)
          .then((response) => {
            product.featured_src = response.data.data.image;
            resolve(product);
          })
          .catch((error) => {
            product.featured_src = "";
            resolve(product);
          });
      });
    },
    getCertificateStickers: function (product) {
      return new Promise((resolve, reject) => {
        product.certificate = product.certificate.split(",");
        if (product.warranty === "5 Years") {
          //Hard coded
          product.certificate.push(Api.getBaseURL() + "/images/5Year.png");
        }
        resolve(product);
      });
    },
    exportCompareWizard: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Export/CompareWizard`;
        Api.post_doc(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    pinAsFavourite: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Favourite/AddToFavourites`;
        Api.post(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default CompareDataHandler;
