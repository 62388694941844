import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get branch details
 * Get users in branch
 * Change branch admin
 * Get branch tier
 * Add Salesrep
 * Remove Salesrep
 */
const BranchOverviewHandler = (function () {
  return {
    getBranchDetails: function (branchId) {
      return new Promise((resolve, reject) => {
        const route = `Branch/BranchDetails?branchId=${branchId}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getUsers: function (branchId, tld, salesAgency) {
      return new Promise((resolve, reject) => {
        const route = `Branch/UserList?branchId=${branchId}&tld=${tld}&salesAgency=${salesAgency}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    changeAdmin: function (oldAdmin, newAdmin, branchId) {
      return new Promise((resolve, reject) => {
        const route = `Branch/ChangeBranchAdmin?oldAdmin=${oldAdmin}&newAdmin=${newAdmin}&branchId=${branchId}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBranchTier: function () {
      return new Promise((resolve, reject) => {
        const route = `Branch/BranchTier`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    addSalesrep: function (branchId) {
      return new Promise((resolve, reject) => {
        const route = `Branch/AddSalesrep?branchId=${branchId}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    removeSalesrep: function (branchId) {
      return new Promise((resolve, reject) => {
        const route = `Branch/RemoveSalesrep?branchId=${branchId}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default BranchOverviewHandler;
