import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Creatable from "react-select/creatable";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Label } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler.js";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp.js";
import "../Disclaimer/disclaimer.css";
import TableauPopup from "../Tableau/TableauPopup.js";
import TableauDataHandler from "../Tableau/TableauDataHandler.js";
import "./clearance.css";
import ClearanceHandler from "./ClearanceHandler.js";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
  Disclaimers,
  fetchJSONFileData,
} from "../Utils/FetchConfigurableData.js";
import fixedLink from "../Assets/fixedAssets.json";
import BranchOverviewHandler from "../Distributors/BranchOverviewHandler.js";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup.js";
import ConfigHandler from "../ConfigHandler/ConfigHandler.js";
import { CopyToClipboard } from "../Utils/CopyToClipboard.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const priceType = ["Price1", "Price2"];
const priceString = "Price";
const sessionCustomer = "customerName";
const branchBasedRoles = ["Distributor User", "Branch Admin"];

let customerName = "";
let customerTLD = "";
let filter = {};

/**
 * Component represents the popup containing the clearance product details
 */
class ClearanceProductPopup extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: "",
      productConfig: "",
      selectedCustomer: "",
      productType: "led",
      customerList: null,
      tierPricing: "Price1",
      tierSelected: "",
      customerTier: "Tier1",
      showConfirmationPopup: false,
      selectedItem: [],
      selected_item_id: "",
      current_tableau_link: "",
      isNewCustomer: false,
      singleCustomer: false,
      customerTLD: "",
      branchName: "",
      selectedCategory: "",
      goldenPriceAccess: true,
      tierList: Miscellaneous.TiersList,
      showSpecLinkPopup: false,
      specLinkPopupStockCode: "",
      specLinkPopupDescription: "",
      specValue: "default",
      engSpecLink: "",
      frSpecLink: "",
      itemCategory: "accessories",
    };
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.handleSingleCustomerSelect =
      this.handleSingleCustomerSelect.bind(this);
    this.getCustomerList = this.getCustomerList.bind(this);
    this.getCustomers = this.getCustomers.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.addItem = this.addItem.bind(this);
    this.copyItem = this.copyItem.bind(this);
    this.addToOrderSummary = this.addToOrderSummary.bind(this);
    this.getDashBoard = this.getDashBoard.bind(this);
    this.handleClearCloseDashboard = this.handleClearCloseDashboard.bind(this);
    this.handleTierSelect = this.handleTierSelect.bind(this);
    this.handleProducts = this.handleProducts.bind(this);
    this.goldenLegend = this.goldenLegend.bind(this);
    this.onProductQuantityChange = this.onProductQuantityChange.bind(this);
    this.onProductQuantityBlur = this.onProductQuantityBlur.bind(this);
    this.showSpecLink = this.showSpecLink.bind(this);
    this.handleOnPopupClose = this.handleOnPopupClose.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await fetchJSONFileData(fixedLink.displayMessagesPath);
    customerName =
      sessionStorage.getItem(sessionCustomer) !== null
        ? sessionStorage.getItem(sessionCustomer)
        : "";

    let role = sessionStorage.getItem("userRole");

    if (branchBasedRoles.includes(role) && this._isMounted) {
      BranchOverviewHandler.getBranchTier()
        .then((response) => {
          if (response.success && this._isMounted) {
            this.setState({
              singleCustomer: true,
              branchName: response.data.BranchName,
              selectedCustomer: response.data.BranchName,
              goldenPriceAccess: JSON.parse(
                sessionStorage.getItem("goldenPriceAccess")
              ),
            });
          }
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.ProductConfig_BranchTier, "error");
        });
    } else if (this._isMounted) {
      this.setState({
        tierList: Miscellaneous.TiersList,
      });
    }
    this.getCustomers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      (prevState) => ({
        selectedProduct: nextProps.selectedProduct,
        productConfig: nextProps.productConfig,
        selectedCustomer: prevState.singleCustomer
          ? prevState.selectedCustomer
          : nextProps.selectedCustomer,
        selectedCategory: nextProps.selectedCategory,
        productType:
          nextProps.productConfig.length === 0
            ? prevState.productType
            : nextProps.productConfig[0].type,
        tierList: Miscellaneous.TiersList,
      }),
      () => {
        this.setFilter();
        this.handleProducts();
      }
    );
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleOnClose}
          className="clearance-modal"
        >
          <Modal.Header closeButton>
            <h1 style={{ fontWeight: "bolder" }}>
              {this.state.selectedProduct.title}
            </h1>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Row>
              <Col md={6} style={{ marginTop: "2%" }}>
                <div
                  className="product-short-description"
                  dangerouslySetInnerHTML={{
                    __html: this.state.selectedProduct.short_description,
                  }}
                />
              </Col>
              <Col md={6}>
                <LinkContainer
                  to={{ pathname: "/Product", state: filter }}
                  onClick={this.trackProductImageClick}
                >
                  <img
                    src={this.state.selectedProduct.featured_src}
                    alt="product img"
                    width="200px"
                    height="200px"
                    className="raise-image"
                    style={{ cursor: "pointer" }}
                  />
                </LinkContainer>
              </Col>
            </Row>
            {this.state.productConfig &&
            this.state.productConfig.filter((item) => item.price1 !== "-1")
              .length > 0 ? (
              <div>
                <Row>{this.getCustomerList()}</Row>
                {this.clearanceTableHeader()}
                {this.clearanceTableBody()}
                {this.goldenLegend()}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Row style={{ marginTop: "0px", marginBottom: "0px" }}>
              {this.clearanceDisclaimer()}
            </Row>
          </Modal.Footer>
        </Modal>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
        <ConfirmationPopUp
          show={this.state.showConfirmationPopup}
          title="Clear Estimate Summary!"
          text={this.state.message}
          clearTableData={this.clearTableData.bind(this)}
          handleClearClose={this.handleClearClose.bind(this)}
        />
        <TableauPopup
          show={this.state.selected_item_id}
          handleClearClose={this.handleClearCloseDashboard}
          tableau_link={this.state.current_tableau_link}
        />
      </div>
    );
  }

  clearanceTableHeader() {
    return (
      <Row className="clearance-popup-header">
        <Col md={1} sm={6} xs={6} className="clearance-row-cell">
          Stock Code
        </Col>
        <Col md={2} sm={6} xs={6} className="clearance-row-cell">
          Description
        </Col>
        <Col md={1} sm={6} xs={6} className="clearance-row-cell">
          Specs
        </Col>
        <Col md={3} sm={6} xs={6} className="clearance-row-cell">
          Short Description
        </Col>
        <Col md={1} sm={6} xs={6} className="clearance-row-cell">
          Qty
        </Col>
        <Col md={1} sm={6} xs={6} className="clearance-row-cell">
          Total Price
        </Col>
        <Col md={2} sm={6} xs={6} className="clearance-row-cell">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Estimated Ship Time</span>
            <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
          </div>
        </Col>
        <Col md={1} sm={6} xs={6} className="clearance-row-cell">
          Add to Cart
        </Col>
      </Row>
    );
  }

  clearanceTableBody() {
    return (
      <div
        className="scrollable-div clearance-popup-body"
        style={{ maxHeight: "300px" }}
      >
        {this.state.productConfig?.map((element, index) => {
          return (
            <Row
              className="clearance-popup text-center"
              key={element.stockCode + "#" + element.description}
            >
              <Col md={1} sm={6} xs={6} className="clearance-row-cell">
                {element.stockCode}
              </Col>
              <Col md={2} sm={6} xs={6} className="clearance-row-cell">
                {element.description}
              </Col>
              <Col
                md={1}
                sm={6}
                xs={6}
                className="clearance-row-cell"
                onClick={() => this.showSpecLink(element)}
                style={{ position: "relative" }}
              >
                <SpecTooltipPopup
                  specValue={element.specValue}
                  productId={element.productId}
                  stockCodeTitle={element.stockCode}
                  descriptionTitle={element.description}
                  engSpecLink={this.state.engSpecLink}
                  frSpecLink={this.state.frSpecLink}
                  addNotification={this.addNotification}
                  isNavigationEnabled={this.state.itemCategory === "base"}
                  onModal={true}
                />
              </Col>

              <Col md={3} sm={6} xs={6} className="clearance-row-cell">
                {element.shortDescription}
              </Col>
              <Col md={1} sm={6} xs={6} className="clearance-row-cell">
                <FormControl
                  type="number"
                  min="1"
                  max="10000"
                  pattern="[0-9]*"
                  style={{ width: "100%" }}
                  value={element.quantity}
                  onChange={(e) => this.onProductQuantityChange(e, element)}
                  onBlur={(e) => this.onProductQuantityBlur(e, element)}
                  aria-label="Quantity"
                />
              </Col>
              <Col md={1} sm={6} xs={6} className="clearance-row-cell">
                {this.clearancePrice(element, false)}
              </Col>
              <Col md={2} sm={6} xs={6} className="clearance-row-cell">
                {element.leadTime}
              </Col>
              <Col
                md={1}
                sm={6}
                xs={6}
                className="clearance-row-cell copy-or-add"
              >
                <Button
                  onClick={() => this.copyItem(element)}
                  className="glyphicon glyphicon-duplicate"
                  aria-label="Copy to clipboard"
                />
                <Button
                  onClick={() => this.addItem(element)}
                  className="glyphicon glyphicon-plus"
                  disabled={
                    element.price1.toString() === "0" &&
                    element.price2.toString() === "0"
                  }
                />
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }

  getSpecLinks(description, pid) {
    let element = {
      description: description,
      productId: pid.toString(),
    };
    ConfigHandler.getSpecLinks(element)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            specValue: data.Specs,
            itemCategory: data.Category,
          });
        } else {
          this.setState({
            specValue: "N/A",
            itemCategory: element.productId !== "NA" ? "base" : "accessories",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOnPopupClose() {
    this.setState({
      showSpecLinkPopup: false,
    });
  }

  showSpecLink(element) {
    let specLinkPopupStockCode = element.stockCode;
    let specLinkPopupDescription = element.description;
    let productId = element.productId;
    let specValue = element.specValue;

    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  onProductQuantityChange(e, element) {
    let stockCode = element.stockCode;
    let qty = e.target.validity.valid ? e.target.value : element.quantity;
    qty = parseInt(qty);
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    this.setState((prevState) => ({
      productConfig: prevState.productConfig.map((item) =>
        item.stockCode === stockCode
          ? { ...item, quantity: qty, leadTime: shipTime }
          : item
      ),
    }));
  }

  onProductQuantityBlur(e, element) {
    let stockCode = element.stockCode;
    let qty = e.target.value === "" ? 1 : e.target.value;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    this.setState((prevState) => ({
      productConfig: prevState.productConfig.map((item) =>
        item.stockCode === stockCode
          ? { ...item, quantity: qty, leadTime: shipTime }
          : item
      ),
    }));
  }

  clearancePrice(element, isCopyToClipboard) {
    let qty = isNaN(element.quantity)
      ? 1
      : +parseFloat(element.quantity).toFixed(2);

    let salePrice = +parseFloat(element.salePrice) * qty;
    let goldenPrice = +parseFloat(element.goldenPrice) * qty;
    let price1 = +parseFloat(element.price1) * qty;
    let price2 = +parseFloat(element.price2) * qty;

    return isCopyToClipboard
      ? this.getClearancePrice(salePrice, goldenPrice, price1, price2)
      : this.showClearancePrice(salePrice, goldenPrice, price1, price2);
  }

  showClearancePrice(salePrice, goldenPrice, price1, price2) {
    if (salePrice === 0) {
      return (
        <div>
          {goldenPrice === 0 ? (
            this.validateTierPrice(price1, price2)
          ) : (
            <span className="gold-pricing">{goldenPrice.toFixed(2)}</span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {goldenPrice === 0 || goldenPrice > salePrice ? (
            <div>
              <div
                style={{
                  textDecorationLine: "line-through",
                }}
              >
                {this.state.tierPricing === priceType[0]
                  ? "$ " + price1.toFixed(2)
                  : "$ " + price2.toFixed(2)}
              </div>
              <div style={{ color: "red" }}>{salePrice.toFixed(2)}</div>
            </div>
          ) : (
            <span className="gold-pricing">{goldenPrice.toFixed(2)}</span>
          )}
        </div>
      );
    }
  }

  getClearancePrice(salePrice, goldenPrice, price1, price2) {
    if (salePrice === 0) {
      return goldenPrice === 0
        ? this.validateTierPrice(price1, price2)
        : goldenPrice.toFixed(2);
    } else {
      return goldenPrice === 0 || goldenPrice > salePrice
        ? salePrice.toFixed(2)
        : goldenPrice.toFixed(2);
    }
  }

  validateTierPrice(price1, price2) {
    return this.state.tierPricing === priceType[0]
      ? price1.toFixed(2)
      : price2.toFixed(2);
  }

  goldenLegend() {
    let goldenPresent = false;

    this.state.productConfig.forEach((item) => {
      if (
        (parseFloat(item.salePrice) === 0 &&
          parseFloat(item.goldenPrice) !== 0) ||
        (parseFloat(item.goldenPrice) !== 0 &&
          parseFloat(item.goldenPrice) <= parseFloat(item.salePrice))
      ) {
        goldenPresent = true;
      }
    });

    if (goldenPresent && this.state.goldenPriceAccess) {
      return (
        <div>
          <br />
          <Row>
            <span className="gold-pricing">
              {DisplayMessages.GoldenPriceLine1}
            </span>
            {DisplayMessages.GoldenPriceLine2}
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  clearanceDisclaimer() {
    return (
      <div>
        {this.props.invLink !== null && this.props.invLink.id !== "" ? (
          <div
            className="disclaimer"
            style={{
              marginTop: "0px",
              textAlign: "left",
            }}
          >
            {Disclaimers.Clearance_DisclaimerMainText}
            <Label className="mimic-link" onClick={() => this.getDashBoard()}>
              {Miscellaneous.InventoryLookupName}
            </Label>
            {Disclaimers.Clearance_DisclaimerSubText}
          </div>
        ) : null}
      </div>
    );
  }

  handleProducts() {
    if (this.state.selectedProduct) {
      ClearanceHandler.getConfigurations(
        this.state.selectedProduct.id,
        encodeURIComponent(this.state.selectedCategory),
        encodeURIComponent(this.state.selectedCustomer)
      )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            if (this.state.goldenPriceAccess) {
              this.setState({
                productConfig: response.data,
                productType: response.data[0].Type,
              });
            } else {
              this.setState({
                productConfig: response.data.map((item) => {
                  return {
                    ...item,
                    goldenPrice: "0",
                  };
                }),
                productType: response.data[0].Type,
              });
            }
          }
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.Clearance_ConfigurationError,
            "error"
          );
        });
    }
  }

  setFilter() {
    filter = {
      postId: this.state.selectedProduct.id,
      applicationType: this.props.applicationType,
      redirection: true,
    };
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  handleClearCloseDashboard() {
    this.setState({
      selected_item_id: "",
      current_tableau_link: "",
    });
  }

  getDashBoard() {
    this.setState({ selected_item_id: Miscellaneous.InventoryLookupName });
    let data = {
      rabLink: Miscellaneous.InventoryLookupName,
      id: this.props.invLink.id,
      type: this.props.invLink.type,
    };
    this.trackTableauDashboardClick(data);
    TableauDataHandler.getTableauDashboard(data)
      .then((response) => {
        if (response.success) {
          this.setState({
            current_tableau_link:
              Miscellaneous.TrustedTableauLink +
              response.data.token +
              "/" +
              response.data.dashboard,
          });
        } else {
          this.setState({
            current_tableau_link: Miscellaneous.DefaultTableauLink,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.Clearance_TableauDashboard, "error");
      });
  }

  clearTableData() {
    ProductConfiguratorHandler.clearOrderSummary()
      .then((response) => {
        if (
          response.success &&
          this.state.selectedItem.salePrice.toString() !== "0"
        ) {
          this.addToOrderSummary();
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UnableToClear, "error");
      });
    this.setState({ showConfirmationPopup: false });
  }

  handleClearClose() {
    this.setState({ showConfirmationPopup: false });
  }

  copyItem(element) {
    let totalPrice = this.clearancePrice(element, true);

    let data = {
      description: element.description,
      productId: element.productId.toString(),
    };

    let copyContent = {
      Stock_Code: element.stockCode,
      SKU_Description: element.description,
      Quantity: parseInt(element.quantity, 10).toString(),
      Total_Price: totalPrice,
      Availability: element.leadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(data, copyContent, this.addNotification, "Clearance Popup");
  }

  addItem(item) {
    this.setState(
      {
        selectedItem: item,
      },
      () => {
        if (this.state.selectedItem.salePrice.toString() !== "0") {
          this.addToOrderSummary();
        }
      }
    );
  }

  customerValidityCheck() {
    if (
      this.state.selectedCustomer === null ||
      this.state.selectedCustomer === "" ||
      this.state.selectedCustomer === " "
    ) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameMissing);
      this.addNotification(ErrorMessages.CustomerNameMissing, "warning");
      return false;
    }
    if (this.state.selectedCustomer.length > 50) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameLength);
      this.addNotification(ErrorMessages.CustomerNameLength, "warning");
      return false;
    }
    if (
      this.state.selectedItem.quantity < 1 ||
      this.state.selectedItem.quantity > 10000
    ) {
      this.trackAddToCartButtonClick(
        ErrorMessages.ProductConfig_QuantityOutOfRange
      );
      this.addNotification(
        ErrorMessages.ProductConfig_QuantityOutOfRange,
        "warning"
      );
      return false;
    }

    return true;
  }

  addToOrderSummary() {
    let isCustomerValid = this.customerValidityCheck();

    if (!isCustomerValid) {
      return;
    }

    let item = [];
    item.push({
      Description: this.state.selectedItem.description,
      Price1: this.state.selectedItem.price1,
      Price2: this.state.selectedItem.price2,
      SetId: this.state.selectedItem.setId,
      itemType: this.state.selectedItem.type,
      SalePrice: this.state.selectedItem.salePrice,
      Quantity: this.state.selectedItem.quantity,
    });

    let data = {
      productId: this.state.selectedProduct.id.toString(),
      title: this.state.selectedProduct.title,
      action: "AddtoSummary",
      setId: "",
      customer_name:
        customerTLD !== ""
          ? customerTLD
          : this.ternaryEvaluation(
              this.state.selectedCustomer,
              null,
              "",
              this.state.selectedCustomer
            ),
      orderlist: {
        product_list: [],
        constructionOrAccessory_list: [],
        quickShip_list: item,
        goldenItemsList: [],
      },
    };
    ProductConfiguratorHandler.addToOrderSummary(data)
      .then((response) => {
        if (!response.success && response.message === "Failed") {
          this.trackAddToCartButtonClick(ErrorMessages.UnableToAddItems);
          this.addNotification(ErrorMessages.UnableToAddItems, "error");
        } else if (!response.success && response.message === "Limit") {
          this.trackAddToCartButtonClick(ErrorMessages.CartLimit);
          this.addNotification(ErrorMessages.CartLimit, "warning");
        } else if (!response.success) {
          this.trackAddToCartButtonClick(response.message);

          this.setState({
            showConfirmationPopup: true,
            message: response.message,
          });
        } else if (response.success) {
          this.trackAddToCartButtonSuccess(
            this.state.selectedItem.description,
            this.state.selectedItem.stockCode
          );
          this.addNotification(DisplayMessages.Clearance_ProductAdded, "info");
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UnableToAddToCart, "error");
      });
  }

  getCustomers() {
    ProductConfiguratorHandler.getCustomerList()
      .then((response) => {
        if (response.success && this._isMounted) {
          let customer = response.data.customers.list.find(
            (item) => item.CustomerName === customerName
          );

          let price =
            response.data.customers.tier === this.state.tierList[0] ||
            customerName === ""
              ? priceType[0]
              : priceType[1];
          this.setState(
            {
              last_updated: response.data.lastUpdatedTime,
              selectedCustomer: customerName,
              isNewCustomer: customer === undefined && customerName !== "",
              tierSelected: this.setTierSelected(response),
              customerTier: this.setCustomerTier(customer),
              tierPricing: this.setTierPricing(customer, price),
              ledTier: this.setLedTier(customer, price),
              constructionTier: this.setConstructionTier(customer, price),
              customerList: response.data.customers.list.map((item) => ({
                value:
                  item.CustomerName +
                  "-tier-" +
                  item.Tier +
                  "-tier-" +
                  item.ConstructionTier,
                label: item.CustomerName,
              })),
              customerTLD: this.setCustomerTLD(response),
            },
            () => {
              if (
                this.state.singleCustomer &&
                this.state.customerList.length > 0
              ) {
                customerName = this.state.customerTLD;
                this.handleSingleCustomerSelect(
                  this.state.customerTLD,
                  this.state.customerList[0]
                );
              }
            }
          );
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.CustomerListMissing, "error");
      });
  }

  setTierSelected(response) {
    return customerName !== ""
      ? response.data.customers.tier
      : this.state.tierList[0];
  }

  setCustomerTier(customer) {
    return customer === undefined || customer.Tier === "1"
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          customer.ConstructionTier,
          "1",
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  setTierPricing(customer, price) {
    return customer === undefined
      ? price
      : this.ternaryEvaluation(
          this.state.productType,
          "led",
          priceString + customer.Tier,
          priceString + customer.ConstructionTier
        );
  }

  setLedTier(customer, price) {
    return customer === undefined ? price : priceString + customer.Tier;
  }

  setConstructionTier(customer, price) {
    return customer === undefined
      ? price
      : priceString + customer.ConstructionTier;
  }

  setCustomerTLD(response) {
    return response.data.customers.list.length === 1
      ? response.data.customers.list[0].TLDName
      : "";
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  getCustomerList() {
    if (this.state.singleCustomer) {
      return null;
    } else {
      return (
        <Row style={{ fontSize: "16px", marginLeft: "-15px" }}>
          <Col md={6} sm={12} xs={12}>
            <ControlLabel>Select Customer</ControlLabel>
            <Creatable
              maxMenuHeight={190}
              value={{
                value: this.state.selectedCustomer,
                label:
                  this.state.selectedCustomer === null ||
                  this.state.selectedCustomer === ""
                    ? "Select..."
                    : this.state.selectedCustomer,
              }}
              styles={customStyles}
              isClearable
              onChange={this.handleCustomerSelect}
              options={this.state.customerList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
          <Col md={4} sm={12} xs={12}>
            <ControlLabel>Customer Tier </ControlLabel>
            {this.state.isNewCustomer ? (
              <Select
                maxMenuHeight={190}
                menuPlacement="auto"
                value={{
                  value: this.state.tierSelected,
                  label: this.state.tierSelected,
                }}
                styles={customStyles}
                onChange={this.handleTierSelect}
                options={this.state.tierList.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            ) : (
              <FormControl
                readOnly
                type="text"
                value={this.state.customerTier}
                style={{ height: "20px", minHeight: "34px" }}
              />
            )}
          </Col>
        </Row>
      );
    }
  }
  handleTierSelect(e) {
    let tier = e.value;

    this.trackCustomerTierSelection(tier);
    ProductConfiguratorHandler.setCustomerTier(tier)
      .then((response) => {
        if (!response.success) {
          this.addNotification(
            ErrorMessages.CustomerTierNotRegistered,
            "error"
          );
        }
        this.setState((prevState) => ({
          tierSelected: tier,
          tierPricing:
            tier === prevState.tierList[0] ? priceType[0] : priceType[1],
          customerTier: tier,
        }));
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.CustomerTierMissing, "error");
      });
  }
  handleSingleCustomerSelect(tld, customer) {
    let customerInfo = customer.value.split("-tier-");
    sessionStorage.setItem(sessionCustomer, tld);
    customerName = customer.label;
    customerTLD = tld;
    this.setState(
      (prevState) => ({
        isNewCustomer: true,
        selectedCustomer: customer.label,
        tierPricing:
          prevState.productType === "led"
            ? priceString + customerInfo[1]
            : priceString + customerInfo[2],
        customerTier:
          customerInfo[1] === "1"
            ? prevState.tierList[0]
            : this.ternaryEvaluation(
                customerInfo[2],
                "1",
                prevState.tierList[1],
                prevState.tierList[2]
              ),
      }),
      this.handleProducts
    );
  }

  handleCustomerSelect(event) {
    if (event === null) {
      sessionStorage.setItem(sessionCustomer, "");
      this.trackCustomerSelection("");
      this.setState(
        (prevState) => ({
          isNewCustomer: false,
          selectedCustomer: "",
          tierPricing: priceType[0],
          customerTier: prevState.tierList[0],
        }),
        this.handleProducts
      );
      return;
    }
    let customerInfo = event.value.split("-tier-");
    if (customerInfo.length > 1) {
      sessionStorage.setItem(sessionCustomer, customerInfo[0]);
      this.trackCustomerSelection(customerInfo[0]);
      this.setState(
        (prevState) => ({
          isNewCustomer: false,
          selectedCustomer: customerInfo[0],
          ledTier: priceString + customerInfo[1],
          constructionTier: priceString + customerInfo[2],
          tierPricing:
            prevState.productType === "led"
              ? priceString + customerInfo[1]
              : priceString + customerInfo[2],
          customerTier:
            customerInfo[1] === "1"
              ? prevState.tierList[0]
              : this.ternaryEvaluation(
                  customerInfo[2],
                  "1",
                  prevState.tierList[1],
                  prevState.tierList[2]
                ),
        }),
        this.handleProducts
      );
    } else {
      sessionStorage.setItem(sessionCustomer, event.value);
      this.trackCustomerSelection(event.value);

      this.setState(
        (prevState) => ({
          isNewCustomer: true,
          selectedCustomer: event.value,
          tierPricing: priceType[0],
          tierSelected: prevState.tierList[0],
          customerTier: prevState.tierList[0],
        }),
        this.handleProducts
      );
    }
    customerName =
      sessionStorage.getItem(sessionCustomer) !== null
        ? sessionStorage.getItem(sessionCustomer)
        : "";
  }

  handleOnClose() {
    this.trackPopupCloseClick();
    this.props.handleOnClose();
  }

  // Mixpanel Tracking //

  trackCustomerSelection(customer) {
    mixpanel.track("Customer Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer is displayed",
      Customer: customer,
    });
  }

  trackCustomerTierSelection(tier) {
    mixpanel.track("Customer Tier Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer tier is displayed",
      Tier: tier,
    });
  }

  trackSpecsClick(specs, description, stockCode, pid) {
    mixpanel.track("Specs", {
      Action: "Button Click",
      Effect: "Show/Hide Specs Tooltip",
      Specs: specs,
      Description: description,
      "Stock Code": stockCode,
      "Product ID": pid,
    });
  }

  trackProductImageClick() {
    mixpanel.track("Clearance Product Image", {
      Action: "Button Click",
      Effect: "Navigate to Product Page for selected product",
      "Product ID": filter.postId,
    });

    mixpanel.track_pageview({
      Page: "Products Page",
    });
  }

  trackTableauDashboardClick(item) {
    mixpanel.track("Tableau Dashboard", {
      Action: "Link Click",
      Effect: `Tableau Dashboard opened`,
      "Tableau Link": item.rabLink,
      "Tableau ID": item.id,
      "Tableau Type": item.type,
    });
  }

  trackAddToCartButtonClick(message) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Attempt to add item(s) to current estimate summary cart",
      Page: "Clearance Page",
      "Added To Cart": false,
      Message: message,
    });
  }

  trackAddToCartButtonSuccess(description, stockCode) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Successfully added to cart",
      Page: "Clearance Page",
      "Added To Cart": true,
      "SKU Description": description,
      "SKU Stock Code": stockCode,
    });
  }

  trackPopupCloseClick() {
    mixpanel.track("Clearance Product Popup Close", {
      Action: "Button Click",
      Effect: `Clearance Product Popup closed`,
    });
  }
}

export default ClearanceProductPopup;
