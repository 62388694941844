import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get sales agency
 * Get branch
 * Get branchID
 * Add new user
 * Update existing user
 * Get user data
 * Search user data
 * Delete user
 * Send mail
 * Export users list
 */
const UserManagementHandler = (function () {
  return {
    getSalesAgency: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/UserAgency?UserName=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getEmptyBranch: function (tld) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/EmptyBranch?tld=${tld}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBranch: function (tld) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/Branch?tld=${tld}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBranchId: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/BranchId?branchName=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    addUser: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/AddUser`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateUser: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/UpdateUser`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUserData: function () {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/UserData`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    searchUserData: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/SearchUserData?search=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    deleteUser: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/DeleteUser`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendMail: function (data) {
      return new Promise((resolve, reject) => {
        const route = `UserManagement/SendMail`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    exportUsers: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Export/UserManagement`;
        Api.post_doc(route, data)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default UserManagementHandler;
