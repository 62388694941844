import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Row from "react-bootstrap/lib/Row";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import mixpanel from "mixpanel-browser";

import "./attachmentListPopup.css";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup.js";
import { ErrorMessages, DisplayMessages } from "../Utils/FetchConfigurableData";
import ContentDataHandler from "../Content/ContentDataHandler.js";

/**
 * Component represents the popup containing the list of attachments.
 */
class AttachmentListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    };

    this.delete = this.delete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.cancelAttachmentDeletion = this.cancelAttachmentDeletion.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              Attachments - {this.getDocumentName()}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row className="container attachmentList-container">
              <Table>
                <thead>
                  <tr>
                    <th>Attachment Name</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>{this.generateAttachmentList()}</tbody>
              </Table>
            </Row>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
        <DeleteConfirmationPopup
          show={this.state.deleteAttachmentPopup}
          item={this.state.attachmentName}
          itemType={"Attachment"}
          handleConfirmation={this.delete}
          handleCancel={this.cancelAttachmentDeletion}
        />
      </div>
    );
  }

  generateAttachmentList() {
    if (this.props.attachments.length <= 0) {
      return (
        <tr>
          <td colSpan="5">
            <h4 style={{ textAlign: "center" }}>No attachments to display!</h4>
          </td>
        </tr>
      );
    } else {
      return this.props.attachments.map((attachment, i) =>
        this.getAttachmentItem(attachment, i)
      );
    }
  }

  getDocumentName() {
    if (this.props.documentName) {
      return this.props.documentName.document_type;
    }
  }

  getAttachmentItem(item, i) {
    let extension = item.original_file_name.slice(
      item.original_file_name.lastIndexOf(".") + 1,
      item.original_file_name.length
    );
    let fileName = item.original_file_name.slice(
      0,
      item.original_file_name.lastIndexOf(".")
    );
    let downLoadBtn;
    if (extension === "pdf" || extension === "PDF") {
      downLoadBtn = (
        <Button
          key={item.original_file_name}
          bsSize="small"
          className="button-blue"
          style={{ height: "100%", width: "100%" }}
          href={item.filePath}
          target="_blank"
          onClick={() => this.trackAttachmentDownloadButtonClick(fileName)}
        >
          <span className="glyphicon glyphicon-save" />
          &nbsp;Download
        </Button>
      );
    } else {
      downLoadBtn = (
        <Button
          key={item.original_file_name}
          bsSize="small"
          className="button-blue"
          href={item.filePath}
          style={{ height: "100%", width: "100%" }}
          download={fileName + "." + extension}
          onClick={() => this.trackAttachmentDownloadButtonClick(fileName)}
        >
          <span className="glyphicon glyphicon-save" />
          &nbsp;Download
        </Button>
      );
    }

    return (
      <tr key={item.id} style={{ textAlign: "center" }}>
        <td style={{ verticalAlign: "middle" }}>{fileName}</td>
        <td style={{ width: "150px" }}>{downLoadBtn}</td>
        <td style={{ width: "50px" }}>
          <Button
            bsSize="small"
            style={{ height: "100%", width: "100%" }}
            className="button-download button-red"
            onClick={() => {
              this.handleDelete(item);
            }}
          >
            <span className="glyphicon glyphicon-trash" />
          </Button>
        </td>
      </tr>
    );
  }

  cancelAttachmentDeletion() {
    this.setState({
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    });
  }

  handleDelete(item) {
    this.trackDeleteAttachmentButtonClick(item);
    this.setState({
      deleteAttachmentPopup: true,
      attachmentName: item.original_file_name,
      attachmentId: item.id,
    });
  }

  delete() {
    this.trackDeleteAttachmentButtonConfirmationClick();

    ContentDataHandler.deleteExistingDocument(this.state.attachmentId)
      .then((response) => {
        this.cancelAttachmentDeletion();
        this.props.addNotification(DisplayMessages.DocumentDeleted, "info");
        this.props.handleDeleteAttachment();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.AttachmentDeletionFailed,
          "error"
        );
      });
  }

  handleOnClose() {
    this.props.handleOnClose();
  }

  // Mixpanel Tracking //

  trackAttachmentDownloadButtonClick(filename) {
    mixpanel.track("Attachment Download", {
      Action: "Button Click",
      Effect: "Selected attachment will be downloaded",
      Filename: filename,
    });
  }

  trackDeleteAttachmentButtonClick(item) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected attachment will be deleted",
      "Item Type": "Attachment",
      "Attachment Name": item.original_file_name,
      "Attachment ID": item.id,
    });
  }

  trackDeleteAttachmentButtonConfirmationClick() {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected attachment deleted",
      "Item Type": "Attachment",
      "Attachment Name": this.state.attachmentName,
      "Attachment ID": this.state.attachmentId,
    });
  }
}

export default AttachmentListPopup;
