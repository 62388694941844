import axios from "axios";
import Api from "./api";

const Plugin = (function () {
  const baseURL = Api.getBaseURL() + "/api/Plugin?input=";
  return {
    get: function (route) {
      const requestURL = baseURL + route;
      return axios.get(requestURL, this.getHeaders()).catch((resp) => {
        if (resp.response !== undefined && resp.response.status === 401) {
          this.clearTokenOnLogout();
        } else {
          return Promise.reject(resp);
        }
      });
    },
    post: function () {},
    getHeaders: function () {
      const token = JSON.parse(sessionStorage.getItem("currentUser") || "{}")[
        "token"
      ];
      this.headers = {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      };
      return this.headers;
    },
    getDirectHeaders: function () {
      const token = JSON.parse(sessionStorage.getItem("currentUser") || "{}")[
        "token"
      ];
      this.headers = { Authorization: `Bearer ${token}` };
      return this.headers;
    },
    getBaseURL: function () {
      return baseURL;
    },
    clearTokenOnLogout: function () {
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("username");

      this.headers = {};
    },
  };
})();

export default Plugin;
