import React, { Component } from "react";
import {
  Modal,
  FormControl,
  FormGroup,
  ControlLabel,
  Row,
  Col,
  DropdownButton,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";

/**
 * Component represents the download cart popup
 */
class DownloadCartPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitPopupClicked: this.props.show,
      filename: "",
      hasPrice: true,
      hasNotes: false,
      hasLeadTime: false,
      format: "",
      pricingAccess: true,
    };

    this.handleFileNameChange = this.handleFileNameChange.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.handleLeadTime = this.handleLeadTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateAccess = this.updateAccess.bind(this);
  }

  componentDidMount() {
    this.updateAccess();
  }

  componentWillReceiveProps(newProps) {
    this.updateAccess();
  }

  updateAccess() {
    this.setState({
      hasPrice: sessionStorage.getItem("pricingAccess") === "true",
      pricingAccess: sessionStorage.getItem("pricingAccess") === "true",
    });
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          className="popup-modal"
        >
          <Modal.Header>
            <Modal.Title
              style={{
                color: "#074391",
                fontWeight: "bolder",
                fontSize: "24px",
                textShadow: "0px 1px, 1px 0px, 1px 1px",
                paddingLeft: "20px",
              }}
            >
              Download Cart
              <br />
            </Modal.Title>
            <span style={{ fontSize: "12px", paddingLeft: "20px" }}>
              {DisplayMessages.DownloadCartPopup_DownloadCartLine1}
            </span>
            <br />
            <span style={{ fontSize: "12px", paddingLeft: "20px" }}>
              {DisplayMessages.DownloadCartPopup_DownloadCartLine2}
            </span>
          </Modal.Header>
          <Modal.Body>
            <br />
            <Row className="center-container">
              <Col md={10} sm={10} xs={10}>
                <ControlLabel style={{ width: "100%" }}>File Name</ControlLabel>
              </Col>
              <Col md={10} sm={10} xs={10}>
                <FormGroup>
                  <FormControl
                    type="text"
                    value={this.state.filename}
                    placeholder="Please enter the file name"
                    spellCheck="false"
                    maxLength="50"
                    onChange={this.handleFileNameChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="toggle-popup-row">
                  {this.state.pricingAccess ? (
                    <div>
                      <span className="default-label">Include Price </span>
                      <div>
                        <label
                          className="switch"
                          onChange={this.handlePrice}
                          htmlFor="toggle-has-price"
                        >
                          <input
                            id="toggle-has-price"
                            type="checkbox"
                            value={this.state.hasPrice}
                            defaultChecked={this.state.pricingAccess}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <span className="default-label">Include Notes </span>
                    <div>
                      <label
                        className="switch"
                        onChange={this.handleNotes}
                        htmlFor="toggle-has-notes"
                      >
                        <input
                          id="toggle-has-notes"
                          type="checkbox"
                          value={this.state.hasNotes}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>

                  <div>
                    <span className="default-label">Include Lead Time </span>
                    <div>
                      <label
                        className="switch"
                        onChange={this.handleLeadTime}
                        htmlFor="toggle-has-lead-time"
                      >
                        <input
                          id="toggle-has-lead-time"
                          type="checkbox"
                          value={this.state.hasLeadTime}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <DropdownButton
              bsStyle="primary"
              className="button-blue-summary"
              title="Download"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div>
                <h5
                  className="dropdown-item"
                  onClick={() => this.handleSelect("English")}
                >
                  PDF - English
                </h5>
                {Miscellaneous.FrenchDownloadEnabled ? (
                  <h5
                    className="dropdown-item"
                    onClick={(e) => this.handleSelect("French")}
                  >
                    PDF - French
                  </h5>
                ) : null}
                <h5
                  className="dropdown-item"
                  onClick={(e) => this.handleSelect("Excel")}
                >
                  Excel
                </h5>
              </div>
            </DropdownButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleClose() {
    this.setState(
      {
        filename: "",
        hasPrice: true,
        hasNotes: false,
        hasLeadTime: false,
        format: "",
        pricingAccess: true,
      },
      this.props.handleOnClose
    );
  }

  handlePrice() {
    this.setState(
      (prevState) => ({
        hasPrice: !prevState.hasPrice,
      }),
      this.trackIncludePriceToggle
    );
  }

  handleNotes() {
    this.setState(
      (prevState) => ({
        hasNotes: !prevState.hasNotes,
      }),
      this.trackIncludeNotesToggle
    );
  }
  handleLeadTime() {
    this.setState(
      (prevState) => ({
        hasLeadTime: !prevState.hasLeadTime,
      }),
      this.trackIncludeLeadTimeToggle
    );
  }

  handleSelect(format) {
    this.setState(
      {
        format: format,
      },
      this.handleSubmit
    );
  }

  handleFileNameChange(e) {
    this.setState({
      filename: e.target.value,
    });
  }

  handleSubmit() {
    if (!this.state.filename || this.state.filename === "") {
      this.trackDownloadSelectionFailedClick(
        ErrorMessages.DownloadCartPopup_ValidName
      );
      this.props.addNotification(
        ErrorMessages.DownloadCartPopup_ValidName,
        "error"
      );
    } else if (this.state.filename.length > 100) {
      this.trackDownloadSelectionFailedClick(
        ErrorMessages.DownloadCartPopup_MaxLimit
      );
      this.props.addNotification(
        ErrorMessages.DownloadCartPopup_MaxLimit,
        "error"
      );
    } else {
      this.trackDownloadSelectionClick();
      this.props.handleOnSubmit(
        this.state.filename,
        this.state.hasPrice,
        this.state.hasNotes,
        this.state.hasLeadTime,
        this.state.format
      );
      this.handleClose();
    }
  }

  // Mixpanel Tracking //

  trackIncludePriceToggle() {
    mixpanel.track("Include Price Toggle", {
      Action: "Toggle",
      Effect: "Include/Exclude Price in cart download",
      Included: this.state.hasPrice,
    });
  }

  trackIncludeNotesToggle() {
    mixpanel.track("Include Notes Toggle", {
      Action: "Toggle",
      Effect: "Include/Exclude Notes in cart download",
      Included: this.state.hasNotes,
    });
  }

  trackIncludeLeadTimeToggle() {
    mixpanel.track("Include Lead Time Toggle", {
      Action: "Toggle",
      Effect: "Include/Exclude Lead Time in cart download",
      Included: this.state.hasLeadTime,
    });
  }

  trackDownloadSelectionClick() {
    mixpanel.track("Download Cart", {
      Action: "Button Click",
      Effect: "Current estimate summary cart will be downloaded",
      Filename: this.state.filename,
      Format: this.state.format,
      Price: this.state.hasPrice,
      Notes: this.state.hasNotes,
      "Lead Time": this.state.hasLeadTime,
    });
  }

  trackDownloadSelectionFailedClick(message) {
    mixpanel.track("Download Cart", {
      Action: "Button Click",
      Effect: "Current estimate summary cart will not be downloaded",
      Message: message,
    });
  }
}

export default DownloadCartPopup;
