import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";

import { ErrorMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the name popup
 */
class NamePopup extends Component {
  constructor(props) {
    super(props);

    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      name: "",
    };
  }
  componentWillReceiveProps(newProp) {
    this.setState({ name: "" });
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
                textAlign: "center",
              }}
              className="popup-blue-text"
            >
              {this.props.title}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                id="nametext"
                value={this.state.name}
                placeholder="Enter Name"
                onChange={this.handleNameChange}
                onKeyDown={this.handleKeyPress}
                maxLength="100"
              />
              <FormControl.Feedback />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
            <Button onClick={this.handleDiscard}>Discard</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleOnClose() {
    this.props.handleOnClose();
  }
  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleDiscard() {
    this.setState({ name: "" });
    this.props.handleOnDiscard();
  }
  handleSubmit() {
    if (!this.state.name || this.state.name === "") {
      this.props.addNotification(ErrorMessages.NamePopup_ValidName, "error");
      return;
    } else if (this.state.name.length > 100) {
      this.props.addNotification(ErrorMessages.NamePopup_MaxLimit, "error");
      return;
    }
    this.props.handleOnSubmit(this.state.name);
    this.props.handleOnClose();
  }
  handleKeyPress(target) {
    if (target.keyCode === 13) {
      this.handleSubmit();
    }
  }
}

export default NamePopup;
