import axios from "axios";

import Plugin from "../Utils/plugin";
import Api from "../Utils/api";
import { createCancelTokenHandler } from "../Utils/axiosUtils";

/**
 * API Calls to handle:
 * Get current user information
 * Get website configuration - logo, mobile number, display name
 * Load current user information to check changes
 * Complete first time login for a user
 * Search items based on stock code
 * Search items based on description
 * Dropdown list of items based on search based on description
 * Dropdown list of items based on search based on stock code
 * Search product details based on search filter
 */

const ConfigHandler = (function () {
  return {
    getDisplayName: function () {
      return new Promise((resolve, reject) => {
        const route = `Config/DisplayName`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getWebsiteConfig: function () {
      return new Promise((resolve, reject) => {
        const route = `Config/WebsiteConfiguration`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadUserInfo: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Config/LoadUserInfo?username=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    completeFirstLogin: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Config/CompleteFirstLogin?username=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    stockCodeSearch: function (input) {
      return new Promise((resolve, reject) => {
        const route = `Config/StockCodeSearch?searchString=${input}`;
        axios({
          method: "GET",
          url: Api.getBaseURL() + "/api/" + route,
          headers: Api.getDirectHeaders(),
          cancelToken:
            cancelTokenHandlerObject[
              this.stockCodeSearch.name
            ].handleRequestCancellation().token,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    descriptionSearch: function (input) {
      return new Promise((resolve, reject) => {
        const route = `Config/DescriptionSearch?searchString=${input}`;
        axios({
          method: "GET",
          url: Api.getBaseURL() + "/api/" + route,
          headers: Api.getDirectHeaders(),
          cancelToken:
            cancelTokenHandlerObject[
              this.descriptionSearch.name
            ].handleRequestCancellation().token,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    dropdownDescription: function (input) {
      return new Promise((resolve, reject) => {
        const route = `Config/DropdownForDescription?searchString=${input}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    dropdownStockCode: function (input) {
      return new Promise((resolve, reject) => {
        const route = `Config/DropdownForStockCode?searchString=${input}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    productSearch: function (filters) {
      return new Promise((resolve, reject) => {
        const route = `searchproducts/${filters.searchString}?page=${filters.page}&offset=${filters.offset}`;
        axios({
          method: "GET",
          url: Plugin.getBaseURL() + route,
          headers: Plugin.getDirectHeaders(),
          cancelToken:
            cancelTokenHandlerObject[
              this.productSearch.name
            ].handleRequestCancellation().token,
        })
          .then((response) => {
            let resolveObj = {};
            resolveObj.total = response.data.data.total;
            resolveObj.productList = response.data.data.products.map(
              (item) => item
            );
            resolve(resolveObj);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSpecLinks: function (data) {
      return new Promise((resolve, reject) => {
        let route = `Config/SpecLinks`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

const cancelTokenHandlerObject = createCancelTokenHandler(ConfigHandler);

export default ConfigHandler;
