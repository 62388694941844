import fixedLink from "../Assets/fixedAssets.json";

/**
 * Method to fetch JSON data from document asynchronously
 * @param {*} resourceLocation
 * @returns data in json format
 */
async function fetchJSONData(resourceLocation) {
  const storedData = await fetch(process.env.PUBLIC_URL + resourceLocation, {
    method: "GET",
    mode: "no-cors",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {});
  return storedData;
}

/**
 * Method to load documents present in resource location
 * @param {*} resourceLocation
 * @returns object
 */
export function fetchResourceData(resourceLocation) {
  return process.env.PUBLIC_URL + resourceLocation;
}

/**
 * Returns the Display Messages used across the web application
 */
export let DisplayMessages = fetchJSONData(fixedLink.displayMessagesPath).then(
  (result) => {
    DisplayMessages = result;
  }
);

/**
 * Returns the Disclaimer texts used across the web application
 */
export let Disclaimers = fetchJSONData(fixedLink.disclaimersPath).then(
  (result) => {
    Disclaimers = result;
  }
);

/**
 * Returns the Error Messages used across the web application
 */
export let ErrorMessages = fetchJSONData(fixedLink.errorMessagesPath).then(
  (result) => {
    ErrorMessages = result;
  }
);

/**
 * Returns the Miscellaneous options used across the web application
 */
export let Miscellaneous = fetchJSONData(fixedLink.miscellaneousPath).then(
  (result) => {
    Miscellaneous = result;
  }
);

export async function fetchJSONFileData(resourceLocation) {
  const storedData = await fetch(process.env.PUBLIC_URL + resourceLocation, {
    method: "GET",
    mode: "no-cors",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return storedData;
}
