import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Panel from "react-bootstrap/lib/Panel";
import { Label, Modal } from "react-bootstrap";
import Creatable from "react-select/creatable";
import FormControl from "react-bootstrap/lib/FormControl";
import Popover from "react-bootstrap/lib/Popover";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Spinner from "react-loading";

import NotificationSystem from "react-notification-system";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Select from "react-select";
import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import Tooltip from "react-bootstrap/lib/Tooltip";
import mixpanel from "mixpanel-browser";

import Disclaimer from "../Disclaimer/Disclaimer";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp.js";
import TableauPopup from "../Tableau/TableauPopup.js";
import TableauDataHandler from "../Tableau/TableauDataHandler.js";
import "../Clearance/clearance.css";
import BranchOverviewHandler from "../Distributors/BranchOverviewHandler";
import yourPricingHandler from "../YourPricing/YourPricingHandler";
import "./productConfigurator.css";
import ProductConfiguratorHandler from "./ProductConfiguratorHandler.js";
import fixedLink from "../Assets/fixedAssets.json";
import {
  Disclaimers,
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
  fetchJSONFileData,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import ContentLoading from "../ContentLoading/ContentLoading.js";
import { CopyToClipboard } from "../Utils/CopyToClipboard.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

let customerName = "";
let customerTLD = "";

const branchBasedRoles = ["Distributor User", "Branch Admin"];

const productCriteria = [
  "led",
  "construction",
  "accessories",
  "quickship",
  "golden",
];

const itemCategory = ["finish"];

const priceType = ["price1", "price2"];
const priceString = "price";

const allCustomColors = [
  { title: "BRZ", name: "Bronze", className: " bronze" },
  { title: "BLK", name: "Matte Black", className: " matte-black" },
  { title: "MBK", name: "Mirror Black", className: " mirror-black" },
  { title: "SBK", name: "Sandtex Black", className: " black-sandtex" },
  { title: "WHT", name: "White", className: " white" },
  { title: "HGY", name: "Hammertone", className: " hammertone" },
  { title: "DGY", name: "Dove Gray", className: " dove-gray" },
  { title: "SPS", name: "Sparkle Silver", className: " sparkle-silver" },
  { title: "DGN", name: "Design Green", className: " green" },
  { title: "GGN", name: "Garden Green", className: " garden-green" },
  { title: "BLU", name: "Blue", className: " blue" },
  { title: "YLW", name: "Yellow", className: " yellow" },
  { title: "RED", name: "Red", className: " red" },
  { title: "GRY", name: "Gray", className: " gray" },
];

const blankImg = fetchResourceData(fixedLink.blankIcon);

/**
 * Component represents the product configurator in product page
 */
class ProductConfigurator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      customerList: [],
      customerTLD: "",
      selectedCustomer: null,
      productAccessories: [],
      configuratorValues: [],
      productFamily: [],
      productModelList: [],
      productModelSelection: [],
      wattage: "",
      tierPricing: "price1",
      entireConfigurator: [],
      description: [],
      cstIndex: -1,
      cstColorProps: null,
      productPrice: null,
      nonClearancePrice: 0,
      saleProductPrice: null,
      productQuantity: 1,
      isViewClicked: false,
      showConfirmationPopup: false,
      currentSetIndex: 0,
      last_updated: null,
      stockdetails: [],
      productType: "",
      receivedData: false,
      customerTier: "Tier1",
      ledTierPricing: "price1",
      constructionTierPricing: "price1",
      quickShipProducts: [],
      invLink: "",
      selected_item_id: "",
      current_tableau_link: "",
      configuratorAvailable: true,
      isChecked: true,
      standardFinishes: [],
      setVisibility: true,
      configurationList: [],
      accessoryList: [],
      singleCustomer: false,
      pricingAccess: true,
      goldenPriceAccess: true,
      baseQuantity: 1,
      adderPrice: 0,
      isNewCustomer: false,
      addedToOrderSummary: false,
      goldenProduct: false,
      goldenProductPrice: null,
      goldenProductsList: [],
      tierList: Miscellaneous.TiersList,
      goldenPresent: false,
      stockCode: "",
      branchLedTierPricing: "price1",
      branchConstructionTierPricing: "price1",
      // Complete configurator
      allAccessories: [],
      allBases: [],
      allOptions: [],
      selectedBase: null,
      selectedSetId: null,
      ledShipTime: null,
      ledShipInfo: null,
      configQuantity: "1",
      configLeadTime: "",
      configPartCategory: "",
      currentConstraints: null,
      showConstraints: false,
      baseConstraintPresent: false,
      accessoryConstraintPresent: false,
      optionConstraintPresent: false,
      selectedProductCriteria: productCriteria[0],
      selectedElement: null,
      defaultFinish: null,
      defaultAccessoryColorProps: allCustomColors[0],
      showSpecLinkPopup: false,
      specLinkPopupStockCode: "",
      specLinkPopupDescription: "",
      ledSpecValue: "default",
      engSpecLink: "",
      frSpecLink: "",
      customColorList: [],
      addersReset: [],
    };

    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);

    // Customer Selection
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.handleSingleCustomerSelect =
      this.handleSingleCustomerSelect.bind(this);
    this.handleTierSelect = this.handleTierSelect.bind(this);
    this.updateSelectedTier = this.updateSelectedTier.bind(this);
    this.clearTableData = this.clearTableData.bind(this);
    this.handleClearClose = this.handleClearClose.bind(this);
    this.addToCart = this.addToCart.bind(this);

    // Product Description Toggle
    this.onProductModelChange = this.onProductModelChange.bind(this);
    this.configuratorPreviewHandler =
      this.configuratorPreviewHandler.bind(this);
    this.updateProductDescription = this.updateProductDescription.bind(this);
    this.handleCustomColorClick = this.handleCustomColorClick.bind(this);
    this.validateProductQuantity = this.validateProductQuantity.bind(this);
    this.addLedProductsToOrderSummary =
      this.addLedProductsToOrderSummary.bind(this);
    this.addProductsToCart = this.addProductsToCart.bind(this);
    this.getConfigPrice = this.getConfigPrice.bind(this);
    this.updateProductPreview = this.updateProductPreview.bind(this);
    this.findGoldenProduct = this.findGoldenProduct.bind(this);
    this.onConfigQuantityChange = this.onConfigQuantityChange.bind(this);
    this.onConfigQuantityBlur = this.onConfigQuantityBlur.bind(this);
    this.showLEDSpecLink = this.showLEDSpecLink.bind(this);
    this.copyLEDItem = this.copyLEDItem.bind(this);

    // Constraint Messages
    this.constraintMessageSection = this.constraintMessageSection.bind(this);
    this.checkBaseConstraints = this.checkBaseConstraints.bind(this);
    this.checkAccessoryConstraints = this.checkAccessoryConstraints.bind(this);
    this.checkIndividualOptionConstraint =
      this.checkIndividualOptionConstraint.bind(this);
    this.checkOptionConstraints = this.checkOptionConstraints.bind(this);
    this.getConstraintMessages = this.getConstraintMessages.bind(this);

    // Construction Product Specific
    this.showConstructionSpecLink = this.showConstructionSpecLink.bind(this);
    this.onConstructionQtyBlur = this.onConstructionQtyBlur.bind(this);
    this.onConstructionQtyChange = this.onConstructionQtyChange.bind(this);
    this.addConstructionProductToOrderSummary =
      this.addConstructionProductToOrderSummary.bind(this);
    this.constructionProductPrice = this.constructionProductPrice.bind(this);

    // Accessories Specific
    this.generateAccessoriesList = this.generateAccessoriesList.bind(this);
    this.showAccessorySpecLink = this.showAccessorySpecLink.bind(this);
    this.onAccessoryQuantityBlur = this.onAccessoryQuantityBlur.bind(this);
    this.onAccessoryQuantityChange = this.onAccessoryQuantityChange.bind(this);
    this.setAccessoryImages = this.setAccessoryImages.bind(this);
    this.handleAccessoryCustomColorClick =
      this.handleAccessoryCustomColorClick.bind(this);
    this.addAccessoriesToOrderSummary =
      this.addAccessoriesToOrderSummary.bind(this);

    // Quick Ship Specific
    this.getQuickShipList = this.getQuickShipList.bind(this);
    this.showQuickShipSpecLink = this.showQuickShipSpecLink.bind(this);
    this.addQuickShipProductToOrderSummary =
      this.addQuickShipProductToOrderSummary.bind(this);
    this.onQuickShipQuantityBlur = this.onQuickShipQuantityBlur.bind(this);
    this.onQuickShipQuantityChange = this.onQuickShipQuantityChange.bind(this);
    this.quickShipProductPrice = this.quickShipProductPrice.bind(this);

    // Golden Pricing Specific
    this.addGoldenProductToOrderSummary =
      this.addGoldenProductToOrderSummary.bind(this);
    this.getYourPricingProducts = this.getYourPricingProducts.bind(this);
    this.showYourPricingSpecLink = this.showYourPricingSpecLink.bind(this);

    // Other Actions
    this.onHover = this.onHover.bind(this);
    this.getDashBoard = this.getDashBoard.bind(this);
    this.handleClearCloseDashboard = this.handleClearCloseDashboard.bind(this);

    this.getLedSpecLinks = this.getLedSpecLinks.bind(this);
    this.setInventoryDetails = this.setInventoryDetails.bind(this);
    this.handleOnPopupClose = this.handleOnPopupClose.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.postId !== nextProps.postId) {
      customerName =
        sessionStorage.getItem("customerName") !== null
          ? sessionStorage.getItem("customerName")
          : "";
      this.getQuickShipList();
      this.getYourPricingProducts();
    } else if (this.state.allBases.length > 0) {
      this.setState((prevState) => ({
        selectedBase: prevState.allBases[0].title,
      }));
    }
    this.getCustomers();
  }

  async componentDidMount() {
    await fetchJSONFileData(fixedLink.displayMessagesPath);
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";
    this.getQuickShipList();
    this.getYourPricingProducts();

    let role = sessionStorage.getItem("userRole");

    if (branchBasedRoles.includes(role)) {
      BranchOverviewHandler.getBranchTier()
        .then((response) => {
          this.setState(
            {
              branchLedTierPricing: priceString + response.data.LedTier,
              branchConstructionTierPricing:
                priceString + response.data.ConstructionTier,
              branchName: response.data.BranchName,
              singleCustomer: true,
              pricingAccess: JSON.parse(
                sessionStorage.getItem("pricingAccess")
              ),
              goldenPriceAccess: JSON.parse(
                sessionStorage.getItem("goldenPriceAccess")
              ),
            },
            () => {
              this.getCustomers();
            }
          );
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.ProductConfig_BranchTier, "error");
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.description !== this.state.description ||
        (prevState.wattage !== "" &&
          prevState.wattage !== this.state.wattage) ||
        prevState.selectedCustomer !== this.state.selectedCustomer ||
        prevState.customerTier !== this.state.customerTier) &&
      this.state.productType === "led"
    ) {
      let desc = this.state.description.filter((e) => e).join("-");
      desc = desc.replace("`", "'");
      let data = {
        description: desc,
        wattage: this.state.wattage,
        cstColor:
          this.state.cstColorProps === null
            ? "CST"
            : this.state.cstColorProps.title,
        productId: this.props.postId.toString(),
        setId: this.state.selectedSetId,
        customer_name: this.state.singleCustomer
          ? this.state.branchName
          : this.ternaryEvaluation(
              this.state.selectedCustomer,
              null,
              "",
              this.state.selectedCustomer
            ),
      };
      ProductConfiguratorHandler.getStockCodeAndPrice(data)
        .then((response) => {
          this.setState(
            (prevState) => ({
              stockCode: response.data.stockcode,
              productPrice:
                response.data.price.toString() !== "-1"
                  ? response.data.price.toString()
                  : prevState.productPrice,
              saleProductPrice: response.data.salePrice,
              goldenProductPrice: response.data.goldenPrice,
              baseQuantity: response.data.baseQuantity,
              adderPrice: response.data.adderPrice,
              nonClearancePrice: response.data.nonClearancePrice,
              configQuantity: 1,
            }),
            () => {
              this.getLedSpecLinks();
              this.getConstraintMessages();
              this.setInventoryDetails();
            }
          );
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.ProductConfig_StockCodePrice,
            "error"
          );
        });
    }
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  ternaryEvaluationForTier(prodType, value1, value2, condition1, condition2) {
    if (prodType === "led") {
      return value1 === value2 ? condition1 : priceString + condition2.Tier;
    } else {
      return value1 === value2
        ? condition1
        : priceString + condition2.ConstructionTier;
    }
  }

  render() {
    return this.state.configuratorAvailable
      ? this.fullConfigurationSection()
      : this.noConfiguratorSection();
  }

  fullConfigurationSection() {
    return this.state.receivedData &&
      (this.state.productPrice !== null || this.state.allBases.length > 0)
      ? this.configuratorSection()
      : this.configuratorLoadingSection();
  }

  configuratorSection() {
    return (
      <div className="loading-fade">
        <br />
        <br />
        {this.CustomerList()}
        <br />
        <br />
        {this.ProductConfiguratorSection()}
        <TableauPopup
          show={this.state.selected_item_id}
          handleClearClose={this.handleClearCloseDashboard}
          tableau_link={this.state.current_tableau_link}
        />
      </div>
    );
  }

  configuratorLoadingSection() {
    return <ContentLoading message={""} size={50} />;
  }

  noConfiguratorSection() {
    return (
      <Row>
        <hr className="custom-color-hr" />
        <div className="no-configurator-section">
          <span style={{ fontWeight: "bold" }}>
            {Disclaimers.NoProductConfigurator_DisclaimerText}
          </span>
          <br />
          <span>
            <a
              href={"tel:" + Miscellaneous.MobileNumber}
              className="no-configurator-section-redirect"
            >
              {Miscellaneous.MobileNumber}
            </a>{" "}
            |{" "}
            <a
              href={"tel:" + Miscellaneous.InfoMail}
              className="no-configurator-section-redirect"
            >
              {Miscellaneous.InfoMail}
            </a>
          </span>
        </div>
        <br />
      </Row>
    );
  }

  getConstraintMessages() {
    let constraints = [];
    let data = this.state.allBases.find(
      (item) => item.title === this.state.wattage
    );
    if (data != null) {
      if (
        data.contents.constraintMessage !== "0" &&
        data.contents.constraintMessage !== "NA" &&
        data.contents.constraintMessage !== ""
      ) {
        constraints.push(data.contents.constraintMessage);
      }
    }
    this.state.description.forEach((str) => {
      let data = this.state.allOptions.find((item) => {
        return item.list.find((subItem) => {
          return subItem.category === str;
        });
      });
      if (data != null) {
        let item = data.list.find((item) => item.category === str);
        if (item !== null) {
          let finItem = item.list.find((item) => item.title === str);
          if (finItem !== null) {
            if (
              finItem.constraintMessage !== "0" &&
              finItem.constraintMessage !== "NA" &&
              finItem.constraintMessage !== ""
            ) {
              constraints.push(finItem.constraintMessage);
            }
          }
        }
      }
    });
    this.setState({
      currentConstraints: constraints,
    });
  }

  getLedSpecLinks() {
    let description = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;
    this.setState((prevState) => ({
      specLinkPopupDescription: `${prevState.wattage}-${prevState.description
        .filter((e) => e)
        .join("-")}`,
    }));
    let data = {
      description: description,
      productId: this.props.postId.toString(),
    };
    ConfigHandler.getSpecLinks(data)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            ledSpecValue: data.Specs,
          });
        } else {
          this.setState({
            ledSpecValue: "N/A",
            engSpecLink: "",
            frSpecLink: "",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setInventoryDetails() {
    let description = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;
    let qty = this.state.configQuantity;

    ProductConfiguratorHandler.getInventoryDetails(
      encodeURIComponent(description)
    ).then((response) => {
      let shipTime = Miscellaneous.LeadTimeUnavailable;
      let partCategory = Miscellaneous.PartCategoryNotStocked;
      let shipInfo = response.data.qtyLead;
      shipInfo = shipInfo.sort(
        (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
      );
      let flag = 0;
      shipInfo.forEach((shipdata) => {
        if (
          parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
          flag === 0
        ) {
          flag = 1;
          shipTime = shipdata.LeadTime;
        }
      });

      let partCategoryInfo = response.data.stockdetails.find(
        (item) => item.LeadTime === shipTime
      );

      if (partCategoryInfo !== undefined) {
        partCategory =
          partCategoryInfo.PartCategory === "B"
            ? Miscellaneous.PartCategoryStocked
            : Miscellaneous.PartCategoryNotStocked;
      }

      this.setState({
        configLeadTime: shipTime,
        ledShipInfo: shipInfo,
        configPartCategory: partCategory,
      });
    });
  }

  getConfigPrice() {
    let configList = [];
    this.state.configurationList.forEach((item, index) => {
      item.index = index;
      let desc = item.description.filter((e) => e).join("-");
      desc = desc.replace("`", "'");
      let data = {
        description: desc,
        wattage: item.wattage,
        cstColor: item.cstColor,
        productId: this.props.postId.toString(),

        setId: this.state.selectedSetId,
        customer_name: this.state.singleCustomer
          ? this.state.branchName
          : this.ternaryEvaluation(
              this.state.selectedCustomer,
              null,
              "",
              this.state.selectedCustomer
            ),
      };
      ProductConfiguratorHandler.getStockCodeAndPrice(data)
        .then((response) => {
          item.stockCode = response.data.stockcode;
          item.price = response.data.nonClearancePrice;
          item.GoldenPrice = this.state.goldenPriceAccess
            ? response.data.goldenPrice
            : "0";
          item.SalePrice = response.data.salePrice;
          item.baseQuantity = response.data.baseQuantity;
          item.adderPrice = response.data.adderPrice;
          item.golden = false;
          if (parseFloat(response.data.salePrice) !== 0) {
            if (
              parseFloat(item.GoldenPrice) >
                parseFloat(response.data.salePrice) ||
              parseFloat(item.GoldenPrice) === 0
            ) {
              item.golden = false;
            } else {
              item.golden = true;
              item.price = item.GoldenPrice;
            }
          } else if (parseFloat(item.GoldenPrice) === 0) {
            item.golden = false;
          } else {
            item.golden = true;
            item.price = item.GoldenPrice;
          }
          configList.push(item);
          if (configList.length === this.state.configurationList.length) {
            configList.sort((a, b) => a.index - b.index);
            this.setState({
              configurationList: configList,
            });
          }
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.ProductConfig_StockCodePrice,
            "error"
          );
        });
    });
  }

  getQuickShipList() {
    ProductConfiguratorHandler.getQuickShipList(this.props.postId)
      .then((response) => {
        this.setState(
          {
            quickShipProducts: response.data.list,
            invLink: response.data.invLinkId,
          },
          () => this.getCustomers()
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_QuickShipList,
          "error"
        );
      });
  }

  getYourPricingProducts() {
    yourPricingHandler
      .getGoldenProducts(this.props.postId, encodeURIComponent(customerName))
      .then((response) => {
        if (response.success) {
          let newGoldenList = response.data;
          let oldGoldenList = this.state.goldenProductsList;
          newGoldenList.forEach((item) => {
            let temp = oldGoldenList.find(
              (element) => element.Description === item.Description
            );
            if (temp !== undefined) {
              item.isSelected = temp.isSelected;
              item.Quantity = temp.Quantity;
            }
          });
          this.setState({
            goldenProductsList: newGoldenList,
          });
        } else {
          this.setState({
            goldenProductsList: [],
          });
        }
        this.resetToDefaultTab();
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_GoldenProducts,
          "error"
        );
      });
  }

  getCustomers() {
    ProductConfiguratorHandler.getCustomerList()
      .then((response) => {
        let customer = response.data.customers.list.find(
          (item) => item.CustomerName === customerName
        );
        this.setState(
          {
            isNewCustomer: customer === undefined && customerName !== "",
            last_updated: response.data.lastUpdatedTime,
            customerTier: this.setCustomerTier(customer),
            customerList: response.data.customers.list.map((item) => ({
              value:
                item.CustomerName +
                "-tier-" +
                item.Tier +
                "-tier-" +
                item.ConstructionTier,
              label: item.CustomerName,
            })),
            customerTLD: this.setCustomerTLD(response),
          },
          () => {
            if (
              this.state.singleCustomer &&
              this.state.customerList.length > 0
            ) {
              customerName = this.state.customerTLD;
              this.handleSingleCustomerSelect(
                this.state.customerTLD,
                this.state.customerList[0]
              );
            }
            this.getProductConfigDetails(response.data.customers.list);
          }
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.ProductConfig_CustomerList, "error");
      });
  }

  setCustomerTier(customer) {
    return customer === undefined || customer.Tier === "1"
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          customer.ConstructionTier,
          "1",
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  setCustomerTLD(response) {
    return response.data.customers.list.length === 1
      ? response.data.customers.list[0].TLDName
      : "";
  }

  getProductConfigDetails(customers) {
    let tier = customers.find((item) => item.CustomerName === customerName);
    let availableColorProps = undefined;

    let price =
      this.state.customerTier === this.state.tierList[0]
        ? priceType[0]
        : priceType[1];

    ProductConfiguratorHandler.getCompleteConfiguration(
      this.props.postId,
      this.props.title,
      encodeURIComponent(customerName)
    ).then((response) => {
      let configurator = response.data.configurator;
      let inventoryLink = response.data.inventoryLink;

      let bases = configurator.base;
      let accessories = configurator.accessories;
      let options = configurator.options;
      let productType = configurator.productType;

      if (bases.list != null && bases.list.length > 0) {
        bases = bases.list.map((item) => {
          return {
            title: item.category,
            setId: item.list[0].setId,
            contents: item.list[0],
          };
        });

        let originalBases = configurator.base.list.map((item) => {
          return {
            title: item.category,
            setId: item.list[0].setId,
            contents: item.list[0],
            itemQuantity: 1,
          };
        });

        let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;

        let selectedBase =
          this.state.selectedBase == null
            ? originalBases[0].title
            : this.state.selectedBase;
        let temp = bases.find((item) => (item.title = selectedBase));
        let selectedSetId = temp.setId;

        let defaultFinish = [];
        if (productType === productCriteria[0]) {
          defaultFinish = options.find(
            (opt) => opt.category === itemCategory[0]
          );
          if (defaultFinish !== undefined) {
            defaultFinish = defaultFinish.list[0].list.find(
              (df) => df.setId === selectedSetId
            );
          }
        }

        accessories = accessories.map((item) => {
          if (defaultFinish !== undefined) {
            availableColorProps = allCustomColors.find(
              (x) => x.title === defaultFinish.title
            );

            defaultAccessoryColorProps =
              availableColorProps !== undefined
                ? availableColorProps
                : this.state.defaultAccessoryColorProps;
          }

          return { ...item, accessoryColor: defaultAccessoryColorProps };
        });

        this.setState(
          {
            configuratorAvailable: true,
            invLink: inventoryLink,
            allBases: originalBases,
            allAccessories: accessories,
            defaultAccessoryColorProps: defaultAccessoryColorProps,
            allOptions: options,
            productType: productType,
            selectedBase: selectedBase,
            wattage: selectedBase,
            selectedSetId: selectedSetId,
            ledTierPricing: this.setLedTier(tier, price),
            constructionTierPricing: this.setConstructionTier(tier, price),
            tierPricing: this.setLedTier(tier, price),
            defaultFinish: defaultFinish,
            customerTier: this.setUpdatedCustomerTier(tier, price),
          },
          () => {
            this.setAccessoryImages();
            this.configuratorPreviewHandler();
            this.checkBaseConstraints();
            this.checkAccessoryConstraints();
            this.checkOptionConstraints();
          }
        );
      } else {
        this.setState({
          configuratorAvailable: false,
        });
      }
    });
  }

  setUpdatedCustomerTier(tier, price) {
    return this.setLedTier(tier, price) === priceType[0]
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          this.setConstructionTier(tier, price),
          priceType[0],
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  setLedTier(tier, price) {
    return this.state.singleCustomer
      ? this.state.branchLedTierPricing
      : this.ternaryEvaluationForTier("led", tier, undefined, price, tier);
  }

  setConstructionTier(tier, price) {
    return this.state.singleCustomer
      ? this.state.branchConstructionTierPricing
      : this.ternaryEvaluationForTier(
          "construction",
          tier,
          undefined,
          price,
          tier
        );
  }

  setAccessoryImages() {
    let accessories = this.state.allAccessories;
    let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;

    accessories.forEach((element) => {
      element.accessoryColor = defaultAccessoryColorProps;

      if (element.imageURL !== null) {
        this.setState(
          {
            allAccessories: accessories,
          },
          this.generateAccessoriesList
        );
      } else if (element.photoID !== "") {
        ProductConfiguratorHandler.getAccessoriesImages(element.photoID).then(
          (urls) => {
            element.imageURL = urls[0].src;

            this.setState(
              {
                allAccessories: accessories,
              },
              this.generateAccessoriesList
            );
          }
        );
      } else {
        element.imageURL = blankImg;
        this.setState(
          {
            allAccessories: accessories,
          },
          this.generateAccessoriesList
        );
      }
    });
  }

  handleClearCloseDashboard() {
    this.setState({
      selected_item_id: "",
      current_tableau_link: "",
    });
  }

  getDashBoard() {
    this.setState({ selected_item_id: Miscellaneous.InventoryLookupName });
    let data = {
      rabLink: Miscellaneous.InventoryLookupName,
      id: this.state.invLink.id,
      type: this.state.invLink.type,
    };

    this.trackTableauDashboardClick(data);
    TableauDataHandler.getTableauDashboard(data)
      .then((response) => {
        if (response.success) {
          this.setState({
            current_tableau_link:
              Miscellaneous.TrustedTableauLink +
              response.data.token +
              "/" +
              response.data.dashboard,
          });
        } else {
          this.setState({
            current_tableau_link: Miscellaneous.DefaultTableauLink,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_TableauDashboard,
          "error"
        );
      });
  }

  configuratorPreviewHandler() {
    let tempCstIndex = -1;
    let cstColorProps = this.state.cstColorProps;
    let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;
    if (cstColorProps !== null) {
      cstColorProps.title = "CST";
      cstColorProps.name = "CUSTOM";
    }

    let description = [];
    let options = [...this.state.allOptions];
    let availableColorProps = undefined;

    let allCSTColors = [];
    let allStandardColors = [];

    options.forEach((item, i) => {
      let isItemCovered = false;
      item.list.forEach((element) => {
        let defaultElement = element.list.find(
          (x) => x.setId === this.state.selectedSetId
        );
        if (defaultElement !== undefined && !isItemCovered) {
          if (defaultElement.valueIndex !== "1") {
            description[i] = "";
          } else {
            description[i] = defaultElement.title;
            isItemCovered = true;
          }
        }

        if (
          item.category === itemCategory[0] ||
          item.list.find((clr) => clr.category === "CST")
        ) {
          tempCstIndex = i;
          if (item.list.find((clr) => clr.category === "CST") !== undefined) {
            cstColorProps =
              defaultElement !== undefined && defaultElement.title === "CST"
                ? defaultElement
                : cstColorProps;
            if (cstColorProps !== null && cstColorProps.title !== "CST") {
              availableColorProps = allCustomColors.find(
                (item) => item.title === cstColorProps.title
              );

              defaultAccessoryColorProps =
                availableColorProps !== undefined
                  ? availableColorProps
                  : this.state.defaultAccessoryColorProps;
            }
            allCSTColors = allCustomColors.filter(
              (acc) => !item.list.find((clr) => clr.category === acc.title)
            );
            allStandardColors = allCustomColors.filter((acc) =>
              item.list.find((clr) => clr.category === acc.title)
            );
          }
        }
      });
    });

    this.setState(
      {
        receivedData: true,
        allOptions: options,
        description: description,
        allCustomColors: allCustomColors,
        customColorList: allCSTColors,
        standardColorList: allStandardColors,
        cstIndex: tempCstIndex,
        cstColorProps: cstColorProps,
        defaultAccessoryColorProps: defaultAccessoryColorProps,
        selectedCustomer: customerName,
      },
      () => {
        this.generateAccessoriesList();
        if (customerName) {
          this.findGoldenProduct();
        }
      }
    );
  }

  generateAccessoriesList() {
    let productId = this.props.postId;
    let currentCustomer = encodeURIComponent(customerName);
    let finish = this.state.defaultAccessoryColorProps.title;
    ProductConfiguratorHandler.getAllAccessories(
      productId,
      currentCustomer,
      finish
    )
      .then((response) => {
        let accessories = [];
        if (response.success) {
          accessories = response.data.map((item) => {
            let pickedAccessory = this.state.allAccessories.find(
              (element) =>
                element.photoID === item.photoID || element.title === item.title
            );

            return {
              ...item,
              imageURL:
                pickedAccessory === undefined
                  ? item.imageURL
                  : pickedAccessory.imageURL,
              accessoryColor: this.state.defaultAccessoryColorProps,
            };
          });
        }

        this.setState({
          allAccessories: accessories,
        });
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_MissingAccessories,
          "warning"
        );
      });
  }

  findGoldenProduct() {
    let description = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;
    yourPricingHandler
      .getProductGoldenPrice(
        encodeURIComponent(description),
        encodeURIComponent(customerName)
      )

      .then((response) => {
        if (response.success) {
          this.setState((prevState) => ({
            goldenProductPrice: prevState.goldenPriceAccess
              ? response.message
              : null,
            productPrice: prevState.goldenPriceAccess
              ? response.message
              : prevState.productPrice,
            goldenProduct: prevState.goldenPriceAccess,
          }));
        } else {
          this.setState({
            goldenProductPrice: null,
            goldenProduct: false,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_ProductGoldenPrice,
          "error"
        );
      });
  }

  handleSingleCustomerSelect(tld, customer) {
    let customerInfo = customer.value.split("-tier-");
    sessionStorage.setItem("customerName", tld);
    customerName = customer.label;
    customerTLD = tld;
    this.setState(
      {
        isNewCustomer: true,
        selectedCustomer: customer.label,
        tierPricing: this.setCustomerTierPricing(customerInfo),
        customerTier: this.setHandleCustomerTier(customerInfo),
      },
      () => {
        this.getConfigPrice();
        this.findGoldenProduct();
        this.getYourPricingProducts();
      }
    );
  }

  setCustomerTierPricing(customerInfo) {
    return this.state.productType === "led"
      ? priceString + customerInfo[1]
      : priceString + customerInfo[2];
  }

  setHandleCustomerTier(customerInfo) {
    return customerInfo[1] === "1"
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          customerInfo[2],
          "1",
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  handleCustomerSelect(event) {
    if (event === null) {
      sessionStorage.setItem("customerName", "");
      this.trackCustomerSelection("");

      customerName = "";
      this.setState(
        (prevState) => ({
          isNewCustomer: false,
          selectedCustomer: "",
          tierPricing: priceType[0],
          customerTier: prevState.tierList[0],
          goldenProduct: false,
          goldenProductPrice: null,
        }),
        () => {
          this.updateSelectedTier(this.state.tierList[0]);
          this.getConfigPrice();
          this.findGoldenProduct();
          this.getYourPricingProducts();
        }
      );
      return;
    }
    let customerInfo = event.value.split("-tier-");
    if (customerInfo.length > 1) {
      sessionStorage.setItem("customerName", customerInfo[0]);
      this.trackCustomerSelection(customerInfo[0]);
      customerName = customerInfo[0];
      this.setState(
        {
          isNewCustomer: false,
          selectedCustomer: customerInfo[0],
          ledTierPricing: priceString + customerInfo[1],
          constructionTierPricing: priceString + customerInfo[2],
          tierPricing: this.setCustomerTierPricing(customerInfo),
          customerTier: this.setHandleCustomerTier(customerInfo),
        },
        () => {
          this.getConfigPrice();
          this.findGoldenProduct();
          this.getYourPricingProducts();
        }
      );
    } else {
      sessionStorage.setItem("customerName", event.value);
      this.trackCustomerSelection(event.value);
      customerName = event.value;
      this.setState(
        (prevState) => ({
          isNewCustomer: true,
          selectedCustomer: event.value,
          tierPricing: priceType[0],
          customerTier: prevState.tierList[0],
        }),
        () => {
          this.updateSelectedTier(this.state.tierList[0]);
          this.getConfigPrice();
          this.findGoldenProduct();
          this.getYourPricingProducts();
        }
      );
    }
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";
  }

  onProductModelChange(prodModel) {
    let selectedBase = prodModel.title;
    let temp = this.state.allBases.find((item) => item.title === selectedBase);
    let selectedSetId = temp.setId;
    let cstColorProps = this.state.cstColorProps;
    let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;
    let availableColorProps = undefined;

    if (cstColorProps !== null) {
      cstColorProps.title = "CST";
      cstColorProps.name = "CUSTOM";
    }

    let defaultFinish = [];
    defaultFinish = this.state.allOptions.find(
      (opt) => opt.category === itemCategory[0]
    );
    if (defaultFinish !== undefined) {
      defaultFinish = defaultFinish.list[0].list.find(
        (df) => df.setId === selectedSetId
      );

      availableColorProps = allCustomColors.find(
        (item) => item.title === defaultFinish.title
      );

      defaultAccessoryColorProps =
        availableColorProps !== undefined
          ? availableColorProps
          : this.state.defaultAccessoryColorProps;
    }

    this.state.allOptions.forEach((item, i) => {
      item.list.forEach((element) => {
        let defaultElement = element.list.find(
          (x) => x.setId === selectedSetId
        );

        if (
          item.category === itemCategory[0] ||
          item.list.find((clr) => clr.category === "CST")
        ) {
          cstColorProps =
            defaultElement !== undefined && defaultElement.title === "CST"
              ? defaultElement
              : cstColorProps;
        }
        return element;
      });
    });

    this.setState(
      {
        cstColorProps: cstColorProps,
        defaultAccessoryColorProps: defaultAccessoryColorProps,
        selectedBase: selectedBase,
        wattage: selectedBase,
        selectedSetId: selectedSetId,
        defaultFinish: defaultFinish,
        isBoxHovered: false,

        productAccessories: [],
      },
      () => {
        this.trackProductModelElementClick();
        this.updateProductPreview();
        this.generateAccessoriesList();
      }
    );
  }

  updateProductPreview() {
    let description = [];
    let addersReset = [];
    let currentColorProps = this.state.cstColorProps;

    this.state.allOptions.forEach((item, i) => {
      let isItemCovered = false;

      addersReset.push(item.category);
      item.list.forEach((element) => {
        let defaultElement = element.list.find(
          (x) => x.setId === this.state.selectedSetId
        );

        if (item.category === itemCategory[0] && defaultElement !== undefined) {
          let selectedColorProps = this.state.customColorList.find(
            (item) => item.title === this.state.description[i]
          );

          if (selectedColorProps !== undefined) {
            currentColorProps.name = selectedColorProps.name;
            currentColorProps.title = selectedColorProps.title;
            description[i] = selectedColorProps.title;
            isItemCovered = true;
          }

          if (
            selectedColorProps !== undefined &&
            selectedColorProps.title !== defaultElement.title
          ) {
            description[i] = defaultElement.title;
            currentColorProps.name = "CUSTOM";
            currentColorProps.title = "CST";
          }
        }

        if (
          defaultElement !== undefined &&
          defaultElement.title === this.state.description[i]
        ) {
          description[i] = defaultElement.title;
          isItemCovered = true;
        }

        if (defaultElement !== undefined && !isItemCovered) {
          if (defaultElement.valueIndex !== "1") {
            description[i] = "";
          } else {
            description[i] = defaultElement.title;
            isItemCovered = true;
          }
        }
        return element;
      });

      if (description[i] === this.state.description[i]) {
        addersReset = addersReset.filter((x) => x !== item.category);
      }

      let blankDescriptions = [undefined, ""];

      if (
        blankDescriptions.includes(description[i]) &&
        blankDescriptions.includes(this.state.description[i])
      ) {
        addersReset = addersReset.filter((x) => x !== item.category);
      }
    });

    this.setState(
      (prevState) => ({
        wattage: prevState.selectedBase,
        description: description,
        addersReset: addersReset,
        cstColorProps: currentColorProps,
      }),
      this.findGoldenProduct
    );
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  CustomerList() {
    if (this.state.singleCustomer) {
      return null;
    } else {
      return (
        <Row style={{ fontSize: "16px", marginLeft: "-15px" }}>
          <Col md={6} sm={12} xs={12}>
            <ControlLabel>Select Customer</ControlLabel>
            <Creatable
              maxMenuHeight={190}
              value={{
                value: this.state.selectedCustomer,
                label:
                  this.state.selectedCustomer === null ||
                  this.state.selectedCustomer === ""
                    ? "Select..."
                    : this.state.selectedCustomer,
              }}
              styles={customStyles}
              isClearable
              onChange={this.handleCustomerSelect}
              options={this.state.customerList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
          <Col md={4} sm={12} xs={12}>
            <ControlLabel>Customer Tier </ControlLabel>
            {this.state.isNewCustomer ? (
              <Select
                maxMenuHeight={190}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                value={{
                  value: this.state.customerTier,
                  label: this.state.customerTier,
                }}
                styles={customStyles}
                onChange={this.handleTierSelect}
                options={this.state.tierList.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            ) : (
              <FormControl
                readOnly
                type="text"
                value={this.state.customerTier}
                style={{ height: "20px", minHeight: "34px" }}
              />
            )}
          </Col>
        </Row>
      );
    }
  }

  handleTierSelect(e) {
    let tier = e.value;
    this.trackCustomerTierSelection(tier);
    ProductConfiguratorHandler.setCustomerTier(tier)
      .then((response) => {
        if (!response.success) {
          this.addNotification(
            ErrorMessages.CustomerTierNotRegistered,
            "error"
          );
        }
        this.setState(
          (prevState) => ({
            tierPricing:
              tier === prevState.tierList[0] ? priceType[0] : priceType[1],
            customerTier: tier,
          }),
          () => this.getConfigPrice()
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_SetCustomerTier,
          "error"
        );
      });
  }

  updateSelectedTier(tier) {
    ProductConfiguratorHandler.setCustomerTier(tier)

      .then((response) => {
        if (!response.success) {
          this.addNotification(
            ErrorMessages.CustomerTierNotRegistered,
            "error"
          );
        }
        this.setState(
          (prevState) => ({
            tierPricing:
              tier === prevState.tierList[0] ? priceType[0] : priceType[1],
            customerTier: tier,
          }),
          () => this.getConfigPrice()
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_SetCustomerTier,
          "error"
        );
      });
  }

  copyConstructionItem(element) {
    let totalPrice = this.constructionProductPrice(element, true);

    let data = {
      description: element.title,
      productId: this.props.postId.toString(),
    };

    let copyContent = {
      Stock_Code: element.contents.stockCode,
      SKU_Description: element.title,
      Quantity: parseInt(element.itemQuantity, 10).toString(),
      Total_Price: this.state.pricingAccess ? totalPrice : "NA",
      Availability: element.contents.leadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.addNotification,
      "Product Configurator: Construction Product"
    );
  }

  copyAccessory(element) {
    let totalPrice = this.accessoryPrice(element, true);

    let data = {
      description: element.title,
      productId: this.props.postId.toString(),
    };

    let copyContent = {
      Stock_Code: element.stockCode,
      SKU_Description: element.title.replace(
        "CST",
        element.accessoryColor.title
      ),
      Quantity: parseInt(element.quantity, 10).toString(),
      Total_Price: this.state.pricingAccess ? totalPrice : "NA",
      Availability: element.leadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.addNotification,
      "Product Configurator: Accessory"
    );
  }

  copyQuickShipItem(element) {
    let totalPrice = this.quickShipProductPrice(element, true);

    let data = {
      description: element.description,
      productId: this.props.postId.toString(),
    };

    let copyContent = {
      Stock_Code: element.stockCode,
      SKU_Description: element.description,
      Quantity: parseInt(element.quantity, 10).toString(),
      Total_Price: this.state.pricingAccess ? totalPrice : "NA",
      Availability: element.leadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.addNotification,
      "Product Configurator: Quick Ship"
    );
  }

  copyGoldenItem(element) {
    let totalPrice = (
      parseFloat(element.GoldenPrice).toFixed(2) *
      parseInt(element.BaseQuantity, 10)
    ).toFixed(2);

    let data = {
      description: element.Description,
      productId: this.props.postId.toString(),
    };

    let copyContent = {
      Stock_Code: element.StockCode,
      SKU_Description: element.Description,
      Quantity: parseInt(element.BaseQuantity, 10).toString(),
      Total_Price: this.state.pricingAccess ? totalPrice : "NA",
      Availability: element.LeadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.addNotification,
      "Product Configurator: Your Pricing"
    );
  }

  copyLEDItem() {
    let description = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;

    let totalPrice = this.ledProductPrice(true);

    let data = {
      description: description,
      productId: this.props.postId.toString(),
    };

    let copyContent = {
      Stock_Code: this.state.stockCode,
      SKU_Description: description,
      Quantity: parseInt(this.state.configQuantity, 10).toString(),
      Total_Price: this.state.pricingAccess ? totalPrice : "NA",
      Availability: this.state.configLeadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.addNotification,
      "Product Configurator: LED Product"
    );
  }

  addToCart(type, element) {
    this.setState({
      selectedProductCriteria: type,
      selectedElement: element,
    });

    let isCustomerValid = this.customerValidityCheck();
    if (isCustomerValid) {
      switch (type) {
        case productCriteria[0]:
          this.addLedProductsToOrderSummary();
          break;
        case productCriteria[1]:
          this.addConstructionProductToOrderSummary(element);
          break;
        case productCriteria[2]:
          this.addAccessoriesToOrderSummary(element);
          break;
        case productCriteria[3]:
          this.addQuickShipProductToOrderSummary(element);
          break;
        case productCriteria[4]:
          this.addGoldenProductToOrderSummary(element);
          break;
        default:
          this.addLedProductsToOrderSummary();
      }
    }
  }

  addLedProductsToOrderSummary() {
    let item = {};
    let description = [...this.state.description];
    description.forEach((_, index) => {
      if (description[index] === undefined) {
        description[index] = "";
      }
    });

    let currentProduct = {
      description: `${this.state.wattage}-${this.state.description
        .filter((e) => e)
        .join("-")}`,
      stockCode: this.state.stockCode,
    };

    item.description = description;
    item.wattage = this.state.selectedBase;
    item.setId = this.state.selectedSetId;
    item.price = this.state.goldenProduct
      ? this.state.goldenProductPrice
      : this.ternaryEvaluation(
          parseInt(this.state.nonClearancePrice, 10),
          0,
          this.state.productPrice,
          this.state.nonClearancePrice
        );
    item.GoldenPrice = this.state.goldenProduct
      ? this.state.goldenProductPrice
      : "0";
    item.golden = this.state.goldenProduct;
    item.SalePrice = this.state.saleProductPrice;
    item.baseQuantity = this.state.baseQuantity;
    item.adderPrice = this.state.adderPrice;
    item.cstColor =
      this.state.cstColorProps === null
        ? "CST"
        : this.state.cstColorProps.title;
    item.Quantity = this.state.configQuantity;

    let configurationList = [item];

    let orderList = {
      product_list: configurationList.length > 0 ? configurationList : [],
      constructionOrAccessory_list: [],
      quickShip_list: [],
      goldenItemsList: [],
    };

    this.addProductsToCart(currentProduct, orderList, configurationList.length);
  }

  addConstructionProductToOrderSummary(element) {
    let item = {};
    item.GoldenPrice = "0";
    item.Name = element.contents.name;
    item.Price1 = element.contents.price1;
    item.Price2 = element.contents.price2;
    item.Quantity = element.itemQuantity;
    item.SalePrice = element.contents.salePrice;
    item.Title = element.title;
    item.valueIndex = element.contents.valueIndex;
    item.index = 0;

    let currentProduct = {
      description: element.title,
      stockCode: element.contents.stockCode,
    };

    let constructionList = [item];

    let orderList = {
      product_list: [],
      constructionOrAccessory_list:
        constructionList.length > 0 ? constructionList : [],
      quickShip_list: [],
      goldenItemsList: [],
    };

    this.addProductsToCart(currentProduct, orderList, constructionList.length);
  }

  addAccessoriesToOrderSummary(element) {
    let item = {};
    item.Accessories = element.title;
    item.CustomFinishAdder = element.customFinishAdder;
    item.GoldenPrice = element.goldenPrice;
    item.PhotoID = element.photoID;
    item.Price1 = element.price1;
    item.Price2 = element.price2;
    item.Quantity = +parseFloat(element.quantity);
    item.SalePrice = element.salePrice;
    item.StandardFinishes = element.standardFinishes;
    item.adderPrice = "0";
    item.baseQuantity = "1";
    item.color = element.accessoryColor.title;
    item.setId = element.setId;
    item.url = element.imageURL;

    let accessoryList = [item];

    let currentProduct = {
      description: element.title.replace("CST", element.accessoryColor.title),
      stockCode: element.stockCode,
    };

    let orderList = {
      product_list: [],
      constructionOrAccessory_list:
        accessoryList.length > 0 ? accessoryList : [],
      quickShip_list: [],
      goldenItemsList: [],
    };

    this.addProductsToCart(currentProduct, orderList, accessoryList.length);
  }

  addQuickShipProductToOrderSummary(element) {
    let item = {};

    item.Description = element.description;
    item.Price1 = element.price1;
    item.Price2 = element.price2;
    item.StockCode = element.stockCode;
    item.Type = "Quick Ship";
    item.QtyAvailable = element.qtyAvailable;
    item.SalePrice = element.salePrice;
    item.GoldenPrice = element.goldenPrice;
    item.Quantity = element.quantity;

    let quickShipProducts = [item];

    let currentProduct = {
      description: element.description,
      stockCode: element.stockCode,
    };

    let orderList = {
      product_list: [],
      constructionOrAccessory_list: [],
      quickShip_list: quickShipProducts.length > 0 ? quickShipProducts : [],
      goldenItemsList: [],
    };

    this.addProductsToCart(currentProduct, orderList, quickShipProducts.length);
  }

  addGoldenProductToOrderSummary(element) {
    let item = {};
    item.Description = element.Description;
    item.StockCode = element.StockCode;
    item.Quantity = parseInt(element.BaseQuantity);
    item.SalePrice = element.SalePrice;
    item.GoldenPrice = element.GoldenPrice;
    item.isSelected = true;
    item.BaseQuantity = element.BaseQuantity;

    let goldenProduct = [item];

    let currentProduct = {
      description: element.Description,
      stockCode: element.StockCode,
    };

    let orderList = {
      product_list: [],
      constructionOrAccessory_list: [],
      quickShip_list: [],
      goldenItemsList: goldenProduct.length > 0 ? goldenProduct : [],
    };

    this.addProductsToCart(currentProduct, orderList, goldenProduct.length);
  }

  addProductsToCart(currentProduct, orderlist, itemListLength) {
    let data = {
      productId: this.props.postId,
      title: this.props.title,
      action: "AddtoSummary",
      setId: this.state.selectedSetId,
      customer_name:
        customerTLD !== "" ? customerTLD : this.state.selectedCustomer,
      orderlist: orderlist,
    };

    if (!itemListLength) {
      this.trackAddToCartButtonClick(ErrorMessages.ProductConfig_ValidQuantity);
      this.addNotification(ErrorMessages.ProductConfig_ValidQuantity, "error");
      return;
    }

    ProductConfiguratorHandler.addToOrderSummary(data)
      .then((response) => {
        if (!response.success && response.message === "Failed") {
          this.trackAddToCartButtonClick(ErrorMessages.ProductConfig_AddItems);
          this.addNotification(ErrorMessages.ProductConfig_AddItems, "error");
        } else if (!response.success && response.message === "Limit") {
          this.trackAddToCartButtonClick(
            ErrorMessages.ProductConfig_CartLimitMessage
          );
          this.addNotification(
            ErrorMessages.ProductConfig_CartLimitMessage,
            "warning"
          );
        } else if (!response.success) {
          this.trackAddToCartButtonClick(response.message);
          this.setState({
            showConfirmationPopup: true,
            message: response.message,
          });
        } else if (response.success) {
          this.trackAddToCartButtonSuccess(
            currentProduct.description,
            currentProduct.stockCode
          );
          this.setState({ addedToOrderSummary: true });
          this.addNotification(
            DisplayMessages.ProductConfig_ProductAddMsg,
            "info"
          );
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.ProductConfig_AddToSummary, "error");
      });
  }

  checkBaseConstraints() {
    let data = this.state.allBases.find(
      (item) =>
        item.contents.constraintMessage !== "0" &&
        item.contents.constraintMessage !== "" &&
        item.contents.constraintMessage !== "NA"
    );
    if (data != null) {
      this.setState({
        baseConstraintPresent: true,
      });
    }
  }

  checkAccessoryConstraints() {
    let data = this.state.allAccessories.find(
      (item) =>
        item.constraintMessage !== "0" &&
        item.constraintMessage !== "" &&
        item.constraintMessage !== "NA"
    );
    if (data != null) {
      this.setState({
        accessoryConstraintPresent: true,
      });
    }
  }

  checkIndividualOptionConstraint(item) {
    let isConstraintPresent = false;

    let data = item.list.find((listItem) =>
      listItem.list.find(
        (subList) =>
          subList.constraintMessage !== "0" &&
          subList.constraintMessage !== "" &&
          subList.constraintMessage !== "NA"
      )
    );
    if (data != null) {
      isConstraintPresent = true;
    }

    return isConstraintPresent;
  }

  checkOptionConstraints() {
    this.state.allOptions.forEach((item) => {
      let isConstraintPresent = this.checkIndividualOptionConstraint(item);
      if (isConstraintPresent) {
        this.setState({
          optionConstraintPresent: true,
        });
      }
    });
  }

  constraintMessageSection() {
    return (
      <div>
        {this.state.currentConstraints?.map((item, index) => (
          <Row key={item}>
            <p className="popup-text-black">{item}</p>
          </Row>
        ))}
        {(this.state.baseConstraintPresent ||
          this.state.accessoryConstraintPresent ||
          this.state.optionConstraintPresent) && (
          <Row>
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={() => {
                this.trackSeeAllNotesButtonClick();
                this.setState({ showConstraints: true });
              }}
            >
              See all Notes
            </Button>
          </Row>
        )}

        <Modal
          className="all-constraints-modal"
          show={this.state.showConstraints}
          onHide={() => {
            this.setState({ showConstraints: false });
          }}
        >
          <Modal.Header closeButton>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              All Notes
            </h3>
          </Modal.Header>
          <Modal.Body className="all-constraints-modal-body">
            {this.state.allBases && this.state.baseConstraintPresent && (
              <div>
                <Row className="container attachmentList-container">
                  <h4
                    style={{
                      fontWeight: "bolder",
                      paddingLeft: "15px",
                    }}
                    className="popup-blue-text"
                  >
                    Product Model
                  </h4>
                  {this.state.allBases.map((item) => (
                    <Row className="message-content" key={item.title}>
                      {item.contents.constraintMessage}
                    </Row>
                  ))}
                </Row>
                <hr />
              </div>
            )}
            {this.state.allAccessories &&
              this.state.accessoryConstraintPresent && (
                <div>
                  <Row className="container attachmentList-container">
                    <h4
                      style={{
                        fontWeight: "bolder",
                        paddingLeft: "15px",
                      }}
                      className="popup-blue-text"
                    >
                      Accessory
                    </h4>
                    {this.state.allAccessories.map((item) => (
                      <Row className="message-content" key={item.title}>
                        {item.constraintMessage}
                      </Row>
                    ))}
                  </Row>
                  <hr />
                </div>
              )}
            {this.state.allOptions?.map((item) => {
              let isConstraintPresent =
                this.checkIndividualOptionConstraint(item);
              return (
                isConstraintPresent && (
                  <div key={item.category}>
                    <Row className="container attachmentList-container">
                      <h4
                        style={{
                          fontWeight: "bolder",
                          paddingLeft: "15px",
                        }}
                        className="popup-blue-text option-title"
                      >
                        {item.category}
                      </h4>
                      {item.list.map((listItem) => (
                        <div key={listItem.category}>
                          {listItem.list.map((subList) =>
                            subList.constraintMessage !== "0" &&
                            subList.constraintMessage !== "" &&
                            subList.constraintMessage !== "NA" ? (
                              <Row
                                className="message-content"
                                key={subList.stockCode}
                              >
                                <span style={{ fontWeight: "bolder" }}>
                                  {subList.title} - &nbsp;&nbsp;
                                </span>
                                {subList.constraintMessage}
                              </Row>
                            ) : null
                          )}
                        </div>
                      ))}
                    </Row>
                    <hr />
                  </div>
                )
              );
            })}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  ledProductPrice(isCopyToClipboard) {
    let qty = isNaN(this.state.configQuantity) ? 1 : this.state.configQuantity;

    let goldenPrice = +parseFloat(this.state.goldenProductPrice);
    let salePrice = +parseFloat(this.state.saleProductPrice);

    return isCopyToClipboard
      ? this.getProductPrice(goldenPrice, salePrice, qty)
      : this.showProductPrice(goldenPrice, salePrice, qty);
  }

  showProductPrice(goldenPrice, salePrice, qty) {
    return (
      <div>
        {this.state.goldenProduct &&
        this.state.goldenPriceAccess &&
        goldenPrice > 0
          ? this.showGoldenPriceAvailableSection(goldenPrice, salePrice, qty)
          : this.showGoldenPriceUnavailableSection(salePrice, qty)}
      </div>
    );
  }

  getProductPrice(goldenPrice, salePrice, qty) {
    return this.state.goldenProduct &&
      this.state.goldenPriceAccess &&
      goldenPrice > 0
      ? this.getGoldenPriceAvailableSection(goldenPrice, salePrice, qty)
      : this.getGoldenPriceUnavailableSection(salePrice, qty);
  }

  showGoldenPriceAvailableSection(goldenPrice, salePrice, qty) {
    if ((goldenPrice <= salePrice && salePrice !== 0) || salePrice === 0) {
      return (
        <div className="gold-pricing form-control current-configurator">
          {"$" + parseFloat(this.state.goldenProductPrice * qty).toFixed(2)}
        </div>
      );
    } else {
      return (
        <div
          className="form-control current-configurator"
          style={{ color: "red" }}
        >
          {"$" + parseFloat(this.state.saleProductPrice * qty).toFixed(2)}
        </div>
      );
    }
  }

  getGoldenPriceAvailableSection(goldenPrice, salePrice, qty) {
    return (goldenPrice <= salePrice && salePrice !== 0) || salePrice === 0
      ? parseFloat(this.state.goldenProductPrice * qty).toFixed(2)
      : parseFloat(this.state.saleProductPrice * qty).toFixed(2);
  }

  showGoldenPriceUnavailableSection(salePrice, qty) {
    if (salePrice !== 0) {
      return (
        <div
          className="form-control current-configurator"
          style={{ color: "red" }}
        >
          {"$" + parseFloat(this.state.saleProductPrice * qty).toFixed(2)}
        </div>
      );
    } else {
      return (
        <div className="form-control current-configurator">
          {"$" + parseFloat(this.state.productPrice * qty).toFixed(2)}
        </div>
      );
    }
  }

  getGoldenPriceUnavailableSection(salePrice, qty) {
    return salePrice !== 0
      ? parseFloat(this.state.saleProductPrice * qty).toFixed(2)
      : parseFloat(this.state.productPrice * qty).toFixed(2);
  }

  constructionProductPrice(element, isCopyToClipboard) {
    let qty = isNaN(element.itemQuantity)
      ? 1
      : +parseFloat(element.itemQuantity).toFixed(2);

    let goldenPrice = 0;
    let salePrice = +parseFloat(element.contents.salePrice) * qty;
    let goldenList = element.contents.goldenList;
    let productPrice =
      +parseFloat(element.contents[this.state.tierPricing]) * qty;
    let goldenCustomer = goldenList.find(
      (item) => item.CustomerName === this.state.selectedCustomer
    );
    if (goldenCustomer !== undefined && this.state.goldenPriceAccess) {
      goldenPrice += parseFloat(goldenCustomer.UnitPrice) * qty;
    }

    return isCopyToClipboard
      ? this.getItemPrice(salePrice, goldenPrice, productPrice)
      : this.showItemPrice(salePrice, goldenPrice, productPrice);
  }

  showItemPrice(salePrice, goldenPrice, productPrice) {
    if (salePrice === 0) {
      if (goldenPrice === 0 || !this.state.goldenPriceAccess) {
        return <div>$ {productPrice.toFixed(2)}</div>;
      } else {
        return <div className="gold-pricing">$ {goldenPrice.toFixed(2)}</div>;
      }
    } else if (
      goldenPrice === 0 ||
      goldenPrice > salePrice ||
      !this.state.goldenPriceAccess
    ) {
      return (
        <div>
          <div style={{ textDecorationLine: "line-through" }}>
            $ {productPrice.toFixed(2)}
          </div>
          <div style={{ color: "red" }}>{salePrice.toFixed(2)}</div>
        </div>
      );
    } else {
      return <div className="gold-pricing">$ {goldenPrice.toFixed(2)}</div>;
    }
  }

  getItemPrice(salePrice, goldenPrice, productPrice) {
    if (salePrice === 0) {
      return goldenPrice === 0 || !this.state.goldenPriceAccess
        ? productPrice.toFixed(2)
        : goldenPrice.toFixed(2);
    } else if (
      goldenPrice === 0 ||
      goldenPrice > salePrice ||
      !this.state.goldenPriceAccess
    ) {
      return salePrice.toFixed(2);
    } else {
      return goldenPrice.toFixed(2);
    }
  }

  accessoryPrice(element, isCopyToClipboard) {
    let qty = isNaN(element.quantity)
      ? 1
      : +parseFloat(element.quantity).toFixed(2);

    let description = element.title.replace(
      "CST",
      element.accessoryColor.title
    );
    let customFinishAdder = +parseFloat(element.customFinishAdder);
    let goldenPrice = 0;
    let salePrice = +parseFloat(element.salePrice) * qty;
    let goldenList = element.goldenList;
    let productPrice = +parseFloat(element[this.state.tierPricing]);

    let accessoryStandardFinishes = element.standardFinishes.split(",");
    let accessoryCustomList = allCustomColors.filter(
      (acc) => !accessoryStandardFinishes.find((item) => item === acc.title)
    );

    let selectedAccessoryColor = element.accessoryColor;
    if (
      accessoryCustomList.some(
        (item) => item.title === selectedAccessoryColor.title
      )
    ) {
      productPrice += customFinishAdder;
    }

    productPrice *= qty;

    let goldenCustomer = goldenList.find(
      (item) =>
        item.CustomerName === this.state.selectedCustomer &&
        item.Description === description
    );
    if (goldenCustomer !== undefined && this.state.goldenPriceAccess) {
      goldenPrice += parseFloat(goldenCustomer.UnitPrice) * qty;
    }

    return isCopyToClipboard
      ? this.getItemPrice(salePrice, goldenPrice, productPrice)
      : this.showItemPrice(salePrice, goldenPrice, productPrice);
  }

  constraintMessagePopover(item) {
    if (
      item.constraintMessage === "0" ||
      item.constraintMessage === "" ||
      item.constraintMessage === "NA"
    ) {
      return <Tooltip id="disabled-constraint-hover" />;
    } else {
      return (
        <Popover id="enabled-constraint-hover">
          <p style={{ wordBreak: "break-word" }}>{item.constraintMessage}</p>
        </Popover>
      );
    }
  }

  customAccessoryColorSelector(element, index) {
    let standardFinishes = element.standardFinishes;
    let accessoryStandardFinishes = standardFinishes.split(",");
    let accessoryStandardList = allCustomColors.filter((acc) =>
      accessoryStandardFinishes.find((item) => item === acc.title)
    );

    let accessoryCustomList = allCustomColors.filter(
      (acc) => !accessoryStandardFinishes.find((item) => item === acc.title)
    );

    return (
      <Popover
        id="custom-color-selector"
        style={{ width: "300px" }}
        title={
          accessoryStandardList.length > 0
            ? "Standard | Custom Finishes:"
            : "Custom Finishes:"
        }
      >
        <tr>
          {accessoryStandardList.length > 0 ? (
            <Row>
              <div className="custom-color-div">
                {accessoryStandardList.map((item) => {
                  return (
                    <Col
                      className="colors-column"
                      md={3}
                      sm={3}
                      xs={3}
                      key={item.title}
                    >
                      <Button
                        className={
                          element.accessoryColor &&
                          element.accessoryColor.title === item.title
                            ? "square-selected" + item.className
                            : "square" + item.className
                        }
                        value={item.title}
                        onClick={() =>
                          this.handleAccessoryCustomColorClick(
                            item.title,
                            item.name,
                            element
                          )
                        }
                      />
                      <p
                        style={{
                          fontWeight:
                            element.accessoryColor &&
                            element.accessoryColor.title === item.title
                              ? "bolder"
                              : "normal",
                        }}
                      >
                        {item.name}
                      </p>
                    </Col>
                  );
                })}
              </div>
              <hr className="custom-color-hr" />
            </Row>
          ) : null}
          <Row>
            <div className="custom-color-div">
              {accessoryCustomList.map((item) => {
                return (
                  <Col
                    className="colors-column"
                    md={3}
                    sm={3}
                    xs={3}
                    key={item.title}
                  >
                    <Button
                      className={
                        element.accessoryColor &&
                        element.accessoryColor.title === item.title
                          ? "square-selected" + item.className
                          : "square" + item.className
                      }
                      value={item.title}
                      onClick={() =>
                        this.handleAccessoryCustomColorClick(
                          item.title,
                          item.name,
                          element
                        )
                      }
                    />
                    <p
                      style={{
                        fontWeight:
                          element.accessoryColor &&
                          element.accessoryColor.title === item.title
                            ? "bolder"
                            : "normal",
                      }}
                    >
                      {item.name}
                    </p>
                  </Col>
                );
              })}
            </div>
          </Row>
          <br />
          <Row>
            <p style={{ textAlign: "center" }}>Call us for more finishes!</p>
          </Row>
        </tr>{" "}
      </Popover>
    );
  }

  customColorSelector(i) {
    return (
      <Popover
        id="custom-color-selector"
        style={{ width: "300px" }}
        title={"Custom Finishes:"}
      >
        <tr>
          <Row>
            <div className="custom-color-div">
              {this.state.customColorList.map((item) => {
                return (
                  <Col
                    className="colors-column"
                    md={3}
                    sm={3}
                    xs={3}
                    key={item.title}
                  >
                    <Button
                      className={
                        this.state.cstColorProps &&
                        this.state.cstColorProps.title === item.title
                          ? "square-selected" + item.className
                          : "square" + item.className
                      }
                      value={item.title}
                      onClick={() =>
                        this.handleCustomColorClick(
                          item.title,
                          item.name,
                          this.state.cstIndex
                        )
                      }
                    />
                    <p
                      style={{
                        fontWeight:
                          this.state.cstColorProps &&
                          this.state.cstColorProps.title === item.title
                            ? "bolder"
                            : "normal",
                      }}
                    >
                      {item.name}
                    </p>
                  </Col>
                );
              })}
            </div>
          </Row>
          <br />
          <Row>
            <p style={{ textAlign: "center" }}>Call us for more finishes!</p>
          </Row>
        </tr>{" "}
      </Popover>
    );
  }

  goldenLegendInYourPricingTable() {
    if (
      this.state.goldenProductsList &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <div className="pc-section">
          <Row>
            <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> =
            Your Special Pricing
          </Row>
        </div>
      );
    } else return null;
  }

  goldenLegendInQuickShipTable() {
    let isGoldenProductAvailable = false;
    this.state.quickShipProducts.forEach((element) => {
      let goldenPrice = 0;
      let salePrice = +parseFloat(element.salePrice);
      let goldenList = element.goldenList;
      let goldenCustomer = goldenList.find(
        (item) => item.CustomerName === this.state.selectedCustomer
      );
      if (goldenCustomer !== undefined) {
        goldenPrice += parseFloat(goldenCustomer.UnitPrice);
      }

      if (
        (salePrice === 0 && goldenPrice !== 0) ||
        (goldenPrice !== 0 && goldenPrice <= salePrice)
      ) {
        isGoldenProductAvailable = true;
      }
    });

    if (
      isGoldenProductAvailable &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <div className="pc-section">
          <Row>
            <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> =
            Your Special Pricing
          </Row>
        </div>
      );
    } else return null;
  }

  quickShipHeaders() {
    return (
      <Row className="section-row-header">
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Stock Code
        </Col>
        <Col md={3} sm={3} xs={3} className="section-row-headcell">
          Description
        </Col>
        <Col md={2} sm={2} xs={2} className="section-row-headcell">
          Specs
        </Col>
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Qty
        </Col>
        {this.state.pricingAccess ? (
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Total Price
          </Col>
        ) : null}
        <Col md={2} sm={2} xs={2} className="section-row-headcell">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Estimated Ship Time</span>
            <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
          </div>
        </Col>
        <Col
          md={this.state.pricingAccess ? 1 : 2}
          sm={this.state.pricingAccess ? 1 : 2}
          xs={this.state.pricingAccess ? 1 : 2}
          className="section-row-headcell"
        >
          Part Category
        </Col>
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Add to Cart
        </Col>
      </Row>
    );
  }

  quickShipBody() {
    if (this.state.quickShipProducts.length > 0) {
      return (
        <Row style={{ minWidth: "1000px" }}>
          <div className="scrollable-div" style={{ maxHeight: "375px" }}>
            {this.state.quickShipProducts.map((element, index) => {
              return (
                <Row style={{ display: "flex" }} key={element.stockCode}>
                  <Col md={1} sm={1} xs={1} className="section-row-cell ">
                    {element.stockCode}
                  </Col>

                  <Col md={3} sm={3} xs={3} className="section-row-cell">
                    {element.description}
                  </Col>
                  <Col
                    md={2}
                    sm={2}
                    xs={2}
                    className="section-row-cell"
                    onClick={(e) => this.showQuickShipSpecLink(element)}
                  >
                    <SpecTooltipPopup
                      specValue={element.specValue}
                      productId={this.props.postId}
                      stockCodeTitle={element.stockCode}
                      descriptionTitle={element.description}
                      engSpecLink={this.state.engSpecLink}
                      frSpecLink={this.state.frSpecLink}
                      addNotification={this.addNotification}
                      isNavigationEnabled={false}
                      onModal={false}
                    />
                  </Col>

                  <Col md={1} sm={1} xs={1} className="section-row-cell">
                    <FormControl
                      type="number"
                      min="1"
                      max="10000"
                      pattern="[0-9]*"
                      name={index}
                      style={{ width: "150px" }}
                      value={element.quantity}
                      onBlur={(e) => this.onQuickShipQuantityBlur(e, element)}
                      onChange={(e) =>
                        this.onQuickShipQuantityChange(e, element)
                      }
                      aria-label="Quantity"
                    />
                  </Col>

                  {this.state.pricingAccess ? (
                    <Col md={1} sm={1} xs={1} className="section-row-cell">
                      {this.quickShipProductPrice(element, false)}
                    </Col>
                  ) : null}

                  <Col md={2} sm={2} xs={2} className="section-row-cell">
                    {element.leadTime}
                  </Col>
                  <Col
                    md={this.state.pricingAccess ? 1 : 2}
                    sm={this.state.pricingAccess ? 1 : 2}
                    xs={this.state.pricingAccess ? 1 : 2}
                    className="section-row-cell"
                  >
                    {element.partCategory === "B"
                      ? Miscellaneous.PartCategoryStocked
                      : Miscellaneous.PartCategoryNotStocked}
                  </Col>

                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="section-row-cell copy-or-add"
                  >
                    <Button
                      onClick={() => this.copyQuickShipItem(element)}
                      className={"glyphicon glyphicon-duplicate"}
                      aria-label="Copy to clipboard"
                    />
                    <Button
                      onClick={() =>
                        this.addToCart(productCriteria[3], element)
                      }
                      className={"glyphicon glyphicon-plus"}
                      aria-label="Add Item"
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        </Row>
      );
    } else {
      return null;
    }
  }

  ProductConfiguratorSection() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    return (
      <div style={{ fontSize: "16px", align: "center" }}>
        <Panel className="collapsible-panel " defaultExpanded>
          <Tabs
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="content-tabs"
            onSelect={(e) => this.trackSubtabNavigation(e)}
          >
            {this.productConfiguratorTab()}
            {this.accessoriesTab()}
            {this.quickShipTab()}
            {this.yourPricingTab()}
            {this.disclaimerSection()}
          </Tabs>
        </Panel>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
        <ConfirmationPopUp
          show={this.state.showConfirmationPopup}
          title="Clear Estimate Summary!"
          text={this.state.message}
          clearTableData={this.clearTableData}
          handleClearClose={this.handleClearClose}
        />
      </div>
    );
  }

  productConfiguratorTab() {
    return (
      <Tab eventKey={1} title="Product Configurator">
        <Panel.Collapse>
          <Panel.Body>
            {this.state.productType === "led"
              ? this.getLedConfigurator()
              : this.getConstructionConfigurator()}
          </Panel.Body>
        </Panel.Collapse>
      </Tab>
    );
  }

  accessoriesTab() {
    if (
      this.state.allAccessories.length > 0 &&
      this.state.productType === "led"
    ) {
      return (
        <Tab eventKey={2} title="Accessories">
          <Panel.Collapse>
            <Panel.Body>{this.getAccessories()}</Panel.Body>
          </Panel.Collapse>
        </Tab>
      );
    } else {
      return null;
    }
  }

  getAccessories() {
    return (
      <div style={{ margin: "0px 35px" }}>
        <br />
        {this.goldenLegendInAccessories()}
        {this.accessoriesHeader()}
        {this.accessoriesBody()}
      </div>
    );
  }

  quickShipTab() {
    if (this.state.quickShipProducts.length > 0) {
      return (
        <Tab
          eventKey={3}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <OverlayTrigger
                trigger={["hover", "focus", "click"]}
                rootClose
                placement="bottom"
                overlay={
                  <Tooltip id="quickship-outage">
                    <div>{DisplayMessages.QuickShipOutage}</div>
                  </Tooltip>
                }
              >
                <span className="glyphicon glyphicon-info-sign" />
              </OverlayTrigger>
              <span style={{ paddingLeft: "5px" }}> Quick Ships </span>
            </div>
          }
        >
          <Panel.Collapse>
            <Panel.Body>{this.quickShipTable()}</Panel.Body>
          </Panel.Collapse>
        </Tab>
      );
    } else {
      return null;
    }
  }

  quickShipTable() {
    return (
      <div style={{ margin: "0px 35px" }}>
        <br />
        {this.goldenLegendInQuickShipTable()}
        {this.quickShipHeaders()}
        {this.quickShipBody()}
      </div>
    );
  }

  yourPricingTab() {
    if (
      this.state.pricingAccess &&
      this.state.goldenPriceAccess &&
      this.state.goldenProductsList.length > 0
    ) {
      return (
        <Tab eventKey={4} title="Your Pricing">
          <Panel.Collapse>
            <Panel.Body>{this.yourPricingTable()}</Panel.Body>
          </Panel.Collapse>
        </Tab>
      );
    } else {
      return null;
    }
  }

  yourPricingTable() {
    return (
      <div style={{ margin: "0px 35px" }}>
        <br />
        {this.goldenLegendInYourPricingTable()}
        {this.yourPricingHeader()}
        {this.yourPricingBody()}
      </div>
    );
  }

  disclaimerSection() {
    return (
      <Row className="margins-disclaimer">
        <Disclaimer
          last_updated={this.state.last_updated}
          type="OrderSummary"
        />
        {this.state.invLink.id !== "" ? (
          <div
            className="disclaimer"
            style={{
              fontWeight: "bolder",
            }}
          >
            <Label onClick={() => this.getDashBoard()} className="mimic-link">
              {Miscellaneous.InventoryLookupName}
            </Label>
          </div>
        ) : null}
      </Row>
    );
  }

  getLedConfigurator() {
    return this.state.ledSpecValue !== "default"
      ? this.ledConfiguratorDisplaySection()
      : this.ledConfiguratorLoadingSection();
  }

  ledConfiguratorDisplaySection() {
    return (
      <div>
        <br />
        <div style={{ margin: "0px 35px" }}>
          {this.goldenLegendInLedConfigurator()}
        </div>
        {this.productPreviewSection()}
        {this.configuratorSelectionSection()}
      </div>
    );
  }

  ledConfiguratorLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.LEDConfigurator_Loading}
        size={50}
      />
    );
  }

  goldenLegendInLedConfigurator() {
    let goldenPrice = +parseFloat(this.state.goldenProductPrice);
    let salePrice = +parseFloat(this.state.saleProductPrice);

    if (
      this.state.goldenProduct &&
      this.state.goldenPriceAccess &&
      goldenPrice > 0
    ) {
      if ((goldenPrice <= salePrice && salePrice !== 0) || salePrice === 0) {
        return (
          <div className="pc-section">
            <Row>
              <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> =
              Your Special Pricing
            </Row>
          </div>
        );
      } else return null;
    } else return null;
  }

  productPreviewSection() {
    return (
      <Row className="product-preview-section">
        <div id="stockCode" className="preview-columns">
          <div className="product-preview-heading">Stock Code</div>
          <div className="form-control current-configurator">
            {this.state.stockCode}
          </div>
        </div>

        <div id="description" className="preview-columns">
          <div className="product-preview-heading">Description</div>
          <div className="form-control current-configurator">
            {`${this.state.wattage}-${this.state.description
              .filter((e) => e)
              .join("-")}`}
          </div>
        </div>

        <div
          id="specs"
          className="preview-columns"
          onClick={(e) => this.showLEDSpecLink()}
        >
          <div className="product-preview-heading">Specs</div>

          <div
            className="text-center current-configurator-specs"
            style={{ border: "1px solid #ccc" }}
          >
            <SpecTooltipPopup
              specValue={this.state.ledSpecValue}
              productId={this.props.postId}
              stockCodeTitle={this.state.stockCode}
              descriptionTitle={this.state.specLinkPopupDescription}
              engSpecLink={this.state.engSpecLink}
              frSpecLink={this.state.frSpecLink}
              addNotification={this.addNotification}
              isNavigationEnabled={false}
              onModal={false}
            />
          </div>
        </div>

        <div id="quantity" className="preview-columns">
          <ControlLabel className="product-preview-heading">
            Quantity
          </ControlLabel>
          <FormControl
            className="current-configurator-quantity"
            type="number"
            min="1"
            max="10000"
            pattern="[0-9]*"
            value={this.state.configQuantity}
            onBlur={this.onConfigQuantityBlur}
            onChange={this.onConfigQuantityChange}
            aria-label="Quantity"
          />
        </div>

        <div
          hidden={!this.state.pricingAccess}
          id="unitPrice"
          className="preview-columns"
        >
          <div className="product-preview-heading">Total Price</div>
          {this.ledProductPrice(false)}
        </div>

        <div id="estimatedShipTime" className="preview-columns">
          <ControlLabel className="product-preview-heading">
            <OverlayTrigger
              trigger={["hover"]}
              rootClose
              delay={0}
              placement="top"
              overlay={
                <Tooltip
                  id="prod-config-EST-tooltip"
                  style={{
                    opacity: 1,
                  }}
                >
                  <Disclaimer
                    last_updated={this.state.last_updated}
                    type="ProductConfigurator"
                  />
                </Tooltip>
              }
            >
              <span className="glyphicon glyphicon-info-sign" />
            </OverlayTrigger>{" "}
            Estimated Ship Time
          </ControlLabel>
          <div className="form-control current-configurator">
            {this.state.configLeadTime}
          </div>
        </div>

        <div id="partCategory" className="preview-columns">
          <ControlLabel className="product-preview-heading">
            Part Category
          </ControlLabel>
          <div className="form-control current-configurator">
            {this.state.configPartCategory}
          </div>
        </div>

        <div id="blankSpace" style={{ width: "30px" }} />

        <div className="preview-columns">
          <Button
            className=" glyphicon glyphicon-duplicate copy-btn"
            onClick={this.copyLEDItem}
          />
        </div>

        <div className="preview-columns">
          <Button
            bsStyle="primary"
            className="button-blue"
            disabled={
              !this.state.productPrice &&
              !this.state.nonClearancePrice &&
              !this.state.goldenProductPrice
            }
            onClick={() => this.addToCart(productCriteria[0], null)}
            style={{ marginTop: "27px" }}
          >
            Add to Cart
          </Button>
        </div>
      </Row>
    );
  }

  configuratorSelectionSection() {
    return (
      <div>
        <Row className="panel-config">
          <table className="config-table">
            <tbody>
              <tr className="config-columns">
                <td className="config-details-productmodel-td">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th className="config-details">
                          <h5>Product Model</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allBases.map((item, i) => {
                        let price = item.contents[this.state.tierPricing];
                        return (
                          <tr key={item.title}>
                            <td
                              className={
                                this.state.selectedBase === item.title
                                  ? "primary"
                                  : "light"
                              }
                              onClick={() => this.onProductModelChange(item)}
                            >
                              {item.title}
                              <br />
                              <label
                                className="name"
                                hidden={!this.state.pricingAccess}
                              >
                                {parseFloat(price) > 0
                                  ? "($" + parseFloat(price).toFixed(2) + ")"
                                  : null}
                              </label>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
                {this.state.allOptions?.map((item, i) => {
                  let optionsList = [];
                  item.list.forEach((element, index) => {
                    let validElement = element.list.find(
                      (x) => x.setId === this.state.selectedSetId
                    );
                    if (
                      element.category === "CST" &&
                      i === this.state.cstIndex &&
                      validElement !== undefined
                    ) {
                      optionsList.push(
                        <tr key={element.category}>
                          <OverlayTrigger
                            trigger={"click"}
                            placement="right"
                            overlay={this.customColorSelector(i)}
                            rootClose
                          >
                            <td
                              className={
                                this.state.description[i] !== "" &&
                                this.state.cstColorProps &&
                                this.state.cstColorProps.title !== "CST"
                                  ? "primary"
                                  : "light"
                              }
                              onMouseEnter={() => this.onHover()}
                              onClick={() =>
                                this.handleCustomColorClick(
                                  "CST",
                                  "CUSTOM",
                                  this.state.cstIndex
                                )
                              }
                            >
                              {this.state.cstColorProps.title}
                              <br />
                              <label className="name">
                                {this.state.cstColorProps.name.toUpperCase()}
                                <br />
                                <div hidden={!this.state.pricingAccess}>
                                  {parseFloat(
                                    this.state.cstColorProps[
                                      this.state.tierPricing
                                    ]
                                  ) > 0
                                    ? "($" +
                                      parseFloat(
                                        this.state.cstColorProps[
                                          this.state.tierPricing
                                        ]
                                      ).toFixed(2) +
                                      ")"
                                    : null}
                                </div>
                              </label>
                            </td>
                          </OverlayTrigger>
                        </tr>
                      );
                    } else if (validElement !== undefined) {
                      optionsList.push(
                        <tr key={element.category}>
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="right"
                            overlay={this.constraintMessagePopover(
                              validElement
                            )}
                            rootClose
                          >
                            <td
                              className={
                                this.state.description[i] === element.category
                                  ? "primary"
                                  : "light"
                              }
                              onClick={() =>
                                this.updateProductDescription(item, element, i)
                              }
                            >
                              {element.category} <br />
                              <label className="name">
                                {validElement.name === "N/A"
                                  ? null
                                  : validElement.name}{" "}
                                <br />
                                <div hidden={!this.state.pricingAccess}>
                                  {parseFloat(
                                    validElement[this.state.tierPricing]
                                  ) > 0
                                    ? "($" +
                                      parseFloat(
                                        validElement[this.state.tierPricing]
                                      ).toFixed(2) +
                                      ")"
                                    : null}
                                </div>
                              </label>
                            </td>
                          </OverlayTrigger>
                        </tr>
                      );
                    } else {
                      validElement = element.list[0];
                      optionsList.push(
                        <tr
                          key={element.category}
                          onClick={() =>
                            this.trackDisabledConfigurationElementClick(
                              item.category,
                              element.category
                            )
                          }
                        >
                          <td className="disabled-cell">
                            {element.category} <br />
                            <label className="name">
                              {validElement.name === "N/A"
                                ? null
                                : validElement.name}
                              <br />
                              <div hidden={!this.state.pricingAccess}>
                                {parseFloat(
                                  validElement[this.state.tierPricing]
                                ) > 0
                                  ? "($" +
                                    parseFloat(
                                      validElement[this.state.tierPricing]
                                    ).toFixed(2) +
                                    ")"
                                  : null}
                              </div>
                            </label>
                          </td>
                        </tr>
                      );
                    }
                  });

                  return (
                    <td className="config-details-td" key={item.category}>
                      {item.list.length > 0 ? (
                        <table>
                          <thead>
                            <tr>
                              <th className="config-details">
                                {this.showCategoryHeader(item.category)}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{optionsList}</tbody>
                        </table>
                      ) : null}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </Row>
        <br />
        <Row className="constraints">{this.constraintMessageSection()}</Row>
      </div>
    );
  }

  showCategoryHeader(category) {
    if (this.state.addersReset.find((x) => x === category)) {
      return (
        <div className="config-adder-reset">
          <h5>{category}</h5>
          <OverlayTrigger
            trigger={["hover", "focus", "click"]}
            rootClose
            placement="bottom"
            overlay={
              <Tooltip id="adder-reset">
                <span>{DisplayMessages.AdderResetToDefault}</span>
              </Tooltip>
            }
          >
            <span className="glyphicon glyphicon-info-sign" />
          </OverlayTrigger>
        </div>
      );
    } else {
      return <h5>{category}</h5>;
    }
  }

  getConstructionConfigurator() {
    return (
      <div style={{ margin: "0px 35px" }}>
        <br />
        {this.goldenLegendInConstructionConfigurator()}
        {this.constructionProductsHeader()}
        {this.constructionProductsBody()}
      </div>
    );
  }

  goldenLegendInConstructionConfigurator() {
    let isGoldenProductAvailable = false;
    this.state.allBases.forEach((element) => {
      let goldenPrice = 0;
      let salePrice = +parseFloat(element.contents.salePrice);
      let goldenList = element.contents.goldenList;
      let goldenCustomer = goldenList.find(
        (item) => item.CustomerName === this.state.selectedCustomer
      );
      if (goldenCustomer !== undefined) {
        goldenPrice += parseFloat(goldenCustomer.UnitPrice);
      }

      if (
        (salePrice === 0 && goldenPrice !== 0) ||
        (goldenPrice !== 0 && goldenPrice <= salePrice)
      ) {
        isGoldenProductAvailable = true;
      }
    });

    if (
      isGoldenProductAvailable &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <div className="pc-section">
          <Row>
            <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> =
            Your Special Pricing
          </Row>
        </div>
      );
    } else return null;
  }

  constructionProductsHeader() {
    if (this.state.allBases.length > 0) {
      return (
        <Row className="section-row-header">
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Stock Code
          </Col>
          <Col md={3} sm={3} xs={3} className="section-row-headcell">
            Description
          </Col>
          <Col md={2} sm={2} xs={2} className="section-row-headcell">
            Specs
          </Col>
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Qty
          </Col>
          {this.state.pricingAccess ? (
            <Col md={1} sm={1} xs={1} className="section-row-headcell">
              Total Price
            </Col>
          ) : null}
          <Col md={2} sm={2} xs={2} className="section-row-headcell">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Estimated Ship Time</span>
              <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
            </div>
          </Col>
          <Col
            md={this.state.pricingAccess ? 1 : 2}
            sm={this.state.pricingAccess ? 1 : 2}
            xs={this.state.pricingAccess ? 1 : 2}
            className="section-row-headcell"
          >
            Part Category
          </Col>
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Add To Cart
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  constructionProductsBody() {
    if (this.state.allBases.length > 0) {
      return (
        <Row style={{ minWidth: "1000px" }}>
          <div className="scrollable-div" style={{ maxHeight: "375px" }}>
            {this.state.allBases.map((element, index) => {
              return (
                <Row
                  style={{ display: "flex" }}
                  key={element.contents.stockCode}
                >
                  <Col md={1} sm={1} xs={1} className="section-row-cell ">
                    {element.contents.stockCode}
                  </Col>
                  <Col md={3} sm={3} xs={3} className="section-row-cell">
                    {element.title}
                  </Col>
                  <Col
                    md={2}
                    sm={2}
                    xs={2}
                    className="section-row-cell"
                    onClick={(e) => this.showConstructionSpecLink(element)}
                  >
                    <SpecTooltipPopup
                      specValue={element.contents.specValue}
                      productId={this.props.postId}
                      stockCodeTitle={element.contents.stockCode}
                      descriptionTitle={element.title}
                      engSpecLink={this.state.engSpecLink}
                      frSpecLink={this.state.frSpecLink}
                      addNotification={this.addNotification}
                      isNavigationEnabled={false}
                      onModal={false}
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1} className="section-row-cell">
                    <FormControl
                      type="number"
                      min="1"
                      max="10000"
                      pattern="[0-9]*"
                      name={index}
                      style={{ width: "150px" }}
                      value={element.itemQuantity}
                      onBlur={(e) => this.onConstructionQtyBlur(e, element)}
                      onChange={(e) => this.onConstructionQtyChange(e, element)}
                      aria-label="Quantity"
                    />
                  </Col>
                  {this.state.pricingAccess ? (
                    <Col
                      md={this.state.pricingAccess ? 1 : 0}
                      sm={this.state.pricingAccess ? 1 : 0}
                      xs={this.state.pricingAccess ? 1 : 0}
                      className="section-row-cell"
                    >
                      {this.constructionProductPrice(element, false)}
                    </Col>
                  ) : null}
                  <Col md={2} sm={2} xs={2} className="section-row-cell">
                    {element.contents.leadTime}
                  </Col>
                  <Col
                    md={this.state.pricingAccess ? 1 : 2}
                    sm={this.state.pricingAccess ? 1 : 2}
                    xs={this.state.pricingAccess ? 1 : 2}
                    className="section-row-cell"
                  >
                    {element.contents.partCategory === "B"
                      ? Miscellaneous.PartCategoryStocked
                      : Miscellaneous.PartCategoryNotStocked}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="section-row-cell copy-or-add"
                  >
                    <Button
                      onClick={() => this.copyConstructionItem(element)}
                      aria-label="Copy to clipboard"
                      className="glyphicon glyphicon-duplicate"
                    />
                    <Button
                      onClick={() =>
                        this.addToCart(productCriteria[1], element)
                      }
                      aria-label="Add Item"
                      className="glyphicon glyphicon-plus"
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        </Row>
      );
    } else {
      return null;
    }
  }

  goldenLegendInAccessories() {
    let isGoldenAccessoriesAvailable = false;
    this.state.allAccessories.forEach((element) => {
      let description = element.title.replace(
        "CST",
        element.accessoryColor.title
      );
      let goldenPrice = 0;
      let salePrice = +parseFloat(element.salePrice);
      let goldenList = element.goldenList;
      let goldenCustomer = goldenList.find(
        (item) =>
          item.CustomerName === this.state.selectedCustomer &&
          item.Description === description
      );
      if (goldenCustomer !== undefined) {
        goldenPrice += parseFloat(goldenCustomer.UnitPrice);
      }

      if (
        (salePrice === 0 && goldenPrice !== 0) ||
        (goldenPrice !== 0 && goldenPrice <= salePrice)
      ) {
        isGoldenAccessoriesAvailable = true;
      }
    });

    if (
      isGoldenAccessoriesAvailable &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <div className="pc-section">
          <Row>
            <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> =
            Your Special Pricing
          </Row>
        </div>
      );
    } else return null;
  }
  accessoriesHeader() {
    if (this.state.allAccessories.length > 0) {
      return (
        <Row className="section-row-header">
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Photo
          </Col>
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Paint
          </Col>
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Stock Code
          </Col>
          <Col md={3} sm={3} xs={3} className="section-row-headcell">
            Description
          </Col>
          <Col
            md={this.state.pricingAccess ? 1 : 2}
            sm={this.state.pricingAccess ? 1 : 2}
            xs={this.state.pricingAccess ? 1 : 2}
            className="section-row-headcell"
          >
            Specs
          </Col>
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Quantity
          </Col>
          {this.state.pricingAccess ? (
            <Col md={1} sm={1} xs={1} className="section-row-headcell">
              Total Price
            </Col>
          ) : null}
          <Col md={2} sm={2} xs={2} className="section-row-headcell">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Estimated Ship Time</span>
              <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
            </div>
          </Col>
          <Col md={1} sm={1} xs={1} className="section-row-headcell">
            Add To Cart
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  accessoriesBody() {
    if (this.state.allAccessories.length > 0) {
      return (
        <Row style={{ minWidth: "1000px" }}>
          <div className="scrollable-div" style={{ maxHeight: "375px" }}>
            {this.state.allAccessories.map((element, index) => {
              return (
                <Row
                  style={{ display: "flex" }}
                  key={element.stockCode.concat(element.title)}
                >
                  <Col md={1} sm={1} xs={1} className="section-row-cell ">
                    {this.displayAccessoryImage(element)}
                  </Col>
                  <Col md={1} sm={1} xs={1} className="section-row-cell">
                    {this.displayAccessoryPaint(element, index)}
                  </Col>
                  <Col md={1} sm={1} xs={1} className="section-row-cell">
                    {element.stockCode}
                  </Col>
                  <Col md={3} sm={3} xs={3} className="section-row-cell">
                    {element.title.replace("CST", element.accessoryColor.title)}
                  </Col>
                  <Col
                    md={this.state.pricingAccess ? 1 : 2}
                    sm={this.state.pricingAccess ? 1 : 2}
                    xs={this.state.pricingAccess ? 1 : 2}
                    className="section-row-cell"
                    onClick={(e) => this.showAccessorySpecLink(element)}
                  >
                    <SpecTooltipPopup
                      specValue={element.specValue}
                      productId={this.props.postId}
                      stockCodeTitle={element.stockCode}
                      descriptionTitle={element.title.replace(
                        "CST",
                        element.accessoryColor.title
                      )}
                      engSpecLink={this.state.engSpecLink}
                      frSpecLink={this.state.frSpecLink}
                      addNotification={this.addNotification}
                      isNavigationEnabled={false}
                      onModal={false}
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1} className="section-row-cell">
                    <FormControl
                      type="number"
                      min="1"
                      max="10000"
                      pattern="[0-9]*"
                      name={index}
                      style={{ width: "150px" }}
                      value={element.quantity}
                      onBlur={(e) => this.onAccessoryQuantityBlur(e, element)}
                      onChange={(e) =>
                        this.onAccessoryQuantityChange(e, element)
                      }
                      aria-label="Quantity"
                    />
                  </Col>
                  {this.state.pricingAccess ? (
                    <Col md={1} sm={1} xs={1} className="section-row-cell">
                      {this.accessoryPrice(element, false)}
                    </Col>
                  ) : null}
                  <Col md={2} sm={2} xs={2} className="section-row-cell">
                    {element.leadTime}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="section-row-cell copy-or-add"
                  >
                    <Button
                      onClick={() => this.copyAccessory(element)}
                      aria-label="Copy to clipboard"
                      className="glyphicon glyphicon-duplicate"
                    />
                    <Button
                      onClick={() =>
                        this.addToCart(productCriteria[2], element)
                      }
                      aria-label="Add Item"
                      className="glyphicon glyphicon-plus"
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        </Row>
      );
    } else {
      return null;
    }
  }

  displayAccessoryImage(element) {
    if (element.imageURL) {
      return (
        <div className="accessory-images">
          <img src={element.imageURL} alt="" className="fit-image" />
        </div>
      );
    } else {
      return (
        <Spinner type="cylon" color="#1B2D5B" height="50px" width="50px" />
      );
    }
  }

  displayAccessoryPaint(element, index) {
    if (element.title.includes("CST")) {
      return (
        <div className="accessory-paint-column">
          <OverlayTrigger
            trigger={"click"}
            placement={"right"}
            overlay={this.customAccessoryColorSelector(element, index)}
            rootClose
          >
            <div
              className={"square" + element.accessoryColor.className}
              value={element.accessoryColor.title}
            ></div>
          </OverlayTrigger>
          <span>{element.accessoryColor.title}</span>
          <span>{element.accessoryColor.name}</span>
        </div>
      );
    } else {
      return <span style={{ fontSize: "14px" }}>Standard Finish Only</span>;
    }
  }

  updateProductDescription(currItem, currElement, index) {
    let description = [...this.state.description];
    let cstColorProps = this.state.cstColorProps;
    let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;
    let availableColorProps = undefined;
    let addersReset = [...this.state.addersReset];

    let isDefaultElementAvailable = false;
    currItem.list.forEach((item) => {
      let defaultEle = item.list.find((x) => x.valueIndex === "1");
      if (defaultEle !== undefined) {
        isDefaultElementAvailable = true;
      }
    });

    if (
      !isDefaultElementAvailable &&
      description[index] === currElement.category
    ) {
      description[index] = "";
    } else {
      description[index] = currElement.category;
    }

    addersReset = addersReset.filter((x) => x !== currItem.category);

    if (index === this.state.cstIndex) {
      let cst = this.state.customColorList.find(
        (item) => item.title === currElement.category
      );
      if (!cst && cstColorProps !== null) {
        cstColorProps.title = "CST";
        cstColorProps.name = "CUSTOM";
      }

      if (currItem.category === itemCategory[0]) {
        availableColorProps = allCustomColors.find(
          (item) => item.title === currElement.category
        );

        defaultAccessoryColorProps =
          availableColorProps !== undefined
            ? availableColorProps
            : this.state.defaultAccessoryColorProps;
      }

      this.setState(
        {
          isBoxHovered: false,
          cstColorProps: cstColorProps,
          defaultAccessoryColorProps: defaultAccessoryColorProps,
        },
        () => {
          if (
            currItem.category === itemCategory[0] &&
            availableColorProps !== undefined
          ) {
            this.setAccessoryImages();
          }
        }
      );
    }

    this.setState(
      {
        description: description,
        addersReset: addersReset,
      },
      () => {
        this.trackConfigurationElementClick(
          currItem.category,
          description[index]
        );
        if (customerName) {
          this.findGoldenProduct();
        }
        if (
          currItem.category === itemCategory[0] &&
          availableColorProps !== undefined
        ) {
          this.generateAccessoriesList();
        }
      }
    );
  }

  onHover() {
    this.setState((prevState) => ({ isBoxHovered: !prevState.isBoxHovered }));
  }

  handleCustomColorClick(title, name, index) {
    let tempDescription = [...this.state.description];
    let customColorPicked = this.state.cstColorProps;
    let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;
    let addersReset = [...this.state.addersReset];

    if (title !== "CST" && name !== "CUSTOM") {
      tempDescription[index] = title;
      customColorPicked.title = title;
      customColorPicked.name = name;

      defaultAccessoryColorProps = allCustomColors.find(
        (item) => item.title === customColorPicked.title
      );

      addersReset = addersReset.filter((x) => x !== itemCategory[0]);

      this.setState(
        {
          description: tempDescription,
          addersReset: addersReset,
          cstColorProps: customColorPicked,
          defaultAccessoryColorProps: defaultAccessoryColorProps,
        },
        () => {
          this.trackCustomFinishSelection();
          this.generateAccessoriesList();
          if (customerName) {
            this.findGoldenProduct();
          }
        }
      );
    } else {
      this.trackCustomFinishPopupClick();
    }
  }

  handleAccessoryCustomColorClick(title, name, element) {
    let accessories = [...this.state.allAccessories];
    let defaultAccessoryColorProps = this.state.defaultAccessoryColorProps;

    defaultAccessoryColorProps = allCustomColors.find(
      (item) => item.title === title
    );

    let productId = this.props.postId;
    let currentCustomer = encodeURIComponent(customerName);
    let finish = defaultAccessoryColorProps.title;

    ProductConfiguratorHandler.getAllAccessories(
      productId,
      currentCustomer,
      finish
    )
      .then((response) => {
        let allAccessories = [];
        if (response.success) {
          this.trackAccessoryFinishSelection(element, title, name);

          allAccessories = response.data;
          let selectedAccessory = allAccessories.find(
            (item) => item.title === element.title
          );

          accessories = accessories.map((item) => {
            if (item.title === element.title) {
              selectedAccessory.imageURL = item.imageURL;
              selectedAccessory.accessoryColor = defaultAccessoryColorProps;
              return selectedAccessory;
            } else {
              return item;
            }
          });
        }

        this.setState({
          allAccessories: accessories,
        });
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_MissingAccessories,
          "warning"
        );
      });
  }

  yourPricingHeader() {
    return (
      <Row className="section-row-header">
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Stock Code
        </Col>
        <Col md={3} sm={3} xs={3} className="section-row-headcell">
          Description
        </Col>
        <Col md={2} sm={2} xs={2} className="section-row-headcell">
          Specs
        </Col>
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Qty
        </Col>
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Unit Price
        </Col>
        <Col md={2} sm={2} xs={2} className="section-row-headcell">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Estimated Ship Time</span>
            <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
          </div>
        </Col>
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Part Category
        </Col>
        <Col md={1} sm={1} xs={1} className="section-row-headcell">
          Add To Cart
        </Col>
      </Row>
    );
  }

  yourPricingBody() {
    return this.state.goldenProductsList.length > 0
      ? this.yourPricingDisplaySection()
      : this.yourPricingLoadingSection();
  }

  yourPricingDisplaySection() {
    return (
      <Row style={{ minWidth: "1000px" }}>
        <div className="scrollable-div" style={{ maxHeight: "375px" }}>
          {this.state.goldenProductsList.map((element, i) => {
            return (
              <Row style={{ display: "flex" }} key={element.StockCode}>
                <Col md={1} sm={1} xs={1} className="section-row-cell ">
                  {element.StockCode}
                </Col>
                <Col md={3} sm={3} xs={3} className="section-row-cell">
                  {element.Description}
                </Col>
                <Col
                  md={2}
                  sm={2}
                  xs={2}
                  className="section-row-cell"
                  onClick={(e) => this.showYourPricingSpecLink(element)}
                >
                  <SpecTooltipPopup
                    specValue={element.SpecValue}
                    productId={this.props.postId}
                    stockCodeTitle={element.StockCode}
                    descriptionTitle={element.Description}
                    engSpecLink={this.state.engSpecLink}
                    frSpecLink={this.state.frSpecLink}
                    addNotification={this.addNotification}
                    isNavigationEnabled={false}
                    onModal={false}
                  />
                </Col>
                <Col md={1} sm={1} xs={1} className="section-row-cell">
                  {parseInt(element.BaseQuantity, 10)}
                </Col>

                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="section-row-cell gold-pricing"
                >
                  ${parseFloat(element.GoldenPrice).toFixed(2)}
                </Col>
                <Col md={2} sm={2} xs={2} className="section-row-cell">
                  {element.LeadTime}
                </Col>
                <Col md={1} sm={1} xs={1} className="section-row-cell">
                  {element.PartCategory === "B"
                    ? Miscellaneous.PartCategoryStocked
                    : Miscellaneous.PartCategoryNotStocked}
                </Col>
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="section-row-cell copy-or-add"
                >
                  <Button
                    onClick={() => this.copyGoldenItem(element)}
                    className={"glyphicon glyphicon-duplicate"}
                    aria-label="Copy to clipboard"
                  />
                  <Button
                    onClick={() => this.addToCart(productCriteria[4], element)}
                    className={"glyphicon glyphicon-plus"}
                    aria-label="Add Item"
                  />
                </Col>
              </Row>
            );
          })}
        </div>
      </Row>
    );
  }

  yourPricingLoadingSection() {
    return (
      <ContentLoading message={DisplayMessages.YourPricing_Loading} size={50} />
    );
  }

  clearTableData() {
    ProductConfiguratorHandler.clearOrderSummary()
      .then((response) => {
        if (response.success) {
          this.addToCart(
            this.state.selectedProductCriteria,
            this.state.selectedElement
          );
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_ClearingError,
          "error"
        );
      });
    this.setState({ showConfirmationPopup: false });
  }

  handleClearClose() {
    this.setState({ showConfirmationPopup: false });
  }

  validateProductQuantity(qty) {
    qty = parseFloat(qty);
    let preventOrder = false;

    if (qty < 1 || this.state.configQuantity > 10000) {
      preventOrder = true;
    }

    return preventOrder;
  }

  showLEDSpecLink() {
    let specLinkPopupDescription = `${
      this.state.wattage
    }-${this.state.description.filter((e) => e).join("-")}`;
    let specLinkPopupStockCode = this.state.stockCode;
    let productId = this.props.postId;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          this.state.ledSpecValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  showConstructionSpecLink(element) {
    let specLinkPopupDescription = element.title;
    let specLinkPopupStockCode = element.contents.stockCode;
    let productId = this.props.postId;
    let specValue = element.contents.specValue;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  showAccessorySpecLink(element) {
    let tempSpecDescription = element.title.replace(
      "CST",
      element.accessoryColor.title
    );
    let specLinkPopupDescription = element.title;
    let specLinkPopupStockCode = element.stockCode;
    let productId = this.props.postId;
    let specValue = element.specValue;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          tempSpecDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  showYourPricingSpecLink(element) {
    let specLinkPopupDescription = element.Description;
    let specLinkPopupStockCode = element.StockCode;
    let productId = this.props.postId;
    let specValue = element.SpecValue;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  showQuickShipSpecLink(element) {
    let specLinkPopupDescription = element.description;
    let specLinkPopupStockCode = element.stockCode;
    let productId = this.props.postId;
    let specValue = element.specValue;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  getSpecLinks(description, pid) {
    let data = {
      description: description,
      productId: pid.toString(),
    };
    ConfigHandler.getSpecLinks(data)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "N/A");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOnPopupClose() {
    this.setState({
      showSpecLinkPopup: false,
    });
  }

  onConfigQuantityBlur(e) {
    let qty = e.target.value === "" ? 1 : e.target.value;
    let shipTime = Miscellaneous.LeadTimeUnavailable;

    let shipInfo = this.state.ledShipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    this.setState({
      configQuantity: parseFloat(qty),
      configLeadTime: shipTime,
    });
  }

  onConfigQuantityChange(e) {
    let qty = e.target.validity.valid
      ? e.target.value
      : this.state.configQuantity;
    qty = parseInt(qty);
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = this.state.ledShipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });
    this.setState({
      configQuantity: parseFloat(qty),
      configLeadTime: shipTime,
    });
  }

  onConstructionQtyBlur(e, element) {
    let allBases = [...this.state.allBases];
    let index = parseInt(e.target.name, 10);
    let qty = e.target.value === "" ? 1 : e.target.value;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.contents.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    allBases[index].itemQuantity = parseFloat(qty);
    allBases[index].contents.leadTime = shipTime;

    this.setState({
      allBases: allBases,
    });
  }

  onConstructionQtyChange(e, element) {
    let allBases = [...this.state.allBases];
    let index = parseInt(e.target.name, 10);
    let qty = e.target.validity.valid
      ? e.target.value
      : allBases[index].itemQuantity;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.contents.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    allBases[index].itemQuantity = parseFloat(qty);
    allBases[index].contents.leadTime = shipTime;

    this.setState({
      allBases: allBases,
    });
  }

  onQuickShipQuantityBlur(e, element) {
    let quickShipList = [...this.state.quickShipProducts];
    let index = parseFloat(e.target.name, 10);
    let qty = e.target.value === "" ? 1 : e.target.value;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let partCategory = Miscellaneous.PartCategoryNotStocked;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
        partCategory = shipdata.PartCategory;
      }
    });

    quickShipList[index].quantity = parseInt(qty);
    quickShipList[index].leadTime = shipTime;
    quickShipList[index].partCategory = partCategory;

    this.setState({
      quickShipProducts: quickShipList,
    });
  }

  onQuickShipQuantityChange(e, element) {
    let quickShipList = [...this.state.quickShipProducts];
    let index = parseFloat(e.target.name, 10);
    let qty = e.target.validity.valid ? e.target.value : element.quantity;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let partCategory = Miscellaneous.PartCategoryNotStocked;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );

    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
        partCategory = shipdata.PartCategory;
      }
    });

    quickShipList[index].quantity = parseInt(qty);
    quickShipList[index].leadTime = shipTime;
    quickShipList[index].partCategory = partCategory;

    this.setState({
      quickShipProducts: quickShipList,
    });
  }

  onAccessoryQuantityBlur(e, element) {
    let allAccessories = [...this.state.allAccessories];
    let index = parseFloat(e.target.name, 10);
    let qty = e.target.value === "" ? 1 : e.target.value;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    allAccessories[index].quantity = parseInt(qty);
    allAccessories[index].leadTime = shipTime;

    this.setState({
      allAccessories: allAccessories,
    });
  }

  onAccessoryQuantityChange(e, element) {
    let allAccessories = [...this.state.allAccessories];
    let index = parseFloat(e.target.name, 10);
    let qty = e.target.validity.valid ? e.target.value : element.quantity;
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );

    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    allAccessories[index].quantity = parseInt(qty);
    allAccessories[index].leadTime = shipTime;

    this.setState({
      allAccessories: allAccessories,
    });
  }

  quickShipProductPrice(element, isCopyToClipboard) {
    let qty = isNaN(element.quantity)
      ? 1
      : +parseFloat(element.quantity).toFixed(2);

    let goldenPrice = 0;
    let salePrice = +parseFloat(element.salePrice) * qty;
    let goldenList = element.goldenList;
    let productPrice = +parseFloat(element[this.state.tierPricing]) * qty;
    let goldenCustomer = goldenList.find(
      (item) => item.CustomerName === this.state.selectedCustomer
    );
    if (goldenCustomer !== undefined && this.state.goldenPriceAccess) {
      goldenPrice += parseFloat(goldenCustomer.UnitPrice) * qty;
    }

    return isCopyToClipboard
      ? this.getItemPrice(salePrice, goldenPrice, productPrice)
      : this.showItemPrice(salePrice, goldenPrice, productPrice);
  }

  customerValidityCheck() {
    if (
      this.state.selectedCustomer === null ||
      this.state.selectedCustomer === "" ||
      this.state.selectedCustomer === " "
    ) {
      this.trackAddToCartButtonClick(ErrorMessages.ProductConfig_CustomerName);
      this.addNotification(ErrorMessages.ProductConfig_CustomerName, "warning");
      return false;
    }
    if (this.state.selectedCustomer.length > 50) {
      this.trackAddToCartButtonClick(ErrorMessages.ProductConfig_NameLength);
      this.addNotification(ErrorMessages.ProductConfig_NameLength, "warning");
      return false;
    }
    if (this.validateProductQuantity(this.state.configQuantity)) {
      this.trackAddToCartButtonClick(
        ErrorMessages.ProductConfig_QuantityOutOfRange
      );
      this.addNotification(
        ErrorMessages.ProductConfig_QuantityOutOfRange,
        "warning"
      );
      return false;
    }

    return true;
  }

  resetToDefaultTab() {
    let prodConfigTab = document.getElementById(
      "uncontrolled-tab-example-tab-1"
    );
    let accessoriesTab = document.getElementById(
      "uncontrolled-tab-example-tab-2"
    );
    let quickShipTab = document.getElementById(
      "uncontrolled-tab-example-tab-3"
    );
    let goldenTab = document.getElementById("uncontrolled-tab-example-tab-4");
    if (
      goldenTab === null &&
      prodConfigTab.ariaSelected === "false" &&
      (accessoriesTab === null || accessoriesTab.ariaSelected === "false") &&
      (quickShipTab === null || quickShipTab.ariaSelected === "false")
    ) {
      prodConfigTab.click();
    }
  }

  // Mixpanel Tracking //

  trackCustomerSelection(customer) {
    mixpanel.track("Customer Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer is displayed",
      Customer: customer,
    });
  }

  trackCustomerTierSelection(tier) {
    mixpanel.track("Customer Tier Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer tier is displayed",
      Tier: tier,
    });
  }

  trackSubtabNavigation(id) {
    let subtabs = [
      "_",
      "Product Configurator",
      "Accessories",
      "Quick Ships",
      "Your Pricing",
    ];

    mixpanel.track("Configurator Navigation", {
      Action: "Subtab Click",
      Effect: "Navigated to a different subtab",
      "Selected Tab": subtabs[id],
    });

    mixpanel.track_pageview({
      Page: `${subtabs[id]} Subtab`,
    });
  }

  trackTableauDashboardClick(item) {
    mixpanel.track("Tableau Dashboard", {
      Action: "Link Click",
      Effect: `Tableau Dashboard opened`,
      "Tableau Link": item.rabLink,
      "Tableau ID": item.id,
      "Tableau Type": item.type,
    });
  }

  trackSeeAllNotesButtonClick() {
    mixpanel.track("See All Notes", {
      Action: "Button Click",
      Effect: "Show/Hide constraint messages",
    });
  }

  trackProductModelElementClick() {
    mixpanel.track("Product Model", {
      Action: "Element Click",
      Effect: `Product Model will be changed`,
      Base: this.state.selectedBase,
      SetID: this.state.selectedSetId,
      "Default Finish Name": this.state.defaultFinish
        ? this.state.defaultFinish.name
        : "NA",
      "Default Finish Title": this.state.defaultFinish
        ? this.state.defaultFinish.title
        : "NA",
    });
  }

  trackConfigurationElementClick(item, element) {
    let desc = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;
    mixpanel.track("Product Configuration", {
      Action: "Element Click",
      Effect: `Product Configuration will be changed`,
      Category: item,
      Configuration: element,
      Description: desc,
    });
  }

  trackDisabledConfigurationElementClick(item, element) {
    let desc = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;
    mixpanel.track("Disabled Product Configuration", {
      Action: "Element Click",
      Effect: `Product Configuration will not be changed`,
      Category: item,
      Configuration: element,
      Description: desc,
    });
  }

  trackCustomFinishSelection() {
    let desc = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;
    mixpanel.track("Custom Product Finish", {
      Action: "Element Click",
      Effect: `Product Finish will be customized`,
      Description: desc,
      "Custom Finish Title": this.state.cstColorProps
        ? this.state.cstColorProps.title
        : "NA",
      "Custom Finish Name": this.state.cstColorProps
        ? this.state.cstColorProps.name
        : "NA",
    });
  }

  trackAccessoryFinishSelection(element, title, name) {
    let description = element.title.replace(
      "CST",
      element.accessoryColor.title
    );

    mixpanel.track("Custom Accessory Finish", {
      Action: "Element Click",
      Effect: `Accessory finish is customized`,
      Description: description,
      "Accessory Finish Title": title,
      "Accessory Finish Name": name,
    });
  }

  trackCustomFinishPopupClick() {
    mixpanel.track("Custom Finish Popup", {
      Action: "Element Click",
      Effect: "Show/Hide Custom Finish Popup",
    });
  }

  trackSpecsClick(specs, description, stockCode, pid) {
    mixpanel.track("Specs", {
      Action: "Button Click",
      Effect: "Show/Hide Specs Tooltip",
      Specs: specs,
      Description: description,
      "Stock Code": stockCode,
      "Product ID": pid,
    });
  }

  trackAddToCartButtonClick(message) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Attempt to add item(s) to current estimate summary cart",
      Page: "Product Configurator Page",
      "Added To Cart": false,
      Message: message,
    });
  }

  trackAddToCartButtonSuccess(description, stockCode) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Successfully added to cart",
      Page: "Product Configurator Page",
      "Added To Cart": true,
      "SKU Description": description,
      "SKU Stock Code": stockCode,
    });
  }
}
export default ProductConfigurator;
