import Plugin from "../Utils/plugin.js";
import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get product details
 * Get additional attachments
 * Add new log
 * Pin/Favourite the product
 */
const ProductDataHandler = (function () {
  return {
    getProductDetails: function (postId) {
      return new Promise((resolve, reject) => {
        const route = "products/" + postId;
        Plugin.get(route)
          .then((response) => {
            let productDetails = response.data.data;
            resolve(productDetails);
          })
          .catch((error) => reject(error));
      });
    },
    getAditionalAttachments: function (productName, resourceType) {
      return new Promise((resolve, reject) => {
        const route =
          "ContentDisplay/productName?productName=" +
          productName +
          "&resourceType=" +
          resourceType;
        Api.get(route)
          .then((response) => {
            let uploads = response.data;
            resolve(uploads);
          })
          .catch((error) => reject(error));
      });
    },
    insertLog: function (productName, action) {
      return new Promise((resolve, reject) => {
        const route = "ContentDisplay/AddToUsageTrends";
        let data = { action: action, entityName: productName };
        Api.post(route, data)
          .then((response) => {
            let uploads = response.data;
            resolve(uploads);
          })
          .catch((error) => reject(error));
      });
    },
    postPinPage: function (data) {
      return new Promise((resolve, reject) => {
        Api.post("Favourite/AddToFavourites", data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ProductDataHandler;
