import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import { LinkContainer } from "react-router-bootstrap";

/**
 * Component represents reset popup generated when password is reset.
 */
class ResetPopup extends Component {
  constructor(props) {
    super(props);
    this.handleClearClose = this.handleClearClose.bind(this);
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleClearClose}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ControlLabel>{this.props.text}</ControlLabel>
          </Modal.Body>
          <Modal.Footer>
            <LinkContainer to={{ pathname: "/ForgotPassword" }}>
              <Button className="button-blue" onClick={this.handleClearClose}>
                Confirm
              </Button>
            </LinkContainer>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleClearClose() {
    this.props.handleClearClose();
  }
}

export default ResetPopup;
