import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get order summary table
 * Clear order summary
 * Get products
 * Get saved address
 * Get contractor
 * Get shipping order
 * Get free shipping details
 * Clear one record from order summary
 * Get maximum quantity
 * Get pinned estimate summary cart
 * Add to Summary History
 * Save Address
 * Save contractor
 * Delete address
 * Delete contractor
 * Send PO Mail
 * Save shipping order details
 * Get provinces list
 * Pin/Favourite the estimate summary cart
 * Update orders
 * Export order summary
 * Get the cart details
 * Request Quotes Mail
 */
const OrderSummaryHandler = (function () {
  return {
    getOrderSummaryCart: function () {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/CurrentCart`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clearOrderSummary: function () {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/DeleteAllOrders`;
        Api.delete(route)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    },
    getProducts: function () {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/Products`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getPinnedProducts: function (data) {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/PinnedProducts`;
        Api.post(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getSavedAddress: function () {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/SavedAddress`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getContractor: function () {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/Contractor`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getShippingOrder: function () {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/ShippingOrder`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getFreeShippingDetails: function (customer, username) {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/FreeShippingDetails?customer=${customer}&username=${username}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    clearOneOrderRecord: function (description, wattage, setId) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/DeleteOrder?description=${encodeURIComponent(
          description
        )}&wattage=${wattage}&setId=${setId}`;
        Api.delete(route)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    },
    getMaximumQuantity: function (
      description,
      wattage,
      productId,
      customColor
    ) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/GetMaximumQuantity?description=${encodeURIComponent(
          description
        )}&wattage=${wattage}&productId=${productId}&customColor=${customColor}`;
        Api.get(route)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    },
    getPinnedSummaryTable: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/PinnedOrders`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addToSummaryHistory: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/AddToSummaryHistory`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveAddress: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/AddAddress`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveContractor: function (contractor) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/SaveContractor?contractor=${contractor}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAddress: function (id) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/DeleteAddress?id=${id}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => reject(error));
      });
    },
    deleteContractor: function (id) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/DeleteContractor?id=${id}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => reject(error));
      });
    },
    sendPOMail: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/SendPOMail`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveShippingOrderDetails: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/SaveShippingOrder`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProvinces: function () {
      return new Promise((resolve, reject) => {
        const route = `EstimateSummary/Provinces`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    postPinPage: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`Favourite/AddToFavourites`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateOrders: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`EstimateSummary/UpdateCart`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    exportOrderSummary: function (data) {
      return new Promise((resolve, reject) => {
        Api.post_doc(`Export/OrderSummary`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    cartDetails: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`EstimateSummary/SummaryCart`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    requestQuotesMail: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`EstimateSummary/RequestQuotesMail`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default OrderSummaryHandler;
