import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Select from "react-select";
import NotificationSystem from "react-notification-system";
import Row from "react-bootstrap/lib/Row";
import { FormGroup, ModalFooter } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import BranchOverviewHandler from "./BranchOverviewHandler";
import "./branchOverview.css";
import "./branchAdminPopup.css";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 125,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

/**
 * Component represents branch admin change popup
 */
class BranchAdminPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReps: [],
      distributorUsers: [],
      currentAdmin: this.props.branchAdmin,
      selectedBranchAdmin: null,
      branchId: this.props.branchId,
      tldId: this.props.tldId,
      salesAgency: this.props.salesAgency,
      selectedUserType: "Salesrep",
    };
    this._notificationSystem = null;
    this.handleOnClose = this.handleOnClose.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.getAdminOptions = this.getAdminOptions.bind(this);
    this.handleTypeSelection = this.handleTypeSelection.bind(this);
    this.updateBranchAdmin = this.updateBranchAdmin.bind(this);
    this.handleAdminChange = this.handleAdminChange.bind(this);
    this.existingAdminHandler = this.existingAdminHandler.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState(
      {
        selectedUserType: "Salesrep",
        currentAdmin: props.branchAdmin,
        branchId: props.branchId,
        tldId: props.tldId,
        salesAgency: props.salesAgency,
      },
      () => {
        this.getUserList();
      }
    );
  }

  getUserList() {
    BranchOverviewHandler.getUsers(
      this.state.branchId,
      this.state.tldId,
      encodeURIComponent(this.state.salesAgency)
    )
      .then((response) => {
        if (response.success) {
          this.setState(
            {
              salesReps: response.data.salesreps,
              distributorUsers: response.data.distributorusers,
            },
            () => {
              if (this.state.currentAdmin) {
                this.existingAdminHandler();
              }
            }
          );
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((ex) => {
        this.addNotification(ErrorMessages.BranchAdminPopup_GetUsers, "error");
      });
  }

  existingAdminHandler() {
    let adminEmail = this.state.currentAdmin.Email;

    this.setState((prevState) => ({
      salesReps: prevState.salesReps.filter(
        (item) => item.Email !== adminEmail
      ),
      distributorUsers: prevState.distributorUsers.filter(
        (item) => item.Email !== adminEmail
      ),
    }));
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleOnClose}
          dialogClassName="change-admin-popup"
        >
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", textAlign: "center" }}
              className="popup-blue-text"
            >
              {this.state.currentAdmin === null
                ? "Add Branch Admin"
                : "Change Branch Admin"}
            </h3>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Row>
              <div className="sltabs">
                <input
                  type="radio"
                  id="radio-1"
                  name="radiogroup"
                  value="Salesrep"
                  onChange={this.handleTypeSelection}
                  checked={this.state.selectedUserType === "Salesrep"}
                />
                <label className="sltab" htmlFor="radio-1">
                  <strong>SALESREP</strong>
                </label>
                <input
                  type="radio"
                  id="radio-2"
                  name="radiogroup"
                  value="DistributorUser"
                  onChange={this.handleTypeSelection}
                />
                <label className="sltab" htmlFor="radio-2">
                  <strong>DISTRIBUTOR USER</strong>
                </label>
                <span className="glider" />
              </div>
            </Row>
            <br />
            <Row>
              <FormGroup className="visible">
                {this.getAdminOptions()}
              </FormGroup>
            </Row>
          </Modal.Body>
          <ModalFooter>
            <div className="text-center">
              <Button
                className="button-skyblue"
                style={{ width: "150px" }}
                onClick={this.updateBranchAdmin}
              >
                Save
              </Button>
              <Button
                className="button-white"
                style={{ width: "150px" }}
                onClick={() => {
                  this.trackCancelButtonClick();
                  this.handleOnClose();
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  handleTypeSelection(e) {
    this.trackBranchAdminClick(e.target.value);

    this.setState({
      selectedUserType: e.target.value,
      selectedBranchAdmin: [],
    });
  }

  updateBranchAdmin() {
    if (
      this.state.selectedBranchAdmin === null ||
      this.state.selectedBranchAdmin.length === 0
    ) {
      this.addNotification(
        DisplayMessages.BranchAdminPopup_BranchAdminSelect,
        "warning"
      );
      return;
    }
    let oldAdmin =
      this.state.currentAdmin === null ? "" : this.state.currentAdmin.Email;
    let newAdmin = this.state.selectedBranchAdmin.value;

    let oldAdminName =
      this.state.currentAdmin === null
        ? ""
        : this.state.currentAdmin.FirstName +
          " " +
          this.state.currentAdmin.LastName;
    let newAdminName = this.state.selectedBranchAdmin.label;
    let branchId = this.state.branchId;
    BranchOverviewHandler.changeAdmin(oldAdmin, newAdmin, branchId)
      .then((response) => {
        if (response.success) {
          this.addNotification(response.message, "info");
          this.trackSaveButtonClick(oldAdminName, newAdminName, branchId);
          this.handleOnClose();
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchAdminPopup_ChangeAdmin,
          "error"
        );
      });
  }

  getAdminOptions() {
    let userList =
      this.state.selectedUserType === "DistributorUser"
        ? this.state.distributorUsers
        : this.state.salesReps;
    let users = userList.map((item) => ({
      value: item.UserId,
      label: item.FirstName + " " + item.LastName,
    }));
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        value={this.state.selectedBranchAdmin}
        placeholder={
          this.state.selectedUserType === "Salesrep"
            ? "Select a salesrep"
            : "Select a distributor user"
        }
        styles={customStyles}
        onChange={this.handleAdminChange}
        options={users}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  handleAdminChange(e) {
    this.setState({
      selectedBranchAdmin: e,
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  handleOnClose() {
    this.setState({
      selectedBranchAdmin: null,
    });
    this.props.handleOnClose();
  }

  // Mixpanel Tracking //

  trackBranchAdminClick(type) {
    mixpanel.track("Branch Admin Type", {
      Action: "Toggle",
      Effect: "Switch between branch admin types",
      "Branch Admin Type": type,
    });
  }

  trackSaveButtonClick(oldAdmin, newAdmin, branchId) {
    mixpanel.track("Change Branch Admin", {
      Action: "Button Click",
      Effect: "Branch admin updated",
      "Old Branch Admin": oldAdmin,
      "New Branch Admin": newAdmin,
      "Branch ID": branchId,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Cancel Branch Admin Change", {
      Action: "Button Click",
      Effect: "Cancel branch admin change",
    });
  }
}
export default BranchAdminPopup;
