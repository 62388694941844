import React, { Component } from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Panel from "react-bootstrap/lib/Panel";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Select from "react-select";
import FormControl from "react-bootstrap/lib/FormControl";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Lightbox from "react-images";
import "react-image-lightbox/style.css";
import Checkbox from "react-bootstrap/lib/Checkbox";
import Autocomplete from "react-google-autocomplete";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import mixpanel from "mixpanel-browser";

import UploadPopup from "./UploadPopup.js";
import "./installationgallery.css";
import Api from "../Utils/api.js";
import EmailPopup from "../EmailPopup/EmailPopup.js";
import ToolsDataHandler from "../Tools/tools_data_handler.js";
import InstallsAttachmentsPopup from "./InstallsAttachmentsPopup.js";
import ContentDataHandler from "../Content/ContentDataHandler.js";
import GalleryDataHandler from "./GalleryDataHandler.js";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup.js";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

/**
 * Component represents the installs page
 */
class InstallationGallery extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      open: false,
      sortBy: "recent",
      organizedBy: "family",
      headingList: [],
      showMap: "show-google-map",
      buttonName: "Hide Map",
      uploadedBy: "",
      showEmailPopup: false,
      selectedFilesForShare: [],
      attachments: [],
      templateString: "",
      searchAddress: "",
      searchApplication: "",
      searchFamily: "",
      showAttachmentListPopup: false,
      imageIndex: 0,
      imageArray: [],
      selectedApplication: "",
      selectedFamily: "",
      selectedAddress: "",
      selectedFileName: "",
      UpdateButtonName: "Upload",
      hideDetails: "hidden",
      selectedUpdateId: -1,
      editContent: false,
      editContentClass: "glyphicon glyphicon-pencil",
      description: "",
      disableMenuItem: "",
      applicationTypes: [],
      productNames: [],
      currentUser: "",
      userName: "",
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    };

    this.uploadForm = this.uploadForm.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOrganize = this.handleOrganize.bind(this);
    this.handleMap = this.handleMap.bind(this);
    this.handleImageView = this.handleImageView.bind(this);
    this.getdata = this.getdata.bind(this);
    this.getMarkers = this.getMarkers.bind(this);
    this.handleEmailShare = this.handleEmailShare.bind(this);
    this.handleCloseEmailPopup = this.handleCloseEmailPopup.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.handleSearchAddressChange = this.handleSearchAddressChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.handleOnAttachmentsListPopupClose =
      this.handleOnAttachmentsListPopupClose.bind(this);
    this.getAttachments = this.getAttachments.bind(this);
    this.handleMenuDelete = this.handleMenuDelete.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.menuDelete = this.menuDelete.bind(this);
    this.handleMenuUpdate = this.handleMenuUpdate.bind(this);
    this.thumbnailClick = this.thumbnailClick.bind(this);
    this.handleEditContentClick = this.handleEditContentClick.bind(this);
    this.getDescriptionText = this.getDescriptionText.bind(this);
    this.showIfAdmin = this.showIfAdmin.bind(this);
    this.handleMenuSetPhoto = this.handleMenuSetPhoto.bind(this);
    this.showContextMenuAdmin = this.showContextMenuAdmin.bind(this);
    this.handleMenuShow = this.handleMenuShow.bind(this);
    this.getApplicationTypes = this.getApplicationTypes.bind(this);
    this.handleApplicationTypeChange =
      this.handleApplicationTypeChange.bind(this);
    this.handleProductFamilyChange = this.handleProductFamilyChange.bind(this);
    this.cancelAttachmentDeletion = this.cancelAttachmentDeletion.bind(this);
    this.getAllInstallations = this.getAllInstallations.bind(this);
    this.logToUsageTrends = this.logToUsageTrends.bind(this);
    this.filterProductNames = this.filterProductNames.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let data = {
      type: "family",
      sortby: "recent",
      address: "null",
      application: "null",
      family: "null",
    };

    this.getAllInstallations(data);
    this.getDescriptionText();
    this.getApplicationTypes();
    this.getProductNames();
    this.logToUsageTrends();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAllInstallations(data) {
    GalleryDataHandler.getInstallations(data)
      .then((response) => {
        if (response.success && this._isMounted) {
          let heading = response.data.map((item) => {
            return item;
          });
          this.setState({
            headingList: heading,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_MissingInstallations,
          "error"
        );
      });
  }

  getApplicationTypes() {
    GalleryDataHandler.getApplicationTypes()
      .then((response) => {
        if (
          response.data.find(
            (item) => item.name === this.state.searchApplication
          ) &&
          this._isMounted
        ) {
          this.setState({
            applicationTypes: response.data.map((item) => ({
              value: item.name,
              label: item.name,
            })),
          });
        } else if (this._isMounted) {
          this.setState(
            {
              applicationTypes: response.data.map((item) => ({
                value: item.name,
                label: item.name,
              })),
              searchApplication: "",
              searchFamily: "",
            },
            this.getdata
          );
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetApplicationTypes,
          "error"
        );
      });
  }

  getProductNames() {
    GalleryDataHandler.getProductNames(this.state.searchApplication)
      .then((response) => {
        if (
          response.data.find((item) => item.name === this.state.searchFamily) &&
          this._isMounted
        ) {
          this.setState({
            productNames: response.data.map((item) => ({
              value: item.name,
              label: item.name,
            })),
          });
        } else if (this._isMounted) {
          this.setState(
            {
              productNames: response.data.map((item) => ({
                value: item.name,
                label: item.name,
              })),
              searchFamily: "",
            },
            this.getdata
          );
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetProductNames,
          "error"
        );
      });
  }

  filterProductNames() {
    GalleryDataHandler.getProductNames(this.state.searchApplication)
      .then((response) => {
        this.setState({
          productNames: response.data.map((item) => ({
            value: item.name,
            label: item.name,
          })),
        });
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetProductNames,
          "error"
        );
      });
  }

  getDescriptionText() {
    GalleryDataHandler.getDescriptionText()
      .then((response) => {
        if (response.success && this._isMounted) {
          let message = response.data[0].description;
          this.setState({
            description: message,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetDescriptionText,
          "error"
        );
      });
  }

  logToUsageTrends() {
    let trends = { action: "Gallery", entityName: "NA" };
    ContentDataHandler.addToUsageTrends(trends)
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            userName: sessionStorage.getItem("username"),
          });
        } else if (!response.success) {
          this.props.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_AddToUsageTrends,
          "error"
        );
      });
  }

  handleSearchAddressChange(e) {
    this.setState({
      searchAddress: e.target.value,
    });
  }

  uploadForm() {
    this.trackUploadPicButtonClick();
    this.setState({
      show: true,
      UpdateButtonName: "Upload",
      hideDetails: "hidden",
      selectedAddress: "",
      selectedApplication: "",
      selectedFileName: "",
      selectedFamily: "",
      uploadedBy: "",
      selectedUpdateId: -1,
    });
  }

  handleOnClose() {
    this.setState({
      show: false,
    });
    this.getDescriptionText();
    this.getApplicationTypes();
    this.getProductNames();
  }

  handleMap() {
    if (this.state.buttonName === "Show Map") {
      this.setState(
        {
          showMap: "show-google-map",
          buttonName: "Hide Map",
        },
        this.trackHideMapButtonClick
      );
    } else {
      this.setState(
        {
          showMap: "hide-google-map",
          buttonName: "Show Map",
        },
        this.trackHideMapButtonClick
      );
    }
  }

  onMarkerClick(e) {
    let longitude = e.longitude;
    let latitude = e.latitude;
    let imageUrl = [];

    GalleryDataHandler.getLocation(longitude, latitude)
      .then((response) => {
        response.data.forEach((item) => {
          let path = Api.getBaseURL() + "/uploads/" + item.file_name;
          let uploadedBy = "Image uploaded by : " + e.uploadedBy;
          let productFamily = "Product Family : " + item.product_family;
          let photoCaption = productFamily + " | " + uploadedBy;
          imageUrl.push({ src: path, caption: photoCaption });
        });

        this.setState({
          imageIndex: 0,
          imageArray: imageUrl,
          open: true,
        });
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetLocation,
          "error"
        );
      });
  }

  handleSort(event) {
    this.trackSortBySelection(event.target.value);
    this.setState(
      {
        sortBy: event.target.value,
      },
      () => {
        this.getdata();
      }
    );
  }

  handleOrganize(event) {
    this.trackOrganizeBySelection(event.target.value);

    this.setState(
      {
        organizedBy: event.target.value,
      },
      () => {
        this.getdata();
      }
    );
  }

  handleImageView(e) {
    let uploaderName = e.currentTarget.attributes.uploadedby.nodeValue;
    let uploadedBy = "Image uploaded by : " + uploaderName;
    let productFamilyName = e.currentTarget.attributes.productfamily.nodeValue;
    let productFamily = "Product Family : " + productFamilyName;
    let photoCaption = productFamily + " | " + uploadedBy;
    let imageUrl = e.currentTarget.src;
    this.trackInstallationImageClick(uploaderName, productFamilyName);

    this.setState({
      imageurl: imageUrl,
      open: true,
      uploadedBy: uploadedBy,
      imageIndex: 0,
      imageArray: [{ src: imageUrl, caption: photoCaption }],
    });
  }

  handleEmailShare() {
    if (this.state.selectedFilesForShare.length <= 0) {
      this.props.addNotification(
        ErrorMessages.InstallationGallery_NoFileSelectedForShare,
        "warning"
      );
    } else {
      let data = {
        PortalWebsite: Miscellaneous.PortalWebsite,
        PortalName: Miscellaneous.PortalName,
        videoUrls: [],
      };
      ToolsDataHandler.getEmailTemplate(data)
        .then((response) => {
          if (!response.success) {
            this.props.addNotification(response.message, "error");
          } else {
            this.setState({
              showEmailPopup: true,
              templateString: response.data.body,
            });
          }
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.InstallationGallery_EmailTemplate,
            "error"
          );
        });
    }

    this.trackEmailShareButtonClick(
      this.state.selectedFilesForShare.length <= 0 ? false : true
    );
  }

  handleCloseEmailPopup() {
    this.setState({
      showEmailPopup: false,
    });
  }

  isChecked(id) {
    let fileIdList = this.state.selectedFilesForShare;
    let isFileIdChecked = false;
    if (fileIdList.find((item) => item.id === id)) {
      isFileIdChecked = true;
    }
    return isFileIdChecked;
  }

  clearSelection() {
    this.setState({
      selectedFilesForShare: [],
    });
  }

  handleFileSelect(id, filePath, fileName) {
    let fileIdList = this.state.selectedFilesForShare;
    if (fileIdList.find((item) => item.id === id)) {
      fileIdList = fileIdList.filter((item) => item.id !== id);
      this.trackInstallationCheckBoxSelection(false, id, fileName);
    } else {
      this.trackInstallationCheckBoxSelection(true, id, fileName);

      fileIdList.push({
        id: id,
        fileName: fileName,
        source: "local",
        filePath: filePath,
      });
    }
    this.setState({
      selectedFilesForShare: fileIdList,
    });
  }

  getdata() {
    let sortby = this.state.sortBy;
    let address =
      this.state.searchAddress === "" ? "null" : this.state.searchAddress;
    let application =
      this.state.searchApplication === ""
        ? "null"
        : this.state.searchApplication;
    let family =
      this.state.searchFamily === "" ? "null" : this.state.searchFamily;
    let organize = this.state.organizedBy;

    let data = {
      type: organize,
      sortby: sortby,
      address: address,
      application: application,
      family: family,
    };

    this.getAllInstallations(data);
  }

  getAttachments() {
    GalleryDataHandler.getAttachments()
      .then((response) => {
        let list = response.data.map((item) => {
          return item;
        });
        this.setState({
          attachments: list,
        });
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetAttachments,
          "error"
        );
      });
  }

  getMarkers() {
    return this.state.headingList.map((item, i) => {
      return item.attachments.map((itemDetails, id) => {
        let monthPhoto = itemDetails.monthPhoto;
        let url = "";
        if (monthPhoto === 0) {
          url = fetchResourceData(fixedLink.redPinIcon);
        } else {
          url = fetchResourceData(fixedLink.goldPinIcon);
        }
        return (
          <Marker
            key={itemDetails.fileName}
            onClick={this.onMarkerClick}
            title={itemDetails.address}
            icon={url}
            src={itemDetails.fileName}
            longitude={itemDetails.longitude}
            latitude={itemDetails.latitude}
            uploadedBy={itemDetails.uploadedBy}
            position={{ lat: itemDetails.latitude, lng: itemDetails.longitude }}
          />
        );
      });
    });
  }

  gotoNext() {
    this.setState((prevState) => ({
      imageIndex: prevState.imageIndex + 1,
    }));
  }

  gotoPrevious() {
    this.setState((prevState) => ({
      imageIndex: prevState.imageIndex - 1,
    }));
  }
  thumbnailClick(e) {
    this.setState({
      imageIndex: e,
    });
  }
  handleDelete() {
    this.trackDeleteClick();

    this.setState({
      showAttachmentListPopup: true,
    });
    this.getAttachments();
  }

  handleDeleteAttachment() {
    this.getAttachments();
  }

  handleOnAttachmentsListPopupClose() {
    this.setState({
      showAttachmentListPopup: false,
    });
    this.getdata();
  }

  getCategoryElement(category, key) {
    if (category.attachments.length > 0) {
      return (
        <Panel
          eventKey={key}
          key={key}
          className="collapsible-panel"
          defaultExpanded
        >
          <div
            role="menu"
            id="collapsible-panel--heading"
            className="panel-heading"
          >
            <Panel.Heading role="menuitem" aria-labelledby="category">
              <Panel.Title toggle>{category.heading}</Panel.Title>
            </Panel.Heading>
          </div>
          <Panel.Collapse>
            <Panel.Body>
              {category.attachments.map((item, i) => {
                let title = "";
                if (this.state.organizedBy === "family") {
                  title = item.applicationName;
                } else {
                  title = item.productFamily;
                }
                title = title + " : " + item.city;

                let filePath = Api.getBaseURL() + "/uploads/" + item.fileName;
                let showHideClass = "";
                let borderCLass = "";
                if (category.heading !== "Photo of the month") {
                  showHideClass = "hidden";
                  borderCLass = "normal-product-details-container";
                } else {
                  borderCLass = "month-product-details-container";
                }
                return (
                  <Col md={3} sm={6} key={item.id}>
                    <div className="product-container">
                      <div className={borderCLass}>
                        <p
                          className={showHideClass}
                          id="monthPhoto"
                          style={{
                            color: "#d28d29",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          PHOTO OF THE MONTH
                        </p>
                        <div className="gallery-image-box">
                          <ContextMenuTrigger
                            id="some_unique_identifier"
                            heading={category.heading}
                            idNumber={item.id}
                            src={filePath}
                            applicationName={item.applicationName}
                            applicationFamily={item.productFamily}
                            address={item.address}
                            fileName={item.fileName}
                            uploadedby={item.uploadedBy}
                            date={item.date}
                            collect={(props) => props}
                            disable={false}
                          >
                            <img
                              src={filePath}
                              alt="Product img"
                              className="galleryGrid-image"
                              uploadedby={item.uploadedBy}
                              productfamily={item.productFamily}
                              onClick={this.handleImageView}
                            />
                          </ContextMenuTrigger>
                        </div>

                        <div className="product-content">
                          <Row>
                            <div style={{ textAlign: "center" }}>
                              <img
                                align="center"
                                style={{
                                  display: "inline",
                                  marginRight: "5px",
                                }}
                                alt="Product img"
                                className={showHideClass}
                                src={fetchResourceData(fixedLink.crownIcon)}
                              />
                            </div>
                          </Row>
                          <Row>
                            <div style={{ textAlign: "center" }}>
                              <span style={{ wordBreak: "break-word" }}>
                                {item.uploadedBy}
                              </span>
                              <br />
                              <p style={{ textAlign: "center" }}>{title}</p>
                            </div>
                          </Row>
                          <Row>
                            <Checkbox
                              className="download-checkbox"
                              onChange={() =>
                                this.handleFileSelect(
                                  item.id,
                                  filePath,
                                  item.fileName
                                )
                              }
                              checked={this.isChecked(item.id)}
                              aria-label="Download Checkbox"
                            />
                            <Button
                              bsStyle="primary"
                              className="button-blue download-btn"
                              href={filePath}
                              download={item.fileName}
                              onClick={() => {
                                this.trackInstallationDownloadButtonClick(item);
                              }}
                            >
                              Download
                            </Button>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );
    }
  }

  handleMenuUpdate(e, data) {
    this.trackInstallationImageEditDetailsClick(data);
    this.setState({
      show: true,
      selectedApplication: data.applicationName,
      selectedFamily: data.applicationFamily,
      selectedAddress: data.address,
      selectedFileName: data.fileName,
      UpdateButtonName: "Update",
      uploadedBy: data.uploadedBy + " (" + data.date + ") ",
      hideDetails: "visible",
      selectedUpdateId: data.idNumber,
    });
  }

  handleMenuDelete(e, data) {
    let temp = data.fileName;
    let fixedAttachmentName = temp.substring(temp.indexOf("_") + 1);
    this.trackDeleteInstallationButtonClick(fixedAttachmentName, data.idNumber);
    this.setState({
      deleteAttachmentPopup: true,
      attachmentName: fixedAttachmentName,
      attachmentId: data.idNumber,
    });
  }

  cancelAttachmentDeletion() {
    this.setState({
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    });
  }

  menuDelete(id) {
    GalleryDataHandler.deleteInstallations(this.state.attachmentId)
      .then((response) => {
        this.getApplicationTypes();
        this.getProductNames();
        this.trackInstallationDeleteConfirmationButtonClick();

        this.props.addNotification(
          DisplayMessages.InstallationGallery_RecordDeleteMsg,
          "info"
        );
        this.cancelAttachmentDeletion();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_DeleteInstallation,
          "error"
        );
      });
  }

  handleEditContentClick() {
    if (this.state.editContent) {
      let text = document.getElementById("classEdit").innerText;
      let description = { text: text };
      GalleryDataHandler.updateGalleryDescription(description)
        .then((response) => {
          if (response.success) {
            this.getDescriptionText();
          }
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.InstallationGallery_EditContent,
            "error"
          );
        });
      this.trackConfirmInstallsHeadingClick(text);
    } else {
      this.trackEditInstallsHeadingClick();
    }
    this.setState({
      editContent: !this.state.editContent,
      editContentClass:
        this.state.editContentClass === "glyphicon glyphicon-pencil"
          ? "glyphicon glyphicon-ok"
          : "glyphicon glyphicon-pencil",
    });
  }

  showIfAdmin() {
    if (![this.props.role].includes("360 Admin")) {
      return "button-download button-blue hidden";
    } else {
      return "button-download button-blue";
    }
  }
  hideIfAdmin() {
    if ([this.props.role].includes("360 Admin")) {
      return "inst-btn button-blue hideButton";
    } else {
      return "inst-btn button-blue";
    }
  }

  showContextMenuAdmin() {
    if (![this.props.role].includes("360 Admin")) {
      return "hidden";
    } else {
      return "";
    }
  }

  handleMenuSetPhoto(e, data) {
    let data1 = { id: data.idNumber, username: this.state.userName };
    this.trackPhotoOfTheMonthOptionClick(data1);

    GalleryDataHandler.setAsMonthPhoto(data1)
      .then((response) => {
        this.getdata();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_SetPhoto,
          "error"
        );
      });
  }

  handleApplicationTypeChange(event) {
    if (event === null) {
      this.setState(
        {
          searchApplication: "",
          searchFamily: "",
        },
        this.filterProductNames
      );
    } else {
      let application = event.value;
      this.setState(
        {
          searchApplication: application,
          searchFamily: "",
        },
        this.filterProductNames
      );
    }
  }

  handleProductFamilyChange(event) {
    if (event == null) {
      this.setState({
        searchFamily: "",
      });
    } else {
      this.setState({
        searchFamily: event.value,
      });
    }
  }

  handleMenuShow(e, data) {
    let heading = e.detail.data.heading;
    if (heading === "Photo of the month") {
      this.setState({ disableMenuItem: "disabledMenu" });
    } else {
      this.setState({ disableMenuItem: "" });
    }
  }

  applicationDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.searchApplication,
          label:
            this.state.searchApplication === null ||
            this.state.searchApplication === ""
              ? "Select Application Type"
              : this.state.searchApplication,
        }}
        aria-label="Application Type"
        styles={customStyles}
        isClearable
        onChange={this.handleApplicationTypeChange}
        options={this.state.applicationTypes}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  productFamilyDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.searchApplication,
          label:
            this.state.searchFamily === null || this.state.searchFamily === ""
              ? "Select Product Family"
              : this.state.searchFamily,
        }}
        aria-label="Product Family"
        styles={customStyles}
        isClearable
        onChange={this.handleProductFamilyChange}
        options={this.state.productNames}
        formatCreateLabel={() => undefined}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  render() {
    return (
      <div className="container-div loading-fade">
        <div>
          <h2 style={{ color: "#1B2D5B", fontWeight: "bold" }}>
            {Miscellaneous.FootPrintText}
          </h2>
        </div>
        <hr />
        <Row>
          <Col md={8} sm={12} xs={12} className="no-padding">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexFlow: "nowrap",
                alignItems: "flex-end",
              }}
            >
              <Button
                bsStyle="primary"
                bsSize="small"
                className={this.showIfAdmin()}
                style={{ height: "35px", width: "35px", marginRight: "20px" }}
                onClick={this.handleEditContentClick}
                aria-label="Edit Text"
              >
                <span
                  className={this.state.editContentClass}
                  style={{ verticalAlign: "middle" }}
                />
              </Button>
              <p
                onChange={this.handleEdit}
                contentEditable={this.state.editContent}
                suppressContentEditableWarning={true}
                id={this.state.editContent ? "classEdit" : ""}
                style={{
                  display: "inline-block",
                  margin: "5px 0px",
                  fontSize: "16px",
                  width: "90%",
                }}
              >
                {this.state.description}
              </p>
            </div>
          </Col>
          <Col md={4} sm={12} xs={12} className="no-padding">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexFlow: "nowrap",
                float: "right",
              }}
            >
              <Button
                bsStyle="primary"
                bsSize="small"
                className="inst-btn button-blue"
                onClick={this.uploadForm}
              >
                <span className="glyphicon glyphicon-upload" />
                &nbsp;Upload Pic
              </Button>
              <Button
                bsStyle="primary"
                bsSize="small"
                className="inst-btn button-blue"
                onClick={this.handleMap}
              >
                <span className="glyphicon glyphicon-view" />
                &nbsp;{this.state.buttonName}
              </Button>
              <Button
                bsStyle="primary"
                bsSize="small"
                className="inst-btn button-blue"
                onClick={this.handleEmailShare}
              >
                <span className="glyphicon glyphicon-share" />
                &nbsp;Share
              </Button>
              <Button
                bsStyle="primary"
                bsSize="small"
                className={this.hideIfAdmin()}
                onClick={this.handleDelete}
              >
                <span className="glyphicon glyphicon-delete" />
                &nbsp;Delete
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <FormGroup>
            <Row>
              <Col md={3} style={{ paddingLeft: "0px" }}>
                <Autocomplete
                  className="autocompleteMain-container"
                  placeholder="Search by Location"
                  onChange={this.handleSearchAddressChange}
                  onPlaceSelected={(place) => {
                    this.setState({ searchAddress: place.formatted_address });
                  }}
                  types={["geocode"]}
                  componentRestrictions={{
                    country: Miscellaneous.CountryCode,
                  }}
                />
              </Col>
              <Col md={3} style={{ paddingLeft: "0px" }}>
                {this.applicationDropdown()}
              </Col>
              <Col md={3} style={{ paddingLeft: "0px" }}>
                {this.productFamilyDropdown()}
              </Col>
              <Col md={3} style={{ paddingRight: "0px" }}>
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  style={{ float: "left", minHeight: "34px", height: "20px" }}
                  onClick={() => {
                    this.trackApplyFilterButtonClick();
                    this.getdata();
                  }}
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
            <FormControl.Feedback />
            <br />
          </FormGroup>
        </Row>

        <Row>
          <Col md={9} xs={12} className="sortby">
            <span className="default-label">Sort By:</span>
            <label>
              {" "}
              <input
                type="radio"
                name="sortBy"
                value="recent"
                onChange={this.handleSort}
                style={{ marginLeft: 10 }}
                defaultChecked
              />{" "}
              Recently Added
            </label>
            <label>
              <input
                type="radio"
                name="sortBy"
                value="alpha"
                onChange={this.handleSort}
                style={{ marginLeft: 20 }}
              />{" "}
              Alphabetical
            </label>
          </Col>
          <Col md={3} xs={12} className="organizeby">
            <span className="default-label">Organize By:</span>
            <label>
              {" "}
              <input
                type="radio"
                name="organizeBy"
                value="application"
                onChange={this.handleOrganize}
                style={{ marginLeft: 10 }}
              />{" "}
              Product Application
            </label>
            <label>
              <input
                type="radio"
                name="organizeBy"
                value="family"
                defaultChecked
                onChange={this.handleOrganize}
                style={{ marginLeft: 20 }}
              />{" "}
              Product Family
            </label>
          </Col>

          <br />
          <br />
        </Row>

        <Row>
          <Map
            google={this.props.google}
            zoom={4}
            minZoom={2}
            initialCenter={{
              lat: Miscellaneous.Latitude,
              lng: Miscellaneous.Longitude,
            }}
            className={this.state.showMap}
          >
            {this.getMarkers()}
          </Map>
          <br />
          <br />
        </Row>

        <Row>
          <div>
            {this.state.headingList.map((item, i) => {
              return this.getCategoryElement(item, i);
            })}
          </div>
        </Row>
        <div>
          <ContextMenu
            role="menu"
            id="some_unique_identifier"
            collect={(props) => props}
            className={this.showContextMenuAdmin()}
            onShow={this.handleMenuShow}
          >
            <MenuItem data={{}} onClick={this.handleMenuDelete} role="menuitem">
              <i className="fa fa-trash" style={{ marginRight: "10px" }} />
              Delete
            </MenuItem>
            <MenuItem data={{}} onClick={this.handleMenuUpdate} role="menuitem">
              <i
                className="fa fa-pencil-square-o"
                style={{ marginRight: "10px" }}
              />
              Edit Details
            </MenuItem>
            <MenuItem divider role="menuitem" />
            <MenuItem
              data={{}}
              onClick={this.handleMenuSetPhoto}
              role="menuitem"
            >
              <div className={this.state.disableMenuItem}>
                <i className="fa fa-cogs" style={{ marginRight: "10px" }}/>
                Set 'Photo of the Month'
              </div>
            </MenuItem>
          </ContextMenu>
        </div>
        <UploadPopup
          show={this.state.show}
          handleOnClose={this.handleOnClose}
          addNotification={this.props.addNotification}
          getdata={this.getdata}
          organizedBy={this.state.organizedBy}
          applicationName={this.state.selectedApplication}
          family={this.state.selectedFamily}
          address={this.state.selectedAddress}
          fileName={this.state.selectedFileName}
          uploadedBy={this.state.uploadedBy}
          hideDetails={this.state.hideDetails}
          id={this.state.selectedUpdateId}
          applicationType={this.props.applicationType}
        />
        <EmailPopup
          show={this.state.showEmailPopup}
          attachments={this.state.selectedFilesForShare}
          handleOnClose={this.handleCloseEmailPopup}
          addNotification={this.props.addNotification}
          clearSelection={this.clearSelection}
          emailTemplate={this.state.templateString}
          type="gallery"
        />
        <InstallsAttachmentsPopup
          show={this.state.showAttachmentListPopup}
          currentUser={this.state.currentUser}
          handleOnClose={this.handleOnAttachmentsListPopupClose}
          attachments={this.state.attachments}
          addNotification={this.props.addNotification}
          handleDeleteAttachment={this.handleDeleteAttachment}
        />

        <Lightbox
          images={this.state.imageArray}
          isOpen={this.state.open}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={() => this.setState({ open: false })}
          showThumbnails={true}
          currentImage={this.state.imageIndex}
          onClickThumbnail={this.thumbnailClick}
        />
        <DeleteConfirmationPopup
          show={this.state.deleteAttachmentPopup}
          item={this.state.attachmentName}
          itemType={"Attachment"}
          handleConfirmation={this.menuDelete}
          handleCancel={this.cancelAttachmentDeletion}
        />
      </div>
    );
  }

  // Mixpanel Tracking //

  trackConfirmInstallsHeadingClick(description) {
    mixpanel.track("Confirm Installs Heading", {
      Action: "Button Click",
      Effect: "Confirm installs heading text",
      Description: description,
    });
  }

  trackEditInstallsHeadingClick() {
    mixpanel.track("Edit Installs Heading", {
      Action: "Button Click",
      Effect: "Enable edit installs heading text",
    });
  }

  trackUploadPicButtonClick() {
    mixpanel.track("Upload Pic", {
      Action: "Button Click",
      Effect: "Upload installation photo popup is displayed",
    });
  }

  trackHideMapButtonClick() {
    mixpanel.track("Installation Marker Map", {
      Action: "Button Click",
      Effect: "Show/Hide installation map",
      "Map Status": this.state.buttonName,
    });
  }

  trackEmailShareButtonClick(open) {
    mixpanel.track("Share Installations", {
      Action: "Button Click",
      Effect: open ? "Open email share popup" : "No installations selected",
    });
  }

  trackDeleteClick() {
    mixpanel.track("Delete Installations", {
      Action: "Button Click",
      Effect: "Installations of current user will be deleted",
    });
  }

  trackApplyFilterButtonClick() {
    mixpanel.track("Installs Filter", {
      Action: "Button Click",
      Effect: "Filter installations based on selections",
      "Sort By": this.state.sortBy,
      Address: this.state.searchAddress,
      "Product Application": this.state.searchApplication,
      "Product Family": this.state.searchFamily,
      "Organize By": this.state.organizedBy,
    });
  }

  trackSortBySelection(sortBy) {
    mixpanel.track("Sort By - Installations", {
      Action: "Button Click",
      Effect: "Sort installations based on filter",
      "Sort By": sortBy,
    });
  }

  trackOrganizeBySelection(organizeBy) {
    mixpanel.track("Organize By - Installations", {
      Action: "Button Click",
      Effect: "Organize installations based on filter",
      "Organize By": organizeBy,
    });
  }

  trackInstallationImageClick(uploader, productFamily) {
    mixpanel.track("Installation Image", {
      Action: "Image Click",
      Effect: "Display installation image in fullscreen mode",
      "Uploaded By": uploader,
      "Product Family": productFamily,
    });
  }

  trackInstallationCheckBoxSelection(isChecked, id, fileName) {
    mixpanel.track("Installation Image Selection", {
      Action: "Checkbox Click",
      Effect: "Check/Uncheck installation image",
      Selected: isChecked,
      "Image ID": id,
      Filename: fileName,
    });
  }

  trackInstallationDownloadButtonClick(item) {
    mixpanel.track("Installation Image Download", {
      Action: "Button Click",
      Effect: "Initiate installation image download",
      "Image ID": item.id,
      Filename: item.fileName,
    });
  }

  trackDeleteInstallationButtonClick(name, id) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected installation will be deleted",
      "Item Type": "Installation",
      Name: name,
      ID: id,
    });
  }

  trackInstallationDeleteConfirmationButtonClick() {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected installation deleted",
      "Item Type": "Installation",
      Name: this.state.attachmentName,
      ID: this.state.attachmentId,
    });
  }

  trackInstallationImageEditDetailsClick(data) {
    mixpanel.track("Edit Installation Details", {
      Action: "Button Click",
      Effect: "Edit Popup will be displayed",
      "Product Application": data.applicationName,
      "Product Family": data.applicationFamily,
    });
  }

  trackPhotoOfTheMonthOptionClick(item) {
    mixpanel.track("Photo of the month", {
      Action: "Button Click",
      Effect: "Installation set as new photo of the month",
      "Photo ID": item.id,
      Username: item.username,
    });
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API,
})(InstallationGallery);
