import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/lib/Navbar";
import Nav from "react-bootstrap/lib/Nav";
import NavItem from "react-bootstrap/lib/NavItem";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import mixpanel from "mixpanel-browser";

import yourPricingHandler from "../YourPricing/YourPricingHandler";
import "./navigation.css";
import "./navigation-ipad.css";
import "./navigation-mac.css";
import Plugin from "../Utils/plugin.js";
import DistributorsHandler from "../Distributors/DistributorsHandler";
import { ErrorMessages } from "../Utils/FetchConfigurableData";

const customerBasedRoles = ["360 Admin", "Internal Salesrep", "Salesrep"];
const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the navigation bar
 */
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightingSpaceCategory: [],
      indoorApplicationsCategory: [],
      outdoorApplicationsCategory: [],
      category: [],
      expanded: false,
      showAdmin: true,
      showDistributorManagement: true,
      showClearance: true,
      showYourPricing: true,
      showGoldenPrice: true,
      showTrackMyOrder: true,
      windowHeight: 0,
    };

    this.logOut = this.logOut.bind(this);
    this.getSpecialPricingAvailability =
      this.getSpecialPricingAvailability.bind(this);
  }
  spacesList = [
    "Indoor Lighting",
    "Outdoor Lighting",
    "Poles",
    "Accessories",
    "Construction Products",
  ];

  componentDidMount() {
    this.getSpaces();

    this.getSpecialPricingAvailability();

    if (branchBasedRoles.includes(this.props.role)) {
      let username = encodeURIComponent(sessionStorage.getItem("username"));
      DistributorsHandler.getBranchAdminData(username)
        .then((response) => {
          if ([this.props.role].includes("Distributor User")) {
            if (response.data.tld.tldVisibility === "no") {
              this.setState({
                showDistributorManagement: false,
              });
            }
          }
          if (response.data.tld.pricingAccess === "no") {
            this.setState({
              showClearance: false,
              showYourPricing: false,
            });
          }
          if (response.data.tld.goldenPriceAccess === "no") {
            this.setState({
              showYourPricing: false,
              showGoldenPrice: false,
            });
          }
          if (response.data.tld.orderTrackAccess === "no") {
            this.setState({
              showTrackMyOrder: false,
            });
            sessionStorage.setItem("orderTrackAccess", false);
          }
        })
        .catch((error) => {
          this.props.addNotification(ErrorMessages.BranchAdminData, "error");
        });
    }
  }

  componentWillReceiveProps() {
    this.getSpecialPricingAvailability();
  }

  getSpecialPricingAvailability() {
    let oldPricing = this.state.showYourPricing;
    yourPricingHandler
      .checkPricingAvailability()
      .then((response) => {
        this.setState(
          (prevState) => ({
            showYourPricing: response.data && prevState.showClearance,
          }),
          () => {
            if (oldPricing !== this.state.showYourPricing) {
              this.props.getSpecialPricingAvailability();
            }
          }
        );
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.Navigation_SpecialPricingListUnavailable,
          "error"
        );
      });
  }

  render() {
    return (
      <Navbar
        collapseOnSelect
        className="navbar-blue"
        id="navbar"
        role="navigation"
      >
        <Navbar.Header>
          <Navbar.Toggle style={{ float: "left", marginLeft: "10px" }} />
        </Navbar.Header>
        <Navbar.Collapse
          id="navbar_collapse"
          style={{ paddingLeft: "0px", left: "-1px" }}
        >
          <Nav>
            <LinkContainer to="/Home">
              <NavItem
                eventKey={1}
                onClick={() => {
                  this.trackNavigationClick("Home Page");
                }}
              >
                HOME
              </NavItem>
            </LinkContainer>
            <NavDropdown
              id="productsList"
              eventKey={2}
              title="PRODUCTS"
              onClick={(e) => {
                this.trackProductDropdownClick();
                e.stopPropagation();
              }}
            >
              {this.getMenu()}
            </NavDropdown>
            <LinkContainer to="/YourPricing">
              <NavItem
                eventKey={3}
                className={this.showYourPricing()}
                onClick={() => {
                  this.trackNavigationClick("Your Pricing Page");
                }}
              >
                YOUR PRICING
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Clearance">
              <NavItem
                eventKey={4}
                className={this.showClearance()}
                onClick={() => {
                  this.trackNavigationClick("Clearance Page");
                }}
              >
                CLEARANCE
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Tableau">
              <NavItem
                eventKey={5}
                onClick={() => {
                  this.trackNavigationClick("Real-Time Reports Page");
                }}
              >
                REAL-TIME REPORTS
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/TrackMyOrder">
              <NavItem
                eventKey={6}
                className={this.showTrackMyOrder()}
                onClick={() => {
                  this.trackNavigationClick("Track My Order Page");
                }}
              >
                TRACK MY ORDER
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Compare">
              <NavItem
                eventKey={7}
                onClick={() => {
                  this.trackNavigationClick("Compare Page");
                }}
              >
                COMPARE
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Installs">
              <NavItem
                eventKey={8}
                onClick={() => {
                  this.trackNavigationClick("Installs Page");
                }}
              >
                INSTALLS
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Resources">
              <NavItem
                eventKey={9}
                onClick={() => {
                  this.trackNavigationClick("Resources Page");
                }}
              >
                RESOURCES
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/QuickConfigurator">
              <NavItem
                eventKey={11}
                onClick={() => {
                  this.trackNavigationClick("Quick Configurator Page");
                }}
              >
                QUICK CONFIGURATOR
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Content">
              <NavItem
                eventKey={12}
                className={this.showIfAdmin()}
                onClick={() => {
                  this.trackNavigationClick("Content Page");
                }}
              >
                CONTENT
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/Distributors">
              <NavItem
                eventKey={13}
                className={this.showIfDistributorTabAllowed()}
                onClick={() => {
                  this.trackNavigationClick("Distributors Page");
                }}
              >
                DISTRIBUTOR
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/DistributorsManagement">
              <NavItem
                eventKey={14}
                className={this.showIfDistributorManagementTabAllowed()}
                onClick={() => {
                  this.trackNavigationClick("Distributors Page");
                }}
              >
                DISTRIBUTOR
              </NavItem>
            </LinkContainer>
          </Nav>

          <Nav pullRight>
            <NavItem eventKey={15} className="log-out" onClick={this.logOut}>
              LOG OUT
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  getMenu() {
    return this.state.lightingSpaceCategory.map((space, i) => {
      return (
        <LinkContainer
          key={space.id}
          to={{
            pathname: "/Products",
            state: { space: space },
          }}
          onClick={() => this.trackProductSpaceNavigationClick(space)}
        >
          <NavDropdown
            id={space.id}
            eventKey={space.id}
            title={space.name}
            className="dropdown-submenu"
            noCaret
          >
            {this.getSubMenu(space)}
          </NavDropdown>
        </LinkContainer>
      );
    });
  }

  showIfAdmin() {
    if (![this.props.role].includes("360 Admin")) {
      return "hidden";
    }
  }
  showIfDistributorTabAllowed() {
    if (branchBasedRoles.includes(this.props.role)) {
      return "hidden";
    }
  }
  showIfDistributorManagementTabAllowed() {
    if (
      customerBasedRoles.includes(this.props.role) ||
      !this.state.showDistributorManagement
    ) {
      return "hidden";
    }
  }
  showClearance() {
    if (!this.state.showClearance) {
      return "hidden";
    }
  }
  showTrackMyOrder() {
    if (!this.state.showTrackMyOrder) {
      return "hidden";
    }
  }
  showYourPricing() {
    if (!this.state.showYourPricing || !this.state.showGoldenPrice) {
      return "hidden";
    }
  }
  getSubMenu(space) {
    return this.state.category
      .filter((category) => Number(category.parent) === space.id)
      .map((category) => this.getSubMenuItem(category, space));
  }
  getSubMenuItem(category, space) {
    return (
      <MenuItem eventKey={category.id} key={category.id} role="menuitem">
        <LinkContainer
          to={{
            pathname: "/Products",
            state: { space: space, application: category },
          }}
          onClick={() =>
            this.trackProductCategoryNavigationClick(space, category)
          }
        >
          <NavDropdown title={category.name} noCaret id={category.id} />
        </LinkContainer>
      </MenuItem>
    );
  }
  getSpaces() {
    Plugin.get("spaces")
      .then((response) => {
        this.setState({
          lightingSpaceCategory: this.filterOnlyIndoorOutdoor(
            response.data.data
          ),
        });
        this.getCategories();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.Navigation_SpacesMissing,
          "error"
        );
      });
  }
  filterOnlyIndoorOutdoor(data) {
    return data.filter((item) => this.spacesList.includes(item.name));
  }
  getCategories() {
    Plugin.get("categories")
      .then((response) => {
        this.setState({
          category: response.data.data,
        });
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.Navigation_CategoriesMissing,
          "error"
        );
      });
  }

  logOut() {
    this.trackLogoutClick();
    this.props.logOutHandler();
  }

  // Mixpanel Tracking //

  trackNavigationClick(page) {
    mixpanel.track_pageview({
      Page: page,
    });
  }

  trackProductDropdownClick() {
    mixpanel.track(`Products Dropdown`, {
      Action: "Navigation Click",
      Effect: "Display list of spaces",
    });
  }

  trackProductSpaceNavigationClick(space) {
    mixpanel.track("Product Spaces Navigation", {
      Action: "Dropdown Selection",
      Effect: "Page Navigation to selected space",
      "Space ID": space.id,
      Space: space.name,
    });

    mixpanel.track_pageview({
      Page: "Products Page",
      "Search Results": space.name,
    });
  }

  trackProductCategoryNavigationClick(space, category) {
    mixpanel.track("Product Application Navigation", {
      Action: "Dropdown Selection",
      Effect: "Page Navigation to selected application",
      "Space ID": space.id,
      Space: space.name,
      "Application ID": category.id,
      Application: category.name,
    });

    mixpanel.track_pageview({
      Page: "Products Page",
      "Search Results": `${space.name} + ${category.name}`,
    });
  }

  trackLogoutClick() {
    mixpanel.track(`User Logout`, {
      Action: "Button Click",
      Effect: `User Logout Initiated`,
    });
  }
}
export default Navigation;
