import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Autocomplete from "react-google-autocomplete";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import "./installationgallery.css";
import ProductListPopup from "../ProductListPopup/ProductListPopup.js";
import ContentDataHandler from "../Content/ContentDataHandler.js";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import GalleryDataHandler from "./GalleryDataHandler";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

/**
 * Component represents the uploads popup used to upload a new installation
 */
class UploadPopup extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      selectedApplicationType: "",
      applicationType: [],
      productName: "",
      showProductListPopup: false,
      searchString: "",
      address: "",
      files: [],
      selectedId: -1,
      fileName: "",
      buttonName: "",
      uploadedBy: "",
      hideDetails: "hidden",
      selectedUpdateId: -1,
      updateButtonDisabled: false,
    };
    this.cancel = this.cancel.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.generateApplicationOptions =
      this.generateApplicationOptions.bind(this);
    this.search = this.search.bind(this);
    this.handleProductNameChange = this.handleProductNameChange.bind(this);
    this.handleProductSelect = this.handleProductSelect.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleOnProductListPopupClose =
      this.handleOnProductListPopupClose.bind(this);
    this.upload = this.upload.bind(this);
    this.handleApplicationTypeChange =
      this.handleApplicationTypeChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillMount() {
    this._isMounted = true;
    this.getApplicationTypes();
    this.getUploaderInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(props) {
    this.setState((prevState) => ({
      selectedApplicationType:
        props.applicationName === ""
          ? this.ternaryEvaluationForApplicationName(
              prevState.applicationType[0],
              undefined,
              undefined,
              prevState.applicationType[0]
            )
          : props.applicationName,
      productName: props.family,
      address: props.address,
      fileName: props.fileName,
      buttonName: props.buttonName,
      hideDetails: props.hideDetails,
      selectedUpdateId: props.id,
    }));
  }

  ternaryEvaluationForApplicationName(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2.name;
  }

  getUploaderInfo() {
    ConfigHandler.getDisplayName()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            uploadedBy: response.message,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.UploadPopup_GetCurrentUser,
          "error"
        );
      });
  }

  getApplicationTypes() {
    GalleryDataHandler.getAllApplicationTypes()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            applicationType: response.data,
            selectedApplicationType: response.data[0].name,
            selectedId: response.data[0].application_id,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_GetApplicationTypes,
          "error"
        );
      });
  }

  cancel() {
    this.setState((prevState) => ({
      selectedApplicationType: prevState.applicationType[0].name,
      productName: "",
      searchString: "",
      address: "",
      file: null,
      fileName: "",
      updateButtonDisabled: false,
    }));
    document.getElementById("galleryFile").value = "";
    document.getElementById("addressId").value = "";
    this.trackInstallationCancelButtonClick();
    this.props.handleOnClose();
  }

  handleOnClose() {
    this.cancel();
  }
  generateApplicationOptions() {
    return this.state.applicationType.map((type) => ({
      value: type.name,
      label: type.name,
    }));
  }

  handleProductNameChange(e) {
    this.setState({
      productName: e.target.value,
    });
  }

  handleAddressChange(e) {
    this.setState({
      address: e.target.value,
    });
  }

  search() {
    if (this.state.productName) {
      this.setState((prevState) => ({
        searchString: prevState.productName,
        showProductListPopup: true,
      }));
    } else {
      this.props.addNotification(
        ErrorMessages.InstallationGallery_ProductName,
        "error"
      );
    }
  }

  handleProductSelect(productName, selectedProduct) {
    this.setState({
      showProductListPopup: false,
      productName: productName,
      selectedProduct: selectedProduct,
    });
  }

  handleOnProductListPopupClose() {
    this.setState({
      showProductListPopup: false,
    });
  }
  upload() {
    this.setState({
      updateButtonDisabled: true,
    });

    this.validateUpload()
      .then((form) => {
        GalleryDataHandler.uploadInstallation(form)
          .then((response) => {
            this.trackInstallationUploadButtonClick();
            if (response.success) {
              this.props.addNotification(
                DisplayMessages.InstallationGallery_DocUploadMsg,
                "info"
              );
              this.handleOnClose();
              this.props.getdata(this.props.organizedBy);
            } else {
              this.props.addNotification(response.message, "error");
              this.setState({
                updateButtonDisabled: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              updateButtonDisabled: false,
            });
            this.props.addNotification(
              ErrorMessages.InstallationGallery_DocUploadMsg,
              "error"
            );
          });
      })
      .catch((error) => {
        this.setState({
          updateButtonDisabled: false,
        });
        this.props.addNotification(
          ErrorMessages.InstallationGallery_DocUploadMsg,
          "error"
        );
      });
  }

  validateUpload() {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      let id = parseInt(this.state.selectedUpdateId, 10);
      form.append("Id", id);
      form.append("UploadedBy", this.state.uploadedBy);
      form.append("ApplicationType", this.state.selectedApplicationType);
      if (this.state.address) {
        form.append("Address", this.state.address);
      }
      if (this.state.files) {
        for (let element of this.state.files) {
          let fileSize = this.state.files[0].size;
          if (fileSize > 26214400) {
            reject(new Error("Larger file size"));
          }
          form.append("UploadedFile", element);
        }
      }
      if (!this.state.productName) {
        reject(new Error("Enter product family"));
      }
      ContentDataHandler.fetchProductsByProductName(this.state.productName)
        .then((response) => {
          if (
            response.data.products.find(
              (item) => item.title === this.state.productName
            )
          ) {
            form.append("ProductFamily", this.state.productName);
            resolve(form);
          } else {
            reject(new Error("Invalid product family"));
          }
        })
        .catch((error) => {
          reject(new Error("Error in finding product family"));
        });
    });
  }

  handleFileChange(e) {
    let path = e.target.value;
    let filename = path.substring(path.lastIndexOf("\\") + 1);
    this.setState({
      files: e.target.files,
      fileName: filename,
    });
  }

  handleApplicationTypeChange(e) {
    let selectedItem = e.label;
    let selectedIndex = e.value;
    this.setState({
      selectedApplicationType: selectedItem,
      selectedId: selectedIndex,
    });
  }

  handleKeyPress(target) {
    if (target.keyCode === 13) {
      this.search();
    }
  }
  render() {
    return (
      <div className="filter-wizard">
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              {this.state.buttonName} Installation Photo
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <form>
                  <h4>Select Product Application</h4>
                  <Select
                    maxMenuHeight={190}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    value={{
                      value: this.state.selectedApplicationType,
                      label:
                        this.state.selectedApplicationType !== undefined
                          ? this.state.selectedApplicationType
                          : "Select product application",
                    }}
                    styles={customStyles}
                    onChange={this.handleApplicationTypeChange}
                    options={this.generateApplicationOptions()}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "lightgray",
                        primary: "lightgray",
                      },
                    })}
                  />
                  <h4>
                    Search for product by product family<mark>*</mark>
                  </h4>
                  <FormGroup>
                    <Row>
                      <Col md={11} xs={8} style={{ paddingLeft: "0px" }}>
                        <FormControl
                          type="text"
                          value={this.state.productName}
                          placeholder="Enter product family"
                          onChange={this.handleProductNameChange}
                          onKeyDown={this.handleKeyPress}
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={1} xs={4} style={{ paddingRight: "0px" }}>
                        <Button
                          bsStyle="primary"
                          className="glyphicon glyphicon-search button-blue"
                          style={{ float: "right" }}
                          onClick={this.search}
                        />
                      </Col>
                    </Row>
                    <FormControl.Feedback />
                    <br />
                  </FormGroup>
                  <h4>
                    Installation Address<mark>*</mark>
                  </h4>
                  <FormGroup>
                    <Autocomplete
                      className="pac-container"
                      id="addressId"
                      placeholder="Enter address"
                      onChange={this.handleAddressChange}
                      value={this.state.address}
                      onPlaceSelected={(place) => {
                        this.setState({ address: place.formatted_address });
                      }}
                      types={["geocode"]}
                      autoComplete="nope"
                      componentRestrictions={{
                        country: Miscellaneous.CountryCode,
                      }}
                    />
                    <br />
                    <br />
                  </FormGroup>
                  <h4>
                    Choose an image to upload<mark>*</mark>
                  </h4>
                  <FormGroup>
                    <FormControl
                      id="galleryFile"
                      type="file"
                      label="File"
                      help=""
                      onChange={this.handleFileChange}
                    />
                    <label htmlFor="galleryFile" id="chooseFile">
                      Choose file
                    </label>{" "}
                    <label>{this.state.fileName} </label>
                    <br />
                  </FormGroup>
                  <h4 className={this.state.hideDetails}>Uploaded by</h4>
                  <FormGroup className={this.state.hideDetails}>
                    <FormControl
                      type="text"
                      value={this.state.uploadedBy}
                      disabled
                    />
                    <FormControl.Feedback />
                    <br />
                  </FormGroup>
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col md={12}>
              <ButtonToolbar>
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  onClick={this.upload}
                  disabled={this.state.updateButtonDisabled}
                >
                  Update
                </Button>
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  onClick={this.cancel}
                  disabled={this.state.hideDetails === "hidden" ? false : true}
                >
                  Cancel
                </Button>
              </ButtonToolbar>
            </Col>
          </Modal.Footer>
        </Modal>

        <ProductListPopup
          show={this.state.showProductListPopup}
          searchString={this.state.searchString}
          handleOnClose={this.handleOnProductListPopupClose}
          handleProductSelect={this.handleProductSelect}
          addNotification={this.props.addNotification}
        />
      </div>
    );
  }

  // Mixpanel Tracking //

  trackInstallationUploadButtonClick() {
    mixpanel.track(`${this.state.buttonName} Installation`, {
      Action: "Button Click",
      Effect: `Installation will be ${this.state.buttonName}ed`,
      "Application Type": this.state.applicationType,
      "Product Name": this.state.productName,
      Address: this.state.address,
      Filename: this.state.fileName,
    });
  }

  trackInstallationCancelButtonClick() {
    mixpanel.track(`Cancel ${this.state.buttonName} Installation`, {
      Action: "Button Click",
      Effect: `Installation ${this.state.buttonName} is cancelled`,
    });
  }
}
export default UploadPopup;
