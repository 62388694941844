import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get pinned items
 * Delete pinned items
 * Get Banners and Support documents
 * Get Inventory Ticker
 */
const HomePageDataHandler = (function () {
  return {
    getPinnedItems: function () {
      return new Promise((resolve, reject) => {
        let route = `Favourite/AllPinnedItems`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deletePinnedItem: function (id) {
      return new Promise((resolve, reject) => {
        let route = `Favourite/DeleteFromFavourites?id=${id}`;
        Api.delete(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBannersAndSupportDocs: function (data) {
      return new Promise((resolve, reject) => {
        let route = `HomePage/BannersAndSupportDocs?resourceType=${data}`;
        Api.get(route)
          .then((result) => {
            let response = result.data;
            response.data.banners.map((item) => {
              item.filePath = Api.getBaseURL() + "/" + item.filePath;
              return item;
            });
            response.data.supportingDocs.map((item) => {
              item.filePath = Api.getBaseURL() + "/" + item.filePath;
              return item;
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInventoryTickers: function () {
      return new Promise((resolve, reject) => {
        let route = `HomePage/InventoryTicker`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default HomePageDataHandler;
