import Api from "../Utils/api.js";

/**
 * API Call to handle:
 * Send Mail
*/
const EmailPopupDataHandler = (function () {
  return {
    sendMail: function (route, mailContent) {
      return new Promise((resolve, reject) => {
        Api.post(route, mailContent)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default EmailPopupDataHandler;
