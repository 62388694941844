import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get category list
 * Get Product list in category
 * Get product configurations for given productId, category and customer
 */
const ClearanceHandler = (function () {
  return {
    getCategoryList: function () {
      return new Promise((resolve, reject) => {
        let route = `Clearance/Categories`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProductList: function (category) {
      return new Promise((resolve, reject) => {
        let route = `Clearance/ProductList?category=${category}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getConfigurations: function (productId, category, customer) {
      return new Promise((resolve, reject) => {
        let route = `Clearance/Configurations?productId=${productId}&category=${category}&customer=${customer}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ClearanceHandler;
