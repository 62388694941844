import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";

import "./videoPlayer.css";
class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.handleOnClose = this.handleOnClose.bind(this);
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleOnClose}
        bsSize="large"
        className="video-player-modal"
      >
        <Modal.Header>
          <h3
            style={{
              fontWeight: "bolder",
            }}
            className="popup-blue-text"
          >
            {this.getTitle()}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div id="video-display">
            <iframe src={this.getURL()} title={this.getTitle()} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  handleOnClose() {
    this.props.handleOnClose();
  }
  getURL() {
    return this.props.URL;
  }
  getTitle() {
    return this.props.title;
  }
}
export default VideoPlayer;
