import React from "react";
import Col from "react-bootstrap/lib/Col";

import FilterButton from "../FilterButton/FilterButton.js";

function createFilterButton(
  filterItem,
  callback,
  isSelected,
  selectedOption,
  filterType
) {
  if (!filterItem.icon) {
    filterItem.icon = "./Images/icons/area.png";
  }

  return (
    <Col xs={6} sm={4} md={3} key={filterItem.id}>
      <FilterButton
        filterItem={filterItem}
        handleFilterSelect={callback}
        key={filterItem.id}
        filterType={filterType}
        isOptionDisabled={selectedOption !== "option1"}
        isSelected={isSelected(filterItem)}
      />
    </Col>
  );
}
export default createFilterButton;
