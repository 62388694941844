import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import mixpanel from "mixpanel-browser";

import "./clearance.css";
import ClearanceHandler from "./ClearanceHandler.js";
import ClearanceProductPopup from "./ClearanceProductPopup";
import {
  DisplayMessages,
  ErrorMessages,
} from "../Utils/FetchConfigurableData.js";
import ContentLoading from "../ContentLoading/ContentLoading.js";

let priceFilterList = [
  "Lowest to Highest",
  "Highest to Lowest",
  "Under $50",
  "$50 - $200",
  "$200 - $300",
  "$300+",
];
let sortNumbers = [50, 200, 300];

/**
 * Component represents the clearance page
 */
class Clearance extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      categoryList: null,
      productList: null,
      isSelected: false,
      selectedCategory: "",
      filterType: priceFilterList[0],
      sortingList: null,
      productConfig: [],
      isProductSelected: false,
      selectedProduct: [],
      invLink: null,
      last_updated: null,
      firstRun: true,
      selectedCustomer: "",
      isLoading: true,
    };

    this.getProducts = this.getProducts.bind(this);
    this.updateProductList = this.updateProductList.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleProduct = this.handleProduct.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.filterConfig = this.filterConfig.bind(this);
    this.getCategoryList = this.getCategoryList.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getCategoryList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="container-div">
        {this.state.categoryList === null ||
        this.state.categoryList.length === 0
          ? this.clearanceLoadingSection()
          : this.clearanceAvailableSection()}
        <ClearanceProductPopup
          show={this.state.isProductSelected}
          selectedProduct={this.state.selectedProduct}
          productConfig={this.state.productConfig}
          handleOnClose={this.handleOnClose}
          applicationType={this.props.applicationType}
          invLink={this.state.invLink}
          last_updated={this.state.last_updated}
          selectedCustomer={this.state.selectedCustomer}
          selectedCategory={this.state.selectedCategory}
        />
      </div>
    );
  }

  clearanceLoadingSection() {
    if (this.state.isLoading) {
      return (
        <ContentLoading message={DisplayMessages.Clearance_Loading} size={80} />
      );
    } else {
      return (
        <Row>
          <h4 style={{ textAlign: "center" }}>
            {DisplayMessages.Clearance_NoProducts}
          </h4>
          <br />
          <br />
        </Row>
      );
    }
  }

  clearanceAvailableSection() {
    return (
      <div className="loading-fade">
        {this.categorySelectionSection()}
        <Row>
          <Row className="border-category" />
          <br />

          <div>
            {this.filterRangeSection()}
            {this.clearanceProductsSection()}
          </div>
        </Row>
      </div>
    );
  }

  categorySelectionSection() {
    return (
      <div>
        <Row>
          <h3>Select Category:</h3>
        </Row>
        <Row className="border-category" />
        <br />
        <div className="scrollable-div" style={{ maxHeight: "200px" }}>
          <Row>
            {this.state.categoryList !== null
              ? this.categorySelectionDisplaySection()
              : this.categorySelectionLoadingSection()}
          </Row>
        </div>
        <br />
      </div>
    );
  }

  categorySelectionDisplaySection() {
    return this.state.categoryList
      .filter((item) => !item.category.includes("Accessory"))
      .map((item, i) => {
        return (
          <Col md={2} sm={4} xs={12} key={item.category}>
            <div
              className="clearance-category-column"
              onClick={() => this.getProducts(item)}
            >
              <div className="raise-image">
                <img
                  src={item.featured_src}
                  alt="Product img"
                  className="product-image"
                  id={item.id}
                  loading="lazy"
                  style={{ maxHeight: "100px" }}
                />
              </div>
              <h4
                className={
                  item.category === this.state.selectedCategory
                    ? "bold-text"
                    : ""
                }
              >
                {item.category}
              </h4>
            </div>
          </Col>
        );
      });
  }

  filterRangeSection() {
    return (
      <Col md={2} sm={3} xs={12}>
        <div className="filter-space">
          <h4 style={{ padding: "10px" }}>Filter by</h4>
          <h5 style={{ marginLeft: "20px" }}>Price Range:</h5>
          {priceFilterList.map((item, i) => (
            <div key={item}>
              <label>
                <h5>
                  <input
                    type="radio"
                    name="sortBy"
                    value={item}
                    onChange={() => this.handleSort(item)}
                    checked={this.state.filterType === item}
                    style={{ marginLeft: 50 }}
                  />{" "}
                  {item}{" "}
                </h5>
              </label>
              <br />
            </div>
          ))}
          <label>
            <h5 style={{ marginLeft: "-30px" }}>
              <input
                type="radio"
                name="sortBy"
                value="alphabetic"
                onChange={() => this.handleSort("alphabetic")}
                checked={this.state.filterType === "alphabetic"}
                style={{ marginLeft: 50 }}
              />{" "}
              Alphabetical{" "}
            </h5>
          </label>
          <br />
          <h5 style={{ marginLeft: "20px" }}>Quantity Available:</h5>
          <label>
            <h5>
              <input
                type="radio"
                name="sortBy"
                value="leastToMost"
                onChange={() => this.handleSort("leastToMost")}
                checked={this.state.filterType === "leastToMost"}
                style={{ marginLeft: 50 }}
              />{" "}
              Least to Most{" "}
            </h5>
          </label>
          <br />
          <label>
            <h5>
              <input
                type="radio"
                name="sortBy"
                value="mostToLeast"
                onChange={() => this.handleSort("mostToLeast")}
                checked={this.state.filterType === "mostToLeast"}
                style={{ marginLeft: 50 }}
              />{" "}
              Most to Least{" "}
            </h5>
          </label>
          <br />
        </div>
        <br />
        <br />
      </Col>
    );
  }

  clearanceProductsSection() {
    return (
      <Col md={10} sm={9} xs={12} style={{ float: "right" }}>
        {this.state.productList !== null
          ? this.clearanceProductsAvailableSection()
          : this.clearanceProductsLoadingSection()}
      </Col>
    );
  }

  clearanceProductsAvailableSection() {
    if (this.state.productList.length > 0) {
      return this.state.productList
        .filter((element) => element.qtyAvailable > 0)
        .map((item, i) => {
          return (
            <Col
              md={4}
              sm={4}
              xs={12}
              style={{ minWidth: "250px" }}
              key={item.id}
            >
              <div className="product-container loading-fade">
                <div
                  className="product-details-container"
                  onClick={(e) => this.handleProduct(item)}
                  role="presentation"
                >
                  <div className="product-image-box">
                    <img
                      src={item.featured_src}
                      alt="Product img"
                      className="selected-category-product-image"
                      id={item.id}
                    />
                  </div>
                  <div
                    className="product-content"
                    style={{ minWidth: "200px" }}
                  >
                    <div className="product-name">
                      <h4 style={{ fontWeight: "bold" }}>{item.title}</h4>
                      {item.minPrice === item.maxPrice ? (
                        <h5>${item.minPrice}</h5>
                      ) : (
                        <h5>
                          {" "}
                          From ${item.minPrice} - ${item.maxPrice}{" "}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        });
    } else {
      return (
        <h4 style={{ textAlign: "center", paddingRight: "10px" }}>
          {DisplayMessages.Clearance_NoProducts}
        </h4>
      );
    }
  }

  clearanceProductsLoadingSection() {
    return this.state.isSelected ? (
      <ContentLoading message={DisplayMessages.Products_Loading} size={50} />
    ) : null;
  }

  getCategoryList() {
    ClearanceHandler.getCategoryList()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState(
            {
              categoryList: response.data.list,
              last_updated: response.data.lastUpdated,
              invLink: response.data.inventoryLink,
              isLoading: false,
            },
            () => {
              if (this.state.categoryList.length > 0) {
                this.getProducts(this.state.categoryList[0]);
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
          });
        }
        this.props.addNotification(
          ErrorMessages.Clearance_CategoryList,
          "error"
        );
      });
  }

  getProducts(item) {
    this.trackCategoryCardClick(item);
    let category = item.category;
    let selectedCustomer =
      sessionStorage.getItem("customerName") === null
        ? ""
        : sessionStorage.getItem("customerName");
    this.setState(
      {
        isSelected: true,
        selectedCategory: category,
        filterType: priceFilterList[0],
        selectedCustomer: selectedCustomer,
        productList: null,
      },
      this.updateProductList
    );
  }

  updateProductList() {
    ClearanceHandler.getProductList(this.state.selectedCategory)
      .then((response) => {
        this.setState({
          productList: response.data.sort((a, b) => a.minPrice - b.minPrice),
          sortingList: response.data.sort((a, b) => a.minPrice - b.minPrice),
          isSelected: false,
        });
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.Clearance_ProductList,
          "error"
        );
      });
  }

  handleOnClose() {
    let selectedCustomer =
      sessionStorage.getItem("customerName") === null
        ? ""
        : sessionStorage.getItem("customerName");
    this.setState({
      isProductSelected: false,
      selectedCustomer: selectedCustomer,
    });
  }
  handleSort(filter) {
    let products = [];
    switch (filter) {
      case priceFilterList[0]:
        products = this.state.sortingList.sort(
          (a, b) => a.minPrice - b.minPrice
        );
        break;
      case priceFilterList[1]:
        products = this.state.sortingList.sort(
          (a, b) => b.maxPrice - a.maxPrice
        );
        break;
      case priceFilterList[2]:
        products = this.state.sortingList.filter(
          (a) => a.minPrice <= sortNumbers[0]
        );
        break;
      case priceFilterList[3]:
        products = this.state.sortingList.filter(
          (a) =>
            (a.minPrice > sortNumbers[0] && a.minPrice <= sortNumbers[1]) ||
            (a.maxPrice > sortNumbers[0] && a.maxPrice <= sortNumbers[1]) ||
            (a.minPrice <= sortNumbers[0] && a.maxPrice >= sortNumbers[1])
        );
        break;
      case priceFilterList[4]:
        products = this.state.sortingList.filter(
          (a) =>
            (a.minPrice > sortNumbers[1] && a.minPrice <= sortNumbers[2]) ||
            (a.maxPrice > sortNumbers[1] && a.maxPrice <= sortNumbers[2]) ||
            (a.minPrice <= sortNumbers[1] && a.maxPrice >= sortNumbers[2])
        );
        break;
      case priceFilterList[5]:
        products = this.state.sortingList.filter(
          (a) => a.maxPrice > sortNumbers[2]
        );
        break;
      case "alphabetic":
        products = this.state.sortingList.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        break;
      case "leastToMost":
        products = this.state.sortingList.sort(
          (a, b) => a.qtyAvailable - b.qtyAvailable
        );
        break;
      case "mostToLeast":
        products = this.state.sortingList.sort(
          (a, b) => b.qtyAvailable - a.qtyAvailable
        );
        break;
      default:
        products = this.state.sortingList;
    }

    this.trackClearanceFilterSelection(filter);

    this.setState({
      filterType: filter,
      productList: products,
    });
  }
  handleProduct(item) {
    ClearanceHandler.getConfigurations(
      item.id,
      encodeURIComponent(this.state.selectedCategory),
      encodeURIComponent(this.state.selectedCustomer)
    )
      .then((response) => {
        this.setState(
          {
            productConfig: response.data,
            selectedProduct: item,
          },
          () => this.filterConfig()
        );
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.Clearance_ConfigurationError,
          "error"
        );
      });
  }
  filterConfig() {
    let products = [];
    switch (this.state.filterType) {
      case priceFilterList[0]:
        products = this.state.productConfig.sort(
          (a, b) => a.SalePrice - b.SalePrice
        );
        break;
      case priceFilterList[1]:
        products = this.state.productConfig.sort(
          (a, b) => b.SalePrice - a.SalePrice
        );
        break;
      case priceFilterList[2]:
        products = this.state.productConfig
          .sort((a, b) => a.SalePrice - b.SalePrice)
          .filter((a) => a.SalePrice <= sortNumbers[0] && a.price1 >= 0);
        break;
      case priceFilterList[3]:
        products = this.state.productConfig
          .sort((a, b) => a.SalePrice - b.SalePrice)
          .filter(
            (a) => a.SalePrice > sortNumbers[0] && a.SalePrice <= sortNumbers[1]
          );
        break;
      case priceFilterList[4]:
        products = this.state.productConfig
          .sort((a, b) => a.SalePrice - b.SalePrice)
          .filter(
            (a) => a.SalePrice > sortNumbers[1] && a.SalePrice <= sortNumbers[2]
          );
        break;
      case priceFilterList[5]:
        products = this.state.productConfig
          .sort((a, b) => a.SalePrice - b.SalePrice)
          .filter((a) => a.SalePrice > sortNumbers[2]);
        break;
      case "alphabetic":
        products = this.state.productConfig.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        break;
      case "leastToMost":
        products = this.state.productConfig.sort(
          (a, b) => a.QtyAvailable - b.QtyAvailable
        );
        break;
      case "mostToLeast":
        products = this.state.productConfig.sort(
          (a, b) => b.QtyAvailable - a.QtyAvailable
        );
        break;
      default:
        products = this.state.productConfig;
    }

    this.setState(
      {
        productConfig: products,
      },
      () => this.showPopup()
    );
  }

  showPopup() {
    this.trackApplicationCardClick();
    this.setState({
      isProductSelected: true,
    });
  }

  categorySelectionLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.ClearanceCategories_Loading}
        size={50}
      />
    );
  }

  // Mixpanel Tracking //

  trackCategoryCardClick(item) {
    mixpanel.track("Clearance Category", {
      Action: "Element Click",
      Effect: "Clearance applications for selected category is displayed",
      Category: item.category,
      "Category ID": item.id,
    });
  }

  trackClearanceFilterSelection(filter) {
    mixpanel.track("Clearance Applications - Filter By", {
      Action: "Radio Button Click",
      Effect: "Selected filter applied to applications",
      FilterBy: filter,
    });
  }

  trackApplicationCardClick() {
    mixpanel.track("Clearance Application", {
      Action: "Element Click",
      Effect: "Clearance products popup for selected application is displayed",
      Application: this.state.selectedProduct.title,
      "Application ID": this.state.selectedProduct.id,
    });
  }
}

export default Clearance;
