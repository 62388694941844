import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer";

import fixedLink from "../Assets/fixedAssets.json";
import {
  fetchResourceData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import "./privacyPolicy.css";

const privacyPolicyDocument = fetchResourceData(
  fixedLink.privacyPolicyDocument
);
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const CONTAINER_PADDING = 50;

/**
 * Component represents the privacy policy popup
 */
class PolicyPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      totalPages: null,
      allPages: [],
      pageNumber: 1,
      outerWidth: 0,
    };

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleOnClose}
        dialogClassName="policy-popup-modal"
        
      >
        <Modal.Header>
          <h3
            style={{
              fontWeight: "bolder",
              textShadow: "0px 1px, 1px 0px, 1px 1px",
            }}
            className="popup-blue-text"
          >
            {Miscellaneous.PrivacyPolicyTitle}
          </h3>
        </Modal.Header>
        <Modal.Body className="policy-popup-body">
          <div id="pdf-display">
            <Document
              file={privacyPolicyDocument}
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              <div className="privacy-scrollable-div">
                {this.state.allPages
                  ? this.state.allPages.map((pn) => (
                      <Page
                        key={`page-${pn}`}
                        width={this.state.outerWidth - CONTAINER_PADDING * 2}
                        pageNumber={pn}
                      />
                    ))
                  : undefined}
              </div>
            </Document>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <Button
              bsStyle="primary"
              className="button-del-blue"
              onClick={this.props.handleOnClose}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  onDocumentLoadSuccess(pages) {
    const allPages = [];
    for (let p = 1; p < pages._pdfInfo.numPages + 1; p++) {
      allPages.push(p);
    }

    let width = document.getElementById("pdf-display").offsetWidth;
    this.setState({
      numPages: pages,
      totalPages: pages._pdfInfo.numPages,
      allPages: allPages,
      outerWidth: width,
    });
  }
}

export default PolicyPopup;
