import React, { Component } from "react";
import {
  Modal,
  FormControl,
  FormGroup,
  ControlLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";

import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import OrderSummaryHandler from "./OrderSummaryHandler";
import mixpanel from "mixpanel-browser";

const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents formal quotes popup
 */
class FormalQuotesPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormalQuoteClicked: this.props.show,
      notesText: this.props.notesText,
      name: this.props.name,
      currentRole: this.props.currentRole,
      userName: this.props.userName,
      customerName: this.props.customerName,
      branchData: this.props.branchData,
      salesrepEmails: "",
      ccmail: this.props.userName,
      toMail: "",
      subject: "",
      attachmentName: "",
      files: [],
      fileNameList: [],
      confirmEmail: false,
      buttonClicked: false,
      address: this.props.address,
      cart: this.props.cart,
      orderType: this.props.orderType,
      stockingType: this.props.stockingType,
      projectName: this.props.projectName,
      contractor: this.props.contractor,
      specAvailable: this.props.specAvailable,
      title: this.props.title,
      quotesMail: JSON.parse(sessionStorage.getItem("currentUser"))[
        "quotesMail"
      ],
    };

    this.BranchBased = this.BranchBased.bind(this);
    this.CustomerBased = this.CustomerBased.bind(this);
    this.setSubject = this.setSubject.bind(this);
    this.addAttachments = this.addAttachments.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleCCMailChange = this.handleCCMailChange.bind(this);
    this.modifyNotes = this.modifyNotes.bind(this);
    this.validateEmailIds = this.validateEmailIds.bind(this);
    this.getOrderSummaryFile = this.getOrderSummaryFile.bind(this);
    this.setToMail = this.setToMail.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnConfirmClose = this.handleOnConfirmClose.bind(this);
    this.setAttachment = this.setAttachment.bind(this);

    this.sendQuotesMail = this.sendQuotesMail.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState(
      (prevState) => ({
        branchData: newProps.branchData,
        isFormalQuoteClicked: newProps.show,
        notesText:
          newProps.notesText === this.props.notesText
            ? prevState.notesText
            : newProps.notesText,
        name: newProps.name,
        currentRole: newProps.currentRole,
        userName: newProps.userName,
        customerName: newProps.customerName,
        salesrepEmails:
          newProps.branchData !== null
            ? newProps.branchData.salesrep.toString()
            : "",
        address: newProps.address,
        cart: this.props.cart,
        orderType: newProps.orderType,
        stockingType: newProps.stockingType,
        projectName: newProps.projectName,
        contractor: newProps.contractor,
        specAvailable: newProps.specAvailable,
        title: newProps.title,
      }),
      () => {
        this.setSubject();
        this.setAttachment();
        this.setToMail();
      }
    );
  }
  componentDidMount() {
    this.setAttachment();
  }

  componentWillUnmount() {
    this.handleOnClose();
  }
  setSubject() {
    if (branchBasedRoles.includes(this.state.currentRole)) {
      if (this.state.branchData) {
        this.setState((prevState) => ({
          subject:
            "Quote Request - " +
            prevState.branchData.tldName +
            " : " +
            prevState.name,
        }));
      }
    } else {
      this.setState((prevState) => ({
        subject:
          "Quote Request - " + prevState.customerName + " - " + prevState.name,
      }));
    }
  }

  setAttachment() {
    const date = new Date();
    let attachmentName =
      "360Cart_" +
      date.getDate() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      "_";
    if (branchBasedRoles.includes(this.state.currentRole)) {
      if (this.state.branchData) {
        this.setState((prevState) => ({
          attachmentName:
            attachmentName +
            prevState.branchData.tldName +
            "_" +
            prevState.name,
        }));
      }
    } else {
      this.setState((prevState) => ({
        attachmentName:
          attachmentName + prevState.customerName + "_" + prevState.name,
      }));
    }
  }

  setToMail() {
    if (
      branchBasedRoles.includes(this.state.currentRole) &&
      this.state.salesrepEmails !== ""
    ) {
      this.setState((prevState) => ({
        toMail: prevState.quotesMail + "," + prevState.salesrepEmails,
      }));
    } else {
      this.setState((prevState) => ({
        toMail: prevState.quotesMail,
      }));
    }
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.confirmEmail}
          onHide={this.handleOnConfirmClose}
        >
          <Modal.Header>
            <Modal.Title
              style={{
                color: "#074391",
                fontWeight: "bolder",
                fontSize: "24px",
                textAlign: "center",
                textShadow: "0px 1px, 1px 0px, 1px 1px",
              }}
            >
              Quote Sent
              <br />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              textAlign: "center",
            }}
          >
            You will receive an email to confirm.
          </Modal.Body>
        </Modal>
        <Modal
          show={this.props.show}
          onHide={this.handleOnClose}
          className="popup-modal"
        >
          <Modal.Header>
            <Modal.Title
              style={{
                color: "#074391",
                fontWeight: "bolder",
                fontSize: "24px",
                textShadow: "0px 1px, 1px 0px, 1px 1px",
                paddingLeft: "20px",
              }}
            >
              Request Quote
              <br />
            </Modal.Title>
            <span
              style={{
                fontSize: "12px",
                paddingLeft: "20px",
                display: "block",
              }}
            >
              {DisplayMessages.FormalQuotesPopup_RequestQuoteHeaderLine1}
            </span>
            <br />
            <span style={{ fontSize: "12px", paddingLeft: "20px" }}>
              {DisplayMessages.FormalQuotesPopup_RequestQuoteHeaderLine2}
            </span>
          </Modal.Header>
          <div>
            {branchBasedRoles.includes(this.state.currentRole)
              ? this.BranchBased()
              : this.CustomerBased()}
          </div>
          <Modal.Footer>
            <Button
              disabled={this.state.buttonClicked}
              bsStyle="primary"
              className="button-blue"
              onClick={this.getOrderSummaryFile}
            >
              Send Quote Request
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleOnClose() {
    this.setState(
      {
        ccmail: this.props.userName,
        subject: "",
        files: [],
        fileNameList: [],
      },
      this.props.handleOnClose
    );
  }

  handleSubmit() {
    this.setState(
      {
        ccmail: this.props.userName,
        subject: "",
        files: [],
        fileNameList: [],
        confirmEmail: true,
      },
      this.props.handleOnClose
    );
  }

  handleOnConfirmClose() {
    this.setState({
      confirmEmail: false,
      buttonClicked: false,
    });
  }

  BranchBased() {
    return (
      <Modal.Body>
        <br />
        <Row className="popup-row">
          <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
            <ControlLabel className="popup-labels">To</ControlLabel>
          </Col>
          <Col md={10} sm={10} xs={10}>
            <FormGroup>
              <FormControl
                type="text"
                disabled={true}
                value={this.state.toMail}
                spellCheck="false"
              />
            </FormGroup>
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <Row className="popup-row">
          <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
            <ControlLabel className="popup-labels">Subject</ControlLabel>
          </Col>
          <Col md={10} sm={10} xs={10}>
            <FormGroup>
              <FormControl
                type="text"
                disabled={true}
                value={this.state.subject}
                spellCheck="false"
              />
            </FormGroup>
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <Row>
          <Row>
            <Col md={1} sm={2} xs={2} style={{ padding: "0px" }} />
            <Col md={10} sm={10} xs={10}>
              <span style={{ fontWeight: "bold" }}>
                Use commas "," to separate emails. A Sales Rep will send a
                formal quote to the emails below.
              </span>
            </Col>
          </Row>
          <Row className="popup-row">
            <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
              <ControlLabel className="popup-labels">Reply to</ControlLabel>
            </Col>
            <Col md={10} sm={10} xs={10}>
              <FormGroup>
                <FormControl
                  type="text"
                  value={this.state.ccmail}
                  spellCheck="false"
                  onChange={this.handleCCMailChange}
                  onBlur={this.validateEmailIds}
                />
              </FormGroup>
            </Col>
            <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          </Row>
        </Row>
        <Row className="popup-row">
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          <Col md={3} sm={4} xs={4} style={{ paddingRight: "0px" }}>
            <input
              id="attachments"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={this.addAttachments}
            />
            <Button
              bsStyle="primary"
              className="button-blue"
              style={{ width: "100%" }}
              onClick={this.openFileBrowser}
            >
              Add Attachments
            </Button>
          </Col>
          <Col md={7} sm={6} xs={6} style={{ padding: "0px" }}>
            <Col md={3} sm={3} xs={3}>
              <div className="attachment-container">
                <div className="attachment-name">
                  {this.state.attachmentName}
                </div>
                <img src={fetchResourceData(fixedLink.excelIcon)} alt="excel" />
              </div>
            </Col>
            {this.state.fileNameList.length > 0 ? (
              this.getAttachments()
            ) : (
              <span />
            )}
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <br />
        <Row>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          <Col md={10} sm={12} xs={12}>
            <FormControl
              componentClass="textarea"
              style={{ resize: "none", height: "100%", minHeight: "180px" }}
              rows="3"
              value={this.state.notesText}
              spellCheck="false"
              placeholder="Add notes, special requests, lighting layout needs..."
              onChange={this.modifyNotes}
              maxLength="500"
            />
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
      </Modal.Body>
    );
  }

  CustomerBased() {
    return (
      <Modal.Body>
        <br />
        <Row className="popup-row">
          <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
            <ControlLabel className="popup-labels">To</ControlLabel>
          </Col>
          <Col md={10} sm={10} xs={10}>
            <FormGroup>
              <FormControl
                type="text"
                disabled={true}
                value={this.state.toMail}
                spellCheck="false"
              />
            </FormGroup>
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <Row className="popup-row">
          <Col md={1} sm={2} xs={2} style={{ padding: "0px" }} />
          <Col md={10} sm={10} xs={10}>
            <span style={{ fontWeight: "bold" }}>
              Use commas "," to separate emails in CC field
            </span>
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <Row className="popup-row">
          <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
            <ControlLabel className="popup-labels">CC</ControlLabel>
          </Col>
          <Col md={10} sm={10} xs={10}>
            <FormGroup>
              <FormControl
                type="text"
                value={this.state.ccmail}
                spellCheck="false"
                onChange={this.handleCCMailChange}
                onBlur={this.validateEmailIds}
              />
            </FormGroup>
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <Row className="popup-row">
          <Col md={1} sm={2} xs={2} style={{ padding: "0px" }}>
            <ControlLabel className="popup-labels">Subject</ControlLabel>
          </Col>
          <Col md={10} sm={10} xs={10}>
            <FormGroup>
              <FormControl
                type="text"
                disabled={true}
                value={this.state.subject}
                spellCheck="false"
              />
            </FormGroup>
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <Row className="popup-row">
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          <Col md={3} sm={4} xs={4} style={{ paddingRight: "0px" }}>
            <input
              id="attachments"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={this.addAttachments}
            />
            <Button
              bsStyle="primary"
              className="button-blue"
              style={{ width: "100%" }}
              onClick={this.openFileBrowser}
            >
              Add Attachments
            </Button>
          </Col>
          <Col md={7} sm={6} xs={6} style={{ padding: "0px" }}>
            <Col md={3} sm={3} xs={3}>
              <div className="attachment-container">
                <div className="attachment-name">
                  {this.state.attachmentName}
                </div>
                <img src={fetchResourceData(fixedLink.excelIcon)} alt="excel" />
              </div>
            </Col>
            {this.state.fileNameList.length > 0 ? (
              this.getAttachments()
            ) : (
              <span />
            )}
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
        <br />
        <Row>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
          <Col md={10} sm={12} xs={12}>
            <FormControl
              componentClass="textarea"
              style={{ resize: "none", height: "100%", minHeight: "180px" }}
              rows="3"
              value={this.state.notesText}
              spellCheck="false"
              placeholder="Add notes, special requests, lighting layout needs..."
              onChange={this.modifyNotes}
              maxLength="500"
            />
          </Col>
          <Col md={1} sm={0} xs={0} style={{ padding: "0px" }} />
        </Row>
      </Modal.Body>
    );
  }
  getOrderSummaryFile() {
    this.setState({
      buttonClicked: true,
    });

    let cart = {
      ...this.state.cart,
      notesText: this.state.notesText,
      attachmentName: this.state.attachmentName,
    };

    cart.orderSummaryData.forEach((element) => {
      element.Price = element.Price.toString();
    });
    OrderSummaryHandler.cartDetails(cart)
      .then((response) => {
        if (response.success) {
          this.sendQuotesMail();
        } else {
          this.trackSendQuoteRequestFailedButtonClick("Something went wrong");
          this.setState({
            buttonClicked: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          buttonClicked: false,
        });
        this.trackSendQuoteRequestFailedButtonClick(
          ErrorMessages.FormalQuotesPopup_QuoteRequest
        );

        this.props.addNotification(
          ErrorMessages.FormalQuotesPopup_QuoteRequest,
          "error"
        );
      });
  }

  sendQuotesMail() {
    if (this.state.toMail.length > 2) {
      let mailContent = {
        toMail: this.state.toMail,
        ccmail: this.state.ccmail.replaceAll(",", ";"),
        subject: this.state.subject,
        body: this.state.notesText,
        salesrepEmails: this.state.salesrepEmails,
        attachments: this.state.files,
        attachmentName: this.state.attachmentName,
      };
      let form = new FormData();

      form.append("To", mailContent.toMail);
      form.append("CC", mailContent.ccmail);
      form.append("Subject", mailContent.subject);
      form.append(
        "CustomerID",
        this.state.branchData ? this.state.branchData.customerId : ""
      );
      form.append("CustomerName", this.state.customerName);
      form.append("UserName", this.state.name);
      form.append("Email", sessionStorage.getItem("username"));
      form.append("Title", this.state.title);
      form.append("Address", this.state.address);
      form.append("OrderType", this.state.orderType);
      form.append("StockType", this.state.stockingType);
      form.append("ProjectName", this.state.projectName);
      form.append(
        "Contractor",
        this.state.contractor === "" ? "" : this.state.contractor.label
      );
      form.append("SpecAvailable", this.state.specAvailable);
      form.append(
        "UserNotes",
        this.state.notesText ? this.state.notesText : "NA"
      );
      form.append(
        "TLDName",
        this.state.branchData ? this.state.branchData.tldName : ""
      );
      form.append(
        "BranchName",
        this.state.branchData ? this.state.branchData.branchName : ""
      );
      form.append("SalesrepEmails", mailContent.salesrepEmails);
      form.append("UserRole", this.state.currentRole);
      mailContent.attachments.forEach((item) => {
        form.append("Attachments", item[0]);
      });
      form.append("AttachmentName", mailContent.attachmentName);

      OrderSummaryHandler.requestQuotesMail(form)
        .then((response) => {
          if (response.success) {
            this.trackSendQuoteRequestSuccessButtonClick(mailContent);
            this.handleSubmit();
          } else {
            this.trackSendQuoteRequestFailedButtonClick(response.message);
            this.setState(
              {
                buttonClicked: false,
              },
              this.props.addNotification(response.message, "error")
            );
          }
        })
        .catch((error) => {
          this.trackSendQuoteRequestFailedButtonClick(
            ErrorMessages.FormalQuotesPopup_SendMail
          );

          this.setState(
            {
              buttonClicked: false,
            },
            this.props.addNotification(
              ErrorMessages.FormalQuotesPopup_SendMail,
              "error"
            )
          );
        });
    } else {
      this.trackSendQuoteRequestFailedButtonClick(
        ErrorMessages.FormalQuotesPopup_ReceiverMail
      );
      this.setState(
        {
          buttonClicked: false,
        },
        this.props.addNotification(
          ErrorMessages.FormalQuotesPopup_ReceiverMail,
          "error"
        )
      );
    }
  }

  modifyNotes(e) {
    this.setState({
      notesText: e.target.value,
    });
    this.props.AdditionalNotesChange(e.target.value);
  }

  validateEmailIds(e) {
    let emailList = e.target.value.split(",");
    let ccEmailList = [];
    let pattern = Miscellaneous.RegexEmailId;
    const re = new RegExp(pattern, "i");
    emailList.forEach((email) => {
      email = email.trim();
      if (email.length > 0 && re.test(email)) {
        ccEmailList.push(email);
      }
      this.setState({
        ccmail: ccEmailList.toString(),
      });
    });
  }

  handleCCMailChange(e) {
    let ccmail = e.target.value;
    ccmail = ccmail.replaceAll(",,", ",");
    ccmail = ccmail.replaceAll(" ", "");
    this.setState({
      ccmail: ccmail,
    });
  }

  getAttachments() {
    return this.state.fileNameList.map((item, i) => {
      return (
        <Col md={3} sm={3} xs={3} key={item}>
          <div className="attachment-container">
            <div>
              <span
                className="glyphicon glyphicon-remove"
                style={{ float: "right" }}
                onClick={() => this.removeFile(i)}
              />
            </div>
            <div className="attachment-name">{item}</div>
            <img src={this.getDocumentTypeIcon(item)} alt="file" />
          </div>
        </Col>
      );
    });
  }

  removeFile(index) {
    let fileNameList = this.state.fileNameList;
    let files = this.state.files;
    fileNameList.splice(index, 1);
    files.splice(index, 1);
    this.setState({
      fileNameList: fileNameList,
      files: files,
    });
  }
  openFileBrowser() {
    let inputElement = document.getElementById("attachments");
    inputElement.click();

    this.trackAddAttachmentsButtonClick();
  }
  getDocumentTypeIcon(filePath) {
    let fileExtension = filePath.slice(
      filePath.lastIndexOf(".") + 1,
      filePath.length
    );
    switch (fileExtension) {
      case "pdf":
        return fetchResourceData(fixedLink.pdfIcon);
      case "doc":
      case "docx":
        return fetchResourceData(fixedLink.wordIcon);
      case "xls":
      case "xlsx":
        return fetchResourceData(fixedLink.excelIcon);
      case "ppt":
      case "pptx":
        return fetchResourceData(fixedLink.powerpointIcon);
      case "png":
      case "jpg":
        return fetchResourceData(fixedLink.imageIcon);
      default:
        return fetchResourceData(fixedLink.textIcon);
    }
  }

  addAttachments(e) {
    let files = this.state.files;
    let fileNameList = this.state.fileNameList;
    let path = e.target.value;
    let filename = path.substring(path.lastIndexOf("\\") + 1);
    if (filename !== "" && !fileNameList.find((item) => item === filename)) {
      files.push(e.target.files);
      fileNameList.push(filename);
    }
    this.setState({
      files: files,
      fileNameList: fileNameList,
    });
  }

  // Mixpanel Tracking //

  trackAddAttachmentsButtonClick() {
    mixpanel.track("Add Attachments", {
      Action: "Button Click",
      Effect: "Selected attachments will be added to mail",
    });
  }

  trackSendQuoteRequestSuccessButtonClick(mailContent) {
    mixpanel.track("Send Request Quote", {
      Action: "Button Click",
      Effect: "Request Quote will be sent",
      To: mailContent.toMail,
      CC: mailContent.ccmail,
      Subject: mailContent.subject,
    });
  }

  trackSendQuoteRequestFailedButtonClick(message) {
    mixpanel.track("Send Request Quote", {
      Action: "Button Click",
      Effect: "Request Quote details will not be sent",
      Message: message,
    });
  }
}

export default FormalQuotesPopup;
