import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import { Col, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-table/react-table.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import DistributorsHandler from "./DistributorsHandler";
import "./branchNavigation.css";
import BranchDetail from "./BranchDetail";
import "./branchOverview.css";
import NoDeletePopup from "./NoDeletePopup";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading";

const backToDistributors = "<- Back to Distributors";

/**
 * Component represents the branch navigation page for admin based roles
 */
class BranchNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchData: [],
      rowdata: [],
      isDataReceived: false,
      searchString: "",
      searchValid: false,
      selectedTLD: this.props.selectedTLD,
      tldId: this.props.selectedTLD.tldId,
      distributor: this.props.selectedTLD.distributor,
      selectedBranch: this.props.selectedBranch
        ? this.props.selectedBranch
        : null,
      role: "",
      username: "",
      branchesUnderSalesrep: [],
      showAllBranches: false,
      deleteData: [],
      showNoDeletePopup: false,
      showDeletePopup: false,
    };
    this._notificationSystem = null;
    this.handleOnClose = this.handleOnClose.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    this.searchBranches = this.searchBranches.bind(this);
    this.handleExportCSV = this.handleExportCSV.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goToBranchNavigation = this.goToBranchNavigation.bind(this);
    this.searchBarVisible = this.searchBarVisible.bind(this);
    this.toggleBranches = this.toggleBranches.bind(this);
    this.branchDataList = this.branchDataList.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNoDeleteClose = this.handleNoDeleteClose.bind(this);
    this.deleteBranch = this.deleteBranch.bind(this);
    this.branchNameLayout = this.branchNameLayout.bind(this);
    this.branchNameHandler = this.branchNameHandler.bind(this);
    this.branchActionsLayout = this.branchActionsLayout.bind(this);
  }

  componentDidMount() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    let username = sessionStorage.getItem("username");
    this.setState(
      {
        role: userObj["role"],
        showAllBranches: userObj["role"] === "360 Admin",
        username: username,
      },
      this.refreshPage
    );
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  paginationOptionsBuilder() {
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.trackPagination(page);
        onPageChange(page);
      };

      return (
        <li className="page-item" key={page}>
          {/* eslint-disable-next-line */}
          <a href="#" key={page} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      pageButtonRenderer,
      sizePerPageList: [
        {
          text: "25",
          value: 25,
        },
      ],
    };

    return options;
  }

  refreshPage() {
    if (this.state.role === "Salesrep") {
      DistributorsHandler.getBranchesUnderSalesrep(
        encodeURIComponent(this.state.username)
      )
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({
              branchesUnderSalesrep: response.data,
            });
          } else {
            this.setState({
              branchesUnderSalesrep: [],
            });
          }
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.BranchNavigation_BranchesUnderSalesrep,
            "error"
          );
        });
    }
    DistributorsHandler.getBranchData(
      this.state.tldId,
      encodeURIComponent(this.state.username)
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            branchData: response.data,
            searchString: "",
            searchValid: true,
            isDataReceived: true,
          });
        } else {
          this.setState({
            searchValid: false,
            isDataReceived: true,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchNavigation_BranchData,
          "error"
        );
      });
  }

  searchBranches() {
    DistributorsHandler.searchBranches(
      encodeURIComponent(this.state.searchString),
      this.state.tldId,
      this.state.username
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            branchData: response.data,
            searchValid: true,
            isDataReceived: true,
          });
        } else {
          this.setState({
            searchValid: false,
            isDataReceived: true,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchNavigation_SearchBranches,
          "error"
        );
      });
  }

  toggleBranches() {
    this.setState(
      (prevState) => ({
        showAllBranches: !prevState.showAllBranches,
      }),
      this.trackViewAllBranchesCheckbox
    );
  }

  branchDataList() {
    if (this.state.showAllBranches) {
      return this.state.branchData;
    } else {
      let homeBranchData = this.state.branchData.filter(
        (item) => item.home || item.star || item.admin
      );
      return homeBranchData;
    }
  }

  branchTableLoadingSection() {
    return (
      <ContentLoading message={DisplayMessages.BranchList_Loading} size={50} />
    );
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
        },
      },
    };
    return (
      <div className="distributor-container">
        {this.state.selectedBranch === null
          ? this.branchTableSection()
          : this.branchDetailSection()}
        <DeleteConfirmationPopup
          show={this.state.showDeletePopup}
          item={this.state.deleteData.branchName}
          itemType={"Branch"}
          handleConfirmation={this.handleDelete}
          handleCancel={this.handleDeleteClose}
        />
        <NoDeletePopup
          show={this.state.showNoDeletePopup}
          itemCount={this.state.deleteData.totalUsers}
          itemName={this.state.deleteData.branchName}
          itemType={"Branch"}
          handleOnClose={this.handleNoDeleteClose}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  branchTableSection() {
    return (
      <div>
        {this.branchTable_TitleSection()}
        <br />
        {this.state.isDataReceived
          ? this.branchTableDisplaySection()
          : this.branchTableLoadingSection()}
      </div>
    );
  }

  branchTable_TitleSection() {
    return (
      <div>
        {this.branchTable_GoBackSection()}
        {this.branchTable_DistributorNameSection()}
        <br />
        {this.branchTable_SearchSection()}
      </div>
    );
  }

  branchTable_GoBackSection() {
    return (
      <Row>
        <Col
          md={12}
          sm={12}
          xs={12}
          style={{
            padding: "0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div onClick={this.goBack} className="backToButton">
            <span style={{ fontWeight: "bold" }}>{backToDistributors}</span>
          </div>
        </Col>
      </Row>
    );
  }

  branchTable_DistributorNameSection() {
    return (
      <Row>
        <Col md={11} style={{ paddingLeft: "0px" }}>
          <h2 className="big-blue-text">{this.state.distributor}</h2>
        </Col>
      </Row>
    );
  }

  branchTable_SearchSection() {
    return (
      <Row
        className={this.searchBarVisible()}
        style={{
          alignItems: "flex-end",
        }}
      >
        <Col md={7} sm={7} xs={7} className="add-export">
          {this.state.role !== "360 Admin" ? (
            <div>
              <label
                onChange={this.toggleBranches}
                style={{ fontSize: "14px" }}
              >
                <input
                  type="checkbox"
                  value={this.state.showAllBranches}
                  defaultChecked={this.state.showAllBranches}
                />{" "}
                View All Branches
              </label>
            </div>
          ) : null}
        </Col>
        <Col
          md={5}
          sm={12}
          xs={12}
          className="searchbox"
          style={{ float: "right", padding: "0px", marginTop: "10px" }}
        >
          <Col className="no-padding-branch">
            <FormControl
              type="text"
              autoComplete="off"
              placeholder="Search for a branch"
              spellCheck="false"
              value={this.state.searchString}
              onChange={this.handleSearchStringChange}
            />
          </Col>
        </Col>
      </Row>
    );
  }

  branchDetailSection() {
    return (
      <BranchDetail
        goToBranchNavigation={this.goToBranchNavigation}
        addNotification={this.addNotification}
        selectedBranch={this.state.selectedBranch}
        tldId={this.state.tldId}
        branchesUnderSalesrep={this.state.branchesUnderSalesrep}
      />
    );
  }

  goToBranchNavigation() {
    this.setState(
      {
        selectedBranch: null,
      },
      this.resetBranchTable
    );
  }

  searchBarVisible() {
    if (this.state.searchValid || this.state.searchString.length > 0) {
      return "flex-component";
    } else {
      return "hide-component";
    }
  }
  branchTableDisplaySection() {
    if (!this.state.searchValid || this.state.branchData.length === 0) {
      return (
        <Row>
          <center>
            <h4>No Branches Found!</h4>
          </center>
        </Row>
      );
    } else {
      return (
        <Row className="loading-fade">
          <BootstrapTable
            keyField="branchId"
            data={this.branchDataList()}
            columns={this.getColumns()}
            defaultSorted={this.getDefaultSort()}
            pagination={paginationFactory(this.paginationOptionsBuilder())}
          />
        </Row>
      );
    }
  }

  handleDeleteClose() {
    this.setState({
      showDeletePopup: false,
      deleteData: [],
    });
  }

  handleNoDeleteClose() {
    this.setState({
      showNoDeletePopup: false,
      deleteData: [],
    });
  }

  handleDelete() {
    let branchName = this.state.deleteData.branchName;
    DistributorsHandler.deleteBranch(this.state.deleteData.branchId)
      .then((response) => {
        if (response.success) {
          this.trackDeleteBranchConfirmationButtonClick(branchName);
          this.addNotification(
            DisplayMessages.BranchNavigation_DeleteBranch,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
        this.resetBranchTable();
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchNavigation_DeleteBranch,
          "error"
        );
      });
    this.setState({
      showDeletePopup: false,
      deleteData: [],
    });
  }

  handleSearchStringChange(e) {
    let searchString = e.target.value.replace(/\s{2,}/g, " ");
    this.setState(
      {
        searchString: searchString,
        isDataReceived: false,
      },
      () => {
        if (searchString !== "") {
          this.trackSearchBranchesFilter(searchString);
          this.searchBranches();
        } else {
          this.refreshPage();
        }
      }
    );
  }

  getDefaultSort() {
    const defaultSort = [
      {
        dataField: "activeUsers",
        order: "desc",
      },
    ];

    return defaultSort;
  }

  getColumns() {
    const columns = [
      {
        dataField: "branchName",
        text: "Branch Name",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "350px",
        },
        formatter: (cell, row) => {
          return this.branchNameLayout(cell, row);
        },
      },
      {
        dataField: "address",
        text: "Address",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "400px",
        },
      },
      {
        dataField: "branchAdmin",
        text: "Branch Admin",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "200px",
        },
      },
      {
        dataField: "csr",
        text: "CSR",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "200px",
        },
      },

      {
        dataField: "totalUsers",
        text: "Number of Users",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 15px",
          width: "100px",
        },
      },
      {
        dataField: "inactiveUsers",
        text: "Inactive Users",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 15px",
          width: "100px",
        },
      },
    ];
    if (this.state.role === "360 Admin") {
      columns.push({
        dataField: "branchId",
        text: "Branch Actions",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "80px",
        },
        formatter: (cell, row, rowIndex) => {
          return this.branchActionsLayout(row);
        },
      });
    }

    return columns;
  }

  branchNameLayout(cell, row) {
    return (
      <span style={{ display: "flex" }}>
        <button
          className="blue-text"
          onClick={() => {
            this.branchNameHandler(cell, row);
          }}
        >
          <span>{cell}</span>
        </button>
        {row.home ? (
          <div>
            {row.isCSR ? (
              <span
                className="glyphicon glyphicon-home home-icons"
                title="You are the CSR of this branch"
              />
            ) : (
              <span
                className="glyphicon glyphicon-home home-icons"
                title="This is your home branch"
              />
            )}
          </div>
        ) : null}
        {row.admin ? (
          <div>
            <span
              className="glyphicon glyphicon-user admin-icons"
              title="You are the branch admin"
            />
          </div>
        ) : null}
        {row.star ? (
          <div>
            <span
              className="glyphicon glyphicon-star-empty star-icons"
              title="You are assigned to this branch"
            />
          </div>
        ) : null}
      </span>
    );
  }

  branchNameHandler(cell, row) {
    this.trackBranchButtonClick(cell);
    this.setState({
      selectedBranch: row,
    });
  }

  branchActionsLayout(row) {
    return (
      <span>
        <button
          className="deleteButton"
          onClick={() => {
            this.deleteBranch(row);
          }}
        >
          <span className="glyphicon glyphicon-trash" />
        </button>
      </span>
    );
  }

  deleteBranch(row) {
    this.trackDeleteBranchButtonClick(row.branchName);
    this.setState({ deleteData: row });
    if (row.totalUsers > 0) {
      this.setState({ showNoDeletePopup: true });
    } else {
      this.setState({ showDeletePopup: true });
    }
  }

  resetBranchTable() {
    if (this.state.searchString.length > 0) {
      this.searchBranches();
    } else {
      this.refreshPage();
    }
  }

  handleExportSubmit(fileName) {
    let data = {
      distributor: this.state.distributor,
      branchInfo: this.state.branchData,
      searchString: this.state.searchString,
    };

    DistributorsHandler.exportBranch(data)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        fileName = fileName + ".xlsx";

        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (document.getElementById("export-dist-button")) {
          document.getElementById("export-dist-button").blur();
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.BranchNavigation_ExpBranch, "error");
      });
  }

  handleExportCSV() {
    if (!this.state.searchValid) {
      this.addNotification("No Branches found", "info");
    } else {
      this.setState({ exportBranchesPopup: true });
    }
  }

  goBack() {
    this.trackBackToDistributorsButtonClick();
    this.props.goBack();
  }

  handleOnClose() {
    this.resetBranchTable();
  }

  // Mixpanel Tracking //

  trackSearchBranchesFilter(searchString) {
    mixpanel.track("Filter Branches", {
      Action: "Search",
      Effect: "Branch table will be filtered based on search string",
      "Search String": searchString,
    });
  }

  trackBackToDistributorsButtonClick() {
    mixpanel.track("Back To Distributors", {
      Action: "Button Click",
      Effect: "Navigate back to distributors page",
    });

    mixpanel.track_pageview({
      Page: "Distributors Page",
    });
  }

  trackBranchButtonClick(branch) {
    mixpanel.track("Branch Select", {
      Action: "Link Click",
      Effect: "Navigate to branch overview page for selected branch",
      "Branch Name": branch,
    });

    mixpanel.track_pageview({
      Page: "Branch Overview Page",
    });
  }

  trackDeleteBranchButtonClick(branch) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected branch will be deleted",
      "Item Type": "Branch",
      Branch: branch,
    });
  }

  trackDeleteBranchConfirmationButtonClick(branch) {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected branch deleted",
      "Item Type": "Branch",
      Branch: branch,
    });
  }

  trackViewAllBranchesCheckbox() {
    mixpanel.track("View All Branches", {
      Action: "Checkbox Click",
      Effect: "Show/Hide all branches for current user",
      Visibility: this.state.showAllBranches,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Branch Table Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}
export default BranchNavigation;
