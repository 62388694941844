import React, { Component } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import "./TutorialFAQ.css";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import DistributorsHandler from "../Distributors/DistributorsHandler";

const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the tutorial overlay
 */
class TutorialFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distUser: false,
      role: "",
      branchAdminName: "",
      branchAdminEmail: "",
      csrName: "",
      csrTitle: "",
      csrPhone: "",
      csrEmail: "",
      salesrepList: [],
    };

    this.getAgencyInfo = this.getAgencyInfo.bind(this);
    this.handleFAQButton = this.handleFAQButton.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.role !== this.state.role) {
      if (branchBasedRoles.includes(newProps.role)) {
        this.setState(
          {
            role: newProps.role,
            distUser: true,
          },
          this.getAgencyInfo
        );
      } else {
        this.setState({
          role: newProps.role,
          distUser: false,
        });
      }
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleOnClose}
        dialogClassName="tutorial-faq-modal"
      >
        <Modal.Header closeButton style={{ border: "0px" }}>
          <Col md={12} sm={12} xs={12}>
            <h3
              style={{
                fontWeight: "bolder",
                textShadow: "0px 1px, 1px 0px, 1px 1px",
                marginTop: "10px",
              }}
              className="popup-blue-text"
            >
              Portal Resources
            </h3>
            <span>{DisplayMessages.TutorialOverlay_Subtext}</span>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ display: "flex" }}>
            <Col
              md={6}
              style={{
                padding: "0px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={this.trackTutorialClick}
                className="button-blue tutorial-faq-button"
                style={{ maxWidth: "90%", cursor: "not-allowed" }}
              >
                Tutorial - Coming Soon
              </Button>
            </Col>
            <Col
              md={6}
              style={{
                padding: "0px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className="button-blue tutorial-faq-button"
                style={{ maxWidth: "90%" }}
                onClick={this.handleFAQButton}
              >
                FAQ, Bugs & Feedback
              </Button>
            </Col>
          </Row>
          {this.state.distUser ? this.agencyInformation() : null}
        </Modal.Body>
      </Modal>
    );
  }

  handleFAQButton() {
    this.trackFeedbackClick();
    window.open(Miscellaneous.FAQLink);
  }

  getAgencyInfo() {
    DistributorsHandler.agencyRelatedInfo()
      .then((response) => {
        if (response.success) {
          let agencyInfo = response.data;
          this.setState({
            branchAdminName: agencyInfo.branchAdminName,
            branchAdminEmail: agencyInfo.branchAdminEmail,
            csrName: agencyInfo.csrName,
            csrTitle: agencyInfo.csrTitle,
            csrPhone: agencyInfo.csrPhone,
            csrEmail: agencyInfo.csrEmail,
            salesrepList: agencyInfo.repsList,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.TutorialOverlay_AgencyInfo,
          "error"
        );
      });
  }

  agencyInformation() {
    return (
      <div>
        <br />
        <Row>
          <Col md={12} sm={12} xs={12}>
            <h4 className="popup-blue-text">Key Contacts</h4>
          </Col>
        </Row>
        <Row style={{ display: "flex" }}>
          <Col md={8} sm={8} xs={8}>
            <span>{DisplayMessages.TutorialOverlay_BranchAdminText}</span>
          </Col>
          <Col md={4} sm={4} xs={4}>
            <span style={{ wordBreak: "break-all" }}>
              {this.state.branchAdminName}
            </span>
            <br />
            <span style={{ wordBreak: "break-all" }}>
              {this.state.branchAdminEmail}
            </span>
          </Col>
        </Row>
        <br />
        <Row style={{ display: "flex" }}>
          <Col md={12} sm={12} xs={12}>
            <span>{DisplayMessages.TutorialOverlay_SalesAgencyText}</span>
          </Col>
        </Row>
        <Row>
          {this.state.csrName !== "NA" ? (
            <Col md={4} sm={4} xs={4}>
              <br />
              <span style={{ wordBreak: "break-all" }}>
                {this.state.csrName}
              </span>
              <br />
              <span style={{ wordBreak: "break-all" }}>
                {this.state.csrTitle}
              </span>
              <br />
              <span style={{ wordBreak: "break-all" }}>
                {this.state.csrPhone}
              </span>
              <br />
              <span style={{ wordBreak: "break-all" }}>
                {this.state.csrEmail}
              </span>
              <br />
              <br />
            </Col>
          ) : null}
          {this.state.salesrepList.map((element) => {
            return this.salesAgentInfo(element);
          })}
        </Row>
        <br />
      </div>
    );
  }

  salesAgentInfo(rep) {
    return (
      <Col md={4} sm={4} xs={4} key={rep.salesrepEmail}>
        <br />
        <span style={{ wordBreak: "break-all" }}>{rep.salesrepName}</span>
        <br />
        <span style={{ wordBreak: "break-all" }}>{rep.salesrepTitle}</span>
        <br />
        <span style={{ wordBreak: "break-all" }}>{rep.salesrepPhone}</span>
        <br />
        <span style={{ wordBreak: "break-all" }}>{rep.salesrepEmail}</span>
        <br />
        <br />
      </Col>
    );
  }

  // Mixpanel Tracking //

  trackTutorialClick() {
    mixpanel.track("Tutorials", {
      Action: "Button Click",
      Effect: "No effect",
    });
  }

  trackFeedbackClick() {
    mixpanel.track("Feedback and FAQ", {
      Action: "Button Click",
      Effect: "Redirected to FAQ page",
    });
  }
}

export default TutorialFAQ;
