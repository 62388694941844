import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import { Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import "./specTooltipPopup.css";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  Miscellaneous,
  fetchJSONFileData,
} from "../Utils/FetchConfigurableData";
import { LinkContainer } from "react-router-bootstrap";

/**
 * Component represents the OK Popup
 */
class SpecTooltipPopup extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      onModal: this.props.onModal,
      specs: this.props.specValue,
      productId: this.props.productId,
      stockCode: this.props.stockCodeTitle,
      description: this.props.descriptionTitle,
      engSpecLink: this.props.engSpecLink,
      frSpecLink: this.props.frSpecLink,
      contact: "",
      textInfoSpec: DisplayMessages.SpecLinks_CartDownloadMessage,
      textInfoCallUs: "",
      isSpecNA: false,
      container: this.props.container,
      applicationType: "RAB",
      isNavigationEnabled: this.props.isNavigationEnabled,
    };

    this.popoverRight = this.popoverRight.bind(this);
    this.goToSpecsPage = this.goToSpecsPage.bind(this);
    this.getContactInfo = this.getContactInfo.bind(this);
    this.checkIfBlankSpec = this.checkIfBlankSpec.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await fetchJSONFileData(fixedLink.displayMessagesPath);
    this.getContactInfo();

    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  handleScroll() {
    document.body.click();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.stockCodeTitle !== this.state.stockCode ||
      nextProps.engSpecLink !== this.state.engSpecLink ||
      nextProps.frSpecLink !== this.state.frSpecLink ||
      nextProps.descriptionTitle !== this.state.description ||
      nextProps.specValue !== this.state.specs ||
      nextProps.productId !== this.state.productId ||
      nextProps.isNavigationEnabled !== this.state.isNavigationEnabled
    ) {
      let textInfoSpec = DisplayMessages.SpecLinks_CartDownloadMessage;
      let textInfoCallUs = "";
      let isSpecNA = false;

      if (
        nextProps.specValue === "N/A" ||
        (nextProps.specValue !== "N/A" &&
          this.checkIfBlankSpec(nextProps.engSpecLink) &&
          this.checkIfBlankSpec(nextProps.frSpecLink))
      ) {
        textInfoSpec = DisplayMessages.SpecLinks_UnavailableInformation;
        textInfoCallUs = DisplayMessages.SpecLinks_CallUs;
        isSpecNA = true;
      }

      this.setState({
        onModal: nextProps.onModal,
        specs: nextProps.specValue === "N/A" ? "NA" : nextProps.specValue,
        productId: nextProps.productId,
        stockCode: nextProps.stockCodeTitle,
        description: nextProps.descriptionTitle,
        engSpecLink: nextProps.engSpecLink,
        frSpecLink: nextProps.frSpecLink,
        isNavigationEnabled: nextProps.isNavigationEnabled,
        textInfoSpec:
          nextProps.specValue === "N/A"
            ? DisplayMessages.SpecLinks_UnavailableInformation
            : textInfoSpec,
        textInfoCallUs:
          nextProps.specValue === "N/A"
            ? DisplayMessages.SpecLinks_CallUs
            : textInfoCallUs,
        isSpecNA: nextProps.specValue === "N/A" ? true : isSpecNA,
      });
    }
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <OverlayTrigger
          placement="right"
          trigger="click"
          style={{ backgroundColor: "#fff" }}
          overlay={this.popoverRight()}
          rootClose
        >
          <p className="link-text" title={this.state.specs}>
            {this.state.specs}
          </p>
        </OverlayTrigger>
      </div>
    );
  }

  checkIfBlankSpec(str) {
    let isBlankSpec = false;
    if (str === "0" || str === "" || str === "N/A") {
      isBlankSpec = true;
    }

    return isBlankSpec;
  }

  goToSpecsPage(link) {
    window.open(link);
  }

  getContactInfo() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this._isMounted) {
      this.setState({
        contact: sessionStorage.getItem("salesAgencyContact"),
        applicationType: userObj["applicationType"],
      });
    }
  }

  popoverRight() {
    let filter = {
      postId: parseInt(this.state.productId, 10).toString(),
      applicationType: this.state.applicationType,
      redirection: true,
    };

    return (
      <Popover
        id="specPopover"
        color="transparent"
        style={{
          maxWidth: "550px",
          minWidth: "500px",
          maxHeight: "250px",
          zIndex: this.state.onModal ? "1050" : "1",
          borderRadius: "0px",
        }}
      >
        <div className="tooltip-container">
          <div className="tooltip-content">
            <div className="popup-white-text">
              <Col
                md={this.state.isNavigationEnabled ? 11 : 12}
                sm={this.state.isNavigationEnabled ? 11 : 12}
                xs={this.state.isNavigationEnabled ? 11 : 12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Row className="text-center" style={{ marginLeft: "40px" }}>
                  {this.state.stockCode}
                </Row>
                <Row className="text-center" style={{ marginLeft: "40px" }}>
                  {this.state.description}
                </Row>
              </Col>
              {this.navigationLink(filter)}
            </div>
            <FormGroup>
              <br />
              {!this.state.isSpecNA && (
                <div>
                  <Row
                    className="text-center"
                    style={{ marginTop: "-10px", marginBottom: "5px" }}
                  >
                    <Row
                      className="popup-black-text"
                      style={{
                        fontSize: "15px",
                        color: "black",
                      }}
                    >
                      {this.state.specs}
                    </Row>
                  </Row>
                  <Row
                    className="text-center mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {!this.checkIfBlankSpec(this.state.engSpecLink) && (
                      <Col md={6}>
                        <Button
                          bsStyle="primary"
                          className="button-blue"
                          style={{
                            fontSize: "15px",
                            width: "70%",
                            fontFamily: "Montserrat, sans-serif",
                            marginRight: !this.checkIfBlankSpec(
                              this.state.frSpecLink
                            )
                              ? "-70px"
                              : "",
                          }}
                          onClick={() => {
                            this.trackSpecLinkClick(
                              this.state.engSpecLink,
                              "English"
                            );
                            this.goToSpecsPage(this.state.engSpecLink);
                          }}
                        >
                          {Miscellaneous.ApplicationType === "RAB"
                            ? "English Spec"
                            : "Spec Sheet"}
                        </Button>
                      </Col>
                    )}
                    {!this.checkIfBlankSpec(this.state.frSpecLink) && (
                      <Col md={6}>
                        <Button
                          bsStyle="primary"
                          className="button-blue text-center"
                          style={{
                            fontSize: "15px",
                            width: "70%",
                            fontFamily: "Montserrat, sans-serif",
                            marginLeft: !this.checkIfBlankSpec(
                              this.state.engSpecLink
                            )
                              ? "-70px"
                              : "",
                          }}
                          onClick={() => {
                            this.trackSpecLinkClick(
                              this.state.frSpecLink,
                              "French"
                            );
                            this.goToSpecsPage(this.state.frSpecLink);
                          }}
                        >
                          French Spec
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <br />
                </div>
              )}

              <Row className="text-center mt-2" style={{ marginTop: "-10px" }}>
                <Row
                  className="popup-black-text"
                  style={{
                    fontSize:
                      this.state.textInfoSpec ===
                      DisplayMessages.SpecLinks_CartDownloadMessage
                        ? "13px"
                        : "14.5px",
                  }}
                >
                  {this.state.textInfoSpec}
                </Row>
                <Row
                  className="popup-black-text"
                  style={{
                    fontSize: "14.5px",
                  }}
                >
                  {this.state.textInfoCallUs}
                </Row>
                {this.state.isSpecNA && (
                  <Row
                    className="popup-blue-text"
                    style={{
                      fontSize: "14.5px",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {this.state.contact}
                  </Row>
                )}
              </Row>
            </FormGroup>
          </div>
        </div>
      </Popover>
    );
  }

  navigationLink(filter) {
    if (this.state.isNavigationEnabled) {
      return (
        <Col
          md={1}
          sm={1}
          xs={1}
          style={{ fontSize: "20px", cursor: "pointer" }}
        >
          <LinkContainer to={{ pathname: "/Product", state: filter }}>
            <span
              onClick={() => this.trackProductPageNavigation()}
              className="glyphicon glyphicon-link"
              style={{ color: "white" }}
            />
          </LinkContainer>
        </Col>
      );
    } else {
      return null;
    }
  }

  // Mixpanel Tracking //

  trackSpecLinkClick(link, language) {
    mixpanel.track("Spec Link", {
      Action: "Button Click",
      Effect: "Navigate to Spec Link Page",
      Language: language,
      Link: link,
    });

    mixpanel.track_pageview({
      Page: "Spec Links Page",
    });
  }

  trackProductPageNavigation() {
    mixpanel.track("Product Link", {
      Action: "Link Click",
      Effect: "Navigate to Product Page",
      ProductId: parseInt(this.state.productId, 10),
      "Stock Code": this.state.stockCode,
      Description: this.state.description,
      Specs: this.state.specs,
    });

    mixpanel.track_pageview({
      Page: "Product Page",
    });
  }
}

export default SpecTooltipPopup;
