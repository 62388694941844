import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import mixpanel from "mixpanel-browser";

/**
 * Component represents selected tableau dashboard popup
 */
class TableauPopup extends Component {
  constructor(props) {
    super(props);
    this.handleClearClose = this.handleClearClose.bind(this);
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show.length > 0}
          onHide={this.handleClearClose}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <h3
              style={{
                fontWeight: "bolder",
              }}
              className="popup-blue-text"
            >
              {this.props.show}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="dashboard-view">
              <iframe
                title="dashboard"
                id="vizContainer"
                src={this.props.tableau_link}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
  handleClearClose() {
    this.trackTableauCloseClick();
    this.props.handleClearClose();
  }

  // Mixpanel Tracking //

  trackTableauCloseClick() {
    mixpanel.track("Tableau Dashboard Close", {
      Action: "Button Click",
      Effect: `Tableau Dashboard closed`,
    });
  }
}

export default TableauPopup;
