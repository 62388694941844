import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import OrderTrackHandler from "./OrderTrackHandler.js";
import TrackingTable from "./TrackingTable.js";
import "./orderTrack.css";
import NotificationSystem from "react-notification-system";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 125,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

let dateDict = {
  "1 day": 1,
  "7 days": 7,
  "30 days": 30,
  "60 days": 60,
  "90 days": 90,
  "1 year": 365,
};

/**
 * Component represents Track My Order page
 */
const branchBasedRoles = ["Distributor User", "Branch Admin"];
class OrderTrack extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      allCustomerNames: [],
      allSalesCodes: [],
      dateFilterOptions: Object.keys(dateDict),
      filterOption: "",
      filtertype: "",
      showTrackingResults: false,
      startDate: null,
      endDate: null,
      filteredTableResult: [],
      filteredAllRowResults: [],
      filterlist: [],
      filterDisplay: "",
      viewClicked: false,
      gotresults: false,
      last_updated_time: "",
      distUser: false,
    };
    this._notificationSystem = null;
    this.initialize = this.initialize.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.showResults = this.showResults.bind(this);
    this.viewResults = this.viewResults.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleGoBackToForm = this.handleGoBackToForm.bind(this);
    this.addNotification = this.addNotification.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    this.setState({
      distUser: branchBasedRoles.includes(userObj["role"]),
    });

    this.initialize();
    OrderTrackHandler.getCustomerDetails()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            allCustomerNames: response.data.customername,
            allSalesCodes: response.data.salescodes,
            gotresults: true,
            last_updated_time: response.data.time,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderTrack_CustomerDetails, "error");
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initialize() {
    let filters = [];
    this.state.dateFilterOptions.forEach((item, i) => {
      filters.push({ value: item, label: item });
    });
    let today = new Date();
    let sDate = new Date();
    sDate.setDate(today.getDate() - 1);
    if (this._isMounted) {
      this.setState({
        filtertype: "timePeriod",
        filterlist: filters,
        filterOption: filters[0].value,
        endDate:
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        startDate:
          sDate.getFullYear() +
          "-" +
          (sDate.getMonth() + 1) +
          "-" +
          sDate.getDate(),
        filterDisplay: filters[0].value,
      });
    }
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div className="manage-order-container">
        {this.filterOptionSection()}
        {this.trackingTableSection()}
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  filterOptionSection() {
    return (
      <div className={"content-usage-form-container " + this.showFilters()}>
        {this.state.viewClicked
          ? this.filterOptionLoadingSection()
          : this.filterOptionDisplaySection()}
      </div>
    );
  }

  filterOptionLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.TrackingTable_Loading}
        size={80}
      />
    );
  }

  filterOptionDisplaySection() {
    return (
      <Row className="loading-fade">
        <Col md={4} sm={6} xs={12}>
          <FormGroup>
            <span className="order-track-form">Filter By :</span>
            <br />
            <br />
            <label>
              <h5>
                <input
                  type="radio"
                  name="sortBy"
                  value="timePeriod"
                  onChange={() => this.handleSort("timePeriod")}
                  checked={this.state.filtertype === "timePeriod"}
                  style={{ marginLeft: 50 }}
                />{" "}
                Time Period{" "}
              </h5>
            </label>
            <br />
            <label>
              <h5>
                <input
                  type="radio"
                  name="sortBy"
                  value="customerPO"
                  onChange={() => this.handleSort("customerPO")}
                  checked={this.state.filtertype === "customerPO"}
                  style={{ marginLeft: 50 }}
                />{" "}
                Customer PO Number{" "}
              </h5>
            </label>
            <br />
            {!this.state.distUser ? (
              <div>
                <label>
                  <h5>
                    <input
                      type="radio"
                      name="sortBy"
                      value="customerID"
                      onChange={() => this.handleSort("customerID")}
                      checked={this.state.filtertype === "customerID"}
                      style={{ marginLeft: 50 }}
                    />{" "}
                    Customer Name / ID{" "}
                  </h5>
                </label>
                <br />
                {this.props.role !== "Salesrep" ? (
                  <div>
                    <label>
                      <h5>
                        <input
                          type="radio"
                          name="sortBy"
                          value="salesAgencyCode"
                          onChange={() => this.handleSort("salesAgencyCode")}
                          checked={this.state.filtertype === "salesAgencyCode"}
                          style={{ marginLeft: 50 }}
                        />{" "}
                        Sales Agency Code{" "}
                      </h5>
                    </label>
                    <br />
                  </div>
                ) : null}
              </div>
            ) : null}
            <label>
              <h5>
                <input
                  type="radio"
                  name="sortBy"
                  value="orderNumber"
                  onChange={() => this.handleSort("orderNumber")}
                  checked={this.state.filtertype === "orderNumber"}
                  style={{ marginLeft: 50 }}
                />{" "}
                Sales Order Number{" "}
              </h5>
            </label>
            <br />
            <div className="manage-orders-container">
              <h4>Filter criteria</h4>
              <div>
                {this.renderFilterCriteria()}
                <br />
                <br />
                <br />
                <Button
                  bsStyle="primary"
                  disabled={!this.state.gotresults}
                  onClick={this.viewResults}
                  className="button-blue"
                >
                  View Orders
                </Button>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  trackingTableSection() {
    return (
      <div className={"content-usage-result-container " + this.showResults()}>
        <Row>
          <Button
            bsStyle="primary"
            className="button-blue glyphicon glyphicon-arrow-left"
            onClick={this.handleGoBackToForm}
          />
        </Row>
        <Row>
          <TrackingTable
            filteredTableResult={this.state.filteredTableResult}
            filteredAllRowResults={this.state.filteredAllRowResults}
            filterOption={this.state.filterDisplay}
            last_updated_time={this.state.last_updated_time}
            role={this.props.role}
          />
        </Row>
        <br />
        <br /> <br />
        <br />
      </div>
    );
  }

  renderFilterCriteria() {
    switch (this.state.filtertype) {
      case "customerPO":
      case "orderNumber":
        return (
          <FormControl
            type="text"
            style={{ minHeight: "38px" }}
            placeholder={"Enter number"}
            value={this.state.filterDisplay}
            onChange={(e) =>
              this.handleSelection(e.target.value, e.target.value)
            }
          />
        );
      default:
        return (
          <Select
            name="filtercriteria"
            maxMenuHeight={190}
            menuPlacement="auto"
            aria-label="Filter criteria"
            key={`my_unique_select_key__${this.state.filterOption}`}
            placeholder={
              this.state.gotresults
                ? this.ternaryEvaluation(
                    this.state.filterOption,
                    "",
                    "No Options",
                    this.state.filterDisplay
                  )
                : "Loading Options..."
            }
            styles={customStyles}
            isDisabled={
              !this.state.gotresults || this.state.filterOption.length <= 0
            }
            onChange={(e) => this.handleSelection(e.value, e.label)}
            options={this.state.filterlist}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "lightgray",
                primary: "lightgray",
              },
            })}
          />
        );
    }
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  handleSort(value) {
    let filters = [];

    switch (value) {
      case "customerPO":
        this.setState({
          filterOption: "",
          filterlist: "",
          filtertype: value,
          filterDisplay: "",
        });
        return;
      case "orderNumber":
        this.setState({
          filtertype: value,
          filterOption: "",
          filterlist: "",
          filterDisplay: "",
        });
        return;
      case "timePeriod":
        this.state.dateFilterOptions.forEach((item, i) => {
          filters.push({ value: item, label: item });
        });
        break;
      case "customerID":
        if (this.state.allCustomerNames.length <= 0) {
          this.setState({
            filterOption: "",
            filterlist: "",
            filtertype: value,
          });
          return;
        }
        this.state.allCustomerNames.forEach((item, i) => {
          filters.push({
            value: item.CustomerName,
            label: item.Customer + ": " + item.CustomerName,
          });
        });
        break;
      case "salesAgencyCode":
        if (this.state.allSalesCodes.length <= 0) {
          this.setState({
            filterOption: "",
            filterlist: "",
            filtertype: value,
          });
          return;
        }
        this.state.allSalesCodes.forEach((item, i) => {
          item.SalesAgencyName !== ""
            ? filters.push({
                value: item.Salesperson,
                label: item.Salesperson + ": " + item.SalesAgencyName,
              })
            : filters.push({
                value: item.Salesperson,
                label: item.Salesperson,
              });
        });
        break;
      default:
        break;
    }

    this.setState({ filtertype: value, filterlist: filters }, () => {
      this.handleSelection(filters[0].value, filters[0].label);
    });
  }
  handleSelection(value, label) {
    if (this.state.filtertype === "timePeriod") {
      let eDate;
      let today = new Date();
      let sDate = new Date();
      eDate = today;

      sDate.setDate(today.getDate() - dateDict[value]);
      this.setState({
        startDate:
          sDate.getFullYear() +
          "-" +
          (sDate.getMonth() + 1) +
          "-" +
          sDate.getDate(),
        endDate:
          eDate.getFullYear() +
          "-" +
          (eDate.getMonth() + 1) +
          "-" +
          eDate.getDate(),
      });
    }

    this.setState({
      filterOption: value,
      filterDisplay: value === label ? value : label,
    });
  }

  handleResponse(orderData) {
    orderData.tabledata.map((element) => {
      for (let item of orderData.dispatchedSum) {
        if (element.SalesOrder === item.SalesOrder) {
          element.DispatchSum = item.TotalValDispatched;
          element.ShipStatus =
            parseFloat(element.OrderQty) === parseFloat(item.TotalDispatched) &&
            parseFloat(item.WaybillCount) === parseFloat(item.PackageCount)
              ? "Shipped"
              : "In Progress";
          break;
        }
      }
      for (let item of orderData.backorderQty) {
        if (element.SalesOrder === item.SalesOrder) {
          element.BackOrderQty = item.BackOrderQty;
          break;
        } else {
          element.BackOrderQty = "0";
        }
      }
      return element;
    });

    this.setState({
      filteredTableResult: orderData.tabledata,
      filteredAllRowResults: orderData.packagelist,
      showTrackingResults: orderData.tabledata.length > 0,
    });
  }

  viewResults() {
    if (this.state.filterOption.trim() === "") {
      this.addNotification(ErrorMessages.OrderTrack_ValidNumber, "warning");
      return;
    }
    let filter = {
      filterOption: this.state.filterOption,
      filtertype: this.state.filtertype,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.trackViewOrdersButtonClick();

    if (this.state.allCustomerNames.length > 0) {
      this.setState({ viewClicked: true });
      OrderTrackHandler.getTableDetails(filter)
        .then((response) => {
          if (response.success) {
            this.handleResponse(response.data);
          } else {
            this.setState({ viewClicked: false });
            this.addNotification(response.message, "warning");
          }
        })
        .catch((error) => {
          this.setState({ viewClicked: false });
          this.addNotification(ErrorMessages.OrderTrack_TableDetails, "error");
        });
    } else {
      this.addNotification(ErrorMessages.OrderTrack_NoOrders, "error");
    }
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  showResults() {
    if (this.state.showTrackingResults) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  handleGoBackToForm() {
    this.trackBackButtonClick();
    this.setState({
      showTrackingResults: false,
      viewClicked: false,
    });
  }

  showFilters() {
    if (!this.state.showTrackingResults) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  // Mixpanel Tracking //

  trackViewOrdersButtonClick() {
    mixpanel.track("View Orders", {
      Action: "Button Click",
      Effect: "Display results based on filter",
      "Filter Option": this.state.filterOption,
      "Filter By": this.state.filtertype,
    });
  }

  trackBackButtonClick() {
    mixpanel.track("Back To Order Filters", {
      Action: "Button Click",
      Effect: "Navigate back to the order filters page",
    });
  }
}

export default OrderTrack;
