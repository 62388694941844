import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import { ButtonToolbar, Col, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-table/react-table.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import DistributorsHandler from "./DistributorsHandler";
import ExportDataPopup from "../ExportDataPopup/ExportDataPopup";
import "./distributors.css";
import BranchNavigation from "./BranchNavigation.js";
import NoDeletePopup from "./NoDeletePopup";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading.js";
import DownloadProgress from "../DownloadPopup/DownloadProgress.js";

const adminRole = "360 Admin";

/**
 * Component represents the distributors page
 */
class Distributors extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      distributorData: [],
      isDataReceived: false,
      rowdata: [],
      deleteData: [],
      showNoDeletePopup: false,
      showDeletePopup: false,
      exportDistributorsPopup: false,
      searchString: "",
      searchValid: false,
      selectedTLD: null,
      role: "",
      username: "",
      selectedBranch: null,
      showAllDistributors: false,
      downloadLoader: false,
    };
    this.timer = null;

    this._notificationSystem = null;
    this.handleOnClose = this.handleOnClose.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
    this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    this.searchDistributors = this.searchDistributors.bind(this);
    this.handleExportCSV = this.handleExportCSV.bind(this);
    this.handleExportClose = this.handleExportClose.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.searchBarVisible = this.searchBarVisible.bind(this);
    this.deleteTld = this.deleteTld.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNoDeleteClose = this.handleNoDeleteClose.bind(this);
    this.toggleDistributors = this.toggleDistributors.bind(this);
    this.distributorsDataList = this.distributorsDataList.bind(this);
    this.distributorNameLayout = this.distributorNameLayout.bind(this);
    this.distributorActionsLayout = this.distributorActionsLayout.bind(this);
    this.distributorNameHandler = this.distributorNameHandler.bind(this);
  }

  componentDidMount(props) {
    this._isMounted = true;
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    let username = sessionStorage.getItem("username");
    if (this._isMounted) {
      this.setState(
        {
          role: userObj["role"],
          username: username,
          selectedBranch: this.props.location.state
            ? this.props.location.state.selectedBranch
            : null,
          selectedTLD: this.props.location.state
            ? this.props.location.state.selectedTLD
            : null,
        },
        this.refreshPage
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedBranch: nextProps.location.state
          ? nextProps.location.state.selectedBranch
          : null,
        selectedTLD: nextProps.location.state
          ? nextProps.location.state.selectedTLD
          : null,
      },
      this.refreshPage
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  paginationOptionsBuilder() {
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.trackPagination(page);
        onPageChange(page);
      };

      return (
        <li className="page-item" key={page}>
          {/* eslint-disable-next-line */}
          <a href="#" key={page} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      pageButtonRenderer,
      sizePerPageList: [
        {
          text: "25",
          value: 25,
        },
      ],
    };

    return options;
  }

  refreshPage() {
    DistributorsHandler.getDistributorData(
      encodeURIComponent(this.state.username)
    )
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            distributorData: response.data,
            searchString: "",
            searchValid: true,
            isDataReceived: true,
          });
        } else if (this._isMounted) {
          this.setState({
            isDataReceived: true,
            searchValid: false,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.Distributors_DistributorData,
          "error"
        );
      });
  }

  searchDistributors() {
    DistributorsHandler.searchDistributors(
      encodeURIComponent(this.state.searchString),
      this.state.username
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            distributorData: response.data,
            isDataReceived: true,
            searchValid: true,
          });
        } else {
          this.setState({
            isDataReceived: true,
            searchValid: false,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.Distributors_SearchDistributors,
          "error"
        );
      });
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
        },
      },
    };
    if (this.state.selectedTLD === null) {
      return (
        <div className="distributor-container">
          <Row>
            <h2 className="big-blue-text">Distributors</h2>
            <br />
          </Row>
          <Row
            className={this.searchBarVisible()}
            style={{ alignItems: "flex-end" }}
          >
            <Col md={7} sm={7} xs={7} className="add-export">
              {this.state.role === adminRole && this.state.searchValid ? (
                <ButtonToolbar>
                  <Button
                    bsStyle="primary"
                    className={this.allowExport()}
                    onClick={this.handleExportCSV}
                  >
                    Export as .csv
                  </Button>
                </ButtonToolbar>
              ) : null}
              {this.state.role !== adminRole ? (
                <div>
                  <label
                    onChange={this.toggleDistributors}
                    style={{ fontSize: "14px" }}
                  >
                    <input
                      type="checkbox"
                      value={this.state.showAllDistributors}
                      defaultChecked={this.state.showAllDistributors}
                    />{" "}
                    View All Distributors
                  </label>
                </div>
              ) : null}
            </Col>
            <Col
              md={5}
              sm={12}
              xs={12}
              className="searchbox"
              style={{ float: "right", padding: "0px", marginTop: "10px" }}
            >
              <Col className="no-padding-dist">
                <FormControl
                  type="text"
                  autoComplete="off"
                  placeholder="Search for a distributor"
                  spellCheck="false"
                  value={this.state.searchString}
                  onChange={this.handleSearchStringChange}
                />
              </Col>
            </Col>
          </Row>
          <br />
          {this.distributorTableSection()}
          <ExportDataPopup
            show={this.state.exportDistributorsPopup}
            title="Export Distributor Details"
            handleOnClose={this.handleExportClose}
            handleOnSubmit={this.handleExportSubmit}
            addNotification={this.addNotification}
          />
          <DeleteConfirmationPopup
            show={this.state.showDeletePopup}
            item={this.state.deleteData.distributor}
            itemType={"TLD"}
            handleConfirmation={this.handleDelete}
            handleCancel={this.handleDeleteClose}
          />
          <NoDeletePopup
            show={this.state.showNoDeletePopup}
            itemCount={this.state.deleteData.totalBranches}
            itemName={this.state.deleteData.distributor}
            itemType={"TLD"}
            handleOnClose={this.handleNoDeleteClose}
          />
          <DownloadProgress show={this.state.downloadLoader} />

          <NotificationSystem
            ref={(notificationSystem) =>
              (this._notificationSystem = notificationSystem)
            }
            style={notificationStyle}
          />
        </div>
      );
    } else {
      return (
        <BranchNavigation
          goBack={this.goBack}
          selectedTLD={this.state.selectedTLD}
          selectedBranch={this.state.selectedBranch}
        />
      );
    }
  }

  distributorTableSection() {
    return this.state.isDataReceived
      ? this.distributorTableDisplay()
      : this.distributorTableLoadingSection();
  }

  handleDeleteClose() {
    this.setState({
      showDeletePopup: false,
      deleteData: [],
    });
  }

  handleNoDeleteClose() {
    this.setState({
      showNoDeletePopup: false,
      deleteData: [],
    });
  }

  handleDelete() {
    let distributor = this.state.deleteData.distributor;
    DistributorsHandler.deleteTld(this.state.deleteData.tldId)
      .then((response) => {
        if (response.success) {
          this.trackDeleteTLDConfirmationButtonClick(distributor);
          this.addNotification(DisplayMessages.Distributors_DeleteTld, "info");
        } else {
          this.addNotification(response.message, "error");
        }
        this.resetTLDTable();
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.Distributors_DeleteDistributor,
          "error"
        );
      });
    this.setState({
      showDeletePopup: false,
      deleteData: [],
    });
  }

  toggleDistributors() {
    this.setState(
      (prevState) => ({
        showAllDistributors: !prevState.showAllDistributors,
      }),
      this.trackViewAllDistributorsCheckbox
    );
  }

  goBack() {
    this.setState(
      {
        selectedTLD: null,
        selectedBranch: null,
      },
      this.resetTLDTable
    );
  }

  allowExport() {
    if (this.state.role === adminRole && this.state.searchValid) {
      return "export-dist-button";
    } else {
      return "export-dist-button hidden";
    }
  }

  distributorsDataList() {
    if (this.state.showAllDistributors || this.state.role === adminRole) {
      return this.state.distributorData;
    } else {
      let homeDistributorData = this.state.distributorData.filter(
        (item) => item.home || item.star || item.admin
      );
      // Add a length check here
      return homeDistributorData;
    }
  }

  searchBarVisible() {
    if (
      this.state.searchValid ||
      this.state.searchString.length > 0 ||
      this.state.distributorData.length > 0
    ) {
      return "flex-component";
    } else {
      return "hide-component";
    }
  }

  distributorTableDisplay() {
    if (!this.state.searchValid || this.state.distributorData.length === 0) {
      return (
        <Row>
          <center>
            <h4>No Distributors Found!</h4>
          </center>
        </Row>
      );
    } else {
      return (
        <Row className="loading-fade">
          <BootstrapTable
            keyField="tldId"
            data={this.distributorsDataList()}
            columns={this.getColumns()}
            defaultSorted={this.getDefaultSort()}
            pagination={paginationFactory(this.paginationOptionsBuilder())}
          />
        </Row>
      );
    }
  }

  handleSearchStringChange(e) {
    let searchString = e.target.value.replace(/\s{2,}/g, " ");
    this.setState(
      {
        searchString: searchString,
        isDataReceived: false,
      },
      () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (searchString !== "") {
            this.trackSearchDistributorsFilter(searchString);
            this.searchDistributors();
          } else {
            this.refreshPage();
          }
        }, 1000);
      }
    );
  }

  getColumns() {
    const columns = [
      {
        dataField: "distributor",
        text: "Distributor",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "350px",
        },
        formatter: (cell, row) => {
          return this.distributorNameLayout(cell, row);
        },
      },
      {
        dataField: "totalBranches",
        text: "Total Branches",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "100px",
        },
      },
      {
        dataField: "activeUsers",
        text: "Active Users",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "100px",
        },
      },
      {
        dataField: "inactiveUsers",
        text: "Inactive Users",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "15px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 15px",
          width: "100px",
        },
      },
    ];
    if (this.state.role === adminRole) {
      columns.push({
        dataField: "tldId",
        text: "Distributor Actions",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "80px",
        },
        formatter: (cell, row, rowIndex) => {
          return this.distributorActionsLayout(row);
        },
      });
    }
    return columns;
  }

  distributorNameLayout(cell, row) {
    return (
      <span style={{ display: "flex" }}>
        <button
          className="blue-text"
          onClick={() => {
            this.distributorNameHandler(cell, row);
          }}
        >
          <span>{cell}</span>
        </button>
        {row.home ? (
          <div>
            {row.isCSR ? (
              <span
                className="glyphicon glyphicon-home home-icons"
                title="You have CSR provisions in this TLD"
              />
            ) : (
              <span
                className="glyphicon glyphicon-home home-icons"
                title="You have a home branch in this TLD"
              />
            )}
          </div>
        ) : null}
        {row.admin ? (
          <div>
            <span
              className="glyphicon glyphicon-user admin-icons"
              title="You have branch admin provisions in this TLD"
            />
          </div>
        ) : null}
        {row.star ? (
          <div>
            <span
              className="glyphicon glyphicon-star-empty star-icons"
              title="You are assigned to branches in this TLD"
            />
          </div>
        ) : null}
      </span>
    );
  }

  distributorNameHandler(cell, row) {
    this.trackDistributorButtonClick(cell);
    this.setState({
      selectedTLD: row,
      rowdata: row,
    });
  }

  distributorActionsLayout(row) {
    return (
      <span>
        <button
          className="deleteButton"
          aria-label="Delete"
          onClick={() => {
            this.deleteTld(row);
          }}
        >
          <span className="glyphicon glyphicon-trash" />
        </button>
      </span>
    );
  }

  deleteTld(row) {
    this.trackDeleteTLDButtonClick(row.distributor);
    this.setState({ deleteData: row });
    if (row.totalBranches > 0) {
      this.setState({ showNoDeletePopup: true });
    } else {
      this.setState({ showDeletePopup: true });
    }
  }

  resetTLDTable() {
    if (this.state.searchString.length > 0) {
      this.searchDistributors();
    } else {
      this.refreshPage();
    }
  }

  handleExportClose() {
    this.trackExportDiscardButtonClick();
    this.handlePopup();
  }

  handlePopup() {
    this.setState({
      exportDistributorsPopup: false,
    });
  }

  handleExportSubmit(fileName) {
    let data = {
      tldInfo: this.state.distributorData,
      searchString: this.state.searchString,
      username: this.state.username,
    };
    this.setState({
      downloadLoader: true,
      exportDistributorsPopup: false,
    });
    DistributorsHandler.exportDistributors(data)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        fileName = fileName + ".xlsx";

        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (document.getElementById("export-dist-button")) {
          document.getElementById("export-dist-button").blur();
        }
        this.setState({
          downloadLoader: false,
        });
        this.trackExportConfirmationButtonClick(fileName);
      })
      .catch((error) => {
        this.setState({
          downloadLoader: false,
        });
        this.addNotification(
          ErrorMessages.Distributors_ExportDistributors,
          "error"
        );
      });
  }

  getDefaultSort() {
    const defaultSort = [
      {
        dataField: "activeUsers",
        order: "desc",
      },
    ];

    return defaultSort;
  }

  handleExportCSV() {
    if (!this.state.searchValid) {
      this.addNotification(
        ErrorMessages.Distributors_NoDistributors,
        "warning"
      );
    } else {
      this.trackExportButtonClick();
      this.setState({ exportDistributorsPopup: true });
    }
  }

  distributorTableLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.Distributors_Loading}
        size={50}
      />
    );
  }

  handleOnClose() {
    this.resetTLDTable();
  }

  // Mixpanel Tracking //

  trackExportButtonClick() {
    mixpanel.track("Export Distributors", {
      Action: "Button Click",
      Effect: "Table data will be exported",
      Title: "Distributors Details",
    });
  }

  trackExportConfirmationButtonClick(fileName) {
    mixpanel.track("Export Distributors", {
      Action: "Button Click",
      Effect: "Table data exported",
      Title: "Distributors Details",
      "File name": fileName,
    });
  }

  trackExportDiscardButtonClick() {
    mixpanel.track("Export Discard", {
      Action: "Button Click",
      Effect: "Table data export discarded",
      Title: "Distributors Details",
    });
  }

  trackSearchDistributorsFilter(searchString) {
    mixpanel.track("Filter TLDs", {
      Action: "Search",
      Effect: "Distributor table will be filtered based on search string",
      "Search String": searchString,
    });
  }

  trackDistributorButtonClick(tld) {
    mixpanel.track("Distributor Select", {
      Action: "Link Click",
      Effect: "Navigate to branch table of selected distributor",
      "Distributor Name": tld,
    });

    mixpanel.track_pageview({
      Page: "Branches Page",
    });
  }

  trackDeleteTLDButtonClick(tld) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected TLD will be deleted",
      "Item Type": "TLD",
      TLD: tld,
    });
  }

  trackDeleteTLDConfirmationButtonClick(tld) {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected TLD deleted",
      "Item Type": "TLD",
      TLD: tld,
    });
  }

  trackViewAllDistributorsCheckbox() {
    mixpanel.track("View All Distributors", {
      Action: "Checkbox Click",
      Effect: "Show/Hide All distributors for current user",
      Visibility: this.state.showAllDistributors,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("TLD Table Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}
export default Distributors;
