import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { ButtonToolbar } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./distributors.css";
import DistributorsHandler from "./DistributorsHandler";
import DistributorConfirmationPopup from "../ConfirmationPopUp/DistributorConfirmationPopup";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the edit distributor users popup
 */
class EditDistributorUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      selectedRole: "",
      selectedPricingAccess: true,
      selectedOrderTracking: true,
      selectedTldVisibility: false,
      phone: "",
      jobTitle: "",
      rowdata: [],
      multiAdmin: false,
      currentUser: "",
      branchId: this.props.branchId,
      confirmationPopup: false,
      isUserAdmin: this.props.isUserAdmin,
      allowEdit: false,
    };
    this._notificationSystem = null;
    this.handleEditClose = this.handleEditClose.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.reset = this.reset.bind(this);
    this.setOrderTracking = this.setOrderTracking.bind(this);
    this.setPricingAccess = this.setPricingAccess.bind(this);
    this.setBranchVisibility = this.setBranchVisibility.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.setJobTitle = this.setJobTitle.bind(this);
    this.update = this.update.bind(this);
    this.cancel = this.cancel.bind(this);
    this.freshUpdate = this.freshUpdate.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      rowdata: nextProps.rowdata,
      userName: nextProps.rowdata.userName,
      firstName: nextProps.rowdata.firstName,
      lastName: nextProps.rowdata.lastName,
      selectedRole: nextProps.rowdata.role,
      selectedPricingAccess: nextProps.rowdata.pricingAccess === "yes",
      selectedOrderTracking: nextProps.rowdata.orderTracking === "yes",
      selectedTldVisibility: nextProps.rowdata.tldVisibility === "yes",
      phone:
        nextProps.rowdata.phoneNumber === "-"
          ? ""
          : nextProps.rowdata.phoneNumber,
      multiAdmin: nextProps.rowdata.multiAdmin,
      jobTitle: nextProps.rowdata.jobTitle,
      isUserAdmin: nextProps.isUserAdmin,
      currentUser: sessionStorage.getItem("username"),
      allowEdit: false,
    });
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleEditClose}
          className="add-user-modal"
        >
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              Edit User
            </h3>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Row>
              <Col md={4}>
                <h4 style={{ fontWeight: "bold" }}>Username/Email</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.userName}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <Row>
                  <h4 style={{ fontWeight: "bold" }}>
                    First Name<mark>*</mark>
                  </h4>
                </Row>
                <Row>
                  <FormGroup>
                    <FormControl
                      style={{ color: "black" }}
                      type="text"
                      maxLength={50}
                      value={this.state.firstName}
                      placeholder="Enter First Name"
                      onChange={this.setFirstName}
                    />
                  </FormGroup>
                </Row>
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Row>
                  <h4 style={{ fontWeight: "bold" }}>
                    Last Name<mark>*</mark>
                  </h4>
                </Row>
                <Row>
                  <FormGroup>
                    <FormControl
                      type="text"
                      style={{ color: "black" }}
                      maxLength={50}
                      value={this.state.lastName}
                      placeholder="Enter Last Name"
                      onChange={this.setLastName}
                    />
                  </FormGroup>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <h4 style={{ fontWeight: "bold" }}>
                  Title<mark>*</mark>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.jobTitle}
                    placeholder="Enter Job Title"
                    onChange={this.setJobTitle}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <h4 style={{ fontWeight: "bold" }}>Phone Number (optional)</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={20}
                    value={this.state.phone}
                    placeholder="Enter Phone Number"
                    onChange={this.setPhoneNumber}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <h4 style={{ fontWeight: "bold" }}>Role</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.selectedRole}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Col md={4} sm={6} xs={6}>
                  <Row>
                    <h4 style={{ fontWeight: "bold" }}>Pricing Access</h4>
                    <span>{DisplayMessages.PricingSubtext}</span>
                  </Row>
                  <br />
                  <Row>
                    <label
                      className="switch-dist"
                      onChange={this.setPricingAccess}
                      htmlFor="edit-pricing-access"
                    >
                      <input
                        id="edit-pricing-access"
                        type="checkbox"
                        defaultChecked={this.state.selectedPricingAccess}
                        disabled={this.state.isUserAdmin}
                      />
                      <span className="slider-dist round" />
                    </label>
                  </Row>
                </Col>
                <Col md={4} sm={6} xs={6}>
                  <Row>
                    <h4 style={{ fontWeight: "bold" }}>Order Tracking</h4>
                    <span>{DisplayMessages.TrackingSubtext}</span>
                  </Row>
                  <br />
                  <Row>
                    <label
                      className="switch-dist"
                      onChange={this.setOrderTracking}
                      htmlFor="edit-order-track"
                    >
                      <input
                        id="edit-order-track"
                        type="checkbox"
                        defaultChecked={this.state.selectedOrderTracking}
                        disabled={this.state.isUserAdmin}
                      />
                      <span className="slider-dist round" />
                    </label>
                  </Row>
                </Col>
              </Col>
            </Row>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <ButtonToolbar>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    disabled={!this.state.allowEdit}
                    onClick={this.update}
                    style={{ width: "100%", marginTop: "10px" }}
                    className="button-blue"
                  >
                    Save Changes
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    onClick={this.cancel}
                    style={{ width: "100%", marginTop: "10px" }}
                    className="button-white"
                  >
                    Cancel
                  </Button>
                </Col>
              </ButtonToolbar>
            </Row>
          </Modal.Footer>
        </Modal>
        <DistributorConfirmationPopup
          type={"edit"}
          show={this.state.confirmationPopup}
          handleConfirmation={this.handleConfirmation}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  setJobTitle(e) {
    this.setState(
      {
        jobTitle: e.target.value,
      },
      this.freshUpdate
    );
  }

  cancel() {
    this.trackCancelButtonClick();
    this.handleEditClose();
  }

  freshUpdate() {
    let changesMade = 0;

    if (this.state.firstName !== this.state.rowdata.firstName) {
      changesMade++;
    }
    if (this.state.lastName !== this.state.rowdata.lastName) {
      changesMade++;
    }
    if (this.state.jobTitle !== this.state.rowdata.jobTitle) {
      changesMade++;
    }
    if (this.state.phone !== this.state.rowdata.phoneNumber.replace("-", "")) {
      changesMade++;
    }
    if (
      (this.state.selectedPricingAccess ? "yes" : "no") !==
      this.state.rowdata.pricingAccess
    ) {
      changesMade++;
    }
    if (
      (this.state.selectedTldVisibility ? "yes" : "no") !==
      this.state.rowdata.tldVisibility
    ) {
      changesMade++;
    }
    if (
      (this.state.selectedOrderTracking ? "yes" : "no") !==
      this.state.rowdata.orderTracking
    ) {
      changesMade++;
    }
    if (changesMade > 0) {
      this.setState({
        allowEdit: true,
      });
    } else {
      this.setState({
        allowEdit: false,
      });
    }
  }

  validateUserDetails() {
    let validity = true;
    if (this.state.firstName === null || this.state.firstName.trim() === "") {
      this.trackSaveChangesFailedButtonClick(
        ErrorMessages.EditDistributorUser_FirstName
      );
      this.addNotification(
        ErrorMessages.EditDistributorUser_FirstName,
        "warning"
      );
      validity = false;
    }
    if (this.state.lastName === null || this.state.lastName.trim() === "") {
      this.trackSaveChangesFailedButtonClick(
        ErrorMessages.EditDistributorUser_LastName
      );
      this.addNotification(
        ErrorMessages.EditDistributorUser_LastName,
        "warning"
      );
      validity = false;
    }
    if (this.state.jobTitle === null || this.state.jobTitle.trim() === "") {
      this.trackSaveChangesFailedButtonClick(
        ErrorMessages.EditDistributorUser_JobTitle
      );
      this.addNotification(
        ErrorMessages.EditDistributorUser_JobTitle,
        "warning"
      );
      validity = false;
    }

    return validity;
  }
  update() {
    let validity = this.validateUserDetails();
    if (!validity) {
      return;
    }

    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      userName: this.state.userName,
      phoneNumber: this.state.phone,
      role: this.state.selectedRole,
      jobTitle: this.state.jobTitle,
      branch: this.state.branchId,
      orderTracking: this.state.selectedOrderTracking ? "yes" : "no",
      pricingAccess: this.state.selectedPricingAccess ? "yes" : "no",
      tldVisibility: this.state.selectedTldVisibility ? "yes" : "no",
      goldenPriceAccess: this.state.selectedPricingAccess
        ? this.state.rowdata.goldenPriceAccess
        : "no",
    };

    DistributorsHandler.updateDistributorUser(data)
      .then((response) => {
        if (response.success) {
          this.setState({
            confirmationPopup: true,
          });
          if (this.state.currentUser === this.state.userName) {
            sessionStorage.setItem("firstName", this.state.firstName);
            sessionStorage.setItem("lastName", this.state.lastName);
            sessionStorage.setItem("userRole", this.state.selectedRole);
          }
          this.trackSaveChangesButtonClick(data);
        } else {
          this.trackSaveChangesFailedButtonClick(response.message);
          this.addNotification(response.message, "error");
        }
        this.handleEditClose();
      })
      .catch((error) => {
        this.trackSaveChangesFailedButtonClick(
          ErrorMessages.EditDistributorUser_FailedUser
        );
        this.addNotification(
          ErrorMessages.EditDistributorUser_FailedUser,
          "error"
        );
      });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  setPhoneNumber(e) {
    let phone = e.target.value;
    this.setState(
      {
        phone: phone,
      },
      this.freshUpdate
    );
  }

  setOrderTracking() {
    this.setState(
      (prevState) => ({
        selectedOrderTracking: !prevState.selectedOrderTracking,
      }),
      this.freshUpdate
    );
  }

  setPricingAccess() {
    this.setState(
      (prevState) => ({
        selectedPricingAccess: !prevState.selectedPricingAccess,
      }),
      this.freshUpdate
    );
  }

  setBranchVisibility() {
    this.setState(
      (prevState) => ({
        selectedTldVisibility: !prevState.selectedTldVisibility,
      }),
      this.freshUpdate
    );
  }

  reset() {
    this.setState((prevState) => ({
      firstName: prevState.rowdata.firstName,
      lastName: prevState.rowdata.lastName,
      selectedRole: prevState.rowdata.role,
      phone: prevState.rowdata.phoneNumber,
      jobTitle: prevState.rowdata.jobTitle,
      selectedOrderTracking: prevState.rowdata.orderTracking === "yes",
      selectedPricingAccess: prevState.rowdata.pricingAccess === "yes",
      selectedTldVisibility: prevState.rowdata.tldVisibility === "yes",
    }));
  }

  handleEditClose() {
    this.reset();
    this.props.handleEditClose();
  }

  handleConfirmation() {
    this.setState({
      confirmationPopup: false,
    });
  }

  setFirstName(e) {
    let firstName = e.target.value;
    firstName = firstName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState(
      {
        firstName: firstName,
      },
      this.freshUpdate
    );
  }

  setLastName(e) {
    let lastName = e.target.value;
    lastName = lastName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState(
      {
        lastName: lastName,
      },
      this.freshUpdate
    );
  }

  // Mixpanel Tracking //

  trackSaveChangesButtonClick(data) {
    mixpanel.track("Edit Distributor User Confirmation", {
      Action: "Button Click",
      Effect: "Distributor User details updated",
      "First Name": data.firstName,
      "Last Name": data.lastName,
      "Phone Number": data.phoneNumber,
      "Current Job Role": data.role,
      "Job Title": data.jobTitle,
      "Branch ID": data.branchId,
      "TLD Visibility": data.tldVisibility,
      "Order Track Access": data.orderTracking,
      "Pricing Access": data.pricingAccess,
      "Golden Price Access": data.goldenPriceAccess,
    });
  }

  trackSaveChangesFailedButtonClick(message) {
    mixpanel.track("Edit Distributor User", {
      Action: "Button Click",
      Effect: "Distributor User update failed",
      Message: message,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Edit Distributor User Cancellation", {
      Action: "Button Click",
      Effect: "Distributor User update cancelled",
    });
  }
}
export default EditDistributorUser;
