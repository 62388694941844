import React, { Component } from "react";
import "./branchOverview.css";
import { Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import DistributorsHandler from "./DistributorsHandler";
import EditDistributorUser from "./EditDistributorUser";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading";

const tickOk = fetchResourceData(fixedLink.positiveIcon);
const dashMinus = fetchResourceData(fixedLink.negativeIcon);

/**
 * Component represents the user list in the branch details section
 */
class UserList extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);

    this.state = {
      selectedBranch: this.props.selectedBranch,
      branchName: this.props.selectedBranch.branchName,
      branchId: this.props.selectedBranch.branchId,
      usersOverviewTab: true,
      branchOverviewTab: false,
      searchString: this.props.searchString,
      searchValid: false,
      role: "360 Admin",
      distuserdata: [],
      rowdata: [],
      deleteUserConfirmationPopup: false,
      editUserPopup: false,
      addUsersPopup: this.props.addUsersPopup,
      popupName: "",
      isDataReceived: false,
      isUserAdmin: false,
    };

    this.timer = null;

    this._notificationSystem = null;
    this.usersOverviewTabHandler = this.usersOverviewTabHandler.bind(this);
    this.branchOverviewTabHandler = this.branchOverviewTabHandler.bind(this);
    this.deleteUserConfirmed = this.deleteUserConfirmed.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.editUser = this.editUser.bind(this);
    this.editAdminUser = this.editAdminUser.bind(this);
    this.resetUserTable = this.resetUserTable.bind(this);
    this.getDistributorUsers = this.getDistributorUsers.bind(this);
    this.searchDistributorUsers = this.searchDistributorUsers.bind(this);
    this.closeAllPopup = this.closeAllPopup.bind(this);
  }

  componentDidMount() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    this.setState(
      {
        role: userObj["role"],
        selectedBranch: this.props.selectedBranch,
        branchName: this.props.selectedBranch.branchName,
        branchId: this.props.selectedBranch.branchId,
      },
      this.getDistributorUsers
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.searchString !== this.state.searchString ||
      nextProps.addUsersPopup !== this.state.addUsersPopup
    ) {
      this.setState(
        {
          searchString: nextProps.searchString,
          addUsersPopup: nextProps.addUsersPopup,
          isDataReceived: !nextProps.addUsersPopup ? false : true,
        },
        () => {
          if (!this.state.addUsersPopup) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              if (nextProps.searchString !== "") {
                this.trackSearchUserFilter(this.state.searchString);
                this.searchDistributorUsers();
              } else {
                this.getDistributorUsers();
              }
            }, 1000);
          }
        }
      );
    }
  }

  paginationOptionsBuilder() {
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.trackPagination(page);
        onPageChange(page);
      };

      return (
        <li className="page-item" key={page}>
          {/* eslint-disable-next-line */}
          <a href="#" key={page} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      pageButtonRenderer,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
      ],
    };

    return options;
  }

  searchDistributorUsers() {
    DistributorsHandler.searchDistributorUsers(
      this.state.branchId,
      encodeURIComponent(this.state.searchString)
    )
      .then((response) => {
        if (response.success && response.data.length > 0) {
          this.setState(
            {
              distuserdata: response.data,
              searchValid: true,
              isDataReceived: true,
            },
            this.props.searchValidity(true)
          );
        } else {
          this.setState(
            {
              searchValid: false,
              isDataReceived: true,
            },
            this.props.searchValidity(false)
          );
        }

        this.closeAllPopup();
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.UserList_SearchDistributorUsers,
          "error"
        );
      });
  }

  getDistributorUsers() {
    DistributorsHandler.getDistributorUsers(this.state.branchId)
      .then((response) => {
        if (response.success && response.data.length > 0) {
          this.setState(
            {
              distuserdata: response.data,
              searchValid: true,
              isDataReceived: true,
            },
            this.props.searchValidity(true)
          );
        } else {
          this.setState(
            {
              searchValid: false,
              isDataReceived: true,
            },
            this.props.searchValidity(false)
          );
        }
        this.closeAllPopup();
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.UserList_GetDistributorUsers,
          "error"
        );
      });
  }

  closeAllPopup() {
    this.setState({
      deleteUserConfirmationPopup: false,
      editUserPopup: false,
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  deleteUserConfirmed() {
    this.trackDeleteDistributorUserConfirmationButtonClick(this.state.rowdata);
    DistributorsHandler.deleteDistributorUser(this.state.rowdata)
      .then((response) => {
        if (response.success) {
          this.resetUserTable();
          this.addNotification(
            DisplayMessages.UserList_SuccessfulDelete,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.UserList_DeleteDistributorUser,
          "error"
        );
      });
  }

  resetUserTable() {
    this.setState(
      {
        isDataReceived: false,
      },
      () => {
        if (this.state.searchString.length > 0) {
          this.searchDistributorUsers();
        } else {
          this.getDistributorUsers();
        }
      }
    );
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
        },
      },
    };
    return (
      <div>
        {this.state.isDataReceived
          ? this.usersOverviewTabDisplay()
          : this.userTableLoadingSection()}
        <DeleteConfirmationPopup
          show={this.state.deleteUserConfirmationPopup}
          item={
            this.state.rowdata.firstName + " " + this.state.rowdata.lastName
          }
          itemType={"User"}
          handleConfirmation={this.deleteUserConfirmed}
          handleCancel={this.handleClosePopup}
        />
        <EditDistributorUser
          show={this.state.editUserPopup}
          rowdata={this.state.rowdata}
          branchId={this.state.branchId}
          isUserAdmin={this.state.isUserAdmin}
          handleEditClose={this.handleEditClose}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  usersOverviewTabDisplay() {
    if (this.state.usersOverviewTab) {
      if (!this.state.searchValid) {
        return (
          <Row>
            <center>
              <h4>No Distributor Users Found!</h4>
            </center>
          </Row>
        );
      } else {
        return (
          <Row
            style={{ padding: "15px 15px 15px 0px" }}
            className="loading-fade"
          >
            <BootstrapTable
              keyField="userId"
              data={this.state.distuserdata}
              columns={this.getColumns()}
              defaultSorted={this.getDefaultSort()}
              pagination={paginationFactory(this.paginationOptionsBuilder())}
            />
          </Row>
        );
      }
    } else {
      return null;
    }
  }

  getColumns() {
    const columns = [
      {
        dataField: "userName",
        text: "Email/Username",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "250px",
        },
      },
      {
        dataField: "firstName",
        text: "First Name",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "lastName",
        text: "Last Name",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "left",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "jobTitle",
        text: "Title",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "phoneNumber",
        text: "Phone Number",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "125px",
        },
        formatter: (cell) => {
          return this.phoneNumberLayout(cell);
        },
      },
      {
        dataField: "pricingAccess",
        text: "Pricing Access",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "tldVisibility",
        text: "Branch Visibility",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "orderTracking",
        text: "Order Tracking",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "goldenPriceAccess",
        text: "Golden Price Access",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "lastLogin",
        text: "Last Login",
        sort: true,
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "125px",
        },
        formatter: (cell) => {
          return this.lastLoginLayout(cell);
        },
      },
    ];
    if (this.state.selectedBranch.admin || this.state.role === "360 Admin") {
      columns.push({
        dataField: "userId",
        text: "Account Actions",
        style: {
          border: "1px solid #CACACA",
          textAlign: "center",
          padding: "10px",
          wordBreak: "break-all",
          verticalAlign: "middle",
        },
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "125px",
        },
        formatter: (cell, row, rowIndex) => {
          return this.accountActionsLayout(row, rowIndex);
        },
      });
    }

    return columns;
  }

  phoneNumberLayout(cell) {
    if (cell === "" || cell === null) {
      return "-";
    } else {
      return cell;
    }
  }

  accessIconsLayout(cell) {
    if (cell === "no") {
      return (
        <div>
          <img className="icons" src={dashMinus} alt="no" />
        </div>
      );
    } else if (cell === "yes") {
      return (
        <div>
          <img className="icons" src={tickOk} alt="yes" />
        </div>
      );
    } else {
      return (
        <div>
          <span>
            <strong>-</strong>
          </span>
        </div>
      );
    }
  }

  lastLoginLayout(cell) {
    if (cell === 1) {
      return cell + " Day Ago";
    } else if (cell === -1) {
      return "-";
    } else {
      return cell + " Days Ago";
    }
  }

  accountActionsLayout(row, rowIndex) {
    if (row.userName !== sessionStorage.getItem("username")) {
      return (
        <span>
          <button
            className="editButton"
            onClick={() => {
              this.editUser(row, rowIndex);
            }}
          >
            Edit
          </button>{" "}
          <strong>|</strong>{" "}
          <button
            className="deleteButton"
            onClick={() => {
              this.deleteUser(row);
            }}
          >
            Delete
          </button>
        </span>
      );
    } else {
      return (
        <span>
          <button
            className="editButton"
            onClick={() => {
              this.editAdminUser(row, rowIndex);
            }}
          >
            Edit
          </button>
        </span>
      );
    }
  }

  getDefaultSort() {
    const defaultSort = [
      {
        dataField: "lastName",
        order: "asc",
      },
    ];

    return defaultSort;
  }

  editUser(row, rowIndex) {
    this.trackEditDistributorUserButtonClick(row);
    this.setState({
      rowdata: row,
      isUserAdmin: false,
      editUserPopup: true,
    });
  }

  editAdminUser(row, rowIndex) {
    this.trackEditDistributorUserButtonClick(row);
    this.setState({
      rowdata: row,
      isUserAdmin: true,
      editUserPopup: true,
    });
  }

  deleteUser(row) {
    this.trackDeleteDistributorUserButtonClick(row);
    this.setState({
      rowdata: row,
      deleteUserConfirmationPopup: true,
    });
  }

  handleClosePopup() {
    this.setState({
      deleteUserConfirmationPopup: false,
    });
  }

  handleEditClose() {
    this.setState({
      editUserPopup: false,
      rowdata: [],
    });
    this.resetUserTable();
  }

  usersOverviewTabHandler() {
    this.props.usersOverviewTabHandler();
  }

  branchOverviewTabHandler() {
    this.props.branchOverviewTabHandler();
  }

  goBack() {
    this.props.goBack();
  }

  userTableLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.DistributorUsers_Loading}
        size={50}
      />
    );
  }

  // Mixpanel Tracking //

  trackEditDistributorUserButtonClick(row) {
    mixpanel.track("Edit Distributor User", {
      Action: "Button Click",
      Effect: "Distributor User details will be updated",
      Username: row.userName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
    });
  }

  trackDeleteDistributorUserButtonClick(row) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected user will be deleted",
      "Item Type": "Distributor User",
      Username: row.userName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
    });
  }

  trackDeleteDistributorUserConfirmationButtonClick(row) {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected user deleted",
      "Item Type": "Distributor User",
      Username: row.userName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
    });
  }

  trackSearchUserFilter(searchString) {
    mixpanel.track("Filter Distributor Users", {
      Action: "Search",
      Effect: "User table will be filtered based on search string",
      "Search String": searchString,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Distributor User Table Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}

export default UserList;
