import React, { Component } from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Checkbox from "react-bootstrap/lib/Checkbox";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Grid from "react-bootstrap/lib/Grid";
import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import FormControl from "react-bootstrap/lib/FormControl";
import mixpanel from "mixpanel-browser";

import "./tools.css";
import ToolsDataHandler from "./tools_data_handler.js";
import ProductDataHandler from "../Product/ProductDataHandler.js";
import EmailPopup from "../EmailPopup/EmailPopup.js";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import ContentDataHandler from "../Content/ContentDataHandler";
import ContentLoading from "../ContentLoading/ContentLoading.js";

const priceTierArray = {
  Tier1: 3,
  Preferred: 4,
  "Distributor Net": 5,
};
const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the Resources page
 */
class Tools extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      showEmailPopup: false,
      categoryList: [],
      selectedFilesForShare: [],
      searchString: "",
      templateString: "",
      editContent: false,
      editContentClass: "glyphicon glyphicon-pencil",
      sortBy: "recent",
      resourceType: 0,
    };

    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleCloseEmailPopup = this.handleCloseEmailPopup.bind(this);
    this.handleEmailShare = this.handleEmailShare.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.addLog = this.addLog.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    this.search = this.search.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getDocumentTypeIcon = this.getDocumentTypeIcon.bind(this);
    this.showIfAdmin = this.showIfAdmin.bind(this);
    this.handleEditContentClick = this.handleEditContentClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getData = this.getData.bind(this);
    this.setUserAccess = this.setUserAccess.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUserAccess();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setUserAccess() {
    let role = sessionStorage.getItem("userRole");
    if (branchBasedRoles.includes(role)) {
      let username = sessionStorage.getItem("username");
      ToolsDataHandler.getPricingDetails(username)
        .then((response) => {
          if (response.success && this._isMounted) {
            if (response.data.pricingAccess) {
              this.setState(
                {
                  resourceType: priceTierArray[response.data.priceTier],
                },
                () => {
                  this.getData("recent", "");
                }
              );
            } else {
              this.setState(
                {
                  resourceType: 2,
                },
                () => {
                  this.getData("recent", "");
                }
              );
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.props.addNotification(
            ErrorMessages.Resources_ResourceType,
            "error"
          );
        });
    } else if (this._isMounted) {
      this.getData("recent", "");
    }
  }

  getData(sortBy, searchString) {
    let data = {
      sortBy: sortBy,
      searchString: encodeURIComponent(searchString),
      resourceType: this.state.resourceType,
    };
    ToolsDataHandler.specificToolsDocuments(data)
      .then((attachmentList) => {
        if (attachmentList.success && this._isMounted) {
          this.handleResponse(attachmentList);
        }
      })
      .catch((error) => {
        this.handleError(error);
        this.props.addNotification(
          ErrorMessages.Resources_SpecificToolsDocuments,
          "error"
        );
      });
  }

  render() {
    return (
      <div>
        {this.searchResourcesSection()}
        {this.sortResourcesSection()}
        {this.resourceListSection()}
        <br />
        <EmailPopup
          show={this.state.showEmailPopup}
          attachments={this.state.selectedFilesForShare}
          handleOnClose={this.handleCloseEmailPopup}
          addNotification={this.props.addNotification}
          clearSelection={this.clearSelection}
          emailTemplate={this.state.templateString}
          type="others"
        />
      </div>
    );
  }

  sortResourcesSection() {
    return (
      <Row>
        <Col md={9} style={{ paddingLeft: "0px" }}>
          <label>
            Sort By:{" "}
            <input
              type="radio"
              name="sortBy"
              value="recent"
              onChange={this.handleSort}
              style={{ marginLeft: 10, marginRight: "3px" }}
              defaultChecked
            />
            Recently Added
          </label>
          <label>
            <input
              type="radio"
              name="sortBy"
              value="alpha"
              onChange={this.handleSort}
              style={{ marginLeft: 20, marginRight: "3px" }}
            />
            Alphabetical
          </label>
        </Col>
        <br />
        <br />
        <br />
      </Row>
    );
  }

  searchResourcesSection() {
    return (
      <Row>
        <Col md={6} sm={12} xs={12} className="no-padding">
          <Col md={11} sm={11} xs={11} className="no-padding">
            <FormGroup>
              <FormControl
                type="text"
                autoComplete="off"
                placeholder="Search for document"
                onChange={this.handleSearchStringChange}
                onKeyDown={this.handleKeyPress}
              />
            </FormGroup>
          </Col>
          <Col md={1} sm={1} xs={1} className="no-padding">
            <Button
              bsStyle="primary"
              className="glyphicon glyphicon-search button-blue"
              aria-label="Search"
              onClick={this.search}
              style={{ top: "auto" }}
            />
          </Col>
        </Col>
        <Col md={6} sm={12} xs={12} className="no-padding">
          <Button
            bsStyle="primary"
            bsSize="small"
            className="share-btn button-blue"
            onClick={this.handleEmailShare}
            style={{ float: "right" }}
          >
            <span className="glyphicon glyphicon-share" />
            &nbsp;Share
          </Button>
        </Col>
      </Row>
    );
  }

  resourceListSection() {
    return this.state.categoryList.length > 0
      ? this.resourceDisplaySection()
      : this.resourceLoadingSection();
  }

  resourceDisplaySection() {
    return (
      <Row className="loading-fade">
        <Tabs
          defaultActiveKey={0}
          id="uncontrolled-tab-example"
          className="content-tabs"
          onSelect={this.trackSubtabNavigation}
        >
          {this.state.categoryList.map((item, i) => {
            return this.getCategoryElement(item, i);
          })}
        </Tabs>
      </Row>
    );
  }

  resourceLoadingSection() {
    if (!this.state.isLoaded) {
      return (
        <ContentLoading message={DisplayMessages.Resources_Loading} size={80} />
      );
    } else {
      return (
        <div>
          <br />
          <Grid>
            <h4 className="text-message">
              {DisplayMessages.Resources_NoDocuments}
            </h4>
          </Grid>
        </div>
      );
    }
  }

  handleKeyPress(target) {
    if (target.keyCode === 13) {
      this.search();
    }
  }

  addLog(fileName, action, extension) {
    ProductDataHandler.insertLog(fileName, action)
      .then(() => {
        this.trackDownloadResourceClick(fileName, extension);
      })
      .catch((error) => {
        this.handleError(error);
        this.trackDownloadResourceFailedClick(
          fileName,
          extension,
          ErrorMessages.Resources_InsertLog
        );
        this.props.addNotification(ErrorMessages.Resources_InsertLog, "error");
      });
  }

  handleResponse(attachmentList) {
    this.setState({
      isLoaded: true,
      categoryList: attachmentList,
    });
  }

  handleError(error) {
    this.setState({
      isLoaded: true,
    });
  }

  showIfAdmin() {
    if (![this.props.role].includes("360 Admin")) {
      return "hidden";
    } else {
      return "button-download button-blue";
    }
  }

  handleEditContentClick(docType, key) {
    if (this.state.editContent) {
      let text = document.getElementsByName("Edit" + key)[0].innerText;
      let description = { DisplayText: text, DocumentType: docType };
      ContentDataHandler.updateDescriptionText(description)
        .then((_) => {
          this.getData(this.state.sortBy, "");
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.Resources_UpdateContent,
            "error"
          );
        });

      this.trackConfirmContentClick(description);
    } else {
      this.trackEditContentClick();
    }

    this.setState((prevState) => ({
      editContent: !prevState.editContent,
      editContentClass:
        prevState.editContentClass === "glyphicon glyphicon-pencil"
          ? "glyphicon glyphicon-ok"
          : "glyphicon glyphicon-pencil",
    }));
  }

  handleSort(e) {
    let selected = e.currentTarget.value;
    this.trackResourceSortChange(selected);
    this.setState(
      {
        sortBy: selected,
      },
      this.getData(selected, this.state.searchString)
    );
  }

  getCategoryElement(category, key) {
    return (
      <Tab eventKey={key} title={category.documentType} key={key}>
        <div className="category-tabs">
          <Col md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexFlow: "nowrap",
                alignItems: "flex-end",
              }}
            >
              <Button
                bsStyle="primary"
                bsSize="small"
                aria-label="Edit Description"
                className={this.showIfAdmin()}
                style={{ height: "40px", width: "40px", marginRight: "20px" }}
                onClick={() =>
                  this.handleEditContentClick(category.documentType, key)
                }
              >
                <span
                  className={this.state.editContentClass}
                  style={{ verticalAlign: "middle" }}
                />
              </Button>
              <p
                onChange={this.handleEdit}
                contentEditable={this.state.editContent}
                suppressContentEditableWarning={true}
                name={"Edit" + key}
                id={this.state.editContent ? "classEdit" : ""}
                style={{
                  display: "inline-block",
                  margin: "5px 0px",
                  fontSize: "16px",
                  width: "90%",
                }}
              >
                {category.displayText}
              </p>
            </div>
          </Col>
          <br />
          {category.attachments.map((item, i) => {
            let fileName = item.original_file_name.slice(
              0,
              item.original_file_name.lastIndexOf(".")
            );
            let filePath = item.filePath;
            return this.generateAttachmentItem(fileName, filePath, item.id, i);
          })}
          <br />
        </div>
      </Tab>
    );
  }

  generateAttachmentItem(fileName, filePath, id, key) {
    let extension = filePath.slice(
      filePath.lastIndexOf(".") + 1,
      filePath.length
    );
    let imageSource = this.getDocumentTypeIcon(extension);
    return (
      <div key={key}>
        <FormGroup>
          <Col md={6} sm={12} xs={12}>
            <div className="resource-docs">
              <Button
                href={filePath}
                download={fileName + "." + extension}
                onClick={() => {
                  this.addLog(fileName, "Download", extension);
                }}
                style={{
                  height: "40px",
                  width: "40px",
                  marginRight: "15px",
                }}
              >
                <span
                  className="glyphicon glyphicon-download-alt "
                  style={{ verticalAlign: "middle" }}
                />
              </Button>
              <img
                src={imageSource}
                alt="support docs"
                style={{
                  height: "30px",
                  width: "30px",
                  margin: "0px 10px 0px 5px",
                  verticalAlign: "middle",
                }}
              />
              <Checkbox
                className="attachment-name"
                onChange={() => this.handleFileSelect(id, filePath, fileName)}
                checked={this.isChecked(id)}
              >
                {fileName}
              </Checkbox>
            </div>
          </Col>
        </FormGroup>
      </div>
    );
  }

  getDocumentTypeIcon(fileExtension) {
    switch (fileExtension) {
      case "pdf":
        return fetchResourceData(fixedLink.pdfIcon);
      case "doc":
      case "docx":
        return fetchResourceData(fixedLink.wordIcon);
      case "xls":
      case "xlsx":
        return fetchResourceData(fixedLink.excelIcon);
      case "ppt":
      case "pptx":
        return fetchResourceData(fixedLink.powerpointIcon);
      case "png":
      case "jpg":
        return fetchResourceData(fixedLink.imageIcon);
      default:
        return fetchResourceData(fixedLink.textIcon);
    }
  }
  isChecked(id) {
    let isFileIdChecked = false;
    let fileIdList = this.state.selectedFilesForShare;
    if (fileIdList.find((item) => item.id === id)) {
      isFileIdChecked = true;
    }
    return isFileIdChecked;
  }

  handleFileSelect(id, filePath, fileName) {
    let fileIdList = this.state.selectedFilesForShare;
    let isFileChecked = false;
    if (fileIdList.find((item) => item.id === id)) {
      fileIdList = fileIdList.filter((item) => item.id !== id);
    } else {
      isFileChecked = true;
      fileIdList.push({
        id: id,
        fileName: fileName,
        source: "local",
        filePath: filePath,
      });
    }
    this.trackResourceCheckboxToggle(id, fileName, isFileChecked);
    this.setState({
      selectedFilesForShare: fileIdList,
    });
  }

  handleEmailShare() {
    if (this.state.selectedFilesForShare.length <= 0) {
      this.props.addNotification(
        ErrorMessages.Resources_NoFileSelectedForShare,
        "warning"
      );
    } else {
      let data = {
        PortalWebsite: Miscellaneous.PortalWebsite,
        PortalName: Miscellaneous.PortalName,
        videoUrls: [],
      };
      ToolsDataHandler.getEmailTemplate(data)
        .then((response) => {
          if (!response.success) {
            this.props.addNotification(response.message, "error");
          } else {
            this.setState({
              showEmailPopup: true,
              templateString: response.data.body,
            });
          }
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.Resources_EmailTemplate,
            "error"
          );
        });
    }

    this.trackEmailShareButtonClick(
      this.state.selectedFilesForShare.length <= 0 ? false : true
    );
  }

  handleCloseEmailPopup() {
    this.setState({
      showEmailPopup: false,
    });
  }

  clearSelection() {
    this.setState({
      selectedFilesForShare: [],
    });
  }

  handleSearchStringChange(e) {
    this.setState({
      searchString: e.target.value,
    });
  }

  search() {
    this.trackResourceSearchButtonClick();
    this.getData(this.state.sortBy, this.state.searchString);
  }

  // Mixpanel Tracking //

  trackResourceSearchButtonClick() {
    mixpanel.track("Search for Document", {
      Action: "Button Click",
      Effect: "Results for the search string will be displayed",
      "Search String": this.state.searchString,
    });
  }

  trackResourceSortChange(sortBy) {
    let sortType = ["Recently Added", "Alphabetical"];
    mixpanel.track("Resources Sort By", {
      Action: "Radio Button Click",
      Effect: "Changing sort by parameter",
      "Sort By": sortBy === "recent" ? sortType[0] : sortType[1],
    });
  }

  trackDownloadResourceClick(filename, extension) {
    mixpanel.track("Resources Download", {
      Action: "Button Click",
      Effect: "Download selected resource",
      Filename: filename,
      Extension: extension,
    });
  }

  trackDownloadResourceFailedClick(filename, extension, message) {
    mixpanel.track("Resources Download", {
      Action: "Button Click",
      Effect: "Failed to download selected resource",
      Filename: filename,
      Extension: extension,
      Message: message,
    });
  }

  trackConfirmContentClick(description) {
    mixpanel.track("Confirm Content", {
      Action: "Button Click",
      Effect: "Confirm category content text",
      Description: description.DisplayText,
      "Document Type": description.DocumentType,
    });
  }

  trackEditContentClick() {
    mixpanel.track("Edit Content", {
      Action: "Button Click",
      Effect: "Enable edit category content text",
    });
  }

  trackResourceCheckboxToggle(id, filename, isChecked) {
    mixpanel.track("Resource Selection", {
      Action: "Checkbox Click",
      Effect: "Selection or deselection of resource checkbox",
      Filename: filename,
      "File ID": id,
      isChecked: isChecked,
    });
  }

  trackEmailShareButtonClick(open) {
    mixpanel.track("Share Resource", {
      Action: "Button Click",
      Effect: open ? "Open email share popup" : "No resources selected",
    });
  }

  trackSubtabNavigation() {
    mixpanel.track("Category Navigation", {
      Action: "Subtab Click",
      Effect: "Navigated to a different subtab",
    });
  }
}
export default Tools;
