import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Send Reset Mail
 * Validate Token
 * Reset Password
 * Get Username
 * Validate OTP
 * Resend OTP
 * Login user based on credentials
 * Logout user
 */
const LoginHandler = (function () {
  return {
    sendResetMail: function (data) {
      return new Promise((resolve, reject) => {
        let route = `Login/ResetMail`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    validateToken: function (userdata) {
      return new Promise((resolve, reject) => {
        Api.post("Login/ValidateToken", userdata)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetPassword: function (data) {
      return new Promise((resolve, reject) => {
        let route = `Login/ResetPassword`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUserName: function (key) {
      return new Promise((resolve, reject) => {
        let route = `Login/UserName?key=${key}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    validateOtp: function (otp, key, userName) {
      return new Promise((resolve, reject) => {
        let route = `Login/ValidateOtp?otp=${otp}&key=${key}&userName=${userName}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resendOtp: function (key, username) {
      return new Promise((resolve, reject) => {
        let route = `Login/ResendOtp?key=${key}&username=${username}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    userLogin: function (data) {
      return new Promise((resolve, reject) => {
        const route = `Login/UserLogin`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    userLogout: function () {
      return new Promise((resolve, reject) => {
        const route = `Login/UserLogout`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default LoginHandler;
