import React, { Component } from "react";
import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import mixpanel from "mixpanel-browser";

import "./orderSummary.css";
import OrderSummary from "./OrderSummary.js";
import OrderSummaryHistory from "../OrderSummaryHistory/OrderSummaryHistory";

/**
 * Component contains the estimate summary cart and estimate summary history section
 */
class OrderSummaryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyClicked: false,
    };
  }

  render() {
    return (
      <Tabs
        defaultActiveKey={1}
        id="uncontrolled-tab-example"
        className="content-tabs"
        onSelect={(key) => this.onSelectTab(key)}
      >
        <Tab eventKey={1} title="Estimate Summary">
          {!this.state.historyClicked ? (
            <OrderSummary
              addNotification={this.props.addNotification}
              userName={this.props.userName}
              applicationType={this.props.applicationType}
            />
          ) : null}
        </Tab>
        <Tab eventKey={2} title="Estimate Summary History">
          {this.state.historyClicked ? <OrderSummaryHistory /> : null}
        </Tab>
      </Tabs>
    );
  }

  onSelectTab(key) {
    if (key === 2) {
      this.trackEstimateSummaryHistoryNavigation();
      this.setState({
        historyClicked: true,
      });
    } else {
      this.trackEstimateSummaryNavigation();
      this.setState({
        historyClicked: false,
      });
    }
  }

  // Mixpanel Tracking //

  trackEstimateSummaryNavigation() {
    mixpanel.track_pageview({
      Page: "Estimate Summary Page",
    });
  }

  trackEstimateSummaryHistoryNavigation() {
    mixpanel.track_pageview({
      Page: "Estimate Summary History Page",
    });
  }
}
export default OrderSummaryPage;
