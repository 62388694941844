import Api from "../Utils/api.js";
import Plugin from "../Utils/plugin.js";

/**
 * API Calls to handle:
 * Get sales rep names
 * Add Banners and Support Documents
 * Change Display Order
 * Delete Banners and Support Documents
 * Get Home Page documents
 */
const ManageHomePageDataHandler = (function () {
  return {
    getSalesRepNames: function () {
      return new Promise((resolve, reject) => {
        let route = "users";
        Plugin.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadDocument: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`ManageHomePage/AddDocuments`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveDisplayOrder: function (documentType, attachments) {
      const route = `ManageHomePage/SaveDisplayOrder?documentType=${documentType}`;
      return new Promise((resolve, reject) => {
        Api.put(route, attachments)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteDocuments: function (id) {
      return new Promise((resolve, reject) => {
        Api.delete(`ManageHomePage/DeleteDocuments?id=${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    homePageDocuments: function (data) {
      return new Promise((resolve, reject) => {
        let route = `ManageHomePage/HomePageDocuments?documentType=${data}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ManageHomePageDataHandler;
