import Api from "../Utils/api.js";
/**
 * API Calls to handle:
 * Get tableau links
 * Get tableau dashboards
 */
const TableauDataHandler = (function () {
  return {
    getTableauLinks: function () {
      return new Promise((resolve, reject) => {
        let route = "Tableau/GetLinks";
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTableauDashboard: function (data) {
      return new Promise((resolve, reject) => {
        let route = `Tableau/GetDashboard`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default TableauDataHandler;
