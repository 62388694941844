import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get installations
 * Get application types
 * Get product names
 * Get locations
 * Get attachments
 * Delete installations
 * Upload installation
 * Set as month photo
 * Get description text
 * Update gallery description
 * Get all application types
 */
const GalleryDataHandler = (function () {
  return {
    getInstallations: function (data) {
      return new Promise((resolve, reject) => {
        const route = `RabFootprint/Installations`;
        Api.post(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getApplicationTypes: function () {
      return new Promise((resolve, reject) => {
        Api.get(`RabFootprint/Application`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProductNames: function (data) {
      return new Promise((resolve, reject) => {
        Api.get(`RabFootprint/ProductName?application=${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocation: function (longitude, latitude) {
      return new Promise((resolve, reject) => {
        Api.get(`RabFootprint/Location/${longitude}/${latitude}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAttachments: function (data) {
      return new Promise((resolve, reject) => {
        Api.get(`RabFootprint/Attachments`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteInstallations: function (id) {
      return new Promise((resolve, reject) => {
        Api.delete(`RabFootprint/DeleteInstallation?id=${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadInstallation: function (data) {
      return new Promise((resolve, reject) => {
        const route = `RabFootprint/UploadInstallations`;
        Api.post(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setAsMonthPhoto: function (data) {
      return new Promise((resolve, reject) => {
        const route = `ManageRabFootprint/MonthPhoto`;
        Api.post(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDescriptionText: function () {
      return new Promise((resolve, reject) => {
        Api.get(`RabFootprint/DescriptionText`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateGalleryDescription: function (data) {
      return new Promise((resolve, reject) => {
        const route = `ManageRabFootprint/GalleryDescription`;
        Api.put(route, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllApplicationTypes: function () {
      return new Promise((resolve, reject) => {
        Api.get(`RabFootprint/AllApplication`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default GalleryDataHandler;
