import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Row from "react-bootstrap/lib/Row";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import mixpanel from "mixpanel-browser";

import "./homePageAttachmentsPopup.css";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup.js";
import {
  DisplayMessages,
  ErrorMessages,
} from "../Utils/FetchConfigurableData.js";
import ManageHomePageDataHandler from "./ManageHomePageDataHandler.js";

/**
 * Component represents the Homepage attachments popup
 */
class HomePageAttachmentsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    };
    this.delete = this.delete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.cancelAttachmentDeletion = this.cancelAttachmentDeletion.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              {this.props.modelTitle}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row className="container attachmentList-container">
              <Table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>File Name</th>
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>{this.generateAttachmentList()}</tbody>
              </Table>
            </Row>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
        <DeleteConfirmationPopup
          show={this.state.deleteAttachmentPopup}
          item={this.state.attachmentName}
          itemType={"Attachment"}
          handleConfirmation={this.delete}
          handleCancel={this.cancelAttachmentDeletion}
        />
      </div>
    );
  }

  generateAttachmentList() {
    if (this.props.attachments.length <= 0) {
      return (
        <tr>
          <td colSpan="5">
            <h4 style={{ textAlign: "center" }}>No attachments to display!</h4>
          </td>
        </tr>
      );
    } else {
      return this.props.attachments.map((attachment) =>
        this.getAttachmentItem(attachment)
      );
    }
  }
  getAttachmentItem(item) {
    let extension = item.original_file_name.slice(
      item.original_file_name.lastIndexOf(".") + 1,
      item.original_file_name.length
    );
    let fileName = item.original_file_name.slice(
      0,
      item.original_file_name.lastIndexOf(".")
    );
    let downLoadBtn;
    if (extension === "pdf" || extension === "PDF") {
      downLoadBtn = (
        <Button
          bsSize="small"
          className="button-blue"
          style={{ height: "100%", width: "100%" }}
          href={item.filePath}
          target="_blank"
          onClick={() => this.trackDownloadButtonClick(fileName)}
        >
          <span className="glyphicon glyphicon-save" />
          &nbsp;Download
        </Button>
      );
    } else {
      downLoadBtn = (
        <Button
          bsSize="small"
          className="button-blue"
          href={item.filePath}
          style={{ height: "100%", width: "100%" }}
          onClick={() => this.trackDownloadButtonClick(fileName)}
          download={fileName + "." + extension}
        >
          <span className="glyphicon glyphicon-save" />
          &nbsp;Download
        </Button>
      );
    }

    return (
      <tr key={item.id} style={{ textAlign: "center" }}>
        <td style={{ verticalAlign: "middle" }}>{item.title}</td>
        <td style={{ verticalAlign: "middle" }}>{fileName}</td>
        <td style={{ verticalAlign: "middle" }}>
          <Button
            bsSize="small"
            className="button-download button-blue"
            style={{ height: "100%", width: "100%" }}
            onClick={() => {
              this.edit(item);
            }}
          >
            <span className="glyphicon glyphicon-pencil" />
          </Button>
        </td>
        <td style={{ verticalAlign: "middle" }}>{downLoadBtn}</td>
        <td style={{ verticalAlign: "middle" }}>
          <Button
            bsSize="small"
            className="button-download button-red"
            style={{ height: "100%", width: "100%" }}
            onClick={() => {
              this.handleDelete(item);
            }}
          >
            <span className="glyphicon glyphicon-trash" />
          </Button>
        </td>
      </tr>
    );
  }

  edit(item) {
    this.trackEditButtonClick(item);
    this.props.edit(item);
  }

  delete() {
    this.trackDeleteAttachmentButtonConfirmationClick();
    ManageHomePageDataHandler.deleteDocuments(this.state.attachmentId)
      .then((response) => {
        this.cancelAttachmentDeletion();
        this.props.addNotification(
          DisplayMessages.HomePageAttachmentsPopup_DocDeleteMsg,
          "info"
        );
        this.props.handleDeleteAttachment();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.HomePageAttachmentsPopup_DocDeleteMsg,
          "error"
        );
      });
  }

  cancelAttachmentDeletion() {
    this.setState({
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    });
  }

  handleDelete(item) {
    this.trackDeleteAttachmentButtonClick(item);
    this.setState({
      deleteAttachmentPopup: true,
      attachmentName: item.original_file_name,
      attachmentId: item.id,
    });
  }

  handleOnClose() {
    this.props.handleOnClose();
  }

  // Mixpanel Tracking //

  trackEditButtonClick(item) {
    mixpanel.track("Edit Document", {
      Action: "Button Click",
      Effect: "Provision to edit selected document",
      Title: item.title,
      Description: item.description,
      Link: item.link,
      "Item ID": item.id,
    });
  }

  trackDownloadButtonClick(fileName) {
    mixpanel.track("Download Document", {
      Action: "Button Click",
      Effect: "Selected document will be downloaded",
      Filename: fileName,
    });
  }

  trackDeleteAttachmentButtonClick(item) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected attachment will be deleted",
      "Item Type": "Attachment",
      "Attachment Name": item.original_file_name,
      "Attachment ID": item.id,
    });
  }

  trackDeleteAttachmentButtonConfirmationClick() {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected attachment deleted",
      "Item Type": "Attachment",
      "Attachment Name": this.state.attachmentName,
      "Attachment ID": this.state.attachmentId,
    });
  }
}

export default HomePageAttachmentsPopup;
