import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import SearchBox from "../SearchBox/SearchBox.js";
import TutorialFAQ from "../TutorialFAQ/TutorialFAQ.js";
import "./header.css";
import Messages from "../Assets/constant_strings.json";
import ConfigHandler from "../ConfigHandler/ConfigHandler.js";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData.js";

let updatedInfoReceived = true;
const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the header portion of the website
 */
class Header extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      logo: fetchResourceData(fixedLink.appLogo),
      userName: "",
      email: "",
      firstName: "",
      lastName: "",
      userRole: "",
      salesAgency: "",
      branchName: "",
      tldName: "",
      pricingAccess: "",
      goldenPriceAccess: "",
      trackingAccess: "",
      tldVisibility: "",
      logOutMessage: [],
      firstLogin: true,
      showTutorialFAQ: false,
      logOutStatus: false,
      nameUpdatedStatus: false,
      accountTerminated: false,
      multiAdmin: false,
      salesAgencyContact: "",
    };

    this.userInformation = this.userInformation.bind(this);
    this.getUpdatedInfo = this.getUpdatedInfo.bind(this);
    this.credentialsCheck = this.credentialsCheck.bind(this);
    this.handleTutorialFAQ = this.handleTutorialFAQ.bind(this);
    this.TutorialFAQClose = this.TutorialFAQClose.bind(this);
    this.OkButtonHandler = this.OkButtonHandler.bind(this);
    this.notificationPopupHandler = this.notificationPopupHandler.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState(
      {
        email: sessionStorage.getItem("username"),
      },
      this.getUpdatedInfo
    );

    if (updatedInfoReceived) {
      window.addEventListener("updateUserInfo", (event) => {
        this.getUpdatedInfo();
      });
      updatedInfoReceived = false;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      logo: nextProps.logo,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("updateUserInfo", (event) => {
      this.getUpdatedInfo();
    });
  }

  getUpdatedInfo() {
    let username = encodeURIComponent(sessionStorage.getItem("username"));
    ConfigHandler.loadUserInfo(username)
      .then((response) => {
        if (response.success && this._isMounted) {
          let userdata = response.data;

          if (this.state.firstLogin) {
            sessionStorage.setItem("firstName", userdata.firstName);
            sessionStorage.setItem("lastName", userdata.lastName);
            sessionStorage.setItem("userRole", userdata.userRole);
            sessionStorage.setItem("customerName", userdata.customerName);
            sessionStorage.setItem("phoneNumber", userdata.phoneNumber);
            sessionStorage.setItem(
              "pricingAccess",
              userdata.pricingAccess === "yes"
            );
            sessionStorage.setItem(
              "orderTrackAccess",
              userdata.trackingAccess === "yes"
            );
            sessionStorage.setItem(
              "tldVisibility",
              userdata.tldVisibility === "yes"
            );
            sessionStorage.setItem(
              "goldenPriceAccess",
              userdata.goldenPriceAccess === "yes"
            );
            sessionStorage.setItem(
              "salesAgencyContact",
              userdata.salesAgencyContact
            );

            this.trackUserIdentification(userdata);
          }

          this.setState(
            {
              firstName: userdata.firstName,
              lastName: userdata.lastName,
              userRole: userdata.userRole,
              salesAgency: userdata.salesAgency,
              branchName: userdata.branchName,
              tldName: userdata.tldName,
              multiAdmin: userdata.multiAdmin,
              pricingAccess: userdata.pricingAccess,
              goldenPriceAccess: userdata.goldenPriceAccess,
              trackingAccess: userdata.trackingAccess,
              tldVisibility: userdata.tldVisibility,
              firstLogin: false,
              salesAgencyContact: userdata.salesAgencyContact,
            },

            () => {
              this.credentialsCheck();
            }
          );
        } else if (this._isMounted) {
          let logOut = false;
          let accountTerminated = false;
          let errorMessage = [];
          if (response.message === "No Data") {
            errorMessage.push(Messages.logout_delete);
            logOut = true;
            accountTerminated = true;
          } else {
            errorMessage.push(Messages.logout_password);
            logOut = true;
          }
          this.setState({
            logOutStatus: logOut,
            accountTerminated: accountTerminated,
            logOutMessage: errorMessage,
            nameUpdatedStatus: false,
          });
        }
        updatedInfoReceived = true;
      })
      .catch((error) => {
        updatedInfoReceived = true;
        this.props.addNotification(ErrorMessages.Header_LoadUserInfo, "error");
      });
  }

  handleTutorialFAQ() {
    this.trackPortalResourcesClick();
    this.setState({
      showTutorialFAQ: true,
    });
  }

  TutorialFAQClose() {
    this.setState({
      showTutorialFAQ: false,
    });
  }

  credentialsCheck() {
    let errorMessage = [];
    let logOut = false;
    let nameUpdated = false;

    if (
      sessionStorage.getItem("salesAgencyContact") !==
      this.state.salesAgencyContact
    ) {
      sessionStorage.setItem(
        "salesAgencyContact",
        this.state.salesAgencyContact
      );
    }
    if (sessionStorage.getItem("firstName") !== this.state.firstName) {
      errorMessage.push(Messages.logout_firstName);
      nameUpdated = true;
    }
    if (sessionStorage.getItem("lastName") !== this.state.lastName) {
      errorMessage.push(Messages.logout_lastName);
      nameUpdated = true;
    }
    if (sessionStorage.getItem("userRole") !== this.state.userRole) {
      errorMessage.push(Messages.logout_userRole);
      logOut = true;
      nameUpdated = false;
    }
    if (
      JSON.parse(sessionStorage.getItem("pricingAccess")) !==
      (this.state.pricingAccess === "yes")
    ) {
      errorMessage.push(Messages.logout_pricingAccess);
      logOut = true;
      nameUpdated = false;
    }
    if (
      JSON.parse(sessionStorage.getItem("goldenPriceAccess")) !==
      (this.state.goldenPriceAccess === "yes")
    ) {
      if (!errorMessage.includes(Messages.logout_pricingAccess)) {
        errorMessage.push(Messages.logout_pricingAccess);
      }
      logOut = true;
      nameUpdated = false;
    }
    if (
      JSON.parse(sessionStorage.getItem("orderTrackAccess")) !==
      (this.state.trackingAccess === "yes")
    ) {
      errorMessage.push(Messages.logout_trackingAccess);
      logOut = true;
      nameUpdated = false;
    }
    if (
      JSON.parse(sessionStorage.getItem("tldVisibility")) !==
      (this.state.tldVisibility === "yes")
    ) {
      errorMessage.push(Messages.logout_tldVisibility);
      logOut = true;
      nameUpdated = false;
    }

    this.setState({
      logOutStatus: logOut,
      logOutMessage: errorMessage,
      nameUpdatedStatus: nameUpdated,
    });
  }

  render() {
    return (
      <div>
        <div className="header" id="nav-header">
          <br />
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md={4} sm={4} xs={4}>
              <LinkContainer to="/Home">
                <div className="app-logo">
                  <img
                    src={this.state.logo}
                    onClick={this.trackWebsiteLogoClick}
                    alt="logo"
                  />
                </div>
              </LinkContainer>
            </Col>
            <Col md={8} sm={8} xs={8}>
              <Row>
                <SearchBox />
              </Row>
              <div className="top-form top-search pull-right">
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Col md={11} sm={11} xs={11} className="no-padding">
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {updatedInfoReceived ? (
                        <div className="welcome-text" id="welcome">
                          Welcome {this.state.firstName}
                        </div>
                      ) : null}
                      <OverlayTrigger
                        trigger={["click"]}
                        rootClose
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="user-info-tooltip"
                            style={{ opacity: 1 }}
                          >
                            {this.userInformation()}
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={this.trackUserProfileClick}
                          className="glyphicon glyphicon glyphicon-user"
                          aria-label="User"
                          style={{
                            color: "gray",
                            border: "0px",
                            fontSize: "18px",
                          }}
                        />
                      </OverlayTrigger>
                      <div className="vertical-line" />
                      <Link to={{ pathname: "/EstimateSummary" }}>
                        <Button
                          onClick={this.trackCartButtonClick}
                          aria-label="Cart"
                        >
                          <span className="glyphicon glyphicon-shopping-cart estimate-cart-icon" />
                        </Button>
                      </Link>
                    </Row>
                  </Col>
                  <Col md={1} sm={1} xs={1} className="no-padding">
                    <Button
                      style={{ float: "right", color: "#1B2D5B" }}
                      onClick={this.handleTutorialFAQ}
                      aria-label="Help"
                      className="glyphicon glyphicon-question-sign"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <TutorialFAQ
          show={this.state.showTutorialFAQ}
          handleOnClose={this.TutorialFAQClose}
          role={this.state.userRole}
          addNotification={this.props.addNotification}
        />
        <Modal
          show={this.state.logOutStatus}
          dialogClassName="logout-modal"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
                textAlign: "center",
              }}
              className="popup-red-text"
            >
              Auto Logout Notification
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ fontSize: "14px", textAlign: "center" }}>
              <br />
              {this.logOutMessageDisplay()}
              <br />
              {!this.state.accountTerminated ? (
                <strong>Please login again.</strong>
              ) : null}
              <br />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                bsStyle="primary"
                className="button-skyblue"
                onClick={this.OkButtonHandler}
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.nameUpdatedStatus}
          dialogClassName="logout-modal"
          onHide={this.notificationPopupHandler}
        >
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
                textAlign: "center",
              }}
              className="popup-blue-text"
            >
              Notification
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ fontSize: "14px", textAlign: "center" }}>
              <br />
              {this.logOutMessageDisplay()}
              <br />
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  logOutMessageDisplay() {
    let rowDisplay = this.state.logOutMessage.map((item) => {
      return (
        <Row key={item}>
          <span>{item}</span>
        </Row>
      );
    });
    return <div>{rowDisplay}</div>;
  }

  userRoleDetails() {
    if (this.state.userRole === "Salesrep") {
      return this.salesRepPopup();
    } else if (branchBasedRoles.includes(this.state.userRole)) {
      return this.distUserPopup();
    } else {
      return this.adminPopup();
    }
  }

  OkButtonHandler() {
    if (this.state.logOutStatus) {
      this.trackAutoLogoutConfirmationClick();
      this.props.logOutHandler();
    }
  }

  notificationPopupHandler() {
    this.setState(
      {
        nameUpdatedStatus: false,
      },
      () => {
        sessionStorage.setItem("firstName", this.state.firstName);
        sessionStorage.setItem("lastName", this.state.lastName);
      }
    );
  }

  salesRepPopup() {
    return (
      <div>
        <Row>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "right", paddingLeft: "0px" }}
          >
            <span className="sub-text">User Role:</span>
          </Col>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "left", paddingRight: "0px" }}
          >
            <span className="sub-text">{this.state.userRole}</span>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "right", paddingLeft: "0px" }}
          >
            <span className="sub-text">Sales Agency:</span>
          </Col>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "left", paddingRight: "0px" }}
          >
            <span className="sub-text">{this.state.salesAgency}</span>
          </Col>
        </Row>
      </div>
    );
  }

  adminPopup() {
    return (
      <Row style={{ display: "flex" }}>
        <Col
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "right", paddingLeft: "0px" }}
        >
          <span className="sub-text">User Role:</span>
        </Col>
        <Col
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "left", paddingRight: "0px" }}
        >
          <span className="sub-text">{this.state.userRole}</span>
        </Col>
      </Row>
    );
  }

  distUserPopup() {
    return (
      <div>
        <Row>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "right", paddingLeft: "0px" }}
          >
            <span className="sub-text">User Role:</span>
          </Col>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "left", paddingRight: "0px" }}
          >
            <span className="sub-text">{this.state.userRole}</span>
          </Col>
        </Row>
        <br />
        {this.state.multiAdmin ? this.tldSection() : this.branchSection()}
        <br />
        <Row>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "right", paddingLeft: "0px" }}
          >
            <span className="sub-text">Pricing Access:</span>
          </Col>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "left", paddingRight: "0px" }}
          >
            {this.state.pricingAccess === "yes" ? (
              <span
                className="glyphicon glyphicon-ok-sign"
                style={{ fontSize: "15px", color: "#99b83c" }}
              />
            ) : (
              <span
                className="glyphicon glyphicon-remove-sign"
                style={{ fontSize: "15px", color: "red" }}
              />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "right", paddingLeft: "0px" }}
          >
            <span className="sub-text">Tracking Access:</span>
          </Col>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "left", paddingRight: "0px" }}
          >
            {this.state.trackingAccess === "yes" ? (
              <span
                className="glyphicon glyphicon-ok-sign"
                style={{ fontSize: "15px", color: "#99b83c" }}
              />
            ) : (
              <span
                className="glyphicon glyphicon-remove-sign"
                style={{ fontSize: "15px", color: "red" }}
              />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "right", paddingLeft: "0px" }}
          >
            <span className="sub-text">Branch Visibility:</span>
          </Col>
          <Col
            md={6}
            xs={6}
            sm={6}
            style={{ textAlign: "left", paddingRight: "0px" }}
          >
            {this.state.tldVisibility === "yes" ? (
              <span
                className="glyphicon glyphicon-ok-sign"
                style={{ fontSize: "15px", color: "#99b83c" }}
              />
            ) : (
              <span
                className="glyphicon glyphicon-remove-sign"
                style={{ fontSize: "15px", color: "red" }}
              />
            )}
          </Col>
        </Row>
        <br />
        <br />
        {this.footerMessage()}
      </div>
    );
  }

  branchSection() {
    return (
      <Row>
        <Col
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "right", paddingLeft: "0px" }}
        >
          <span className="sub-text">Branch:</span>
        </Col>
        <Col
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "left", paddingRight: "0px" }}
        >
          <span className="sub-text">{this.state.branchName}</span>
        </Col>
      </Row>
    );
  }

  tldSection() {
    return (
      <Row>
        <Col
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "right", paddingLeft: "0px" }}
        >
          <span className="sub-text">TLD:</span>
        </Col>
        <Col
          md={6}
          xs={6}
          sm={6}
          style={{ textAlign: "left", paddingRight: "0px" }}
        >
          <span className="sub-text">{this.state.tldName}</span>
        </Col>
      </Row>
    );
  }

  footerMessage() {
    if (this.state.userRole === "Branch Admin") {
      return (
        <div style={{ textAlign: "left" }}>
          {this.state.pricingAccess === "no" ? (
            <Row>
              <span className="low-text">
                {DisplayMessages.UserProfile_PricingAdmin}
              </span>
            </Row>
          ) : null}
          {this.state.trackingAccess === "no" ? (
            <Row>
              <span className="low-text">
                {DisplayMessages.UserProfile_TrackingAdmin}
              </span>
            </Row>
          ) : null}
          {this.state.tldVisibility === "no" ? (
            <Row>
              <span className="low-text">
                {DisplayMessages.UserProfile_TLDVisibilityAdmin}
              </span>
            </Row>
          ) : null}
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "left" }}>
          {this.state.pricingAccess === "no" ? (
            <Row>
              <span className="low-text">
                {DisplayMessages.UserProfile_PricingBranch}
              </span>
            </Row>
          ) : null}
          {this.state.trackingAccess === "no" ? (
            <Row>
              <span className="low-text">
                {DisplayMessages.UserProfile_TrackingBranch}
              </span>
            </Row>
          ) : null}
          {this.state.tldVisibility === "no" ? (
            <Row>
              <span className="low-text">
                {DisplayMessages.UserProfile_TLDVisibility}
              </span>
            </Row>
          ) : null}
        </div>
      );
    }
  }

  userInformation() {
    return (
      <div>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            className="glyphicon glyphicon glyphicon-user"
            style={{
              color: "gray",
              border: "0px",
              fontSize: "25px",
              padding: "5px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "5px",
            }}
          >
            <Row>
              <span className="popup-text">
                {this.state.firstName + " " + this.state.lastName}
              </span>
            </Row>
            <Row>
              <span className="sub-text">{this.state.email}</span>
            </Row>
          </div>
        </Row>
        <hr
          className="line-separator"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        />
        {this.userRoleDetails()}
      </div>
    );
  }

  // Mixpanel Tracking //

  trackUserIdentification(data) {
    let branch = "NA";
    let tldName = "Internal";
    if (branchBasedRoles.includes(data.userRole)) {
      branch = data.branchName;
      tldName = data.tldName ? data.tldName : data.trackTldName;
    }
    if (data.userRole === "Salesrep") {
      tldName = data.salesAgency;
    }

    mixpanel.people.set({
      $email: sessionStorage.getItem("username"),
      $name: `${data.firstName} ${data.lastName}`,
      "First Name": data.firstName,
      "Last Name": data.lastName,
      "User Role": data.userRole,
      "Phone Number": data.phoneNumber,
      "Job Title": data.jobTitle,
      Branch: branch,
      TLD: tldName,
    });
  }

  trackWebsiteLogoClick() {
    mixpanel.track("Website Logo", {
      Action: "Image Click",
      Effect: "Redirected to Home Page",
    });
  }

  trackUserProfileClick() {
    mixpanel.track("User Profile", {
      Action: "Button Click",
      Effect: "User Profile Dialog opened",
    });
  }

  trackPortalResourcesClick() {
    mixpanel.track("Portal Resources", {
      Action: "Button Click",
      Effect: "Portal Resources Popup opened",
    });
  }

  trackCartButtonClick() {
    mixpanel.track("Estimate Summary Cart", {
      Action: "Button Click",
      Effect: "Navigate to estimate summary cart",
    });

    mixpanel.track_pageview({
      Page: "Estimate Summary Cart Page",
    });
  }

  trackAutoLogoutConfirmationClick() {
    mixpanel.track("Auto Logout Notification", {
      Action: "Button Click",
      Effect: "Confirmed user auto logout",
    });

    mixpanel.track_pageview({
      Page: "Login Page",
    });
  }
}
export default Header;
