import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Table from "react-bootstrap/lib/Table";
import Pager from "react-bootstrap/lib/Pager";
import Button from "react-bootstrap/lib/Button";
import Swipeable from "react-swipeable";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import FilterWizard1 from "../FilterWizard1/FilterWizard1.js";
import FilterWizard2 from "../FilterWizard2/FilterWizard2.js";
import NamePopup from "../NamePopup/NamePopup.js";
import CompareDataHandler from "./CompareDataHandler.js";
import "./compare.css";
import Product from "../Product/Product.js";
import DistributorsHandler from "../Distributors/DistributorsHandler";
import NoProductsPopup from "./NoProductsPopup.js";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData.js";
import ContentLoading from "../ContentLoading/ContentLoading.js";
import DownloadProgress from "../DownloadPopup/DownloadProgress.js";

const branchBasedRoles = ["Distributor User", "Branch Admin"];
const specialOfferIcon = fetchResourceData(fixedLink.specialOffersIcon);
const hideIcon = fetchResourceData(fixedLink.hideIcon);
const nextIcon = fetchResourceData(fixedLink.nextIcon);
const pinIcon = fetchResourceData(fixedLink.pinIcon);
const blankImg = fetchResourceData(fixedLink.blankIcon);

/**
 * Component represents the compare wizard page
 */
class Compare extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.maxTutorialPages = 10;

    this.state = {
      isLoaded: true,
      productDataList: [],
      productListToDisplay: [],
      hiddenProductList: [],
      selectedLightingSpaces: [],
      selectedApplications: [],
      legends: [],
      selectedLightSource: {},
      watts: "",
      lumen: "",
      minWattage: "",
      selectedProductPostId: "",
      showFilterWizard_1: false,
      showFilterWizard_2: false,
      showCompareTable: true,
      showProductDetails: false,
      COMPARE_PRODUCT_LIMIT: 5,
      showButton: false,
      showExportNamePopup: false,
      showPinNamePopup: false,
      showTutorial: false,
      disableTutorialButton: false,
      tldName: "",
      showNoMatchPopup: false,
      downloadLoader: false,
    };

    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleShowFilterWizard_1 = this.handleShowFilterWizard_1.bind(this);
    this.handleCloseFilterWizard_1 = this.handleCloseFilterWizard_1.bind(this);
    this.handleCloseFilterWizard_2 = this.handleCloseFilterWizard_2.bind(this);
    this.handleGoBackToComparePage = this.handleGoBackToComparePage.bind(this);
    this.filterWizard_1OnNext = this.filterWizard_1OnNext.bind(this);
    this.filterWizard_2OnPrevious = this.filterWizard_2OnPrevious.bind(this);
    this.onSubmitFilters = this.onSubmitFilters.bind(this);
    this.nextProduct = this.nextProduct.bind(this);
    this.previousProduct = this.previousProduct.bind(this);
    this.onApplicationSelectionChanged_FilterWizard1 =
      this.onApplicationSelectionChanged_FilterWizard1.bind(this);
    this.generateTableOnDesktop = this.generateTableOnDesktop.bind(this);
    this.generateTableOnMobile = this.generateTableOnMobile.bind(this);
    this.generateSwipableContentFixedFeatures =
      this.generateSwipableContentFixedFeatures.bind(this);
    this.handleExportResults = this.handleExportResults.bind(this);
    this.hideProduct = this.hideProduct.bind(this);
    this.handleUnhide = this.handleUnhide.bind(this);
    this.handlePinPage = this.handlePinPage.bind(this);
    this.pinProduct = this.pinProduct.bind(this);
    this.handleSubmitNamePopup = this.handleSubmitNamePopup.bind(this);
    this.handleClosePinPopup = this.handleClosePinPopup.bind(this);
    this.handleDiscardPinPopup = this.handleDiscardPinPopup.bind(this);
    this.handleCloseExportPopup = this.handleCloseExportPopup.bind(this);
    this.handleDiscardExportPopup = this.handleDiscardExportPopup.bind(this);
    this.showTutorialOverlay = this.showTutorialOverlay.bind(this);
    this.hideTutorialOverlay = this.hideTutorialOverlay.bind(this);
    this.getOverlayContent = this.getOverlayContent.bind(this);
    this.gotoNextTutorialPage = this.gotoNextTutorialPage.bind(this);
    this.gotoPreviousTutorialPage = this.gotoPreviousTutorialPage.bind(this);
    this.getTutorialOverlay = this.getTutorialOverlay.bind(this);
    this.updateCompareWizardForTutorial =
      this.updateCompareWizardForTutorial.bind(this);
    this.showPrev = this.showPrev.bind(this);
    this.showNext = this.showNext.bind(this);
    this.nextClickOperation = this.nextClickOperation.bind(this);
    this.handleNoMatchPopup = this.handleNoMatchPopup.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.location?.state?.pinned && this._isMounted) {
      let params = this.props.location.state;
      this.setState(
        {
          showFilterWizard_1: false,
          selectedLightingSpaces: params.selectedLightingSpaces,
          selectedApplications: params.selectedApplications,
          selectedLightSource: params.selectedLightSource,
          watts: params.watts,
          lumen: params.lumen,
          minWattage: params.minWattage,
        },
        this.getData
      );
    } else if (this._isMounted) {
      this.setState({
        showFilterWizard_1: true,
      });
    }
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.handleWindowSizeChange();
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    if (branchBasedRoles.includes(userObj["role"])) {
      let username = encodeURIComponent(sessionStorage.getItem("username"));
      DistributorsHandler.getBranchAdminData(username).then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            tldName: response.data.tld.tldName,
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    const isMobileOrTablet = this.detectMobile();
    if (!isMobileOrTablet) {
      return this.renderCompareTable(this.generateTableOnDesktop);
    } else {
      return this.renderCompareTable(this.generateTableOnMobile);
    }
  }
  detectMobile() {
    let userAgent = false;

    if (
      /Android/i.exec(navigator.userAgent) ||
      /webOS/i.exec(navigator.userAgent) ||
      /iPhone/i.exec(navigator.userAgent) ||
      /iPad/i.exec(navigator.userAgent) ||
      /iPod/i.exec(navigator.userAgent) ||
      /BlackBerry/i.exec(navigator.userAgent) ||
      /Windows Phone/i.exec(navigator.userAgent)
    ) {
      userAgent = true;
    }
    return userAgent;
  }
  handleWindowSizeChange = () => {
    let prodCount;
    if (window.innerWidth <= 500 || window.outerWidth <= 500) {
      prodCount = 1;
    } else if (window.innerWidth <= 1160 || window.outerWidth <= 1160) {
      prodCount = 3;
    } else {
      prodCount = 5;
    }

    if (this._isMounted) {
      this.setState(
        {
          COMPARE_PRODUCT_LIMIT: prodCount,
        },
        this.createListToShow
      );
    }
  };
  createListToShow() {
    let hiddenProductIds = this.state.hiddenProductList;
    let productList = this.state.productDataList.filter(function (item) {
      return hiddenProductIds.indexOf(item.id) === -1;
    });
    this.setState({
      productListToDisplay: productList.slice(
        0,
        this.state.COMPARE_PRODUCT_LIMIT
      ),
    });
  }
  renderCompareTable(generateTable) {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    let buttonPanel = this.getButtonPanel();
    let resultPanel =
      this.state.productListToDisplay.length > 0
        ? generateTable()
        : this.compareTableLoadingSection();
    return (
      <div className="compare-container">
        <div className={"compare-table-container " + this.showTable()}>
          <Row>{buttonPanel}</Row>
          <br />
          <Row>{resultPanel}</Row>
          <FilterWizard1
            ref="filterWizard1"
            show={this.state.showFilterWizard_1}
            selectedFilters={{
              selectedLightingSpaces: this.state.selectedLightingSpaces,
              selectedApplications: this.state.selectedApplications,
            }}
            handleOnClose={this.handleCloseFilterWizard_1}
            handleOnNext={this.filterWizard_1OnNext}
            addNotification={this.addNotification}
            handleOnApplicationSelectionChanged={
              this.onApplicationSelectionChanged_FilterWizard1
            }
            handleTutorialClick={this.showTutorialOverlay}
          />
          <FilterWizard2
            ref="filterWizard2"
            show={this.state.showFilterWizard_2}
            selectedFilters={{
              selectedApplications: this.state.selectedApplications,
              selectedLightSource: this.state.selectedLightSource,
              watts: this.state.watts,
              lumen: this.state.lumen,
              minWattage: this.state.minWattage,
            }}
            addNotification={this.addNotification}
            handleOnClose={this.handleCloseFilterWizard_2}
            handleOnPrevious={this.filterWizard_2OnPrevious}
            handleOnSubmitFilters={this.onSubmitFilters}
          />
          <NamePopup
            show={this.state.showExportNamePopup}
            title={DisplayMessages.Compare_ExportResultTitle}
            handleOnClose={this.handleCloseExportPopup}
            handleOnDiscard={this.handleDiscardExportPopup}
            handleOnSubmit={this.handleSubmitNamePopup}
            addNotification={this.addNotification}
          />
          <NamePopup
            show={this.state.showPinNamePopup}
            title={DisplayMessages.Compare_PinPageTitle}
            handleOnClose={this.handleClosePinPopup}
            handleOnDiscard={this.handleDiscardPinPopup}
            handleOnSubmit={this.pinProduct}
            addNotification={this.addNotification}
          />
        </div>
        <div
          className={this.showProductDetails() + " product-detail-container "}
        >
          <Button
            bsStyle="primary"
            className="button-blue glyphicon glyphicon-arrow-left"
            id="back-to-compare-page"
            onClick={this.handleGoBackToComparePage}
          />
          <Product
            postId={this.state.selectedProductPostId}
            applicationType={this.props.applicationType}
          />
        </div>
        {this.getTutorialOverlay()}
        <NoProductsPopup
          show={this.state.showNoMatchPopup}
          handleOnClose={this.handleNoMatchPopup}
          application={this.state.selectedApplications}
        />
        <DownloadProgress show={this.state.downloadLoader} />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }
  getButtonPanel() {
    if (!this.state.showButton) {
      return (
        <div id="button-panel">
          <Button
            className="button-blue"
            bsStyle="primary"
            id="modify-filters-button"
            onClick={this.handleShowFilterWizard_1}
          >
            Modify Filters
          </Button>
        </div>
      );
    } else {
      return (
        <div id="button-panel">
          <Button
            className="button-blue"
            bsStyle="primary"
            id="modify-filters-button"
            onClick={this.handleShowFilterWizard_1}
          >
            Modify Filters
          </Button>
          <Button
            className="button-blue"
            bsStyle="primary"
            id="export-button"
            onClick={this.handleExportResults}
          >
            <span className="glyphicon glyphicon-export" />
            &nbsp;Export
          </Button>
          <Button
            className="button-blue"
            bsStyle="primary"
            id="hide-product-button"
            onClick={this.handleUnhide}
          >
            Unhide All Products
          </Button>
          <Button
            className="button-blue"
            bsStyle="primary"
            id="pin-button"
            onClick={this.handlePinPage}
          >
            <span className="glyphicon glyphicon-pushpin pin-icon" />
          </Button>
        </div>
      );
    }
  }

  compareTableLoadingSection() {
    if (!this.state.isLoaded) {
      return (
        <ContentLoading message={DisplayMessages.Compare_Loading} size={80} />
      );
    } else {
      return (
        <Row className="loading-icon">
          <h4>{DisplayMessages.Compare_NoMatchingProducts}</h4>
        </Row>
      );
    }
  }
  showTable() {
    if (this.state.showCompareTable) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }
  showProductDetails() {
    if (this.state.showProductDetails) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }
  showLegends() {
    if (this.state.productListToDisplay.length <= 0) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }
  generateTableOnDesktop() {
    return (
      <div className="compare-table loading-fade">
        <table>
          <tbody>
            <tr>
              <td className="navigation-cell">
                <Pager>
                  <Pager.Item
                    previous
                    onClick={this.previousProduct}
                    className="list-pager"
                  >
                    <div className="prev-button" />
                  </Pager.Item>
                </Pager>
              </td>
              <td className="table-container">
                <Table
                  id="result-table"
                  className="compare-result-table"
                  striped
                >
                  {this.generateHeader(this.state.productListToDisplay)}
                  {this.generateBody(this.state.productListToDisplay)}
                </Table>
                <div className={this.showLegends() + " table-legends"}>
                  <h5>Legends:</h5>
                  {this.generateLegends(this.state.legends)}
                </div>
              </td>
              <td className="navigation-cell">
                <Pager>
                  <Pager.Item
                    next
                    onClick={this.nextProduct}
                    className="list-pager"
                  >
                    <div className="next-button" />
                  </Pager.Item>
                </Pager>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  generateTableOnMobile() {
    return (
      <div className="compare-table loading-fade">
        {this.generateSwipableContentFixedFeatures(this.state.productDataList)}
        <div className={this.showLegends() + " table-legends"}>
          <h5>Legends:</h5>
          {this.generateLegends(this.state.legends)}
        </div>
      </div>
    );
  }

  getData() {
    if (this._isMounted) {
      this.setState({ showButton: false, isLoaded: false });
    }
    let legends;
    let filter = {
      spaceId: this.state.selectedLightingSpaces.map((item) => item.id).join(),
      applicationId: this.state.selectedApplications
        .map((item) => item.id)
        .join(),
      lightSourceId: this.state.selectedLightSource.id,
      lightSourceWatt: this.state.watts,
      lumen: this.state.lumen,
      wattage: this.state.minWattage,
    };
    CompareDataHandler.getProductsToCompare(filter)
      .then((response) => {
        if (response.success && this._isMounted) {
          legends = response.legends;
          this.setState({
            showNoMatchPopup: response.noProducts.toString() === "true",
          });
        }
        return Promise.all(
          response.productList.map((product) =>
            CompareDataHandler.getFeaturedImage(product)
          )
        );
      })
      .then((productList) => {
        if (this._isMounted) {
          this.setState((prevState) => ({
            productDataList: productList,
            hiddenProductList: [],
            legends: legends,
            showButton: productList && productList.length > 0,
            isLoaded: true,
            productListToDisplay: productList.slice(
              0,
              prevState.COMPARE_PRODUCT_LIMIT
            ),
            disableTutorialButton: false,
          }));
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            isLoaded: true,
            disableTutorialButton: false,
          });
        }
        this.addNotification(ErrorMessages.Compare_GetData, "error");
      });
  }

  generateSwipableContentFixedFeatures(data) {
    return (
      <Swipeable
        onSwipedLeft={this.nextProduct}
        onSwipedRight={this.previousProduct}
      >
        <Table striped>
          {this.generateHeader(this.state.productListToDisplay)}
          {this.generateBody(this.state.productListToDisplay)}
        </Table>
      </Swipeable>
    );
  }
  generateHeader(data) {
    return (
      <thead id="result-table-header">
        <tr>
          <th>
            <Row className="product-image-header">
              <Col md={12} sm={6} xs={6} />
            </Row>
            <Row className="blue-header">
              <Col md={12} sm={6} xs={6}>
                <p className="product-name-header">FEATURES</p>
              </Col>
            </Row>
          </th>
          {data.map((product, i) => this.productHeaderDetails(product, i))}
        </tr>
      </thead>
    );
  }

  productHeaderDetails(product, i) {
    let productImage =
      product.featured_src.length > 0 ? product.featured_src : blankImg;
    return (
      <th key={product.post_id + "#" + product.Name}>
        <Row className="product-image-header">
          <Col md={12} sm={6} xs={6}>
            <div className="raise-image">
              <img
                src={productImage}
                alt="product img"
                className="header-product-image"
                id={product.post_id}
                onClick={(e) => {
                  this.trackProductImageClick(product);
                  this.handleSelectProduct(e.target);
                }}
              />
            </div>
          </Col>
        </Row>
        {this.getProductHeaderRow(
          product.Name,
          product.id,
          product.ClearanceQty
        )}
      </th>
    );
  }

  generateBody(data) {
    if (data.length <= 0) {
      return <tbody>"No matching products."</tbody>;
    } else {
      return (
        <tbody className="compare-table-body">
          {this.productComparisonFeature("Standout Features", data)}
          {this.productComparisonFeature("Watts", data)}
          {this.productComparisonFeature("Lumens", data)}
          {this.productComparisonFeature("Efficacy", data)}
          {this.productComparisonFeature("Voltage", data)}
          {this.productComparisonFeature("CCT", data)}
          {this.productComparisonFeature("CRI", data)}
          {this.productComparisonFeature("Operating Temp", data)}
          {this.productComparisonFeature("Beam Pattern", data)}
          {this.productComparisonFeature("Surge Supression", data)}
          {this.productComparisonFeature("Dimming", data)}
          {this.productComparisonFeature("Standard Lens", data)}
          {this.productComparisonFeature("Finish Options", data)}
          {this.productComparisonFeature("Mounting Options", data)}
          {this.productComparisonFeature("Additional Options", data)}
          {this.productComparisonFeature("Certification", data)}
        </tbody>
      );
    }
  }

  generateLegends(legends) {
    return (
      <Row>
        <ul key={"compare-legends"}>
          {legends.map((legend) => (
            <Col md={3} sm={6} xs={6} key={legend.name}>
              {" "}
              <li key={legend.name}>
                &nbsp;
                <span style={{ fontWeight: "bolder" }}>{legend.name}</span>{" "}
                {" : " + legend.description}
                &nbsp;&nbsp;
              </li>
            </Col>
          ))}
        </ul>
      </Row>
    );
  }

  handleSelectProduct(product) {
    this.setState({
      selectedProductPostId: product.id,
      showCompareTable: false,
      showProductDetails: true,
    });
  }

  handleGoBackToComparePage() {
    this.trackBackToCompareWizardButtonClick();
    this.setState({
      showCompareTable: true,
      showProductDetails: false,
    });
  }

  productComparisonFeature(feature, data) {
    if (data.find((item) => item[feature] !== "N/A")) {
      return (
        <tr>
          <td className="feature-header">{feature}</td>
          {data.map((product) => this.getFeatureForProduct(product, feature))}
        </tr>
      );
    }
  }

  getFeatureForProduct(product, feature) {
    return (
      <td className="feature-value" key={product.id}>
        {product[feature]}
      </td>
    );
  }

  getProductHeaderRow(name, id, clearanceQty) {
    return (
      <Row className="blue-header">
        {parseInt(clearanceQty, 10) > 0 ? (
          <span>
            <img
              src={specialOfferIcon}
              alt="support docs"
              style={{ height: "15px", width: "15px", display: "inline" }}
            />
            <span />
          </span>
        ) : (
          <span />
        )}
        <p className="product-name-header">{name}</p>
        <Button
          className="hide-product-button"
          onClick={() => {
            this.hideProduct(id, name);
          }}
        >
          <span className="glyphicon glyphicon-minus minus-icon" />
        </Button>
      </Row>
    );
  }

  nextProduct() {
    let temp_productListToDisplay = this.state.productListToDisplay.map(
      (item) => item
    );
    if (this.nextProductToView()) {
      temp_productListToDisplay.shift();
      temp_productListToDisplay.push(this.nextProductToView());
    }

    this.trackNextProductButtonClick();

    this.setState({
      productListToDisplay: temp_productListToDisplay,
    });
  }

  nextProductToView() {
    let temp_productListToDisplay = this.state.productListToDisplay.map(
      (item) => item
    );
    let lastProduct = temp_productListToDisplay.pop();
    let hiddenProductIds = this.state.hiddenProductList;
    let productListCopy = this.state.productDataList.filter(function (item) {
      return hiddenProductIds.indexOf(item.id) === -1;
    });
    let productInList = productListCopy.find((item) => {
      let isLastProduct = false;
      if (item.id === lastProduct.id) {
        isLastProduct = true;
      }
      return isLastProduct;
    });
    let index = productListCopy.indexOf(productInList) + 1;
    return productListCopy[index];
  }

  previousProduct() {
    let temp_productListToDisplay = this.state.productListToDisplay.map(
      (item) => item
    );
    if (this.previousProductToView()) {
      temp_productListToDisplay.pop();
      temp_productListToDisplay.unshift(this.previousProductToView());
    }

    this.trackPreviousProductButtonClick();

    this.setState({
      productListToDisplay: temp_productListToDisplay,
    });
  }

  previousProductToView() {
    let temp_productListToDisplay = this.state.productListToDisplay.map(
      (item) => item
    );
    temp_productListToDisplay = temp_productListToDisplay.reverse();
    let firstProduct = temp_productListToDisplay.pop();
    let hiddenProductIds = this.state.hiddenProductList;
    let productListCopy = this.state.productDataList.filter(function (item) {
      return hiddenProductIds.indexOf(item.id) === -1;
    });
    let productInList = productListCopy.find((item) => {
      let isFirstProduct = false;
      if (item.id === firstProduct.id) {
        isFirstProduct = true;
      }
      return isFirstProduct;
    });
    let index = productListCopy.indexOf(productInList) - 1;
    return productListCopy[index];
  }

  handleShowFilterWizard_1() {
    this.trackModifyFiltersButtonClick();
    this.setState({ showFilterWizard_1: true });
  }

  handleCloseFilterWizard_1() {
    this.setState({ showFilterWizard_1: false });
  }

  handleCloseFilterWizard_2() {
    this.setState({ showFilterWizard_2: false });
  }

  filterWizard_1OnNext(selectedFilters) {
    this.setState({
      selectedLightingSpaces: selectedFilters.selectedLightingSpaces,
      selectedApplications: selectedFilters.selectedApplications,
      productDataList: [],
      productListToDisplay: [],
      hiddenProductList: [],
      legends: [],
      showFilterWizard_1: false,
      showFilterWizard_2: true,
    });
  }

  filterWizard_2OnPrevious(selectedFilters) {
    this.setState({
      showFilterWizard_1: true,
      showFilterWizard_2: false,
      productDataList: [],
      productListToDisplay: [],
      hiddenProductList: [],
      legends: [],
      watts: selectedFilters.watts,
      lumen: selectedFilters.lumen,
      minWattage: selectedFilters.minWattage,
      selectedLightSource: selectedFilters.selectedLightSource,
    });
  }

  onApplicationSelectionChanged_FilterWizard1() {
    this.setState({
      watts: "",
      lumen: "",
      minWattage: "",
      selectedLightSource: [],
      productDataList: [],
      productListToDisplay: [],
      hiddenProductList: [],
      legends: [],
    });
  }

  onSubmitFilters(selectedFilters) {
    this.setState(
      {
        selectedLightSource: selectedFilters.selectedLightSource,
        watts: selectedFilters.watts,
        lumen: selectedFilters.lumen,
        minWattage: selectedFilters.minWattage,
        showFilterWizard_2: false,
      },
      this.getData
    );
  }

  handleExportResults() {
    this.trackExportButtonClick();
    this.setState({
      showExportNamePopup: true,
    });
  }

  hideProduct(product_id, product_name) {
    let new_hiddenProductIds = this.state.hiddenProductList.map((item) => item);
    new_hiddenProductIds.push(product_id);
    let productListCopy = this.state.productDataList.filter(function (item) {
      return new_hiddenProductIds.indexOf(item.id) === -1;
    });

    //if only one product don't allow the user to hide
    if (productListCopy.length === 0) {
      this.trackHideProductFailedClick(
        product_name,
        ErrorMessages.Compare_ProductHide
      );
      this.addNotification(ErrorMessages.Compare_ProductHide, "error");
      return;
    }

    //find the first product displayed
    let temp_itemsToDisplay = this.state.productListToDisplay.map(
      (item) => item
    );
    temp_itemsToDisplay = temp_itemsToDisplay.reverse();
    let firstProduct = temp_itemsToDisplay.pop();
    if (firstProduct.id === product_id) {
      firstProduct = temp_itemsToDisplay.pop();
    }
    //find the index of first displayed item in visible items list
    let index = productListCopy.indexOf(firstProduct);

    let new_productList = productListCopy.slice(
      index,
      index + this.state.COMPARE_PRODUCT_LIMIT
    );
    if (
      new_productList.length < this.state.COMPARE_PRODUCT_LIMIT &&
      productListCopy.length >= this.state.COMPARE_PRODUCT_LIMIT
    ) {
      index = index - 1;
      new_productList = productListCopy.slice(
        index,
        index + this.state.COMPARE_PRODUCT_LIMIT
      );
    }
    this.trackHideProductButtonClick(product_name);

    this.setState({
      productListToDisplay: new_productList,
      hiddenProductList: new_hiddenProductIds,
    });
  }

  handleUnhide() {
    let new_productList = [];
    if (this.state.productDataList) {
      new_productList = this.state.productDataList.slice(
        0,
        this.state.COMPARE_PRODUCT_LIMIT
      );
    }
    this.trackUnhideAllProductsButtonClick();
    this.setState({
      productListToDisplay: new_productList,
      hiddenProductList: [],
    });
  }

  handlePinPage() {
    this.trackPinButtonClick();
    this.setState({
      showPinNamePopup: true,
    });
  }

  pinProduct(pinName) {
    const {
      selectedLightingSpaces,
      selectedApplications,
      selectedLightSource,
      watts,
      lumen,
      minWattage,
    } = this.state;
    let jsonData = {
      selectedLightingSpaces: selectedLightingSpaces,
      selectedApplications: selectedApplications,
      selectedLightSource: selectedLightSource,
      watts: watts,
      lumen: lumen,
      minWattage: minWattage,
    };
    const data = {
      title: pinName,
      redirectPage: "Compare",
      parameters: jsonData,
    };
    CompareDataHandler.pinAsFavourite(data)
      .then((response) => {
        if (response.success) {
          this.trackPinPageSubmitClick(pinName);
          this.addNotification(DisplayMessages.Compare_PinnedMsg, "info");
        } else {
          this.trackPinPageFailedClick(pinName, response.message);
          this.addNotification(response.message, "warning");
        }
      })
      .catch((error) => {
        this.trackPinPageFailedClick(pinName, ErrorMessages.Compare_PinnedMsg);
        this.addNotification(ErrorMessages.Compare_PinnedMsg, "error");
      });
  }

  handleSubmitNamePopup(fileName) {
    let requestData = {
      data: this.state.productListToDisplay,
      legends: this.state.legends,
      space: this.state.selectedLightingSpaces
        .map((item) => item.space_name)
        .join(),
      application: this.state.selectedApplications
        .map((item) => item.application_name)
        .join(),
      lightSource: this.state.selectedLightSource.light_source_name,
      lightSourceWatt: this.state.watts,
      lumen: this.state.lumen,
      wattage: this.state.minWattage,
      tldName: this.state.tldName === "" ? "NA" : this.state.tldName,
    };

    this.setState({
      downloadLoader: true,
    });

    this.trackExportResultsSubmitClick(fileName);
    CompareDataHandler.exportCompareWizard(requestData)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        fileName += ".pdf";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (document.getElementById("export-button")) {
          document.getElementById("export-button").blur();
        }

        this.setState({
          downloadLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          downloadLoader: false,
        });
        this.addNotification(ErrorMessages.Compare_ServerError, "error");
      });
  }

  handleDiscardExportPopup() {
    this.trackExportResultsDiscardClick();
    this.handleCloseExportPopup();
  }

  handleCloseExportPopup() {
    this.setState({
      showExportNamePopup: false,
    });
  }

  handleDiscardPinPopup() {
    this.trackPinPageDiscardClick();
    this.handleClosePinPopup();
  }

  handleClosePinPopup() {
    this.setState({
      showPinNamePopup: false,
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  /*  Tutorial overlay related code  */

  async showTutorialOverlay() {
    this.refs.filterWizard1.clearAllFilters();
    this.onApplicationSelectionChanged_FilterWizard1();
    this.setState({
      currentTutorialPage: 1,
    });
    document.getElementById("overlay").style.display = "block";
  }

  async hideTutorialOverlay() {
    this.refs.filterWizard1.clearAllFilters();
    this.onApplicationSelectionChanged_FilterWizard1();
    this.setState({
      showButton: false,
      currentTutorialPage: 1,
      showFilterWizard_1: true,
      showFilterWizard_2: false,
    });
    document.getElementById("overlay").style.display = "none";
  }

  gotoNextTutorialPage() {
    this.setState({ disableTutorialButton: true });
    setTimeout(this.nextClickOperation, 500);
  }

  nextClickOperation() {
    let nextPage =
      this.state.currentTutorialPage + 1 > this.maxTutorialPages
        ? this.state.currentTutorialPage
        : this.state.currentTutorialPage + 1;
    this.updateCompareWizardForTutorial(nextPage);
    this.setState({
      currentTutorialPage: nextPage,
    });
    if (nextPage !== 6) {
      this.setState({
        disableTutorialButton: false,
      });
    }
  }

  gotoPreviousTutorialPage() {
    let nextPage =
      this.state.currentTutorialPage - 1 < 1
        ? 1
        : this.state.currentTutorialPage - 1;
    this.updateCompareWizardForTutorial(nextPage);
    this.setState({
      currentTutorialPage: nextPage,
    });
  }

  updateCompareWizardForTutorial(nextPage) {
    if (nextPage > this.state.currentTutorialPage) {
      switch (nextPage) {
        case 1:
          this.onApplicationSelectionChanged_FilterWizard1();
          break;
        case 2:
          break;
        case 3:
          this.refs.filterWizard1.selectLightingSpaces();
          break;
        case 4:
          this.refs.filterWizard1.selectWallApplicationAndGotoNext();
          break;
        case 5:
          this.refs.filterWizard2.selectMH150Fixture();
          break;
        case 6:
          this.setState({ disableTutorialButton: true });
          this.refs.filterWizard2.handleOnSubmitFilters();
          break;
        case 7:
        case 8:
        case 9:
        case 10:
        default:
          break;
      }
    } else {
      switch (nextPage) {
        case 1:
          this.setState({ disableTutorialButton: false });
          break;
        case 2:
          this.refs.filterWizard1.clearAllFilters();
          this.onApplicationSelectionChanged_FilterWizard1();
          break;
        case 3:
          this.refs.filterWizard2.clearAllFilters();
          this.refs.filterWizard1.deselectApplications();
          break;
        case 4:
          this.refs.filterWizard2.deselectLightSource();
          break;
        case 5:
          this.setState({ disableTutorialButton: false });
          this.refs.filterWizard1.handleOnNext();
          break;
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        default:
          break;
      }
    }
  }

  getTutorialOverlay() {
    return (
      <div id="overlay">
        <Row>
          <Button
            className="closeButtonDesign"
            onClick={this.hideTutorialOverlay}
          >
            X
          </Button>
        </Row>
        <Row>
          <Button
            bsStyle="primary"
            disabled={this.state.disableTutorialButton}
            className={"tutorial-prev-button " + this.showPrev()}
            onClick={this.gotoPreviousTutorialPage}
          >
            {" << Prev "}
          </Button>
          {this.getOverlayContent()}
          <Button
            bsStyle="primary"
            disabled={this.state.disableTutorialButton}
            className={"tutorial-next-button " + this.showNext()}
            onClick={this.gotoNextTutorialPage}
          >
            {" Next >> "}
          </Button>
        </Row>
      </div>
    );
  }

  getOverlayContent() {
    let positionStyle = { position: "fixed", top: "50%", left: "50%" };
    let positionStyle2 = { position: "fixed", top: "50%", left: "50%" };
    let positionStyle3 = { position: "fixed", top: "50%", left: "50%" };

    let element = null,
      elementOffset = null;
    switch (this.state.currentTutorialPage) {
      case 1:
        return (
          <div id="overlay-content" style={positionStyle}>
            <div className="text">
              Imagine that a project requires wall lights to replace 100W MH.
              This wizard will allow you to easily compare{" "}
              {this.props.applicationType} products that fit that need.
            </div>
          </div>
        );
      case 2:
        return (
          <div id="overlay-content" style={positionStyle}>
            <div className="text">
              First, choose a space to light, you can choose both or just one.
            </div>
          </div>
        );
      case 3:
        positionStyle.top = "25%";
        positionStyle.left = "50%";

        positionStyle2.top = "40%";
        positionStyle2.left = "45%";
        return (
          <div>
            <div className="text" style={positionStyle}>
              You can narrow your search to specific applications
            </div>
            <div className="text" style={positionStyle2}>
              We will choose a wall light for the comparison
            </div>
          </div>
        );
      case 4:
        positionStyle.top = "30%";
        positionStyle.left = "15%";
        positionStyle.width = "400px";
        return (
          <div>
            <div className="text" style={positionStyle}>
              You can further narrow your search by answering one of these
              questions
            </div>
          </div>
        );
      case 5:
        return (
          <div id="overlay-content" style={positionStyle}>
            <div className="text">
              We want to find wall lights that replace 100W MH
            </div>
          </div>
        );
      case 6:
        positionStyle.top = "15%";
        element = document.getElementById("button-panel");
        if (element) {
          elementOffset = element.getBoundingClientRect().bottom;
          positionStyle.top = elementOffset;
        }
        return (
          <div id="overlay-content" style={positionStyle}>
            <div className="text">
              Here you have an overview of fixtures that meet your criteria
            </div>
          </div>
        );
      case 7:
        positionStyle.top = "30%";
        positionStyle.left = "10%";
        positionStyle.width = "400px";

        positionStyle2.top = "35%";
        positionStyle2.left = "60%";
        let exportBtn = (
          <img
            height={70}
            width={60}
            src={Miscellaneous.exportIcon}
            alt="pin"
            style={{ display: "inline", margin: "5px" }}
          />
        );
        return (
          <div>
            <div className="text" style={positionStyle}>
              Click on a product to go to its product page
            </div>
            <div className="text" style={positionStyle2}>
              Export the current sets of products onto a PDF by clicking on the{" "}
              {exportBtn} button. (This might take a couple of seconds)
            </div>
          </div>
        );
      case 8:
        positionStyle.top = "30%";
        positionStyle.left = "5%";
        positionStyle.width = "500px";

        positionStyle2.top = "55%";
        positionStyle2.left = "25%";
        positionStyle2.width = "500px";

        positionStyle3.top = "65%";
        positionStyle3.left = "75%";

        let btn1 = (
          <img
            height={25}
            width={25}
            src={hideIcon}
            alt="hide"
            style={{ display: "inline", margin: "5px" }}
          />
        );
        let btn2 = (
          <img
            height={25}
            width={25}
            src={nextIcon}
            alt="next"
            style={{ display: "inline", margin: "5px" }}
          />
        );
        return (
          <div>
            <div className="text" style={positionStyle}>
              Customize your pdf view by hiding products and finding more
              results
            </div>
            <div className="text" style={positionStyle2}>
              You can hide products that don’t fit the project using {btn1}
              button
            </div>
            <div className="text" style={positionStyle3}>
              Click on {btn2} to see more search results
            </div>
          </div>
        );
      case 9:
        positionStyle.top = "35%";
        positionStyle.left = "65%";

        let btn = (
          <img
            height={30}
            width={30}
            src={pinIcon}
            alt="pin"
            style={{ display: "inline", margin: "5px" }}
          />
        );
        return (
          <div>
            <div className="text" style={positionStyle}>
              For a comparison that you use often, you can pin it to your
              homepage using {btn} button so you don’t have to go through the
              previous steps
            </div>
          </div>
        );
      case 10:
        positionStyle.top = "60%";
        element = document.getElementById("result-table-header");
        if (element) {
          elementOffset = element.getBoundingClientRect().bottom + 80;
          positionStyle.top = elementOffset;
        }
        return (
          <div id="overlay-content" style={positionStyle}>
            <div className="text">
              We are constantly updating the wizard whenever new products are
              added, so be on the lookout!
            </div>
            <Button
              bsStyle="primary"
              className="tutorial-complete-button"
              onClick={this.hideTutorialOverlay}
            >
              Complete Guided Tour
            </Button>
          </div>
        );
      default:
        return (
          <div id="overlay-content">
            <Button bsStyle="primary" onClick={this.hideTutorialOverlay}>
              Close
            </Button>
          </div>
        );
    }
  }

  showPrev() {
    return this.state.currentTutorialPage === 1 ? "hide-component" : "";
  }

  showNext() {
    return this.state.currentTutorialPage === this.maxTutorialPages
      ? "hide-component"
      : "";
  }

  handleNoMatchPopup() {
    this.trackNoMatchingProductsAcknowledgementClick();
    this.setState({
      showNoMatchPopup: false,
    });
  }

  /*  Tutorial overlay section end   */

  // Mixpanel Tracking //

  trackPreviousProductButtonClick() {
    mixpanel.track("Show Previous Product", {
      Action: "Button Click",
      Effect: "Showcase previous product",
    });
  }

  trackNextProductButtonClick() {
    mixpanel.track("Show Next Product", {
      Action: "Button Click",
      Effect: "Showcase next product",
    });
  }

  trackHideProductButtonClick(name) {
    mixpanel.track("Hide Product", {
      Action: "Button Click",
      Effect: "Hide product",
      "Product Name": name,
    });
  }

  trackHideProductFailedClick(name, message) {
    mixpanel.track("Hide Product", {
      Action: "Button Click",
      Effect: "Hide product",
      "Product Name": name,
      Message: message,
    });
  }

  trackModifyFiltersButtonClick() {
    mixpanel.track("Modify Filters", {
      Action: "Button Click",
      Effect: "Display filter popup",
    });
  }

  trackUnhideAllProductsButtonClick() {
    mixpanel.track("Unhide All Products", {
      Action: "Button Click",
      Effect: "Show all hidden products",
    });
  }

  trackExportButtonClick() {
    mixpanel.track("Export Compare Wizard", {
      Action: "Button Click",
      Effect: "Current comparison table will be exported",
    });
  }

  trackExportResultsDiscardClick() {
    mixpanel.track("Discard Export Compare Wizard", {
      Action: "Button Click",
      Effect: "Current comparison table will not be exported",
    });
  }

  trackExportResultsSubmitClick(exportName) {
    mixpanel.track("Submit Export Compare Wizard", {
      Action: "Button Click",
      Effect: "Current comparison table is exported",
      "Export Name": exportName,
    });
  }

  trackPinButtonClick() {
    mixpanel.track("Pin Content", {
      Action: "Button Click",
      Effect: "Current comparison table will be pinned",
      Content: "Comparison Table",
    });
  }

  trackPinPageDiscardClick() {
    mixpanel.track("Discard Pin Content", {
      Action: "Button Click",
      Effect: "Current comparison table will not be pinned",
      Content: "Comparison Table",
    });
  }

  trackPinPageSubmitClick(pinName) {
    mixpanel.track("Submit Pin Content", {
      Action: "Button Click",
      Effect: "Current comparison table is pinned",
      Content: "Comparison Table",
      "Pinned Name": pinName,
    });
  }

  trackPinPageFailedClick(pinName, message) {
    mixpanel.track("Submit Pin Content", {
      Action: "Button Click",
      Effect: "Current comparison table is not pinned",
      Content: "Comparison Table",
      "Pinned Name": pinName,
      Message: message,
    });
  }

  trackProductImageClick(product) {
    mixpanel.track("Product Section", {
      Action: "Image Click",
      Effect: "Showcase selected product page",
      "Product ID": product.id,
      "Product Name": product.Name,
    });
  }

  trackBackToCompareWizardButtonClick() {
    mixpanel.track("Back to Compare Wizard", {
      Action: "Button Click",
      Effect: "Go back to comparison table",
    });
  }

  trackNoMatchingProductsAcknowledgementClick() {
    mixpanel.track("No Matching Products", {
      Action: "Button Click",
      Effect: "Acknowledge no matching products exist",
    });
  }
}
export default Compare;
