import React, { Component } from "react";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import ReactTable from "react-table";
import BootstrapTable from "react-bootstrap-table-next";
import SortAsc from "react-icons/lib/fa/sort-asc";
import SortDesc from "react-icons/lib/fa/sort-desc";
import Sort from "react-icons/lib/fa/sort";
import Select from "react-select";
import "react-table/react-table.css";

import "./contentUsageTrend.css";
import ContentUsageTrendDataHandler from "./ContentUsageTrendDataHandler.js";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 125,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

/**
 * Component represents the content usage trends page
 */
class ContentUsageTrend extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      repsList: [],
      dateFilterOptions: [
        "This month",
        "Past 2 Months",
        "This Quarter",
        "This Year",
        "Range",
      ],
      trendTypeList: [
        "Product Page Trend",
        "File Download Trend",
        "File Share Trend",
        "Tableau Trend",
        "Product Configurator Trend",
        "Estimate Summary Trend",
        "Order Tracking Trend",
        "Quick Configurator Trend",
        "All",
      ],
      selectedRep: "",
      selectedRepName: "",
      selectedDateOption: "",
      selectedTrendType: "",
      startDate: "",
      endDate: "",
      showTrendResults: false,
      trendResult: null,
      firsttrendResult: null,
      sorted: [],
      orderSummaryTrend: [],
      orderTrackingTrend: [],
      configuratorTrend: [],
    };
    this.viewTrends = this.viewTrends.bind(this);
    this.export = this.export.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleTrendTypeChange = this.handleTrendTypeChange.bind(this);
    this.handleRepNameChange = this.handleRepNameChange.bind(this);
    this.handleGoBackToForm = this.handleGoBackToForm.bind(this);
    this.handleTrendResponse = this.handleTrendResponse.bind(this);
    this.getSortedComponent = this.getSortedComponent.bind(this);
    this.getDisplayContent = this.getDisplayContent.bind(this);
  }

  componentWillMount() {
    this._isMounted = true;

    ContentUsageTrendDataHandler.getSalesRepNames()
      .then((response) => {
        if (response.success && this._isMounted) {
          let today = new Date();
          let day = today.getDate(),
            month = today.getMonth(),
            year = today.getFullYear();

          let startDateString = `${year}-${("0" + (month + 1)).slice(-2)}-${(
            "0" + 1
          ).slice(-2)}`;
          let endDateString = `${year}-${("0" + (month + 1)).slice(-2)}-${(
            "0" + day
          ).slice(-2)}`;

          this.setState((prevState) => ({
            repsList: response.data,
            selectedRep: "All",
            selectedRepName: "All",
            selectedDateOption: "This month",
            selectedTrendType: prevState.trendTypeList[0],
            startDate: startDateString,
            endDate: endDateString,
          }));
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.ContentUsageTrend_SalesRepNamesResultError,
          "error"
        );
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="content-usage-container">
        <div className={"content-usage-form-container " + this.showForm()}>
          <Col md={3} sm={6}>
            {this.selectSalesRepDropdown()}
            {this.selectDateCriteriaDropdown()}
            {this.selectRangeSection()}
            {this.selectTrendTypeDropdown()}

            <Button
              bsStyle="primary"
              disabled={this.state.repsList.length === 0}
              onClick={this.viewTrends}
              className="button-blue"
            >
              View Trends
            </Button>
          </Col>
        </div>

        <div className={"content-usage-result-container " + this.showResults()}>
          <Col md={1}>
            <Button
              bsStyle="primary"
              className="button-blue glyphicon glyphicon-arrow-left"
              onClick={this.handleGoBackToForm}
            />
          </Col>
          <Col md={10}>{this.generateTrendResultTables()}</Col>
        </div>
      </div>
    );
  }

  selectSalesRepDropdown() {
    return (
      <div>
        <h4>Select a Sales Rep</h4>
        <Select
          maxMenuHeight={190}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={customStyles}
          value={{
            value: this.state.selectedRep,
            label: this.state.selectedRepName,
          }}
          onChange={this.handleRepNameChange}
          options={this.generateNameOptions()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "lightgray",
              primary: "lightgray",
            },
          })}
        />
        <br />
      </div>
    );
  }

  selectDateCriteriaDropdown() {
    return (
      <div>
        <h4>Select Date Criteria</h4>
        <Select
          maxMenuHeight={190}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={customStyles}
          value={{
            value:
              this.state.selectedDateOption !== undefined
                ? this.state.selectedDateOption
                : "Select a date criteria",
            label:
              this.state.selectedDateOption !== undefined
                ? this.state.selectedDateOption
                : "Select a date criteria",
          }}
          onChange={this.handleDateFilterChange}
          options={this.generateDateOptions()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "lightgray",
              primary: "lightgray",
            },
          })}
        />
        <br />
      </div>
    );
  }

  selectTrendTypeDropdown() {
    return (
      <div>
        <h4>Select Trend Type</h4>
        <Select
          maxMenuHeight={190}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={customStyles}
          value={{
            value: this.state.selectedTrendType,
            label: this.state.selectedTrendType,
          }}
          onChange={this.handleTrendTypeChange}
          options={this.generateTrendTypes()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "lightgray",
              primary: "lightgray",
            },
          })}
        />
        <br />
      </div>
    );
  }

  selectRangeSection() {
    return (
      <div
        className={"range-selection-container " + this.showDateRangeSelection()}
      >
        <Form horizontal>
          <FormGroup>
            <Col md={3}>
              {" "}
              <h5>From Date</h5>
            </Col>
            <Col md={9}>
              <input
                type="date"
                placeholder="dd-mm-yyyy"
                className="date-selection"
                style={{
                  width: "90%",
                  height: "32px",
                }}
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
                required
              />
              <span className="validity" />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col md={3}>
              <h5>To Date</h5>
            </Col>
            <Col md={9}>
              <input
                type="date"
                className="date-selection"
                placeholder="dd-mm-yyyy"
                style={{
                  width: "90%",
                  height: "32px",
                }}
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
                required
              />
              <span className="validity" />
            </Col>
          </FormGroup>
        </Form>
        <br />
      </div>
    );
  }
  generateTrendResultTables() {
    if (this.state.trendResult) {
      return (
        <div key={1}>
          <div className="row">
            <div className="col-sm-11">
              <h3>Usage Trend Results</h3>
              <h5>Selected Rep: {this.state.selectedRepName}</h5>
              <h5>
                Selected Date: {this.state.startDate} to {this.state.endDate}
              </h5>
            </div>
            <div className="col-sm-1">
              <Button
                bsStyle="primary"
                onClick={this.export}
                className="button-blue"
              >
                Export
              </Button>
            </div>
          </div>

          <br />
          {this.getDisplayContent()}
          {(this.state.selectedTrendType === "Estimate Summary Trend" ||
            this.state.selectedTrendType === "All") &&
          this.state.orderSummaryTrend
            ? this.getOrderSummaryTable()
            : null}
          {(this.state.selectedTrendType === "Order Tracking Trend" ||
            this.state.selectedTrendType === "All") &&
          this.state.orderTrackingTrend
            ? this.getOrderTrackTable()
            : null}
          {(this.state.selectedTrendType === "Product Configurator Trend" ||
            this.state.selectedTrendType === "All") &&
          this.state.configuratorTrend
            ? this.getProductConfiguratorTable(
                "Product",
                this.state.configuratorTrend
              )
            : null}
          {(this.state.selectedTrendType === "Quick Configurator Trend" ||
            this.state.selectedTrendType === "All") &&
          this.state.quickconfigTrend
            ? this.getProductConfiguratorTable(
                "Quick",
                this.state.quickconfigTrend
              )
            : null}
          {this.state.trendResult
            ? this.state.trendResult.map((item, i) =>
                this.generateTable(item, i)
              )
            : null}
        </div>
      );
    } else {
      return <div>{DisplayMessages.ContentUsageTrend_NoData}</div>;
    }
  }

  export() {
    let filter = {
      userId: this.state.selectedRep,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    ContentUsageTrendDataHandler.exportUsageTrend(filter)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "UsageTrend.xlsx");
        link.ariaLabel = "Export";
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.ContentUsageTrend_ServerError,
          "error"
        );
      });
  }

  getDisplayContent() {
    if (this.state.firsttrendResult && this.state.firsttrendResult.length > 0) {
      return (
        <div>
          <h4>Summary</h4>
          <BootstrapTable
            keyField="id"
            data={this.state.firsttrendResult}
            columns={this.getDisplayContentColumns()}
          />
          <br />
        </div>
      );
    } else {
      return <h4>{DisplayMessages.ContentUsageTrend_NoSummaryData}</h4>;
    }
  }

  generateNameOptions() {
    if (this.state.repsList.length <= 0) {
      let names = [{ value: "0", label: "Loading..." }];
      return names;
    }
    let names = [];
    names.push({
      value: "All#All",
      label: "All",
    });
    this.state.repsList.forEach((item, i) =>
      names.push({
        value: item.user_login + "#" + item.display_name,
        label: item.display_name,
      })
    );
    return names;
  }
  generateDateOptions() {
    return this.state.dateFilterOptions.map((item, i) => ({
      value: item,
      label: item,
    }));
  }
  generateTrendTypes() {
    return this.state.trendTypeList.map((item, i) => ({
      value: item,
      label: item,
    }));
  }
  getProductConfiguratorTable(name, list) {
    if (list.length === 0) {
      return (
        <div>
          <br />
          <h4>
            {name}
            {DisplayMessages.ContentUsageTrend_NoConfigSummaryData}
          </h4>
        </div>
      );
    } else {
      const { getSortedComponent } = this;
      const { sorted } = true;
      return (
        <div key={name}>
          <h4> {name} Configurator Summary Trend</h4>
          <ReactTable
            data={list}
            columns={[
              {
                Header: "Product Name",
                accessor: "entity_name",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
                Cell: (props) =>
                  props.value.charAt(0).toUpperCase() + props.value.substr(1),
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>No. of Accessories &nbsp;&nbsp; {Sorted}</span>;
                },
                id: name + "0",
                accessor: "AccessoryAdded",
                sortable: true,
                headerClassName: "blue-header",
                className: "count-column",
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  a = a ? parseInt(a, 10) : a;
                  b = b ? parseInt(b, 10) : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>No. of Products &nbsp;&nbsp; {Sorted}</span>;
                },
                id: name + "1",
                accessor: "ProductAdded",
                sortable: true,
                headerClassName: "blue-header",
                className: "count-column",
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  a = a ? parseInt(a, 10) : a;
                  b = b ? parseInt(b, 10) : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return (
                    <span>Add to Estimate Summary &nbsp;&nbsp; {Sorted}</span>
                  );
                },
                id: name + "2",
                headerStyle: { boxShadow: "none" },
                accessor: "AddtoSummary",
                headerClassName: "blue-header",
                className: "count-column",
                sortable: true,
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  a = a ? parseInt(a, 10) : a;
                  b = b ? parseInt(b, 10) : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
            ]}
            sorted={sorted}
            onSortedChange={(newSort) => this.setState({ sorted: newSort })}
            showPagination={list > 20}
            minRows={0}
            resizable={false}
            defaultPageSize={20}
            showPageSizeOptions={false}
          />
          <br />
        </div>
      );
    }
  }
  getOrderTrackTable() {
    if (this.state.orderTrackingTrend.length === 0) {
      return (
        <div>
          <br />
          <h4>{DisplayMessages.ContentUsageTrend_NoOrderTrackingData}</h4>
        </div>
      );
    } else {
      const { getSortedComponent } = this;
      const { sorted } = true;
      return (
        <div>
          <h4>Order Tracking View Trend</h4>
          <ReactTable
            data={this.state.orderTrackingTrend}
            columns={[
              {
                Header: "Filter Type",
                accessor: "FilterType",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
                Cell: (props) => {
                  switch (props.value.toUpperCase()) {
                    case "TIMEPERIOD":
                      return "Time Period";
                    case "CUSTOMERPO":
                      return "Customer PO Number";
                    case "CUSTOMERID":
                      return "Customer Name/ID";
                    case "ORDERNUMBER":
                      return "Sales Order Number";
                    case "SALESAGENCYCODE":
                      return "Sales Agency Code";
                    default:
                      return "Filter Type";
                  }
                },
              },
              {
                Header: "Filter Option",
                accessor: "FilterName",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>Count &nbsp;&nbsp; {Sorted}</span>;
                },
                headerStyle: { boxShadow: "none" },
                accessor: "Count",
                headerClassName: "blue-header",
                className: "count-column",
                sortable: true,
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  a = a ? parseInt(a, 10) : a;
                  b = b ? parseInt(b, 10) : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
            ]}
            sorted={sorted}
            onSortedChange={(newSort) => this.setState({ sorted: newSort })}
            minRows={0}
            showPagination={this.state.orderTrackingTrend.length > 20}
            resizable={false}
            defaultPageSize={20}
            showPageSizeOptions={false}
          />
          <br />
        </div>
      );
    }
  }
  getOrderSummaryTable() {
    if (this.state.orderSummaryTrend.length === 0) {
      return (
        <div>
          <br />
          <h4>{DisplayMessages.ContentUsageTrend_NoEstimateSummaryData}</h4>
        </div>
      );
    } else {
      const { getSortedComponent } = this;
      const { sorted } = true;
      return (
        <div>
          <h4>Estimate Summary Trend</h4>
          <ReactTable
            data={this.state.orderSummaryTrend}
            columns={[
              {
                Header: "Customer",
                accessor: "Customer",
                headerClassName: "blue-header",
                className: "count-column",
                sortable: false,
              },
              {
                Header: "Type",
                accessor: "Type",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>Estimate &nbsp;&nbsp; {Sorted}</span>;
                },
                headerStyle: { boxShadow: "none" },
                accessor: "Estimate",
                sortable: true,
                headerClassName: "blue-header",
                className: "count-column",
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  a = a ? parseInt(a, 10) : a;
                  b = b ? parseInt(b, 10) : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>Date &nbsp;&nbsp; {Sorted}</span>;
                },
                headerStyle: { boxShadow: "none" },
                accessor: "Date",
                headerClassName: "blue-header",
                className: "count-column",
                sortable: true,
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
              {
                Header: "StockCode",
                accessor: "StockCode",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
              },
              {
                Header: "Description",
                accessor: "Description",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
                style: { whiteSpace: "unset" },
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>Price &nbsp;&nbsp; {Sorted}</span>;
                },
                accessor: "Price",
                sortable: true,
                headerClassName: "blue-header",
                className: "name-column",
                Cell: (props) =>
                  new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "USD",
                  })
                    .format(props.value)
                    .replace("US", ""),
                sortMethod: (a, b, desc) => {
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  a = a ? parseFloat(a) : a;
                  b = b ? parseFloat(b) : b;
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                },
              },
              {
                Header: "Sales Rep",
                accessor: "UserName",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
              },
              {
                Header: "Quantity",
                headerStyle: { boxShadow: "none" },
                accessor: "Quantity",
                headerClassName: "blue-header",
                className: "count-column",
                sortable: false,
              },
            ]}
            sorted={sorted}
            onSortedChange={(newSort) => this.setState({ sorted: newSort })}
            minRows={0}
            showPagination={this.state.orderSummaryTrend.length > 20}
            resizable={false}
            defaultPageSize={20}
            showPageSizeOptions={false}
          />
          <br />
        </div>
      );
    }
  }

  generateTable(item, key) {
    let trendName, headerName;
    switch (item.activity) {
      case "View":
        trendName = "Product Page Trend";
        headerName = "Product Name";
        break;
      case "Download":
        trendName = "File Download Trend";
        headerName = "File Name";
        break;
      case "Share":
        trendName = "File Share Trend";
        headerName = "File Name";
        break;
      case "Tableau":
        trendName = "Tableau Trend";
        headerName = "Dashboard Name";
        break;
      case "Configurator":
        trendName = "Product Configurator Trends";
        headerName = "Product Name";
        break;
      case "QuickConfigurator":
        trendName = "Quick Configurator Trends";
        headerName = "Product Name";
        break;
      case "OrderTrack":
        trendName = "Tracking Trends";
        headerName = "Customer Name";
        break;
      default:
        trendName = "";
        headerName = "";
        break;
    }
    if (item.data.length === 0) {
      return (
        <div>
          <br />
          <h4>
            {trendName} - {DisplayMessages.ContentUsageTrend_NoData}
          </h4>
        </div>
      );
    } else {
      const { getSortedComponent } = this;
      const { sorted } = true;
      return (
        <div key={key}>
          <h4>{trendName}</h4>
          <ReactTable
            data={item.data}
            columns={[
              {
                Header: headerName,
                accessor: "entity_name",
                sortable: false,
                headerClassName: "blue-header",
                className: "name-column",
              },
              {
                Header: (props) => {
                  const Sorted = getSortedComponent(props.column.id);
                  return <span>Usage Count &nbsp;&nbsp; {Sorted}</span>;
                },
                id: trendName,
                headerStyle: { boxShadow: "none" },
                accessor: "count",
                headerClassName: "blue-header",
                className: "count-column",
                sortable: true,
                sortMethod: (a, b, desc) => {
                  // force null and undefined to the bottom
                  a = a === null || a === undefined ? -Infinity : a;
                  b = b === null || b === undefined ? -Infinity : b;
                  // force any values to integer
                  a = a ? parseInt(a, 10) : a;
                  b = b ? parseInt(b, 10) : b;
                  // Return either 1 or -1 to indicate a sort priority
                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  // returning 0, undefined or any falsy value will use subsequent sorts or the index as a tiebreaker
                  return 0;
                },
              },
            ]}
            sorted={sorted}
            onSortedChange={(newSort) => this.setState({ sorted: newSort })}
            minRows={0}
            showPagination={this.showPagination(item.activity)}
            resizable={false}
            defaultPageSize={20}
            showPageSizeOptions={false}
          />
          <br />
        </div>
      );
    }
  }
  getSortedComponent(id) {
    let sortInfo = this.state.sorted.filter((item) => item.id === id);
    if (sortInfo.length) {
      if (sortInfo[0].desc) {
        return <SortDesc />;
      } else {
        return <SortAsc />;
      }
    }
    return <Sort />;
  }
  showDateRangeSelection() {
    if (this.state.selectedDateOption !== "Range") {
      return "hide-component";
    } else {
      return "show-component";
    }
  }
  showForm() {
    if (this.state.showTrendResults) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }
  showResults() {
    if (!this.state.showTrendResults) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }
  showPagination(activity) {
    let trendItem = this.state.trendResult.find(
      (item) => item.activity === activity
    );
    if (trendItem) {
      return trendItem.data.length > 20;
    } else {
      return false;
    }
  }
  getQuarterStartMonth(currentMonth) {
    let sMonth;
    if (currentMonth >= 0 && currentMonth <= 2) {
      sMonth = 0;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      sMonth = 3;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      sMonth = 6;
    } else if (currentMonth >= 9 && currentMonth <= 11) {
      sMonth = 9;
    }
    return sMonth;
  }
  handleDateFilterChange(e) {
    let today = new Date();
    let sDate = today;
    let eDate = today;
    let selected = e.value;
    let day = today.getDate(),
      month = today.getMonth(),
      year = today.getFullYear();

    if (selected === "Range") {
      this.setState({
        selectedDateOption: selected,
        startDate: "",
        endDate: "",
      });
    } else {
      if (selected === "This month") {
        sDate = new Date(year, month, 1);
      } else if (selected === "Past 2 Months") {
        sDate = new Date(year, month - 2, 1);
        eDate = new Date(year, month, 0);
      } else if (selected === "This Quarter") {
        sDate = new Date(year, this.getQuarterStartMonth(month), 1);
      } else if (selected === "This Year") {
        sDate = new Date(year, 0, 1);
      } else if (selected === "Range") {
        sDate = new Date(year, month, day - 5);
      }

      let startDateString = `${sDate.getFullYear()}-${(
        "0" +
        (sDate.getMonth() + 1)
      ).slice(-2)}-${("0" + sDate.getDate()).slice(-2)}`;

      let endDateString = `${eDate.getFullYear()}-${(
        "0" +
        (eDate.getMonth() + 1)
      ).slice(-2)}-${("0" + eDate.getDate()).slice(-2)}`;

      this.setState({
        selectedDateOption: selected,
        startDate: startDateString,
        endDate: endDateString,
      });
    }
  }
  handleStartDateChange(e) {
    this.setState({
      startDate: e.target.value,
    });
  }
  handleEndDateChange(e) {
    this.setState({
      endDate: e.target.value,
    });
  }
  handleTrendTypeChange(e) {
    this.setState({
      selectedTrendType: e.value,
    });
  }
  handleRepNameChange(e) {
    let namelist = e.value.split("#");
    this.setState({
      selectedRep: namelist[0],
      selectedRepName: namelist[1],
    });
  }

  handleGoBackToForm() {
    this.setState({
      showTrendResults: false,
    });
  }
  handleTrendResponse(response) {
    this.setState({
      firsttrendResult: response.data.list,
      trendResult: response.data.list1,
      orderSummaryTrend: response.data.ordersummarytrend,
      orderTrackingTrend: response.data.ordertracktrend,
      configuratorTrend: response.data.configuratortrend,
      quickconfigTrend: response.data.quickconfigtrend,
      showTrendResults: true,
    });
  }

  getActivity() {
    switch (this.state.selectedTrendType) {
      case "Product Page Trend":
        return "View";
      case "File Download Trend":
        return "Download";
      case "File Share Trend":
        return "Share";
      case "Tableau Trend":
        return "Tableau";
      case "Order Tracking Trend":
        return "OrderTrack";
      case "Estimate Summary Trend":
        return "AddtoSummary";
      case "Product Configurator Trend":
        return "Configurator";
      case "Quick Configurator Trend":
        return "QuickConfigurator";
      case "All":
        return "All";
      default:
        return "All";
    }
  }

  viewTrends() {
    if (
      this.isValidDate(this.state.startDate) &&
      this.isValidDate(this.state.endDate)
    ) {
      let filter = {
        activity: this.getActivity(),
        userId: this.state.selectedRep,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      ContentUsageTrendDataHandler.getTrendResults(filter)
        .then((response) => {
          if (response.success) {
            this.handleTrendResponse(response);
          } else {
            this.props.addNotification(response.message, "error");
          }
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.ContentUsageTrend_TrendResultsError,
            "error"
          );
        });
    } else {
      this.props.addNotification(
        ErrorMessages.ContentUsageTrend_InvalidDate,
        "error"
      );
    }
  }

  isValidDate(d) {
    let isDateInvalid = true;
    if (isNaN(Date.parse(d))) {
      isDateInvalid = false;
    }
    return isDateInvalid;
  }

  getDisplayContentColumns() {
    const columns = [
      {
        dataField: "userName",
        text: "User",
        sort: true,
        style: { border: "1px solid black", textAlign: "left" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "logins",
        text: "Logins",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "views",
        text: "Page Views",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "downloads",
        text: "Downloads",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "shares",
        text: "File Shares",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "comparisonWizards",
        text: "Comparison Wizard",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "certificationTools",
        text: "Certification Tool",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "gallery",
        text: "Installation Gallery",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "imageUploads",
        text: "Image Uploads",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "pin",
        text: "Pin",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "summaryPin",
        text: "Estimate Summary Pins",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "summaryDownload",
        text: "Estimate Summary Downloads",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "summaryQuote",
        text: "Estimate Summary Quotes",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "bounceRate",
        text: "Bounce Rate",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
      {
        dataField: "searchInventory",
        text: "Inventory Search",
        sort: true,
        style: { border: "1px solid black", textAlign: "center" },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          width: "auto",
          border: "1px solid black",
        },
      },
    ];

    return columns;
  }
}

export default ContentUsageTrend;
