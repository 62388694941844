import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get customer details
 * Get Package details
 * Get table details
 * Post track clicks
 * Get backorder details
 * Get total order details
 * Get courier list
 */
const OrderTrackHandler = (function () {
  return {
    getCustomerDetails: function () {
      return new Promise((resolve, reject) => {
        let route = `OrderTrack/FilterOptions`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getPackageDetails: function (salesorder, dispatchnote) {
      return new Promise((resolve, reject) => {
        let route = `OrderTrack/PackageDetails?salesorder=${salesorder}&dispatchnote=${dispatchnote}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTableDetails: function (filter) {
      return new Promise((resolve, reject) => {
        let route =
          `OrderTrack/TableData?filterType=${
            filter.filtertype
          }&filterOption=${encodeURIComponent(filter.filterOption)}` +
          `&startDate=${filter.startDate}&endDate=${filter.endDate}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    postTrackClicks: function (customer) {
      return new Promise((resolve, reject) => {
        let route = `OrderTrack/TrackClick?customer=${customer}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBackOrderDetails: function (salesorder) {
      return new Promise((resolve, reject) => {
        let route = `OrderTrack/BackOrderDetails?salesorder=${salesorder}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTotalOrderDetails: function (salesorder) {
      return new Promise((resolve, reject) => {
        let route = `OrderTrack/AllOrders?salesorder=${salesorder}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCourierList: function () {
      return new Promise((resolve, reject) => {
        let route = `OrderTrack/CourierList`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default OrderTrackHandler;
