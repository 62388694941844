import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { LinkContainer } from "react-router-bootstrap";
import mixpanel from "mixpanel-browser";

import fixedLink from "../Assets/fixedAssets.json";
import { fetchResourceData } from "../Utils/FetchConfigurableData";
import "./login.css";

const appLogo = fetchResourceData(fixedLink.appLogo);

/**
 * Component represents the Login page
 */
class LogIn extends Component {
  constructor(props) {
    super(props);

    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      username: "",
      password: "",
      logo: appLogo,
    };
  }

  componentDidMount() {
    sessionStorage.setItem("Time", "");
  }

  render() {
    return (
      <div className="login-container">
        <br />
        <br />
        <br />

        <img src={this.state.logo} alt="logo" height="175px" width="175px" />
        <br />
        <br />
        <div onKeyDown={this.handleKeyPress} role="presentation">
          <Row className="center-container">
            <Col md={4} sm={4} xs={4} style={{ minWidth: "300px" }}>
              <FormGroup controlId="user-name" className="username">
                <FormControl
                  type="text"
                  value={this.state.username}
                  placeholder="Email / Username"
                  onChange={this.handleUsernameChange}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={4} xs={4} style={{ minWidth: "300px" }}>
              <FormGroup controlId="password" className="password">
                <FormControl
                  type="password"
                  value={this.state.password}
                  placeholder="Password"
                  onChange={this.handlePasswordChange}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <center>
                <Button
                  id="login"
                  bsStyle="primary"
                  className="button-blue"
                  onClick={this.handleLogIn}
                >
                  Login
                </Button>
              </center>
            </Col>
            <br />
            <Col md={4} sm={4} xs={4} style={{ minWidth: "300px" }}>
              <LinkContainer to={{ pathname: "/ForgotPassword" }}>
                <a
                  href="/ForgotPassword"
                  onClick={this.trackForgotPasswordButtonClick}
                >
                  Forgot Password
                </a>
              </LinkContainer>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  handleLogIn() {
    sessionStorage.setItem("username", this.state.username);
    const credentials = {
      userName: this.state.username,
      password: this.state.password,
    };

    this.props.handleLogIn(credentials);
  }

  handleUsernameChange(e) {
    this.setState({
      username: e.target.value,
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleKeyPress(target) {
    if (target.keyCode === 13) {
      target.preventDefault();
      this.handleLogIn(target);
    }
  }

  // Mixpanel Tracking //

  trackForgotPasswordButtonClick() {
    mixpanel.track("Forgot Password", {
      Action: "Button Click",
      Effect: "Navigate to forgot password page",
    });
    mixpanel.track_pageview({
      Page: "Forgot Password Page",
    });
  }
}
export default LogIn;
