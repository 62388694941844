import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";

import "./downloadPopup.css";
import { ErrorMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the download popup
 */
class DownloadPopup extends Component {
  constructor(props) {
    super(props);

    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.handleIcons = this.handleIcons.bind(this);

    this.state = {
      name: "",
      hasPrice: true,
      hasNotes: false,
      hasIcons: false,
      format: "",
    };
  }

  componentWillReceiveProps(newProp) {
    this.setState({ format: newProp.format });
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                value={this.state.name}
                placeholder="Enter Name"
                onChange={this.handleNameChange}
                onKeyDown={this.handleKeyPress}
                maxLength="100"
              />
              <FormControl.Feedback />
            </FormGroup>
            <Row>
              <Col md={4} sm={4} xs={4}>
                <span>Include Price </span>
                <label
                  className="switch"
                  onChange={this.handlePrice}
                  htmlFor="toggle-has-price"
                >
                  <input
                    id="toggle-has-price"
                    type="checkbox"
                    value={this.state.hasPrice}
                    defaultChecked
                  />
                  <span className="slider"/>
                </label>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <span>Include Notes </span>
                <label
                  className="switch"
                  onChange={this.handleNotes}
                  htmlFor="toggle-has-notes"
                >
                  <input
                    id="toggle-has-notes"
                    type="checkbox"
                    value={this.state.hasNotes}
                  />
                  <span className="slider"/>
                </label>
              </Col>
              <Col md={4} sm={4} xs={4} hidden={this.state.format === "Excel"}>
                <span>Include Icons </span>
                <label
                  className="switch"
                  onChange={this.handleIcons}
                  htmlFor="toggle-has-icons"
                >
                  <input
                    id="toggle-has-icons"
                    type="checkbox"
                    value={this.state.hasIcons}
                  />
                  <span className="slider"/>
                </label>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleDiscard}>Discard</Button>
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleOnClose() {
    this.setState({
      name: "",
    });
    this.props.handleOnClose();
  }
  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleDiscard() {
    this.handleOnClose();
  }

  handlePrice() {
    this.setState((prevState) => ({
      hasPrice: !prevState.hasPrice,
    }));
  }
  handleIcons() {
    this.setState((prevState) => ({
      hasIcons: !prevState.hasIcons,
    }));
  }
  handleNotes() {
    this.setState((prevState) => ({
      hasNotes: !prevState.hasNotes,
    }));
  }
  handleSubmit() {
    if (!this.state.name || this.state.name === "") {
      this.props.addNotification(
        ErrorMessages.DownloadPopup_ValidName,
        "error"
      );
      return;
    } else if (this.state.name.length > 100) {
      this.props.addNotification(ErrorMessages.DownloadPopup_MaxLimit, "error");
      return;
    }
    this.props.handleOnSubmit(
      this.state.name,
      this.state.hasPrice,
      this.state.hasNotes,
      this.state.hasIcons
    );
    this.setState({
      hasNotes: false,
      hasPrice: true,
      hasIcons: false,
    });
    this.handleOnClose();
  }
  handleKeyPress(target) {
    if (target.keyCode === 13) {
      this.handleSubmit();
    }
  }
}

export default DownloadPopup;
