import Api from "../Utils/api";
/**
 * API Calls to handle:
 * Upload adders sheet document
 * Upload Naming convention document
 * Upload customer mapping document
 */
const ManageProductConfiguratorHandler = (function () {
  return {
    postAddersDocument: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(`ManageProductConfigurator/UploadAddersSheet`, form)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    postNamingDocument: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(
          `ManageProductConfigurator/UploadNamingConventionDocument`,
          form
        )
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    postCustomerMapping: function (form) {
      return new Promise((resolve, reject) => {
        Api.post(`ManageProductConfigurator/UploadCustomerMapping`, form)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ManageProductConfiguratorHandler;
