import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Panel from "react-bootstrap/lib/Panel";
import Spinner from "react-loading";
import { Button } from "react-bootstrap";
import FormControl from "react-bootstrap/lib/FormControl";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler";
import OrderSummaryHandler from "./OrderSummaryHandler.js";

import "./orderSummary.css";
import Disclaimer from "../Disclaimer/Disclaimer";
import DownloadCartPopup from "./DownloadCartPopup";
import fixedLink from "../Assets/fixedAssets.json";
import {
  ErrorMessages,
  fetchResourceData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import DownloadProgress from "../DownloadPopup/DownloadProgress.js";

const blankImg = fetchResourceData(fixedLink.blankIcon);

let listOfRecords = [];
let totalprice = 0.0;

const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the Pinned Estimate Summary page
 */
class PinnedOrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderSummaryData: [],
      showPinNamePopup: false,
      isFormalQuoteClicked: false,
      projectOrStockingType: "",
      customerName: "",
      projectName: "",
      isClearButtonClicked: false,
      allFieldFilled: false,
      body: "",
      branch: "",
      status: false,
      last_updated: "",
      gotResponse: false,
      isViewClicked: false,
      stockdetails: [],
      format: "",
      extension: "",
      pricingAccess: true,
      goldenPriceAccess: true,
      name: "",
      date: "",
      email: "",
      customer: "",
      customerInfo: "",
      shippingAddress: "",
      orderDetails: "",
      currentRole: "",
      downloadLoader: false,
      GoldenLegend: false,
      showExportNamePopup: false,
      enableDownload: false,
      showSpecLinkPopup: false,
      specLinkPopupStockCode: "",
      specLinkPopupDescription: "",
      specValue: "",
      frSpecLink: "",
      engSpecLink: "",
      itemCategory: "accessories",
    };
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.downloadOrderSummary = this.downloadOrderSummary.bind(this);
    this.handleCloseNamePopup = this.handleCloseNamePopup.bind(this);
    this.renderReactTable = this.renderReactTable.bind(this);
    this.handleSubmitButtonDownload =
      this.handleSubmitButtonDownload.bind(this);
    this.getAllDetails = this.getAllDetails.bind(this);
    this.CalculateTotalPrice = this.CalculateTotalPrice.bind(this);
    this.viewButtonClicked = this.viewButtonClicked.bind(this);
    this.getPinnedProducts = this.getPinnedProducts.bind(this);
    this.assignPhotoId = this.assignPhotoId.bind(this);
    this.showSpecLink = this.showSpecLink.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
  }
  componentDidMount() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    if (this.props.location?.state?.pinned) {
      let params = this.props.location.state;

      let updatedOrderSummary = params.orderSummaryData.map((item) => {
        return {
          ...item,
          Price: item.Price.toString(),
        };
      });

      OrderSummaryHandler.getPinnedSummaryTable(updatedOrderSummary)
        .then((response) => {
          this.setState(
            {
              last_updated: response.data.lastUpdatedTime,
              orderSummaryData: response.data.list.map((item) => {
                if (JSON.parse(sessionStorage.getItem("goldenPriceAccess"))) {
                  return item;
                } else {
                  return { ...item, GoldenPrice: "0" };
                }
              }),
              status: false,
              gotResponse: true,
              currentRole: userObj["role"],
              name: params.name,
              date: params.date,
              email: params.email,
              customer: params.customer,
              customerInfo: params.customerInfo,
              notesText: params.notesText,
              marginPercent: params.marginPercent,
              shippingAddress: params.shippingAddress,
              orderDetails: params.orderDetails,
              pricingAccess: JSON.parse(
                sessionStorage.getItem("pricingAccess")
              ),
              goldenPriceAccess: JSON.parse(
                sessionStorage.getItem("goldenPriceAccess")
              ),
            },
            () => {
              this.getAllDetails();
            }
          );
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.PinnedOrderSummary_PinnedSummaryTable,
            "error"
          );
        });
    }

    window.scrollTo(0, 0);
  }

  hideForDistributorUser() {
    let isHidden = false;
    if (
      !this.state.pricingAccess &&
      branchBasedRoles.includes(this.state.currentRole)
    ) {
      isHidden = true;
    }
    return isHidden;
  }

  getPinnedProducts(list) {
    let productIds = list.map((item) => {
      return item.ProductId;
    });
    OrderSummaryHandler.getPinnedProducts(productIds)
      .then((response) => {
        this.assignPhotoId(response.data);
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_GetImage, "error");
      });
  }

  assignPhotoId(productList) {
    let tempList = this.state.orderSummaryData;
    if (tempList.length > 0) {
      tempList.forEach((element, index) => {
        if (element.ProductId === "NA") {
          element.url = blankImg;
        } else if (element.PhotoID !== "" && element.PhotoID !== undefined) {
          ProductConfiguratorHandler.getAccessoriesImages(element.PhotoID).then(
            (imagedetails) => {
              element.url = imagedetails[0].src;
              this.setState({ orderSummaryData: tempList });
            }
          );
        } else {
          let prod = productList.find(
            (item) => item.id === parseInt(element.ProductId, 10)
          );
          element.url = prod.featured_src;
        }
        this.setState({ orderSummaryData: tempList });
      });
    }
  }

  getAllDetails() {
    listOfRecords = this.state.orderSummaryData;

    this.getPinnedProducts(listOfRecords);

    let goldenAvailable = false;
    let customer = "";

    listOfRecords.forEach((element) => {
      customer = element.CustomerName;
      let salePrice1 = +parseFloat(element.SalePrice);
      let price1 = +parseFloat(element.Price);
      let goldenPrice1 = +parseFloat(element.GoldenPrice);
      let adderPrice1 = +parseFloat(element.AdderPrice);
      let quantity1 = +parseFloat(element.Quantity);
      let baseQuantity1 = +parseFloat(element.BaseQuantity);
      let marginAdjustment1 = +parseFloat(element.MarginAdjustment);

      let price = salePrice1 === 0 ? price1 : salePrice1;

      if (goldenPrice1 !== 0) {
        if (salePrice1 === 0 || goldenPrice1 <= salePrice1) {
          price = goldenPrice1;
          goldenAvailable = true;
          element.Price = goldenPrice1;
        }
      }

      element.CalculatedPrice = parseFloat(
        (parseFloat(price, 3) * quantity1) / (1 - marginAdjustment1 / 100),
        2
      ).toFixed(2);

      element.WarehouseFee = (
        parseFloat(parseInt(quantity1, 10) % parseInt(baseQuantity1, 10)) *
        adderPrice1
      ).toFixed(2);

      element.LeadTime = Miscellaneous.LeadTimeUnavailable;
      element.StockCode = Miscellaneous.StockCodeUnavailable;
      element.PartCategory = "";
      let flag = 0;

      if (element.stockDetails.length > 0) {
        let stockDetails = element.stockDetails.sort(
          (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
        );
        stockDetails.forEach((item) => {
          element.StockCode = item.StockCode;
          if (
            parseInt(quantity1, 10) <= parseInt(item.QtyAvailable, 10) &&
            flag === 0
          ) {
            flag = 1;
            element.LeadTime = item.LeadTime;
            element.PartCategory = item.PartCategory;
          }
        });
      }

      let calculatedPrice1 = +parseFloat(element.CalculatedPrice);

      element.CalculatedPrice = (Math.ceil(calculatedPrice1 * 20) / 20).toFixed(
        2
      );

      element.unitCalculatedPrice = parseFloat(
        (parseFloat(price, 3) * 1) / (1 - marginAdjustment1 / 100),
        2
      ).toFixed(2);

      let unitCalculatedPrice1 = +parseFloat(element.unitCalculatedPrice);

      element.unitCalculatedPrice = (
        Math.ceil(unitCalculatedPrice1 * 20) / 20
      ).toFixed(2);

      element.oldPrice = parseFloat(
        (parseFloat(element.Price, 3) * quantity1) /
          (1 - marginAdjustment1 / 100),
        2
      ).toFixed(2);

      element.oldPrice = (Math.ceil(element.oldPrice * 20) / 20).toFixed(2);
      element.unitOldPrice = parseFloat(
        (parseFloat(element.Price, 3) * 1) / (1 - marginAdjustment1 / 100),
        2
      ).toFixed(2);

      let unitOldPrice1 = +parseFloat(element.unitOldPrice);

      element.unitOldPrice = (Math.ceil(unitOldPrice1 * 20) / 20).toFixed(2);

      element.ViewClicked = false;
    });

    this.setState({
      orderSummaryData: listOfRecords,
      customerName: customer,
      GoldenLegend: goldenAvailable,
    });
  }

  customerInfoDisplay() {
    return (
      <Col md={6} sm={6} xs={12}>
        <div style={{ fontSize: "14px" }}>
          <Row>
            <Col md={2}>
              <strong>Date:</strong>
            </Col>
            <Col md={10}>{this.state.date}</Col>
          </Row>
          <br />
          <Row>
            <Col md={2}>
              <strong>Name:</strong>
            </Col>
            <Col md={10}>{this.state.name}</Col>
          </Row>
          <br />
          <Row>
            <Col md={2}>
              <strong>Email:</strong>
            </Col>
            <Col md={10}>{this.state.email}</Col>
          </Row>
          <br />
          <Row>
            <Col md={2}>
              <strong>{this.state.customer}</strong>
            </Col>
            <Col md={10}>{this.state.customerInfo}</Col>
          </Row>
        </div>
        <br />
      </Col>
    );
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div style={{ minWidth: "300px" }} className="estimate-page-container">
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
        <DownloadCartPopup
          show={this.state.showExportNamePopup}
          handleOnClose={this.handleCloseNamePopup}
          handleOnSubmit={this.handleSubmitButtonDownload}
          addNotification={this.addNotification}
        />
        <DownloadProgress show={this.state.downloadLoader} />
        {this.state.gotResponse ? this.showPinnedOrderSummaryCart() : null}
      </div>
    );
  }

  showPinnedOrderSummaryCart() {
    if (this.state.orderSummaryData.length > 0) {
      return (
        <div>
          <Row>
            {this.customerInfoDisplay()}
            <Col md={6} sm={6} xs={12}>
              {this.state.orderSummaryData.length > 0 ? (
                <div className="bottom-column">
                  <Button
                    bsStyle="primary"
                    className="button-blue"
                    style={{
                      width: "100%",
                    }}
                    onClick={this.downloadOrderSummary}
                    disabled={!this.state.enableDownload}
                  >
                    Download
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
          {this.renderShippingDetailsTable()}
          <Row>
            <Col md={12} sm={12} style={{ marginTop: "50px" }}>
              {this.renderReactTable()}
              <div>{this.goldenLegend()}</div>
            </Col>
            <Col md={12} sm={12} style={{ marginTop: "10px" }}>
              <Panel eventKey={1} key="notes" defaultExpanded>
                <Panel.Heading>
                  <Panel.Title>
                    <span className="glyphicon glyphicon-pencil" />
                    Additional Notes
                  </Panel.Title>
                </Panel.Heading>
                <FormControl
                  componentClass="textarea"
                  disabled
                  style={{ resize: "none", borderRadius: "0" }}
                  className={"disabled-input"}
                  rows="3"
                  value={this.state.notesText}
                  spellCheck="false"
                />
              </Panel>
            </Col>
          </Row>
          <Row style={{ marginTop: "0px", marginBottom: "0px" }}>
            <Col md={12} sm={12}>
              <Disclaimer
                last_updated={this.state.last_updated}
                type="OrderSummary"
              />
            </Col>
          </Row>
        </div>
      );
    } else return <h4 className="text-center">No data to display</h4>;
  }

  goldenLegend() {
    if (
      this.state.orderSummaryData.length > 0 &&
      this.state.GoldenLegend &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <Row>
          <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> = Your
          Special Pricing
        </Row>
      );
    } else {
      return <br />;
    }
  }

  renderShippingDetailsTable() {
    return (
      <Row className="shipping-details-table-pinned">
        <Col
          md={6}
          sm={6}
          xs={12}
          className="col-border"
          style={{ paddingBottom: "10px" }}
        >
          <div>
            <Row className="shipping-details-heading">Shipping Details</Row>
            <Row>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Shipping Address
              </span>
            </Row>
            <Row>{this.state.shippingAddress}</Row>
          </div>
        </Col>
        <Col
          md={6}
          sm={6}
          xs={12}
          style={{ marginLeft: "20px", paddingBottom: "10px" }}
        >
          <div>
            <Row className="shipping-details-heading">Order Details</Row>
            <Row>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Order Type
              </span>
            </Row>
            <Row>
              {this.state.orderDetails.orderType === ""
                ? "NA"
                : this.state.orderDetails.orderType}
            </Row>
            <br />
            {this.state.orderDetails.orderType !== "" ? (
              <div>
                {this.state.orderDetails.orderType === "Project" ? (
                  <div>
                    <Row>
                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Project Name
                      </span>
                    </Row>
                    <Row>{this.state.orderDetails.projectName}</Row>
                    <br />
                    {this.state.orderDetails.desiredShipDate !== "" &&
                    this.state.orderDetails.desiredShipDate !== undefined ? (
                      <div>
                        <Row>
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Desired Ship Date
                          </span>
                        </Row>
                        <Row>{this.state.orderDetails.desiredShipDate}</Row>
                        <br />
                        <Row>
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Quote Number
                          </span>
                        </Row>
                        <Row>{this.state.orderDetails.quoteNumber}</Row>
                        <br />
                      </div>
                    ) : null}
                    <Row>
                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Contractor
                      </span>
                    </Row>
                    <Row>{this.state.orderDetails.contractor}</Row>
                    <br />
                    <Row>
                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Spec Available?
                      </span>
                    </Row>
                    <Row>{this.state.orderDetails.specAvailable}</Row>
                    <br />
                  </div>
                ) : (
                  <div>
                    <Row>
                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        Stocking Type
                      </span>
                    </Row>
                    <Row>{this.state.orderDetails.stockingType}</Row>
                    <br />
                    {this.state.orderDetails.desiredShipDate !== "" &&
                    this.state.orderDetails.desiredShipDate !== undefined ? (
                      <div>
                        <Row>
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            Desired Ship Date
                          </span>
                        </Row>
                        <Row>{this.state.orderDetails.desiredShipDate}</Row>
                        <br />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  }

  renderReactTable() {
    return (
      <div className="text-center">
        {this.pinnedOrderSummaryHeader()}
        {this.pinnedOrderSummaryBody()}
        <br />
        {!this.hideForDistributorUser() ? this.CalculateTotalPrice() : null}
      </div>
    );
  }

  pinnedOrderSummaryHeader() {
    return (
      <Row
        className="estimate-summary-header"
        style={{ minWidth: "1230px", width: "100%" }}
      >
        <Col
          md={2}
          sm={2}
          xs={2}
          className="estimate-summary-table-cell"
          style={{ paddingLeft: "8px" }}
        >
          Products
        </Col>
        <Col md={10} sm={10} xs={10} className="estimate-summary-table-cell">
          <Row style={{ width: "100%" }}>
            <Col
              md={!this.hideForDistributorUser() ? 2 : 5}
              sm={!this.hideForDistributorUser() ? 2 : 5}
              xs={!this.hideForDistributorUser() ? 2 : 5}
              className="estimate-summary-table-cell"
            >
              Stock Code / Description
            </Col>
            <Col
              md={2}
              sm={2}
              xs={2}
              className="estimate-summary-table-cell"
              id="cart-table-specs"
            >
              Specs
            </Col>
            <Col
              md={!this.hideForDistributorUser() ? 1 : 2}
              sm={!this.hideForDistributorUser() ? 1 : 2}
              xs={!this.hideForDistributorUser() ? 1 : 2}
              className="estimate-summary-table-cell"
            >
              Quantity
            </Col>
            {!this.hideForDistributorUser() ? (
              <div>
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                >
                  Distributor Unit Price
                </Col>
                <Col
                  md={2}
                  sm={2}
                  xs={2}
                  className="estimate-summary-table-cell"
                >
                  Distributor Margin
                </Col>
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                >
                  Distributor Sell Price
                </Col>
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                >
                  Distributor Total Price
                </Col>
              </div>
            ) : null}
            <Col
              md={!this.hideForDistributorUser() ? 2 : 3}
              sm={!this.hideForDistributorUser() ? 2 : 3}
              xs={!this.hideForDistributorUser() ? 2 : 3}
              className="estimate-summary-table-cell"
              id="cart-table-estimated-ship-time"
              style={{ minWidth: "60px" }}
            >
              <span>
                Estimated Ship Time
                <br />
                (based on qty entered)
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  pinnedOrderSummaryBody() {
    return (
      <Row style={{ minWidth: "1230px", width: "100%" }}>
        {this.state.orderSummaryData.map((element, i) => {
          return (
            <Row className="estimate-summary-each-row" key={element.StockCode}>
              <Col
                md={2}
                sm={2}
                xs={2}
                className="estimate-summary-table-cell"
                style={{ minHeight: "100px" }}
              >
                {element.url ? (
                  <div
                    className="product-image-box"
                    style={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={element.url}
                      alt=""
                      className="fit-image"
                      onLoad={() => this.setState({ enableDownload: true })}
                    />
                  </div>
                ) : (
                  <Spinner
                    type="cylon"
                    color="#1B2D5B"
                    height="50px"
                    width="50px"
                  />
                )}
              </Col>
              <Col
                id="product-info"
                md={10}
                sm={10}
                xs={10}
                className="estimate-summary-table-cell"
              >
                <Row style={{ width: "100%", marginTop: "10px" }}>
                  <Col
                    id="stock-code"
                    md={!this.hideForDistributorUser() ? 2 : 5}
                    sm={!this.hideForDistributorUser() ? 2 : 5}
                    xs={!this.hideForDistributorUser() ? 2 : 5}
                    className="estimate-summary-table-cell"
                  >
                    {element.StockCode}
                    <br />{" "}
                    {element.Wattage === ""
                      ? element.Description
                      : element.Wattage + "-" + element.Description}
                  </Col>
                  <Col
                    id="cart-contents-specs"
                    md={2}
                    sm={2}
                    xs={2}
                    className="estimate-summary-table-cell"
                    onClick={() => this.showSpecLink(element)}
                  >
                    <SpecTooltipPopup
                      specValue={element.SpecValue}
                      productId={element.ProductId}
                      stockCodeTitle={this.getStockCodeTitle(element)}
                      descriptionTitle={this.getDescriptionTitle(element)}
                      engSpecLink={this.state.engSpecLink}
                      frSpecLink={this.state.frSpecLink}
                      addNotification={this.addNotification}
                      isNavigationEnabled={this.state.itemCategory === "base"}
                      onModal={false}
                    />
                  </Col>
                  <Col
                    id="quantity"
                    md={!this.hideForDistributorUser() ? 1 : 2}
                    sm={!this.hideForDistributorUser() ? 1 : 2}
                    xs={!this.hideForDistributorUser() ? 1 : 2}
                    className="estimate-summary-table-cell"
                  >
                    {" "}
                    {element.Quantity}{" "}
                  </Col>
                  {!this.hideForDistributorUser() ? (
                    <div>
                      <Col
                        id="distributor-unit-price"
                        md={1}
                        sm={1}
                        xs={1}
                        className="estimate-summary-table-cell"
                      >
                        {this.displayDistributorUnitPrice(element)}
                      </Col>
                      <Col
                        id="margin-adjustment"
                        md={2}
                        sm={2}
                        xs={2}
                        className="estimate-summary-table-cell"
                      >
                        <table>
                          <tr>
                            <td style={{ width: "75%", minWidth: "80px" }}>
                              {element.MarginAdjustment}
                            </td>
                            <td style={{ width: "25%", minWidth: "40px" }}>
                              %
                            </td>
                          </tr>
                        </table>
                      </Col>
                      <Col
                        id="distributor-sell-price"
                        md={1}
                        sm={1}
                        xs={1}
                        className="estimate-summary-table-cell"
                      >
                        {this.displayDistributorSellPrice(element)}
                      </Col>
                      <Col
                        id="margin-adjusted-total"
                        md={1}
                        sm={1}
                        xs={1}
                        className="estimate-summary-table-cell"
                      >
                        {this.displayDistributorTotalPrice(element)}
                      </Col>
                    </div>
                  ) : null}
                  <Col
                    id="inventory"
                    md={!this.hideForDistributorUser() ? 2 : 3}
                    sm={!this.hideForDistributorUser() ? 2 : 3}
                    xs={!this.hideForDistributorUser() ? 2 : 3}
                    className="estimate-summary-table-cell"
                  >
                    {element.LeadTime}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Row>
    );
  }

  getStockCodeTitle(element) {
    return element.stockDetails.length > 0
      ? element.stockDetails[0].StockCode
      : Miscellaneous.StockCodeUnavailable;
  }

  getDescriptionTitle(element) {
    return element.Wattage === ""
      ? element.Description
      : element.Wattage + "-" + element.Description;
  }

  showSpecLink(element) {
    let specLinkPopupStockCode =
      element.stockDetails.length > 0
        ? element.stockDetails[0].StockCode
        : Miscellaneous.StockCodeUnavailable;
    let specLinkPopupDescription =
      element.Wattage === ""
        ? element.Description
        : element.Wattage + "-" + element.Description;
    let productId = element.ProductId;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  getSpecLinks(description, pid) {
    let element = {
      description: description,
      productId: pid.toString(),
    };
    ConfigHandler.getSpecLinks(element)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            specValue: data.Specs,
            itemCategory: data.Category,
          });
        } else {
          this.setState({
            specValue: "N/A",
            itemCategory: element.productId !== "NA" ? "base" : "accessories",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  viewButtonClicked(index) {
    let listOfOrders = this.state.orderSummaryData;
    listOfOrders[index].ViewClicked = !listOfOrders[index].ViewClicked;
    this.setState({
      orderSummaryData: listOfOrders,
    });
  }

  CalculateTotalPrice() {
    totalprice = 0.0;
    this.state.orderSummaryData.forEach((element, i) => {
      totalprice =
        parseFloat(totalprice, 2) +
        parseFloat(element.CalculatedPrice, 2) +
        parseFloat(element.WarehouseFee, 2);
    });
    let totalPriceText = "$" + parseFloat(totalprice).toFixed(2);
    return (
      <div style={{ float: "right", paddingRight: "25px" }}>
        <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
          Total before tax: {totalPriceText}
        </h5>
      </div>
    );
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  handleCloseNamePopup() {
    this.setState({
      showPinNamePopup: false,
      showExportNamePopup: false,
    });
  }

  downloadOrderSummary() {
    this.trackDownloadCartButtonClick();

    this.setState({ status: true });
    let flag = this.allFieldsFilled();
    if (!flag) {
      this.addNotification(
        ErrorMessages.OrderSummary_ValidQuantityValues,
        "error"
      );
      return;
    }
    this.setState({
      showExportNamePopup: true,
    });
  }

  handleSubmitButtonDownload(
    fileName,
    hasPrice,
    hasNotes,
    hasLeadTime,
    format
  ) {
    this.setState({
      downloadLoader: true,
    });

    let updatedOrderSummary = this.state.orderSummaryData.map((item) => {
      return {
        ...item,
        Price: item.Price.toString(),
      };
    });

    let userData = {
      firstName: sessionStorage.getItem("firstName"),
      lastName: sessionStorage.getItem("lastName"),
      email: sessionStorage.getItem("username"),
      phoneNumber: sessionStorage.getItem("phoneNumber"),
    };

    let list = {
      orderSummaryData: updatedOrderSummary,
      totalprice: parseFloat(totalprice).toFixed(2),
      customername:
        this.state.customer === "Customer:" ? this.state.customerInfo : "NA",
      format: format,
      includePrice: hasPrice,
      includeNotes: hasNotes,
      includeLeadTime: hasLeadTime,
      notesText: this.state.notesText ? this.state.notesText : "NA",
      branch:
        this.state.customer === "Branch:" ? this.state.customerInfo : "NA",
      tldName: this.state.customer === "TLD:" ? this.state.customerInfo : "NA",
      orderType:
        this.state.orderDetails.orderType === ""
          ? "NA"
          : this.state.orderDetails.orderType,
      address: this.state.shippingAddress,
      projectName:
        this.state.orderDetails.orderType !== "Project"
          ? "NA"
          : this.state.orderDetails.projectName,
      contractorName:
        this.state.orderDetails.orderType !== "Project"
          ? "NA"
          : this.state.orderDetails.contractor,
      specAvailable:
        this.state.orderDetails.orderType !== "Project"
          ? "NA"
          : this.state.orderDetails.specAvailable,
      stockingType:
        this.state.orderDetails.orderType !== "Stocking"
          ? "NA"
          : this.state.orderDetails.stockingType,
      userData: userData,
    };

    OrderSummaryHandler.exportOrderSummary(list)
      .then((response) => {
        this.setState({
          downloadLoader: false,
        });

        this.trackDownloadCartSuccessClick(fileName);
        const url = window.URL.createObjectURL(new Blob([response]));
        fileName += list.format === "Excel" ? ".xlsx" : ".pdf";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.setState({
          downloadLoader: false,
        });
        this.trackDownloadCartFailedClick(
          fileName,
          ErrorMessages.OrderSummary_Download
        );
        this.addNotification(ErrorMessages.OrderSummary_Download, "error");
      });
  }

  allFieldsFilled() {
    let flag = true;
    this.state.orderSummaryData.forEach((item, i) => {
      if (
        item.Quantity === "" ||
        parseInt(item.Quantity, 10) === 0 ||
        parseInt(item.Quantity, 10) > 10000
      )
        flag = false;
    });
    return flag;
  }

  displayDistributorUnitPrice(element) {
    return (
      <div>
        {parseFloat(element.SalePrice) === 0 ? (
          <div>
            {parseFloat(element.GoldenPrice) === 0 ? (
              <span>${parseFloat(element.Price).toFixed(2)}</span>
            ) : (
              <div className="gold-pricing">
                <span>${parseFloat(element.GoldenPrice).toFixed(2)}</span>
              </div>
            )}
          </div>
        ) : (
          <div>
            {parseFloat(element.SalePrice) < parseFloat(element.GoldenPrice) ||
            parseFloat(element.GoldenPrice) === 0 ? (
              <div>
                <div
                  style={{
                    textDecorationLine: "line-through",
                  }}
                >
                  ${parseFloat(element.Price).toFixed(2)}
                </div>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bolder",
                  }}
                >
                  ${parseFloat(element.SalePrice).toFixed(2)}
                </div>
              </div>
            ) : (
              <div className="gold-pricing">
                <span>${parseFloat(element.GoldenPrice).toFixed(2)}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  displayDistributorSellPrice(element) {
    return parseFloat(element.SalePrice) === 0 ? (
      <div>
        {parseFloat(element.GoldenPrice) === 0 ? (
          <span>
            {"$" +
              (element.CalculatedPrice === 0
                ? "0.00"
                : parseFloat(element.unitCalculatedPrice).toFixed(2))}
          </span>
        ) : (
          <div className="gold-pricing">
            <span>
              {"$" +
                (element.CalculatedPrice === 0
                  ? "0.00"
                  : parseFloat(element.unitCalculatedPrice).toFixed(2))}
            </span>
          </div>
        )}
      </div>
    ) : (
      <div>
        {parseFloat(element.SalePrice) < parseFloat(element.GoldenPrice) ||
        parseFloat(element.GoldenPrice) === 0 ? (
          <div>
            <div
              style={{
                textDecorationLine: "line-through",
              }}
            >
              {"$" +
                (element.oldPrice === 0
                  ? "0.00"
                  : parseFloat(element.unitOldPrice).toFixed(2))}
            </div>
            <div
              style={{
                color: "red",
                fontWeight: "bolder",
              }}
            >
              {"$" +
                (parseFloat(element.CalculatedPrice) === 0
                  ? "0.00"
                  : parseFloat(element.unitCalculatedPrice).toFixed(2))}
            </div>
          </div>
        ) : (
          <div className="gold-pricing">
            <div>
              {"$" +
                (parseFloat(element.CalculatedPrice) === 0
                  ? "0.00"
                  : parseFloat(element.unitCalculatedPrice).toFixed(2))}
            </div>
          </div>
        )}
      </div>
    );
  }

  displayDistributorTotalPrice(element) {
    return (
      <div>
        {parseFloat(element.SalePrice) === 0 ? (
          <div>
            {parseFloat(element.GoldenPrice) === 0 ? (
              <span>
                {"$" +
                  (parseFloat(element.CalculatedPrice) === 0
                    ? "0.00"
                    : parseFloat(element.CalculatedPrice).toFixed(2))}
              </span>
            ) : (
              <div className="gold-pricing">
                <span>
                  {"$" +
                    (parseFloat(element.CalculatedPrice) === 0
                      ? "0.00"
                      : parseFloat(element.CalculatedPrice).toFixed(2))}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div>
            {parseFloat(element.SalePrice) < parseFloat(element.GoldenPrice) ||
            parseFloat(element.GoldenPrice) === 0 ? (
              <div>
                <div
                  style={{
                    textDecorationLine: "line-through",
                  }}
                >
                  {"$" +
                    (parseFloat(element.oldPrice) === 0
                      ? "0.00"
                      : parseFloat(element.oldPrice).toFixed(2))}
                </div>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bolder",
                  }}
                >
                  {"$" +
                    (parseFloat(element.CalculatedPrice) === 0
                      ? "0.00"
                      : parseFloat(element.CalculatedPrice).toFixed(2))}
                </div>
              </div>
            ) : (
              <div className="gold-pricing">
                <div>
                  {"$" +
                    (parseFloat(element.CalculatedPrice) === 0
                      ? "0.00"
                      : parseFloat(element.CalculatedPrice).toFixed(2))}
                </div>
              </div>
            )}
          </div>
        )}{" "}
        {parseFloat(element.WarehouseFee) > 0
          ? " + $" + element.WarehouseFee
          : ""}
      </div>
    );
  }

  // Mixpanel Tracking //

  trackDownloadCartButtonClick() {
    mixpanel.track("Download Cart", {
      Action: "Button Click",
      Effect: "Download Cart details will be displayed",
    });
  }

  trackDownloadCartSuccessClick(filename) {
    mixpanel.track("Next", {
      Action: "Button Click",
      Effect: "Cart downloaded successfully",
      Filename: filename,
    });
  }

  trackDownloadCartFailedClick(filename, message) {
    mixpanel.track("Next", {
      Action: "Button Click",
      Effect: "Cart downloaded failed",
      Filename: filename,
      Message: message,
    });
  }
}

export default PinnedOrderSummary;
