import Api from "../Utils/api.js";
/**
 * API Calls to handle:
 * Get tool documents based on resource type
 * Get email template
 * Get pricing details
 * Attach full file path
 */
const ToolsDataHandler = (function () {
  return {
    specificToolsDocuments: function (data) {
      return new Promise((resolve, reject) => {
        const route = `ContentDisplay/Attachments?whereToDisplay=Tools&sort=${data.sortBy}&searchString=${data.searchString}&resourceType=${data.resourceType}`;
        Api.get(route)
          .then((response) => {
            let documentList = response.data.data.map((item) => {
              item.attachments = attachFullPath(item.attachments);
              return item;
            });
            documentList.success = response.data.success;
            resolve(documentList);
          })
          .catch((error) => reject(error));
      });
    },
    getEmailTemplate(data) {
      return new Promise((resolve, reject) => {
        const route = "Share/EmailTemplate";
        Api.post(route, data)
          .then((response) => {
            let template = response.data;
            resolve(template);
          })
          .catch((error) => reject(error));
      });
    },
    getPricingDetails: function (data) {
      return new Promise((resolve, reject) => {
        const route = `ContentDisplay/PricingDetails?username=${data}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

const attachFullPath = (attachments) => {
  return attachments.map((attachment) => {
    attachment.filePath = Api.getBaseURL() + "/" + attachment.filePath;
    return attachment;
  });
};

export default ToolsDataHandler;
