import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import ReactLoading from "react-loading";
import { Redirect } from "react-router-dom";
import { clearInterval, setInterval } from "worker-timers";
import mixpanel from "mixpanel-browser";

import "./login.css";
import LoginHandler from "./LoginHandler";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";

const appLogo = fetchResourceData(fixedLink.appLogo);

/**
 * Component represents the Two-Factor Authentication page
 */
class TwoFactorAuthentication extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.validate = this.validate.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);
    this.startCountDown = this.startCountDown.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.codeExpiryMessage = this.codeExpiryMessage.bind(this);
    this.timer = 0;

    this.state = {
      otp: "",
      appLogo: appLogo,
      isResponseReceived: false,
      invalidKey: false,
      resend: false,
      disableSubmit: true,
      userName: "",
      time: {},
      isTimerRunning: false,
      seconds: 3600, // timer changed from 5 minutes to 1 hour
    };
  }

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    hours = this.pad(hours, 2);

    let minutesDivisor = secs % (60 * 60);
    let minutes = Math.floor(minutesDivisor / 60);
    minutes = this.pad(minutes, 2);

    let secondsDivisor = minutesDivisor % 60;
    let seconds = Math.ceil(secondsDivisor);
    seconds = this.pad(seconds, 2);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    this.setState(
      (prevState) => ({
        time: this.secondsToTime(prevState.seconds - 1),
        seconds: prevState.seconds - 1,
      }),
      () => {
        sessionStorage.setItem("Time", this.state.seconds);
        if (this.state.seconds === 0) {
          clearInterval(this.timer);
          this.setState({
            isTimerRunning: false,
            resend: true,
          });
        }
      }
    );
  }

  codeExpiryMessage() {
    if (this.state.isTimerRunning && this.timer !== 0) {
      return (
        <Col
          md={12}
          style={{
            color: "red",
            fontWeight: "bold",
            padding: "0px",
            textAlign: "right",
          }}
        >
          {DisplayMessages.TFA_TimerRunning}
          {this.state.time.h} h {this.state.time.m} m {this.state.time.s} s
        </Col>
      );
    } else {
      return (
        <Col
          md={12}
          style={{
            color: "red",
            fontWeight: "bold",
            padding: "0px",
            textAlign: "right",
          }}
        >
          {DisplayMessages.TFA_TimerExpired}
        </Col>
      );
    }
  }

  componentDidMount() {
    if (this.state.userName === "") {
      this.validate(this.props.match.params.key);
    }
    let time = sessionStorage.getItem("Time")
      ? sessionStorage.getItem("Time")
      : this.state.seconds;
    let resend = false;
    if (sessionStorage.getItem("Time") === "0") {
      resend = true;
    }
    let timeLeftVar = this.secondsToTime(time);
    this.setState(
      (prevState) => ({
        time: timeLeftVar,
        seconds: sessionStorage.getItem("Time")
          ? sessionStorage.getItem("Time")
          : prevState.seconds,
        resend: resend,
      }),
      this.startCountDown
    );
  }

  startCountDown() {
    if (!this.state.isTimerRunning) {
      this.startTimer();
      this.setState({
        isTimerRunning: true,
      });
    }
  }

  validate(key) {
    LoginHandler.getUserName(key)
      .then((response) => {
        this.setState({
          isResponseReceived: true,
        });
        if (response.success) {
          this.setState({
            userName: response.data.username,
          });
        } else {
          this.setState({
            invalidKey: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isResponseReceived: true,
          invalidKey: true,
        });
        this.props.addNotification(ErrorMessages.TFA_UsernameFailed, "error");
      });
  }
  render() {
    return this.state.isResponseReceived
      ? this.validAuthenticationSection()
      : this.getProgress();
  }

  validAuthenticationSection() {
    if (this.state.invalidKey) {
      return <Redirect to={{ pathname: "/" }} />;
    } else {
      return (
        <div className="login-container">
          <div style={{ height: "45px" }}></div>
          <img
            src={this.state.appLogo}
            alt="logo"
            height="175px"
            width="175px"
          />
          <br />
          <br />
          <div onKeyDown={this.handleKeyPress}>
            <Row className="center-container">
              <Col md={4} sm={4} xs={4}>
                <h4 className="heading">{DisplayMessages.TFA_Title}</h4>
              </Col>
              <br />
              <Col md={4} sm={4} xs={4}>
                <center style={{ fontSize: "14px" }}>
                  {DisplayMessages.TFA_AuthCodeText}
                  <strong>{this.state.userName}</strong>
                </center>
              </Col>
              <br />
              <Col md={4} sm={4} xs={4}>
                <FormGroup controlId="user-name" className="username">
                  <FormControl
                    type="text"
                    value={this.state.otp}
                    placeholder="Enter the code"
                    onChange={this.handleOtpChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={4} xs={4}>
                {this.codeExpiryMessage()}
              </Col>
              <br />
              <Col md={4} sm={4} xs={4}>
                <center>
                  <Button
                    bsStyle="primary"
                    className="button-blue"
                    style={{ margin: "8px" }}
                    disabled={this.state.disableSubmit}
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    bsStyle="primary"
                    className="button-blue"
                    disabled={!this.state.resend}
                    style={{ margin: "8px" }}
                    onClick={this.handleResend}
                  >
                    Resend
                  </Button>
                </center>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }

  handleOtpChange(e) {
    let input = e.target.value.replace(/[A-Za-z]*/g, "");
    if (input.length > 0) {
      this.setState({
        otp: input,
        disableSubmit: false,
      });
    } else {
      this.setState({
        otp: input,
        disableSubmit: true,
      });
    }
  }
  handleResend() {
    this.trackResendButtonClick();
    LoginHandler.resendOtp(this.props.match.params.key, this.state.userName)
      .then((response) => {
        if (response.success) {
          this.props.addNotification(DisplayMessages.TFA_OTPSent, "info");
          this.timer = 0;
          this.setState(
            {
              resend: false,
              isTimerRunning: true,
              otp: "",
              seconds: 3600,
            },
            () => this.startTimer()
          );
        } else {
          this.setState({
            resend: true,
            isTimerRunning: false,
            otp: "",
          });
          this.props.addNotification(ErrorMessages.TFA_OTPNotSent, "error");
        }
      })
      .catch((error) => {
        this.setState({
          resend: true,
          isTimerRunning: false,
          otp: "",
        });
        this.props.addNotification(ErrorMessages.TFA_OTPNotSent, "error");
      });
  }

  handleSubmit() {
    this.setState({
      disableSubmit: true,
    });

    this.trackSubmitButtonClick();

    LoginHandler.validateOtp(
      this.state.otp,
      this.props.match.params.key,
      this.state.userName
    )
      .then((response) => {
        this.setState({
          disableSubmit: false,
        });
        if (response.success) {
          sessionStorage.removeItem("Time");
          if (response.data.userActivity === "2") {
            this.trackPageNavigation("Privacy Policy Page");

            this.props.goToPrivacyPolicy(
              response.data,
              response.data.displayName
            );
          } else {
            this.props.goToHome(response.data, this.state.userName);
            this.props.addNotification(DisplayMessages.TFA_Completed, "info");
          }
        } else {
          this.setState({
            otp: "",
            disableSubmit: true,
          });
          this.props.addNotification(ErrorMessages.TFA_InvalidCode, "error");
        }
      })
      .catch((error) => {
        this.setState({
          disableSubmit: true,
          otp: "",
        });
        this.props.addNotification(ErrorMessages.TFA_InvalidCode, "error");
      });
  }

  getProgress() {
    return (
      <div className="loading-icon">
        <br />
        <br />
        <br />
        <ReactLoading type="spokes" color="#797575" height={50} width={50} />
      </div>
    );
  }
  handleKeyPress(target) {
    if (target.keyCode === 13) {
      target.preventDefault();
      this.handleSubmit();
    }
  }

  // Mixpanel Tracking //

  trackSubmitButtonClick() {
    mixpanel.track("Submit OTP", {
      Action: "Button Click",
      Username: this.state.userName,
    });
  }

  trackResendButtonClick() {
    mixpanel.track("Resend OTP", {
      Action: "Button Click",
      Username: this.state.userName,
    });
  }

  trackPageNavigation(page) {
    mixpanel.track_pageview({
      Page: page,
    });
  }
}
export default TwoFactorAuthentication;
