import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import LoginHandler from "./LoginHandler";
import "./login.css";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
  fetchJSONFileData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";

const appLogo = fetchResourceData(fixedLink.appLogo);

/**
 * Component represents the forgot password page
 */
class ForgotPassWord extends Component {
  constructor(props) {
    super(props);
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.state = {
      username: "",
      appLogo: appLogo,
      baseurl: "",
    };
  }

  async componentDidMount() {
    await fetchJSONFileData(fixedLink.displayMessagesPath);
    let hostname =
      window.location.hostname === "localhost"
        ? window.location.hostname + ":3000"
        : window.location.hostname;
    let url = window.location.protocol + "//" + hostname + "/ResetPassword";
    this.setState({
      baseurl: url,
    });
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div className="login-container">
        <br />
        <br />
        <br />
        <img src={this.state.appLogo} alt="logo" height="175px" width="175px" />
        <br />
        <br />
        <Row className="center-container">
          <Col md={4} sm={4} xs={4}>
            <h4 className="heading">Forgot Password</h4>
          </Col>
          <br />
          <Col md={4} sm={4} xs={4}>
            {DisplayMessages.ForgotPassword_MainText}
          </Col>
        </Row>
        <br />
        <div onKeyDown={this.handleKeyPress} role="presentation">
          <Row className="center-container">
            <Col md={4} sm={4} xs={4}>
              <FormGroup controlId="user-name" className="username">
                <FormControl
                  type="text"
                  value={this.state.username}
                  placeholder="Email / Username"
                  onChange={this.handleUsernameChange}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <center>
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  onClick={this.handleReset}
                >
                  Reset
                </Button>
              </center>
            </Col>
          </Row>
        </div>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  handleReset() {
    this.trackResetButtonClick(this.state.username.toString().trim());

    if (this.state.username !== "") {
      let emailid = this.state.username.toString().trim();
      let pattern = Miscellaneous.RegexEmailId;
      const re = new RegExp(pattern, "i");

      if (!re.test(emailid)) {
        this.addNotification(ErrorMessages.ForgotPassword_InvalidUser, "error");
      } else {
        let data = {
          username: this.state.username,
          baseurl: this.state.baseurl,
        };
        LoginHandler.sendResetMail(data)
          .then((response) => {
            if (response.success) {
              this.addNotification(
                DisplayMessages.ForgotPassword_EmailSent,
                "info"
              );
            } else {
              this.addNotification(
                ErrorMessages.ForgotPassword_EmailNotSent,
                "error"
              );
            }
          })
          .catch((error) => {
            this.addNotification(
              ErrorMessages.ForgotPassword_EmailNotSent,
              "error"
            );
          });
      }
    } else {
      this.addNotification(ErrorMessages.ForgotPassword_EmptyUsername, "error");
    }
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }
  handleUsernameChange(e) {
    this.setState({
      username: e.target.value,
    });
  }
  handleKeyPress(target) {
    if (target.keyCode === 13) {
      target.preventDefault();
      this.handleReset(target);
    }
  }

  // Mixpanel Tracking //

  trackResetButtonClick(email) {
    mixpanel.track("Reset Password", {
      Action: "Button Click",
      Effect: "Reset password mail will be sent to the email",
      Username: email,
    });
  }
}
export default ForgotPassWord;
