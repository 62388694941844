import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import mixpanel from "mixpanel-browser";

import "./emailpopup.css";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import EmailPopupDataHandler from "./EmailPopupDataHandler";

/**
 * Component represents the email popup
 */
class EmailPopup extends Component {
  constructor(props) {
    super(props);

    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleToAddressChange = this.handleToAddressChange.bind(this);
    this.handleMailSubjectChange = this.handleMailSubjectChange.bind(this);
    this.handleCCChange = this.handleCCChange.bind(this);
    this.handleBCCChange = this.handleBCCChange.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.sendMail = this.sendMail.bind(this);

    this.state = {
      toAddress: "",
      cc: "",
      bcc: "",
      mailSubject: "",
      body: this.props.emailTemplate,
      attachmentList: this.props.attachments,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      attachmentList: nextProps.attachments,
      body: nextProps.emailTemplate,
    });
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              Email
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <h4>To</h4>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    value={this.state.toAddress}
                    placeholder="Email id"
                    spellcheck="false"
                    onChange={this.handleToAddressChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4>CC</h4>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    value={this.state.cc}
                    spellcheck="false"
                    onChange={this.handleCCChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4>BCC</h4>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    value={this.state.bcc}
                    spellcheck="false"
                    onChange={this.handleBCCChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4>Subject</h4>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    value={this.state.mailSubject}
                    onChange={this.handleMailSubjectChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4>Body</h4>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <div
                    id="email-content"
                    className="email-body"
                    dangerouslySetInnerHTML={{ __html: this.state.body }}
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Table>
                  <thead>
                    <tr>
                      {" "}
                      <ControlLabel>Attachments</ControlLabel>{" "}
                    </tr>
                  </thead>
                  <tbody>{this.generateAttachments()}</tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                bsStyle="primary"
                className="button-skyblue"
                style={{ textAlign: "center" }}
                onClick={this.sendMail}
              >
                Send
              </Button>
              <Button
                onClick={this.handleDiscard}
                bsStyle="primary"
                className="button-white"
                style={{ textAlign: "center" }}
              >
                Discard
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleOnClose() {
    this.setState({
      toAddress: "",
      cc: "",
      bcc: "",
      mailSubject: "",
      body: "",
      attachmentList: [],
    });
    this.props.handleOnClose();
  }

  generateAttachments() {
    return this.state.attachmentList.map((item, i) => (
      <tr key={item.fileName}>
        <td>{item.fileName}</td>
      </tr>
    ));
  }

  handleToAddressChange(e) {
    this.setState({
      toAddress: e.target.value,
    });
  }

  handleMailSubjectChange(e) {
    this.setState({
      mailSubject: e.target.value,
    });
  }

  handleCCChange(e) {
    this.setState({
      cc: e.target.value,
    });
  }

  handleBCCChange(e) {
    this.setState({
      bcc: e.target.value,
    });
  }

  handleDiscard() {
    this.trackEmailDiscardClick();
    this.handleOnClose();
  }

  sendMail() {
    let content = document.getElementById("email-content").innerHTML;
    let mailContent = {
      to: this.state.toAddress.trim(),
      cc: this.state.cc.trim(),
      bcc: this.state.bcc.trim(),
      subject: this.state.mailSubject,
      body: content,
      attachments: this.props.attachments,
      type: this.props.type,
    };

    if (mailContent.to !== "") {
      let route;
      if (this.props.emailFromProductPage) {
        route = "Share/ProductsPage";
      } else {
        route = "Share/ProductDocuments";
      }
      EmailPopupDataHandler.sendMail(route, mailContent)
        .then((response) => {
          if (response.success) {
            this.trackEmailSendSuccessClick(mailContent);
            this.props.addNotification(
              DisplayMessages.EmailPopup_EmailSuccess,
              "info"
            );
            this.handleOnClose();
            this.props.clearSelection();
          } else {
            this.trackEmailSendFailureClick(mailContent);
            this.props.addNotification(response.data.message, "error");
          }
        })
        .catch((error) => {
          this.trackEmailSendFailureClick(mailContent);
          this.props.addNotification(
            ErrorMessages.EmailPopup_SendMailError,
            "error"
          );
        });
      this.handleOnClose();
    } else {
      this.trackEmailSendFailureClick(mailContent);
      this.props.addNotification(
        ErrorMessages.EmailPopup_ToAddressEmpty,
        "error"
      );
    }
  }

  // Mixpanel Tracking //

  trackEmailSendSuccessClick(mailContent) {
    mixpanel.track("Send Email", {
      Action: "Button Click",
      Effect: "Email will be sent to recipient",
      Recipient: mailContent.to,
      Subject: mailContent.subject,
    });
  }

  trackEmailSendFailureClick(mailContent) {
    mixpanel.track("Send Email", {
      Action: "Button Click",
      Effect: "Failed to send email to recipient",
      Recipient: mailContent.to,
      Subject: mailContent.subject,
    });
  }

  trackEmailDiscardClick() {
    mixpanel.track("Discard Email", {
      Action: "Button Click",
      Effect: "Share email popup closed",
    });
  }
}

export default EmailPopup;
