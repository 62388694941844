import React, { Component } from "react";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import NotificationSystem from "react-notification-system";
import Creatable from "react-select/creatable";
import Select from "react-select";
import ReactLoading from "react-loading";
import Panel from "react-bootstrap/lib/Panel";
import Button from "react-bootstrap/lib/Button";
import Popover from "react-bootstrap/lib/Popover";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import { LinkContainer } from "react-router-bootstrap";
import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import mixpanel from "mixpanel-browser";

import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp.js";
import Disclaimer from "../Disclaimer/Disclaimer";
import "../ProductConfigurator/productConfigurator.css";
import "./quickConfigurator.css";
import fixedLink from "../Assets/fixedAssets.json";
import QuickConfiguratorHandler from "./QuickConfiguratorHandler.js";
import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler.js";
import {
  DisplayMessages,
  Disclaimers,
  ErrorMessages,
  Miscellaneous,
  fetchResourceData,
  fetchJSONFileData,
} from "../Utils/FetchConfigurableData";
import BranchOverviewHandler from "../Distributors/BranchOverviewHandler.js";
import { CopyToClipboard } from "../Utils/CopyToClipboard.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const allCustomColors = [
  { Title: "BRZ", Name: "Bronze", className: " bronze" },
  { Title: "BLK", Name: "Matte Black", className: " matte-black" },
  { Title: "MBK", Name: "Mirror Black", className: " mirror-black" },
  { Title: "SBK", Name: "Sandtex Black", className: " black-sandtex" },
  { Title: "WHT", Name: "White", className: " white" },
  { Title: "HGY", Name: "Hammertone", className: " hammertone" },
  { Title: "DGY", Name: "Dove Gray", className: " dove-gray" },
  { Title: "SPS", Name: "Sparkle Silver", className: " sparkle-silver" },
  { Title: "DGN", Name: "Design Green", className: " green" },
  { Title: "GGN", Name: "Garden Green", className: " garden-green" },
  { Title: "BLU", Name: "Blue", className: " blue" },
  { Title: "YLW", Name: "Yellow", className: " yellow" },
  { Title: "RED", Name: "Red", className: " red" },
  { Title: "GRY", Name: "Gray", className: " gray" },
];
const priceType = ["Price1", "Price2"];
const priceString = "Price";
const branchBasedRoles = ["Distributor User", "Branch Admin"];

let customerName = "";
let customerTLD = "";
let customColors = [];

/**
 * Component represents the quick configurator page
 */
class QuickConfigurator extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      productList: [],
      selectedProduct: null,
      productDetails: null,
      customerList: [],
      selectedCustomer: null,
      productAccessories: [],
      configuratorValues: [],
      productFamily: [],
      familyList: [],
      wattageList: [],
      wattage: "",
      tierPricing: "Price1",
      customerTier: "Tier1",
      entireConfigurator: [],
      description: [""],
      cstIndex: -1,
      cstColorProps: null,
      productPrice: null,
      productSalePrice: null,
      productGoldenPrice: null,
      productQuantity: 1,
      isViewClicked: false,
      showConfirmationPopup: false,
      currentSetIndex: 0,
      last_updated: null,
      stockdetails: [],
      productType: "led",
      constructionTier: "Price1",
      ledTier: "Price1",
      isChecked: true,
      isNewCustomer: false,
      standardFinishes: [],
      tierSelected: "Tier1",
      singleCustomer: false,
      pricingAccess: true,
      goldenPriceAccess: true,
      qtyLead: [],
      customerTLD: "",
      selectedProductId: "",
      tierList: Miscellaneous.TiersList,
    };
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.handleSingleCustomerSelect =
      this.handleSingleCustomerSelect.bind(this);
    this.onChangeFamily = this.onChangeFamily.bind(this);
    this.onChangeWattage = this.onChangeWattage.bind(this);
    this.handleAccessoriesSelection =
      this.handleAccessoriesSelection.bind(this);
    this.onProductQtyChange = this.onProductQtyChange.bind(this);
    this.viewButtonClicked = this.viewButtonClicked.bind(this);
    this.validateProductQuantity = this.validateProductQuantity.bind(this);
    this.addToOrderSummary = this.addToOrderSummary.bind(this);
    this.addToSummaryAPI = this.addToSummaryAPI.bind(this);
    this.onBlurQtyChange = this.onBlurQtyChange.bind(this);
    this.onBlurAccessoryQtyChange = this.onBlurAccessoryQtyChange.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);
    this.splitBase = this.splitBase.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getConstructionProductConfigurator =
      this.getConstructionProductConfigurator.bind(this);
    this.onBlurConstructionQtyChange =
      this.onBlurConstructionQtyChange.bind(this);
    this.handleAccessoriesCST = this.handleAccessoriesCST.bind(this);
    this.handleAccessoriesColor = this.handleAccessoriesColor.bind(this);
    this.addAccessory = this.addAccessory.bind(this);
    this.onAccessoryClick = this.onAccessoryClick.bind(this);
    this.handleColorSelection = this.handleColorSelection.bind(this);
    this.getAccessoryPrice = this.getAccessoryPrice.bind(this);
    this.handleTierSelect = this.handleTierSelect.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await fetchJSONFileData(fixedLink.displayMessagesPath);
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";

    let role = sessionStorage.getItem("userRole");

    if (branchBasedRoles.includes(role)) {
      BranchOverviewHandler.getBranchTier()
        .then((response) => {
          if (response.success && this._isMounted) {
            this.setState({
              singleCustomer: true,
              branchName: response.data.BranchName,
              pricingAccess: JSON.parse(
                sessionStorage.getItem("pricingAccess")
              ),
              goldenPriceAccess: JSON.parse(
                sessionStorage.getItem("goldenPriceAccess")
              ),
            });
          }
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.ProductConfig_BranchTier, "error");
        });
    }

    this.getCustomers();
    this.getProducts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.productDetails !== null &&
      (prevState.description !== this.state.description ||
        (prevState.wattage !== "" &&
          prevState.wattage !== this.state.wattage) ||
        prevState.selectedCustomer !== this.state.selectedCustomer ||
        prevState.tierSelected !== this.state.tierSelected) &&
      this.state.productType === "led"
    ) {
      let desc = this.state.description.filter((e) => e).join("-");
      desc = desc.replace("`", "'");
      let data = {
        description: desc,
        wattage: this.state.wattage,
        cstColor:
          this.state.cstColorProps == null
            ? "CST"
            : this.state.cstColorProps.Title,
        productId: this.state.productDetails.id.toString(),
        setId:
          this.state.entireConfigurator.configurator[this.state.currentSetIndex]
            .setId,
        customer_name: this.state.singleCustomer
          ? this.state.branchName
          : this.ternaryEvaluation(
              this.state.selectedCustomer,
              null,
              "",
              this.state.selectedCustomer
            ),
      };
      ProductConfiguratorHandler.getStockCodeAndPrice(data)
        .then((response) => {
          this.setState((prevState) => ({
            productPrice: response.data.price,
            productSalePrice: response.data.salePrice,
            productGoldenPrice: prevState.goldenPriceAccess
              ? response.data.goldenPrice
              : "0",
          }));
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.StockPrice, "error");
        });
    }
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  getProducts() {
    QuickConfiguratorHandler.getProductList(this.state.productType)
      .then((response) => {
        if (response.success && this._isMounted) {
          let sortedProducts = response.data.sort((a, b) =>
            a.title.localeCompare(b.title)
          );
          this.setState({
            productList: sortedProducts,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.ProductListMissing, "error");
      });
  }

  getCustomers() {
    ProductConfiguratorHandler.getCustomerList()
      .then((response) => {
        if (response.success && this._isMounted) {
          let customer = response.data.customers.list.find(
            (item) => item.CustomerName === customerName
          );
          let price =
            response.data.customers.tier === this.state.tierList[0] ||
            customerName === ""
              ? priceType[0]
              : priceType[1];
          this.setState(
            {
              last_updated: response.data.lastUpdatedTime,
              selectedCustomer: customerName,
              isNewCustomer: customer === undefined && customerName !== "",
              tierSelected: this.setTierSelected(response),
              tierPricing: this.setTierPricing(price, customer),
              ledTier: this.setLedTier(price, customer),
              constructionTier: this.setConstructionTier(price, customer),
              customerTier: this.setCustomerTier(customer),
              customerList: response.data.customers.list.map((item) => ({
                value:
                  item.CustomerName +
                  "-tier-" +
                  item.Tier +
                  "-tier-" +
                  item.ConstructionTier,
                label: item.CustomerName,
              })),
              customerTLD: this.setCustomerTLD(response),
            },
            () => {
              if (
                this.state.singleCustomer &&
                this.state.customerList.length > 0
              ) {
                customerName = this.state.customerTLD;
                this.handleSingleCustomerSelect(
                  this.state.customerTLD,
                  this.state.customerList[0]
                );
              }
            }
          );
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.CustomerListMissing, "error");
      });
  }

  setTierSelected(response) {
    return customerName !== ""
      ? response.data.customers.tier
      : this.state.tierList[0];
  }

  setTierPricing(price, customer) {
    return customer === undefined
      ? price
      : this.ternaryEvaluation(
          this.state.productType,
          "led",
          priceString + customer.Tier,
          priceString + customer.ConstructionTier
        );
  }

  setLedTier(price, customer) {
    return customer === undefined ? price : priceString + customer.Tier;
  }

  setConstructionTier(price, customer) {
    return customer === undefined
      ? price
      : priceString + customer.ConstructionTier;
  }

  setCustomerTier(customer) {
    return customer === undefined || customer.Tier === "1"
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          customer.ConstructionTier,
          "1",
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  setCustomerTLD(response) {
    return response.data.customers.list.length === 1
      ? response.data.customers.list[0].TLDName
      : "";
  }

  generateProductDescription() {
    if (this.state.productDetails) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Col md={6}>
            <img
              src={this.state.productDetails.featured_src}
              alt="product img"
              width="200px"
              height="200px"
            />
          </Col>
          <Col md={6}>
            <h2>{this.state.productDetails.title}</h2>
            <div
              className="product-short-description"
              dangerouslySetInnerHTML={{
                __html: this.state.productDetails.short_description,
              }}
            />
          </Col>
        </div>
      );
    } else {
      return null;
    }
  }

  getProductConfigDetails(productId, title) {
    let productFamily = [];
    ProductConfiguratorHandler.getProductConfigDetails(
      productId,
      title,
      "QuickConfigurator",
      encodeURIComponent(customerName)
    ).then((response) => {
      if (response.data.configurator.length > 0) {
        response.data.configurator.forEach((table, index) => {
          table.base.map((item, i) => {
            item.index = index;
            item.isSelected = i === 0;
            item.Quantity = i === 0 ? 1 : 0;
            if (!this.state.goldenPriceAccess) {
              item.GoldenPrice = "0";
            }
            productFamily.push(item);
            return item;
          });
        });
        this.splitBase(productFamily, response.data);
      }
    });
  }

  splitBase(productFamily, response) {
    productFamily.forEach((element) => {
      let constProduct = this.state.productFamily.find(
        (item) => item.Title === element.Title
      );
      if (constProduct !== undefined) {
        element.isSelected = constProduct.isSelected;
        element.Quantity = constProduct.Quantity;
      }
    });

    if (this.state.productType === "led") {
      let familyList = productFamily
        .map((item) => {
          let list = item.Title.split("-");
          if (
            item.Title.includes("LED") &&
            list[1].startsWith("LED") === false
          ) {
            list[0] = list[0] + "-" + list[1];
            list[1] = list[2];
          }
          return list[0];
        })
        .filter((x, i, a) => a.indexOf(x) === i);
      let family = familyList.length > 0 ? familyList[0] : "";
      let wattageList = productFamily.filter((item) =>
        item.Title.includes(family + "-")
      );
      this.setState(
        {
          entireConfigurator: response,
          productFamily: productFamily,
          familyList: familyList,
          family: family,
          wattageList: wattageList,
          wattage: wattageList[0].Title,
          currentSetIndex: wattageList[0].index,
        },
        () => {
          this.handleConfiguratorResponse();
        }
      );
    } else {
      this.setState(
        {
          entireConfigurator: response,
          productFamily: productFamily,
        },
        () => {
          this.handleConfiguratorResponse();
        }
      );
    }
  }

  handleConfiguratorResponse() {
    let tempCstIndex = -1;
    let cstColorProps = this.state.cstColorProps;
    if (cstColorProps !== null) {
      cstColorProps.Title = "CST";
      cstColorProps.Name = "CUSTOM";
    }
    let description = [];
    let options =
      this.state.entireConfigurator.configurator[this.state.currentSetIndex]
        .options;
    let tempAccessories = [];
    if (this.state.goldenPriceAccess) {
      tempAccessories =
        this.state.entireConfigurator.configurator[this.state.currentSetIndex]
          .accessories;
    } else {
      tempAccessories = this.state.entireConfigurator.configurator[
        this.state.currentSetIndex
      ].accessories.map((item) => {
        return { ...item, GoldenPrice: "0" };
      });
    }
    if (tempAccessories.length <= 0)
      this.setState({ productAccessories: tempAccessories });
    tempAccessories.map((element) => {
      if (element.PhotoID !== "") {
        ProductConfiguratorHandler.getAccessoriesImages(element.PhotoID).then(
          (imagedetails) => {
            element.url = imagedetails[0].src;
            this.setState({
              productAccessories: tempAccessories,
            });
          }
        );
      }
      let selectedAccessory = this.state.productAccessories.filter(
        (item) => item.isSelected
      );
      let selectedItem = selectedAccessory.find(
        (item) => item.Accessories === element.Accessories
      );
      if (selectedItem !== undefined) {
        element.color = selectedItem.color;
        element.SalePrice = selectedItem.SalePrice;
        element.ViewClicked = selectedItem.ViewClicked;
        element.isSelected = selectedItem.isSelected;
        element.Quantity = selectedItem.Quantity;
        element.setId = selectedItem.setId;
      } else {
        element.color = "";
        element.ViewClicked = false;
        element.isSelected = false;
        element.Quantity = 0;
        element.setId =
          this.state.entireConfigurator.configurator[
            this.state.currentSetIndex
          ].setId;
      }
      return null;
    });
    options.map((item, i) => {
      if (item.hasOwnProperty("list") && item.list.length > 0) {
        if (item.list[0].ValueIndex === "1") {
          description[i] = item.list[0].Title;
        }
      }
      if (
        item.list.filter((e) => e.Title === "CST").length > 0 ||
        item.category === "finish"
      ) {
        tempCstIndex = i;
        if (item.list.find((e) => e.Title === "CST") !== undefined) {
          let cst = item.list.find((e) => e.Title === "CST");
          cstColorProps = { ...cst };
          customColors = allCustomColors.filter(
            (c) => !item.list.find((e) => e.Title === c.Title)
          );
        }
      }
      return item;
    });
    this.setState(
      {
        configuratorValues: options,
        productAccessories: tempAccessories,
        description: description,
        cstIndex: tempCstIndex,
        cstColorProps: cstColorProps,
      },
      () => this.handleAccessoriesColor()
    );
  }

  handleTierSelect(e) {
    let tier = e.value;
    ProductConfiguratorHandler.setCustomerTier(tier)
      .then((response) => {
        if (!response.success) {
          this.addNotification(
            ErrorMessages.CustomerTierNotRegistered,
            "error"
          );
        }
        this.trackCustomerTierSelection(tier);
        this.setState({
          tierSelected: tier,
          tierPricing:
            tier === this.state.tierList[0] ? priceType[0] : priceType[1],
          customerTier: tier,
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.CustomerTierMissing, "error");
      });
  }

  getAccessoryPrice(index) {
    let tempAccessories = this.state.productAccessories;
    let desc = tempAccessories[index].Accessories.replace(
      "CST",
      tempAccessories[index].color
    );
    desc = desc.replace("`", "'");
    let data = {
      description: desc,
      wattage: "",
      cstColor: tempAccessories[index].color,
      productId: this.state.productDetails.id.toString(),
      setId:
        this.state.entireConfigurator.configurator[this.state.currentSetIndex]
          .setId,
      customer_name: this.state.singleCustomer
        ? this.state.branchName
        : this.ternaryEvaluation(
            this.state.selectedCustomer,
            null,
            "",
            this.state.selectedCustomer
          ),
    };

    ProductConfiguratorHandler.getStockCodeAndPrice(data)
      .then((response) => {
        tempAccessories[index].SalePrice = response.data.salePrice;
        this.setState({
          productAccessories: tempAccessories,
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.StockPrice, "error");
      });
    this.setState({ productAccessories: tempAccessories });
  }

  getCustomerList() {
    if (this.state.singleCustomer) {
      return null;
    } else {
      return (
        <div>
          <Row style={{ fontSize: "16px" }}>
            <Col md={6} sm={12} xs={12}>
              <h4>Select Customer</h4>
              <Creatable
                maxMenuHeight={190}
                value={{
                  value: this.state.selectedCustomer,
                  label:
                    this.state.selectedCustomer === null ||
                    this.state.selectedCustomer === ""
                      ? "Select..."
                      : this.state.selectedCustomer,
                }}
                aria-label="Select Customer"
                styles={customStyles}
                isClearable
                onChange={this.handleCustomerSelect}
                options={this.state.customerList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <h4>Customer Tier</h4>
              {this.state.isNewCustomer ? (
                <Select
                  maxMenuHeight={190}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  value={{
                    value: this.state.tierSelected,
                    label: this.state.tierSelected,
                  }}
                  aria-label="Select Tier"
                  styles={customStyles}
                  onChange={this.handleTierSelect}
                  options={this.state.tierList.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "lightgray",
                    },
                  })}
                />
              ) : (
                <FormControl
                  readOnly={this.state.isNewCustomer ? false : true}
                  type="text"
                  style={{ height: "20px", minHeight: "34px" }}
                  value={this.state.customerTier}
                  aria-label="text"
                />
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }

  handleSingleCustomerSelect(tld, customer) {
    let customerInfo = customer.value.split("-tier-");
    sessionStorage.setItem("customerName", tld);
    customerName = customer.label;
    customerTLD = tld;
    this.setState({
      isNewCustomer: true,
      selectedCustomer: customer.label,
      tierPricing:
        this.state.productType === "led"
          ? priceString + customerInfo[1]
          : priceString + customerInfo[2],
      ledTier: priceString + customerInfo[1],
      constructionTier: priceString + customerInfo[2],
      customerTier:
        customerInfo[1] === "1"
          ? this.state.tierList[0]
          : this.ternaryEvaluation(
              customerInfo[2],
              "1",
              this.state.tierList[1],
              this.state.tierList[2]
            ),
    });
  }

  handleCustomerSelect(event) {
    if (event === null) {
      sessionStorage.setItem("customerName", "");
      customerName = "";
      this.trackCustomerSelection(customerName);
      this.setState({
        isNewCustomer: false,
        selectedCustomer: "",
        tierPricing: priceType[0],
        customerTier: this.state.tierList[0],
        ledTier: priceType[0],
        constructionTier: priceType[0],
      });
      if (this.state.productDetails !== null) {
        this.getProductConfigDetails(
          this.state.productDetails.id,
          this.state.productDetails.title
        );
      }

      return;
    }
    let customerInfo = event.value.split("-tier-");

    if (customerInfo.length > 1) {
      sessionStorage.setItem("customerName", customerInfo[0]);
      this.trackCustomerSelection(customerInfo[0]);
      this.setState({
        isNewCustomer: false,
        selectedCustomer: customerInfo[0],
        ledTier: priceString + customerInfo[1],
        constructionTier: priceString + customerInfo[2],
        tierPricing:
          this.state.productType === "led"
            ? priceString + customerInfo[1]
            : priceString + customerInfo[2],
        customerTier:
          customerInfo[1] === "1"
            ? this.state.tierList[0]
            : this.ternaryEvaluation(
                customerInfo[2],
                "1",
                this.state.tierList[1],
                this.state.tierList[2]
              ),
      });
    } else {
      sessionStorage.setItem("customerName", event.value);
      this.trackCustomerSelection(event.value);
      this.setState({
        isNewCustomer: true,
        selectedCustomer: event.value,
        tierPricing: priceType[0],
        customerTier: this.state.tierList[0],
        ledTier: priceType[0],
        constructionTier: priceType[0],
        tierSelected: this.state.tierList[0],
      });
    }
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";

    if (this.state.productDetails !== null) {
      this.getProductConfigDetails(
        this.state.productDetails.id,
        this.state.productDetails.title
      );
    }
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <div className={"dlc-container"} style={{ minWidth: "300px" }}>
          {this.QuickConfiguratorSection()}
        </div>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
        <ConfirmationPopUp
          show={this.state.showConfirmationPopup}
          title="Clear Estimate Summary!"
          text={this.state.message}
          clearTableData={this.clearTableData.bind(this)}
          handleClearClose={this.handleClearClose.bind(this)}
        />
      </div>
    );
  }

  QuickConfiguratorSection() {
    return (
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Panel
            eventKey={2}
            key="Config"
            className="collapsible-panel"
            defaultExpanded
          >
            <Panel.Body
              style={{
                minHeight: "200px",
                backgroundColor: "rgb(245, 245, 245)",
              }}
            >
              <div
                role="menu"
                id="collapsible-panel--heading"
                className="panel-heading"
              >
                <Panel.Heading
                  className="quick-tabs"
                  role="menuitem"
                  aria-labelledby="configuratorTabs"
                >
                  <Tabs
                    defaultActiveKey={1}
                    id="uncontrolled-tab-example"
                    className="content-tabs"
                    onSelect={(key) => this.onSelectTab(key)}
                  >
                    <br />
                    {this.getCustomerList()}
                    <br />
                    <br />
                    {this.LEDConfiguratorSection()}
                    {this.ConstructionConfiguratorSection()}
                  </Tabs>
                </Panel.Heading>
              </div>
              <Row>
                <Col
                  md={4}
                  sm={8}
                  xs={12}
                  style={{ textAlign: "right", float: "right" }}
                >
                  <span style={{ color: "#074391" }}>
                    {Disclaimers.QuickConfigurator_DisclaimerText}
                  </span>
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    );
  }

  LEDConfiguratorSection() {
    return (
      <Tab eventKey={1} title="LED Configurator">
        {this.state.isLoading
          ? this.getProgress()
          : this.selectProductSection()}
        <Row>
          {this.state.configuratorValues.length ? (
            <div>{this.getProductConfigurator()}</div>
          ) : null}
        </Row>
      </Tab>
    );
  }

  selectProductSection() {
    return (
      <Row>
        <Col md={6}>
          <form>
            <h4>Select Product</h4>
            <Select
              maxMenuHeight={190}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              aria-label="Select Product"
              value={{
                value: this.state.productDetails
                  ? this.state.productDetails.id
                  : "Select...",
                label: this.state.productDetails
                  ? this.state.productDetails.title
                  : "Select...",
              }}
              onChange={this.handleProductChange}
              styles={customStyles}
              options={this.generateProductOptions()}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </form>
        </Col>
        <Col md={6}>{this.generateProductDescription()}</Col>
      </Row>
    );
  }

  ConstructionConfiguratorSection() {
    return (
      <Tab eventKey={2} title="Construction Configurator">
        {this.state.isLoading ? (
          this.getProgress()
        ) : (
          <Row>
            <Col md={6}>
              <form>
                <h4>Select Product</h4>

                <Select
                  maxMenuHeight={190}
                  menuPlacement="auto"
                  aria-label="Select Product"
                  menuPortalTarget={document.body}
                  value={{
                    value: this.state.productDetails
                      ? this.state.productDetails.id
                      : "Select...",
                    label: this.state.productDetails
                      ? this.state.productDetails.title
                      : "Select...",
                  }}
                  onChange={this.handleProductChange}
                  styles={customStyles}
                  options={this.generateProductOptions()}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "lightgray",
                    },
                  })}
                />
              </form>
            </Col>
            <Col md={6}>{this.generateProductDescription()}</Col>
          </Row>
        )}
        <Row>
          <div>
            {this.state.productDetails !== null
              ? this.getConstructionProductConfigurator()
              : null}
          </div>
        </Row>
      </Tab>
    );
  }

  goldenLegendSummary() {
    let goldenPresent = false;

    let productSalePrice1 = +parseFloat(this.state.productSalePrice);
    let productGoldenPrice1 = +parseFloat(this.state.productGoldenPrice);

    if (this.state.productSalePrice && productSalePrice1 !== 0) {
      if (
        productGoldenPrice1 <= productSalePrice1 &&
        productGoldenPrice1 !== 0
      ) {
        goldenPresent = true;
      }
    } else {
      if (this.state.productGoldenPrice !== null && productGoldenPrice1 !== 0) {
        goldenPresent = true;
      }
    }

    this.state.productAccessories
      .filter((item) => item.isSelected)
      .forEach((item) => {
        let itemGoldenPrice = +parseFloat(item.GoldenPrice);
        let itemSalePrice = +parseFloat(item.SalePrice);
        if (itemSalePrice === 0) {
          if (itemGoldenPrice !== 0) {
            goldenPresent = true;
          }
        } else {
          if (itemGoldenPrice !== 0 && itemGoldenPrice <= itemSalePrice) {
            goldenPresent = true;
          }
        }
      });

    this.state.productFamily
      .filter((item) => item.isSelected)
      .forEach((item) => {
        let itemGoldenPrice = +parseFloat(item.GoldenPrice);
        let itemSalePrice = +parseFloat(item.SalePrice);
        if (itemSalePrice === 0) {
          if (itemGoldenPrice !== 0) {
            goldenPresent = true;
          }
        } else {
          if (itemGoldenPrice !== 0 && itemGoldenPrice <= itemSalePrice) {
            goldenPresent = true;
          }
        }
      });

    if (
      goldenPresent &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <div>
          <br />
          <Row>
            <span className="gold-pricing">
              {DisplayMessages.GoldenPriceLine1}
            </span>
            {DisplayMessages.GoldenPriceLine2}
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  onSelectTab(key) {
    let type = key === 1 ? "led" : "construction";

    this.trackConfiguratorTabClick(type);
    this.setState(
      {
        productType: type,
        productDetails: null,
        addedToOrderSummary: false,
        configuratorValues: [],
        isLoading: true,
        tierPricing:
          type === "led" ? this.state.ledTier : this.state.constructionTier,
        customerTier:
          this.state.ledTier === priceType[0]
            ? this.state.tierList[0]
            : this.ternaryEvaluation(
                this.state.constructionTier,
                priceType[0],
                this.state.tierList[1],
                this.state.tierList[2]
              ),
      },
      () => this.getProducts()
    );
  }

  getProgress() {
    return (
      <div className="loading-icon">
        <ReactLoading type="spokes" color="#797575" height={50} width={50} />
      </div>
    );
  }

  getProductConfigurator() {
    return (
      <div style={{ marginTop: "20px", fontSize: "16px", align: "center" }}>
        <Row>
          {this.familyDropdown()}
          {this.wattageDropdown()}
          {this.state.configuratorValues &&
            this.state.configuratorValues.map((item, i) => {
              let optionlist =
                item.list[0] !== undefined && item.list[0].ValueIndex === "1"
                  ? []
                  : this.getDefaultList();
              item.list.forEach((element, index) => {
                if (i === this.state.cstIndex && element.Title === "CST") {
                  customColors.forEach((cstm) => {
                    optionlist.push(
                      <option key={cstm.Title} value={cstm.Title}>
                        {cstm.Title}, {cstm.Name === "N/A" ? null : cstm.Name}
                        {parseFloat(
                          this.state.cstColorProps &&
                            this.state.cstColorProps[this.state.tierPricing]
                        ) > 0
                          ? " ($" +
                            parseFloat(
                              this.state.cstColorProps[this.state.tierPricing]
                            ).toFixed(2) +
                            ")"
                          : null}
                      </option>
                    );
                  });
                } else {
                  optionlist.push(
                    <option key={element.Title} value={element.Title}>
                      {element.Title},{" "}
                      {element.Name === "N/A" ? null : element.Name}
                      {parseFloat(element[this.state.tierPricing]) > 0
                        ? " ($" +
                          parseFloat(element[this.state.tierPricing]).toFixed(
                            2
                          ) +
                          ")"
                        : null}
                    </option>
                  );
                }
              });

              return item.list.length > 0 ? (
                <Col md={6} sm={12} xs={12} key={item.category}>
                  <h4>Select {item.category}</h4>
                  <FormGroup controlId="formControlsSelect">
                    <FormControl
                      componentClass="select"
                      placeholder={item.category}
                      value={this.state.description[i] || ""}
                      onChange={(e) => this.generateDescription(item, e, i)}
                    >
                      {optionlist}
                    </FormControl>
                  </FormGroup>
                </Col>
              ) : (
                ""
              );
            })}
        </Row>
        {this.accessoriesProductFinishSection()}
        <br />
        <Row>{this.getAccessories()}</Row>
        <Row>{this.goldenLegendSummary()}</Row>
        {this.getProductSummary()}
      </div>
    );
  }

  familyDropdown() {
    return (
      <Col md={6} sm={12} xs={12}>
        <h4>Select family</h4>

        <Select
          maxMenuHeight={190}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          value={{
            value: this.state.family ? this.state.family : "Select ...",
            label: this.state.family ? this.state.family : "Select ...",
          }}
          aria-label="Family"
          styles={customStyles}
          onChange={this.onChangeFamily}
          options={this.generateFamilyOptions()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "lightgray",
              primary: "lightgray",
            },
          })}
        />
      </Col>
    );
  }

  wattageDropdown() {
    return (
      <Col md={6} sm={12} xs={12}>
        {this.state.familyList[0] !== undefined &&
        this.state.familyList[0].startsWith("UC") ? (
          <h4>Select length</h4>
        ) : (
          <h4>Select wattage</h4>
        )}

        <Select
          maxMenuHeight={190}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          value={{
            value: this.state.wattage ? this.state.wattage : "Select...",
            label: this.state.wattage ? this.state.wattage : "Select...",
          }}
          aria-label="Wattage"
          styles={customStyles}
          onChange={this.onChangeWattage}
          options={this.generateWattageOptions()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "lightgray",
              primary: "lightgray",
            },
          })}
        />
      </Col>
    );
  }

  accessoriesProductFinishSection() {
    return (
      <div>
        {this.state.productAccessories.length > 0 &&
        this.state.productAccessories.filter(
          (item) => item.StandardFinishes !== ""
        ).length > 0 ? (
          <div>
            <label
              onChange={() => this.handleAccessoriesCST()}
              style={{ padding: "10px" }}
            >
              <input
                type="checkbox"
                value={this.state.isChecked}
                defaultChecked
                aria-label="Apply Product Finish"
              />
            </label>
            <label>{DisplayMessages.ApplyProductFinish}</label>
          </div>
        ) : null}
      </div>
    );
  }

  handleAccessoriesCST() {
    this.setState(
      {
        isChecked: !this.state.isChecked,
      },
      () => {
        this.trackProductFinishApplySelection();
        this.handleAccessoriesColor();
      }
    );
  }

  handleAccessoriesColor() {
    let colorOptions = this.state.productAccessories;
    if (this.state.isChecked) {
      colorOptions.forEach((item, i) => {
        item.color =
          this.state.cstIndex !== -1
            ? this.state.description[this.state.cstIndex]
            : "";
        item.price =
          item.StandardFinishes !== "" &&
          !item.StandardFinishes.includes(item.color) &&
          item.CustomFinishAdder !== "" &&
          parseInt(item.CustomFinishAdder, 10) !== 0
            ? parseFloat(item.CustomFinishAdder).toFixed(2)
            : 0;
        if (item.isSelected) {
          this.getAccessoryPrice(i);
        }
      });
    }
    this.setState({
      productAccessories: colorOptions,
    });
  }

  getProductSummary() {
    let price = 0;
    let productPrice = 0;
    if (this.state.productType === "led") {
      this.state.productAccessories
        .filter((item) => item.isSelected)
        .forEach((item) => {
          let accessoryQty = item.Quantity <= 0 ? 0.0 : item.Quantity;
          let accessoryPrice = 0;
          if (parseFloat(item.SalePrice) === 0) {
            if (parseFloat(item.GoldenPrice) === 0) {
              accessoryPrice = parseFloat(
                parseFloat(accessoryPrice) +
                  parseFloat(
                    parseFloat(item[this.state.tierPricing]) +
                      parseFloat(item.price)
                  ) *
                    parseFloat(accessoryQty)
              ).toFixed(2);
            } else {
              accessoryPrice = parseFloat(
                parseFloat(accessoryPrice) +
                  parseFloat(
                    parseFloat(item.GoldenPrice) + parseFloat(item.price)
                  ) *
                    parseFloat(accessoryQty)
              ).toFixed(2);
            }
          } else {
            if (
              parseFloat(item.GoldenPrice) === 0 ||
              parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice)
            ) {
              accessoryPrice = parseFloat(
                parseFloat(accessoryPrice) +
                  parseFloat(
                    parseFloat(item.SalePrice) + parseFloat(item.price)
                  ) *
                    parseFloat(accessoryQty)
              ).toFixed(2);
            } else {
              accessoryPrice = parseFloat(
                parseFloat(accessoryPrice) +
                  parseFloat(
                    parseFloat(item.GoldenPrice) + parseFloat(item.price)
                  ) *
                    parseFloat(accessoryQty)
              ).toFixed(2);
            }
          }
          price = parseFloat(
            parseFloat(price) + parseFloat(accessoryPrice)
          ).toFixed(2);
        });
      let qty =
        this.state.productQuantity <= 0 ? 0.0 : this.state.productQuantity;
      if (
        this.state.productSalePrice &&
        parseFloat(this.state.productSalePrice) !== 0
      ) {
        if (
          parseFloat(this.state.productGoldenPrice) >
            parseFloat(this.state.productSalePrice) ||
          parseFloat(this.state.productGoldenPrice) === 0
        ) {
          productPrice = this.state.productSalePrice;
        } else {
          productPrice = this.state.productGoldenPrice;
        }
      } else {
        if (
          this.state.productGoldenPrice === null ||
          parseFloat(this.state.productGoldenPrice) === 0
        ) {
          productPrice = this.state.productPrice;
        } else {
          productPrice = this.state.productGoldenPrice;
        }
      }
      price = parseFloat(
        parseFloat(price) + parseFloat(productPrice) * parseFloat(qty)
      ).toFixed(2);
    } else {
      this.state.productFamily
        .filter((item) => item.isSelected)
        .forEach((item) => {
          let qty = item.Quantity <= 0 ? 0.0 : item.Quantity;
          let constructionPrice = 0;
          if (parseFloat(item.SalePrice) === 0) {
            if (parseFloat(item.GoldenPrice) === 0) {
              constructionPrice = parseFloat(
                parseFloat(constructionPrice) +
                  parseFloat(item[this.state.tierPricing]) * parseFloat(qty)
              ).toFixed(2);
            } else {
              constructionPrice = parseFloat(
                parseFloat(constructionPrice) +
                  parseFloat(item.GoldenPrice) * parseFloat(qty)
              ).toFixed(2);
            }
          } else {
            if (
              parseFloat(item.GoldenPrice) === 0 ||
              parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice)
            ) {
              constructionPrice = parseFloat(
                parseFloat(constructionPrice) +
                  parseFloat(item.SalePrice) * parseFloat(qty)
              ).toFixed(2);
            } else {
              constructionPrice = parseFloat(
                parseFloat(constructionPrice) +
                  parseFloat(item.GoldenPrice) * parseFloat(qty)
              ).toFixed(2);
            }
          }

          price = parseFloat(
            parseFloat(price) + parseFloat(constructionPrice)
          ).toFixed(2);
        });
      price = parseFloat(price).toFixed(2);
    }
    let priceTag = price.toString();
    let productSalePrice1 = +parseFloat(this.state.productSalePrice);
    let productGoldenPrice1 = +parseFloat(this.state.productGoldenPrice);

    return (
      <Col md={12} sm={12} xs={12} style={{ marginTop: "30px" }}>
        <h4>Product Summary</h4>
        <div className="horizontal-line" />
        {this.productSummaryHeaders()}
        {this.productSummaryConfiguratorBody(
          productSalePrice1,
          productGoldenPrice1
        )}
        {this.productSummaryAccessoriesBody()}
        <br />
        {this.addToEstimateSummarySection(priceTag)}
      </Col>
    );
  }

  productSummaryHeaders() {
    return (
      <Row
        className="product-summary-row product-summary-section"
        style={{ fontWeight: "bold", marginBottom: "1%" }}
      >
        <Col md={1} sm={1} xs={4} className="product-summary-columns" />
        <Col md={3} sm={3} xs={8} className="product-summary-columns">
          Description
        </Col>
        {this.state.pricingAccess ? (
          <Col md={1} sm={1} xs={4} className="product-summary-columns">
            Price
          </Col>
        ) : null}
        <Col md={1} sm={1} xs={4} className="product-summary-columns">
          Quantity
        </Col>
        {this.state.pricingAccess ? (
          <Col md={1} sm={1} xs={4} className="product-summary-columns">
            Total Price
          </Col>
        ) : null}
        <Col md={1} sm={1} xs={4} className="product-summary-columns">
          Lead Time
        </Col>
        <Col md={1} sm={1} xs={4} className="product-summary-columns" />
      </Row>
    );
  }

  productSummaryConfiguratorBody(productSalePrice1, productGoldenPrice1) {
    return this.state.productType === "led"
      ? this.LEDProductSummaryBody(productSalePrice1, productGoldenPrice1)
      : this.constructionProductConfiguratorBody();
  }

  LEDProductSummaryBody(productSalePrice1, productGoldenPrice1) {
    return (
      <div>
        <Row className="product-summary-row product-summary-section">
          <Col md={1} sm={1} xs={4} className="product-summary-columns">
            {this.LEDSpecialOffersIconSection(
              productSalePrice1,
              productGoldenPrice1
            )}
          </Col>
          <Col md={3} sm={3} xs={8} className="product-summary-columns">
            {`${this.state.wattage}-${this.state.description
              .filter((e) => e)
              .join("-")}`}
          </Col>
          {this.state.pricingAccess ? (
            <Col md={1} sm={1} xs={4} className="product-summary-columns">
              {this.ledUnitProductPriceSection(
                productSalePrice1,
                productGoldenPrice1
              )}
            </Col>
          ) : null}
          <Col md={1} sm={1} xs={4} className="product-summary-columns">
            <FormControl
              type="number"
              min="1"
              max="10000"
              pattern="[0-9]*"
              onBlur={this.onBlurQtyChange}
              onChange={this.onProductQtyChange}
              value={this.state.productQuantity}
            />
          </Col>

          {this.state.pricingAccess ? (
            <Col md={1} sm={1} xs={4} className="product-summary-columns">
              {this.ledTotalProductPriceSection(
                productSalePrice1,
                productGoldenPrice1,
                false
              )}
            </Col>
          ) : null}
          <Col md={1} sm={1} xs={4} className="product-summary-columns">
            <OverlayTrigger
              trigger={["click", "hover"]}
              rootClose
              placement="bottom"
              overlay={this.popoverRight(
                this.state.stockdetails,
                this.state.productQuantity
              )}
            >
              <Button
                onClick={() =>
                  this.viewButtonClicked(
                    "product",
                    0,
                    this.state.productQuantity
                  )
                }
                onMouseOver={() =>
                  this.viewButtonClicked(
                    "product",
                    0,
                    this.state.productQuantity
                  )
                }
              >
                View
              </Button>
            </OverlayTrigger>
          </Col>
          <Col md={1} sm={1} xs={4} className="product-summary-columns">
            <Button
              onClick={() =>
                this.copyLEDItem(productSalePrice1, productGoldenPrice1)
              }
              aria-label="Copy to clipboard"
              className="glyphicon glyphicon-duplicate"
            />
          </Col>
        </Row>
        <br />
      </div>
    );
  }

  copyLEDItem(productSalePrice1, productGoldenPrice1) {
    let totalPrice = this.ledTotalProductPriceSection(
      productSalePrice1,
      productGoldenPrice1,
      true
    );

    let description = `${this.state.wattage}-${this.state.description
      .filter((e) => e)
      .join("-")}`;

    let data = {
      description: description,
      productId: this.state.productDetails.id.toString(),
    };

    ProductConfiguratorHandler.getInventoryDetails(
      encodeURIComponent(description)
    )
      .then((response) => {
        let copyContent = {
          Stock_Code: response.data.stockdetails[0].StockCode,
          SKU_Description: description,
          Quantity: parseInt(this.state.productQuantity, 10).toString(),
          Total_Price: this.state.pricingAccess ? totalPrice : "NA",
          Availability: response.data.qtyLead[0].LeadTime,
          Spec_Sheet_English: "NA",
          Spec_Sheet_French: "NA",
        };

        CopyToClipboard(
          data,
          copyContent,
          this.addNotification,
          "Quick Configurator"
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_InventoryDetails,
          "error"
        );
      });
  }

  LEDSpecialOffersIconSection(productSalePrice1, productGoldenPrice1) {
    if (
      this.state.productSalePrice &&
      productSalePrice1 !== 0 &&
      (productGoldenPrice1 > productSalePrice1 ||
        this.state.productGoldenPrice === null ||
        productGoldenPrice1 === 0) &&
      this.state.pricingAccess
    ) {
      return (
        <span style={{ float: "right" }}>
          <img
            src={fetchResourceData(fixedLink.specialOffersIcon)}
            alt="Support Docs"
            style={{
              height: "20px",
              width: "20px",
              display: "inline",
            }}
          />
        </span>
      );
    } else return null;
  }

  ledUnitProductPriceSection(productSalePrice1, productGoldenPrice1) {
    if (this.state.productSalePrice && productSalePrice1 !== 0) {
      return (
        <div>
          {productGoldenPrice1 > productSalePrice1 ||
          this.state.productGoldenPrice === null ||
          productGoldenPrice1 === 0 ? (
            <div>
              <span style={{ textDecorationLine: "line-through" }}>
                {this.state.productPrice == null
                  ? ""
                  : "$" + parseFloat(this.state.productPrice).toFixed(2)}{" "}
              </span>
              <span style={{ color: "red" }}>
                {this.state.productSalePrice == null
                  ? ""
                  : parseFloat(this.state.productSalePrice).toFixed(2)}{" "}
              </span>
            </div>
          ) : (
            <span className="gold-pricing">
              ${parseFloat(this.state.productGoldenPrice).toFixed(2)}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {this.state.productGoldenPrice === null ||
          productGoldenPrice1 === 0 ? (
            <span>
              {this.state.productPrice == null
                ? ""
                : "$" + parseFloat(this.state.productPrice).toFixed(2)}{" "}
            </span>
          ) : (
            <span className="gold-pricing">
              ${parseFloat(this.state.productGoldenPrice).toFixed(2)}
            </span>
          )}
        </div>
      );
    }
  }

  ledTotalProductPriceSection(
    productSalePrice1,
    productGoldenPrice1,
    isCopyToClipboard
  ) {
    return isCopyToClipboard
      ? this.getLEDTotalProductPrice(productSalePrice1, productGoldenPrice1)
      : this.showLEDTotalProductPrice(productSalePrice1, productGoldenPrice1);
  }

  showLEDTotalProductPrice(productSalePrice1, productGoldenPrice1) {
    let qty =
      this.state.productQuantity === ""
        ? 0
        : parseFloat(this.state.productQuantity);

    if (this.state.productSalePrice && productSalePrice1 !== 0) {
      return (
        <div>
          {productGoldenPrice1 > productSalePrice1 ||
          this.state.productGoldenPrice === null ||
          productGoldenPrice1 === 0 ? (
            <div>
              <span>
                {this.state.productSalePrice == null
                  ? ""
                  : (parseFloat(this.state.productSalePrice) * qty).toFixed(
                      2
                    )}{" "}
              </span>
            </div>
          ) : (
            <span className="gold-pricing">
              ${(parseFloat(this.state.productGoldenPrice) * qty).toFixed(2)}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {this.state.productGoldenPrice === null ||
          productGoldenPrice1 === 0 ? (
            <span>
              {this.state.productPrice == null
                ? ""
                : "$" +
                  (parseFloat(this.state.productPrice) * qty).toFixed(2)}{" "}
            </span>
          ) : (
            <span className="gold-pricing">
              ${(parseFloat(this.state.productGoldenPrice) * qty).toFixed(2)}
            </span>
          )}
        </div>
      );
    }
  }

  getLEDTotalProductPrice(productSalePrice1, productGoldenPrice1) {
    let qty =
      this.state.productQuantity === ""
        ? 0
        : parseFloat(this.state.productQuantity);

    if (this.state.productSalePrice && productSalePrice1 !== 0) {
      return productGoldenPrice1 > productSalePrice1 ||
        this.state.productGoldenPrice === null ||
        productGoldenPrice1 === 0
        ? this.ternaryEvaluation(
            this.state.productSalePrice,
            null,
            "",
            (parseFloat(this.state.productSalePrice) * qty).toFixed(2)
          )
        : (parseFloat(this.state.productGoldenPrice) * qty).toFixed(2);
    } else {
      return this.state.productGoldenPrice === null || productGoldenPrice1 === 0
        ? this.ternaryEvaluation(
            this.state.productPrice,
            null,
            "",
            (parseFloat(this.state.productPrice) * qty).toFixed(2)
          )
        : (parseFloat(this.state.productGoldenPrice) * qty).toFixed(2);
    }
  }

  constructionProductConfiguratorBody() {
    return (
      <Row>
        {this.state.productFamily.map((item, i) => {
          return (
            <div key={item.Title.toString()}>
              {item.isSelected ? (
                <div>
                  <Row className="product-summary-row product-summary-section">
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                    >
                      {this.specialOffersIconSection(item)}
                    </Col>

                    <Col
                      md={3}
                      sm={3}
                      xs={8}
                      className="product-summary-columns"
                    >
                      {item.Title.toString()}
                    </Col>

                    {this.state.pricingAccess ? (
                      <Col md={1} sm={1} xs={4}>
                        {this.constructionUnitPriceSection(item)}
                      </Col>
                    ) : null}
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <FormControl
                        name={i}
                        type="number"
                        min="0"
                        max="10000"
                        pattern="[0-9]*"
                        onBlur={this.onBlurConstructionQtyChange}
                        onChange={this.onConstructionQtyChange.bind(this)}
                        aria-label="Quantity"
                        value={item.Quantity}
                      />
                    </Col>

                    {this.state.pricingAccess ? (
                      <Col
                        md={1}
                        sm={1}
                        xs={4}
                        className="product-summary-columns"
                      >
                        {this.constructionTotalPriceSection(item, false)}
                      </Col>
                    ) : null}
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                    >
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        rootClose
                        show={item.ViewClicked}
                        placement="bottom"
                        overlay={this.popoverRight(
                          this.state.stockdetails,
                          item.Quantity
                        )}
                      >
                        <Button
                          onClick={() => {
                            this.viewButtonClicked("product", i, item.Quantity);
                          }}
                          onMouseOver={() => {
                            this.viewButtonClicked("product", i, item.Quantity);
                          }}
                        >
                          View
                        </Button>
                      </OverlayTrigger>
                    </Col>
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                    >
                      <Button
                        onClick={() => this.copyItem(item)}
                        aria-label="Copy to clipboard"
                        className="glyphicon glyphicon-duplicate"
                      />
                    </Col>
                  </Row>
                  <br />
                </div>
              ) : null}
            </div>
          );
        })}
      </Row>
    );
  }

  copyAccessory(item) {
    let totalPrice = this.accessoryTotalPriceSection(item, true);

    let description = item.Accessories.replace("CST", item.color).toString();

    let data = {
      description: description,
      productId: this.state.productDetails.id.toString(),
    };

    ProductConfiguratorHandler.getInventoryDetails(
      encodeURIComponent(description)
    )
      .then((response) => {
        let copyContent = {
          Stock_Code: response.data.stockdetails[0].StockCode,
          SKU_Description: description,
          Quantity: parseInt(item.Quantity, 10).toString(),
          Total_Price: this.state.pricingAccess ? totalPrice : "NA",
          Availability: response.data.qtyLead[0].LeadTime,
          Spec_Sheet_English: "NA",
          Spec_Sheet_French: "NA",
        };

        CopyToClipboard(
          data,
          copyContent,
          this.addNotification,
          "Quick Configurator"
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_InventoryDetails,
          "error"
        );
      });
  }

  copyItem(item) {
    let totalPrice = this.constructionTotalPriceSection(item, true);

    let description = item.Title.toString();

    let data = {
      description: description,
      productId: this.state.productDetails.id.toString(),
    };

    ProductConfiguratorHandler.getInventoryDetails(
      encodeURIComponent(description)
    )
      .then((response) => {
        let copyContent = {
          Stock_Code: item.StockCode,
          SKU_Description: description,
          Quantity: parseInt(item.Quantity, 10).toString(),
          Total_Price: this.state.pricingAccess ? totalPrice : "NA",
          Availability: response.data.qtyLead[0].LeadTime,
          Spec_Sheet_English: "NA",
          Spec_Sheet_French: "NA",
        };

        CopyToClipboard(
          data,
          copyContent,
          this.addNotification,
          "Quick Configurator"
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_InventoryDetails,
          "error"
        );
      });
  }

  specialOffersIconSection(item) {
    if (
      parseFloat(item.SalePrice) !== 0 &&
      (parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
        parseFloat(item.GoldenPrice) === 0) &&
      this.state.pricingAccess
    ) {
      return (
        <span style={{ float: "right" }}>
          <img
            src={fetchResourceData(fixedLink.specialOffersIcon)}
            alt="Support Docs"
            style={{
              height: "20px",
              width: "20px",
              display: "inline",
            }}
          />
        </span>
      );
    } else return null;
  }

  constructionUnitPriceSection(item) {
    if (parseFloat(item.SalePrice) !== 0) {
      return (
        <div>
          {parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
          parseFloat(item.GoldenPrice) === 0 ? (
            <div>
              <span>
                <span
                  style={{
                    textDecorationLine: "line-through",
                  }}
                >
                  ${parseFloat(item[this.state.tierPricing]).toFixed(2)}
                </span>
                <span> </span>
                <span style={{ color: "red" }}>
                  {parseFloat(item.SalePrice).toFixed(2)}
                </span>
              </span>
            </div>
          ) : (
            <span className="gold-pricing">
              ${parseFloat(item.GoldenPrice).toFixed(2)}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {parseFloat(item.GoldenPrice) === 0 ? (
            <span>${parseFloat(item[this.state.tierPricing]).toFixed(2)}</span>
          ) : (
            <span className="gold-pricing">
              ${parseFloat(item.GoldenPrice).toFixed(2)}
            </span>
          )}
        </div>
      );
    }
  }

  constructionTotalPriceSection(item, isCopyToClipboard) {
    return isCopyToClipboard
      ? this.getConstructionItemTotalPrice(item)
      : this.showConstructionItemTotalPrice(item);
  }

  showConstructionItemTotalPrice(item) {
    let qty = item.Quantity === "" ? 0 : item.Quantity;

    if (parseFloat(item.SalePrice) !== 0) {
      return (
        <div>
          {parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
          parseFloat(item.GoldenPrice) === 0 ? (
            <div>
              <span>
                <span>{(parseFloat(item.SalePrice) * qty).toFixed(2)}</span>
              </span>
            </div>
          ) : (
            <span className="gold-pricing">
              ${(parseFloat(item.GoldenPrice) * qty).toFixed(2)}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {parseFloat(item.GoldenPrice) === 0 ? (
            <span>
              ${(parseFloat(item[this.state.tierPricing]) * qty).toFixed(2)}
            </span>
          ) : (
            <span className="gold-pricing">
              ${(parseFloat(item.GoldenPrice) * qty).toFixed(2)}
            </span>
          )}
        </div>
      );
    }
  }

  getConstructionItemTotalPrice(item) {
    let qty = item.Quantity === "" ? 0 : item.Quantity;

    if (parseFloat(item.SalePrice) !== 0) {
      return parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
        parseFloat(item.GoldenPrice) === 0
        ? (parseFloat(item.SalePrice) * qty).toFixed(2)
        : (parseFloat(item.GoldenPrice) * qty).toFixed(2);
    } else {
      return parseFloat(item.GoldenPrice) === 0
        ? (parseFloat(item[this.state.tierPricing]) * qty).toFixed(2)
        : (parseFloat(item.GoldenPrice) * qty).toFixed(2);
    }
  }

  productSummaryAccessoriesBody() {
    return (
      <Row>
        {this.state.productAccessories.map((item, i) => {
          return (
            <div key={item.Accessories.replace("CST", item.color).toString()}>
              {item.isSelected ? (
                <div>
                  <Row className="product-summary-row product-summary-section">
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                    >
                      {this.specialOffersIconSection(item)}
                    </Col>

                    <Col
                      md={3}
                      sm={3}
                      xs={8}
                      className="product-summary-columns"
                    >
                      {item.Accessories.replace("CST", item.color).toString()}
                    </Col>
                    {this.state.pricingAccess ? (
                      <Col
                        md={1}
                        sm={1}
                        xs={4}
                        className="product-summary-columns"
                      >
                        {this.accessoryUnitPriceSection(item)}
                      </Col>
                    ) : null}
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <FormControl
                        name={i}
                        type="number"
                        min="0"
                        max="10000"
                        pattern="[0-9]*"
                        onBlur={this.onBlurAccessoryQtyChange}
                        onChange={this.accessoryQtyChange.bind(this)}
                        value={item.Quantity}
                      />
                    </Col>
                    {this.state.pricingAccess ? (
                      <Col
                        md={1}
                        sm={1}
                        xs={4}
                        className="product-summary-columns"
                      >
                        {this.accessoryTotalPriceSection(item, false)}
                      </Col>
                    ) : null}
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                    >
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        rootClose
                        show={item.ViewClicked}
                        placement="bottom"
                        overlay={this.popoverRight(
                          this.state.stockdetails,
                          item.Quantity
                        )}
                      >
                        <Button
                          onClick={() => {
                            this.viewButtonClicked(item, i, item.Quantity);
                          }}
                          onMouseOver={() => {
                            this.viewButtonClicked(item, i, item.Quantity);
                          }}
                        >
                          View
                        </Button>
                      </OverlayTrigger>
                    </Col>
                    <Col
                      md={1}
                      sm={1}
                      xs={4}
                      className="product-summary-columns"
                    >
                      <Button
                        onClick={() => this.copyAccessory(item)}
                        aria-label="Copy to clipboard"
                        className="glyphicon glyphicon-duplicate"
                      />
                    </Col>
                  </Row>
                  <br />
                </div>
              ) : null}
            </div>
          );
        })}
      </Row>
    );
  }

  accessoryUnitPriceSection(item) {
    if (parseFloat(item.SalePrice) !== 0) {
      return (
        <div>
          {parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
          parseFloat(item.GoldenPrice) === 0 ? (
            <div>
              <span style={{ textDecorationLine: "line-through" }}>
                $
                {parseFloat(
                  parseFloat(item[this.state.tierPricing]) +
                    parseFloat(item.price)
                ).toFixed(2)}
              </span>
              <span> </span>
              <span style={{ color: "red" }}>
                {parseFloat(item.SalePrice).toFixed(2)}
              </span>
            </div>
          ) : (
            <span className="gold-pricing">
              ${parseFloat(item.GoldenPrice).toFixed(2)}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {parseFloat(item.GoldenPrice) === 0 ? (
            <div>
              $
              {parseFloat(
                parseFloat(item[this.state.tierPricing]) +
                  parseFloat(item.price)
              ).toFixed(2)}
            </div>
          ) : (
            <span className="gold-pricing">
              ${parseFloat(item.GoldenPrice).toFixed(2)}
            </span>
          )}
        </div>
      );
    }
  }

  accessoryTotalPriceSection(item, isCopyToClipboard) {
    return isCopyToClipboard
      ? this.getAccessoryTotalPriceSection(item)
      : this.showAccessoryTotalPriceSection(item);
  }

  showAccessoryTotalPriceSection(item) {
    let qty = item.Quantity === "" ? 0 : item.Quantity;

    if (parseFloat(item.SalePrice) !== 0) {
      return (
        <div>
          {parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
          parseFloat(item.GoldenPrice) === 0 ? (
            <div>
              <span>{(parseFloat(item.SalePrice) * qty).toFixed(2)}</span>
            </div>
          ) : (
            <span className="gold-pricing">
              ${(parseFloat(item.GoldenPrice) * qty).toFixed(2)}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {parseFloat(item.GoldenPrice) === 0 ? (
            <div>
              $
              {parseFloat(
                (parseFloat(item[this.state.tierPricing]) +
                  parseFloat(item.price)) *
                  qty
              ).toFixed(2)}
            </div>
          ) : (
            <span className="gold-pricing">
              ${(parseFloat(item.GoldenPrice) * qty).toFixed(2)}
            </span>
          )}
        </div>
      );
    }
  }

  getAccessoryTotalPriceSection(item) {
    let qty = item.Quantity === "" ? 0 : item.Quantity;

    if (parseFloat(item.SalePrice) !== 0) {
      return parseFloat(item.GoldenPrice) > parseFloat(item.SalePrice) ||
        parseFloat(item.GoldenPrice) === 0
        ? (parseFloat(item.SalePrice) * qty).toFixed(2)
        : (parseFloat(item.GoldenPrice) * qty).toFixed(2);
    } else {
      return parseFloat(item.GoldenPrice) === 0
        ? parseFloat(
            (parseFloat(item[this.state.tierPricing]) +
              parseFloat(item.price)) *
              qty
          ).toFixed(2)
        : (parseFloat(item.GoldenPrice) * qty).toFixed(2);
    }
  }

  addToEstimateSummarySection(priceTag) {
    return (
      <div style={{ float: "right" }}>
        {this.state.pricingAccess ? (
          <Row>
            <h4>
              Total Order Price:
              <span style={{ fontWeight: "bold", paddingLeft: "5px" }}>
                ${priceTag}
              </span>
            </h4>
          </Row>
        ) : null}
        <Row>
          <Row>
            <Button
              bsStyle="primary"
              className="button-blue"
              style={{ width: "230px" }}
              onClick={() => this.addToOrderSummary()}
            >
              <span className="glyphicon glyphicon-shopping-cart" />
              <span> </span>
              <span>Add to Estimate Summary</span>
            </Button>
            <br />
            <br />
          </Row>
          <Row>
            <LinkContainer
              to={{ pathname: "/EstimateSummary" }}
              onClick={this.trackViewEstimateSummaryButtonClick}
            >
              <div
                className={
                  this.state.addedToOrderSummary
                    ? "visible-div"
                    : "invisible-div"
                }
              >
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  style={{ width: "230px" }}
                >
                  <span className="glyphicon glyphicon-shopping-cart" />
                  <span> </span>
                  <span>View Estimate Summary</span>
                </Button>
              </div>
            </LinkContainer>
          </Row>
        </Row>
      </div>
    );
  }

  onBlurConstructionQtyChange(e) {
    let tempProduct = [...this.state.productFamily];
    let index = parseInt(e.target.name, 10);
    tempProduct[index].Quantity = e.target.value === "" ? 1 : e.target.value;
    this.setState({ productFamily: tempProduct });
  }

  onConstructionQtyChange(e) {
    let tempProduct = [...this.state.productFamily];
    let index = parseInt(e.target.name, 10);
    tempProduct[index].Quantity = e.target.validity.valid
      ? e.target.value
      : tempProduct[index].Quantity;
    this.setState({ productFamily: tempProduct });
  }

  handleClearClose() {
    this.setState({ showConfirmationPopup: false });
  }

  clearTableData() {
    ProductConfiguratorHandler.clearOrderSummary()
      .then((response) => {
        if (response.success) {
          this.addToOrderSummary();
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UnableToClear, "error");
      });
    this.setState({ showConfirmationPopup: false });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  addToOrderSummary() {
    let product_details = [];
    this.state.productType === "led"
      ? product_details.push({
          description: this.state.description.filter((x) => x !== undefined),
          wattage: this.state.wattage,
          cstColor:
            this.state.cstColorProps == null
              ? "CST"
              : this.state.cstColorProps.Title,
          Quantity: this.state.productQuantity,
          setId:
            this.state.entireConfigurator.configurator[
              this.state.currentSetIndex
            ].setId,
        })
      : (product_details.length = 0);
    let data = {
      productId: this.state.productDetails.id.toString(),
      title: this.state.productDetails.title,
      action: "QuickSummary",
      setId:
        this.state.entireConfigurator.configurator[this.state.currentSetIndex]
          .setId,
      customer_name:
        customerTLD !== ""
          ? customerTLD
          : this.ternaryEvaluation(
              this.state.selectedCustomer,
              null,
              "",
              this.state.selectedCustomer
            ),
      orderlist: {
        product_list: this.state.productQuantity > 0 ? product_details : [],
        constructionOrAccessory_list:
          this.state.productType === "led"
            ? this.state.productAccessories.filter(
                (item) => item.isSelected && item.Quantity > 0
              )
            : this.state.productFamily.filter(
                (item) => item.isSelected && item.Quantity > 0
              ),
        quickShip_list: [],
        goldenItemsList: [],
      },
    };
    this.addToSummaryAPI(data);
  }

  validateProductQuantity() {
    let preventOrder = false;

    if (this.state.productQuantity < 1) {
      preventOrder = true;
    }
    this.state.productAccessories.forEach((item) => {
      if (item.isSelected && item.Quantity < 1) {
        preventOrder = true;
      }
      return preventOrder;
    });

    this.state.productFamily.forEach((item) => {
      if (item.isSelected && item.Quantity < 1) {
        preventOrder = true;
      }
      return preventOrder;
    });

    return preventOrder;
  }

  addToSummaryAPI(data) {
    if (
      this.state.selectedCustomer === null ||
      this.state.selectedCustomer === ""
    ) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameMissing);
      this.addNotification(ErrorMessages.CustomerNameMissing, "warning");
      return;
    }
    if (this.state.selectedCustomer.length > 50) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameLength);
      this.addNotification(ErrorMessages.CustomerNameLength, "warning");
      return;
    }
    if (this.validateProductQuantity()) {
      this.trackAddToCartButtonClick(
        ErrorMessages.ProductConfig_QuantityOutOfRange
      );
      this.addNotification(
        ErrorMessages.ProductConfig_QuantityOutOfRange,
        "warning"
      );
      return;
    }
    if (
      data.orderlist.product_list.length === 0 &&
      data.orderlist.constructionOrAccessory_list.length === 0 &&
      data.orderlist.goldenItemsList.length === 0
    ) {
      this.trackAddToCartButtonClick(ErrorMessages.ValidQuantityValues);
      this.addNotification(ErrorMessages.ValidQuantityValues, "error");
      return;
    }
    ProductConfiguratorHandler.addToOrderSummary(data)
      .then((response) => {
        if (!response.success && response.message === "Failed") {
          this.trackAddToCartButtonClick(ErrorMessages.UnableToAddItems);
          this.addNotification(ErrorMessages.UnableToAddItems, "error");
        } else if (!response.success && response.message === "Limit") {
          this.trackAddToCartButtonClick(ErrorMessages.CartLimit);
          this.addNotification(ErrorMessages.CartLimit, "warning");
        } else if (!response.success) {
          this.trackAddToCartButtonClick(response.message);

          this.setState({
            showConfirmationPopup: true,
            message: response.message,
          });
        } else if (response.success) {
          this.trackAddToCartButtonSuccess();
          this.addNotification(DisplayMessages.AddedProductAccessory, "info");
          this.setState({ addedToOrderSummary: true });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UnableToAddToCart, "error");
      });
  }

  viewButtonClicked(type, index, qty) {
    let tempStockDetails = [];
    let description = "";
    if (type === "product") {
      description =
        this.state.productType === "led"
          ? `${this.state.wattage}-${this.state.description
              .filter((e) => e)
              .join("-")}`
          : "" + this.state.productFamily[index].Title;
      this.setState({
        isViewClicked: !this.state.isViewClicked,
      });
    } else {
      description = type.Accessories.replace("CST", type.color).toString();
      let listOfOrders = this.state.productAccessories;
      listOfOrders[index].ViewClicked = !listOfOrders[index].ViewClicked;
      this.setState({
        productAccessories: listOfOrders,
      });
    }

    ProductConfiguratorHandler.getInventoryDetails(
      encodeURIComponent(description)
    )
      .then((response) => {
        if (response.data.stockdetails.length <= 0) {
          tempStockDetails.StockCode = Miscellaneous.StockCodeUnavailable;
          tempStockDetails.LeadTime = Miscellaneous.LeadTimeUnavailable;
          tempStockDetails.QtyAvailable = "";
          tempStockDetails.PartCategory = "";
        } else {
          tempStockDetails = response.data.stockdetails[0];
        }

        let qtyLead = response.data.qtyLead;

        let leadTime = Miscellaneous.LeadTimeUnavailable;
        for (let row in qtyLead) {
          if (parseFloat(qtyLead[row].QtyAvailable) >= qty) {
            leadTime = qtyLead[row].LeadTime;
            break;
          }
        }
        tempStockDetails.LeadTime = leadTime;

        this.setState({
          stockdetails: tempStockDetails,
        });
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ProductConfig_InventoryDetails,
          "error"
        );
      });
  }

  handleAccessoriesSelection(i) {
    let list = [];
    if (this.state.productAccessories.length > 0) {
      list = this.state.productAccessories;
      this.getAccessoryPrice(i);
      list[i].isSelected = true;
      list[i].Quantity = 1;
    }
    this.setState({ productAccessories: list });
  }

  popoverRight(element, quantity) {
    if (element === null) return null;
    else {
      return (
        <Popover
          id="popover-positioned-right"
          style={{ width: "210px" }}
          title="Lead Time"
        >
          <div>
            <strong>StockCode</strong>
            <div>{element.StockCode}</div>
            <strong>
              {element.LeadTime === null || element.LeadTime === ""
                ? ""
                : "Estimated Shipping Time"}
            </strong>
            <div>{element.LeadTime}</div>

            <strong>Part Category</strong>
            <div>
              {element.PartCategory === "B"
                ? Miscellaneous.PartCategoryStocked
                : Miscellaneous.PartCategoryNotStocked}
            </div>
            <br />
            <div>
              <Disclaimer
                last_updated={this.state.last_updated}
                type="ShippingTime"
              />
            </div>
          </div>
        </Popover>
      );
    }
  }

  generateDescription(item, e, index) {
    let description = [...this.state.description];
    let cstColorProps = this.state.cstColorProps;
    description[index] = e.target.value;

    if (index === this.state.cstIndex) {
      if (cstColorProps && e.target.value === "") {
        cstColorProps.Title = "CST";
        cstColorProps.Name = "CUSTOM";
      } else {
        let cst = customColors.find((item) => item.Title === e.target.value);
        if (cst && cstColorProps) {
          cstColorProps.Title = cst.Title;
          cstColorProps.Name = cst.Name;
        }
      }
    }
    this.setState(
      {
        description: description,
        cstColorProps: cstColorProps,
      },
      () => {
        this.trackSelectConfigurationDropdownSelection(
          item.category,
          this.state.description[index]
        );
        this.handleAccessoriesColor();
      }
    );
  }

  getDefaultList() {
    const options = [];
    options.push(
      <option key={0} value={""}>
        {"Select"}
      </option>
    );
    return options;
  }

  onBlurQtyChange(e) {
    let qty = e.target.value === "" ? 1 : e.target.value;
    this.setState({ productQuantity: qty });
  }

  onBlurAccessoryQtyChange(e) {
    let tempAccessories = [...this.state.productAccessories];
    let index = parseInt(e.target.name, 10);
    tempAccessories[index].Quantity =
      e.target.value === "" ? 1 : e.target.value;
    this.setState({ productAccessories: tempAccessories });
  }

  accessoryQtyChange(e) {
    let tempAccessories = [...this.state.productAccessories];
    let index = parseInt(e.target.name, 10);
    let qty = e.target.validity.valid
      ? e.target.value
      : this.ternaryEvaluation(
          tempAccessories[index].Quantity,
          "",
          0,
          tempAccessories[index].Quantity
        );
    tempAccessories[index].Quantity = qty;
    this.setState({ productAccessories: tempAccessories });
  }

  onProductQtyChange(e) {
    let qty = e.target.validity.valid
      ? e.target.value
      : this.ternaryEvaluation(
          this.state.productQuantity,
          "",
          0,
          this.state.productQuantity
        );
    this.setState({ productQuantity: qty });
  }

  generateProductOptions() {
    let options = [];
    this.state.productList.forEach((item, i) =>
      options.push({
        value: item.id,
        label: item.title,
      })
    );
    return options;
  }

  generateFamilyOptions() {
    const options = [];
    this.state.familyList.forEach((item, i) =>
      options.push({
        value: item,
        label: item,
      })
    );
    return options;
  }

  generateWattageOptions() {
    const options = [];
    this.state.wattageList.forEach((item, i) => {
      let wattagevalue = item.Title.toString().split("-");
      if (
        item.Title.includes("LED") &&
        wattagevalue[1].startsWith("LED") === false
      ) {
        wattagevalue[0] = wattagevalue[0] + "-" + wattagevalue[1];
        wattagevalue[1] = wattagevalue[2];
      }
      let price = item[this.state.tierPricing];
      let wattageLabel = wattagevalue[1];
      let wattageLabel2 =
        parseFloat(price) > 0 && this.state.pricingAccess
          ? " ($" + parseFloat(price).toFixed(2) + ")"
          : "";
      options.push({
        value: item.Title,
        label: wattageLabel + wattageLabel2,
      });
    });
    return options;
  }

  showResults() {
    if (!this.state.showResults) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  handleProductChange(e) {
    let productId = e.value;
    if (productId !== "") {
      let product = this.state.productList.find(
        (item) => item.id === parseInt(productId, 10)
      );
      this.trackSelectProductDropdownSelection(product);

      this.setState({
        productDetails: product,
        productQuantity: 1,
        productAccessories: [],
        addedToOrderSummary: false,
      });
      this.getProductConfigDetails(productId, product.title);
    } else {
      this.setState({
        productDetails: null,
        isChecked: true,
        configuratorValues: [],
        productQuantity: 1,
        addedToOrderSummary: false,
      });
    }
  }

  onChangeFamily(e) {
    let family = e.value !== "Select..." ? e.value : "";
    let wattageList = this.state.productFamily.filter((item) =>
      item.Title.includes(family + "-")
    );

    this.trackSelectFamilyDropdownSelection(family);
    this.setState(
      {
        family: family,
        wattageList: wattageList,
        wattage: wattageList[0].Title,
        currentSetIndex: wattageList[0].index,
      },
      () => {
        this.handleConfiguratorResponse();
      }
    );
  }

  onChangeWattage(e) {
    let wattage = this.state.wattageList.find((item) => item.Title === e.value);

    this.trackSelectWattageDropdownSelection(wattage.Title);
    this.setState(
      {
        wattage: wattage.Title,
        currentSetIndex: wattage.index,
      },
      () => {
        this.handleConfiguratorResponse();
      }
    );
  }

  getConstructionProductConfigurator() {
    return this.state.productFamily.length > 0 ? (
      <div style={{ marginTop: "20px", fontSize: "16px", align: "center" }}>
        <Col md={12} sm={12} xs={12}>
          <h4>Product Configurator</h4>
          <div className="horizontal-line"></div>
          <div className="scrollable-div">
            {this.state.productFamily.map((item, i) => {
              return (
                <Col
                  md={3}
                  sm={6}
                  xs={12}
                  key={item.Title.toString()}
                  style={{ marginBottom: "5px" }}
                >
                  <div className="product-container">
                    <div
                      className={
                        item.isSelected
                          ? "product-details-container-config-selected"
                          : "product-details-container-config"
                      }
                      onClick={() => this.handleConstructorProduct(item, i)}
                    >
                      <div style={{ padding: "5px", minHeight: "100px" }}>
                        <h5 className="product-name-config">
                          {item.Title.toString()}
                        </h5>

                        {parseFloat(item.GoldenPrice) === 0 ? (
                          <h5
                            className="product-name-config"
                            hidden={!this.state.pricingAccess}
                          >
                            {"($" +
                              parseFloat(
                                parseFloat(item[this.state.tierPricing])
                              ).toFixed(2) +
                              ")"}
                          </h5>
                        ) : (
                          <h5
                            className="product-name-config gold-pricing"
                            hidden={!this.state.pricingAccess}
                          >
                            {"($" +
                              parseFloat(
                                parseFloat(item.GoldenPrice.toString())
                              ).toFixed(2) +
                              ")"}
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </div>
          {this.goldenLegendSummary()}
          {this.getProductSummary()}
        </Col>
      </div>
    ) : null;
  }

  handleConstructorProduct(item, i) {
    let count = this.state.productFamily.filter((obj) => obj.isSelected).length;
    let list = [];
    if (this.state.productFamily.length > 0) {
      list = this.state.productFamily;
      if (list[i].isSelected && count > 1) {
        list[i].Quantity = 0;
        list[i].isSelected = false;
      } else {
        list[i].isSelected = true;
        list[i].Quantity = 1;
      }
    }
    this.setState({
      productFamily: list,
      wattage: "",
      cstColorProps: null,
    });
  }

  getAccessories() {
    return this.state.productAccessories.length > 0 ? (
      <Col md={12} sm={12} xs={12}>
        <h4>Accessories</h4>
        <div className="horizontal-line"></div>
        <div className="scrollable-div">
          {this.state.productAccessories.map((item, i) => {
            return (
              <Col
                md={4}
                sm={6}
                xs={12}
                key={item.Accessories.replace("CST", item.color).toString()}
                style={{ marginBottom: "5px" }}
              >
                <OverlayTrigger
                  show={item.isBoxHovered}
                  trigger="click"
                  rootClose
                  placement="top"
                  overlay={this.popoverAccessories(item, i)}
                >
                  <div className="product-container">
                    <div
                      className={
                        item.isSelected
                          ? "product-details-container-config-selected"
                          : "product-details-container-config"
                      }
                      onClick={() =>
                        this.state.isChecked
                          ? this.addAccessory(i)
                          : this.onAccessoryClick(i)
                      }
                    >
                      <div
                        className="product-image-box"
                        style={{ marginBottom: "5px" }}
                      >
                        <img
                          style={{ backgroundColor: "white", height: "120px" }}
                          src={item.url}
                          alt=""
                        />
                      </div>
                      <div className="product-content-config">
                        <h5 className="product-name-config">
                          {item.Accessories.replace(
                            "CST",
                            item.color
                          ).toString()}
                        </h5>

                        {parseFloat(item.GoldenPrice) === 0 ? (
                          <h5
                            className="product-name-config"
                            hidden={!this.state.pricingAccess}
                          >
                            {parseFloat(item.SalePrice) === 0 ? (
                              <div>
                                {"($" +
                                  parseFloat(
                                    parseFloat(item[this.state.tierPricing])
                                  ).toFixed(2) +
                                  ")"}
                              </div>
                            ) : (
                              <span>
                                <span
                                  style={{
                                    textDecorationLine: "line-through",
                                  }}
                                >
                                  $
                                  {parseFloat(
                                    item[this.state.tierPricing]
                                  ).toFixed(2)}
                                </span>
                                <span> </span>
                                <span style={{ color: "red" }}>
                                  {parseFloat(item.SalePrice).toFixed(2)}
                                </span>
                              </span>
                            )}
                          </h5>
                        ) : (
                          <div>
                            {parseFloat(item.GoldenPrice) <=
                              parseFloat(item.SalePrice) ||
                            parseFloat(item.SalePrice) === 0 ? (
                              <h5
                                className="product-name-config gold-pricing"
                                hidden={!this.state.pricingAccess}
                              >
                                {"($" +
                                  parseFloat(
                                    parseFloat(item.GoldenPrice.toString())
                                  ).toFixed(2) +
                                  ")"}
                              </h5>
                            ) : (
                              <h5
                                className="product-name-config"
                                hidden={!this.state.pricingAccess}
                              >
                                <span>
                                  <span
                                    style={{
                                      textDecorationLine: "line-through",
                                    }}
                                  >
                                    $
                                    {parseFloat(
                                      item[this.state.tierPricing]
                                    ).toFixed(2)}
                                  </span>
                                  <span> </span>
                                  <span style={{ color: "red" }}>
                                    {parseFloat(item.SalePrice).toFixed(2)}
                                  </span>
                                </span>
                              </h5>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
              </Col>
            );
          })}
        </div>
      </Col>
    ) : null;
  }

  //TODO: create a new component for color palette
  popoverAccessories(element, index) {
    return this.state.isChecked || !element.Accessories.includes("CST") ? (
      <div></div>
    ) : (
      <Popover
        id="popover-right"
        style={{ width: "180px" }}
        title="Color Pallete"
      >
        <div>
          <div>
            {this.state.standardFinishes.length > 0 ? (
              <Row>
                <h5>Standard Finishes</h5>
                {this.state.standardFinishes.map((item, i) => {
                  return (
                    <Col
                      className="colors"
                      md={4}
                      sm={6}
                      xs={6}
                      key={item.Title}
                    >
                      <Button
                        className={
                          this.state.cstColorProps &&
                          item.Title !== undefined &&
                          this.state.cstColorProps.Title === item.Title
                            ? "circle-selected" + item.className
                            : "circle" + item.className
                        }
                        value={item.Title}
                        onClick={() =>
                          this.handleColorSelection(element, index, item)
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            ) : null}
          </div>
          <div>
            <Row>
              <h5>Custom Colors</h5>
              {allCustomColors
                .filter((obj) => !this.state.standardFinishes.includes(obj))
                .map((item, i) => {
                  return (
                    <Col
                      className="colors"
                      md={4}
                      sm={6}
                      xs={6}
                      key={item.Title}
                    >
                      <Button
                        className={
                          this.state.cstColorProps &&
                          this.state.cstColorProps.Title === item.Title
                            ? "circle-selected" + item.className
                            : "circle" + item.className
                        }
                        value={item.Title}
                        onClick={() =>
                          this.handleColorSelection(element, index, item)
                        }
                      />
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      </Popover>
    );
  }

  handleColorSelection(element, index, item) {
    element.color = item.Title;
    element.price =
      element.StandardFinishes !== "" &&
      !element.StandardFinishes.includes(element.color) &&
      parseInt(element.CustomFinishAdder, 10) !== 0
        ? parseFloat(element.CustomFinishAdder).toFixed(2)
        : 0;

    this.trackProductFinishColorClick(element);
    this.handleAccessoriesSelection(index);
  }

  addAccessory(index) {
    let tempAccessories = this.state.productAccessories;
    if (tempAccessories[index].isSelected) {
      tempAccessories[index].Quantity = 0;
      tempAccessories[index].isSelected = false;
      tempAccessories[index].isBoxHovered = false;
    } else {
      this.getAccessoryPrice(index);
      tempAccessories[index].isSelected = true;
      tempAccessories[index].Quantity = 1;
      tempAccessories[index].isBoxHovered = true;
    }

    this.setState({
      productAccessories: tempAccessories,
    });
  }

  onAccessoryClick(index) {
    let tempAccessories = this.state.productAccessories;
    let standardFinishes = [];
    let tempStandardFinishes =
      tempAccessories[index].StandardFinishes.split(",");
    tempStandardFinishes.map((item) => {
      let colorObj = allCustomColors.filter(
        (element) => element.Title === item.trim()
      );
      if (colorObj[0] !== undefined) {
        let obj = colorObj[0];
        standardFinishes.push(obj);
      }
      return item;
    });
    if (tempAccessories[index].isSelected) {
      tempAccessories[index].isSelected = false;
      tempAccessories[index].Quantity = 0;
      tempAccessories[index].isBoxHovered = false;
    } else {
      if (!tempAccessories[index].Accessories.includes("CST")) {
        if (tempAccessories[index].isSelected) {
          tempAccessories[index].Quantity = 0;
          tempAccessories[index].isSelected = false;
          tempAccessories[index].isBoxHovered = false;
        } else {
          this.getAccessoryPrice(index);
          tempAccessories[index].isSelected = true;
          tempAccessories[index].Quantity = 1;
          tempAccessories[index].isBoxHovered = true;
        }
      } else {
        for (let i = 0; i < tempAccessories.length; i++) {
          tempAccessories[i].isBoxHovered =
            i === index ? !tempAccessories[i].isBoxHovered : false;
        }
        tempAccessories[index].isBoxHovered = true;
      }
    }
    this.setState({
      standardFinishes: standardFinishes,
      productAccessories: tempAccessories,
    });
  }

  // Mixpanel Tracking //

  trackConfiguratorTabClick(tab) {
    mixpanel.track("Configurator Tab", {
      Action: "Subtab Click",
      Effect: "Switch to selected tab",
      "Selected Tab": tab,
    });
  }

  trackCustomerSelection(customer) {
    mixpanel.track("Customer Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer is displayed",
      Customer: customer,
    });
  }

  trackCustomerTierSelection(tier) {
    mixpanel.track("Customer Tier Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer tier is displayed",
      Tier: tier,
    });
  }

  trackSelectProductDropdownSelection(product) {
    mixpanel.track("Select Product", {
      Action: "Dropdown Selection",
      Effect: "Show configurations for selected product",
      "Product Name": product.title,
    });
  }

  trackSelectFamilyDropdownSelection(family) {
    mixpanel.track("Select Family", {
      Action: "Dropdown Selection",
      Effect: "Filter configuration options",
      "Selected Option": family,
    });
  }

  trackSelectWattageDropdownSelection(wattage) {
    mixpanel.track("Select Wattage", {
      Action: "Dropdown Selection",
      Effect: "Filter configuration options",
      "Selected Option": wattage,
    });
  }

  trackSelectConfigurationDropdownSelection(category, description) {
    mixpanel.track(`Select ${category}`, {
      Action: "Dropdown Selection",
      Effect: "Filter configuration options",
      "Selected Option": description,
    });
  }

  trackProductFinishApplySelection() {
    mixpanel.track("Automatic Product Finish", {
      Action: "Checkbox Click",
      Effect: "Select/Deselect automatic product finish",
      Status: this.state.isChecked,
    });
  }

  trackProductFinishColorClick(element) {
    mixpanel.track("Accessory Color Selection", {
      Action: "Button Click",
      Effect: "Selected color finish is added to the accessory",
      Accessory: element.Accessories,
      Finish: element.color,
    });
  }

  trackAddToCartButtonClick(message) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Attempt to add item(s) to current estimate summary cart",
      Page: "Quick Configurator Page",
      "Added To Cart": false,
      Message: message,
    });
  }

  trackAddToCartButtonSuccess() {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Selected items are added to estimate summary cart",
      Page: "Quick Configurator Page",
      "Added To Cart": true,
    });
  }

  trackViewEstimateSummaryButtonClick() {
    mixpanel.track("View Estimate Summary", {
      Action: "Button Click",
      Effect: "Navigate user to Estimate Summary page",
    });

    mixpanel.track_pageview({
      Page: "Estimate Summary Page",
    });
  }
}

export default QuickConfigurator;
