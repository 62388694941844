import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";

import "./availabilityListPopup.css";
import { DisplayMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the popup containing the list of available and unavailable products
 */
class AvailabilityListPopUp extends Component {
  constructor(props) {
    super(props);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    return (
      <div className="availability-list">
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              {this.props.title}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="unavail">
              <h4>
                <strong>Unavailable Products</strong>
              </h4>
              {this.props.unavail.map((item, i) => (
                <h5 key={item}>{item}</h5>
              ))}
            </div>
            <br />
            {this.availableList()}
          </Modal.Body>
          <Modal.Footer>
            <span className="text-danger" style={{ fontSize: "15px" }}>
              {DisplayMessages.AvailabilityListLine1}
              <br />
              {DisplayMessages.AvailabilityListLine2}
            </span>
            <br />
            <br />
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={this.handleAccept}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  availableList() {
    if (this.props.avail.length > 0) {
      return (
        <div className="unavail">
          <h4>
            <strong>Available Products</strong>
          </h4>
          {this.props.avail.map((item, i) => (
            <h5 key={item}>{item}</h5>
          ))}
        </div>
      );
    } else {
      return (
        <div className="unavail">
          <h4>
            <strong>Available Products</strong>
          </h4>
          <span style={{ fontStyle: "italic", fontSize: "15px" }}>None</span>
        </div>
      );
    }
  }
  handleAccept() {
    this.props.handleAccept();
  }
  handleClose() {
    this.props.handleClose();
  }
}

export default AvailabilityListPopUp;
