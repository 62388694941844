import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Row from "react-bootstrap/lib/Row";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import mixpanel from "mixpanel-browser";

import "../AttachmentListPopup/attachmentListPopup.css";
import GalleryDataHandler from "./GalleryDataHandler";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the popup containing the installation selected and it's details
 */
class InstallsAttachmentsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    };
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.delete = this.delete.bind(this);
    this.cancelAttachmentDeletion = this.cancelAttachmentDeletion.bind(this);
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              Uploaded Pics
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row className="container attachmentList-container">
              <Table>
                <thead>
                  <tr>
                    <th>Installation Name</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>{this.generateAttachmentList()}</tbody>
              </Table>
            </Row>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
        <DeleteConfirmationPopup
          show={this.state.deleteAttachmentPopup}
          item={this.state.attachmentName}
          itemType={"Installation"}
          handleConfirmation={this.delete}
          handleCancel={this.cancelAttachmentDeletion}
        />
      </div>
    );
  }

  generateAttachmentList() {
    if (this.props.attachments.length <= 0) {
      return (
        <tr>
          <td colSpan="3">
            <h4 style={{ textAlign: "center" }}>
              {ErrorMessages.InstallationGallery_NoAttachments}
            </h4>
          </td>
        </tr>
      );
    } else {
      return this.props.attachments.map((attachment) =>
        this.getAttachmentItem(attachment)
      );
    }
  }

  getAttachmentItem(item) {
    let fileName = item.file_name.split(".");
    return (
      <tr key={item.id}>
        <td style={{ textAlign: "center" }}>{fileName[0]}</td>
        <td style={{ width: 80 }} />
        <td style={{ height: "62px", width: "50px", textAlign: "center" }}>
          <Button
            bsStyle="primary"
            bsSize="small"
            className="button-download button-red"
            style={{ width: "50px" }}
            onClick={() => {
              this.handleDelete(item, fileName);
            }}
          >
            <span className="glyphicon glyphicon-trash" />
            &nbsp;
          </Button>
        </td>
      </tr>
    );
  }

  handleDelete(item, fileName) {
    this.trackDeleteAttachmentButtonClick(item.id, fileName);
    this.setState({
      deleteAttachmentPopup: true,
      attachmentName: fileName,
      attachmentId: item.id,
    });
  }

  cancelAttachmentDeletion() {
    this.setState({
      deleteAttachmentPopup: false,
      attachmentName: "",
      attachmentId: "",
    });
  }

  delete() {
    this.trackDeleteAttachmentButtonConfirmationClick();

    GalleryDataHandler.deleteInstallations(this.state.attachmentId)
      .then((response) => {
        this.cancelAttachmentDeletion();
        this.props.addNotification(
          DisplayMessages.InstallationGallery_RecordDeleteMsg,
          "info"
        );
        this.props.handleDeleteAttachment();
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.InstallationGallery_DeleteInstallation,
          "error"
        );
      });
  }
  handleOnClose() {
    this.props.handleOnClose();
  }

  // Mixpanel Tracking //

  trackDeleteAttachmentButtonClick(itemId, fileName) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected installation will be deleted",
      "Item Type": "Installation",
      "Attachment Name": fileName,
      "Attachment ID": itemId,
    });
  }

  trackDeleteAttachmentButtonConfirmationClick() {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected installation deleted",
      "Item Type": "Installation",
      "Attachment Name": this.state.attachmentName,
      "Attachment ID": this.state.attachmentId,
    });
  }
}

export default InstallsAttachmentsPopup;
