import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";

import { ErrorMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the export user details popup
 */
class ExportDataPopup extends Component {
  constructor(props) {
    super(props);

    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      name: "",
    };
  }

  componentWillReceiveProps(newProp) {
    this.setState({ name: "" });
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              {this.props.title}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Row>
                  <h4>Name</h4>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <FormGroup>
                    <FormControl
                      type="text"
                      value={this.state.name}
                      placeholder="Enter Name"
                      onChange={this.handleNameChange}
                      onKeyDown={this.handleKeyPress}
                      maxLength={100}
                    />
                  </FormGroup>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                bsStyle="primary"
                className="button-skyblue"
                style={{ textAlign: "center" }}
                onClick={this.handleSubmit}
              >
                Export
              </Button>
              <Button
                onClick={this.handleDiscard}
                bsStyle="primary"
                className="button-white"
                style={{ textAlign: "center" }}
              >
                Discard
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleOnClose() {
    this.props.handleOnClose();
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleDiscard() {
    this.setState({ name: "" });
    this.props.handleOnClose();
  }

  handleSubmit() {
    if (!this.state.name || this.state.name === "") {
      this.props.addNotification(
        ErrorMessages.ExportUsersPopup_ValidName,
        "error"
      );
      return;
    } else if (this.state.name.length > 100) {
      this.props.addNotification(
        ErrorMessages.ExportUsersPopup_MaxLimit,
        "error"
      );
      return;
    }
    this.props.handleOnSubmit(this.state.name);
  }

  handleKeyPress(target) {
    if (target.keyCode === 13) {
      this.handleSubmit();
    }
  }
}

export default ExportDataPopup;
