import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Grid from "react-bootstrap/lib/Grid";
import Button from "react-bootstrap/lib/Button";
import Pagination from "react-js-pagination";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import Product from "../Product/Product.js";
import "./productList.css";
import ProductListDataHandler from "./ProductListDataHandler.js";
import {
  DisplayMessages,
  ErrorMessages,
} from "../Utils/FetchConfigurableData.js";
import ContentLoading from "../ContentLoading/ContentLoading.js";

/**
 * Component represents the product list section
 */
class ProductList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);

    this.state = {
      error: null,
      isLoaded: false,
      spaceId: "",
      applicationId: "",
      searchString: "",
      showProductList: true,
      showProductDetails: false,
      selectedProductPostId: "",
      productList: [],
      activePage: 1,
      totalItemsCount: 0,
      searchCriteria: "",
    };

    this.handleProductListResponse = this.handleProductListResponse.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
    this.handleGoBackToProductListPage =
      this.handleGoBackToProductListPage.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateState(this.props);
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return this.state.productList !== undefined &&
      this.state.productList.length > 0
      ? this.productListSection()
      : this.productListLoadingSection();
  }

  productListSection() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    return (
      <div className="loading-fade">
        {this.productListDisplaySection()}
        {this.productDescriptionSection()}
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  productListDisplaySection() {
    return (
      <div className={this.showProductList()}>
        {this.searchDescriptionSection()}
        {this.state.isLoaded ? (
          <Row>
            {this.state.productList.map((item, i) =>
              this.generateProductListItem(item, i)
            )}
          </Row>
        ) : (
          <Row className="loading-icon" style={{ height: "1160px" }}>
            <ContentLoading
              message={DisplayMessages.Products_Loading}
              size={80}
            />
          </Row>
        )}
        <Row
          className={`pagination-container ${this.showPagination()}`}
          align="center"
        >
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={16}
            totalItemsCount={this.state.totalItemsCount}
            onChange={this.handlePageChange}
          />
        </Row>
        <br />
        <br />
      </div>
    );
  }

  productDescriptionSection() {
    return (
      <div className={this.showProductDetails()}>
        <Button
          bsStyle="primary"
          className="button-blue glyphicon glyphicon-arrow-left"
          onClick={this.handleGoBackToProductListPage}
        />
        <Product
          postId={this.state.selectedProductPostId}
          applicationType={this.props.applicationType}
        />
      </div>
    );
  }

  productListLoadingSection() {
    let message, icon;
    if (!this.state.isLoaded) {
      message = "Loading...";
      icon = <ContentLoading message={""} size={80} />;
    } else {
      message = "No product To display!";
      icon = <Row />;
    }
    return (
      <div>
        <br />
        {this.searchDescriptionSection()}
        <Grid>
          <h3 className="text-message">{message}</h3>
          {icon}
        </Grid>
      </div>
    );
  }

  updateState(newProps) {
    let params = newProps.location.state;
    if (!params && this._isMounted) {
      this.setState({
        isLoaded: true,
      });
      return;
    }

    if (params.space && params.application && this._isMounted) {
      this.setState(
        {
          isLoaded: false,
          activePage: 1,
          spaceId: params.space.id,
          applicationId: params.application.id,
          searchCriteria: params.space.name + " + " + params.application.name,
          productList: [],
        },
        this.getData
      );
    } else if (params.s && this._isMounted) {
      this.setState(
        {
          isLoaded: false,
          activePage: 1,
          searchString: params.s,
          searchCriteria: params.s,
          productList: [],
        },
        this.getSearchResult
      );
    } else if (this._isMounted) {
      this.setState(
        {
          isLoaded: false,
          activePage: 1,
          spaceId: params.space.id,
          applicationId: "",
          searchCriteria: params.space.name,
          productList: [],
        },
        this.getData
      );
    }
  }

  getData() {
    let filter = {
      spaceId: this.state.spaceId,
      applicationId: this.state.applicationId,
      page: this.state.activePage,
      offset: 16,
    };
    ProductListDataHandler.getProductListToDisplay(filter)
      .then((response) => {
        this.handleProductListResponse(response);
      })
      .catch((error) => {
        this.handleError(error);
        this.addNotification(
          ErrorMessages.ProductList_ProductListToDisplay,
          "error"
        );
      });
  }
  getSearchResult() {
    let filter = {
      searchString: this.state.searchString,
      page: this.state.activePage,
      offset: 16,
    };

    ProductListDataHandler.getSearchResult(filter)
      .then((response) => {
        this.handleProductListResponse(response);
      })
      .catch((error) => {
        this.handleError(error);
        this.addNotification(ErrorMessages.ProductList_SearchResult, "error");
      });
  }
  generateProductListItem(product, i) {
    return (
      <Col md={3} sm={6} key={product.id} style={{ minWidth: "250px" }}>
        <div className="product-container">
          <div className="product-details-container">
            <div className="product-image-box ">
              <img
                src={product.featured_src}
                alt="Product img"
                className="selected-category-product-image loading-fade"
                style={{ maxHeight: "180px" }}
                id={product.id}
                onClick={(e) => {
                  this.trackProductImageClick(product);
                  this.handleSelectProduct(e.target);
                }}
              />
            </div>
            <div className="product-content">
              <h5 className="product-name">{product.title}</h5>
              <Button
                className="button-blue"
                bsStyle="primary"
                block
                id={product.id}
                onClick={(e) => {
                  this.trackProductButtonClick(product);
                  this.handleSelectProduct(e.target);
                }}
              >
                READ MORE
              </Button>
            </div>
          </div>
        </div>
      </Col>
    );
  }
  handlePageChange(pageNumber) {
    this.trackPagination(pageNumber);
    if (this.state.searchString) {
      this.setState(
        { isLoaded: false, activePage: pageNumber },
        this.getSearchResult
      );
    } else {
      this.setState({ isLoaded: false, activePage: pageNumber }, this.getData);
    }
  }

  handleProductListResponse(response) {
    if (this._isMounted) {
      this.setState({
        isLoaded: true,
        totalItemsCount: response.total,
        showProductList: true,
        showProductDetails: false,
        productList: response.productList,
      });
    }
  }
  handleError(error) {
    if (this._isMounted) {
      this.setState({
        isLoaded: true,
        error: error,
      });
    }
  }
  handleSelectProduct(product) {
    this.setState({
      selectedProductPostId: product.id,
      showProductList: false,
      showProductDetails: true,
    });
  }
  handleGoBackToProductListPage() {
    this.trackBackButtonClick();
    this.setState({
      showProductList: true,
      showProductDetails: false,
    });
  }
  showProductList() {
    if (this.state.showProductList) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }
  showProductDetails() {
    if (this.state.showProductDetails) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }
  showPagination() {
    if (this.state.totalItemsCount > 16) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  searchDescriptionSection() {
    if (
      this.state.searchCriteria !== null &&
      this.state.searchCriteria !== ""
    ) {
      let criteria = this.state.searchCriteria.toString().replace("&amp;", "&");
      return (
        <Row>
          <h4 className="search-descriptor">
            Search Results: "
            <span style={{ color: "#074391" }}>{criteria}</span>"
          </h4>
        </Row>
      );
    } else {
      return <Row />;
    }
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  // Mixpanel Tracking //

  trackProductImageClick(product) {
    mixpanel.track("Product Selection", {
      Action: "Image Click",
      Effect: `Navigate to Selected Product Page`,
      "Image Source": product.featured_src,
      "Product ID": product.id,
      Permalink: product.permalink,
      "Short Description": product.short_description,
      "Product Title": product.title,
    });

    mixpanel.track_pageview({
      Page: "Product Page",
    });
  }

  trackProductButtonClick(product) {
    mixpanel.track("Product Selection", {
      Action: "Button Click",
      Effect: `Navigate to Selected Product Page`,
      "Image Source": product.featured_src,
      "Product ID": product.id,
      Permalink: product.permalink,
      "Short Description": product.short_description,
      "Product Title": product.title,
    });

    mixpanel.track_pageview({
      Page: "Product Page",
    });
  }

  trackBackButtonClick() {
    mixpanel.track("Back To Product List", {
      Action: "Button Click",
      Effect: `Navigate back to Product List Page`,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Product List Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}
export default ProductList;
