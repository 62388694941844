import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Select from "react-select";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import { Redirect } from "react-router-dom";
import makeAnimated from "react-select/animated";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import mixpanel from "mixpanel-browser";

import "./distributorManagement.css";
import DistributorManagementHandler from "./DistributorManagementHandler";
import OrderSummaryHandler from "../OrderSummary/OrderSummaryHandler";
import Api from "../Utils/api";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const multiCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const animatedComponents = makeAnimated();

let actionsList = [
  { value: "Add TLD", label: "Add TLD" },
  { value: "Add Branch", label: "Add Branch" },
  { value: "Edit TLD", label: "Edit TLD" },
  { value: "Edit Branch", label: "Edit Branch" },
];

/**
 * Component represents the Distributor Management Tab in Content page
 */
class DistributorManagement extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      selectedAction: "Add TLD",
      tldName: "",
      logoName: "",
      files: [],
      selectedTLD: "",
      tldList: [],
      originalTLDName: "",
      originalLogoName: "",
      logoPath: "",
      originalLogoPath: "",
      branchName: "",
      originalBranchName: "",
      salesAgencyList: [],
      selectedSalesAgency: "",
      LEDTier: 1,
      constructionTier: 1,
      customerID: "",
      CSRList: [],
      selectedCSR: "",
      selectedCSRUserId: "",
      location: "",
      province: "",
      emailDomain: "",
      salesRepList: [],
      selectedSalesRep: [],
      selectedSalesRepUserId: [],
      freeShipping: 0.0,
      branchList: [],
      selectedBranch: "",
      selectedBranchId: "",
      branchInfoList: [],
      salesRepInfoList: [],
      tldInfoList: [],
      csrInfoList: [],
      redirectBranch: "",
      redirectTLD: "",
      allowRedirect: false,
      provinceList: [],
      ledTierList: [],
      constructionTierList: [],
    };
    this.activeMode = this.activeMode.bind(this);
    this.generateCSRList = this.generateCSRList.bind(this);
    this.generateBranchList = this.generateBranchList.bind(this);
    this.generateSalesRepList = this.generateSalesRepList.bind(this);
    this.generateAllSalesrepList = this.generateAllSalesrepList.bind(this);
    this.getBranchInfo = this.getBranchInfo.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleAddTLD = this.handleAddTLD.bind(this);
    this.handleAddBranch = this.handleAddBranch.bind(this);
    this.handleTLDNameChange = this.handleTLDNameChange.bind(this);
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleCustomerIDChange = this.handleCustomerIDChange.bind(this);
    this.handleBranchNameChange = this.handleBranchNameChange.bind(this);
    this.handleEmailDomainChange = this.handleEmailDomainChange.bind(this);
    this.handleSalesAgencyChange = this.handleSalesAgencyChange.bind(this);
    this.handleSalesRepChange = this.handleSalesRepChange.bind(this);
    this.handleFreeShippingChange = this.handleFreeShippingChange.bind(this);
    this.handleCSRChange = this.handleCSRChange.bind(this);
    this.handleLEDTierChange = this.handleLEDTierChange.bind(this);
    this.handleConstructionTierChange =
      this.handleConstructionTierChange.bind(this);
    this.handleOnBlurFreeShipping = this.handleOnBlurFreeShipping.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.validateTLD = this.validateTLD.bind(this);
    this.handleEditTLD = this.handleEditTLD.bind(this);
    this.generateSalesAgencyList = this.generateSalesAgencyList.bind(this);
    this.generateTLDList = this.generateTLDList.bind(this);
    this.handleEditBranch = this.handleEditBranch.bind(this);
    this.handleTLDChange = this.handleTLDChange.bind(this);
    this.handleBranchTLDChange = this.handleBranchTLDChange.bind(this);
    this.createTLD = this.createTLD.bind(this);
    this.createBranch = this.createBranch.bind(this);
    this.updateTLD = this.updateTLD.bind(this);
    this.updateBranch = this.updateBranch.bind(this);
    this.cancelTLD = this.cancelTLD.bind(this);
    this.cancelBranch = this.cancelBranch.bind(this);
    this.cancelBranchUpdateHandler = this.cancelBranchUpdateHandler.bind(this);
    this.cancelBranchAdditionHandler =
      this.cancelBranchAdditionHandler.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.provinceDropdown = this.provinceDropdown.bind(this);
    this.freshUpdate = this.freshUpdate.bind(this);
    this.RedirectToBranchOverview = this.RedirectToBranchOverview.bind(this);
    this.fixCustomerID = this.fixCustomerID.bind(this);
    this.clearAllFields = this.clearAllFields.bind(this);
    this.getProvinces = this.getProvinces.bind(this);
    this.getTierLists = this.getTierLists.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getProvinces();
    this.getTierLists();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.allowRedirect) {
      return (
        <div className="distributor-page-container">
          <Row>
            <Col md={12} style={{ padding: "0px" }}>
              <form>
                <Row>
                  <Col md={3} sm={4} xs={6}>
                    <h4 className={this.activeMode()}>Select an action</h4>
                    {this.selectActionDropdown()}
                    <hr className="line-separator" />
                    <br />
                    {this.handleAddTLD()}
                  </Col>
                </Row>
                <Row className={this.addBranchControl()}>
                  {this.handleAddBranch()}
                </Row>
                <Row>
                  <Col md={3} sm={4} xs={6}>
                    {this.handleEditTLD()}
                  </Col>
                </Row>
                <Row className={this.editBranchControl()}>
                  {this.handleEditBranch()}
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/Distributors",
            state: {
              selectedBranch: this.state.redirectBranch,
              selectedTLD: this.state.redirectTLD,
            },
          }}
        />
      );
    }
  }

  handleAddTLD() {
    return (
      <div className={this.addTLDControl()}>
        <h4>
          TLD Name<mark>*</mark>
        </h4>
        <FormGroup className="visible">
          <FormControl
            type="text"
            maxLength={50}
            value={this.state.tldName}
            placeholder="Enter TLD name"
            onChange={this.handleTLDNameChange}
          />
          <br />
        </FormGroup>
        <h4 className={this.activeMode()}>
          {DisplayMessages.DistributorManagement_UploadLogo}
        </h4>
        <FormGroup>
          <FormControl
            id="addTLDLogoFile"
            type="file"
            label="File"
            help=""
            onChange={this.handleLogoChange}
          />
          <br />
        </FormGroup>
        <ButtonToolbar>
          <Button
            bsStyle="primary"
            onClick={this.createTLD}
            className={this.activeMode() + " button-blue"}
            style={{ width: "80px" }}
          >
            Create
          </Button>
          <Button
            bsStyle="primary"
            onClick={() => {
              this.trackTLDCancellationButtonClick();
              this.cancelTLD("add");
            }}
            className="button-blue"
            style={{ width: "80px" }}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </div>
    );
  }

  handleAddBranch() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Select a TLD<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.TLDDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Branch Name<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.branchName}
                placeholder="Enter Branch name"
                onChange={this.handleBranchNameChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Sales Agency<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              {this.salesAgencyDropdown()}
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              LED Tier<mark>*</mark>
            </h4>
            {Miscellaneous.OtherTiersEnabled ? (
              <FormGroup className="visible">
                {this.LEDTierDropdown()}
              </FormGroup>
            ) : (
              <FormGroup className="visible">
                <FormControl type="text" disabled value={1} />
              </FormGroup>
            )}
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Construction Tier<mark>*</mark>
            </h4>
            {this.state.LEDTier.toString() === "1" ? (
              <FormGroup className="visible">
                <FormControl type="text" disabled value={1} />
              </FormGroup>
            ) : (
              <FormGroup className="visible">
                {this.constructionTierDropdown()}
              </FormGroup>
            )}
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Customer ID<mark>*</mark>{" "}
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={7}
                value={this.state.customerID}
                placeholder="Enter Customer ID"
                onChange={this.handleCustomerIDChange}
                onBlur={this.fixCustomerID}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              CSR<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.CSRDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Location<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.location}
                placeholder="Enter Location"
                onChange={this.handleLocationChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              {DisplayMessages.OrderSummary_Province}
              <mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.provinceDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>Email Domain</h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.emailDomain}
                placeholder="Enter Email Domain"
                onChange={this.handleEmailDomainChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Sales Reps To Notify<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.salesRepDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Free Shipping $<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="number"
                min="0"
                max="9999999999"
                pattern="[0-9]*"
                step="0.01"
                value={this.state.freeShipping}
                placeholder="Enter Free Shipping Amount"
                onBlur={this.handleOnBlurFreeShipping}
                onChange={this.handleFreeShippingChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row style={{ paddingTop: "20px" }}>
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                onClick={this.createBranch}
                className={this.activeMode() + " button-blue"}
                style={{ width: "80px" }}
              >
                Create
              </Button>
              <Button
                bsStyle="primary"
                onClick={this.cancelBranchAdditionHandler}
                className="button-blue"
                style={{ width: "80px" }}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </Row>
        </Col>
      </div>
    );
  }

  handleEditTLD() {
    return (
      <div className={this.editTLDControl()}>
        <h4>Select a TLD</h4>
        {this.TLDDropdown()}
        <br />
        <h4>
          New TLD Name<mark>*</mark>
        </h4>
        <FormGroup className="visible">
          <FormControl
            type="text"
            maxLength={50}
            value={this.state.tldName}
            placeholder="Enter TLD name"
            onChange={this.handleTLDNameChange}
          />
          <br />
        </FormGroup>
        <Row>
          <h4 className={this.activeMode()}>
            {DisplayMessages.DistributorManagement_UploadLogo}
          </h4>
          <FormGroup>
            <FormControl
              id="editTLDLogoFile"
              type="file"
              label="File"
              help=""
              onChange={this.handleLogoChange}
            />
          </FormGroup>
        </Row>
        {this.state.selectedTLD && this.state.originalLogoName !== "NA" ? (
          <div>
            <Row>
              <img
                src={Api.getBaseURL() + "/uploads/" + this.state.logoPath}
                alt={this.state.originalLogoName}
                height="125px"
                width="125px"
                style={{ margin: "0" }}
              />
            </Row>
            <br />
            <Row>
              <strong style={{ color: "#074391" }}>
                {this.state.originalLogoName}
              </strong>
            </Row>
          </div>
        ) : null}
        <br />
        <ButtonToolbar>
          <Button
            bsStyle="primary"
            onClick={this.updateTLD}
            disabled={this.disableEditTLD()}
            className={this.activeMode() + " button-blue"}
            style={{ width: "80px" }}
          >
            Save
          </Button>
          <Button
            bsStyle="primary"
            onClick={() => {
              this.trackTLDRevertButtonClick();
              this.cancelTLD("edit");
            }}
            className="button-blue"
            style={{ width: "80px" }}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </div>
    );
  }

  handleEditBranch() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Select a TLD<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              {this.BranchTLDDropdown()}
            </FormGroup>
          </Row>
        </Col>
        <Col md={9} sm={8} xs={6} />

        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Select a Branch<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.branchDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Branch Name<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.branchName}
                placeholder="Enter Branch name"
                onChange={this.handleBranchNameChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Sales Agency<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              {this.salesAgencyDropdown()}
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              LED Tier<mark>*</mark>
            </h4>
            {Miscellaneous.OtherTiersEnabled ? (
              <FormGroup className="visible">
                {this.LEDTierDropdown()}
              </FormGroup>
            ) : (
              <FormGroup className="visible">
                <FormControl type="text" disabled value={1} />
              </FormGroup>
            )}
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Construction Tier<mark>*</mark>
            </h4>
            {this.state.LEDTier.toString() === "1" ? (
              <FormGroup className="visible">
                <FormControl type="text" disabled value={1} />
              </FormGroup>
            ) : (
              <FormGroup className="visible">
                {this.constructionTierDropdown()}
              </FormGroup>
            )}
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Customer ID<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={7}
                value={this.state.customerID}
                placeholder="Enter Customer ID"
                onChange={this.handleCustomerIDChange}
                onBlur={this.fixCustomerID}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              CSR<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.CSRDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Location<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.location}
                placeholder="Enter Location"
                onChange={this.handleLocationChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Province<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.provinceDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>Email Domain</h4>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.emailDomain}
                placeholder="Enter Email Domain"
                onChange={this.handleEmailDomainChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Sales Reps To Notify<mark>*</mark>
            </h4>
            <FormGroup className="visible">{this.salesRepDropdown()}</FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row>
            <h4>
              Free Shipping $<mark>*</mark>
            </h4>
            <FormGroup className="visible">
              <FormControl
                type="number"
                min="0"
                max="9999999999"
                pattern="[0-9]*"
                step="0.01"
                value={this.state.freeShipping}
                placeholder="Enter Free Shipping Amount"
                onBlur={this.handleOnBlurFreeShipping}
                onChange={this.handleFreeShippingChange}
              />
            </FormGroup>
          </Row>
        </Col>
        <Col md={3} sm={4} xs={6}>
          <Row style={{ paddingTop: "20px" }}>
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                disabled={
                  !this.state.selectedTLD || !this.state.selectedBranchId
                }
                onClick={this.updateBranch}
                style={{ width: "80px" }}
                className={this.activeMode() + " button-blue"}
              >
                Update
              </Button>
              <Button
                bsStyle="primary"
                disabled={!this.state.selectedBranchId}
                onClick={this.cancelBranchUpdateHandler}
                style={{ width: "80px" }}
                className="button-blue"
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </Row>
        </Col>
      </div>
    );
  }

  disableEditTLD() {
    let isEditTLDDisabled = true;
    if (this.state.originalTLDName === this.state.tldName) {
      if (this.state.files.length > 0) {
        isEditTLDDisabled = false;
      }
    } else {
      isEditTLDDisabled = false;
    }

    return isEditTLDDisabled;
  }

  branchDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedBranch,
          label:
            this.state.selectedBranch === null ||
            this.state.selectedBranch === ""
              ? "Select..."
              : this.state.selectedBranch,
        }}
        styles={customStyles}
        onChange={this.handleBranchChange}
        options={this.state.selectedTLD ? this.state.branchList : []}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  handleTLDChange(event) {
    let logo = this.state.tldInfoList.find(
      (item) => item.TldName === event.value
    );

    this.setState({
      selectedTLD: event.value,
      tldName: event.value,
      originalTLDName: event.value,
      logoName: logo.LogoName === "" ? "NA" : logo.LogoName,
      originalLogoName: logo.LogoName === "" ? "NA" : logo.LogoName,
      logoPath: logo.LogoPath === "" ? "NA" : logo.LogoPath,
      files: [],
      branchName: "",
      originalBranchName: "",
      selectedSalesAgency: "",
      LEDTier: 1,
      constructionTier: 1,
      customerID: "",
      selectedCSR: "",
      location: "",
      province: "",
      emailDomain: "",
      selectedSalesRep: [],
      selectedSalesRepUserId: [],
      freeShipping: 0.0,
    });

    document.getElementById("editTLDLogoFile").value = "";
  }

  handleBranchTLDChange(event) {
    let tld = this.state.tldInfoList.find(
      (item) => item.TldName === event.value
    );

    let branchlist = this.state.branchInfoList.filter(
      (item) => item.Tld === tld.Id
    );

    this.setState({
      selectedTLD: event.value,
      tldName: event.value,
      branchList: branchlist.map((item) => ({
        value: item.BranchId,
        label: item.BranchName,
      })),
      selectedBranch: "",
      selectedBranchId: "",
      branchName: "",
      originalBranchName: "",
      location: "",
      province: "",
      customerID: "",
      freeShipping: 0.0,
      LEDTier: 1,
      constructionTier: 1,
      selectedSalesAgency: "",
      selectedCSR: "",
      selectedCSRUserId: "",
      emailDomain: "",
      selectedSalesRepUserId: [],
      selectedSalesRep: [],
    });
  }

  generateAllSalesrepList() {
    DistributorManagementHandler.getAllSalesRepList()
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            salesRepInfoList: response.data,
          });
        } else {
          this.setState({
            salesRepInfoList: [],
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.DistributorManagement_AllSalesRepList,
          "error"
        );
      });
  }

  generateTLDList() {
    DistributorManagementHandler.getTldList()
      .then((response) => {
        if (response.data.length > 0) {
          let tld = "";
          if (this.state.selectedTLD) {
            tld = response.data.find(
              (item) => item.TldName === this.state.selectedTLD
            );
          }
          if (tld === undefined) {
            tld = response.data.find(
              (item) => item.TldName === this.state.tldName
            );
          }

          this.setState((prevState) => ({
            tldList: response.data.map((item) => ({
              value: item.TldName,
              label: item.TldName,
            })),
            tldInfoList: response.data,
            selectedTLD: prevState.tldName,
            originalTLDName: prevState.tldName,
            originalLogoName: prevState.logoName,
            logoPath: prevState.selectedTLD ? tld.LogoPath : "",
            selectedBranch: prevState.branchName,
            files: [],
          }));
        } else {
          this.setState({
            tldList: [],
            tldInfoList: [],
            selectedTLD: "",
            tldName: "",
            originalTLDName: "",
            logoPath: "",
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.DistributorManagement_TldList,
          "error"
        );
      });
  }

  generateSalesAgencyList() {
    DistributorManagementHandler.getSalesAgencyList()
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            salesAgencyList: response.data.map((item) => ({
              value: item.Name,
              label: item.Name,
            })),
          });
        } else {
          this.setState({
            salesAgencyList: [],
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.DistributorManagement_SalesAgencyList,
          "error"
        );
      });
  }

  generateSalesRepList(salesAgency) {
    salesAgency = encodeURIComponent(salesAgency);
    DistributorManagementHandler.getSalesRepList(salesAgency)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            salesRepList: response.data.map((item) => ({
              value: item.UserId,
              label: item.FirstName + " " + item.LastName,
            })),
          });
        } else {
          this.setState({
            salesRepList: [],
            selectedSalesRep: [],
            selectedSalesRepUserId: [],
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.DistributorManagement_SalesRepList,
          "error"
        );
      });
  }

  generateCSRList() {
    DistributorManagementHandler.getCSRList()
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            CSRList: response.data.map((item) => ({
              value: item.UserId,
              label: item.FirstName + " " + item.LastName,
            })),
            csrInfoList: response.data,
          });
        } else {
          this.setState({
            CSRList: [],
            selectedCSR: "",
            selectedCSRUserId: "",
            csrInfoList: [],
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.DistributorManagement_CSRList,
          "error"
        );
      });
  }

  generateBranchList() {
    DistributorManagementHandler.getBranchList().then((response) => {
      if (response.data.length > 0) {
        let branchlist = response.data;
        if (this.state.selectedTLD) {
          let tld = this.state.tldInfoList.find(
            (item) => item.TldName === this.state.selectedTLD
          );

          branchlist = response.data.filter((item) => item.Tld === tld.Id);
        }
        this.setState((prevState) => ({
          branchList: branchlist.map((item) => ({
            value: item.BranchId,
            label: item.BranchName,
          })),
          branchInfoList: response.data,
          originalBranchName: prevState.branchName,
        }));
      } else {
        this.setState({
          branchList: [],
          selectedBranch: "",
          selectedBranchId: "",
          branchInfoList: [],
        });
      }
    });
  }

  getBranchInfo() {
    let selectedBranchInfo = this.state.branchInfoList.find(
      (item) => item.BranchId === this.state.selectedBranchId
    );

    this.generateSalesRepList(selectedBranchInfo.SalesAgency);

    let salesrepId = selectedBranchInfo.Salesrep
      ? selectedBranchInfo.Salesrep.split(",")
      : [];

    let csr = this.state.csrInfoList.find(
      (item) => item.UserId === selectedBranchInfo.CSR
    );

    let salesrep = this.state.salesRepInfoList.filter((item) =>
      salesrepId.includes(item.UserId)
    );

    this.setState({
      branchName: selectedBranchInfo.BranchName,
      originalBranchName: selectedBranchInfo.BranchName,
      location: selectedBranchInfo.Location,
      province: selectedBranchInfo.Province,
      customerID: selectedBranchInfo.CustomerId,
      freeShipping: selectedBranchInfo.FreeShipping,
      LEDTier: selectedBranchInfo.LedTier,
      constructionTier: selectedBranchInfo.ConstructionTier,
      selectedSalesAgency: selectedBranchInfo.SalesAgency,
      selectedCSR: csr ? csr.FirstName + " " + csr.LastName : "",
      selectedCSRUserId: csr ? csr.UserId : "",
      emailDomain: selectedBranchInfo.EmailDomain,
      selectedSalesRepUserId: salesrepId,
      selectedSalesRep: salesrep.map((item) => ({
        value: item.UserId,
        label: item.FirstName + " " + item.LastName,
      })),
    });
  }

  validateTLD(type) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (!this.state.tldName) {
        reject(new Error("Enter TLD name"));
      } else if (type === "update") {
        form.append("TLDName", this.state.originalTLDName);
        form.append("TLDNewName", this.state.tldName);
        form.append("LogoName", this.state.originalLogoName);
        form.append("LogoNewName", this.state.logoName);
      } else {
        form.append("TLDName", this.state.tldName);
        form.append("LogoName", this.state.logoName);
      }
      if (this.state.files) {
        for (let element of this.state.files) {
          let fileSize = this.state.files[0].size;
          if (fileSize > 26214400) {
            reject(new Error("Larger file size"));
          }
          form.append("UploadedFile", element);
        }
      } else {
        form.append("UploadedFile", null);
      }
      resolve(form);
    });
  }

  validateBranch(type) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (!this.state.selectedTLD) {
        reject(ErrorMessages.DistributorManagement_ValidTLD);
      } else if (type === "update" && !this.state.selectedBranch) {
        reject(ErrorMessages.DistributorManagement_SelectedBranch);
      } else if (!this.state.branchName) {
        reject(ErrorMessages.DistributorManagement_ValidBranchName);
      } else if (!this.state.selectedSalesAgency) {
        reject(ErrorMessages.DistributorManagement_SelectedSalesAgency);
      } else if (!this.state.customerID) {
        reject(ErrorMessages.DistributorManagement_ValidCustomerId);
      } else if (!this.state.selectedCSR) {
        reject(ErrorMessages.DistributorManagement_SelectCSR);
      } else if (
        this.state.emailDomain.length > 0 &&
        !this.validEmailDomain()
      ) {
        reject(ErrorMessages.DistributorManagement_EnterValidDomain);
      } else if (!this.state.location) {
        reject(ErrorMessages.DistributorManagement_ValidLocation);
      } else if (!this.state.province) {
        reject(ErrorMessages.DistributorManagement_ValidProvince);
      } else if (this.state.selectedSalesRepUserId.length === 0) {
        reject(ErrorMessages.DistributorManagement_SelectSalesrep);
      } else {
        if (type === "update") {
          form.append("BranchId", this.state.selectedBranchId);
          form.append("NewBranchName", this.state.branchName);
          form.append("BranchName", this.state.originalBranchName);
        } else {
          form.append("BranchName", this.state.branchName);
        }
        form.append("TLDName", this.state.selectedTLD);
        form.append("SalesAgency", this.state.selectedSalesAgency);
        form.append("LEDTier", this.state.LEDTier);
        form.append("ConstructionTier", this.state.constructionTier);
        form.append("CustomerID", this.state.customerID);
        form.append("CSR", this.state.selectedCSRUserId);
        form.append("Location", this.state.location);
        form.append("Province", this.state.province);
        form.append("EmailDomain", this.state.emailDomain);
        form.append("SalesRep", this.state.selectedSalesRepUserId.toString());
        form.append("FreeShippingAmount", this.state.freeShipping);
      }
      resolve(form);
    });
  }

  createTLD() {
    this.validateTLD("create")
      .then((form) => {
        DistributorManagementHandler.uploadTLDInfo(form)
          .then((response) => {
            if (!response.success) {
              this.props.addNotification(response.message, "error");
              this.trackTLDCreationButtonClick(
                this.state.tldName,
                this.state.logoName,
                response.message
              );
            } else {
              this.trackTLDCreationSuccessButtonClick(
                this.state.tldName,
                this.state.logoName
              );
              this.props.addNotification(response.message, "info");
              this.cancelTLD("add");
            }
          })
          .catch((error) => {
            this.trackTLDCreationButtonClick(
              this.state.tldName,
              this.state.logoName,
              ErrorMessages.DistributorManagement_UploadTLD
            );
            this.props.addNotification(
              ErrorMessages.DistributorManagement_UploadTLD,
              "error"
            );
          });
      })
      .catch((error) => {
        this.trackTLDCreationButtonClick(
          this.state.tldName,
          this.state.logoName,
          error.message
        );
        this.props.addNotification(error.message, "warning");
      });
  }

  RedirectToBranchOverview(branchName) {
    this.trackBranchOverviewNavigation();
    branchName = encodeURIComponent(branchName);
    DistributorManagementHandler.getBranchInfo(branchName).then((response) => {
      this.setState({
        redirectBranch: {
          branchName: response.data.BranchName,
          branchId: response.data.BranchId,
        },
        redirectTLD: {
          tldId: response.data.Id,
          distributor: response.data.TldName,
        },
        allowRedirect: true,
      });
    });
  }

  createBranch() {
    this.validateBranch("create")
      .then((form) => {
        DistributorManagementHandler.uploadBranchInfo(form)
          .then((response) => {
            if (!response.success) {
              this.trackBranchCreationButtonClick(
                this.state.branchName,
                this.state.selectedTLD,
                response.message
              );
              this.props.addNotification(response.message, "error");
            } else {
              this.trackBranchCreationSuccessButtonClick(
                this.state.branchName,
                this.state.selectedTLD
              );
              this.props.addNotification(response.message, "info");
              this.RedirectToBranchOverview(this.state.branchName);
              this.cancelBranch("add");
            }
          })
          .catch((error) => {
            this.trackBranchCreationButtonClick(
              this.state.branchName,
              this.state.selectedTLD,
              ErrorMessages.DistributorManagement_UploadBranchInfo
            );
            this.props.addNotification(
              ErrorMessages.DistributorManagement_UploadBranchInfo,
              "error"
            );
          });
      })
      .catch((error) => {
        this.trackBranchCreationButtonClick(
          this.state.branchName,
          this.state.selectedTLD,
          error
        );
        this.props.addNotification(error, "warning");
      });
  }

  updateTLD() {
    this.validateTLD("update")
      .then((form) => {
        DistributorManagementHandler.updateTLDInfo(form)
          .then((response) => {
            if (!response.success) {
              this.trackTLDUpdateButtonClick(
                this.state.originalTLDName,
                this.state.tldName,
                this.state.logoName,
                response.message
              );
              this.props.addNotification(response.message, "error");
            } else {
              this.trackTLDUpdateSuccessButtonClick(
                this.state.originalTLDName,
                this.state.tldName,
                this.state.logoName
              );
              this.props.addNotification(response.message, "info");
              document.getElementById("editTLDLogoFile").value = "";
              this.generateTLDList();
            }
          })
          .catch((error) => {
            this.trackTLDUpdateButtonClick(
              this.state.originalTLDName,
              this.state.tldName,
              this.state.logoName,
              ErrorMessages.DistributorManagement_UpdateTLD
            );
            this.props.addNotification(
              ErrorMessages.DistributorManagement_UpdateTLD,
              "error"
            );
          });
      })
      .catch((error) => {
        this.trackTLDUpdateButtonClick(
          this.state.originalTLDName,
          this.state.tldName,
          this.state.logoName,
          error
        );
        this.props.addNotification(error, "warning");
      });
  }

  updateBranch() {
    if (this.freshUpdate()) {
      this.validateBranch("update")
        .then((form) => {
          DistributorManagementHandler.updateBranchInfo(form)
            .then((response) => {
              if (!response.success) {
                this.trackBranchUpdateButtonClick(
                  this.state.originalBranchName,
                  this.state.branchName,
                  this.state.selectedTLD,
                  response.message
                );
                this.props.addNotification(response.message, "error");
              } else {
                this.trackBranchUpdateSuccessButtonClick(
                  this.state.originalBranchName,
                  this.state.branchName,
                  this.state.selectedTLD
                );
                this.props.addNotification(response.message, "info");
                this.generateTLDList();
                this.generateSalesAgencyList();
                this.generateCSRList();
                this.generateAllSalesrepList();
                this.generateBranchList();
              }
            })
            .catch((error) => {
              this.trackBranchUpdateButtonClick(
                this.state.originalBranchName,
                this.state.branchName,
                this.state.selectedTLD,
                ErrorMessages.DistributorManagement_UpdateBranch
              );

              this.props.addNotification(
                ErrorMessages.DistributorManagement_UpdateBranch,
                "error"
              );
            });
        })
        .catch((error) => {
          this.trackBranchUpdateButtonClick(
            this.state.originalBranchName,
            this.state.branchName,
            this.state.selectedTLD,
            error
          );
          this.props.addNotification(error, "warning");
        });
    } else {
      this.trackBranchUpdateButtonClick(
        this.state.originalBranchName,
        this.state.branchName,
        this.state.selectedTLD,
        ErrorMessages.DistributorManagement_FreshUpdate
      );

      this.props.addNotification(
        DisplayMessages.DistributorManagement_FreshUpdate,
        "warning"
      );
    }
  }

  validEmailDomain() {
    let emailDomain = this.state.emailDomain;
    let pattern = Miscellaneous.RegexEmailDomain;
    const re = new RegExp(pattern, "i");
    return re.test(emailDomain);
  }

  handleLogoChange(e) {
    let path = e.target.value;
    let logoName = path.substring(path.lastIndexOf("\\") + 1);
    this.setState({
      files: e.target.files,
      logoName: logoName,
    });
  }

  handleTLDNameChange(e) {
    let tldName = e.target.value.replace(/\s{2,}/g, " ");
    this.setState({
      tldName: tldName,
    });
  }

  handleCustomerIDChange(e) {
    this.setState({
      customerID: e.target.value,
    });
  }

  fixCustomerID(e) {
    if (e.target.value === "") {
      this.setState({
        customerID: "",
      });
    } else {
      let customerID = e.target.value;
      while (customerID.length < 7) {
        customerID = "0" + customerID;
      }
      this.setState({
        customerID: customerID,
      });
    }
  }

  handleBranchNameChange(e) {
    let branchName = e.target.value.replace(/\s{2,}/g, " ");
    this.setState({
      branchName: branchName,
    });
  }

  handleLocationChange(e) {
    this.setState({
      location: e.target.value,
    });
  }

  handleProvinceChange(e) {
    this.setState({
      province: e === null ? "" : e.value,
    });
  }

  handleOnBlurFreeShipping(e) {
    let freeAmt = e.target.value === "" ? 0.0 : e.target.value;
    freeAmt = parseFloat(freeAmt).toFixed(2);
    this.setState({
      freeShipping: freeAmt,
    });
  }

  handleFreeShippingChange(e) {
    let freeAmt = e.target.validity.valid
      ? e.target.value
      : this.state.freeShipping;
    freeAmt = parseFloat(freeAmt);
    this.setState({
      freeShipping: freeAmt,
    });
  }

  handleEmailDomainChange(e) {
    this.setState({
      emailDomain: e.target.value,
    });
  }

  handleActionChange(event) {
    this.setState(
      {
        selectedAction: event.value,
      },
      this.trackActionDropdownSelection
    );
    this.clearAllFields();
    this.generateTLDList();
    this.generateSalesAgencyList();
    this.generateCSRList();
    this.generateAllSalesrepList();
    this.generateBranchList();
    document.getElementById("addTLDLogoFile").value = "";
    document.getElementById("editTLDLogoFile").value = "";
  }

  clearAllFields() {
    this.setState({
      tldName: "",
      originalTLDName: "",
      logoName: "",
      originalLogoName: "",
      logoPath: "",
      selectedTLD: "",
      selectedBranch: "",
      selectedBranchId: "",
      branchName: "",
      LEDTier: 1,
      constructionTier: 1,
      customerID: "",
      location: "",
      province: "",
      emailDomain: "",
      freeShipping: 0.0,
      selectedCSR: "",
      selectedSalesAgency: "",
      salesRepList: [],
      selectedSalesRep: [],
      selectedSalesRepUserId: [],
    });
  }

  handleLEDTierChange(event) {
    this.setState((prevState) => ({
      LEDTier: event.value,
      constructionTier:
        event.value.toString() === "1" ? 1 : prevState.constructionTier,
    }));
  }

  handleSalesAgencyChange(event) {
    if (event === null) {
      this.setState({
        selectedSalesAgency: "",
        salesRepList: [],
        selectedSalesRep: [],
        selectedSalesRepUserId: [],
      });
    } else {
      this.setState(
        {
          selectedSalesAgency: event.value,
          selectedSalesRep: [],
          selectedSalesRepUserId: [],
        },
        this.generateSalesRepList(event.value)
      );
    }
  }

  handleSalesRepChange(event) {
    if (event == null) {
      this.setState({
        selectedSalesRep: [],
        selectedSalesRepUserId: [],
      });
    } else {
      this.setState({
        selectedSalesRep: event,
        selectedSalesRepUserId: event.map((item) => item.value),
      });
    }
  }

  handleBranchChange(event) {
    if (event === null) {
      this.setState({
        selectedBranch: "",
        selectedBranchId: "",
      });
    } else {
      this.setState(
        {
          selectedBranch: event.label,
          selectedBranchId: event.value,
        },
        this.getBranchInfo
      );
    }
  }

  handleCSRChange(event) {
    if (event === null) {
      this.setState({
        selectedCSR: "",
        selectedCSRUserId: "",
      });
    } else {
      this.setState({
        selectedCSR: event.label,
        selectedCSRUserId: event.value,
      });
    }
  }

  handleConstructionTierChange(event) {
    this.setState({
      constructionTier: event.value,
    });
  }

  addTLDControl() {
    if (
      this.state.selectedAction !== undefined &&
      this.state.selectedAction !== actionsList[0].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  addBranchControl() {
    if (
      this.state.selectedAction !== undefined &&
      this.state.selectedAction !== actionsList[1].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  editTLDControl() {
    if (
      this.state.selectedAction !== undefined &&
      this.state.selectedAction !== actionsList[2].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  editBranchControl() {
    if (
      this.state.selectedAction !== undefined &&
      this.state.selectedAction !== actionsList[3].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  activeMode() {
    if (this.state.selectedAction !== undefined) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  cancelTLD(type) {
    let resetTLDName = "";
    let resetLogoName = "";
    if (type === "edit") {
      resetTLDName = this.state.originalTLDName;
      resetLogoName = this.state.originalLogoName;
    }
    this.setState({
      tldName: resetTLDName,
      logoName: resetLogoName,
      files: [],
    });
    document.getElementById("addTLDLogoFile").value = "";
    document.getElementById("editTLDLogoFile").value = "";
  }

  cancelBranchAdditionHandler() {
    this.trackAddBranchCancelButtonClick();
    this.cancelBranch("add");
  }

  cancelBranchUpdateHandler() {
    this.trackEditBranchCancelButtonClick();
    this.cancelBranch("edit");
  }

  cancelBranch(type) {
    if (type === "edit") {
      this.getBranchInfo();
    } else {
      this.setState({
        branchName: "",
        LEDTier: 1,
        constructionTier: 1,
        customerID: "",
        location: "",
        province: "",
        emailDomain: "",
        freeShipping: 0.0,
        selectedCSR: "",
        selectedSalesAgency: "",
        selectedSalesRep: [],
        selectedSalesRepUserId: [],
      });
      this.generateTLDList();
    }
  }

  freshUpdate() {
    let changesMade = 0;
    let selectedBranchInfo = this.state.branchInfoList.find(
      (item) => item.BranchId === this.state.selectedBranchId
    );

    let salesrepId = selectedBranchInfo.Salesrep
      ? selectedBranchInfo.Salesrep.split(",")
      : [];

    let csr = this.state.csrInfoList.find(
      (item) => item.UserId === selectedBranchInfo.CSR
    );

    if (this.state.branchName !== selectedBranchInfo.BranchName) {
      changesMade++;
    }
    if (this.state.location !== selectedBranchInfo.Location) {
      changesMade++;
    }
    if (this.state.province !== selectedBranchInfo.Province) {
      changesMade++;
    }
    if (this.state.customerID !== selectedBranchInfo.CustomerId) {
      changesMade++;
    }
    if (this.state.freeShipping !== selectedBranchInfo.FreeShipping) {
      changesMade++;
    }
    if (this.state.LEDTier !== selectedBranchInfo.LedTier) {
      changesMade++;
    }
    if (this.state.constructionTier !== selectedBranchInfo.ConstructionTier) {
      changesMade++;
    }
    if (this.state.selectedSalesAgency !== selectedBranchInfo.SalesAgency) {
      changesMade++;
    }
    if (this.state.selectedCSRUserId !== (csr ? csr.UserId : "")) {
      changesMade++;
    }
    if (this.state.emailDomain !== selectedBranchInfo.EmailDomain) {
      changesMade++;
    }
    if (
      this.state.selectedSalesRepUserId.toString() !== salesrepId.toString()
    ) {
      changesMade++;
    }

    let isChangeMade = false;
    if (changesMade > 0) {
      isChangeMade = true;
    }
    return isChangeMade;
  }

  selectActionDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedAction,
          label:
            this.state.selectedAction === null ||
            this.state.selectedAction === ""
              ? "Select..."
              : this.state.selectedAction,
        }}
        styles={customStyles}
        onChange={this.handleActionChange}
        options={actionsList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  TLDDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isSearchable
        value={{
          value: this.state.selectedTLD,
          label:
            this.state.selectedTLD === null || this.state.selectedTLD === ""
              ? "Select..."
              : this.state.selectedTLD,
        }}
        styles={customStyles}
        onChange={this.handleTLDChange}
        options={this.state.tldList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  BranchTLDDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isSearchable
        value={{
          value: this.state.selectedTLD,
          label:
            this.state.selectedTLD === null || this.state.selectedTLD === ""
              ? "Select..."
              : this.state.selectedTLD,
        }}
        styles={customStyles}
        onChange={this.handleBranchTLDChange}
        options={this.state.tldList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  provinceDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        isSearchable
        value={{
          value: this.state.province,
          label:
            this.state.province === null || this.state.province === ""
              ? "Select..."
              : this.state.province,
        }}
        styles={customStyles}
        onChange={this.handleProvinceChange}
        options={this.state.provinceList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  salesAgencyDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        isSearchable
        value={{
          value: this.state.selectedSalesAgency,
          label:
            this.state.selectedSalesAgency === null ||
            this.state.selectedSalesAgency === ""
              ? "Select..."
              : this.state.selectedSalesAgency,
        }}
        styles={customStyles}
        onChange={this.handleSalesAgencyChange}
        options={this.state.salesAgencyList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  LEDTierDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.LEDTier,
          label:
            this.state.LEDTier === null || this.state.LEDTier === ""
              ? "Select..."
              : this.state.LEDTier,
        }}
        styles={customStyles}
        onChange={this.handleLEDTierChange}
        options={this.state.ledTierList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  constructionTierDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.constructionTier,
          label:
            this.state.constructionTier === null ||
            this.state.constructionTier === ""
              ? "Select..."
              : this.state.constructionTier,
        }}
        styles={customStyles}
        onChange={this.handleConstructionTierChange}
        options={this.state.constructionTierList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  CSRDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        isSearchable
        value={{
          value: this.state.selectedCSR,
          label:
            this.state.selectedCSR === null || this.state.selectedCSR === ""
              ? "Select..."
              : this.state.selectedCSR,
        }}
        styles={customStyles}
        onChange={this.handleCSRChange}
        options={this.state.CSRList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  salesRepDropdown() {
    return (
      <Select
        maxMenuHeight={150}
        menuPlacement="auto"
        isMulti
        isClearable
        components={animatedComponents}
        className="basic-multi-select"
        value={this.state.selectedSalesRep}
        styles={multiCustomStyles}
        onChange={this.handleSalesRepChange}
        options={this.state.salesRepList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  getProvinces() {
    OrderSummaryHandler.getProvinces()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            provinceList: response.data.map((item) => {
              return {
                label: item.ProvinceName,
                value: item.ProvinceName,
              };
            }),
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.OrderSummary_Provinces,
          "error"
        );
      });
  }

  getTierLists() {
    DistributorManagementHandler.getTierList()
      .then((response) => {
        if (response.success && this._isMounted) {
          let ledTier = response.data.led_tier;
          let constructionTier = response.data.construction_tier;
          this.setState({
            ledTierList: ledTier.map((item) => {
              return {
                value: item.tiers,
                label: item.tiers,
              };
            }),
            constructionTierList: constructionTier.map((item) => {
              return {
                value: item.tiers,
                label: item.tiers,
              };
            }),
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.OrderSummary_TierList,
          "error"
        );
      });
  }

  // Mixpanel Tracking //

  trackActionDropdownSelection() {
    mixpanel.track("Branch/TLD Action", {
      Action: "Dropdown Selection",
      Effect: "Display filters related to selection",
      Option: this.state.selectedAction,
    });
  }

  trackTLDCreationButtonClick(tld, logo, message) {
    mixpanel.track("Create TLD", {
      Action: "Button Click",
      Effect: "New TLD will not be created",
      TLD: tld,
      Logo: logo,
      Message: message,
    });
  }

  trackTLDCreationSuccessButtonClick(tld, logo) {
    mixpanel.track("Create TLD", {
      Action: "Button Click",
      Effect: "New TLD created successfully",
      TLD: tld,
      Logo: logo,
    });
  }

  trackTLDCancellationButtonClick() {
    mixpanel.track("Cancel TLD Creation", {
      Action: "Button Click",
      Effect: "New TLD will not be created",
    });
  }

  trackBranchCreationButtonClick(branch, tld, message) {
    mixpanel.track("Create Branch", {
      Action: "Button Click",
      Effect: "New branch will not be created",
      Branch: branch,
      TLD: tld,
      Message: message,
    });
  }

  trackBranchCreationSuccessButtonClick(branch, tld) {
    mixpanel.track("Create Branch", {
      Action: "Button Click",
      Effect: "New branch created successfully",
      Branch: branch,
      TLD: tld,
    });
  }

  trackAddBranchCancelButtonClick() {
    mixpanel.track("Cancel Branch Creation", {
      Action: "Button Click",
      Effect: "New branch will not be created",
    });
  }

  trackTLDUpdateButtonClick(tldOld, tldNew, logo, message) {
    mixpanel.track("Update TLD", {
      Action: "Button Click",
      Effect: "Selected TLD will not be updated",
      "Old TLD Name": tldOld,
      "New TLD Name": tldNew,
      Logo: logo,
      Message: message,
    });
  }

  trackTLDUpdateSuccessButtonClick(tldOld, tldNew, logo) {
    mixpanel.track("Update TLD", {
      Action: "Button Click",
      Effect: "Selected TLD updated successfully",
      "Old TLD Name": tldOld,
      "New TLD Name": tldNew,
      Logo: logo,
    });
  }

  trackTLDRevertButtonClick() {
    mixpanel.track("Cancel TLD Update", {
      Action: "Button Click",
      Effect: "Selected TLD will not be updated",
    });
  }

  trackBranchUpdateButtonClick(branchOld, branchNew, tld, message) {
    mixpanel.track("Update Branch", {
      Action: "Button Click",
      Effect: "Selected branch will not be updated",
      "Old Branch Name": branchOld,
      "New Branch Name": branchNew,
      TLD: tld,
      Message: message,
    });
  }

  trackBranchUpdateSuccessButtonClick(branchOld, branchNew, tld) {
    mixpanel.track("Update Branch", {
      Action: "Button Click",
      Effect: "Selected branch updated successfully",
      "Old Branch Name": branchOld,
      "New Branch Name": branchNew,
      TLD: tld,
    });
  }

  trackEditBranchCancelButtonClick() {
    mixpanel.track("Cancel Branch Update", {
      Action: "Button Click",
      Effect: "Selected Branch will not be updated",
    });
  }

  trackBranchOverviewNavigation() {
    mixpanel.track_pageview({
      Page: "Branch Overview Page",
    });
  }
}

export default DistributorManagement;
