import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Button from "react-bootstrap/lib/Button";
import FormControl from "react-bootstrap/lib/FormControl";
import Collapse from "react-bootstrap/lib/Collapse";
import Table from "react-bootstrap/lib/Table";
import Pagination from "react-js-pagination";
import Panel from "react-bootstrap/lib/Panel";
import NotificationSystem from "react-notification-system";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import "./orderSummaryHistory.css";
import OrderSummaryHistoryHandler from "./OrderSummaryHistoryHandler.js";
import AvailabilityListPopUp from "../AvailabilityListPopup/AvailabilityListPopup";
import SelectCustomerPopup from "../SelectCustomerPopup/SelectCustomerPopup";
import fixedLink from "../Assets/fixedAssets.json";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading.js";
import ConfigHandler from "../ConfigHandler/ConfigHandler.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 125,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

let statusList = ["Won", "Lost", "Active", "Inactive"];
let typeList = ["Download", "Quote"];
let followUpList = ["Yes", "No"];

let MAX_ITEMS = 10;
const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the Estimate Summary History section
 */
class OrderSummaryHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAvailabilityPopup: false,
      showSelectCustomerPopup: false,
      orderSummaryHistory: [],
      filteredResults: [],
      open: [false],
      orderSummaryHistoryDetail: [],
      activePage: 1,
      startDate: null,
      endDate: null,
      filterByOrderName: null,
      filterByOrderType: null,
      filterByDownloadQuote: null,
      filterByContractor: null,
      filterByAddress: null,
      filterByFollowUp: null,
      filterByProjectStatus: null,
      filterByCustomerBranch: null,
      gotResponse: false,
      currentCustomer: null,
      // forAddToCart
      productList: [],
      Quantity: 0,
      cstColor: null,
      description: null,
      price: 0,
      salePrice: 0,
      setId: null,
      wattage: null,
      productId: null,
      StockCode: null,
      itemsPresent: [],
      customerName: null,
      count: 0,
      estimateId: null,
      availableList: [],
      unavailableList: [],
      roleType: "",
      pricingAccess: true,
      specLinkPopupDescription: "",
      specLinkPopupStockCode: "",
      showSpecLinkPopup: false,
      currentProductId: "NA",
      engSpecLink: "",
      frSpecLink: "",
      itemCategory: "accessories",
    };
    this._notificationSystem = null;
    this.showIcon = this.showIcon.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.modifyNotes = this.modifyNotes.bind(this);
    this.getPreviousOrders = this.getPreviousOrders.bind(this);
    this.createProductList = this.createProductList.bind(this);
    this.getProductInfo = this.getProductInfo.bind(this);
    this.addPreviousOrderToSummary = this.addPreviousOrderToSummary.bind(this);
    this.getCustomerName = this.getCustomerName.bind(this);
    this.addItemsToCurrentCart = this.addItemsToCurrentCart.bind(this);
    this.getSpecificItem = this.getSpecificItem.bind(this);
    this.showSpecLink = this.showSpecLink.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
  }

  componentDidMount() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    let roleType = branchBasedRoles.includes(userObj["role"])
      ? "Branch"
      : "Customer";
    this.setState({
      roleType: roleType,
      pricingAccess: JSON.parse(sessionStorage.getItem("pricingAccess")),
      currentCustomer:
        roleType === "Branch" ? sessionStorage.getItem("customerName") : null,
    });

    this.getEstimateHistory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filterByOrderName !== this.state.filterByOrderName ||
      prevState.filterByOrderType !== this.state.filterByOrderType ||
      prevState.filterByDownloadQuote !== this.state.filterByDownloadQuote ||
      prevState.filterByContractor !== this.state.filterByContractor ||
      prevState.filterByAddress !== this.state.filterByAddress ||
      prevState.filterByFollowUp !== this.state.filterByFollowUp ||
      prevState.filterByProjectStatus !== this.state.filterByProjectStatus ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.filterTable();
    }
  }

  getEstimateHistory() {
    OrderSummaryHistoryHandler.getEstimateHistory()
      .then((response) => {
        let list = response.data;
        list.forEach((item) => {
          let dateTime = new Date(item.FormatDate + " UTC");
          let formattedDateTime = dateTime.toString();
          item.Date = new Date(formattedDateTime);
        });

        this.setState({
          orderSummaryHistory: list,
          filteredResults: list,
          gotResponse: true,
        });
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummaryHistory_EstimateHistory,
          "error"
        );
      });
  }

  handleTypeChange(e, index) {
    let status = e.value;
    let id = this.state.orderSummaryHistory[index].Id;
    this.trackProjectStatusDropdownSelection(status, id);
    OrderSummaryHistoryHandler.changeHistoryRowStatus(status, id)
      .then((response) => {
        if (response.success) {
          let detail = this.state.orderSummaryHistory;
          detail[index].Status = response.message;
          this.setState({
            orderSummaryHistory: detail,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummaryHistory_HistoryRowStatusChange,
          "error"
        );
      });
  }

  setOpen(id, index) {
    let isOpen = !this.state.open[index];
    let open = this.state.open;
    open[index] = isOpen;
    if (isOpen) {
      OrderSummaryHistoryHandler.getEstimateHistoryDetail(id)
        .then((response) => {
          let detail = this.state.orderSummaryHistoryDetail;

          let temp_orderSummaryHistoryDetail = {
            orderSummaryRecords: response.data.list,
            notes: response.data.notes.length > 0 ? response.data.notes[0] : "",
          };
          detail[index] = temp_orderSummaryHistoryDetail;
          this.setState({
            orderSummaryHistoryDetail: detail,
            open: open,
          });
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.OrderSummaryHistory_EstimateHistoryDetail,
            "error"
          );
        });
    } else {
      this.setState({
        open: open,
      });
    }
  }

  showIcon(index) {
    let expanded = this.state.open[index];
    let url = fetchResourceData(fixedLink.nextRightIcon);
    if (expanded) {
      url = fetchResourceData(fixedLink.nextDownIcon);
    }
    return (
      <img
        height={20}
        width={20}
        src={url}
        alt="next"
        style={{ display: "inline", margin: "5px" }}
      />
    );
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  filterTable() {
    if (this.state.startDate > this.state.endDate) {
      this.setState({ endDate: null });
      this.addNotification(
        ErrorMessages.OrderSummaryHistory_EndDate,
        "warning"
      );
    }
    let results = this.state.orderSummaryHistory;

    if (this.state.filterByOrderName !== null) {
      results = results.filter(
        (item) => item.OrderName === this.state.filterByOrderName
      );
    }

    if (this.state.filterByOrderType !== null) {
      results = results.filter(
        (item) => item.OrderType === this.state.filterByOrderType
      );
    }

    if (this.state.filterByDownloadQuote !== null) {
      results = results.filter(
        (item) => item.Type === this.state.filterByDownloadQuote
      );
    }

    if (this.state.filterByContractor !== null) {
      results = results.filter(
        (item) => item.ContractorName === this.state.filterByContractor
      );
    }

    if (this.state.filterByAddress !== null) {
      results = results.filter(
        (item) => item.Address === this.state.filterByAddress
      );
    }

    if (this.state.filterByFollowUp !== null) {
      results = results.filter(
        (item) => item.FollowUpStatus === this.state.filterByFollowUp
      );
    }

    if (this.state.filterByProjectStatus !== null) {
      results = results.filter(
        (item) => item.Status === this.state.filterByProjectStatus
      );
    }

    if (this.state.filterByCustomerBranch !== null) {
      results = results.filter(
        (item) => item.Customer === this.state.filterByCustomerBranch
      );
    }

    if (this.state.startDate !== null && this.state.endDate !== null) {
      let startDate = new Date(this.state.startDate);
      let startDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0
      );
      let endDate = new Date(this.state.endDate);
      let endDateTime = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        23,
        59,
        59
      ); // set time to end of the day

      results = results.filter((item) => {
        let tempDate = new Date(item.Date);
        return (
          tempDate >= new Date(startDateTime) &&
          tempDate <= new Date(endDateTime)
        );
      });
    }

    this.setState({
      filteredResults: results,
      activePage: 1,
      open: [false],
    });
  }

  onChangeFilterCriteria(name, value) {
    this.setState({
      [name]: value,
    });
  }

  clearAllFilters() {
    this.trackClearAllFiltersButtonClick();

    this.setState({
      startDate: null,
      endDate: null,
      filterByOrderName: null,
      filterByOrderType: null,
      filterByDownloadQuote: null,
      filterByContractor: null,
      filterByAddress: null,
      filterByFollowUp: null,
      filterByProjectStatus: null,
      filterByCustomerBranch: null,
    });
  }

  getSpecificItem(id, index, item) {
    let temp = this.state.orderSummaryHistoryDetail;
    let data = temp[index].orderSummaryRecords.filter(
      (e) => e.Description === item.Description
    );

    this.setState(
      {
        estimateId: id,
      },
      () => {
        this.getCustomerName(data);
      }
    );
  }

  getPreviousOrders(id, index, e) {
    let temp = this.state.orderSummaryHistoryDetail;
    let data = temp[index].orderSummaryRecords;

    this.setState(
      {
        estimateId: id,
      },
      () => {
        this.getCustomerName(data);
      }
    );
  }

  getCustomerName(data) {
    OrderSummaryHistoryHandler.getCustomerName().then((response) => {
      if (this.state.currentCustomer === null) {
        this.setState({
          customerName: response.message,
        });
      } else {
        this.setState((prevState) => ({
          customerName: prevState.currentCustomer,
        }));
      }
      this.setState(
        {
          itemsPresent: data,
          productList: [],
          availableList: [],
          unavailableList: [],
          Quantity: 0,
          cstColor: null,
          description: null,
          price: 0,
          salePrice: 0,
          setId: null,
          wattage: null,
          productId: null,
          StockCode: null,
          count: 0,
        },
        this.createProductList(data)
      );
    });
  }

  createProductList(list) {
    list.forEach((item) => {
      this.getProductInfo(item, item.Description);
    });
  }

  getProductInfo(item, description) {
    let estimateId = this.state.estimateId;
    let tempDesc = encodeURIComponent(description);
    OrderSummaryHistoryHandler.getProductInfo(tempDesc, estimateId)
      .then((response) => {
        if (response.data.wattage !== "Invalid") {
          this.setState({
            wattage: response.data.wattage,
            description: response.data.description,
            productId: response.data.productId,
            setId: response.data.setId,
            cstColor: response.data.cstColor,
          });
          let data = {
            StockCode: item.StockCode,
            Quantity: item.Quantity,
            cstColor: response.data.cstColor,
            wattage: response.data.wattage,
            description: response.data.description,
            productId: response.data.productId,
            setId: response.data.setId,
            price: item.OldPrice,
            salePrice: item.Price,
          };

          this.state.productList.push(data);
          this.state.availableList.push(description);
        } else {
          this.state.unavailableList.push(description);
        }
        this.setState(
          (prevState) => ({
            count: prevState.count + 1,
          }),
          this.addItemsToCurrentCart()
        );
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummaryHistory_ProductInfo,
          "error"
        );
      });
  }

  addItemsToCurrentCart() {
    if (this.state.count === this.state.itemsPresent.length - 1) {
      if (this.state.customerName === "") {
        this.setState({ showSelectCustomerPopup: true });
      } else if (this.state.unavailableList.length > 0) {
        this.setState({ showAvailabilityPopup: true });
      } else {
        let data = {
          title: "Previous Orders",
          action: "AddPrevOrdersToSummary",
          orderlist: this.state.productList,
          customerName:
            this.state.customerName === ""
              ? sessionStorage.getItem("customerName")
              : this.state.customerName,
        };
        this.addPreviousOrderToSummary(data);
      }
    }
  }

  addPreviousOrderToSummary(data) {
    OrderSummaryHistoryHandler.addPreviousOrdersToSummary(data)
      .then((response) => {
        if (!response.success && response.message === "Failed") {
          this.trackAddToCartButtonClick(
            ErrorMessages.OrderSummaryHistory_UnableToAddItems
          );
          this.addNotification(
            ErrorMessages.OrderSummaryHistory_UnableToAddItems,
            "error"
          );
        } else if (!response.success) {
          this.trackAddToCartButtonClick(response.message);
          this.addNotification(response.message, "error");
        } else if (response.success && this.state.availableList.length > 0) {
          let allProducts = data.orderlist;

          if (allProducts.length === 1) {
            let wattage = allProducts[0].wattage;
            let description = "";
            if (wattage.length > 0) {
              description = `${wattage}-${allProducts[0].description}`;
            } else {
              description = allProducts[0].description;
            }
            let stockCode = allProducts[0].StockCode;

            this.trackAddToCartButtonSuccess(description, stockCode);
          } else {
            this.trackAddMultipleToCartButtonClick(this.state.estimateId);
          }

          this.addNotification(
            DisplayMessages.OrderSummaryHistory_SuccessfullyAdded,
            "info"
          );
        }
      })
      .catch((error) => {
        this.trackAddToCartButtonClick(
          ErrorMessages.OrderSummaryHistory_AddPreviousOrdersToSummary
        );
        this.addNotification(
          ErrorMessages.OrderSummaryHistory_AddPreviousOrdersToSummary,
          "error"
        );
      });
  }

  getEstimateHistoryDetail(id, index) {
    return (
      <Collapse in={this.state.open[index]}>
        <Row style={{ margin: "2em" }}>
          <Col md={6} sm={12} xs={12} hidden={this.hideHistoryContents(index)}>
            <Table id="estimate-detail">
              {this.showHeaders()}
              {this.showIndividualItems(id, index)}
            </Table>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Panel
              eventKey={1}
              key="notes"
              style={{ display: "flex" }}
              defaultExpanded
            >
              <Panel.Heading>
                <Panel.Title>Additional Notes</Panel.Title>
              </Panel.Heading>
              <FormControl
                componentClass="textarea"
                style={{ resize: "none", borderRadius: "0" }}
                rows="3"
                value={
                  this.state.orderSummaryHistoryDetail[index]
                    ? this.state.orderSummaryHistoryDetail[index]["notes"].Notes
                    : ""
                }
                spellCheck="false"
                maxLength="500"
                placeholder={
                  DisplayMessages.OrderSummaryHistory_NotesPlaceHolder
                }
                onChange={(e) => this.modifyNotes(id, index, e)}
              />
            </Panel>
            <div hidden={this.hideHistoryContents(index)}>
              <Button
                bsStyle="primary"
                className="button-blue-summary"
                onClick={(e) => this.getPreviousOrders(id, index, e)}
              >
                Add Items to Current Cart
              </Button>
            </div>
          </Col>
        </Row>
      </Collapse>
    );
  }

  showHeaders() {
    return (
      <thead style={{ textAlign: "center" }}>
        <tr>
          <th>Stock Code</th>
          <th>Description</th>
          <th>
            <span style={{ margin: "0px 10px" }}>Specs</span>
          </th>
          <th>Quantity</th>
          <th hidden={!this.state.pricingAccess}>Distributor Total Price</th>
          <th>Add Item To Cart</th>
        </tr>
      </thead>
    );
  }

  showIndividualItems(id, index) {
    if (this.state.orderSummaryHistoryDetail[index]) {
      return this.state.orderSummaryHistoryDetail[index][
        "orderSummaryRecords"
      ].map((item) => {
        return (
          <tbody
            key={item.StockCode + "_" + item.Description}
            style={{ textAlign: "center" }}
          >
            <tr>
              <td>{item.StockCode}</td>
              <td>{item.Description}</td>
              <td onClick={() => this.showSpecLink(item)}>
                <SpecTooltipPopup
                  specValue={item.SpecValue}
                  productId={this.state.currentProductId}
                  stockCodeTitle={item.StockCode}
                  descriptionTitle={item.Description}
                  engSpecLink={this.state.engSpecLink}
                  frSpecLink={this.state.frSpecLink}
                  addNotification={this.addNotification}
                  isNavigationEnabled={this.state.itemCategory === "base"}
                  onModal={false}
                />
              </td>
              <td>{item.Quantity}</td>
              <td hidden={!this.state.pricingAccess}>
                {this.displayPrice(item)}
                {item.WarehouseFee !== null && parseFloat(item.WarehouseFee) > 0
                  ? " + $" + parseFloat(item.WarehouseFee).toFixed(2)
                  : ""}
              </td>
              <td className="center-in-td">
                <Button
                  className="glyphicon glyphicon-plus"
                  onClick={(e) => this.getSpecificItem(id, index, item)}
                />
              </td>
            </tr>
          </tbody>
        );
      });
    } else {
      return null;
    }
  }

  showSpecLink(element) {
    let specLinkPopupStockCode = element.StockCode;
    let specLinkPopupDescription = element.Description;
    let specValue = element.SpecValue;
    let pid = "";

    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          pid
        );
        this.getSpecLinks(specLinkPopupDescription, pid);
      }
    );
  }

  getSpecLinks(description, pid) {
    let element = {
      description: description,
      productId: pid.toString(),
    };
    ConfigHandler.getSpecLinks(element)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            specValue: data.Specs,
            itemCategory: data.Category,
            currentProductId: data.ProductId,
          });
        } else {
          this.setState({
            specValue: "N/A",
            itemCategory:
              element.productId !== "NA" && data.productId !== ""
                ? "base"
                : "accessories",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  hideHistoryContents(index) {
    let isHistoryHidden = true;

    if (
      this.state.orderSummaryHistoryDetail[index] !== undefined &&
      this.state.orderSummaryHistoryDetail[index].orderSummaryRecords.length > 0
    ) {
      isHistoryHidden = false;
    }
    return isHistoryHidden;
  }

  displayPrice(item) {
    let price = +parseFloat(item.Price);
    let oldPrice = +parseFloat(item.OldPrice);

    if (price === oldPrice) {
      return <div>${price.toFixed(2)}</div>;
    } else {
      return (
        <div>
          <div style={{ textDecorationLine: "line-through" }}>
            ${oldPrice.toFixed(2)}
          </div>
          <div style={{ color: "red" }}>${price.toFixed(2)}</div>
        </div>
      );
    }
  }

  modifyNotes(id, index, e) {
    let temp = this.state.orderSummaryHistoryDetail;
    temp[index]["notes"].Notes = e.target.value;
    this.setState({
      orderSummaryHistoryDetail: temp,
    });
    let list = {
      id: id,
      notesText: e.target.value,
    };
    OrderSummaryHistoryHandler.updateEstimateHistoryNotes(list)
      .then((response) => {})
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummaryHistory_UpdateHistoryNotes,
          "error"
        );
      });
  }

  getOrderNameFilters() {
    let options = [{ value: null, label: "Order Name..." }];
    let array = this.state.orderSummaryHistory.map((item) => item.OrderName);
    let unique = array
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b);
    unique.forEach((item) => {
      options.push({ value: item, label: item });
    });
    return options;
  }

  getOrderTypeFilters() {
    let options = [{ value: null, label: "Order Type..." }];
    let array = this.state.orderSummaryHistory.map((item) => item.OrderType);
    let unique = array
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b);
    unique.forEach((item) => {
      options.push({ value: item, label: item });
    });
    return options;
  }

  getDownloadQuoteFilters() {
    let options = [{ value: null, label: "Download/Quote..." }];
    typeList.forEach((item) => options.push({ value: item, label: item }));
    return options;
  }

  getContractorFilters() {
    let options = [{ value: null, label: "Contractor..." }];
    let array = this.state.orderSummaryHistory.map(
      (item) => item.ContractorName
    );
    let unique = array
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b);
    unique.forEach((item) => {
      options.push({ value: item, label: item });
    });
    return options;
  }

  getAddressFilters() {
    let options = [{ value: null, label: "Ship To Address..." }];
    let array = this.state.orderSummaryHistory.map((item) => item.Address);
    let unique = array
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b);
    unique.forEach((item) => {
      options.push({ value: item, label: item });
    });
    return options;
  }

  getFollowUpFilters() {
    let options = [{ value: null, label: "Follow Up..." }];
    followUpList.forEach((item) => options.push({ value: item, label: item }));
    return options;
  }

  getStatusFilters() {
    let options = [{ value: null, label: "Status..." }];
    statusList.forEach((item) => options.push({ value: item, label: item }));
    return options;
  }

  displayFilterRow() {
    return (
      <div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            md={2}
            xs={2}
            sm={2}
            style={{ textAlign: this.filtersEnabled() ? "right" : "center" }}
          >
            <h4>
              <strong>Filter By:</strong>
            </h4>
          </Col>
          {this.filtersEnabled() ? (
            <Col md={2} xs={2} sm={2}>
              <Row>
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  onClick={this.clearAllFilters}
                >
                  Clear All Filters <i className="glyphicon glyphicon-remove" />
                </Button>
              </Row>
            </Col>
          ) : null}
        </Row>
        <br />
        <Row style={{ display: "flex" }}>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">From Date</span>
            </Row>
            <Row>
              <input
                type="date"
                className="date-selection"
                placeholder="dd-mm-yyyy"
                style={{
                  width: "100%",
                  height: "32px",
                  border: "1px solid #ccc",
                }}
                value={this.state.startDate ? this.state.startDate : ""}
                onChange={(e) => {
                  this.trackFilterDateChange("Start Date", e.target.value);
                  this.onChangeFilterCriteria("startDate", e.target.value);
                }}
              />
            </Row>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Order Name</span>
            </Row>
            <Row>
              <Select
                name="filterByOrderName"
                menuPlacement="auto"
                isDisabled={true}
                styles={customStyles}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Order Name", e.value);
                  this.onChangeFilterCriteria("filterByOrderName", e.value);
                }}
                value={this.state.filterByOrderName}
                placeholder={
                  this.state.filterByOrderName
                    ? this.state.filterByOrderName
                    : "Select..."
                }
                options={this.getOrderNameFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Order Type</span>
            </Row>
            <Row>
              <Select
                name="filterByOrderType"
                menuPlacement="auto"
                styles={customStyles}
                isDisabled={this.state.orderSummaryHistory.length === 0}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Order Type", e.value);
                  this.onChangeFilterCriteria("filterByOrderType", e.value);
                }}
                value={this.state.filterByOrderType}
                placeholder={
                  this.state.filterByOrderType
                    ? this.state.filterByOrderType
                    : "Select..."
                }
                options={this.getOrderTypeFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
        </Row>
        <br />
        <Row style={{ display: "flex" }}>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">To Date</span>
            </Row>
            <Row>
              <input
                type="date"
                placeholder="dd-mm-yyyy"
                className="date-selection"
                style={{
                  width: "100%",
                  height: "32px",
                  border: "1px solid #ccc",
                }}
                value={this.state.endDate ? this.state.endDate : ""}
                onChange={(e) => {
                  this.trackFilterDateChange("End Date", e.target.value);
                  this.onChangeFilterCriteria("endDate", e.target.value);
                }}
              />
            </Row>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Download/Quote</span>
            </Row>
            <Row>
              <Select
                name="filterByDownloadQuote"
                menuPlacement="auto"
                styles={customStyles}
                isDisabled={this.state.orderSummaryHistory.length === 0}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Download/Quote", e.value);
                  this.onChangeFilterCriteria("filterByDownloadQuote", e.value);
                }}
                value={this.state.filterByDownloadQuote}
                placeholder={
                  this.state.filterByDownloadQuote
                    ? this.state.filterByDownloadQuote
                    : "Select..."
                }
                options={this.getDownloadQuoteFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Contractor</span>
            </Row>
            <Row>
              <Select
                name="filterByContractor"
                menuPlacement="auto"
                styles={customStyles}
                isDisabled={this.state.orderSummaryHistory.length === 0}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Contractor", e.value);
                  this.onChangeFilterCriteria("filterByContractor", e.value);
                }}
                value={this.state.filterByContractor}
                placeholder={
                  this.state.filterByContractor
                    ? this.state.filterByContractor
                    : "Select..."
                }
                options={this.getContractorFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
        </Row>
        <br />

        <Row style={{ display: "flex" }}>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Ship To Address</span>
            </Row>
            <Row>
              <Select
                name="filterByAddress"
                menuPlacement="auto"
                styles={customStyles}
                isDisabled={this.state.orderSummaryHistory.length === 0}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Address", e.value);
                  this.onChangeFilterCriteria("filterByAddress", e.value);
                }}
                value={this.state.filterByAddress}
                placeholder={
                  this.state.filterByAddress
                    ? this.state.filterByAddress
                    : "Select..."
                }
                options={this.getAddressFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Follow Up Status</span>
            </Row>
            <Row>
              <Select
                name="filterByFollowUp"
                menuPlacement="auto"
                styles={customStyles}
                isDisabled={this.state.orderSummaryHistory.length === 0}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Follow Up", e.value);
                  this.onChangeFilterCriteria("filterByFollowUp", e.value);
                }}
                value={this.state.filterByFollowUp}
                placeholder={
                  this.state.filterByFollowUp
                    ? this.state.filterByFollowUp
                    : "Select..."
                }
                options={this.getFollowUpFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <Row>
              <span className="default-label">Project Status</span>
            </Row>
            <Row>
              <Select
                name="filterByProjectStatus"
                menuPlacement="auto"
                styles={customStyles}
                isDisabled={this.state.orderSummaryHistory.length === 0}
                onChange={(e) => {
                  this.trackFilterDropdownSelection("Project Status", e.value);
                  this.onChangeFilterCriteria("filterByProjectStatus", e.value);
                }}
                value={this.state.filterByProjectStatus}
                placeholder={
                  this.state.filterByProjectStatus
                    ? this.state.filterByProjectStatus
                    : "Select..."
                }
                options={this.getStatusFilters()}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  filtersEnabled() {
    let filterStatus = false;
    if (this.state.startDate !== null && this.state.endDate !== null) {
      filterStatus = true;
    }
    if (this.state.filterByOrderName !== null) {
      filterStatus = true;
    }
    if (this.state.filterByOrderType !== null) {
      filterStatus = true;
    }
    if (this.state.filterByDownloadQuote !== null) {
      filterStatus = true;
    }
    if (this.state.filterByContractor !== null) {
      filterStatus = true;
    }
    if (this.state.filterByAddress !== null) {
      filterStatus = true;
    }
    if (this.state.filterByFollowUp !== null) {
      filterStatus = true;
    }
    if (this.state.filterByProjectStatus !== null) {
      filterStatus = true;
    }

    return filterStatus;
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div className="estimate-page-container" style={{ minWidth: "500px" }}>
        {this.state.gotResponse
          ? this.orderSummaryHistorySection()
          : this.orderSummaryHistoryLoadingSection()}
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
        <AvailabilityListPopUp
          show={this.state.showAvailabilityPopup}
          title="Product Availability"
          avail={this.state.availableList}
          unavail={this.state.unavailableList}
          handleAccept={this.handleAccept.bind(this)}
          handleClose={this.handleClose.bind(this)}
        />
        <SelectCustomerPopup
          show={this.state.showSelectCustomerPopup}
          title="Assign Customer to Cart"
          handleCustomerClose={this.handleCustomerClose.bind(this)}
          addNotification={this.addNotification}
        />
      </div>
    );
  }

  orderSummaryHistoryLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.OrderSummaryHistory_Loading}
        size={80}
      />
    );
  }

  orderSummaryHistoryHeader() {
    return (
      <Row className="estimate-history-header">
        <Col md={1} sm={6} xs={6}>
          Date
        </Col>
        <Col md={2} sm={6} xs={6}>
          Order Name
        </Col>
        <Col md={1} sm={6} xs={6}>
          Order Type
        </Col>
        <Col md={1} sm={6} xs={6}>
          Download or Quote
        </Col>
        <Col md={2} sm={6} xs={6}>
          Contractor Name
        </Col>
        <Col md={2} sm={6} xs={6}>
          Ship To Address
        </Col>
        <Col md={1} sm={6} xs={6}>
          Follow Up Status
        </Col>
        <Col md={1} sm={6} xs={6}>
          Project Status
        </Col>
        <Col md={1} sm={2} xs={2} />
      </Row>
    );
  }

  orderSummaryHistoryNoResultsSection() {
    return (
      <div>
        <br />
        <br />
        <h4 className="text-center">No results to display</h4>
      </div>
    );
  }

  orderSummaryHistoryPagination() {
    if (this.state.filteredResults.length > MAX_ITEMS) {
      return (
        <Row className={"pagination-container show-component"} align="center">
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={MAX_ITEMS}
            totalItemsCount={this.state.filteredResults.length}
            onChange={this.handlePageChange.bind(this)}
          />
        </Row>
      );
    } else return null;
  }

  orderSummaryHistoryRows() {
    return (
      <div>
        {this.state.filteredResults.length !== 0 &&
          this.state.filteredResults.map((item, index) => {
            return (
              <div key={item.Id}>
                {index >= MAX_ITEMS * (this.state.activePage - 1) &&
                index < MAX_ITEMS * (this.state.activePage - 1) + MAX_ITEMS ? (
                  <div className="estimate-history">
                    <Row
                      className="estimate-history-row"
                      onClick={() => this.setOpen(item.Id, index)}
                    >
                      <Col md={1} sm={6} xs={6}>
                        {item.Date.getDate() +
                          "-" +
                          (item.Date.getMonth() + 1) +
                          "-" +
                          item.Date.getFullYear()}
                      </Col>
                      <Col md={2} sm={6} xs={6}>
                        {item.ProjectName}
                      </Col>
                      <Col md={1} sm={5} xs={5}>
                        {item.OrderType}
                      </Col>
                      <Col md={1} sm={5} xs={5}>
                        {item.Type}
                      </Col>
                      <Col md={2} sm={5} xs={5}>
                        <div
                          style={{
                            wordBreak: "break-all",
                            overflowWrap: "break-word",
                          }}
                        >
                          {item.ContractorName}
                        </div>
                      </Col>
                      <Col md={2} sm={5} xs={5}>
                        <div
                          style={{
                            wordBreak: "break-all",
                            overflowWrap: "break-word",
                          }}
                        >
                          {item.Address}
                        </div>
                      </Col>
                      <Col md={1} sm={5} xs={5}>
                        {item.FollowUpStatus}
                      </Col>
                      <Col md={1} sm={5} xs={5}>
                        {this.itemStatusDropdown(item, index)}
                      </Col>
                      <Col md={1} sm={2} xs={2}>
                        {this.showIcon(index)}
                      </Col>
                    </Row>

                    {this.getEstimateHistoryDetail(item.Id, index)}
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
    );
  }

  orderSummaryHistorySection() {
    if (this.state.orderSummaryHistory.length > 0) {
      return (
        <div className="loading-fade">
          {this.displayFilterRow()}
          {this.state.filteredResults.length === 0
            ? this.orderSummaryHistoryNoResultsSection()
            : this.orderSummaryHistoryHeader()}
          {this.orderSummaryHistoryRows()}
          {this.orderSummaryHistoryPagination()}
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="text-center">No data to display</h4>
        </div>
      );
    }
  }

  itemStatusDropdown(item, index) {
    return (
      <div>
        <Select
          maxMenuHeight={190}
          menuPlacement="auto"
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={{
            value: item.Status,
            label: item.Status,
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => this.handleTypeChange(e, index)}
          options={this.getStatusFilters()}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "lightgray",
              primary: "lightgray",
            },
          })}
        />
      </div>
    );
  }

  handleAccept() {
    this.trackProductAvailabilityAcknowledgementClick();
    this.setState({ showAvailabilityPopup: false });
    let data = {
      title: "Previous Orders",
      action: "AddPrevOrdersToSummary",
      orderlist: this.state.productList,
      customerName:
        this.state.customerName === ""
          ? sessionStorage.getItem("customerName")
          : this.state.customerName,
    };
    this.addPreviousOrderToSummary(data);
  }

  handleClose() {
    this.setState({ showAvailabilityPopup: false });
  }

  handleCustomerClose() {
    let customer =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";

    this.setState(
      {
        showSelectCustomerPopup: false,
        currentCustomer: customer,
      },
      () => {
        if (this.state.currentCustomer !== "") {
          this.addNotification(
            "Customer Specified: " + this.state.currentCustomer,
            "info"
          );
          if (this.state.unavailableList.length > 0) {
            this.setState({ showAvailabilityPopup: true });
          } else {
            let data = {
              title: "Previous Orders",
              action: "AddPrevOrdersToSummary",
              orderlist: this.state.productList,
              customerName:
                this.state.currentCustomer === ""
                  ? sessionStorage.getItem("customerName")
                  : this.state.currentCustomer,
            };
            this.addPreviousOrderToSummary(data);
          }
        } else {
          this.addNotification(
            ErrorMessages.OrderSummaryHistory_CustomerNotSpecified,
            "warning"
          );
        }
      }
    );
  }

  handlePageChange(pageNumber) {
    this.trackPagination(pageNumber);
    this.setState({ open: [], activePage: pageNumber });
  }

  // Mixpanel Tracking //

  trackClearAllFiltersButtonClick() {
    mixpanel.track("Clear All Filters", {
      Action: "Button Click",
      Effect: "Clear all selected filters",
    });
  }

  trackFilterDateChange(filterType, date) {
    mixpanel.track("Date Filter - Estimate Summary History", {
      Action: "Date Picker",
      Effect: "Select date to filter estimate summary orders",
      "Filter By": filterType,
      Date: date,
    });
  }

  trackFilterDropdownSelection(filterType, value) {
    mixpanel.track("Filter By - Estimate Summary History", {
      Action: "Dropdown Selection",
      Effect: "Select filter to be applied on estimate summary orders",
      "Filter By": filterType,
      "Filter Value": value,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Estimate Summary History Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }

  trackProjectStatusDropdownSelection(status, id) {
    mixpanel.track("Project Status", {
      Action: "Button Click",
      Effect: "Change the selected order's project status",
      "Project Status": status,
      "Order ID": id,
    });
  }

  trackAddToCartButtonClick(message) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Attempt to add item(s) to current estimate summary cart",
      Page: "Estimate Summary History Page",
      "Added To Cart": false,
      Message: message,
    });
  }

  trackAddMultipleToCartButtonClick(id) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Selected items are added to cart",
      Page: "Estimate Summary History Page",
      "Added To Cart": true,
      "Estimate ID": id,
    });
  }

  trackAddToCartButtonSuccess(description, stockCode) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Successfully added to cart",
      Page: "Estimate Summary History Page",
      "Added To Cart": true,
      "SKU Description": description,
      "SKU Stock Code": stockCode,
    });
  }

  trackProductAvailabilityAcknowledgementClick() {
    mixpanel.track("Product Availability", {
      Action: "Button Click",
      Effect: "Product Availability acknowledged",
    });
  }

  trackSpecsClick(specs, description, stockCode, pid) {
    mixpanel.track("Specs", {
      Action: "Button Click",
      Effect: "Show/Hide Specs Tooltip",
      Specs: specs,
      Description: description,
      "Stock Code": stockCode,
      "Product ID": pid,
    });
  }
}

export default OrderSummaryHistory;
