import React, { Component } from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Checkbox from "react-bootstrap/lib/Checkbox";
import Spinner from "react-loading";

import fixedLink from "../Assets/fixedAssets.json";
import "./filter_button.css";

/**
 * Component represents the filter button in use
 */
class FilterButton extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      imageIcon: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let filterTypeName = "";
    switch (this.props.filterType) {
      case "Lighting Space":
        filterTypeName = this.props.filterItem.space_name;
        break;
      case "Application":
        filterTypeName = this.props.filterItem.application_name;
        break;
      case "Light Source":
        filterTypeName = this.props.filterItem.light_source_name;
        break;
      default:
        filterTypeName = this.props.filterItem.space_name;
        break;
    }

    if (this._isMounted) {
      this.setState({
        imageIcon: filterTypeName,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        {this.state.imageIcon.length > 0 ? (
          <FormGroup className="even-margin">
            <ControlLabel />
            <Checkbox
              inline
              onChange={this.handleChange}
              className={this.props.isSelected}
              disabled={this.props.isOptionDisabled}
            >
              <img
                alt="filter-icon"
                src={fixedLink[this.state.imageIcon]}
                className="filter-button-icon"
              />
            </Checkbox>
          </FormGroup>
        ) : (
          <Spinner type="cylon" color="#1B2D5B" height="50px" width="50px" />
        )}
      </div>
    );
  }
  handleChange(e) {
    this.props.handleFilterSelect(this.props.filterItem);
  }
}
export default FilterButton;
