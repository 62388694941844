import Api from "../Utils/api.js";
/**
 * API Call to handle:
 * Get Product List
 */
const QuickConfiguratorHandler = (function () {
  return {
    getProductList: function (type) {
      return new Promise((resolve, reject) => {
        const route = `QuickConfigurator/Products?type=${type}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default QuickConfiguratorHandler;
