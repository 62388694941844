import React, { Component } from "react";

import "./disclaimer.css";
import { Disclaimers } from "../Utils/FetchConfigurableData";

/**
 * Component represents the disclaimers used in the website
 */
class Disclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.OrderSummaryDisclaimer = this.OrderSummaryDisclaimer.bind(this);
    this.EstimatedShippingTimeDisclaimer =
      this.EstimatedShippingTimeDisclaimer.bind(this);
    this.TrackMyOrderDisclaimer = this.TrackMyOrderDisclaimer.bind(this);
    this.SpecialOffersDisclaimer = this.SpecialOffersDisclaimer.bind(this);
    this.ProductConfiguratorESTDisclaimer =
      this.ProductConfiguratorESTDisclaimer.bind(this);
    this.DefaultDisclaimer = this.DefaultDisclaimer.bind(this);
  }

  render() {
    let formattedDateTime = "";
    let nextDateTime = "";
    if (this.props.last_updated) {
      let dateTime = new Date(this.props.last_updated);
      formattedDateTime =
        dateTime.toLocaleTimeString([], { timeStyle: "short" }) +
        " " +
        dateTime.getFullYear() +
        "-" +
        (dateTime.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        }) +
        "-" +
        dateTime.getDate().toLocaleString("en-US", { minimumIntegerDigits: 2 });
      let hours = dateTime.getHours() === 16 ? 16 : 2;
      dateTime.setHours(dateTime.getHours() + hours);
      nextDateTime =
        dateTime.toLocaleTimeString([], { timeStyle: "short" }) +
        " " +
        dateTime.getFullYear() +
        "-" +
        (dateTime.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        }) +
        "-" +
        dateTime.getDate().toLocaleString("en-US", { minimumIntegerDigits: 2 });
    }

    if (this.props.type && this.props.type === "ShippingTime") {
      return this.EstimatedShippingTimeDisclaimer(
        formattedDateTime,
        nextDateTime
      );
    } else if (this.props.type && this.props.type === "OrderSummary") {
      return this.OrderSummaryDisclaimer();
    } else if (this.props.type && this.props.type === "TrackMyOrder") {
      return this.TrackMyOrderDisclaimer(formattedDateTime, nextDateTime);
    } else if (this.props.type && this.props.type === "SpecialOffers") {
      return this.SpecialOffersDisclaimer(formattedDateTime, nextDateTime);
    } else if (this.props.type && this.props.type === "ProductConfigurator") {
      return this.ProductConfiguratorESTDisclaimer(formattedDateTime);
    } else {
      return this.DefaultDisclaimer(formattedDateTime, nextDateTime);
    }
  }

  OrderSummaryDisclaimer() {
    return (
      <div className="disclaimer" id="disclaimer-content">
        <div>
          <h5 className="title-disclaimer">{Disclaimers.DisclaimerTitle}</h5>
          <div>
            {Disclaimers.OrderSummary_DisclaimerTNCText}
            <br />
            <br />
            {Disclaimers.OrderSummary_DisclaimerMainText}
            <br />
            <br />
            {Disclaimers.OrderSummary_DisclaimerCallText}
            <br />
            {Disclaimers.OrderSummary_DisclaimerEmailText}
          </div>
        </div>
      </div>
    );
  }

  EstimatedShippingTimeDisclaimer(formattedDateTime, nextDateTime) {
    return (
      <div id="inventory-position-content">
        <div className="estimatedShipping">
          {this.props.last_updated !== null ? (
            <div>
              {Disclaimers.LeadTime_DisclaimerLastUpdateText}
              {formattedDateTime}
              {Disclaimers.LeadTime_DisclaimerNextUpdateText}
              {nextDateTime}.
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  TrackMyOrderDisclaimer(formattedDateTime, nextDateTime) {
    return (
      <div className="disclaimer" id="disclaimer-content">
        <div>
          <div>{Disclaimers.TrackMyOrder_DisclaimerTitle}</div>
          {this.props.last_updated !== null ? (
            <div>
              {Disclaimers.TrackMyOrder_DisclaimerLastUpdateText}
              {formattedDateTime}
              {Disclaimers.TrackMyOrder_DisclaimerNextUpdateText}
              {nextDateTime}.
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  SpecialOffersDisclaimer(formattedDateTime, nextDateTime) {
    return (
      <div className="disclaimer" id="disclaimer-content">
        <div style={{ textAlign: "left" }}>
          <div>
            {this.props.last_updated !== null ? (
              <div>
                {Disclaimers.UNUSED_ClearanceDisclaimerLastUpdateText}
                {formattedDateTime}
                {Disclaimers.UNUSED_ClearanceDisclaimerNextUpdateText}
                {nextDateTime}.
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  DefaultDisclaimer(formattedDateTime, nextDateTime) {
    return (
      <div className="disclaimer" id="disclaimer-content">
        <div>
          {this.props.last_updated !== null ? (
            <div>
              {Disclaimers.DefaultDisclaimerLastUpdateText}
              {formattedDateTime}
              {Disclaimers.DefaultDisclaimerNextUpdateText}
              {nextDateTime}.
            </div>
          ) : null}
        </div>
        <h5 className="title-disclaimer">{Disclaimers.DisclaimerTitle}</h5>
        <div>{Disclaimers.DisclaimerLiveUpdateText}</div>
      </div>
    );
  }

  ProductConfiguratorESTDisclaimer(formattedDateTime) {
    return (
      <div>
        {Disclaimers.ProductConfiguratorEST_DisclaimerText}
        {formattedDateTime}.
      </div>
    );
  }
}

export default Disclaimer;
