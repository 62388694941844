import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import { Row } from "react-bootstrap";

import "./confirmationPopup.css";
import { DisplayMessages } from "../Utils/FetchConfigurableData";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

/**
 * Component represents popup generated when a new user is created.
 */
class DistributorConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
    };

    this.handleConfirmation = this.handleConfirmation.bind(this);
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      type: newProps.type,
    });
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleConfirmation}
          styles={customStyles}
          
        >
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
                textAlign: "center",
              }}
              className="popup-blue-text"
            >
              Confirmed
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row>{this.DistributorUserMessage()}</Row>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <div className="text-center">
                <Button
                  bsStyle="primary"
                  onClick={this.handleConfirmation}
                  className="button-blue"
                >
                  Done
                </Button>
              </div>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  DistributorUserMessage() {
    if (this.state.type === "add") {
      return (
        <div className="text-center">
          User <strong>{this.props.popupName}</strong> created.
          <br />
          <br />
          <span>
            <strong>{this.props.email}</strong>
            {DisplayMessages.DistributorUser_EmailSent}
            <strong>{this.props.branchName}</strong>
            {DisplayMessages.DistributorUser_Portal}
          </span>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          {DisplayMessages.DistributorUser_Edited}
          <br />
        </div>
      );
    }
  }

  handleConfirmation() {
    this.props.handleConfirmation();
  }
}

export default DistributorConfirmationPopup;
