import Api from "../Utils/api.js";
import Plugin from "../Utils/plugin.js";

/**
 * API Calls to handle:
 * Get Sales rep names
 * Get trend results
 * Export results
 */
const ContentUsageTrendDataHandler = (function () {
  return {
    getSalesRepNames: function () {
      return new Promise((resolve, reject) => {
        let route = "users";
        Plugin.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTrendResults: function (searchFilter) {
      let startDate = new Date(searchFilter.startDate);
      let startDateUTCString = startDate.toISOString();
      let endDate = new Date(searchFilter.endDate);
      let endDateTime = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        23,
        59,
        59
      ); // set time to end of the day
      let endDateUTCString = endDateTime.toISOString();
      return new Promise((resolve, reject) => {
        let route = `UsageTrend/TrendList?activity=${searchFilter.activity}&userId=${searchFilter.userId}&startDate=${startDateUTCString}&endDate=${endDateUTCString}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    exportUsageTrend: function (filter) {
      return new Promise((resolve, reject) => {
        let route = "UsageTrend/Export";
        Api.post_doc(route, filter)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ContentUsageTrendDataHandler;
