import React, { Component } from "react";
import { Modal, Button, Row } from "react-bootstrap";

import "../OrderSummary/orderSummary.css";
import "./distributors.css";
import { DisplayMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the Cannot delete item popup
 */
class NoDeletePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemCount: this.props.itemCount,
      itemName: this.props.itemName,
      itemType: this.props.itemType,
      message: "",
    };

    this.setMessage = this.setMessage.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.itemName !== undefined &&
      (this.state.itemCount !== newProps.itemCount ||
        this.state.itemName !== newProps.itemName ||
        this.state.itemType !== newProps.itemType)
    ) {
      this.setState(
        {
          itemCount: newProps.itemCount,
          itemName: newProps.itemName,
          itemType: newProps.itemType,
        },
        this.setMessage
      );
    }
  }

  setMessage() {
    if (this.state.itemType === "TLD") {
      if (this.state.itemCount > 1) {
        this.setState((prevState) => ({
          message: DisplayMessages.NoDeletePopup_NoDeleteTldMany.replace(
            "number",
            prevState.itemCount
          ),
        }));
      } else {
        this.setState({
          message: DisplayMessages.NoDeletePopup_NoDeleteTldOne,
        });
      }
    } else if (this.state.itemCount > 1) {
      this.setState((prevState) => ({
        message: DisplayMessages.NoDeletePopup_NoDeleteBranchMany.replace(
          "number",
          prevState.itemCount
        ),
      }));
    } else {
      this.setState({
        message: DisplayMessages.NoDeletePopup_NoDeleteBranchOne,
      });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleOnClose}
        dialogClassName="deletepopup-modal"
      >
        <Modal.Header>
          <h3
            style={{ fontWeight: "bolder", textAlign: "center" }}
            className="popup-blue-text"
          >
            Alert
          </h3>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Row style={{ fontSize: "16px", textAlign: "center" }}>
            <span>
              <strong style={{ wordBreak: "break-word" }}>
                {this.state.itemName}
              </strong>
              {this.state.message}
            </span>
            <br />
          </Row>
          <Row style={{ fontSize: "16px", textAlign: "center" }}>
            This action cannot be done.
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <Button
              bsStyle="primary"
              className="button-del-blue"
              onClick={this.props.handleOnClose}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NoDeletePopup;
