import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import NotificationSystem from "react-notification-system";
import ReactLoading from "react-loading";
import mixpanel from "mixpanel-browser";

import LoginHandler from "./LoginHandler";
import "./login.css";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";

const minimumPasswordLength = 8;

/**
 * Component represents the reset password page
 */
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.getResetPasswordPage = this.getResetPasswordPage.bind(this);
    this.getInvalidUrlText = this.getInvalidUrlText.bind(this);
    this.validateToken = this.validateToken.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.getLoginUrl = this.getLoginUrl.bind(this);

    this.state = {
      newPassword: "",
      confirmPassword: "",
      validUrl: null,
      forgotPasswordUrl: "",
      reset: false,
      loginUrl: "",
      resetStatus: false,
    };
  }

  componentDidMount() {
    let hostname =
      window.location.hostname === "localhost"
        ? window.location.hostname + ":3000"
        : window.location.hostname;
    let url = window.location.protocol + "//" + hostname;
    this.setState({
      forgotPasswordUrl: url + "/ForgotPassword",
      loginUrl: url,
    });
    this.validateToken();
  }

  validateToken() {
    let userdata = {
      id: this.props.match.params.id,
      token: this.props.match.params.token,
    };
    LoginHandler.validateToken(userdata)
      .then((response) => {
        this.setState(
          {
            validUrl: response.success,
          },
          () => {
            if (response.success) {
              this.resetPassword();
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          validUrl: false,
        });
        this.trackResetButtonClick(ErrorMessages.ResetPassword_InvalidToken);

        this.addNotification(ErrorMessages.ResetPassword_InvalidToken, "error");
      });
  }
  resetPassword() {
    if (this.state.reset) {
      this.setState({
        reset: false,
      });
      let data = {
        id: this.props.match.params.id,
        password: this.state.newPassword.trim(),
      };

      LoginHandler.resetPassword(data)
        .then((response) => {
          if (response.success) {
            this.trackResetButtonClick("Password Reset Successfully");

            this.setState({
              resetStatus: true,
            });
          } else {
            this.trackResetButtonClick(ErrorMessages.ResetPassword_Failed);
            this.addNotification(ErrorMessages.ResetPassword_Failed, "error");
          }
        })
        .catch((error) => {
          this.trackResetButtonClick(ErrorMessages.ResetPassword_Failed);
          this.addNotification(ErrorMessages.ResetPassword_Failed, "error");
        });
    }
  }
  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div className="login-container">
        <div style={{ height: "45px" }}></div>
        {this.resetPageSection()}
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  resetPageSection() {
    if (this.state.validUrl === null) {
      return this.getProgress();
    } else if (this.state.resetStatus) {
      return this.getLoginUrl();
    } else if (this.state.validUrl) {
      return this.getResetPasswordPage();
    } else {
      return this.getInvalidUrlText();
    }
  }

  getInvalidUrlText() {
    return (
      <div className="links">
        <div style={{ height: "105px" }}></div>
        <Row>
          <h1>{DisplayMessages.ResetPassword_ApologyText}</h1>
        </Row>
        <Row>
          <h3>{DisplayMessages.ResetPassword_LinkExpired}</h3>
        </Row>
        <Row>
          <a
            href={this.state.forgotPasswordUrl}
            style={{
              color: "#074391",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            onClick={() => this.trackPageNavigation("Forgot Password Page")}
          >
            {DisplayMessages.ResetPassword_RetryText}
          </a>
        </Row>
      </div>
    );
  }
  getLoginUrl() {
    return (
      <div className="links">
        <div style={{ height: "105px" }}></div>
        <Row>
          <h3>{DisplayMessages.ResetPassword_Successful}</h3>
        </Row>
        <Row>
          <a
            href={this.state.loginUrl}
            style={{
              color: "#074391",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            onClick={() => this.trackPageNavigation("Login Page")}
          >
            {DisplayMessages.ResetPassword_ClickMe}
          </a>
        </Row>
      </div>
    );
  }
  getResetPasswordPage() {
    return (
      <div>
        <img
          src={fetchResourceData(fixedLink.appLogo)}
          alt="logo"
          height="175px"
          width="175px"
        />
        <br />
        <br />
        <div onKeyDown={this.handleKeyPress} role="presentation">
          <Row className="center-container">
            <Col md={4} sm={4} xs={4}>
              <h4 className="heading">Reset Password</h4>
            </Col>
            <br />
            <Col md={4} sm={4} xs={4} style={{ paddingBottom: "5px" }}>
              New Password<mark>*</mark>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <FormGroup controlId="new-password" className="password">
                <FormControl
                  type="password"
                  autoComplete="off"
                  value={this.state.newPassword}
                  placeholder="Password"
                  onChange={this.handleNewPasswordChange}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={4} xs={4} style={{ paddingBottom: "5px" }}>
              Confirm Password<mark>*</mark>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <FormGroup controlId="confirm-password" className="password">
                <FormControl
                  type="password"
                  autoComplete="off"
                  value={this.state.confirmPassword}
                  placeholder="Password"
                  onChange={this.handleConfirmPasswordChange}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <center>
                <Button
                  bsStyle="primary"
                  className="button-blue"
                  onClick={this.handleReset}
                >
                  Reset
                </Button>
              </center>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getProgress() {
    return (
      <div className="loading-icon">
        <div style={{ height: "105px" }}></div>
        <ReactLoading type="spokes" color="#797575" height={50} width={50} />
      </div>
    );
  }

  handleReset() {
    this.setState({
      reset: true,
    });

    if (
      this.state.newPassword === null ||
      this.state.newPassword.trim() === ""
    ) {
      this.trackResetButtonClick(ErrorMessages.ResetPassword_NewEmptyPassword);
      this.addNotification(
        ErrorMessages.ResetPassword_NewEmptyPassword,
        "error"
      );
      return;
    }
    if (
      this.state.confirmPassword === null ||
      this.state.confirmPassword.trim() === ""
    ) {
      this.trackResetButtonClick(
        ErrorMessages.ResetPassword_ConfirmEmptyPassword
      );

      this.addNotification(
        ErrorMessages.ResetPassword_ConfirmEmptyPassword,
        "error"
      );
      return;
    }
    if (this.state.confirmPassword.trim() !== this.state.newPassword.trim()) {
      this.trackResetButtonClick(ErrorMessages.ResetPassword_Mismatch);
      this.addNotification(ErrorMessages.ResetPassword_Mismatch, "error");
      return;
    }
    if (this.state.confirmPassword.trim().length < minimumPasswordLength) {
      this.trackResetButtonClick(ErrorMessages.ResetPassword_MinimumLength);
      this.addNotification(ErrorMessages.ResetPassword_MinimumLength, "error");
      return;
    }
    if (!this.checkForNumericsAlpha()) {
      this.trackResetButtonClick(ErrorMessages.ResetPassword_AlphaNumeric);
      this.addNotification(ErrorMessages.ResetPassword_AlphaNumeric, "error");
      return;
    }
    this.validateToken();
  }

  checkForNumericsAlpha() {
    let password = this.state.confirmPassword.trim();
    if (/\d/.test(password)) {
      if (/[A-Za-z]/.test(password)) {
        return true;
      }
    }
    return false;
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }
  handleNewPasswordChange(e) {
    this.setState({
      newPassword: e.target.value,
    });
  }
  handleConfirmPasswordChange(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }
  handleKeyPress(target) {
    if (target.keyCode === 13) {
      target.preventDefault();
      this.handleReset();
    }
  }

  // Mixpanel Tracking //

  trackResetButtonClick(message) {
    mixpanel.track("Reset Password", {
      Action: "Button Click",
      Effect: "Password reset attempted",
      "Reset Status": message,
    });
  }

  trackPageNavigation(page) {
    mixpanel.track_pageview({
      Page: page,
    });
  }
}
export default ResetPassword;
