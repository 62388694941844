import axios from "axios";

import Plugin from "../Utils/plugin.js";
import { createCancelTokenHandler } from "../Utils/axiosUtils";
/**
 * API Calls to handle:
 * Get product list to display
 * Get search results
 */
const ProductListDataHandler = (function () {
  return {
    getProductListToDisplay: function (filters) {
      return new Promise((resolve, reject) => {
        let route = "products/filter/";
        route += filters.spaceId ? `${filters.spaceId}` : ``;
        route += filters.applicationId ? `,${filters.applicationId}` : ``;
        route += filters.page ? `?page=${filters.page}` : ``;
        route += filters.offset ? `&offset=${filters.offset}` : ``;
        Plugin.get(route)
          .then((response) => {
            let resolveObj = {};
            resolveObj.total = response.data.data.total;
            resolveObj.productList = response.data.data.products.map(
              (item) => item
            );
            resolve(resolveObj);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSearchResult: function (filters) {
      return new Promise((resolve, reject) => {
        const route = `searchproducts/${filters.searchString}?page=${filters.page}&offset=${filters.offset}`;
        axios({
          method: "GET",
          url: Plugin.getBaseURL() + route,
          headers: Plugin.getDirectHeaders(),
          cancelToken:
            cancelTokenHandlerObject[
              this.getSearchResult.name
            ].handleRequestCancellation().token,
        })
          .then((response) => {
            let resolveObj = {};
            resolveObj.total = response.data.data.total;
            resolveObj.productList = response.data.data.products.map(
              (item) => item
            );
            resolve(resolveObj);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

const cancelTokenHandlerObject = createCancelTokenHandler(
  ProductListDataHandler
);

export default ProductListDataHandler;
