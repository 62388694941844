import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Modal from "react-bootstrap/lib/Modal";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import ManageProductConfiguratorHandler from "./ManageProductConfiguratorHandler.js";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData.js";
import ContentLoading from "../ContentLoading/ContentLoading.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

/**
 * Component represents the Manage Product Configurator tab in Contents page
 */
class ManageProductConfigurator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      description: "",
      link: "",
      docType: [
        Miscellaneous.AddersSheet,
        Miscellaneous.NamingConvention,
        Miscellaneous.CustomerMapping,
      ],
      attachments: [],
      showAttachmentListPopup: false,
      showDisplayOrderPopup: false,
      selectedMode: "Upload",
      selectedId: -1,

      selectedDocType: Miscellaneous.AddersSheet,
      files: [],
      fieldsUpdated: false,
      modalOpen: false,
    };
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleDocTypeChange = this.handleDocTypeChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.upload = this.upload.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  activeMode() {
    if (this.state.selectedDocType !== undefined) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  render() {
    return (
      <div className="manage-product-config-data-container">
        <Modal
          show={this.state.fieldsUpdated && this.state.modalOpen}
          onHide={this.handleOnClose}
        >
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              Update {this.state.selectedDocType}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>
                {DisplayMessages.ManageProductConfig_NamingConventionText}
              </ControlLabel>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={this.handleOnClose}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col md={3}>
            <form>
              <Row>
                <h4>Select a document type</h4>
                <Select
                  maxMenuHeight={190}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  value={{
                    value: this.state.selectedDocType,
                    label:
                      this.state.selectedDocType !== undefined
                        ? this.state.selectedDocType
                        : "Select a document type",
                  }}
                  aria-label="Document Type"
                  styles={customStyles}
                  onChange={this.handleDocTypeChange}
                  options={this.state.docType.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "lightgray",
                    },
                  })}
                />
              </Row>
              <Row>
                <Col md={8} sm={12} xs={12} style={{ padding: 0 }}>
                  <br />
                  <h4 className={this.activeMode()}>
                    Choose a file to upload<mark>*</mark>
                  </h4>
                  <FormGroup className={this.activeMode()}>
                    <FormControl
                      id="formControlsFile"
                      type="file"
                      label="File"
                      help=""
                      onChange={this.handleFileChange}
                      aria-label="Choose a File to upload"
                    />
                    <br />
                  </FormGroup>
                </Col>
                <Col md={4} sm={12} xs={12}>
                  {this.state.isLoaded
                    ? this.getProgressOrMessagePanel()
                    : null}
                </Col>
              </Row>
              <Row>
                <ButtonToolbar>
                  <Button
                    bsStyle="primary"
                    onClick={this.upload}
                    className={this.activeMode() + " button-blue"}
                  >
                    Upload
                  </Button>
                  <Button
                    bsStyle="primary"
                    onClick={this.cancel}
                    className="button-blue"
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    );
  }

  handleOnClose() {
    this.setState({ modalOpen: false });
  }

  handleDocTypeChange(e) {
    this.setState({
      selectedDocType: e.value,
      files: undefined,
    });
    document.getElementById("formControlsFile").value = "";
  }

  handleFileChange(e) {
    let files = e.target.files;
    this.setState({
      files: files,
    });
  }

  upload() {
    this.setState({
      isLoaded: true,
    });

    this.validateUpload()
      .then((form) => {
        switch (this.state.selectedDocType) {
          case Miscellaneous.AddersSheet:
            this.addersSheetDocumentHandler(form);
            break;
          case Miscellaneous.NamingConvention:
            this.namingConventionHandler(form);
            break;
          case Miscellaneous.CustomerMapping:
            this.customerMappingHandler(form);
            break;
          default:
            this.addersSheetDocumentHandler(form);
        }
      })
      .catch((error) => {
        this.trackUploadButtonFailedClick(
          ErrorMessages.ManageProductConfig_Validate
        );
        this.props.addNotification(
          ErrorMessages.ManageProductConfig_Validate,
          "error"
        );
      });
  }

  addersSheetDocumentHandler(form) {
    ManageProductConfiguratorHandler.postAddersDocument(form)
      .then((response) => {
        this.setState({
          isLoaded: false,
        });
        if (response.success) {
          this.trackUploadButtonClick();

          this.props.addNotification(response.message, "info");
          this.setState({ fieldsUpdated: false });
        } else {
          this.trackUploadButtonFailedClick(response.message);
          this.props.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: false,
        });
        this.trackUploadButtonFailedClick(
          ErrorMessages.ManageProductConfig_AddersSheet
        );

        this.props.addNotification(
          ErrorMessages.ManageProductConfig_AddersSheet,
          "error"
        );
      });
  }

  namingConventionHandler(form) {
    ManageProductConfiguratorHandler.postNamingDocument(form)
      .then((response) => {
        this.setState({
          isLoaded: false,
        });
        if (response.success) {
          this.trackUploadButtonClick();

          this.setState({ fieldsUpdated: true, modalOpen: true });
        } else {
          this.props.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: false,
        });
        this.trackUploadButtonFailedClick(
          ErrorMessages.ManageProductConfig_NamingConventionDocument
        );
        this.props.addNotification(
          ErrorMessages.ManageProductConfig_NamingConventionDocument,
          "error"
        );
      });
  }

  customerMappingHandler(form) {
    ManageProductConfiguratorHandler.postCustomerMapping(form)
      .then((response) => {
        this.setState({
          isLoaded: false,
        });
        if (response.success) {
          this.trackUploadButtonClick();

          this.props.addNotification(response.message, "info");
        } else {
          this.trackUploadButtonFailedClick(response.message);
          this.props.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: false,
        });
        this.trackUploadButtonFailedClick(
          ErrorMessages.ManageProductConfig_CustomerMapping
        );
        this.props.addNotification(
          ErrorMessages.ManageProductConfig_CustomerMapping,
          "error"
        );
      });
  }

  getProgressOrMessagePanel() {
    return <ContentLoading message={""} size={30} />;
  }

  validateUpload() {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (this.state.files) {
        for (let element of this.state.files) {
          let fileSize = this.state.files[0].size;
          if (fileSize > 26214400) {
            reject(new Error("Larger file size"));
          }
          form.append("UploadedFile", element);
        }
      }

      resolve(form);
    });
  }

  cancel() {
    this.trackCancelButtonClick();
    document.getElementById("formControlsFile").value = "";
    this.setState({
      selectedDocType: Miscellaneous.AddersSheet,
      files: undefined,
    });
  }

  // Mixpanel Tracking //

  trackUploadButtonClick() {
    mixpanel.track("Upload Product Configuration Document", {
      Action: "Button Click",
      Effect: "Document is uploaded",
      "Document Type": this.state.selectedDocType,
    });
  }

  trackUploadButtonFailedClick(message) {
    mixpanel.track("Upload Product Configuration Document", {
      Action: "Button Click",
      Effect: "Document is not uploaded",
      "Document Type": this.state.selectedDocType,
      Message: message,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Cancel Product Configuration Document", {
      Action: "Button Click",
      Effect: "Navigate back to uploads page",
    });
  }
}
export default ManageProductConfigurator;
