import React, { Component } from "react";
import Panel from "react-bootstrap/lib/Panel";
import mixpanel from "mixpanel-browser";

import "./tableau.css";
import TableauDataHandler from "./TableauDataHandler.js";
import TableauPopup from "./TableauPopup";
import OkPopUp from "../OkPopUp/OkPopUp";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";

const { detect } = require("detect-browser");
const browser = detect();

/**
 * Component represents the Real-Time Reports page
 */
class Tableau extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tableau_links: [],
      isTableauLinksLoaded: false,
      current_tableau_link: "",
      selected_item_id: "",
      show_alert_popup: false,
    };
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleClearClose = this.handleClearClose.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.getTableauLinks = this.getTableauLinks.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.getTableauLinks();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="container-div loading-fade">
        <div>
          <h2 className="tableau-header">{DisplayMessages.Tableau_Header}</h2>
          <hr />
        </div>
        <div>
          <Panel id="tableau-panel" defaultExpanded>
            <div
              role="menu"
              id="collapsible-panel--heading"
              className="panel-heading"
            >
              <Panel.Heading role="menuitem" aria-labelledby="dashboard">
                <Panel.Title>
                  {!this.state.tableau_links ||
                  this.state.tableau_links.length <= 0
                    ? ""
                    : "Choose a dashboard to display"}
                </Panel.Title>
              </Panel.Heading>
            </div>
            <Panel.Body>{this.getTableauDashboards()}</Panel.Body>
          </Panel>
        </div>
        <OkPopUp
          show={this.state.show_alert_popup}
          handleOnClose={this.handleAlertClose}
          title={"Alert!"}
          text={DisplayMessages.Tableau_CookiesText}
        />
        <TableauPopup
          show={this.state.selected_item_id}
          handleClearClose={this.handleClearClose}
          tableau_link={this.state.current_tableau_link}
        />
      </div>
    );
  }

  handleClearClose() {
    this.setState({
      selected_item_id: "",
      current_tableau_link: "",
    });
  }

  handleAlertClose() {
    this.trackCookiesAlertCloseClick();
    this.setState({
      show_alert_popup: false,
    });
  }

  getTableauDashboards() {
    if (!this.state.tableau_links || this.state.tableau_links.length <= 0) {
      let message = !this.state.isTableauLinksLoaded
        ? "Loading..."
        : "No dashboards to display!";
      return <h4 className="text-center">{message}</h4>;
    } else {
      return (
        <div className="dashboard-list">
          {this.state.tableau_links.map((item) => {
            return (
              <div
                key={item.Id}
                className="dashboard-item"
                onClick={() => this.getDashBoard(item)}
              >
                {item.RabLink}
              </div>
            );
          })}
        </div>
      );
    }
  }

  getTableauLinks() {
    let show_alert_popup = false;
    if (browser.name === "safari") {
      show_alert_popup = true;
    }

    TableauDataHandler.getTableauLinks()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            tableau_links: response.data,
            isTableauLinksLoaded: true,
            show_alert_popup: show_alert_popup,
          });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            isTableauLinksLoaded: true,
          });
        }
        this.addNotification(ErrorMessages.Tableau_TableauLinks, "error");
      });
  }

  getDashBoard(item) {
    this.trackTableauDashboardClick(item);
    this.setState({ selected_item_id: item.RabLink });
    let data = { rabLink: item.RabLink, id: item.Id, type: item.Type };
    TableauDataHandler.getTableauDashboard(data)
      .then((response) => {
        if (response.success) {
          this.setState({
            current_tableau_link:
              Miscellaneous.TrustedTableauLink +
              response.data.token +
              "/" +
              response.data.dashboard,
          });
        } else {
          this.setState({
            current_tableau_link: Miscellaneous.DefaultTableauLink,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.Tableau_TableauDashboard, "error");
      });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  // Mixpanel Tracking //

  trackTableauDashboardClick(item) {
    mixpanel.track("Tableau Dashboard", {
      Action: "Link Click",
      Effect: `Tableau Dashboard opened`,
      "Tableau Link": item.RabLink,
      "Tableau ID": item.Id,
      "Tableau Type": item.Type,
    });
  }

  trackCookiesAlertCloseClick() {
    mixpanel.track("Cookies Alert Popup Close", {
      Action: "Button Click",
      Effect: `Cookies Alert Popup closed`,
    });
  }
}
export default Tableau;
