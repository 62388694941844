import React, { Component } from "react";
import ReactLoading from "react-loading";

export default class ContentLoading extends Component {
  render() {
    return (
      <div className="loading-icon">
        <h4>{this.props.message}</h4>
        <ReactLoading
          type="spokes"
          color="#797575"
          height={this.props.size}
          width={this.props.size}
        />
      </div>
    );
  }
}
