import React, { Component } from "react";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import Api from "../Utils/api";
import HomePageAttachmentsPopup from "./HomePageAttachmentsPopup.js";
import ChangeDisplayOrderPopup from "./ChangeDisplayOrderPopup.js";
import "./manageHomePage.css";
import { ErrorMessages, Miscellaneous } from "../Utils/FetchConfigurableData";
import ManageHomePageDataHandler from "./ManageHomePageDataHandler";

const resourceTypeArray = {
  general: 1,
  noPricing: 2,
  tier1: 3,
  preferred: 4,
  distributorNet: 5,
  salesrepOnly: 6,
};
const docType = ["Banner", "Supporting Document"];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

/**
 * Component represents the Manage Home Page tab in Content page
 */
class ManageHomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDocType: "Banner",
      title: "",
      description: "",
      link: "",
      attachments: [],
      showAttachmentListPopup: false,
      showDisplayOrderPopup: false,
      selectedMode: "Upload",
      selectedId: -1,
      resourceType: 1,
      resourceAccess: this.props.resourceAccess,
      allowResourceButtons: false,
    };
    this.handleDocTypeChange = this.handleDocTypeChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.upload = this.upload.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.validateUpload = this.validateUpload.bind(this);
    this.showBannerControl = this.showBannerControl.bind(this);
    this.showDocumentControl = this.showDocumentControl.bind(this);
    this.viewDocument = this.viewDocument.bind(this);
    this.changeDisplayOrder = this.changeDisplayOrder.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.handleOnAttachmentsListPopupClose =
      this.handleOnAttachmentsListPopupClose.bind(this);
    this.handleOnDisplayOrderPopupClose =
      this.handleOnDisplayOrderPopupClose.bind(this);
    this.handleEditAttachment = this.handleEditAttachment.bind(this);
    this.activeMode = this.activeMode.bind(this);
    this.ResourceTypeChanged = this.ResourceTypeChanged.bind(this);
    this.ResourceTypeRadioButtons = this.ResourceTypeRadioButtons.bind(this);
  }

  handleDocTypeChange(e) {
    this.setState((prevState) => ({
      selectedDocType: e.value,
      allowResourceButtons: e.value === "Banner" ? false : true,
      resourceType: e.value === "Banner" ? 1 : prevState.resourceType,
      title: "",
      link: "",
      description: "",
    }));
    document.getElementById("bannerOrSupportDocFile").value = "";
  }

  handleTitleChange(e) {
    this.setState({ title: e.target.value });
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleLinkChange(e) {
    this.setState({ link: e.target.value });
  }

  handleDeleteAttachment() {
    this.viewDocument();
  }

  handleEditAttachment(item) {
    this.setState({
      showAttachmentListPopup: false,
      title: item.title,
      description: item.description,
      link: item.link,
      selectedId: item.id,
      selectedMode: "Edit",
      resourceType: parseInt(item.resource_type, 10),
    });
  }

  handleOnAttachmentsListPopupClose() {
    this.setState({
      showAttachmentListPopup: false,
    });
  }

  handleOnDisplayOrderPopupClose() {
    this.setState({
      showDisplayOrderPopup: false,
    });
  }

  handleFileChange(e) {
    let files = e.target.files;
    this.setState({
      files: files,
    });
  }

  render() {
    return (
      <div className="manage-home-page-container">
        <div
          className={
            this.state.selectedMode === "Edit"
              ? "show-component"
              : "hide-component"
          }
        >
          <Button
            bsStyle="primary"
            id="back-to-default"
            className="button-blue"
            onClick={() => {
              this.trackBackButtonClick();
              this.cancel();
            }}
          >
            <span className="glyphicon glyphicon-arrow-left" />
          </Button>
        </div>
        <Row>
          <Col md={3}>
            <h4>Select a document type</h4>
            <Select
              maxMenuHeight={190}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              value={{
                value: this.state.selectedDocType,
                label: this.state.selectedDocType,
              }}
              aria-label="Document Type"
              styles={customStyles}
              onChange={this.handleDocTypeChange}
              options={docType.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
            <br />
            <Row>
              <h4>
                Title<mark>*</mark>
              </h4>
              <FormControl
                id={this.state.title}
                type="text"
                value={this.state.title}
                placeholder="Enter Title"
                onChange={this.handleTitleChange}
              />
              <br />
            </Row>
            <Row className={this.showBannerControl()}>
              <h4>
                Description<mark>*</mark>
              </h4>
              <FormControl
                className={this.showBannerControl()}
                id={this.state.description}
                type="text"
                value={this.state.description}
                placeholder="Enter Description"
                onChange={this.handleDescriptionChange}
              />
              <br />
            </Row>
            <Row className={this.showBannerControl()}>
              <h4>
                Link<mark>*</mark>
              </h4>
              <FormControl
                className={this.showBannerControl()}
                id={this.state.link}
                type="text"
                value={this.state.link}
                placeholder="Enter Link Address"
                onChange={this.handleLinkChange}
              />
              <br />
            </Row>
            {this.ResourceTypeRadioButtons()}
            <Row>
              <h4 className={this.activeMode()}>
                Choose a file to upload<mark>*</mark>
              </h4>
              <FormGroup className={this.activeMode()}>
                <FormControl
                  id="bannerOrSupportDocFile"
                  type="file"
                  label="File"
                  help=""
                  onChange={this.handleFileChange}
                  aria-label="Choose File"
                />
              </FormGroup>
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: "15px" }}>
          <ButtonToolbar>
            <Button
              bsStyle="primary"
              onClick={this.upload}
              className={this.activeMode() + " button-blue"}
            >
              Upload
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.upload}
              className={
                (this.state.selectedMode === "Edit"
                  ? "show-component"
                  : "hide-component") + " button-blue"
              }
            >
              Update
            </Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.trackCancelButtonClick();
                this.cancel();
              }}
              className={this.activeMode() + " button-blue"}
            >
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.trackViewButtonClick();
                this.viewDocument();
              }}
              className={this.activeMode() + " button-blue"}
            >
              View
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.changeDisplayOrder}
              className={this.activeMode() + " button-blue"}
            >
              Change Display Order
            </Button>
          </ButtonToolbar>
        </Row>
        <HomePageAttachmentsPopup
          show={this.state.showAttachmentListPopup}
          documentName={this.state.selectedDocType}
          handleOnClose={this.handleOnAttachmentsListPopupClose}
          attachments={this.state.attachments}
          addNotification={this.props.addNotification}
          handleDeleteAttachment={this.handleDeleteAttachment}
          modelTitle={this.state.selectedDocType}
          edit={this.handleEditAttachment}
          activeMode={this.activeMode}
        />
        <ChangeDisplayOrderPopup
          show={this.state.showDisplayOrderPopup}
          attachments={this.state.attachments}
          handleOnClose={this.handleOnDisplayOrderPopupClose}
          modelTitle={this.state.selectedDocType}
        />
      </div>
    );
  }

  ResourceTypeRadioButtons() {
    if (this.state.allowResourceButtons) {
      return (
        <div>
          <h4>
            Choose resource type<mark>*</mark>
          </h4>
          <div style={{ fontSize: "15px" }}>
            <Row>
              <Col md={4}>
                <label>
                  <input
                    type="radio"
                    value="general"
                    name="resourceType"
                    checked={this.state.resourceType === 1}
                    onChange={this.ResourceTypeChanged}
                    aria-label="Resource Type"
                  />{" "}
                  <strong>General Resource</strong>
                </label>
              </Col>
              <Col md={4}>
                <label>
                  <input
                    type="radio"
                    value="tier1"
                    name="resourceType"
                    checked={this.state.resourceType === 3}
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Tier1 Resource</strong>
                </label>
              </Col>
              <Col md={4} hidden={!Miscellaneous.OtherTiersEnabled}>
                <label>
                  <input
                    type="radio"
                    value="distributorNet"
                    name="resourceType"
                    checked={this.state.resourceType === 5}
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Distributor Net Resource</strong>
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={4} hidden={!Miscellaneous.OtherTiersEnabled}>
                <label>
                  <input
                    type="radio"
                    value="preferred"
                    name="resourceType"
                    checked={this.state.resourceType === 4}
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Preferred Resource</strong>
                </label>
              </Col>
              <Col md={4}>
                <label>
                  <input
                    type="radio"
                    value="noPricing"
                    name="resourceType"
                    checked={this.state.resourceType === 2}
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>No-Pricing Resource</strong>
                </label>
              </Col>
              <Col md={4}>
                <label>
                  <input
                    type="radio"
                    value="salesrepOnly"
                    name="resourceType"
                    checked={this.state.resourceType === 6}
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Salesrep Only Resource</strong>
                </label>
              </Col>
            </Row>
          </div>
          <br />
        </div>
      );
    } else {
      return null;
    }
  }

  ResourceTypeChanged(e) {
    let resValue = e.target.value;
    let resType = resourceTypeArray[resValue];
    this.setState({
      resourceType: resType,
    });
  }

  cancel() {
    this.setState({
      title: "",
      description: "",
      link: "",
      files: undefined,
      selectedMode: "Upload",
      selectedId: -1,
      resourceType: 1,
    });
    document.getElementById("bannerOrSupportDocFile").value = "";
  }

  showBannerControl() {
    if (
      this.state.selectedDocType !== undefined &&
      this.state.selectedDocType !== "Banner"
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  activeMode() {
    if (
      this.state.selectedDocType !== undefined &&
      this.state.selectedMode !== "Edit"
    ) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  showDocumentControl() {
    if (
      this.state.selectedDocType !== undefined &&
      this.state.selectedDocType !== "Supporting Document"
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  upload() {
    this.validateUpload()
      .then((form) => {
        if (this.state.selectedDocType === "Banner") {
          this.trackBannerUploadButtonClick();
        } else {
          this.trackSupportingDocumentUploadButtonClick();
        }

        ManageHomePageDataHandler.uploadDocument(form)
          .then((response) => {
            if (response.success) {
              this.props.addNotification(response.message, "info");
              this.cancel();
            } else {
              this.props.addNotification(response.message, "error");
            }
          })
          .catch((error) => {
            this.props.addNotification(ErrorMessages.Content_Upload, "error");
          });
      })
      .catch((error) => {
        this.props.addNotification(error.message, "error");
      });
  }

  validateUpload() {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append("Id", this.state.selectedId);
      if (this.state.selectedDocType) {
        form.append("DocumentType", this.state.selectedDocType);
        form.append("ResourceType", this.state.resourceType);
      }
      if (this.state.title.trim() === "" || this.state.title == null) {
        reject(new Error("Enter a title"));
      } else {
        form.append("Title", this.state.title);
      }
      if (this.state.files && this.state.selectedMode !== "Edit") {
        for (let element of this.state.files) {
          let fileSize = this.state.files[0].size;
          if (fileSize > 26214400) {
            reject(new Error("Larger file size"));
          }
          form.append("UploadedFile", element);
        }
      } else {
        if (this.state.selectedMode !== "Edit")
          reject(new Error("Select a file"));
      }
      if (this.state.selectedDocType === "Banner") {
        if (this.state.description === "" || this.state.description == null) {
          reject(new Error("Enter a description"));
        } else {
          form.append("Description", this.state.description);
        }

        if (this.state.link === "" || this.state.link == null) {
          reject(new Error("Enter a link"));
        } else {
          form.append("Link", this.state.link);
        }
      }
      resolve(form);
    });
  }

  viewDocument() {
    let documentType = this.state.selectedDocType;
    ManageHomePageDataHandler.homePageDocuments(documentType)
      .then((response) => {
        if (response.success) {
          let attachments = response.data.map((item) => {
            item.filePath = Api.getBaseURL() + "/" + item.filePath;
            return item;
          });
          this.setState({
            attachments: attachments,
            showAttachmentListPopup: true,
          });
        } else {
          this.props.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.ManageHomePage_ViewDocument,
          "error"
        );
      });
  }

  changeDisplayOrder() {
    this.trackChangeDisplayOrderButtonClick();
    let documentType = this.state.selectedDocType;
    ManageHomePageDataHandler.homePageDocuments(documentType)
      .then((response) => {
        if (response.success) {
          let attachments = response.data.map((item) => {
            item.filePath =
              Api.getBaseURL() + "/" + item.filePath.replace("\\", "/");
            return item;
          });
          this.setState({
            attachments: attachments,
            showDisplayOrderPopup: true,
          });
        } else {
          this.props.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.ManageHomePage_ViewDocument);
      });
  }

  // Mixpanel Tracking //

  trackBannerUploadButtonClick() {
    mixpanel.track("New Banner Upload", {
      Action: "Button Click",
      Effect: "Chosen banner will be uploaded",
      Title: this.state.title,
      Description: this.state.description,
      Link: this.state.link,
    });
  }

  trackSupportingDocumentUploadButtonClick() {
    mixpanel.track("New Supporting Document Upload", {
      Action: "Button Click",
      Effect: "Chosen supporting document will be uploaded",
      Title: this.state.title,
      "Resource Type": this.state.resourceType,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Clear file upload", {
      Action: "Button Click",
      Effect: "Clear new file filters",
    });
  }

  trackViewButtonClick() {
    mixpanel.track("View Attachments", {
      Action: "Button Click",
      Effect:
        "Display popup containing all uploaded banners or supporting documents",
      "Document Type": this.state.selectedDocType,
    });
  }

  trackBackButtonClick() {
    mixpanel.track("Back To Upload", {
      Action: "Button Click",
      Effect: "Navigate back to uploads page",
    });
  }

  trackChangeDisplayOrderButtonClick() {
    mixpanel.track("Change Display Order", {
      Action: "Button Click",
      Effect:
        "Display popup containing all uploaded banners or supporting documents",
      "Document Type": this.state.selectedDocType,
    });
  }
}
export default ManageHomePage;
