import Plugin from "../Utils/plugin.js";
/**
 * API Calls to handle:
 * Fetch products by product name
 */
const ProductListPopupHandler = (function () {
  return {
    fetchProductsByProductName: function (searchFilter) {
      return new Promise((resolve, reject) => {
        let route = `searchproducts/${searchFilter.searchString}?page=${searchFilter.page}&offset=${searchFilter.offset}`;
        Plugin.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ProductListPopupHandler;
