import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import { ButtonToolbar, Col, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-table/react-table.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./UserManagement.css";
import AddUser from "./AddUser";
import UserManagementHandler from "./UserManagementHandler";
import EditUser from "./EditUser";
import ExportDataPopup from "../ExportDataPopup/ExportDataPopup";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading";
import DownloadProgress from "../DownloadPopup/DownloadProgress";

const tickOk = fetchResourceData(fixedLink.positiveIcon);
const dashMinus = fetchResourceData(fixedLink.negativeIcon);

/**
 * Component represents the user management section
 */
class UserManagement extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      addUserPopup: false,
      editUserPopup: false,
      userList: [],
      searchedUserList: [],
      rowdata: [],
      deleteUserConfirmationPopup: false,
      exportUsersPopup: false,
      searchString: "",
      searchValid: false,
      currentUser: "",
      isLoading: true,
      isTableLoading: true,
      downloadLoader: false,
    };
    this.timer = null;

    this._notificationSystem = null;
    this.addUser = this.addUser.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUserConfirmed = this.deleteUserConfirmed.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleUserSearchStringChange =
      this.handleUserSearchStringChange.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.handleExportCSV = this.handleExportCSV.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.resetUserTable = this.resetUserTable.bind(this);
    this.closeAllPopup = this.closeAllPopup.bind(this);
    this.handleExportClose = this.handleExportClose.bind(this);
    this.resetAfterPopupClosed = this.resetAfterPopupClosed.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    UserManagementHandler.getUserData()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            userList: response.data,
            searchedUserList: response.data,
            currentUser: sessionStorage.getItem("username"),
            searchString: "",
            searchValid: true,
            isLoading: false,
            isTableLoading: false,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UserManagement_UserData, "error");
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  paginationOptionsBuilder() {
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.trackPagination(page);
        onPageChange(page);
      };

      return (
        <li className="page-item">
          {/* eslint-disable-next-line */}
          <a href="#" key={page} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      pageButtonRenderer,
      sizePerPageList: [
        {
          text: "25",
          value: 25,
        },
      ],
    };

    return options;
  }

  refreshPage() {
    UserManagementHandler.getUserData()
      .then((response) => {
        if (response.success) {
          this.setState({
            userList: response.data,
            searchedUserList: response.data,
            searchString: "",
            searchValid: true,
            isLoading: false,
            isTableLoading: false,
          });
        } else {
          this.setState({
            searchValid: false,
            isLoading: false,
            isTableLoading: false,
          });
        }
        this.closeAllPopup();
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UserManagement_UserData, "error");
      });
  }

  searchUsers() {
    UserManagementHandler.searchUserData(
      encodeURIComponent(this.state.searchString)
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            searchedUserList: response.data,
            searchValid: true,
            isLoading: false,
            isTableLoading: false,
          });
        } else {
          this.setState({
            searchValid: false,
            isLoading: false,
            isTableLoading: false,
          });
        }
        this.closeAllPopup();
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.UserManagement_SearchUserData,
          "error"
        );
      });
  }

  render() {
    return this.state.isLoading
      ? this.usersLoadingSection()
      : this.userManagementSection();
  }

  usersLoadingSection() {
    return (
      <div className="user-management-container">
        <ContentLoading
          message={DisplayMessages.UserManagement_Loading}
          size={50}
        />
        <br />
      </div>
    );
  }

  userManagementSection() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
        },
      },
    };

    return (
      <div className="user-management-container">
        {this.userActionSection()}
        <br />
        {this.userTableSection()}
        <AddUser
          show={this.state.addUserPopup}
          resetUserTable={this.resetAfterPopupClosed}
          handleOnClose={this.handleOnClose}
        />
        <EditUser
          show={this.state.editUserPopup}
          rowdata={this.state.rowdata}
          resetUserTable={this.resetUserTable}
          handleOnClose={this.handleOnClose}
        />
        <DeleteConfirmationPopup
          show={this.state.deleteUserConfirmationPopup}
          item={
            this.state.rowdata.firstName + " " + this.state.rowdata.lastName
          }
          itemType={"User"}
          handleConfirmation={this.deleteUserConfirmed}
          handleCancel={this.handleClosePopup}
        />
        <ExportDataPopup
          show={this.state.exportUsersPopup}
          title="Export User Details"
          handleOnClose={this.handleExportClose}
          handleOnSubmit={this.handleExportSubmit}
          addNotification={this.addNotification}
        />
        <DownloadProgress show={this.state.downloadLoader} />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  userActionSection() {
    return (
      <Row style={{ display: "flex", alignItems: "flex-end" }}>
        <Col md={7} sm={12} xs={12} className="add-export">
          <ButtonToolbar>
            <Button
              bsStyle="primary"
              className="button-blue add-user-button"
              onClick={this.addUser}
            >
              Add New User
            </Button>
            <Button
              bsStyle="primary"
              className="export-button"
              onClick={this.handleExportCSV}
            >
              Export as .csv
            </Button>
          </ButtonToolbar>
        </Col>
        <Col
          md={5}
          sm={12}
          xs={12}
          className="searchbox"
          style={{ float: "right", padding: "0px", marginTop: "10px" }}
        >
          <Col className="no-padding-user">
            <FormControl
              type="text"
              autoComplete="off"
              placeholder="Search by user's first name"
              spellCheck="false"
              value={this.state.searchString}
              onChange={this.handleUserSearchStringChange}
            />
          </Col>
        </Col>
      </Row>
    );
  }

  userTableSection() {
    return this.state.isTableLoading
      ? this.usersLoadingSection()
      : this.userTableDisplaySection();
  }

  userTableDisplaySection() {
    if (!this.state.searchValid) {
      return (
        <center>
          <h4>No Users Found!</h4>
        </center>
      );
    } else {
      return (
        <Row className="loading-fade">
          <BootstrapTable
            keyField="userName"
            data={this.state.searchedUserList}
            columns={this.getColumns()}
            pagination={paginationFactory(this.paginationOptionsBuilder())}
          />
        </Row>
      );
    }
  }

  closeAllPopup() {
    this.setState({
      deleteUserConfirmationPopup: false,
      exportUsersPopup: false,
      editUserPopup: false,
      addUserPopup: false,
      rowdata: [],
    });
  }

  handleUserSearchStringChange(e) {
    let searchString = e.target.value.toString().trim();
    this.setState(
      {
        searchString: searchString,
        isTableLoading: true,
      },
      () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (searchString !== "") {
            this.trackSearchUserFilter(searchString);
            this.searchUsers();
          } else {
            this.refreshPage();
          }
        }, 1000);
      }
    );
  }

  getColumns() {
    let colStyle = {
      border: "1px solid #CACACA",
      textAlign: "left",
      padding: "10px",
      wordBreak: "break-all",
      verticalAlign: "middle",
    };
    let centerColStyle = {
      border: "1px solid #CACACA",
      textAlign: "center",
      padding: "10px",
      wordBreak: "break-all",
      verticalAlign: "middle",
    };
    const columns = [
      {
        dataField: "userName",
        text: "Email/Username",
        sort: true,
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "250px",
        },
      },
      {
        dataField: "firstName",
        text: "First Name",
        sort: true,
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "lastName",
        text: "Last Name",
        sort: true,
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "jobTitle",
        text: "Title",
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "tld",
        text: "TLD",
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "130px",
        },
      },
      {
        dataField: "branch",
        text: "Branch",
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "250px",
        },
      },
      {
        dataField: "lastLogin",
        text: "Last Login",
        sort: true,
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
        formatter: (cell) => {
          return this.lastLoginLayout(cell);
        },
      },
      {
        dataField: "phoneNumber",
        text: "Phone Number",
        style: colStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
      },
      {
        dataField: "pricingAccess",
        text: "Pricing Access",
        style: centerColStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "orderTracking",
        text: "Order Tracking",
        style: centerColStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "tldVisibility",
        text: "TLD Visibility",
        style: centerColStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "goldenPriceAccess",
        text: "Golden Price Access",
        style: centerColStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "center",
          padding: "15px 10px",
          width: "45px",
        },
        formatter: (cell) => {
          return this.accessIconsLayout(cell);
        },
      },
      {
        dataField: "userId",
        text: "Account Actions",
        style: centerColStyle,
        headerStyle: {
          border: "1px solid #CACACA",
          backgroundColor: "#F5F5F5",
          color: "black",
          textAlign: "left",
          padding: "15px 10px",
          width: "125px",
        },
        formatter: (cell, row, rowIndex) => {
          return this.accountActionsLayout(row, rowIndex);
        },
      },
    ];

    return columns;
  }

  lastLoginLayout(cell) {
    if (cell === 1) {
      return cell + " Day Ago";
    } else if (cell === -1) {
      return "-";
    } else {
      return cell + " Days Ago";
    }
  }

  accessIconsLayout(cell) {
    if (cell === "no") {
      return (
        <div>
          <img className="icons" src={dashMinus} alt="no" />
        </div>
      );
    } else if (cell === "yes") {
      return (
        <div>
          <img className="icons" src={tickOk} alt="yes" />
        </div>
      );
    } else {
      return (
        <div>
          <span>
            <strong>-</strong>
          </span>
        </div>
      );
    }
  }

  accountActionsLayout(row, rowIndex) {
    if (row.userName !== this.state.currentUser) {
      return (
        <span>
          <button
            className="editButton"
            onClick={() => {
              this.editUser(row, rowIndex);
            }}
          >
            Edit
          </button>{" "}
          <strong>|</strong>{" "}
          <button
            className="deleteButton"
            onClick={() => {
              this.deleteUser(row);
            }}
          >
            Delete
          </button>
        </span>
      );
    } else {
      return (
        <span>
          <button
            className="editButton"
            onClick={() => {
              this.editUser(row, rowIndex);
            }}
          >
            Edit
          </button>
        </span>
      );
    }
  }

  editUser(row, rowIndex) {
    this.trackEditUserButtonClick(row);
    this.setState({
      rowdata: row,
      editUserPopup: true,
    });
  }

  deleteUser(row) {
    this.trackDeleteUserButtonClick(row);
    this.setState({
      rowdata: row,
      deleteUserConfirmationPopup: true,
    });
  }

  resetAfterPopupClosed() {
    this.closeAllPopup();
    this.resetUserTable();
    this.addNotification(
      ErrorMessages.UserManagement_AddUser +
        ". " +
        ErrorMessages.UserManagement_EmailSuccess,
      "info"
    );
  }

  resetUserTable() {
    if (this.state.searchString.length > 0) {
      this.searchUsers();
    } else {
      this.refreshPage();
    }
  }

  handleClosePopup() {
    this.setState({
      deleteUserConfirmationPopup: false,
      exportUsersPopup: false,
    });
  }

  handleExportSubmit(fileName) {
    let data = {
      userinfo: this.state.searchedUserList,
      searchString: this.state.searchString,
    };

    this.setState({
      downloadLoader: true,
      exportUsersPopup: false,
    });
    UserManagementHandler.exportUsers(data)
      .then((response) => {
        this.trackExportConfirmationButtonClick(fileName);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        fileName = fileName + ".xlsx";

        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloadLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          downloadLoader: false,
        });
        this.addNotification(ErrorMessages.UserManagement_ExportUsers, "error");
      });
  }

  handleExportCSV() {
    if (!this.state.searchValid) {
      this.addNotification(DisplayMessages.UserManagement_NoUser, "warning");
    } else {
      this.trackExportButtonClick();
      this.setState({ exportUsersPopup: true });
    }
  }

  deleteUserConfirmed() {
    this.trackDeleteUserConfirmationButtonClick(this.state.rowdata);
    UserManagementHandler.deleteUser(this.state.rowdata)
      .then((response) => {
        if (response.success) {
          this.resetUserTable();
          this.addNotification(
            DisplayMessages.UserManagement_DeleteUser,
            "info"
          );
        } else {
          response.message === "BranchDelete"
            ? this.addNotification(response.message, "warning")
            : this.addNotification("Error: " + response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UserManagement_DeleteUser, "error");
      });
  }

  addUser() {
    this.trackAddNewUserButtonClick();
    this.setState({
      addUserPopup: true,
    });
  }

  handleExportClose() {
    this.handleClosePopup();
    this.trackExportDiscardButtonClick();
  }

  handleOnClose() {
    this.setState({
      addUserPopup: false,
      editUserPopup: false,
    });
  }

  handleEditClose() {
    this.setState({
      editUserPopup: false,
      rowdata: [],
    });
  }

  // Mixpanel Tracking //

  trackAddNewUserButtonClick() {
    mixpanel.track("Add New User", {
      Action: "Button Click",
      Effect: "New user will be created",
    });
  }

  trackExportButtonClick() {
    mixpanel.track("Export Users", {
      Action: "Button Click",
      Effect: "Table data will be exported",
      Title: "Export User Details",
    });
  }

  trackExportConfirmationButtonClick(fileName) {
    mixpanel.track("Export Users", {
      Action: "Button Click",
      Effect: "Table data exported",
      Title: "Export User Details",
      "File name": fileName,
    });
  }

  trackExportDiscardButtonClick() {
    mixpanel.track("Export Discard", {
      Action: "Button Click",
      Effect: "Table data export discarded",
      Title: "Export User Details",
    });
  }

  trackSearchUserFilter(searchString) {
    mixpanel.track("Filter users", {
      Action: "Search",
      Effect: "User table will be filtered based on search string",
      "Search String": searchString,
    });
  }

  trackEditUserButtonClick(row) {
    mixpanel.track("Edit User", {
      Action: "Button Click",
      Effect: "User details will be updated",
      Username: row.userName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
      "Job Role": row.role,
    });
  }

  trackDeleteUserButtonClick(row) {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected user will be deleted",
      "Item Type": "User",
      Username: row.userName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
      "Job Role": row.role,
    });
  }

  trackDeleteUserConfirmationButtonClick(row) {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected user deleted",
      "Item Type": "User",
      Username: row.userName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
      "Job Role": row.role,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("User Management Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}
export default UserManagement;
