import axios from "axios";

const Api = (function () {
  return {
    get: function (route) {
      if (!route.includes("LoadUserInfo") && !route.includes("Login")) {
        try {
          const event = new Event("updateUserInfo");
          window.dispatchEvent(event);
        } catch (e) {
          const event2 = document.createEvent("Event");
          event2.initEvent("updateUserInfo", true, true);
          window.dispatchEvent(event2);
        }
      }
      const requestURL = this.getBaseURL() + "/api/" + route;

      return axios.get(requestURL, this.getHeaders()).catch((resp) => {
        if (resp.response !== undefined && resp.response.status === 401) {
          this.clearTokenOnLogout();
        } else {
          return Promise.reject(resp);
        }
      });
    },
    post: function (route, body) {
      if (!route.includes("Login")) {
        try {
          const event = new Event("updateUserInfo");
          window.dispatchEvent(event);
        } catch (e) {
          const event2 = document.createEvent("Event");
          event2.initEvent("updateUserInfo", true, true);
          window.dispatchEvent(event2);
        }
      }
      const requestURL = this.getBaseURL() + "/api/" + route;
      return axios.post(requestURL, body, this.getHeaders()).catch((resp) => {
        if (resp.response !== undefined && resp.response.status === 401) {
          this.clearTokenOnLogout();
        } else {
          return Promise.reject(resp);
        }
      });
    },
    put: function (route, body) {
      try {
        const event = new Event("updateUserInfo");
        window.dispatchEvent(event);
      } catch (e) {
        const event2 = document.createEvent("Event");
        event2.initEvent("updateUserInfo", true, true);
        window.dispatchEvent(event2);
      }
      const requestURL = this.getBaseURL() + "/api/" + route;
      return axios.put(requestURL, body, this.getHeaders()).catch((resp) => {
        if (resp.response !== undefined && resp.response.status === 401) {
          this.clearTokenOnLogout();
        } else {
          return Promise.reject(resp);
        }
      });
    },
    post_doc: function (route, body) {
      try {
        const event = new Event("updateUserInfo");
        window.dispatchEvent(event);
      } catch (e) {
        const event2 = document.createEvent("Event");
        event2.initEvent("updateUserInfo", true, true);
        window.dispatchEvent(event2);
      }
      const requestURL = this.getBaseURL() + "/api/" + route;
      return axios
        .post(requestURL, body, this.getDocHeaders())
        .catch((resp) => {
          if (resp.response !== undefined && resp.response.status === 401) {
            this.clearTokenOnLogout();
          } else {
            return Promise.reject(resp);
          }
        });
    },
    delete: function (route) {
      try {
        const event = new Event("updateUserInfo");
        window.dispatchEvent(event);
      } catch (e) {
        const event2 = document.createEvent("Event");
        event2.initEvent("updateUserInfo", true, true);
        window.dispatchEvent(event2);
      }
      const requestURL = this.getBaseURL() + "/api/" + route;
      return axios.delete(requestURL, this.getHeaders()).catch((resp) => {
        if (resp.response !== undefined && resp.response.status === 401) {
          this.clearTokenOnLogout();
        } else {
          return Promise.reject(resp);
        }
      });
    },
    getBaseURL: function () {
      if (window.location.protocol === "https:")
        return window.location.protocol + "//" + window.location.hostname;
      //server
      else return `http://${window.location.hostname}:5015`;
    },
    getHeaders: function () {
      const token = JSON.parse(sessionStorage.getItem("currentUser") || "{}")[
        "token"
      ];
      this.headers = {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      };
      return this.headers;
    },
    getDirectHeaders: function () {
      const token = JSON.parse(sessionStorage.getItem("currentUser") || "{}")[
        "token"
      ];
      this.headers = { Authorization: `Bearer ${token}` };
      return this.headers;
    },
    getDocHeaders: function () {
      const token = JSON.parse(sessionStorage.getItem("currentUser") || "{}")[
        "token"
      ];
      this.headers = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
        withCredentials: true,
      };
      return this.headers;
    },
    clearTokenOnLogout: function () {
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("username");
      sessionStorage.clear();
      window.location =
        window.location.protocol + "//" + window.location.host + "/";
      this.headers = {};
    },
  };
})();

export default Api;
