import React, { Component } from "react";
import { Row, Col, FormControl, FormGroup } from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";
import mixpanel from "mixpanel-browser";

import "./branchOverview.css";
import UserList from "./UserList";
import BranchOverview from "./BranchOverview";
import AddDistributorUser from "./AddDistributorUser";
import "./branchNavigation.css";

const backToBranches = "<- Back to Branches";

/**
 * Component represents branch details page containing branch overview section and user list section
 */
class BranchDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBranch: this.props.selectedBranch,
      branchOverviewTab: true,
      userOverviewTab: false,
      searchString: "",
      tldId: this.props.tldId,
      tldVisibility: JSON.parse(sessionStorage.getItem("tldVisibility")),
      addUsersPopup: false,
      searchValid: false,
      branchesUnderSalesrep: this.props.branchesUnderSalesrep,
    };

    this.usersOverviewTabHandler = this.usersOverviewTabHandler.bind(this);
    this.branchOverviewTabHandler = this.branchOverviewTabHandler.bind(this);
    this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.activeButton = this.activeButton.bind(this);
    this.addUsersPopupHandler = this.addUsersPopupHandler.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.searchValidity = this.searchValidity.bind(this);
    this.addNotification = this.addNotification.bind(this);
  }

  componentDidMount() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    if ("Branch Admin" === userObj["role"]) {
      this.setState({
        selectedBranch: this.props.selectedBranch,
        tldId: this.props.tldId,
        tldVisibility: JSON.parse(sessionStorage.getItem("tldVisibility")),
      });
    }
  }

  IsTLDVisible() {
    if (this.state.tldVisibility) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  AddUsersVisible() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    if (
      (this.state.selectedBranch.admin || userObj["role"] === "360 Admin") &&
      this.state.userOverviewTab
    ) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  render() {
    return (
      <div>
        <Row className={this.IsTLDVisible()}>
          <Col
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <div onClick={this.goBack} className="backToButton">
              <span style={{ fontWeight: "bold" }}>{backToBranches}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12} style={{ padding: "0px" }}>
            <h2
              className="big-blue-text"
              style={{ wordBreak: "break-all", overflowWrap: "break-word" }}
            >
              {this.state.selectedBranch.branchName}
            </h2>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={3}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <Col
              md={6}
              sm={6}
              xs={6}
              style={{ padding: "0px", marginTop: "10px" }}
            >
              <Button
                className={this.activeButton(this.state.branchOverviewTab)}
                onClick={this.branchOverviewTabHandler}
                id="branch-overview-button"
                style={{ whiteSpace: "normal", float: "left" }}
              >
                Branch Overview
              </Button>
            </Col>
            <Col
              md={6}
              sm={6}
              xs={6}
              style={{ padding: "0px", marginTop: "10px" }}
            >
              <Button
                className={this.activeButton(this.state.userOverviewTab)}
                onClick={this.usersOverviewTabHandler}
                style={{ whiteSpace: "normal", float: "right" }}
                id="users-button"
              >
                Users
              </Button>
            </Col>
          </Col>
          <Col md={3} sm={12} xs={12} className={this.AddUsersVisible()}>
            <Col
              md={6}
              sm={12}
              xs={12}
              style={{ padding: "0px", paddingTop: "8px", marginTop: "10px" }}
            >
              <div
                onClick={this.addUsersPopupHandler}
                className="add-new-users"
              >
                <span
                  style={{
                    margin: "0px 5px 0px 5px",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Add New User
                </span>
                <span className="glyphicon glyphicon-plus-sign" />
              </div>
            </Col>
          </Col>
          <Col
            md={5}
            sm={12}
            xs={12}
            className="searchbox"
            style={{ float: "right", padding: "0px", marginTop: "10px" }}
          >
            <div
              className={this.activeSearch()}
              style={{ padding: "0px 15px 0px 15px" }}
            >
              <Col className="no-padding-branch">
                <div className={this.searchBarVisible()}>
                  <FormGroup>
                    <FormControl
                      type="text"
                      autoComplete="off"
                      placeholder="Search by user's first name"
                      spellCheck="false"
                      value={this.state.searchString}
                      onChange={this.handleSearchStringChange}
                    />
                    <FormControl.Feedback />
                  </FormGroup>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        {this.state.branchOverviewTab ? (
          <BranchOverview
            branchId={this.state.selectedBranch.branchId}
            tldId={this.state.tldId}
            addNotification={this.addNotification}
            usersOverviewTabHandler={this.usersOverviewTabHandler}
            branchOverviewTabHandler={this.branchOverviewTabHandler}
          />
        ) : (
          <UserList
            selectedBranch={this.state.selectedBranch}
            tldId={this.state.tldId}
            addUsersPopup={this.state.addUsersPopup}
            searchString={this.state.searchString}
            usersOverviewTabHandler={this.usersOverviewTabHandler}
            branchOverviewTabHandler={this.branchOverviewTabHandler}
            searchValidity={this.searchValidity}
          />
        )}
        <AddDistributorUser
          show={this.state.addUsersPopup}
          selectedBranch={this.state.selectedBranch}
          handleOnClose={this.handleOnClose}
        />
      </div>
    );
  }

  addNotification(message, level) {
    this.props.addNotification(message, level);
  }

  searchBarVisible() {
    if (this.state.searchValid || this.state.searchString.length > 0) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  activeButton(tab) {
    if (tab) {
      return "button-blue users-branch-button active-button";
    } else {
      return "button-blue users-branch-button";
    }
  }

  addUsersPopupHandler() {
    this.trackAddNewUsersButtonClick();
    this.setState({
      addUsersPopup: true,
    });
  }

  activeSearch() {
    if (this.state.userOverviewTab) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  handleSearchStringChange(e) {
    let searchString = e.target.value.replace(/\s{2,}/g, " ");
    this.setState({
      searchString: searchString,
    });
  }

  handleOnClose() {
    this.setState({
      addUsersPopup: false,
    });
  }

  searchValidity(validity) {
    this.setState({
      searchValid: validity,
    });
  }

  branchOverviewTabHandler() {
    this.trackBranchOverviewTabClick();
    this.setState({
      branchOverviewTab: true,
      userOverviewTab: false,
    });
  }

  usersOverviewTabHandler() {
    this.trackUsersTabClick();
    this.setState({
      branchOverviewTab: false,
      userOverviewTab: true,
    });
  }

  goBack() {
    this.trackBackToBranchesButtonClick();
    this.props.goToBranchNavigation();
  }

  // Mixpanel Tracking //

  trackAddNewUsersButtonClick() {
    mixpanel.track("Add New Distributor User", {
      Action: "Button Click",
      Effect: "New distributor user will be added",
    });
  }

  trackBackToBranchesButtonClick() {
    mixpanel.track("Back To Branches", {
      Action: "Button Click",
      Effect: "Navigate back to branches page",
    });

    mixpanel.track_pageview({
      Page: "Branches Page",
    });
  }

  trackBranchOverviewTabClick() {
    mixpanel.track("Branch Overview", {
      Action: "Button Click",
      Effect: "Navigate to branch overview tab",
    });

    mixpanel.track_pageview({
      Page: "Branch Overview Page",
    });
  }

  trackUsersTabClick() {
    mixpanel.track("Users", {
      Action: "Button Click",
      Effect: "Navigate to users tab",
    });

    mixpanel.track_pageview({
      Page: "Users Page",
    });
  }
}

export default BranchDetail;
