import React, { Component } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import "./branchOverview.css";
import Api from "../Utils/api";
import BranchOverviewHandler from "./BranchOverviewHandler";
import BranchAdminPopup from "./BranchAdminPopup";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading";

/**
 * Component contains the branch overview section inside branch details
 */
class BranchOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataReceived: false,
      branchId: this.props.branchId,
      tldId: this.props.tldId,
      isDetailsReceived: false,
      branchDetails: [],
      branchAdmin: null,
      csr: null,
      salesAgency: [],
      salesrep: [],
      logo: "",
      userRole: "",
      showChangeAdminPopup: false,
    };
    this.usersOverviewTabHandler = this.usersOverviewTabHandler.bind(this);
    this.branchOverviewTabHandler = this.branchOverviewTabHandler.bind(this);
    this.handleChangeAdmin = this.handleChangeAdmin.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.getBranchDetails = this.getBranchDetails.bind(this);
    this.handleAddSalesrep = this.handleAddSalesrep.bind(this);
    this.handleRemoveSalesrep = this.handleRemoveSalesrep.bind(this);
    this.addNotification = this.addNotification.bind(this);
  }

  componentDidMount() {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    let role = "";
    if (sessionStorage.getItem("currentUser") != null) {
      role = userObj["role"];
    }
    this.setState(
      {
        branchId: this.props.branchId,
        tldId: this.props.tldId,
        userRole: role,
      },
      () => {
        this.getBranchDetails(this.props.branchId);
      }
    );
  }

  getBranchDetails(branchId) {
    BranchOverviewHandler.getBranchDetails(branchId)
      .then((response) => {
        if (response.success) {
          this.setState({
            isDataReceived: true,
            branchDetails: response.data.branch,
            branchAdmin: response.data.branchAdmin,
            csr: response.data.csr,
            salesAgency: response.data.salesAgency,
            salesrep: response.data.salesrep,
            logo:
              response.data.branch.Logo === ""
                ? ""
                : Api.getBaseURL() + "/uploads/" + response.data.branch.Logo,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchOverview_BranchDetails,
          "error"
        );
      });
  }

  render() {
    return (
      <div id="branch-overview-screen">
        {this.state.isDataReceived
          ? this.branchOverviewDisplaySection()
          : this.branchOverviewLoadingSection()}
        <BranchAdminPopup
          show={this.state.showChangeAdminPopup}
          handleOnClose={this.handleOnClose}
          branchAdmin={this.state.branchAdmin}
          salesAgency={this.state.salesAgency.Name}
          branchId={this.state.branchId}
          tldId={this.state.tldId}
        />
      </div>
    );
  }

  branchOverviewDisplaySection() {
    return (
      <Row className="branch-overview-row loading-fade">
        {this.branchInfoSection()}
        {this.branchAdminSection()}
      </Row>
    );
  }

  branchInfoSection() {
    return (
      <Col md={3} sm={12} xs={12} style={{ padding: "0px" }}>
        <div className="branch-info-container">
          <Row className="logo-image">
            {this.state.logo === "" ? (
              <div className="logo-container">
                <img
                  src={fetchResourceData(fixedLink.defaultTLDIcon)}
                  alt="logo"
                />
              </div>
            ) : (
              <img src={this.state.logo} alt="logo" />
            )}
          </Row>
          <Row
            className="branch-info-content"
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            <strong style={{ wordBreak: "break-word" }}>
              {this.state.branchDetails.TldName}
            </strong>
          </Row>
          <Row className="branch-info-content">
            <div>
              <strong>Customer Tier: </strong>
              <span>{this.state.branchDetails.CustomerTier}</span>
            </div>

            <div>
              <strong>Free Shipping Amount: </strong>$
              {this.state.branchDetails.FreeShipping}
            </div>
          </Row>
        </div>
        <div className="location-info-container">
          <Row className="branch-info-content">
            <div style={{ marginTop: "16px" }}>
              <span className="glyphicon glyphicon-map-marker" />
            </div>
            <div>
              <strong>Location:</strong>
            </div>
            <div style={{ wordBreak: "break-word" }}>
              {this.state.branchDetails.Location}
            </div>
            <div>{this.state.branchDetails.Province}</div>
          </Row>
        </div>
      </Col>
    );
  }

  branchAdminSection() {
    return (
      <Col
        md={9}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Row className="branchAdmin-CSR-row">
          <Col md={3} xs={12} sm={6} style={{ paddingLeft: "0px" }}>
            <div className="container-heading">
              <span>Branch Admin</span>
              <span> </span>
              {this.state.userRole === "360 Admin" ? (
                <span
                  className={
                    this.state.branchAdmin === null
                      ? "glyphicon glyphicon-plus"
                      : "glyphicon glyphicon-pencil"
                  }
                  style={{ float: "right" }}
                  onClick={this.handleChangeAdmin}
                />
              ) : (
                <span />
              )}
            </div>
            <div className="container-body scrollable-div">
              {this.state.branchAdmin === null ? (
                <div className="inner-div">
                  <Row>
                    <span className="not-assigned-text">
                      No Branch Admin Assigned
                    </span>
                  </Row>
                </div>
              ) : (
                <div className="inner-div">
                  <Row>
                    <strong>
                      {this.state.branchAdmin.FirstName +
                        " " +
                        this.state.branchAdmin.LastName}
                    </strong>
                  </Row>
                  <Row>
                    {this.state.branchAdmin.Phone === ""
                      ? "-"
                      : this.state.branchAdmin.Phone}
                  </Row>
                  <Row>{this.state.branchAdmin.Email}</Row>
                </div>
              )}
            </div>
          </Col>
          <Col md={3} xs={12} sm={6} style={{ paddingRight: "0px" }}>
            <div className="container-heading">
              {DisplayMessages.BranchOverView_CsrHeading}
            </div>
            <div className="container-body scrollable-div">
              {this.state.csr === null ? (
                <div className="inner-div">
                  <Row>
                    <span className="not-assigned-text">No CSR Assigned</span>
                  </Row>
                </div>
              ) : (
                <div className="inner-div">
                  <Row>
                    <strong>
                      {this.state.csr.FirstName + " " + this.state.csr.LastName}
                    </strong>
                  </Row>
                  <Row>
                    {this.state.csr.Phone === "" ? "-" : this.state.csr.Phone}
                  </Row>
                  <Row>{this.state.csr.Email}</Row>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "24px", marginLeft: "16px" }}>
          <Col md={3} style={{ padding: "0px" }}>
            <div className="container-heading">Sales Agency</div>
            <div className="agency-container-body">
              <div>
                <strong>{this.state.salesAgency.Name}</strong>
              </div>
              <div>{this.state.salesAgency.Phone}</div>
              <div>{this.state.salesAgency.Email}</div>
              <div>{this.state.salesAgency.Website}</div>
            </div>
          </Col>
          <Col md={9} style={{ padding: "0px" }}>
            <div className="container-heading">
              <span>Sales Agents</span>
              {this.state.userRole === "Salesrep" ? (
                this.SalesrepSpecificOptions()
              ) : (
                <span />
              )}
            </div>
            <div className="agency-container-body scrollable-div">
              {this.state.salesrep.length > 0 ? (
                this.state.salesrep.map((item) => {
                  return (
                    <Col
                      md={4}
                      sm={6}
                      xs={12}
                      key={item.Email}
                      style={{ marginBottom: "16px", paddingLeft: "0px" }}
                    >
                      <div>
                        <strong>{item.FirstName + " " + item.LastName}</strong>
                      </div>
                      <div>{item.Phone === "" ? "-" : item.Phone}</div>
                      <div>{item.Email}</div>
                    </Col>
                  );
                })
              ) : (
                <span className="not-assigned-text">No Salesrep Assigned</span>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }

  SalesrepSpecificOptions() {
    if (
      this.state.salesrep.filter(
        (item) => item.Email === sessionStorage.getItem("username")
      ).length > 0
    ) {
      return (
        <OverlayTrigger
          trigger={"hover"}
          rootClose
          placement="top"
          overlay={<Tooltip>Remove yourself as Sales Agent</Tooltip>}
        >
          <span
            id="salesrep_tooltip"
            className="glyphicon glyphicon-trash"
            style={{ float: "right" }}
            onClick={this.handleRemoveSalesrep}
          />
        </OverlayTrigger>
      );
    } else {
      return (
        <OverlayTrigger
          trigger={"hover"}
          rootClose
          placement="top"
          overlay={<Tooltip>Add yourself as Sales Agent</Tooltip>}
        >
          <span
            id="salesrep_tooltip"
            className="glyphicon glyphicon-plus"
            style={{ float: "right" }}
            onClick={this.handleAddSalesrep}
          />
        </OverlayTrigger>
      );
    }
  }

  handleAddSalesrep() {
    BranchOverviewHandler.addSalesrep(this.state.branchId)
      .then((response) => {
        if (response.success) {
          this.trackAddSalesrepButtonClick(this.state.branchDetails.BranchName);
          this.setState({
            salesrep: response.data,
          });
          this.addNotification(
            DisplayMessages.BranchOverview_AddSalesrep,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchOverview_AddSalesrepFailed,
          "error"
        );
      });
  }

  handleRemoveSalesrep() {
    BranchOverviewHandler.removeSalesrep(this.state.branchId)
      .then((response) => {
        if (response.success) {
          this.trackRemoveSalesrepButtonClick(
            this.state.branchDetails.BranchName
          );
          this.setState({
            salesrep: response.data,
          });
          this.addNotification(
            DisplayMessages.BranchOverview_RemoveSalesrep,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.BranchOverview_RemoveSalesrepFailed,
          "error"
        );
      });
  }

  addNotification(message, level) {
    this.props.addNotification(message, level);
  }

  handleOnClose() {
    this.setState({
      showChangeAdminPopup: false,
    });
    this.getBranchDetails(this.state.branchId);
  }

  handleChangeAdmin() {
    this.trackChangeBranchAdminButtonClick();
    this.setState({
      showChangeAdminPopup: true,
    });
  }

  branchOverviewLoadingSection() {
    return (
      <ContentLoading
        message={DisplayMessages.BranchOverview_Loading}
        size={50}
      />
    );
  }

  usersOverviewTabHandler() {
    this.props.usersOverviewTabHandler();
  }

  branchOverviewTabHandler() {
    this.props.branchOverviewTabHandler();
  }

  // Mixpanel Tracking //

  trackChangeBranchAdminButtonClick() {
    mixpanel.track("Change Branch Admin", {
      Action: "Button Click",
      Effect: "Branch admin will be changed",
    });
  }

  trackRemoveSalesrepButtonClick(branch) {
    mixpanel.track("Remove Salesrep from Sales Agents", {
      Action: "Button Click",
      Effect: "Salesrep is removed from sales agents list",
      Branch: branch,
      Salesrep: sessionStorage.getItem("username"),
    });
  }

  trackAddSalesrepButtonClick(branch) {
    mixpanel.track("Add Salesrep to Sales Agents", {
      Action: "Button Click",
      Effect: "Salesrep is added to sales agents list",
      Branch: branch,
      Salesrep: sessionStorage.getItem("username"),
    });
  }
}

export default BranchOverview;
