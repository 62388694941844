import React, { Component } from "react";
import { Modal, Button, Row } from "react-bootstrap";

import "../OrderSummary/orderSummary.css";
import "./compare.css";
import { DisplayMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents the No Matching Products popup for compare wizard
 */
class NoProductsPopup extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      application: this.props.application,
      message: DisplayMessages.Compare_NoMatchingProductsBody,
    };

    this.setMessage = this.setMessage.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(newProps) {
    this.setState(
      {
        application: newProps.application,
      },
      () => {
        if (this.state.application.length > 0) {
          this.setMessage();
        }
      }
    );
  }

  setMessage() {
    let message = DisplayMessages.Compare_NoMatchingProductsBody;
    if (this.state.application.length > 1) {
      message = message.replace("Application", "Applications");
    }
    let category = this.state.application
      .map((item) => item.application_name)
      .join(", ");
    message = message.replace("category", category);
    if (this._isMounted) {
      this.setState({
        message: message,
      });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleOnClose}
        dialogClassName="noProductsPopup-modal"
      >
        <Modal.Header>
          <h3
            style={{
              fontWeight: "bolder",
              paddingLeft: "15px",
              textAlign: "center",
            }}
            className="popup-blue-text"
          >
            {DisplayMessages.Compare_NoMatchingProductsHeading}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ fontSize: "14px", textAlign: "center" }}>
            <br />
            <span>{this.state.message}</span>
            <br />
            <br />
          </Row>
          <Row style={{ fontSize: "14px", textAlign: "center" }}>
            {DisplayMessages.Compare_ClickToProductPageMessage}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <Button
              bsStyle="primary"
              className="button-del-blue"
              onClick={this.props.handleOnClose}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NoProductsPopup;
