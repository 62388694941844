import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import yourPricingHandler from "./YourPricingHandler";
import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import "./YourPricing.css";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup";
import ContentLoading from "../ContentLoading/ContentLoading";
import { CopyToClipboard } from "../Utils/CopyToClipboard";

const customerBasedRoles = ["360 Admin", "Internal Salesrep", "Salesrep"];
const branchBasedRoles = ["Distributor User", "Branch Admin"];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const scrollLimit = 12;

/**
 * Component represents the Your Pricing Page
 */
class YourPricing extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      specialPricingList: [],
      stockdetails: [],
      isViewClicked: false,
      last_updated: null,
      selectedCustomer: null,
      customerList: [],
      showConfirmationPopup: false,
      priceList: null,
      inventory: null,
      productType: null,
      selectedQuantity: "",
      scrollEnabled: false,
      TLDName: null,
      isLoading: true,
      selectedItem: null,
      showSpecLinkPopup: false,
      specLinkPopupStockCode: "",
      specLinkPopupDescription: "",
      engSpecLink: "",
      frSpecLink: "",
      specValue: "default",
      dataReceived: true,
      itemCategory: "accessories",
      currentProductId: "NA",
    };

    this.customerSpecificSpecialPricingList =
      this.customerSpecificSpecialPricingList.bind(this);
    this.getCustomers = this.getCustomers.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.clearTableData = this.clearTableData.bind(this);
    this.handleClearClose = this.handleClearClose.bind(this);
    this.addToOrderSummary = this.addToOrderSummary.bind(this);
    this.scrollFit = this.scrollFit.bind(this);
    this.showSpecLink = this.showSpecLink.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
    this.addItem = this.addItem.bind(this);
    this.copyItem = this.copyItem.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getCustomers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  getCustomers() {
    yourPricingHandler
      .getCustomerList()
      .then((response) => {
        if (response.success && this._isMounted) {
          let selectedCustomer = branchBasedRoles.includes(
            sessionStorage.getItem("userRole")
          )
            ? response.data[0].CustomerName
            : sessionStorage.getItem("customerName");

          this.setState(
            {
              customerList: response.data.map((item) => ({
                value: item.CustomerName,
                label: item.CustomerName,
              })),
              selectedCustomer: selectedCustomer,
            },
            this.customerSpecificSpecialPricingList
          );
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.YourPricing_CustomerList,
          "error"
        );
      });
  }

  yourPricingLoadingSection() {
    return (
      <div className="your-pricing-container">
        <br />
        <ContentLoading
          message={DisplayMessages.YourPricing_Loading}
          size={50}
        />
      </div>
    );
  }

  render() {
    return this.state.isLoading
      ? this.yourPricingLoadingSection()
      : this.yourPricingSection();
  }

  yourPricingSection() {
    return (
      <div className="loading-fade">
        <div className="your-pricing-container">
          <Row>{this.pricingSubHeader()}</Row>
          <Row>{this.specialPricingTable()}</Row>
        </div>
        <ConfirmationPopUp
          show={this.state.showConfirmationPopup}
          title="Clear Estimate Summary!"
          text={this.state.message}
          clearTableData={this.clearTableData.bind(this)}
          handleClearClose={this.handleClearClose.bind(this)}
        />
      </div>
    );
  }

  pricingSubHeader() {
    if (customerBasedRoles.includes(sessionStorage.getItem("userRole"))) {
      return (
        <div className="admin-title">
          <Col md={8} sm={8} xs={8} style={{ padding: "0px" }}>
            <Row>
              <h2 className="big-blue-text">
                {DisplayMessages.YourPricing_Title}
              </h2>
            </Row>
            <br />
            <span className="role-subheader">
              {DisplayMessages.YourPricing_AdminSubTitle}
            </span>
          </Col>
          <Col
            md={4}
            sm={4}
            xs={4}
            style={{ padding: "0px", fontSize: "16px" }}
          >
            <span className="default-label" style={{ fontWeight: "bold" }}>
              Select Customer
            </span>
            <Select
              maxMenuHeight={190}
              menuPlacement="auto"
              value={{
                value: this.state.selectedCustomer,
                label:
                  this.state.selectedCustomer === null ||
                  this.state.selectedCustomer === ""
                    ? "Select Customer"
                    : this.state.selectedCustomer,
              }}
              styles={customStyles}
              isClearable
              onChange={this.handleCustomerSelect}
              options={this.state.customerList}
              aria-label="Select Customer"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
        </div>
      );
    } else {
      return (
        <div>
          <Row>
            <h2 className="big-blue-text">
              {DisplayMessages.YourPricing_Title}
            </h2>
          </Row>
          <br />
          <span className="role-subheader">
            {DisplayMessages.YourPricing_DistributorSubTitle}
          </span>
        </div>
      );
    }
  }

  specialPricingTable() {
    return (
      <div>
        {this.specialPricingTableHeader()}
        {this.specialPricingTableBody()}
        <br />
        {this.specialPricingLegend(true)}
      </div>
    );
  }

  specialPricingTableHeader() {
    return (
      <Row className="special-pricing-row-header">
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Quote Number
        </Col>
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Stock Code
        </Col>
        <Col md={3} sm={3} xs={3} className="special-pricing-row-headcell">
          Description
        </Col>
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Specs
        </Col>
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Your Unit Price
        </Col>
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Qty
        </Col>
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Expiry Date
        </Col>
        <Col md={2} sm={2} xs={2} className="special-pricing-row-headcell">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Estimated Ship Time</span>
            <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
          </div>
        </Col>
        <Col md={1} sm={1} xs={1} className="special-pricing-row-headcell">
          Add To Cart
        </Col>
      </Row>
    );
  }

  specialPricingTableBody() {
    if (this.state.specialPricingList.length > 0 && this.state.dataReceived) {
      return this.specialPricingTableDisplaySection();
    } else if (!this.state.dataReceived) {
      return this.specialPricingTableLoadingSection();
    } else {
      return null;
    }
  }

  specialPricingTableDisplaySection() {
    return (
      <div
        className="scrollable-div loading-fade"
        style={{ height: "600px", minHeight: "500px" }}
      >
        <Row>
          {this.state.specialPricingList.length > 0 &&
            this.state.specialPricingList.map((element, i) => {
              return (
                <Row className="golden-popup" key={element.StockCode}>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell"
                  >
                    {element.QuoteNumber}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell"
                  >
                    {element.StockCode}
                  </Col>
                  <Col
                    md={3}
                    sm={3}
                    xs={3}
                    className="special-pricing-row-cell"
                  >
                    {element.Description}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell"
                    onClick={() => this.showSpecLink(element)}
                  >
                    <SpecTooltipPopup
                      specValue={element.SpecValue}
                      productId={this.state.currentProductId}
                      stockCodeTitle={element.StockCode}
                      descriptionTitle={element.Description}
                      engSpecLink={this.state.engSpecLink}
                      frSpecLink={this.state.frSpecLink}
                      addNotification={this.props.addNotification}
                      isNavigationEnabled={this.state.itemCategory === "base"}
                      onModal={false}
                    />
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell gold-pricing"
                  >
                    {"$ " + parseFloat(element.UnitPrice).toFixed(2)}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell"
                  >
                    {parseInt(element.Qty, 10)}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell"
                  >
                    {element.ExpiryDate.replaceAll("-", "/")}
                  </Col>
                  <Col
                    md={2}
                    sm={2}
                    xs={2}
                    className="special-pricing-row-cell"
                  >
                    {element.LeadTime}
                  </Col>
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="special-pricing-row-cell copy-or-add"
                  >
                    <Button
                      onClick={() => this.copyItem(element)}
                      className="glyphicon glyphicon-duplicate"
                      aria-label="Copy to clipboard"
                    />
                    <Button
                      onClick={() => this.addItem(element)}
                      className="glyphicon glyphicon-plus"
                      aria-label="Add to cart"
                    />
                  </Col>
                </Row>
              );
            })}
        </Row>
        {this.specialPricingLegend(false)}
      </div>
    );
  }

  specialPricingTableLoadingSection() {
    return <ContentLoading message={""} size={50} />;
  }

  showSpecLink(element) {
    let specLinkPopupStockCode = element.StockCode;
    let specLinkPopupDescription = element.Description;
    let specValue = element.SpecValue;
    let pid = "";

    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          pid
        );
        this.getSpecLinks(specLinkPopupDescription, pid);
      }
    );
  }

  getSpecLinks(description, pid) {
    let element = {
      description: description,
      productId: pid.toString(),
      customer_name: this.state.selectedCustomer,
    };
    ConfigHandler.getSpecLinks(element)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            specValue: data.Specs,
            itemCategory: data.Category,
            currentProductId: data.ProductId,
          });
        } else {
          this.setState({
            specValue: "N/A",
            itemCategory:
              element.productId !== "NA" && data.productId !== ""
                ? "base"
                : "accessories",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  specialPricingLegend(scrollType) {
    if (
      this.state.specialPricingList.length > 0 &&
      this.state.scrollEnabled === scrollType
    ) {
      return (
        <Row>
          <br />
          <span className="gold-pricing">
            {DisplayMessages.GoldenPriceLine1}
          </span>
          {DisplayMessages.GoldenPriceLine2}
        </Row>
      );
    } else {
      return null;
    }
  }

  customerSpecificSpecialPricingList() {
    let selectedCustomer = encodeURIComponent(this.state.selectedCustomer);
    if (selectedCustomer !== null) {
      yourPricingHandler
        .getSpecialPricingList(selectedCustomer)
        .then((response) => {
          if (response.success && this._isMounted) {
            let tldName = branchBasedRoles.includes(
              sessionStorage.getItem("userRole")
            )
              ? response.data.tld
              : null;

            this.setState(
              {
                last_updated: response.data.last_updated,
                specialPricingList: response.data.list,
                TLDName: tldName,
                dataReceived: true,
              },
              () => {
                this.scrollFit();
              }
            );
          }
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.YourPricing_ListUnavailable,
            "error"
          );
        });
    } else {
      this.setState({
        specialPricingList: [],
        dataReceived: true,
      });
    }
  }

  scrollFit() {
    if (
      this.state.specialPricingList &&
      this.state.specialPricingList.length < scrollLimit
    ) {
      this.setState({
        scrollEnabled: false,
        isLoading: false,
      });
    } else {
      this.setState({
        scrollEnabled: true,
        isLoading: false,
      });
    }
  }

  handleCustomerSelect(event) {
    let selectedCustomer = null;
    if (typeof event === "string") {
      selectedCustomer = event;
    } else if (event !== null) {
      selectedCustomer = event.value;
    }

    this.trackCustomerSelection(selectedCustomer);

    this.setState(
      {
        selectedCustomer: selectedCustomer,
        specialPricingList: [],
        dataReceived: false,
      },
      this.customerSpecificSpecialPricingList
    );
  }

  handleClearClose() {
    this.setState({
      showConfirmationPopup: false,
    });
  }

  copyItem(element) {
    let totalPrice = (
      parseFloat(element.UnitPrice).toFixed(2) * parseInt(element.Qty, 10)
    ).toFixed(2);

    let data = {
      description: element.Description,
      productId: "",
    };

    let copyContent = {
      Stock_Code: element.StockCode,
      SKU_Description: element.Description,
      Quantity: parseInt(element.Qty, 10).toString(),
      Total_Price: totalPrice,
      Availability: element.LeadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.props.addNotification,
      "Your Pricing Page"
    );
  }

  addItem(element) {
    let description = encodeURIComponent(element.Description);
    let selectedCustomer = encodeURIComponent(this.state.selectedCustomer);
    yourPricingHandler
      .productInformation(description, selectedCustomer)
      .then((response) => {
        if (response.success) {
          this.setState(
            {
              selectedItem: element,
              productType: response.data.pricelist.Type,
              priceList: response.data.pricelist,
              inventory: response.data.inventory,
              selectedQuantity: element.Qty,
            },
            this.addToOrderSummary
          );
        } else {
          this.setState({
            priceList: null,
            inventory: null,
            selectedQuantity: "",
          });
          this.trackAddToCartButtonClick(ErrorMessages.UnableToAddItems);
          this.props.addNotification(ErrorMessages.UnableToAddItems, "error");
        }
      })
      .catch((error) => {
        this.trackAddToCartButtonClick(ErrorMessages.UnableToAddItems);
        this.props.addNotification(ErrorMessages.UnableToAddItems, "error");
      });
  }

  addToOrderSummary() {
    let goldenItems_details = [];
    let product_details = [];
    let description = "";
    if (this.state.productType === "led" && this.state.priceList.Wattage) {
      description = this.state.inventory.Description.replace(
        this.state.priceList.Wattage + "-",
        ""
      );

      product_details.push({
        description: description.split("-"),
        wattage: this.state.priceList.Wattage
          ? this.state.priceList.Wattage
          : "",
        cstColor:
          this.state.priceList.CST || this.state.priceList.CST === null
            ? "CST"
            : this.state.priceList.CST,
        Quantity: parseInt(this.state.selectedQuantity, 10),
        setId: this.state.priceList.SetId,
      });
    } else {
      description = this.state.inventory.Description;
      goldenItems_details.push({
        color: "",
        Description: description,
        Quantity: parseInt(this.state.selectedQuantity, 10),
      });
    }

    let data = {
      title: "YourPricing",
      productId: this.state.priceList.ProductId,
      action: "CustomerSpecificPricing",
      setId: this.state.priceList.SetId,
      customer_name:
        this.state.TLDName === null
          ? this.ternaryEvaluation(
              this.state.selectedCustomer,
              null,
              "",
              this.state.selectedCustomer
            )
          : this.state.TLDName,
      orderlist: {
        product_list: product_details,
        constructionOrAccessory_list: [],
        quickShip_list: [],
        goldenItemsList: goldenItems_details,
      },
    };
    this.addToSummaryAPI(data);
  }

  addToSummaryAPI(data) {
    if (
      this.state.selectedCustomer === null ||
      this.state.selectedCustomer === "" ||
      this.state.selectedCustomer === " "
    ) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameMissing);
      this.props.addNotification(ErrorMessages.CustomerNameMissing, "warning");
      return;
    }
    if (this.state.selectedCustomer.length > 50) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameLength);
      this.props.addNotification(ErrorMessages.CustomerNameLength, "warning");
      return;
    }
    ProductConfiguratorHandler.addToOrderSummary(data)
      .then((response) => {
        if (!response.success && response.message === "Failed") {
          this.trackAddToCartButtonClick(ErrorMessages.UnableToAddItems);
          this.props.addNotification(ErrorMessages.UnableToAddItems, "error");
        } else if (!response.success && response.message === "Limit") {
          this.trackAddToCartButtonClick(ErrorMessages.CartLimit);
          this.props.addNotification(ErrorMessages.CartLimit, "warning");
        } else if (!response.success) {
          this.trackAddToCartButtonClick(response.message);
          this.setState({
            showConfirmationPopup: true,
            message: response.message,
          });
        } else if (response.success) {
          this.trackAddToCartButtonSuccess(
            this.state.selectedItem.Description,
            this.state.selectedItem.StockCode
          );
          this.props.addNotification(
            DisplayMessages.YourPricing_AddedToCart,
            "info"
          );
          sessionStorage.setItem("customerName", this.state.selectedCustomer);
        }
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.UnableToAddToCart, "error");
      });
  }

  clearTableData() {
    ProductConfiguratorHandler.clearOrderSummary()
      .then((response) => {
        if (response.success) {
          sessionStorage.setItem("customerName", this.state.selectedCustomer);
          this.addToOrderSummary();
        }
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.UnableToClear, "error");
      });
    this.setState({ showConfirmationPopup: false });
  }

  // Mixpanel Tracking //

  trackCustomerSelection(customer) {
    mixpanel.track("Customer Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer is displayed",
      Customer: customer,
    });
  }

  trackSpecsClick(specs, description, stockCode, pid) {
    mixpanel.track("Specs", {
      Action: "Button Click",
      Effect: "Show/Hide Specs Tooltip",
      Specs: specs,
      Description: description,
      "Stock Code": stockCode,
      "Product ID": pid,
    });
  }

  trackAddToCartButtonClick(message) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Attempt to add item(s) to current estimate summary cart",
      Page: "Your Pricing Page",
      "Added To Cart": false,
      Message: message,
    });
  }

  trackAddToCartButtonSuccess(description, stockCode) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Successfully added to cart",
      Page: "Your Pricing Page",
      "Added To Cart": true,
      "SKU Description": description,
      "SKU Stock Code": stockCode,
    });
  }
}

export default YourPricing;
