import Api from "../Utils/api.js";
/**
 * API Calls to handle:
 * Get Light Sources
 * Get Lamp Options
 */
const FilterWizard2Components = (function () {
  return {
    getLightSources: function (selectedApplications) {
      return new Promise((resolve, reject) => {
        let groupNumber = selectedApplications[0].group_number;
        const route = "CompareWizard/LightSource/" + groupNumber;
        Api.get(route)
          .then((response) => {
            let lightSources = response.data.data.map((item) => {
              item.icon =
                Api.getBaseURL() + "/images/" + item.light_source_description;
              item.id = item.light_source_id;
              return item;
            });
            resolve(lightSources);
          })
          .catch((error) => reject(error));
      });
    },
    getLampOptions: function () {
      return new Promise((resolve, reject) => {
        const route = `CompareWizard/Lamp`;
        Api.get(route)
          .then((response) => {
            let lampOptions = response.data.data.map((item) => item);
            resolve(lampOptions);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default FilterWizard2Components;
