import React, { Component } from "react";
import mixpanel from "mixpanel-browser";

import "./topbar.css";

/**
 * Component represents the Top bar of the website
 */
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      phoneNumber: nextProps.phoneNumber,
    });
  }

  render() {
    return (
      <div className="header-msg">
        <div className="cta-msg cta-gray">
          <div className="container custom-container cat-container">
            <div className="col-lg-6 col-md-6 col-sm-8">
              QUESTIONS? CALL US TOLL-FREE AT &nbsp;
              <a
                href={"tel:" + this.state.phoneNumber}
                onClick={this.trackTollFreeNumberClick}
              >
                {this.state.phoneNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Mixpanel Tracking //

  trackTollFreeNumberClick() {
    mixpanel.track("Toll Free", {
      Action: "Link Click",
      Effect: "Navigated to telephone link",
    });
  }
}
export default TopBar;
