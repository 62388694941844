import ConfigHandler from "../ConfigHandler/ConfigHandler";
import mixpanel from "mixpanel-browser";
import { DisplayMessages } from "./FetchConfigurableData";
import copy from "copy-to-clipboard";

export const CopyToClipboard = (
  specSearch,
  copyContent,
  addNotification,
  fromPage
) => {
  ConfigHandler.getSpecLinks(specSearch)
    .then((response) => {
      let data = response.data.find((item) => item.Specs !== "0");
      if (data !== null && data !== undefined) {
        copyContent.Spec_Sheet_English = data.EngSpecLink;
        copyContent.Spec_Sheet_French = data.FRSpecLink;
      }

      let copyString = JSON.stringify(copyContent);
      copyString = copyString.replaceAll('",', '",\n');

      trackCopyToClipboardButtonClick(copyContent, fromPage);

      copy(copyString);
      addNotification(DisplayMessages.CopiedToClipboard, "info");
    })
    .catch((error) => {
      console.error(error);
    });
};

// Mixpanel Tracking //

const trackCopyToClipboardButtonClick = (copyContent, fromPage) => {
  mixpanel.track("Copy To Clipboard", {
    Action: "Button Click",
    Effect: `Selected product details are copied to clipboard`,
    Page: fromPage,
    "Stock Code": copyContent.Stock_Code,
    Description: copyContent.SKU_Description,
    Quantity: copyContent.Quantity,
    Availability: copyContent.Availability,
    "English Spec Link": copyContent.Spec_Sheet_English,
    "French Spec Link": copyContent.Spec_Sheet_French,
  });
};
