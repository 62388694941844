import Api from "../Utils/api.js";

/**
 * API Calls to handle:
 * Get lighting space
 * Get applications
 */
const FilterWizard1Components = (function () {
  return {
    getLightingSpaces: function () {
      return new Promise((resolve, reject) => {
        const route = `CompareWizard/Space`;
        Api.get(route)
          .then((response) => {
            let lightingSpaces = response.data.data.map((item) => {
              item.icon =
                Api.getBaseURL() + "/images/" + item.space_description;
              item.id = item.space_id;
              return item;
            });
            lightingSpaces.success = response.data.success;
            resolve(lightingSpaces);
          })
          .catch((error) => reject(error));
      });
    },
    getApplications: function (selectedLightingSpaces) {
      return new Promise((resolve, reject) => {
        let spaceIds = selectedLightingSpaces.map((item) => item.space_id);
        const route = "CompareWizard/Application/" + spaceIds.join();
        Api.get(route)
          .then((response) => {
            let applications = response.data.data.map((item) => {
              item.icon =
                Api.getBaseURL() + "/images/" + item.application_description;
              item.id = item.application_id;
              return item;
            });
            resolve(applications);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default FilterWizard1Components;
