import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import CacheBuster from "react-cache-buster";
import mixpanel from "mixpanel-browser";

import { version } from "../../package.json";
import "./app.css";
import Product from "../Product/Product";
import Navigation from "../Navigation/Navigation";
import Header from "../Header/Header";
import Topbar from "../TopBar/TopBar";
import LogIn from "../LogIn/Login";
import Home from "../Home/Home";
import Compare from "../Compare/Compare";
import ProductList from "../ProductList/ProductList";
import InstallationGallery from "../InstallationGallery/InstallationGallery";
import Tools from "../Tools/tools";
import YourPricing from "../YourPricing/YourPricing";
import yourPricingHandler from "../YourPricing/YourPricingHandler";
import Content from "../Content/Content";
import OrderTrack from "../OrderTrack/OrderTrack";
import Tableau from "../Tableau/Tableau";
import ResetPopup from "./resetPopup";
import OrderSummaryPage from "../OrderSummary/OrderSummaryPage";
import OrderSummary from "../OrderSummary/OrderSummary";
import PinnedOrderSummary from "../OrderSummary/PinnedOrderSummary";
import QuickConfigurator from "../QuickConfigurator/QuickConfigurator";
import Clearance from "../Clearance/Clearance";
import ForgotPassWord from "../LogIn/ForgotPassword";
import ResetPassword from "../LogIn/ResetPassword";
import Distributors from "../Distributors/Distributors";
import DistributorBranchNavigation from "../Distributors/DistributorBranchNavigation";
import DistributorsHandler from "../Distributors/DistributorsHandler";
import TwoFactorAuthentication from "../LogIn/TwoFactorAuthentication";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import SearchList from "../SearchBox/SearchList";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import LoginHandler from "../LogIn/LoginHandler";
import fixedLink from "../Assets/fixedAssets.json";
import {
  ErrorMessages,
  Miscellaneous,
  fetchResourceData,
  DisplayMessages,
  fetchJSONFileData,
} from "../Utils/FetchConfigurableData";
import Loading from "react-loading";

const customerBasedRoles = ["360 Admin", "Internal Salesrep", "Salesrep"];
const branchBasedRoles = ["Distributor User", "Branch Admin"];

const isProduction = process.env.NODE_ENV === "production";

let redirect = 0;
let isLoginClicked = false;
const appLogo = fetchResourceData(fixedLink.appLogo);

/**
 * Component represents the main page of the website.
 * All re-directions and page access are handled here.
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.logout = this.logout.bind(this);
    this.sessionLogout = this.sessionLogout.bind(this);
    this.handleClearClose = this.handleClearClose.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      loggedIn: false,
      selected: "",
      logo: appLogo,
      role: "",
      phoneNumber: "",
      userName: "",
      applicationType: Miscellaneous.ApplicationType,
      show_reset_popup: false,

      showDistributorUsers: true,
      showClearance: true,
      showTrackMyOrder: true,
      showYourPricing: true,
      twoFactorAuthentication: false,
      key: "",
      enablePolicy: false,
      resultData: null,
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.goToPrivacyPolicy = this.goToPrivacyPolicy.bind(this);
    this.getSpecialPricingAvailability =
      this.getSpecialPricingAvailability.bind(this);
    this.roleBasedPageAuthentication =
      this.roleBasedPageAuthentication.bind(this);
    this.configureUserAccess = this.configureUserAccess.bind(this);
  }

  async componentDidMount() {
    await fetchJSONFileData(fixedLink.displayMessagesPath);

    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    // Mixpanel Initialization
    this.mixpanelInitialization();

    if (userObj !== null) {
      this.setState({
        loggedIn: true,
        role: userObj["role"],
      });
      this.getConfiguration();
      this.getSpecialPricingAvailability();
      this.roleBasedPageAuthentication(userObj["role"]);
      if (branchBasedRoles.includes(userObj["role"])) {
        let username = sessionStorage.getItem("username");
        DistributorsHandler.getBranchAdminData(username)
          .then((response) => {
            if (response.success) {
              this.configureUserAccess(userObj, response.data.tld);
            }
          })
          .catch((error) => {
            this.addNotification(ErrorMessages.BranchAdminData, "error");
          });
      }

      this.userActivityTrackingEnabled();
    }
  }

  mixpanelInitialization() {
    let initApiToken = "";

    switch (Miscellaneous.Server) {
      case "STAGING":
        initApiToken =
          Miscellaneous.ApplicationType === "RAB"
            ? process.env.REACT_APP_RAB_STAGING_MIXPANEL_TOKEN
            : process.env.REACT_APP_RDA_STAGING_MIXPANEL_TOKEN;
        break;
      case "PRODUCTION":
        initApiToken =
          Miscellaneous.ApplicationType === "RAB"
            ? process.env.REACT_APP_RAB_PROD_MIXPANEL_TOKEN
            : process.env.REACT_APP_RDA_PROD_MIXPANEL_TOKEN;
        break;
      case "TEST":
      default:
        initApiToken =
          Miscellaneous.ApplicationType === "RAB"
            ? process.env.REACT_APP_RAB_TEST_MIXPANEL_TOKEN
            : process.env.REACT_APP_RDA_TEST_MIXPANEL_TOKEN;
        break;
    }

    mixpanel.init(initApiToken, {
      debug: process.env.NODE_ENV === "development",
      persistence: "localStorage",
    });
  }

  configureUserAccess(userObj, access) {
    if (
      userObj["role"].includes("Distributor User") &&
      access.tldVisibility === "no"
    ) {
      this.setState({
        showDistributorUsers: false,
      });
    }
    if (access.pricingAccess === "no") {
      this.setState({
        showClearance: false,
        showYourPricing: false,
      });
    }
    if (access.goldenPriceAccess === "no") {
      this.setState({
        showYourPricing: false,
      });
    }
    if (access.orderTrackAccess === "no") {
      this.setState({
        showTrackMyOrder: false,
      });
    }
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  roleBasedPageAuthentication(role) {
    if (branchBasedRoles.includes(role)) {
      let username = sessionStorage.getItem("username");
      DistributorsHandler.getBranchAdminData(username)
        .then((response) => {
          if (
            role.includes("Distributor User") &&
            response.data.tld.tldVisibility === "no"
          ) {
            this.setState({
              showDistributorUsers: false,
            });
          }
          if (response.data.tld.pricingAccess === "no") {
            this.setState({
              showClearance: false,
              showYourPricing: false,
            });
          }
          if (response.data.tld.goldenPriceAccess === "no") {
            this.setState({
              showYourPricing: false,
            });
          }
          if (response.data.tld.orderTrackAccess === "no") {
            this.setState({
              showTrackMyOrder: false,
            });
          }
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.BranchAdminData, "error");
        });
    }
  }

  clearApplicationTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setApplicationTimeout() {
    let secondsTimer = 1000;
    let minutesTimer = 60 * secondsTimer;
    let sessionTimer = Miscellaneous.SessionTimeoutInMinutes * minutesTimer;
    this.logoutTimeout = setTimeout(this.sessionLogout, sessionTimer);
  }

  resetTimeout() {
    this.clearApplicationTimeout();
    this.setApplicationTimeout();
  }

  removeApplicationEvents() {
    this.clearApplicationTimeout();

    for (let i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    if (this.state.loggedIn) {
      return (
        <CacheBuster
          currentVersion={version}
          isEnabled={isProduction}
          loadingComponent={<Loading />}
          isVerboseMode={true}
        >
          <div className="app-container scrollable-div">
            <div className="app-header">
              <Topbar phoneNumber={this.state.phoneNumber} />
            </div>
            <div className="custom-container">
              <Header
                logo={this.state.logo}
                logOutHandler={this.logout}
                addNotification={this.addNotification}
              />
            </div>
            <br />
            <Navigation
              logOutHandler={this.logout}
              role={this.state.role}
              addNotification={this.addNotification}
              getSpecialPricingAvailability={this.getSpecialPricingAvailability}
            />
            <div className="custom-container">
              <div className="route-content">
                <Switch>
                  <Route
                    exact
                    path="/Home"
                    component={() => this.HomeComponent()}
                  />
                  <Route
                    exact
                    path="/Compare"
                    component={(props) => this.CompareComponent(props)}
                  />
                  <Route
                    exact
                    path="/Products"
                    component={(props) => this.ProductsComponent(props)}
                  />
                  <Route
                    path="/SearchResults"
                    component={(props) => this.SearchListComponent(props)}
                  />
                  <PrivateRoute
                    exact
                    path="/YourPricing"
                    component={() => this.YourPricingComponent()}
                    authed={this.state.showYourPricing}
                  />
                  <PrivateRoute
                    exact
                    path="/Clearance"
                    component={() => this.ClearanceComponent()}
                    authed={this.state.showClearance}
                  />
                  <Route
                    exact
                    path="/Installs"
                    component={() => this.InstallationGalleryComponent()}
                  />
                  <Route
                    exact
                    path="/Resources"
                    component={() => this.ResourcesComponent()}
                  />
                  <Route
                    exact
                    path="/Tableau"
                    component={() => this.TableauComponent()}
                  />
                  <PrivateRoute
                    exact
                    path="/TrackMyOrder"
                    component={() => this.TrackMyOrderComponent()}
                    authed={this.state.showTrackMyOrder}
                  />
                  <Route exact path="/Product" component={Product} />
                  <Route
                    exact
                    path="/PinnedOrderSummary"
                    component={(props) =>
                      this.PinnedOrderSummaryComponent(props)
                    }
                  />
                  <Route
                    exact
                    path="/EstimateSummary"
                    component={(props) => this.OrderSummaryPageComponent(props)}
                  />
                  <Route
                    exact
                    path="/OrderSummary"
                    component={(props) => this.OrderSummaryComponent(props)}
                  />
                  <Route
                    path="/QuickConfigurator"
                    component={(props) =>
                      this.QuickConfiguratorComponent(props)
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/Content"
                    component={() => this.ContentComponent()}
                    authed={Auth.isUserAllowed("Content")}
                  />
                  <PrivateRoute
                    exact
                    path="/Distributors"
                    component={(props) => this.DistributorsComponent(props)}
                    authed={Auth.isUserAllowed("Distributors")}
                  />
                  <PrivateRoute
                    exact
                    path="/DistributorsManagement"
                    component={() => this.DistributorManagementComponent()}
                    authed={
                      Auth.isUserAllowed("DistributorUsers") &&
                      this.state.showDistributorUsers
                    }
                  />
                  <Route path="/" component={() => this.HomeComponent()} />
                </Switch>
              </div>
            </div>

            <NotificationSystem
              ref={(notificationSystem) =>
                (this._notificationSystem = notificationSystem)
              }
              style={notificationStyle}
            />
          </div>
        </CacheBuster>
      );
    } else if (
      this.state.twoFactorAuthentication &&
      this.state.key !== "" &&
      !window.location.href.includes("TwoStepVerification/" + this.state.key) &&
      redirect === 0
    ) {
      let path = "TwoStepVerification/" + this.state.key;
      redirect = 1;
      return <Redirect to={{ pathname: path }} />;
    } else if (this.state.enablePolicy) {
      return (
        <PrivacyPolicy
          goToHome={this.goToHome}
          addNotification={this.addNotification}
          displayName={this.state.displayName}
          resultData={this.state.resultData}
          events={this.events}
        />
      );
    } else {
      return (
        <CacheBuster
          currentVersion={version}
          isEnabled={isProduction}
          loadingComponent={<Loading />}
          isVerboseMode={true}
        >
          <div className="app">
            <br />
            <div className="container custom-container">
              <div className="route-content">
                <Switch>
                  <Route
                    exact
                    path="/ForgotPassword"
                    component={(props) => this.ForgotPasswordComponent(props)}
                  />
                  <Route
                    exact
                    path="/ResetPassword/:id/:token"
                    component={(props) => this.ResetPasswordComponent(props)}
                  />
                  <Route
                    exact
                    path="/TwoStepVerification/:key"
                    component={(props) =>
                      this.TwoFactorAuthenticationComponent(props)
                    }
                  />
                  <Route
                    exact
                    path="/Login"
                    component={() => this.LoginComponent()}
                  />
                  <Route component={() => this.LoginComponent()} />
                </Switch>
              </div>
            </div>

            <NotificationSystem
              ref={(notificationSystem) =>
                (this._notificationSystem = notificationSystem)
              }
              style={notificationStyle}
            />
            <ResetPopup
              show={this.state.show_reset_popup}
              handleClearClose={this.handleClearClose}
              title={this.state.passwordResetTitle}
              text={this.state.passwordResetContent}
            />
          </div>
        </CacheBuster>
      );
    }
  }

  HomeComponent() {
    return (
      <Home
        addNotification={this.addNotification}
        applicationType={this.state.applicationType}
      />
    );
  }

  CompareComponent(props) {
    return (
      <Compare
        applicationType={this.state.applicationType}
        addNotification={this.addNotification}
        {...props}
      />
    );
  }

  ProductsComponent(props) {
    return (
      <ProductList
        role={this.state.role}
        userName={this.state.userName}
        addNotification={this.addNotification}
        applicationType={this.state.applicationType}
        {...props}
      />
    );
  }

  SearchListComponent(props) {
    return <SearchList addNotification={this.addNotification} {...props} />;
  }

  YourPricingComponent() {
    return <YourPricing addNotification={this.addNotification} />;
  }

  ClearanceComponent() {
    return (
      <Clearance
        addNotification={this.addNotification}
        applicationType={this.state.applicationType}
      />
    );
  }

  InstallationGalleryComponent() {
    return (
      <InstallationGallery
        addNotification={this.addNotification}
        role={this.state.role}
        applicationType={this.state.applicationType}
      />
    );
  }

  ResourcesComponent() {
    return (
      <Tools addNotification={this.addNotification} role={this.state.role} />
    );
  }

  TableauComponent() {
    return <Tableau />;
  }

  TrackMyOrderComponent() {
    return (
      <OrderTrack
        role={this.state.role}
        addNotification={this.addNotification}
      />
    );
  }

  PinnedOrderSummaryComponent(props) {
    return (
      <PinnedOrderSummary
        addNotification={this.addNotification}
        userName={this.state.userName}
        applicationType={this.state.applicationType}
        {...props}
      />
    );
  }

  OrderSummaryPageComponent(props) {
    return (
      <OrderSummaryPage
        addNotification={this.addNotification}
        userName={this.state.userName}
        applicationType={this.state.applicationType}
        {...props}
      />
    );
  }

  OrderSummaryComponent(props) {
    return (
      <OrderSummary
        addNotification={this.addNotification}
        userName={this.state.userName}
        applicationType={this.state.applicationType}
        {...props}
      />
    );
  }

  QuickConfiguratorComponent(props) {
    return (
      <QuickConfigurator
        addNotification={this.addNotification}
        userName={this.state.userName}
        applicationType={this.state.applicationType}
        {...props}
      />
    );
  }

  ContentComponent() {
    return (
      <Content addNotification={this.addNotification} role={this.state.role} />
    );
  }

  DistributorsComponent(props) {
    return (
      <Distributors
        addNotification={this.addNotification}
        role={this.state.role}
        {...props}
      />
    );
  }

  DistributorManagementComponent() {
    return (
      <DistributorBranchNavigation
        addNotification={this.addNotification}
        role={this.state.role}
      />
    );
  }

  ForgotPasswordComponent(props) {
    return <ForgotPassWord addNotification={this.addNotification} {...props} />;
  }

  ResetPasswordComponent(props) {
    return <ResetPassword addNotification={this.addNotification} {...props} />;
  }

  LoginComponent() {
    return (
      <LogIn
        applicationType={this.state.applicationType}
        handleLogIn={this.handleLogIn}
        addNotification={this.addNotification}
      />
    );
  }

  TwoFactorAuthenticationComponent(props) {
    return (
      <TwoFactorAuthentication
        addNotification={this.addNotification}
        goToHome={this.goToHome}
        goToPrivacyPolicy={this.goToPrivacyPolicy}
        {...props}
      />
    );
  }

  handleClearClose() {
    this.setState({
      show_reset_popup: false,
    });
  }

  handleLogIn(credentials) {
    if (credentials !== undefined && !isLoginClicked) {
      this.isLoggedIn(credentials);
    }
  }

  goToHome(data, username) {
    this.trackUserIdentification(username);
    this.trackLoginButtonClick(
      username,
      DisplayMessages.TrackLogin_HomePageNavigation
    );
    this.trackHomePageNavigation();
    sessionStorage.setItem("currentUser", JSON.stringify(data));
    sessionStorage.setItem("userRole", data.role);
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("phoneNumber", this.state.phoneNumber);
    this.getConfiguration();
    this.setState(
      {
        loggedIn: true,
        role: data.role,
        applicationType: data.applicationType,
        enablePolicy: false,
      },
      () => {
        this.userActivityTrackingEnabled();
        this.props.history.push("/Home");
      }
    );
  }

  goToPrivacyPolicy(data, displayName) {
    this.trackPrivacyPolicyPageNavigation();

    this.setState({
      enablePolicy: true,
      displayName: displayName,
      resultData: data,
    });
  }

  isLoggedIn(credentials) {
    isLoginClicked = true;
    LoginHandler.userLogin(credentials)
      .then((response) => {
        isLoginClicked = false;

        if (!response.success) {
          if (response.data == null) {
            this.trackLoginButtonClick(credentials.userName, response.message);
            this.addNotification(response.message, "error");
          } else {
            this.trackLoginButtonClick(
              credentials.userName,
              DisplayMessages.ResetPassword_ForceReset
            );
            this.addNotification(
              DisplayMessages.ResetPassword_ForceReset,
              "warning"
            );
            this.setState({
              loggedIn: false,
              applicationType: response.data.applicationType,
            });
          }
        } else if (
          response.data.isUserLoggedIn &&
          !response.data.isUserTwoFactorAuthenticated
        ) {
          this.setState(
            {
              twoFactorAuthentication: true,
              key: response.data.key,
            },
            () => {
              this.trackLoginButtonClick(
                credentials.userName,
                DisplayMessages.TrackLogin_MFANavigation
              );
              this.trackMFAPageNavigation();
              sessionStorage.setItem("Time", "");
            }
          );
          redirect = 0;
          sessionStorage.setItem("phoneNumber", this.state.phoneNumber);
        } else if (response.data.activityStatus === 2) {
          this.trackLoginButtonClick(
            credentials.userName,
            DisplayMessages.TrackLogin_PrivacyPolicyNavigation
          );
          this.goToPrivacyPolicy(response.data, response.data.displayName);
        } else if (
          response.data.isUserLoggedIn &&
          response.data.isUserTwoFactorAuthenticated
        ) {
          this.goToHome(response.data, credentials.userName);
        } else {
          this.trackLoginButtonClick(
            credentials.userName,
            ErrorMessages.LoginFailed
          );
          this.setState({
            loggedIn: false,
            applicationType: response.data.applicationType,
          });
          document.getElementById("login").disabled = false;
        }
      })
      .catch((error) => {
        isLoginClicked = false;
        this.trackLoginButtonClick(
          credentials.userName,
          ErrorMessages.LoginFailed
        );
        this.addNotification(ErrorMessages.LoginFailed, "error");
      });
  }

  logout() {
    LoginHandler.userLogout()
      .then((response) => {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("username");
        sessionStorage.clear();
        this.setState(
          {
            loggedIn: false,
          },
          this.finalizeLogout
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.LogoutFailed, "error");
      });
    this.removeApplicationEvents();
  }

  finalizeLogout() {
    this.trackUserSessionReset();
    this.props.history.push("/Login");
  }

  sessionLogout() {
    this.logout();
    this.addNotification(DisplayMessages.SessionTimeoutMessage, "warning");
    this.removeApplicationEvents();
  }

  getConfiguration() {
    ConfigHandler.getWebsiteConfig()
      .then((response) => {
        this.setState({
          logo: appLogo,
          phoneNumber: response.data.mobileNumber,
          userName: response.data.displayName,
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.ConfigurationAccessFailed, "error");
      });
    ConfigHandler.getDisplayName()
      .then((response) => {
        if (response.success) {
          this.setState({
            userName: response.message,
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_CurrentUser, "error");
      });
  }

  getSpecialPricingAvailability() {
    yourPricingHandler
      .checkPricingAvailability()
      .then((response) => {
        this.setState((prevState) => ({
          showYourPricing: response.data && prevState.showClearance,
        }));
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.SpecialPricingAvailabilityFailed,
          "error"
        );
      });
  }

  userActivityTrackingEnabled() {
    for (let i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setApplicationTimeout();
  }

  // Mixpanel Tracking //

  trackUserIdentification(username) {
    mixpanel.identify(username);
    mixpanel.people.set({
      $email: username,
    });
  }

  trackUserSessionReset() {
    mixpanel.reset();
  }

  trackLoginButtonClick(username, message) {
    mixpanel.track("User Login", {
      Action: "Button Click",
      Effect: "User login attempted",
      Username: username,
      Message: message,
    });
  }

  trackMFAPageNavigation() {
    mixpanel.track_pageview({
      Page: "Two-Factor Authentication Page",
    });
  }

  trackPrivacyPolicyPageNavigation() {
    mixpanel.track_pageview({
      Page: "Privacy Policy Page",
    });
  }

  trackHomePageNavigation() {
    mixpanel.track_pageview({
      Page: "Home Page",
    });
  }
}

function PrivateRoute({ component: Component, authed, lng, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} lng={lng} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

const Auth = {
  isUserAllowed(page) {
    let isUserAllowed;
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));
    let role = "";
    if (sessionStorage.getItem("currentUser") != null) {
      role = userObj["role"];
    }
    switch (page) {
      case "Content":
        if (role === "360 Admin") isUserAllowed = true;
        break;
      case "Distributors":
        if (customerBasedRoles.includes(role)) {
          isUserAllowed = true;
        }
        break;

      case "DistributorUsers":
        if (branchBasedRoles.includes(role)) {
          isUserAllowed = true;
        }
        break;
      case "PrivacyPolicy":
        if (sessionStorage.getItem("username")) {
          isUserAllowed = true;
        }
        break;
      default:
        isUserAllowed = false;
    }
    return isUserAllowed;
  },
};

export default withRouter(App);
