import Api from "../Utils/api.js";
import Plugin from "../Utils/plugin.js";

/**
 * API Calls to handle:
 * Get Quick ship list
 * Get customer list
 * Get accessory images
 * Get product configuration details
 * Set customer tier
 * Get product price based on stock code
 * Get inventory details
 * Add product to cart
 * Clear items in cart
 * Get Spec Links
 */
const ProductConfiguratorHandler = (function () {
  return {
    getQuickShipList: function (productId) {
      return new Promise((resolve, reject) => {
        const route = `ProductConfigurator/QuickShip?productId=${productId}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getCustomerList: function () {
      return new Promise((resolve, reject) => {
        const route = `ProductConfigurator/Customers`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getAccessoriesImages: function (imageId) {
      return new Promise((resolve, reject) => {
        const route = "accessories/" + imageId;
        Plugin.get(route)
          .then((response) => {
            let imagedetails = response.data.data;
            resolve(imagedetails);
          })
          .catch((error) => reject(error));
      });
    },
    getProductConfigDetails: function (productId, title, screen, customer) {
      return new Promise((resolve, reject) => {
        const route = `ProductConfigurator/ConfigurationDetails?productId=${productId}&title=${title}&screen=${screen}&customer=${customer}`;
        Api.get(route)
          .then((response) => {
            let configdetails = response.data;
            resolve(configdetails);
          })
          .catch((error) => reject(error));
      });
    },
    getCompleteConfiguration: function (productId, title, customer) {
      return new Promise((resolve, reject) => {
        const route = `ProductConfigurator/CompleteProductConfiguration?productId=${productId}&title=${title}&customer=${customer}`;
        Api.get(route)
          .then((response) => {
            let configdetails = response.data;
            resolve(configdetails);
          })
          .catch((error) => reject(error));
      });
    },
    setCustomerTier: function (tier) {
      return new Promise((resolve, reject) => {
        const route = `ProductConfigurator/NewCustomerTier?tier=${tier}`;
        Api.get(route)
          .then((response) => {
            let configdetails = response.data;
            resolve(configdetails);
          })
          .catch((error) => reject(error));
      });
    },
    getStockCodeAndPrice: function (data) {
      return new Promise((resolve, reject) => {
        let route = `ProductConfigurator/StockCodeAndPrice`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInventoryDetails: function (data) {
      return new Promise((resolve, reject) => {
        let route = `ProductConfigurator/InventoryDetails?description=${data}`;
        Api.get(route, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addToOrderSummary: function (data) {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/AddToOrderSummary`;
        Api.post(route, data)
          .then((result) => {
            resolve(result.data.value);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clearOrderSummary: function () {
      return new Promise((resolve, reject) => {
        let route = `EstimateSummary/DeleteAllOrders`;
        Api.delete(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllAccessories: function (productId, customer, finish) {
      return new Promise((resolve, reject) => {
        const route = `ProductConfigurator/AllAccessories?productId=${productId}&customer=${customer}&finish=${finish}`;
        Api.get(route)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  };
})();

export default ProductConfiguratorHandler;
