import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Carousel from "react-bootstrap/lib/Carousel";
import Panel from "react-bootstrap/lib/Panel";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import Pager from "react-bootstrap/lib/Pager";
import Grid from "react-bootstrap/lib/Grid";
import Ticker from "react-ticker";
import mixpanel from "mixpanel-browser";

import "./home.css";
import HomePageDataHandler from "./HomePageDataHandler.js";
import ToolsDataHandler from "../Tools/tools_data_handler";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading.js";

const priceTierArray = {
  Tier1: 3,
  Preferred: 4,
  "Distributor Net": 5,
};
const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the home page of the website
 */
class Home extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isPinsLoaded: false,
      isDocsLoaded: false,
      isTickersLoaded: false,
      COMPARE_PRODUCT_LIMIT: 5,
      pinnedItemsList: [],
      bannerList: [],
      supportDocumentList: [],
      supportDocumentListToDisplay: [],
      tickerMessageList: [],
      resourceType: 0,
    };
    this.previousItem = this.previousItem.bind(this);
    this.previousItemToView = this.previousItemToView.bind(this);
    this.nextItem = this.nextItem.bind(this);
    this.nextItemToView = this.nextItemToView.bind(this);
    this.setUserAccess = this.setUserAccess.bind(this);
    this.getPinnedItemList = this.getPinnedItemList.bind(this);
    this.getBannersAndSupportDocsList =
      this.getBannersAndSupportDocsList.bind(this);
    this.getInventoryTickerList = this.getInventoryTickerList.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.setUserAccess();
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.handleWindowSizeChange();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    let itemCount = 5;

    if (window.innerWidth <= 1500) {
      itemCount = 4;
    }

    this.setState(
      {
        COMPARE_PRODUCT_LIMIT: itemCount,
      },
      this.createListToShow
    );
  };

  setUserAccess() {
    let role = sessionStorage.getItem("userRole");
    if (branchBasedRoles.includes(role)) {
      let username = sessionStorage.getItem("username");
      ToolsDataHandler.getPricingDetails(username)
        .then((response) => {
          if (response.success && this._isMounted) {
            if (response.data.pricingAccess) {
              this.setState(
                {
                  resourceType: priceTierArray[response.data.priceTier],
                },
                () => {
                  this.getHomeData();
                }
              );
            } else {
              this.setState(
                {
                  resourceType: 2,
                },
                () => {
                  this.getHomeData();
                }
              );
            }
          }
        })
        .catch((error) => {
          this.props.addNotification(ErrorMessages.UserResourceType, "error");
        });
    } else {
      this.getHomeData();
    }
  }

  render() {
    return this.state.isPinsLoaded &&
      this.state.isDocsLoaded &&
      this.state.isTickersLoaded
      ? this.homeDisplaySection()
      : this.homeLoadingSection();
  }

  homeDisplaySection() {
    return (
      <div className="home-container loading-fade">
        <Row>
          <Col md={12} sm={12} xs={12}>
            {this.displayInventoryTicker()}
          </Col>
        </Row>
        <Row>
          <Col md={9} sm={12} xs={12}>
            <Row className="banner-container">{this.bannersSection()}</Row>
            <br />
            <Row>
              <h3 className="support-document-header">Key Documents</h3>
              {this.supportDocumentsSection()}
            </Row>
          </Col>
          <Col md={3} sm={12} xs={12}>
            <Panel
              id="collapsible-panel"
              style={{ borderRadius: "0px" }}
              defaultExpanded
            >
              <div
                role="menu"
                id="collapsible-panel--heading"
                className="panel-heading"
              >
                <Panel.Heading role="menuitem" aria-labelledby="pinnedItems">
                  <Panel.Title>Pinned Items</Panel.Title>
                </Panel.Heading>
              </div>
              <Panel.Body>{this.pinnedItemSection()}</Panel.Body>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }

  bannersSection() {
    return (
      <Carousel className="banners">
        {this.state.bannerList.map((item, i) =>
          this.generateBannerItem(item, i)
        )}
      </Carousel>
    );
  }

  homeLoadingSection() {
    return (
      <div style={{ paddingTop: "50px" }}>
        <ContentLoading message={""} size={50} />
      </div>
    );
  }

  createListToShow() {
    this.setState({
      supportDocumentListToDisplay: this.state.supportDocumentList.slice(
        0,
        this.state.COMPARE_PRODUCT_LIMIT
      ),
    });
  }

  getPinnedItemList() {
    HomePageDataHandler.getPinnedItems()
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            pinnedItemsList: data.data,
            isPinsLoaded: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPinsLoaded: true,
        });
        this.props.addNotification(ErrorMessages.PinnedItemsFailed, "error");
      });
  }

  getBannersAndSupportDocsList() {
    HomePageDataHandler.getBannersAndSupportDocs(this.state.resourceType)
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            isDocsLoaded: true,
            bannerList: response.data.banners,
            supportDocumentList: response.data.supportingDocs,
            supportDocumentListToDisplay: response.data.supportingDocs.slice(
              0,
              this.state.COMPARE_PRODUCT_LIMIT
            ),
          });
        }
      })
      .catch((error) => {
        this.setState({
          isDocsLoaded: true,
        });
        this.props.addNotification(
          ErrorMessages.BannerAndSupportDocsFailed,
          "error"
        );
      });
  }

  getInventoryTickerList() {
    HomePageDataHandler.getInventoryTickers()
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            tickerMessageList: response.data,
            isTickersLoaded: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isTickersLoaded: true,
        });
        this.props.addNotification(
          ErrorMessages.InventoryTickerFailed,
          "error"
        );
      });
  }
  getHomeData() {
    this.getPinnedItemList();
    this.getBannersAndSupportDocsList();
    this.getInventoryTickerList();
  }

  pinnedItemSection() {
    if (this.state.pinnedItemsList.length === 0) {
      let message = !this.state.isPinsLoaded
        ? "Loading..."
        : "No Pinned Items!";
      return (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <h3>{message}</h3>
        </div>
      );
    } else {
      return (
        <table className="pinned-item-table">
          <tbody>
            {this.state.pinnedItemsList.map((item, i) =>
              this.generatePinnedItem(item, i)
            )}
          </tbody>
        </table>
      );
    }
  }
  generatePinnedItem(item, key) {
    let path = "/" + item.redirect_page;
    let data = JSON.parse(item.parameters);
    let filter = {};
    let title = item.title;
    switch (item.redirect_page) {
      case "Compare":
        filter = {
          pinned: true,
          selectedLightingSpaces: data.selectedLightingSpaces,
          selectedApplications: data.selectedApplications,
          selectedLightSource: data.selectedLightSource,
          watts: data.watts,
          lumen: data.lumen,
          minWattage: data.minWattage,
        };
        break;
      case "Product":
        filter = {
          pinned: true,
          postId: data.postId,
          applicationType: this.props.applicationType,
        };
        break;
      case "Certification":
        filter = {
          pinned: true,
          selectedApplicationType: data.selectedApplicationType,
          selectedProductName: data.selectedProductName,
          selectedWattage: data.selectedWattage,
          selectedVoltage: data.selectedVoltage,
          selectedColorTemperature: data.selectedColorTemperature,
          selectedBeam: data.selectedBeam,
        };
        break;
      case "PinnedOrderSummary":
        filter = {
          pinned: true,
          orderSummaryData: data.orderSummaryData,
          title: title,
          name: data.name,
          date: data.date,
          email: data.email,
          customerInfo: data.customerInfo,
          customer: data.customer,
          applicationType: this.props.applicationType,
          marginPercent: data.marginPercent,
          notesText: data.notesText,
          shippingAddress: data.address,
          orderDetails: data.orderDetails,
        };
        break;
      default:
        break;
    }
    return (
      <tr className="pinned-item-block" key={key}>
        <LinkContainer to={{ pathname: path, state: filter }}>
          <td
            className="pinned-item-name-cell"
            onClick={() => this.trackPinnedItemClick(item)}
          >
            <h5 className="word-wraptext">{item.title}</h5>
          </td>
        </LinkContainer>
        <td className="pinned-item-delete-cell">
          <Button
            bsStyle="primary"
            bsSize="xsmall"
            className="remove-btn button-blue"
            onClick={() => this.removePin(item)}
          >
            Unpin
          </Button>
        </td>
      </tr>
    );
  }

  removePin(item) {
    let id = item.id;
    HomePageDataHandler.deletePinnedItem(id)
      .then((data) => {
        this.getPinnedItemList();
        this.trackUnpinItemClick(item);
        this.props.addNotification(DisplayMessages.Home_PinRemoved, "info");
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.PinRemoval, "error");
      });
  }

  generateBannerItem(item, key) {
    let divStyle = {
      backgroundImage: "url(" + item.filePath.replace("\\", "/") + ")",
    };
    return (
      <Carousel.Item key={key} className="banner-item">
        <div className="banner-image-div" style={divStyle} />
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => this.trackBannerClick(item)}
        >
          <Carousel.Caption>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </a>
      </Carousel.Item>
    );
  }

  generateSupportDocItem(item, key) {
    let extension = item.filePath.slice(
      item.filePath.lastIndexOf(".") + 1,
      item.filePath.length
    );
    let imageSource = this.getDocumentTypeIcon(extension);

    let button;
    if (extension === "pdf" || extension === "PDF") {
      button = (
        <Button
          href={item.filePath}
          target="_blank"
          className="document-button"
          onClick={() => this.trackKeyDocumentClick(item)}
        >
          <h5 className="document-name">{item.title}</h5>
          <img height={120} width={120} src={imageSource} alt="support docs" />
        </Button>
      );
    } else {
      button = (
        <Button
          href={item.filePath}
          className="document-button"
          onClick={() => this.trackKeyDocumentClick(item)}
        >
          <h5 className="document-name">{item.title}</h5>
          <img height={120} width={120} src={imageSource} alt="support docs" />
        </Button>
      );
    }
    return (
      <td className="document-container" key={key}>
        {button}
      </td>
    );
  }

  supportDocumentsSection() {
    if (
      !this.state.supportDocumentListToDisplay ||
      this.state.supportDocumentListToDisplay.length <= 0
    ) {
      let message = !this.state.isDocsLoaded
        ? DisplayMessages.Home_SupportDocsLoading
        : DisplayMessages.Home_NoSupportDocuments;
      return (
        <div className="support-document-container">
          <Grid>
            <h4 className="text-message">{message}</h4>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className="support-document-container">
          <table>
            <tbody>
              <tr>
                <td className="support-document-navigation-cell">
                  <Pager className={this.showDocumentNavigation()}>
                    <Pager.Item
                      previous
                      onClick={this.previousItem}
                      className="pager"
                    >
                      <span className="glyphicon glyphicon-chevron-left" />
                    </Pager.Item>
                  </Pager>
                </td>
                <td>
                  <Table>
                    <tbody>
                      <tr>
                        {this.state.supportDocumentListToDisplay.map(
                          (item, i) => this.generateSupportDocItem(item, i)
                        )}
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td className="support-document-navigation-cell">
                  <Pager className={this.showDocumentNavigation()}>
                    <Pager.Item next onClick={this.nextItem} className="pager">
                      <span className="glyphicon glyphicon-chevron-right" />
                    </Pager.Item>
                  </Pager>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
  showDocumentNavigation() {
    if (
      this.state.supportDocumentList &&
      this.state.supportDocumentList.length > this.state.COMPARE_PRODUCT_LIMIT
    ) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }
  nextItemToView() {
    let temp_itemListToDisplay = this.state.supportDocumentListToDisplay.map(
      (item) => item
    );
    let lastItem = temp_itemListToDisplay.pop();
    let index = this.state.supportDocumentList.indexOf(lastItem) + 1;
    return this.state.supportDocumentList[index];
  }
  nextItem() {
    let temp_itemsToDisplay = this.state.supportDocumentListToDisplay.map(
      (_item) => _item
    );
    let item = this.nextItemToView();
    if (item) {
      temp_itemsToDisplay.shift();
      temp_itemsToDisplay.push(item);
    }
    this.trackKeyDocumentsNavigationButtonClick("Next");

    this.setState({
      supportDocumentListToDisplay: temp_itemsToDisplay,
    });
  }
  previousItemToView() {
    let temp_itemsToDisplay = this.state.supportDocumentListToDisplay.map(
      (item) => item
    );
    temp_itemsToDisplay = temp_itemsToDisplay.reverse();
    let firstProduct = temp_itemsToDisplay.pop();
    let index = this.state.supportDocumentList.indexOf(firstProduct) - 1;
    return this.state.supportDocumentList[index];
  }
  previousItem() {
    let temp_itemsToDisplay = this.state.supportDocumentListToDisplay.map(
      (_item) => _item
    );
    let item = this.previousItemToView();
    if (item) {
      temp_itemsToDisplay.pop();
      temp_itemsToDisplay.unshift(item);
    }
    this.trackKeyDocumentsNavigationButtonClick("Previous");
    this.setState({
      supportDocumentListToDisplay: temp_itemsToDisplay,
    });
  }

  getDocumentTypeIcon(fileExtension) {
    switch (fileExtension) {
      case "pdf":
        return fetchResourceData(fixedLink.pdfIcon);
      case "doc":
      case "docx":
        return fetchResourceData(fixedLink.wordIcon);
      case "xls":
      case "xlsx":
        return fetchResourceData(fixedLink.excelIcon);
      case "ppt":
      case "pptx":
        return fetchResourceData(fixedLink.powerpointIcon);
      case "png":
      case "jpg":
        return fetchResourceData(fixedLink.imageIcon);
      default:
        return fetchResourceData(fixedLink.textIcon);
    }
  }

  displayInventoryTicker() {
    if (this.state.isTickersLoaded) {
      let message = this.state.tickerMessageList
        .map((_msg) => _msg.ticker_message)
        .join("\t");
      return (
        <Ticker offset="1" mode="chain">
          {() => (
            <h4 style={{ whiteSpace: "nowrap", margin: "10px" }}>
              {DisplayMessages.Home_InventoryTickerLine1}
              {message}
              {DisplayMessages.Home_InventoryTickerLine2}
            </h4>
          )}
        </Ticker>
      );
    } else {
      return <span />;
    }
  }

  // Mixpanel Tracking //

  trackPinnedItemClick(item) {
    mixpanel.track("Pinned Item", {
      Action: "Button Click",
      Effect: "Redirected to corresponding pinned page",
      "Pinned Item Title": item.title,
      "Pinned Item ID": item.id,
    });
  }

  trackUnpinItemClick(item) {
    mixpanel.track("Unpin Item", {
      Action: "Button Click",
      Effect: "Corresponding item is unpinned",
      "Unpinned Item Title": item.title,
      "Unpinned Item ID": item.id,
    });
  }

  trackBannerClick(item) {
    mixpanel.track("Banner Select", {
      Action: "Element Click",
      Effect: "Redirected to corresponding banner page",
      "Banner Link": item.link,
      "Banner Title": item.title,
      "Banner Description": item.description,
    });
  }

  trackKeyDocumentClick(item) {
    mixpanel.track("Key Document", {
      Action: "Element Click",
      Effect: "Display key document in same tab or new tab",
      "Item ID": item.id,
      "Item Title": item.title,
      "Item Filename": item.file_name,
    });
  }

  trackKeyDocumentsNavigationButtonClick(direction) {
    mixpanel.track("Key Documents Navigation", {
      Action: "Button Click",
      Effect: `${direction} key document displayed`,
      Direction: direction,
    });
  }
}

export default Home;
