import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { ButtonToolbar } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "../UserManagement/UserManagement.css";
import DistributorsHandler from "./DistributorsHandler";
import "./distributors.css";
import DistributorConfirmationPopup from "../ConfirmationPopUp/DistributorConfirmationPopup";
import BranchOverviewHandler from "./BranchOverviewHandler";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";

/**
 * Component represents the Add new distributor user popup
 */
class AddDistributorUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      selectedRole: "Distributor User",
      selectedOrderTracking: true,
      selectedPricingAccess: true,
      selectedTldVisibility: false,
      selectedBranch: this.props.selectedBranch,
      branchName: this.props.selectedBranch.branchName,
      branchId: this.props.selectedBranch.branchId,
      emailid: "",
      popupEmail: "",
      phone: "",
      jobTitle: "",
      currentUser: "",
      username: "",
      confirmationPopup: false,
      popupName: "",
      emailValidationMessage:
        DisplayMessages.AddDistributorUser_UsernameSubtext,
      emailValidity: true,
      csr: null,
    };
    this._notificationSystem = null;
    this.handleOnClose = this.handleOnClose.bind(this);
    this.create = this.create.bind(this);
    this.cancel = this.cancel.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.reset = this.reset.bind(this);
    this.setEmailId = this.setEmailId.bind(this);
    this.checkEmailId = this.checkEmailId.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.setJobTitle = this.setJobTitle.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.sendMailToUser = this.sendMailToUser.bind(this);
    this.sendMailToCSR = this.sendMailToCSR.bind(this);
    this.setPricingAccess = this.setPricingAccess.bind(this);
    this.setOrderTracking = this.setOrderTracking.bind(this);
    this.setTldVisibility = this.setTldVisibility.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.getBranchDetails = this.getBranchDetails.bind(this);
    this.defaultEmailValidator = this.defaultEmailValidator.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedBranch: nextProps.selectedBranch,
      branchName: nextProps.selectedBranch.branchName,
      branchId: nextProps.selectedBranch.branchId,
    });
  }

  componentDidMount() {
    let username = sessionStorage.getItem("username");
    ConfigHandler.getDisplayName()
      .then((response) => {
        if (response.success) {
          this.setState({
            currentUser: response.message,
            username: username,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.AddDistributorUser_CurrentUser,
          "error"
        );
      });
    this.getBranchDetails(this.state.branchId);
  }

  getBranchDetails(branchId) {
    BranchOverviewHandler.getBranchDetails(branchId)
      .then((response) => {
        if (response.success) {
          this.setState({
            csr: response.data.csr,
          });
        } else {
          this.setState({
            csr: null,
          });
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.AddDistributorUser_BranchDetails,
          "error"
        );
      });
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleOnClose}
          className="add-user-modal"
        >
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              Add New User
            </h3>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <h4 style={{ fontWeight: "bold" }}>
                  Username/Email<mark>*</mark>
                </h4>
              </Col>
            </Row>
            <Row>{this.defaultEmailValidator()}</Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <Row>
                  <h4 style={{ fontWeight: "bold" }}>
                    First Name<mark>*</mark>
                  </h4>
                </Row>
                <Row>
                  <FormGroup>
                    <FormControl
                      style={{ color: "black" }}
                      type="text"
                      maxLength={50}
                      value={this.state.firstName}
                      placeholder="Enter First Name"
                      onChange={this.setFirstName}
                    />
                  </FormGroup>
                </Row>
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Row>
                  <h4 style={{ fontWeight: "bold" }}>
                    Last Name<mark>*</mark>
                  </h4>
                </Row>
                <Row>
                  <FormGroup>
                    <FormControl
                      type="text"
                      style={{ color: "black" }}
                      maxLength={50}
                      value={this.state.lastName}
                      placeholder="Enter Last Name"
                      onChange={this.setLastName}
                    />
                  </FormGroup>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <h4 style={{ fontWeight: "bold" }}>
                  Title<mark>*</mark>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.jobTitle}
                    placeholder="Enter Job Title"
                    onChange={this.setJobTitle}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <h4 style={{ fontWeight: "bold" }}>Phone Number (optional)</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={20}
                    value={this.state.phone}
                    placeholder="Enter Phone Number"
                    onChange={this.setPhoneNumber}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Col md={4} sm={6} xs={6}>
                  <Row>
                    <h4 style={{ fontWeight: "bold" }}>Pricing Access</h4>
                    <span>{DisplayMessages.PricingSubtext}</span>
                  </Row>
                  <br />
                  <Row>
                    <label
                      className="switch-dist"
                      onChange={this.setPricingAccess}
                      htmlFor="add-pricing-access"
                    >
                      <input
                        id="add-pricing-access"
                        type="checkbox"
                        defaultChecked={this.state.selectedPricingAccess}
                      />
                      <span className="slider-dist round" />
                    </label>
                  </Row>
                </Col>
                <Col md={4} sm={6} xs={6}>
                  <Row>
                    <h4 style={{ fontWeight: "bold" }}>Order Tracking</h4>
                    <span>{DisplayMessages.TrackingSubtext}</span>
                  </Row>
                  <br />
                  <Row>
                    <label
                      className="switch-dist"
                      onChange={this.setOrderTracking}
                      htmlFor="add-order-track"
                    >
                      <input
                        id="add-order-track"
                        type="checkbox"
                        defaultChecked={this.state.selectedOrderTracking}
                      />
                      <span className="slider-dist round" />
                    </label>
                  </Row>
                </Col>
              </Col>
            </Row>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <ButtonToolbar>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    style={{ width: "100%", marginTop: "10px" }}
                    onClick={this.create}
                    className="button-blue"
                  >
                    Confirm
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    style={{ width: "100%", marginTop: "10px" }}
                    onClick={this.cancel}
                    className="button-white"
                  >
                    Cancel
                  </Button>
                </Col>
              </ButtonToolbar>
            </Row>
          </Modal.Footer>
        </Modal>
        <DistributorConfirmationPopup
          type={"add"}
          show={this.state.confirmationPopup}
          popupName={this.state.popupName}
          email={this.state.popupEmail}
          branchName={this.state.branchName}
          handleConfirmation={this.handleConfirmation}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  defaultEmailValidator() {
    return (
      <div>
        <Col md={6} sm={12} xs={12}>
          <FormGroup>
            <FormControl
              type="text"
              id="emailInput"
              className="inputText"
              maxLength={50}
              value={this.state.emailid}
              placeholder="Enter Email ID"
              onChange={this.setEmailId}
              onBlur={this.checkEmailId}
            />
            <span>{this.state.emailValidationMessage}</span>
          </FormGroup>
        </Col>
      </div>
    );
  }

  handleConfirmation() {
    this.setState({
      confirmationPopup: false,
    });
  }

  setJobTitle(e) {
    this.setState({
      jobTitle: e.target.value,
    });
  }

  cancel() {
    this.trackCancelButtonClick();
    this.handleOnClose();
  }

  create() {
    let pattern = Miscellaneous.RegexEmailId;
    const re = new RegExp(pattern, "i");
    if (this.state.firstName === null || this.state.firstName.trim() === "") {
      this.trackDistributorUserCreateFailedClick(
        ErrorMessages.AddDistributorUser_FirstName
      );
      this.addNotification(
        ErrorMessages.AddDistributorUser_FirstName,
        "warning"
      );
      return;
    }
    if (this.state.lastName === null || this.state.lastName.trim() === "") {
      this.trackDistributorUserCreateFailedClick(
        ErrorMessages.AddDistributorUser_LastName
      );
      this.addNotification(
        ErrorMessages.AddDistributorUser_LastName,
        "warning"
      );
      return;
    }
    if (this.state.emailid === null || this.state.emailid.trim() === "") {
      this.trackDistributorUserCreateFailedClick(
        ErrorMessages.AddDistributorUser_Email
      );
      this.addNotification(ErrorMessages.AddDistributorUser_Email, "warning");
      return;
    }
    if (!re.test(this.state.emailid)) {
      this.trackDistributorUserCreateFailedClick(
        ErrorMessages.AddDistributorUser_ValidEmail
      );
      this.addNotification(
        ErrorMessages.AddDistributorUser_ValidEmail,
        "warning"
      );
      return;
    }
    if (!this.state.emailValidity) {
      this.trackDistributorUserCreateFailedClick(
        ErrorMessages.AddDistributorUser_EnterValidDomain
      );
      this.addNotification(
        ErrorMessages.AddDistributorUser_EnterValidDomain,
        "warning"
      );
      return;
    }
    if (this.state.jobTitle === null || this.state.jobTitle.trim() === "") {
      this.trackDistributorUserCreateFailedClick(
        ErrorMessages.AddDistributorUser_JobTitle
      );
      this.addNotification(
        ErrorMessages.AddDistributorUser_JobTitle,
        "warning"
      );
      return;
    }

    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.emailid.trim(),
      phoneNumber: this.state.phone,
      role: this.state.selectedRole,
      jobTitle: this.state.jobTitle,
      branch: this.state.branchId,
      orderTracking: this.state.selectedOrderTracking ? "yes" : "no",
      pricingAccess: this.state.selectedPricingAccess ? "yes" : "no",
      tldVisibility: this.state.selectedTldVisibility ? "yes" : "no",
      currentUser: this.state.currentUser,
    };
    this.setState((prevState) => ({
      popupName: prevState.firstName + " " + prevState.lastName,
      popupEmail: prevState.emailid,
    }));
    DistributorsHandler.addDistributorUser(data)
      .then((response) => {
        if (response.success) {
          this.sendMail("user");
          if (this.state.csr) {
            this.sendMail("CSR");
          } else {
            this.addNotification(
              ErrorMessages.AddDistributorUser_NoCSRForBranch,
              "warning"
            );
          }

          this.trackDistributorUserCreateButtonClick(data);
        } else {
          this.trackDistributorUserCreateFailedClick(response.message);
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.trackDistributorUserCreateFailedClick(
          ErrorMessages.AddDistributorUser_FailedUser
        );
        this.addNotification(
          ErrorMessages.AddDistributorUser_FailedUser,
          "error"
        );
      });
  }

  sendMail(person) {
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    let isCreatedByAdmin = userObj["role"] === "360 Admin";
    person === "user"
      ? this.sendMailToUser(person, isCreatedByAdmin)
      : this.sendMailToCSR(person, isCreatedByAdmin);
  }

  sendMailToUser(person, isCreatedByAdmin) {
    let userData = {
      mailType: person,
      isCreatedByAdmin: isCreatedByAdmin,
      displayName: this.state.firstName + " " + this.state.lastName,
      currentUser: this.state.currentUser,
      branchName: this.state.branchName,
      emailid: this.state.emailid,
    };

    DistributorsHandler.sendMail(userData).then((response) => {
      if (response.success) {
        this.setState({
          confirmationPopup: true,
        });
        this.handleOnClose();
      } else {
        this.addNotification(response.message, "error");
      }
    });
  }

  sendMailToCSR(person, isCreatedByAdmin) {
    let csrData = {
      mailType: person,
      isCreatedByAdmin: isCreatedByAdmin,
      branchName: this.state.branchName,
      branchAdminName: this.state.currentUser,
      branchAdminEmail: this.state.username,
      CSREmail: this.state.csr.Email,
      distributorName: this.state.firstName + " " + this.state.lastName,
      distributorEmail: this.state.emailid,
      jobTitle: this.state.jobTitle,
      phoneNumber: this.state.phone.length === 0 ? "NA" : this.state.phone,
      pricingAccess: this.state.selectedPricingAccess ? "Yes" : "No",
      orderTracking: this.state.selectedOrderTracking ? "Yes" : "No",
      tldVisibility: this.state.selectedTldVisibility ? "Yes" : "No",
    };

    DistributorsHandler.sendMail(csrData).then((response) => {
      if (response.success) {
        this.addNotification(
          DisplayMessages.AddDistributorUser_CsrNotified,
          "info"
        );
      } else {
        this.addNotification(response.message, "error");
      }
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }
  checkEmailId(e) {
    let emailValidity = true;
    let emailid = e.target.value.toString().trim();
    let pattern = Miscellaneous.RegexEmailId;
    const re = new RegExp(pattern, "i");
    let emailInput = document.getElementById("emailInput");
    let validationMessage;
    if (emailid) {
      if (re.test(emailid)) {
        let emailArray = emailid.split("@");
        let domain = emailArray[1];
        if (
          this.state.selectedBranch.emailDomain.toString() !== "" &&
          domain.toString() !==
            this.state.selectedBranch.emailDomain.toString().trim()
        ) {
          emailInput.classList.remove("valid-input");
          emailInput.classList.add("invalid-input");
          validationMessage =
            ErrorMessages.AddDistributorUser_DomainValidationPrefix +
            this.state.selectedBranch.emailDomain.toString().trim() +
            ErrorMessages.AddDistributorUser_DomainValidationSuffix;
          emailValidity = false;
        } else {
          emailInput.classList.remove("invalid-input");
          emailInput.classList.add("valid-input");
          validationMessage =
            DisplayMessages.AddDistributorUser_UsernameSubtext;
        }
      } else {
        emailInput.classList.remove("valid-input");
        emailInput.classList.add("invalid-input");
        validationMessage = ErrorMessages.AddDistributorUser_ValidEmail;
        emailValidity = false;
      }
    } else {
      emailInput.classList.remove("invalid-input");
      emailInput.classList.remove("valid-input");
      validationMessage = DisplayMessages.AddDistributorUser_UsernameSubtext;
    }
    this.setState({
      emailValidationMessage: validationMessage,
      emailValidity: emailValidity,
    });
  }

  setEmailId(e) {
    this.setState({
      emailid: e.target.value.trim(),
    });
  }

  setPhoneNumber(e) {
    let phone = e.target.value;
    this.setState({
      phone: phone,
    });
  }

  setOrderTracking() {
    this.setState((prevState) => ({
      selectedOrderTracking: !prevState.selectedOrderTracking,
    }));
  }

  setTldVisibility() {
    this.setState((prevState) => ({
      selectedTldVisibility: !prevState.selectedTldVisibility,
    }));
  }

  setPricingAccess() {
    this.setState((prevState) => ({
      selectedPricingAccess: !prevState.selectedPricingAccess,
    }));
  }

  reset() {
    this.setState({
      firstName: "",
      lastName: "",
      selectedOrderTracking: true,
      selectedPricingAccess: true,
      selectedTldVisibility: false,
      emailid: "",
      phone: "",
      jobTitle: "",
      emailValidationMessage:
        DisplayMessages.AddDistributorUser_UsernameSubtext,
    });
    let emailInput = document.getElementById("emailInput");
    emailInput.classList.remove("invalid-input");
    emailInput.classList.remove("valid-input");
  }

  handleOnClose() {
    this.reset();
    this.props.handleOnClose();
  }

  setFirstName(e) {
    let firstName = e.target.value;
    firstName = firstName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState({
      firstName: firstName,
    });
  }

  setLastName(e) {
    let lastName = e.target.value;
    lastName = lastName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState({
      lastName: lastName,
    });
  }

  // Mixpanel Tracking //

  trackDistributorUserCreateButtonClick(data) {
    mixpanel.track("Create Distributor User Confirmation", {
      Action: "Button Click",
      Effect: "New distributor user created",
      "First Name": data.firstName,
      "Last Name": data.lastName,
      Email: data.email,
      "Phone Number": data.phoneNumber,
      "Job Role": data.role,
      "Job Title": data.jobTitle,
      Branch: data.branch,
      "TLD Visibility": data.tldVisibility,
      "Order Tracking": data.orderTracking,
      "Pricing Access": data.pricingAccess,
    });
  }

  trackDistributorUserCreateFailedClick(message) {
    mixpanel.track("Create Distributor User", {
      Action: "Button Click",
      Effect: "New distributor user creation failed",
      Message: message,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Create Distributor User Cancellation", {
      Action: "Button Click",
      Effect: "New distributor user creation cancelled",
    });
  }
}
export default AddDistributorUser;
