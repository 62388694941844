import React, { Component } from "react";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import ProductListPopup from "../ProductListPopup/ProductListPopup.js";
import AttachmentListPopup from "../AttachmentListPopup/AttachmentListPopup";
import ContentUsageTrend from "../ContentUsageTrend/ContentUsageTrend.js";
import ManageHomePage from "../ManageHomePage/ManageHomePage.js";
import ManageProductConfigurator from "../ManageProductConfigurator/ManageProductConfigurator.js";
import "./content.css";
import ContentDataHandler from "./ContentDataHandler.js";
import UserManagement from "../UserManagement/UserManagement.js";
import DistributorManagement from "../DistributorManagement/DistributorManagement.js";
import ToolsDataHandler from "../Tools/tools_data_handler.js";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

const documentNames = [
  "Compare Wizard Image",
  "DLC Sheet",
  "Compare Wizard Sheet",
  "Salesperson Mapping Document",
  "Tableau Mapping Document",
  "Clearance/Quick Ship Document",
];
const priceTierArray = {
  Tier1: 3,
  Preferred: 4,
  "Distributor Net": 5,
};
const resourceTypeArray = {
  general: 1,
  noPricing: 2,
  tier1: 3,
  preferred: 4,
  distributorNet: 5,
  salesrepOnly: 6,
};
const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Component represents the Content page
 */
class Content extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.userRoles = [this.props.role];
    this.state = {
      docType: [],
      quickStartGuideList: [],
      files: "",
      productName: "",
      selectedProduct: undefined,
      selectedDocType: undefined,
      selectedLanguage: "",
      existingLink: "",
      selectedLink: "",
      attachments: [],
      showProductListPopup: false,
      showAttachmentListPopup: false,
      resourceType: 1,
      resourceAccess: 0,
      allowResourceButtons: true,
      contactInfo: sessionStorage.getItem("salesAgencyContact"),
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.upload = this.upload.bind(this);
    this.linkUpdate = this.linkUpdate.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDocTypeChange = this.handleDocTypeChange.bind(this);
    this.handleProductNameChange = this.handleProductNameChange.bind(this);
    this.search = this.search.bind(this);
    this.handleOnProductListPopupClose =
      this.handleOnProductListPopupClose.bind(this);
    this.handleProductSelect = this.handleProductSelect.bind(this);
    this.handleOnAttachmentsListPopupClose =
      this.handleOnAttachmentsListPopupClose.bind(this);
    this.viewAttachments = this.viewAttachments.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.ViewEnabled = this.ViewEnabled.bind(this);
    this.setUserAccess = this.setUserAccess.bind(this);
    this.ResourceTypeChanged = this.ResourceTypeChanged.bind(this);
    this.ResourceTypeRadioButtons = this.ResourceTypeRadioButtons.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleQuickStartLinkChange =
      this.handleQuickStartLinkChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.updateContact = this.updateContact.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUserAccess();
    this.getDocTypes();
    this.getQuickStartGuide();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setUserAccess() {
    if (branchBasedRoles.includes(this.userRoles)) {
      let username = sessionStorage.getItem("username");
      ToolsDataHandler.getPricingDetails(username)
        .then((response) => {
          if (response.success && this._isMounted) {
            if (response.data.pricingAccess) {
              this.setState({
                resourceAccess: priceTierArray[response.data.priceTier],
              });
            } else {
              this.setState({
                resourceAccess: 2,
              });
            }
          }
        })
        .catch((error) => {
          this.props.addNotification(
            ErrorMessages.Content_ResourceType,
            "error"
          );
        });
    }
  }

  ViewEnabled() {
    if (this.state.selectedDocType) {
      let selected = this.state.selectedDocType.document_type;
      let isDocumentNameIncluded = false;
      if (documentNames.includes(selected)) {
        isDocumentNameIncluded = true;
      }
      return isDocumentNameIncluded;
    }
  }

  render() {
    return (
      <Tabs
        defaultActiveKey={1}
        id="uncontrolled-tab-example"
        className="content-tabs loading-fade"
        onSelect={(e) => this.trackSubtabNavigation(e)}
      >
        <Tab eventKey={1} title="Manage Content">
          <div className="manage-content-container">
            <Row>
              <Col md={6} sm={6} xs={12}>
                {this.uploadDocumentSection()}
              </Col>
              <Col md={6} sm={6} xs={12}>
                {this.quickStartGuideSection()}
                <Row>
                  <hr className="line-separator" />
                </Row>
                {this.salesAgencyContactSection()}
              </Col>
            </Row>
          </div>
          <ProductListPopup
            show={this.state.showProductListPopup}
            searchString={this.state.searchString}
            handleOnClose={this.handleOnProductListPopupClose}
            handleProductSelect={this.handleProductSelect}
            addNotification={this.props.addNotification}
          />
          <AttachmentListPopup
            show={this.state.showAttachmentListPopup}
            documentName={this.state.selectedDocType}
            handleOnClose={this.handleOnAttachmentsListPopupClose}
            attachments={this.state.attachments}
            addNotification={this.props.addNotification}
            handleDeleteAttachment={this.handleDeleteAttachment}
          />
        </Tab>
        <Tab eventKey={2} title="View Content Usage">
          <ContentUsageTrend addNotification={this.props.addNotification} />
        </Tab>
        <Tab eventKey={3} title="Manage Home Page">
          <ManageHomePage
            addNotification={this.props.addNotification}
            resourceAccess={this.state.resourceAccess}
          />
        </Tab>
        <Tab eventKey={4} title="Product Configurator Data">
          <ManageProductConfigurator
            addNotification={this.props.addNotification}
          />
        </Tab>
        <Tab eventKey={5} title="Distributor Management">
          <DistributorManagement addNotification={this.props.addNotification} />
        </Tab>
        <Tab eventKey={6} title="User Management">
          <UserManagement addNotification={this.props.addNotification} />
        </Tab>
      </Tabs>
    );
  }

  ResourceTypeRadioButtons() {
    if (this.state.allowResourceButtons) {
      return (
        <div>
          <h4>
            Choose resource type<mark>*</mark>
          </h4>
          <div style={{ fontSize: "15px" }}>
            <Row>
              <Col md={4} sm={6} xs={12}>
                <label>
                  <input
                    type="radio"
                    value="general"
                    name="resourceType"
                    defaultChecked
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>General Resource</strong>
                </label>
              </Col>
              <Col md={4} sm={6} xs={12}>
                <label>
                  <input
                    type="radio"
                    value="tier1"
                    name="resourceType"
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Tier1 Resource</strong>
                </label>
              </Col>
              <Col
                md={4}
                sm={6}
                xs={12}
                hidden={!Miscellaneous.OtherTiersEnabled}
              >
                <label>
                  <input
                    type="radio"
                    value="distributorNet"
                    name="resourceType"
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Distributor Net Resource</strong>
                </label>
              </Col>
            </Row>
            <Row>
              <Col
                md={4}
                sm={6}
                xs={12}
                hidden={!Miscellaneous.OtherTiersEnabled}
              >
                <label>
                  <input
                    type="radio"
                    value="preferred"
                    name="resourceType"
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Preferred Resource</strong>
                </label>
              </Col>
              <Col md={4} sm={6} xs={12}>
                <label>
                  <input
                    type="radio"
                    value="noPricing"
                    name="resourceType"
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>No-Pricing Resource</strong>
                </label>
              </Col>
              <Col md={4} sm={6} xs={12}>
                <label>
                  <input
                    type="radio"
                    value="salesrepOnly"
                    name="resourceType"
                    onChange={this.ResourceTypeChanged}
                  />{" "}
                  <strong>Salesrep Only Resource</strong>
                </label>
              </Col>
            </Row>
          </div>
          <br />
        </div>
      );
    } else {
      return null;
    }
  }

  generateProductDescription() {
    if (this.state.selectedProduct) {
      return (
        <Row>
          <Col md={6}>
            <Row>
              <img
                src={this.state.selectedProduct.featured_src}
                alt="product img"
                style={{ maxWidth: "130px" }}
              />
            </Row>
          </Col>
          <Col md={6}>
            <h2>{this.state.selectedProduct.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.selectedProduct.short_description,
              }}
            />
            <hr />
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  salesAgencyContactSection() {
    return (
      <div>
        <Row>
          <h3 style={{ paddingLeft: "15px" }}>Sales Agency Contact</h3>
        </Row>
        <Row>
          <h4 style={{ padding: "0px 15px" }}>Sales Agency Contact Link</h4>
          <Col md={4} sm={4} xs={4}>
            <FormControl
              type="text"
              maxLength={20}
              value={this.state.contactInfo}
              placeholder="Enter Sales Agency Contact"
              onChange={this.handleContactChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={2} sm={2} xs={2}>
            <Button
              bsStyle="primary"
              onClick={this.updateContact}
              className="button-blue"
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  handleContactChange(e) {
    let contact = e.target.value;
    this.setState({
      contactInfo: contact,
    });
  }

  updateContact() {
    let data = { phoneNumber: this.state.contactInfo };

    this.trackSalesAgencyContactUpdateButtonClick();
    ContentDataHandler.updateContactInfo(data).then((response) => {
      if (response.success) {
        sessionStorage.removeItem("salesAgencyContact");
        sessionStorage.setItem("salesAgencyContact", data.phoneNumber);
        this.props.addNotification(
          DisplayMessages.Content_ContactInfoUpdated,
          "info"
        );
      } else {
        this.props.addNotification(
          DisplayMessages.Content_ContactInfoUpdateFailed,
          "error"
        );
      }
    });
  }

  quickStartGuideSection() {
    return (
      <div>
        <Row>
          <h3 style={{ paddingLeft: "15px" }}>Quick Start Guide</h3>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <h4>Select Language</h4>
            <Select
              menuPlacement="auto"
              maxMenuHeight={190}
              isSearchable
              value={{
                value: this.state.selectedLanguage,
                label: this.state.selectedLanguage,
              }}
              styles={customStyles}
              aria-label="Select Language"
              onChange={this.handleLanguageChange}
              options={this.generateLanguageOptions()}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
          <Col md={8} sm={8} xs={8}>
            <h4>Quick Start Guide Link</h4>
            <FormControl
              type="text"
              maxLength={500}
              value={this.state.selectedLink}
              placeholder="Enter Quick Start Guide Link"
              onChange={this.handleQuickStartLinkChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={2} sm={2} xs={2}>
            <Button
              bsStyle="primary"
              disabled={this.disableLinkUpdate()}
              onClick={this.linkUpdate}
              className="button-blue"
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  uploadDocumentSection() {
    return (
      <div>
        <Row>
          <h3>Add New Documents</h3>
        </Row>
        <Row>
          <form>
            <h4>Select a document type</h4>
            <Row>
              <Col md={6} style={{ padding: "0" }}>
                <Select
                  maxMenuHeight={180}
                  menuPlacement="auto"
                  value={{
                    value: this.state.selectedDocType,
                    label:
                      this.state.selectedDocType !== undefined
                        ? this.state.selectedDocType.document_type
                        : "Select a document type",
                  }}
                  aria-label="Select Document Type"
                  styles={customStyles}
                  onChange={this.handleDocTypeChange}
                  options={this.generateDocTypeOptions()}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "lightgray",
                    },
                  })}
                />
              </Col>
            </Row>
            <br />
            <h4 className={this.showSearchProducts()}>
              Search for product by product name<mark>*</mark>
            </h4>
            <FormGroup className={this.showSearchProducts()}>
              <Row>
                <Col md={6} xs={8} sm={8} style={{ padding: "0px" }}>
                  <FormControl
                    type="text"
                    value={this.state.productName}
                    placeholder="Enter product name"
                    onChange={this.handleProductNameChange}
                    onKeyDown={this.handleKeyPress}
                  />
                </Col>
                <Col md={1} xs={2} sm={2} style={{ paddingRight: "0px" }}>
                  <Button
                    bsStyle="primary"
                    className="glyphicon glyphicon-search button-blue"
                    style={{ float: "right" }}
                    onClick={this.search}
                  />
                </Col>
              </Row>
              <FormControl.Feedback />
              <br />
            </FormGroup>

            {this.ResourceTypeRadioButtons()}
            <h4>
              Choose a file to upload<mark>*</mark>
            </h4>
            <FormControl
              id="manageContentFile"
              type="file"
              label="File"
              help=""
              onChange={this.handleFileChange}
              aria-label="File"
            />
            <br />
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                onClick={this.upload}
                className="button-blue"
              >
                Upload
              </Button>
              <Button
                bsStyle="primary"
                onClick={() => {
                  this.trackUploadDocumentCancelButtonClick();
                  this.cancel();
                }}
                className="button-blue"
              >
                Cancel
              </Button>
              <Button
                bsStyle="primary"
                onClick={this.viewAttachments}
                disabled={this.ViewEnabled()}
                className="button-blue"
              >
                View Attachments
              </Button>
            </ButtonToolbar>
          </form>
          {this.generateProductDescription()}
        </Row>
      </div>
    );
  }

  getDocTypes() {
    ContentDataHandler.getDocTypes("DocType")
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState((prevState) => ({
            docType: response.data,
            selectedDocType: response.data[0],
            allowResourceButtons: !documentNames.includes(
              response.data[0].document_type
            ),
            resourceType: documentNames.includes(response.data[0].document_type)
              ? 1
              : prevState.resourceType,
          }));
        }
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.Content_DocTypes, "error");
      });
  }

  getQuickStartGuide() {
    ContentDataHandler.getQuickStartGuide()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            quickStartGuideList: response.data,
            selectedLanguage: response.data[0].Language,
            selectedLink: response.data[0].Link,
            existingLink: response.data[0].Link,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification(
          ErrorMessages.Content_QuickStartGuide,
          "error"
        );
      });
  }

  getAttachments() {
    ContentDataHandler.getAttachments(
      this.state.productName,
      this.state.selectedDocType,
      this.isSelectedDoctypeProductSpecific(),
      this.state.resourceAccess
    )
      .then((attachments) => {
        this.setState({
          attachments: attachments,
          showAttachmentListPopup: true,
        });
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.Content_Attachments, "error");
      });
  }

  showSearchProducts() {
    if (
      this.state.selectedDocType !== undefined &&
      this.isSelectedDoctypeProductSpecific()
    ) {
      return "show-component";
    } else {
      return "hide-component";
    }
  }

  isSelectedDoctypeProductSpecific() {
    if (this.state.selectedDocType) {
      return this.state.selectedDocType.specific_to === "Product Specific";
    } else {
      return false;
    }
  }

  handleProductSelect(productName, selectedProduct) {
    this.setState({
      showProductListPopup: false,
      productName: productName,
      selectedProduct: selectedProduct,
    });
  }

  handleLanguageChange(e) {
    let language = e.value;
    let quickStartGuide = this.state.quickStartGuideList.find(
      (item) => item.Language === language
    );
    this.setState({
      selectedLanguage: quickStartGuide.Language,
      selectedLink: quickStartGuide.Link,
      existingLink: quickStartGuide.Link,
    });
  }

  handleQuickStartLinkChange(e) {
    this.setState({
      selectedLink: e.target.value,
    });
  }

  handleDocTypeChange(e) {
    let selectedItem = this.state.docType.find(
      (item) => item.document_type === e.value
    );
    this.setState((prevState) => ({
      selectedDocType: selectedItem,
      selectedProduct: "",
      productName: "",
      files: undefined,
      allowResourceButtons: !documentNames.includes(selectedItem.document_type),
      resourceType: documentNames.includes(selectedItem.document_type)
        ? 1
        : prevState.resourceType,
    }));
    document.getElementById("manageContentFile").value = "";
  }

  handleFileChange(e) {
    let files = e.target.files;
    this.setState({
      files: files,
    });
  }

  handleDeleteAttachment() {
    this.getAttachments();
  }

  handleProductNameChange(e) {
    this.setState({
      productName: e.target.value,
    });
  }

  generateLanguageOptions() {
    return this.state.quickStartGuideList.map((item) => ({
      value: item.Language,
      label: item.Language,
    }));
  }

  generateDocTypeOptions() {
    return this.state.docType.map((type) => ({
      value: type.document_type,
      label: type.document_type,
    }));
  }

  ResourceTypeChanged(e) {
    let resValue = e.target.value;
    let resType = resourceTypeArray[resValue];
    this.setState({
      resourceType: resType,
    });
  }

  search() {
    if (this.state.productName) {
      this.setState((prevState) => ({
        searchString: prevState.productName,
        showProductListPopup: true,
      }));
      this.trackSearchButtonClick(this.state.productName);
    } else {
      this.props.addNotification(ErrorMessages.Content_ProductName, "error");
    }
  }

  viewAttachments() {
    this.trackUploadDocumentViewAttachmentButtonClick();

    if (
      this.isSelectedDoctypeProductSpecific() &&
      this.state.productName === ""
    ) {
      this.props.addNotification(ErrorMessages.Content_ProductName, "error");
    } else {
      this.getAttachments();
    }
  }

  handleOnAttachmentsListPopupClose() {
    this.setState({
      showAttachmentListPopup: false,
    });
  }

  handleOnProductListPopupClose() {
    this.setState({
      showProductListPopup: false,
    });
  }

  handleKeyPress(target) {
    if (target.keyCode === 13) {
      target.preventDefault();
      this.search();
    }
  }

  disableLinkUpdate() {
    let isLinkDisabled = false;
    if (
      this.state.selectedLink === this.state.existingLink ||
      this.state.selectedLink.length === 0
    ) {
      isLinkDisabled = true;
    }
    return isLinkDisabled;
  }

  linkUpdate() {
    let data = {
      Language: this.state.selectedLanguage,
      Link: this.state.selectedLink,
    };

    ContentDataHandler.updateQuickStartGuide(data).then((response) => {
      if (response.success) {
        this.trackQuickStartGuideUpdateButtonClick(data);
        this.props.addNotification(
          DisplayMessages.Content_QuickStartGuideLinkUpdated,
          "info"
        );
        this.getQuickStartGuide();
        this.setState((prevState) => ({
          existingLink: prevState.selectedLink,
        }));
      } else {
        this.props.addNotification(
          DisplayMessages.Content_QuickStartGuideLinkFailed,
          "error"
        );
      }
    });
  }

  upload() {
    this.validateUpload()
      .then((form) => {
        this.trackUploadDocumentUploadButtonClick();
        ContentDataHandler.uploadNewDocument(form)
          .then((response) => {
            if (!response.success) {
              this.props.addNotification(response.message, "error");
            } else {
              if (
                this.state.selectedDocType.document_type ===
                "Compare Wizard Sheet"
              ) {
                this.props.addNotification(
                  DisplayMessages.Content_CompareWizardMsg,
                  "info"
                );
              } else {
                this.props.addNotification(
                  DisplayMessages.Content_DocUploadMsg,
                  "info"
                );
              }
              this.cancel();
            }
          })
          .catch((error) => {
            this.props.addNotification(ErrorMessages.Content_Upload, "error");
          });
      })
      .catch((error) => {
        this.props.addNotification(ErrorMessages.Content_Validate, "error");
      });
  }

  validateUpload() {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (this.state.selectedDocType) {
        form.append("DocumentTypeId", this.state.selectedDocType.id);
        form.append("DocumentType", this.state.selectedDocType.document_type);
        form.append("ResourceType", this.state.resourceType);
      }
      if (this.state.files) {
        for (let element of this.state.files) {
          let fileSize = this.state.files[0].size;
          if (fileSize > 26214400) {
            reject(new Error("Larger file size"));
          }
          form.append("UploadedFile", element);
        }
      }
      if (this.isSelectedDoctypeProductSpecific()) {
        if (this.state.productName === "" || this.state.productName == null) {
          reject(new Error("Enter a product name"));
        } else {
          ContentDataHandler.fetchProductsByProductName(this.state.productName)
            .then((response) => {
              if (
                response.data.products.find(
                  (item) => item.title === this.state.productName
                )
              ) {
                form.append("ProductName", this.state.productName);
                resolve(form);
              } else {
                reject(new Error("Invalid Product"));
              }
            })
            .catch((error) => {
              reject(error);
              this.props.addNotification(
                ErrorMessages.Content_ProductByName,
                "error"
              );
            });
        }
      } else {
        form.append("ProductName", "");
        resolve(form);
      }
    });
  }

  cancel() {
    this.setState((prevState) => ({
      productName: "",
      selectedProduct: undefined,
      selectedDocType: prevState.selectedDocType,
      files: "",
    }));
    document.getElementById("manageContentFile").value = "";
  }

  // Mixpanel Tracking //

  trackSubtabNavigation(id) {
    let subtabs = [
      "_",
      "Manage Content",
      "View Content Usage",
      "Manage Home Page",
      "Product Configurator Data",
      "Distributor Management",
      "User Management",
    ];

    mixpanel.track("Content Navigation", {
      Action: "Subtab Click",
      Effect: "Navigated to a different subtab",
      "Selected Tab": subtabs[id],
    });

    mixpanel.track_pageview({
      Page: `${subtabs[id]} Subtab`,
    });
  }

  trackSearchButtonClick(searchString) {
    mixpanel.track("Search Case Study Product", {
      Action: "Button Click",
      Effect: "Filter products based on search string",
      "Search String": searchString,
    });
  }

  trackUploadDocumentUploadButtonClick() {
    mixpanel.track("Content Document Update", {
      Action: "Button Click",
      Effect: "Selected document is updated to the web application",
      "Resource Type": this.state.resourceType,
      "Document Type": this.state.selectedDocType.document_type,
    });
  }

  trackUploadDocumentCancelButtonClick() {
    mixpanel.track("Cancel Document Upload", {
      Action: "Button Click",
      Effect: "Selected document is cleared",
    });
  }

  trackUploadDocumentViewAttachmentButtonClick() {
    mixpanel.track("View Attachments", {
      Action: "Button Click",
      Effect: "Display list of all attachments",
    });
  }

  trackQuickStartGuideUpdateButtonClick(data) {
    mixpanel.track("Quick Start Guide", {
      Action: "Button Click",
      Effect: "Quick start guide will be updated",
      Link: data.Link,
      Language: data.Language,
    });
  }

  trackSalesAgencyContactUpdateButtonClick() {
    mixpanel.track("Sales Agency Contact", {
      Action: "Button Click",
      Effect: "Sales agency contact will be updated",
      "Contact Info": this.state.contactInfo,
    });
  }
}
export default Content;
