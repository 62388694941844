import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import mixpanel from "mixpanel-browser";

/**
 * Component represents the confirmation popup
 */
class ConfirmationPopUp extends Component {
  constructor(props) {
    super(props);
    this.handleClearClose = this.handleClearClose.bind(this);
    this.clearTableData = this.clearTableData.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
                textAlign: "center",
              }}
              className="popup-blue-text"
            >
              {this.props.title}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <ControlLabel>{this.props.text}</ControlLabel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                bsStyle="primary"
                onClick={this.clearTableData}
                className="button-confirm-blue"
              >
                Yes
              </Button>
              <Button
                bsStyle="primary"
                onClick={this.handleClearClose}
                className="button-white"
                style={{ width: "150px" }}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleOnClose() {
    this.props.handleClearClose();
  }

  handleClearClose() {
    this.trackCartClearCloseClick();
    this.props.handleClearClose();
  }

  clearTableData() {
    this.trackCartClearSuccessClick();
    this.props.clearTableData();
  }

  // Mixpanel Tracking //

  trackCartClearSuccessClick() {
    mixpanel.track("Clear Cart", {
      Action: "Button Click",
      Effect: "Existing cart is cleared",
      "Cart Status": "Empty",
    });
  }

  trackCartClearCloseClick() {
    mixpanel.track("Clear Cart", {
      Action: "Button Click",
      Effect: "Existing cart is retained",
      "Cart Status": "Retained",
    });
  }
}

export default ConfirmationPopUp;
