import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import ReactDragList from "react-drag-list";
import "react-drag-list/assets/index.css";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./changeDisplayOrderPopup.css";
import {
  ErrorMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";
import fixedLink from "../Assets/fixedAssets.json";
import ManageHomePageDataHandler from "./ManageHomePageDataHandler";

/**
 * Component represents the Change Display Order Popup inside Manage Home Page subtab
 */
class ChangeDisplayOrderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.saveDisplayOrder = this.saveDisplayOrder.bind(this);
    this.generateDraggableAttachmentList =
      this.generateDraggableAttachmentList.bind(this);
  }

  _handleUpdate = (evt, updated) => {};

  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
    });
  }

  handleOnClose() {
    this.trackCancelButtonClick();
    this.props.handleOnClose();
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              {this.props.modelTitle} Display Order
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row className="help-note">
              Drag and drop the items to the required position.
            </Row>
            <Row className="container order-list-container">
              {this.generateDraggableAttachmentList()}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              onClick={this.saveDisplayOrder}
              className={" button-blue"}
            >
              Save
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.handleOnClose}
              className={" button-blue"}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  generateDraggableAttachmentList() {
    if (this.props.attachments.length <= 0) {
      return (
        <tr>
          <h4>No attachments to display!</h4>
        </tr>
      );
    } else {
      return (
        <ReactDragList
          dataSource={this.props.attachments}
          rowKey="title"
          row={(record) => this.getAttachmentItem(record)}
          handles={false}
          className="simple-drag"
          rowClassName="simple-drag-row"
          onUpdate={this._handleUpdate}
        />
      );
    }
  }

  getAttachmentItem(item) {
    let imageSource =
      this.props.modelTitle === "Banner"
        ? item.filePath
        : this.getDocumentTypeIcon(item.filePath);
    return (
      <Row key={item.id} className="drag-item">
        <Col md={4}>
          <img className="drag-item-image" src={imageSource} alt="banner" />
        </Col>
        <Col md={8} style={{ textAlign: "center" }}>
          <div className="drag-item-title">{item.title}</div>
          <span className="drag-item-content">
            Filename: {item.original_file_name}
          </span>
        </Col>
      </Row>
    );
  }

  saveDisplayOrder() {
    ManageHomePageDataHandler.saveDisplayOrder(
      this.props.modelTitle,
      this.props.attachments
    )
      .then((response) => {
        this.trackSaveDisplayOrderButtonClick();
        this.addNotification(response.message, "info");
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.ManageHomePage_SaveDisplayOrder,
          "error"
        );
      });
    this.props.handleOnClose();
  }

  getDocumentTypeIcon(filePath) {
    let fileExtension = filePath.slice(
      filePath.lastIndexOf(".") + 1,
      filePath.length
    );
    switch (fileExtension) {
      case "pdf":
        return fetchResourceData(fixedLink.pdfIcon);
      case "doc":
      case "docx":
        return fetchResourceData(fixedLink.wordIcon);
      case "xls":
      case "xlsx":
        return fetchResourceData(fixedLink.excelIcon);
      case "ppt":
      case "pptx":
        return fetchResourceData(fixedLink.powerpointIcon);
      case "png":
      case "jpg":
        return fetchResourceData(fixedLink.imageIcon);
      default:
        return fetchResourceData(fixedLink.textIcon);
    }
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  // Mixpanel Tracking //

  trackSaveDisplayOrderButtonClick() {
    mixpanel.track("Save Display Order", {
      Action: "Button Click",
      Effect: "Reordered document order is saved",
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Cancel Display Order", {
      Action: "Button Click",
      Effect: "Document order is retained",
    });
  }
}

export default ChangeDisplayOrderPopup;
