import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import { Row } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import "./confirmationPopup.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

/**
 * Component represents the 'Permanently Delete' items popup
 */
class DeleteConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemType: this.props.itemType,
      item: this.props.item,
    };

    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.state.item !== newProps.item ||
      this.state.itemType !== newProps.itemType
    ) {
      this.setState({
        itemType: newProps.itemType,
        item: newProps.item,
      });
    }
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          styles={customStyles}
        >
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                textAlign: "center",
              }}
              className="popup-red-text"
            >
              Delete {" " + this.state.itemType}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <Row>{this.Message()}</Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                bsStyle="primary"
                onClick={this.handleConfirmation}
                className="button-red"
                style={{ textAlign: "center" }}
              >
                Delete
              </Button>
              <Button
                bsStyle="primary"
                onClick={this.handleCancel}
                className="button-white"
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  Message() {
    return (
      <div className="text-center">
        <span>
          Are you sure you want to delete the {" " + this.state.itemType}:
        </span>
        <br />
        <br />
        <strong style={{ fontSize: "20px", wordBreak: "break-word" }}>
          {this.state.item}
        </strong>
        <br />
        <br />
        <span>This action cannot be undone.</span>
      </div>
    );
  }

  handleConfirmation() {
    this.props.handleConfirmation();
  }

  handleClose() {
    this.props.handleCancel();
  }

  handleCancel() {
    this.trackDeleteCancellationClick();
    this.handleClose();
  }

  // Mixpanel Tracking //

  trackDeleteCancellationClick() {
    mixpanel.track("Delete Cancellation", {
      Action: "Button Click",
      Effect: "Item deletion is cancelled",
      "Item Type": this.state.itemType,
      Item: this.state.item,
    });
  }
}

export default DeleteConfirmationPopup;
