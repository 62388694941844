import Api from "../Utils/api.js";
import Plugin from "../Utils/plugin.js";
/**
 * API Calls to handle:
 * Fetch products by product name
 * Get document types
 * Get attachments
 * Add to usage trends
 * Get Quick Start Guide
 * Set Quick Start Guide
 * Upload file to content management
 * Delete file from content management
 * Change content management description
 * Update Sales Agency Contact
 */
const ContentDataHandler = (function () {
  return {
    fetchProductsByProductName: function (searchFilter) {
      return new Promise((resolve, reject) => {
        let route = `searchproducts/${searchFilter}`;
        Plugin.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDocTypes: function (data) {
      return new Promise((resolve, reject) => {
        let route = `ContentDisplay/DocumentTypes?type=${data}`;
        Api.get(route)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => reject(error));
      });
    },

    getAttachments: function (
      productName,
      selectedDocType,
      isSelectedDoctypeProductSpecific,
      resourceAccess
    ) {
      let route = "ContentDisplay/productName?";
      route +=
        productName && isSelectedDoctypeProductSpecific
          ? `productName=${productName}`
          : "";
      route += selectedDocType
        ? `&documentType=${selectedDocType.document_type}`
        : ``;
      route += `&resourceType=${resourceAccess}`;
      return new Promise((resolve, reject) => {
        Api.get(route)
          .then((response) => {
            let attachments = response.data.data.map((item) => {
              item.filePath = Api.getBaseURL() + "/" + item.filePath;
              return item;
            });
            resolve(attachments);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addToUsageTrends: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`ContentDisplay/AddToUsageTrends`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getQuickStartGuide: function () {
      return new Promise((resolve, reject) => {
        Api.get(`ContentDisplay/QuickStartGuide`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => reject(error));
      });
    },
    updateQuickStartGuide: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`ContentDisplay/UpdateQuickStartGuide`, data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadNewDocument: function (data) {
      return new Promise((resolve, reject) => {
        Api.post(`ContentManagement/UploadNewDocument`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteExistingDocument: function (id) {
      return new Promise((resolve, reject) => {
        Api.delete(`ContentManagement/DeleteExistingDocument?id=${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateDescriptionText: function (data) {
      return new Promise((resolve, reject) => {
        Api.put(`ContentManagement/UpdateDescriptionText`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateContactInfo: function (data) {
      return new Promise((resolve, reject) => {
        Api.put(`ContentManagement/UpdateContactInfo`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
})();

export default ContentDataHandler;
