import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import BootstrapTable from "react-bootstrap-table-next";
import Row from "react-bootstrap/lib/Row";
import Button from "react-bootstrap/lib/Button";
import SortAsc from "react-icons/lib/fa/sort-asc";
import SortDesc from "react-icons/lib/fa/sort-desc";
import Sort from "react-icons/lib/fa/sort";
import NotificationSystem from "react-notification-system";
import paginationFactory from "react-bootstrap-table2-paginator";
import mixpanel from "mixpanel-browser";

import "./orderTrack.css";
import OrderTrackHandler from "./OrderTrackHandler.js";
import OkPopUp from "../OkPopUp/OkPopUp.js";
import ShipmentPopUp from "./ShipmentPopUp.js";
import Disclaimer from "../Disclaimer/Disclaimer";
import fixedLink from "../Assets/fixedAssets.json";
import {
  ErrorMessages,
  DisplayMessages,
  fetchResourceData,
} from "../Utils/FetchConfigurableData";

function Sorted(order, column) {
  if (order === undefined)
    return (
      <span>
        <Sort />
      </span>
    );
  if (order === "asc")
    return (
      <span>
        <SortAsc />
      </span>
    );
  return (
    <span>
      <SortDesc />
    </span>
  );
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Orders
  </span>
);

let link;

/**
 * Component represents the tracking table in Track My Order page
 */
class TrackingTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      filteredTableResult: [],
      filteredAllRowResults: [],
      filterOption: "",
      isTrackButtonClicked: false,
      text: "",
      packageDetails: [],
      filterlist: [],
      expanded: [],
      start: true,
      samplepack: false,
      allTableResults: [],
      searchCriteria: "",
      linkNameClicked: "",
      shipmentPopupClicked: false,
      role: "",
      last_updated_time: "",
      urlDict: [],
    };
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.getCourierList = this.getCourierList.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getCourierList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filteredTableResult: nextProps.filteredTableResult,
      filteredAllRowResults: nextProps.filteredAllRowResults,
      filterOption: nextProps.filterOption,
      allTableResults: nextProps.filteredTableResult,
      start: true,
      searchCriteria: "",
      last_updated_time: nextProps.last_updated_time,
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    return (
      <div className="content-usage-result-container ">
        <Row>
          <h3>Order Details</h3>
        </Row>
        <div className="tableheaders">
          <Disclaimer
            last_updated={this.state.last_updated_time}
            type="TrackMyOrder"
          />
          <div>
            <div className="inline-right">
              <h5>Filtered By : </h5>
              <h6>{this.state.filterOption}</h6>
            </div>
          </div>
        </div>

        <br />

        {this.getDisplayContent()}
        <OkPopUp
          show={this.state.isTrackButtonClicked}
          title={"Tracking Portal Unavailable"}
          text={this.state.text}
          handleOnClose={() => {
            this.trackOKPortalCloseClick();
            this.handleOnClose();
          }}
          handleSubmit={this.handleSubmit}
        />
        <ShipmentPopUp
          show={this.state.shipmentPopupClicked}
          title={this.state.linkNameClicked}
          packageDetails={this.state.packageDetails}
          samplepack={this.state.samplepack}
          handleOnClose={() => {
            this.handleOnClose();
          }}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  getCourierList() {
    OrderTrackHandler.getCourierList()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            start: true,
            searchCriteria: "",
            urlDict: response.data.map((item) => {
              return {
                name: item.name,
                link: item.link,
              };
            }),
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderTrack_CourierList, "error");
      });
  }

  getDisplayContent() {
    let constExpandRow = {
      renderer: (row, rowIndex) => this.getExpandedRow(row, rowIndex),
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ _ }) => {
        return null;
      },
      expandColumnRenderer: ({ expanded }) => {
        let url = fetchResourceData(fixedLink.nextRightIcon);
        if (expanded) {
          url = fetchResourceData(fixedLink.nextDownIcon);
        }
        return (
          <img
            height={20}
            width={20}
            src={url}
            alt="next"
            style={{ display: "inline", margin: "5px" }}
          />
        );
      },
      expandColumnPosition: "right",
    };
    if (this.state.start) {
      constExpandRow["expanded"] = [];
    }

    return (
      <div className="order-track-expand-row">
        <BootstrapTable
          classes="order-tracking-table"
          keyField="SalesOrder"
          data={this.state.filteredTableResult}
          columns={this.getDisplayContentColumns()}
          pagination={paginationFactory(this.paginationOptionsBuilder())}
          expandRow={constExpandRow}
        />
      </div>
    );
  }

  getExpandedRow(row) {
    let packagenumber = 0;
    let list = this.state.filteredTableResult.find(
      (item) => item.SalesOrder === row.SalesOrder
    );
    let filteredPackageList = this.state.filteredAllRowResults.filter(
      (item) =>
        item.SalesOrder === row.SalesOrder && item.DispatchNote.length > 0
    );
    return (
      <div className="order-track-expand-row">
        <div>
          <Row>
            <Col md={3} sm={5} xs={5}>
              <Row>
                <div className="inline">Shipped To Address</div>
              </Row>
            </Col>
            <Col md={1} sm={1} xs={1}>
              <Row>
                <div className="inline-right">:</div>
              </Row>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <Row>
                <div>{list.ShipToCustomer}</div>
              </Row>
            </Col>
            <Col md={2} sm={12} xs={12}>
              <Row>
                <div
                  className="inline-package-link"
                  onClick={() =>
                    this.setTotalOrderDetails(
                      list.SalesOrder,
                      parseInt(row.OrderTotal, 10)
                    )
                  }
                >
                  <u>Total Order Details</u>
                </div>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            {filteredPackageList && filteredPackageList.length > 0 ? (
              <div>
                <Col md={3} sm={5} xs={5}>
                  <Row style={{ marginBottom: "3%" }}>
                    <div className="inline">
                      <u>Tracking Number</u>
                    </div>
                  </Row>
                </Col>
              </div>
            ) : null}
          </Row>
          <Row>
            <br />
            <br />
            {filteredPackageList.map((item) => {
              packagenumber = packagenumber + 1;
              let packagename = "Package " + packagenumber + " ";
              return (
                <Row key={row}>
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      <div className="itemsintable">
                        <div
                          className="inline-package-link"
                          onClick={() =>
                            this.setPackagePopup(
                              item,
                              packagename,
                              parseInt(row.OrderTotal, 10)
                            )
                          }
                        >
                          <u>{packagename}</u>:
                        </div>
                        {item.Waybill && item.Waybill !== " " ? (
                          <div
                            className="inline"
                            style={{ paddingLeft: "10px", Width: "15%" }}
                          >
                            {item.Waybill}{" "}
                          </div>
                        ) : null}
                        {item.Courier && item.Courier !== " " ? (
                          <div
                            className="inline"
                            style={{ paddingLeft: "10px" }}
                          >
                            Courier:&nbsp;&nbsp;{item.Courier}
                          </div>
                        ) : (
                          <div className="courier">
                            Package ready for courier
                          </div>
                        )}
                        <div
                          className="inline"
                          style={{ minWidth: "22%", paddingLeft: "10px" }}
                        >
                          Ship Date:&nbsp;&nbsp;{item.DeliveryDate}
                        </div>
                        <span />
                        {item.Waybill !== " " && item.Waybill ? (
                          <div>
                            <Button
                              className="track-button"
                              onClick={() =>
                                this.trackButtonClicked(
                                  item.Courier,
                                  item.Waybill,
                                  row.CustomerName
                                )
                              }
                            >
                              Track
                            </Button>
                          </div>
                        ) : (
                          <span />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <br />
                </Row>
              );
            })}
          </Row>
          {list.BackOrderQty > 0 ? (
            <div>
              <Row>
                <Col md={6} sm={5} xs={5}>
                  <div
                    className="inline-package-link"
                    style={{ paddingRight: "10px" }}
                    onClick={() =>
                      this.getBackOrderDetails(
                        list.SalesOrder,
                        parseInt(row.OrderTotal, 10)
                      )
                    }
                  >
                    <u>Backorder Details</u>
                  </div>
                </Col>
              </Row>
              <br />
              <br />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  paginationOptionsBuilder() {
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.trackPagination(page);
        onPageChange(page);
      };

      return (
        <li className="page-item">
          {/* eslint-disable-next-line */}
          <a href="#" key={page} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      pageButtonRenderer,
      paginationTotalRenderer: customTotal,
      showTotal: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
      ],
    };

    return options;
  }

  setTotalOrderDetails(salesOrder, sample) {
    this.trackTotalOrderDetailsClick(salesOrder);

    OrderTrackHandler.getTotalOrderDetails(salesOrder)
      .then((response) => {
        this.setState({
          packageDetails: response.data,
          linkNameClicked: "Total order ",
          shipmentPopupClicked: true,
          start: false,
          samplepack: sample === 0,
        });
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderTrack_TotalOrderDetails,
          "error"
        );
      });
  }

  getBackOrderDetails(salesOrder, sample) {
    this.trackBackorderDetailsClick(salesOrder);

    OrderTrackHandler.getBackOrderDetails(salesOrder)
      .then((response) => {
        this.setState({
          packageDetails: response.data,
          linkNameClicked: "Backorder ",
          shipmentPopupClicked: true,
          start: false,
          samplepack: sample === 0,
        });
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderTrack_BackOrderDetails,
          "error"
        );
      });
  }

  setPackagePopup(object, packagename, sample) {
    let totalValue = 0;
    let packageDetails = [];
    let listOfItems = [];
    let salesOrder = object.SalesOrder;

    this.trackShipmentPackageClick(salesOrder, packagename);
    OrderTrackHandler.getPackageDetails(salesOrder, object.DispatchNote)
      .then((response) => {
        listOfItems = response.data;
        totalValue = 0;
        response.data.map((item, i) => {
          totalValue = totalValue + item.UnitPrice * item.MQtyToDispatch;
          return null;
        });
        packageDetails = {
          details: listOfItems,
          packagename: packagename,
          salesOrder: salesOrder,
          dispatchValue: object.DispatchValue,
          totalvalue: totalValue,
        };
        this.setState({
          packageDetails: packageDetails,
          linkNameClicked: "Shipment ",
          shipmentPopupClicked: true,
          start: false,
          samplepack: sample === 0,
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderTrack_PackageDetails, "error");
      });
  }

  handleOnClose() {
    this.setState({
      isTrackButtonClicked: false,
      shipmentPopupClicked: false,
      samplepack: false,
    });
  }

  handleSubmit() {
    if (link !== "") {
      window.open(link, "_blank");
    }
    this.handleOnClose();
  }

  copyStringToClipboard(str) {
    let el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  trackButtonClicked(CourierType, trackingNumber, customerName) {
    link = "";
    let text1 = "";
    OrderTrackHandler.postTrackClicks(customerName)
      .then((response) => {
        CourierType = CourierType.replace(/ +/g, "").toUpperCase();
        let courier = this.state.urlDict.filter(
          (item) => item.name === CourierType
        );
        if (courier.length > 0 && courier[0].link !== "") {
          this.trackCourierNavigationButtonClick(
            CourierType,
            trackingNumber,
            customerName
          );

          link = courier[0].link;
          this.copyStringToClipboard(trackingNumber);
          this.addNotification(
            CourierType.toUpperCase() + " Tracking Number copied to clipboard",
            "info"
          );
          let millisecondsToWait = 2000;
          setTimeout(function () {
            window.open(link, "_blank");
          }, millisecondsToWait);
          return;
        } else {
          text1 = DisplayMessages.OrderTrack_ShipmentText;
          this.trackCourierNavigationFailedClick(
            CourierType,
            trackingNumber,
            customerName,
            text1
          );
        }

        this.setState({
          isTrackButtonClicked: true,
          text: text1,
          start: false,
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderTrack_TrackClicks, "error");
      });
  }

  getDisplayContentColumns() {
    const columns = [
      {
        dataField: "OrderDate",
        text: "Order Date",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
          minWidth: "100px",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          minWidth: "100px",
          height: "auto",
        },
      },
      {
        dataField: "CustomerName",
        text: "Customer Name",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
      },
      {
        dataField: "Customer",
        text: "Customer ID",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
      },
      {
        dataField: "SalesOrder",
        text: "Order Number",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
      },
      {
        dataField: "OrderQty",
        text: "Order Quantity",
        sort: true,
        sortCaret: Sorted,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") {
            return a - b;
          }
          return b - a;
        },
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
      },
      {
        dataField: "BackOrderQty",
        text: "Backorder Quantity",
        sort: true,
        sortCaret: Sorted,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") {
            return a - b;
          }
          return b - a;
        },
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
        formatter: (cell, row) => {
          return <div>{parseInt(cell, 10)}</div>;
        },
      },
      {
        dataField: "ShipToCustomer",
        text: "Shipped To Address",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
      },
      {
        dataField: "CustomerPoNumber",
        text: "Customer PO Number",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          verticalAlign: "middle",
          whiteSpace: "unset",
          maxWidth: "120px",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          maxWidth: "120px",
          height: "auto",
        },
      },
      {
        dataField: "ShipStatus",
        text: "Shipping Status",
        sort: true,
        sortCaret: Sorted,
        style: {
          textAlign: "center",
          backgroundColor: "#D6D6D6",
          whiteSpace: "unset",
        },
        headerStyle: {
          backgroundColor: "#1B2D5B",
          color: "#fff",
          whiteSpace: "unset",
          width: "auto",
          height: "auto",
        },
        formatter: (cell, row) => {
          let url = "";
          switch (cell) {
            case "Shipped":
              url = fetchResourceData(fixedLink.shippedPackageIcon);
              break;
            case "In Progress":
              url = fetchResourceData(fixedLink.waitingForPackageIcon);
              break;
            default:
              url = fetchResourceData(fixedLink.waitingForPackageIcon);
          }
          return (
            <div>
              <img
                height={25}
                width={25}
                src={url}
                alt="status"
                style={{ display: "inline", margin: "5px" }}
              />
              <br />
              <span>{cell}</span>
            </div>
          );
        },
      },
    ];

    return columns;
  }

  // Mixpanel Tracking //

  trackOKPortalCloseClick() {
    mixpanel.track("Tracking Portal Unavailable", {
      Action: "Button Click",
      Effect: "Closes portal",
    });
  }

  trackTotalOrderDetailsClick(salesOrderNumber) {
    mixpanel.track("Total Order Details", {
      Action: "Link Click",
      Effect: "Popup with order details displayed",
      "Sales Order Number": salesOrderNumber,
    });
  }

  trackBackorderDetailsClick(salesOrderNumber) {
    mixpanel.track("Backorder Details", {
      Action: "Link Click",
      Effect: "Popup with backorder details displayed",
      "Sales Order Number": salesOrderNumber,
    });
  }

  trackShipmentPackageClick(salesOrderNumber, packageName) {
    mixpanel.track("Shipment Details", {
      Action: "Link Click",
      Effect: "Popup with selected shipment details displayed",
      "Sales Order Number": salesOrderNumber,
      "Package Name": packageName,
    });
  }

  trackCourierNavigationButtonClick(courierType, trackingNumber, customerName) {
    mixpanel.track("Track Courier", {
      Action: "Button Click",
      Effect: "Navigate user to shipment tracking page",
      Courier: courierType,
      "Tracking Number": trackingNumber,
      Customer: customerName,
    });
  }

  trackCourierNavigationFailedClick(
    courierType,
    trackingNumber,
    customerName,
    message
  ) {
    mixpanel.track("Track Courier", {
      Action: "Button Click",
      Effect: "Navigating user to shipment tracking page failed",
      Courier: courierType,
      "Tracking Number": trackingNumber,
      Customer: customerName,
      Message: message,
    });
  }

  trackPagination(pageNumber) {
    mixpanel.track("Order Tracking Table Pagination", {
      Action: "Pagination",
      Effect: `Navigate to page ${pageNumber}`,
      "Page Number": pageNumber,
    });
  }
}
export default TrackingTable;
