import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";

/**
 * Component represents the OK Popup
 */
class OkPopUp extends Component {
  constructor(props) {
    super(props);

    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
              }}
              className="popup-blue-text"
            >
              {this.props.title}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>{this.props.text}</ControlLabel>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="button-blue"
              onClick={this.handleSubmit}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleOnClose() {
    this.props.handleOnClose();
  }
  handleSubmit() {
    this.props.handleOnClose();
  }
}

export default OkPopUp;
