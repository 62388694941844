import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Table from "react-bootstrap/lib/Table";

import { DisplayMessages } from "../Utils/FetchConfigurableData";

/**
 * Component represents shipment popup
 */
class ShipmentPopUp extends Component {
  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleOnClose}>
          <Modal.Header>
            <h3
              style={{
                fontWeight: "bolder",
                paddingLeft: "15px",
                textTransform: "capitalize",
              }}
              className="popup-blue-text"
            >
              {this.props.title} Details{" "}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              {this.props.title === "Shipment " ? (
                <div>
                  <ControlLabel>
                    {this.props.packageDetails.packagename}of Sales Order :
                    &nbsp;{this.props.packageDetails.salesOrder}
                  </ControlLabel>
                  <br />

                  <br />
                </div>
              ) : null}
              <br />
              {this.props.title !== "" &&
              this.props.packageDetails.details.length > 0 ? (
                <Table id="shipment" style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>Stock Code</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      {this.props.title === "Backorder " ? (
                        <th>Estimated Ship Date</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.packageDetails.details.map((item, i) => {
                      return (
                        <tr key={item.MStockCode}>
                          <td>{item.MStockCode}</td>
                          <td>{item.MStockDes}</td>

                          <td>{parseInt(item.MQtyToDispatch, 10)}</td>

                          {this.props.title === "Backorder " ? (
                            <td>{item.PromiseDate}</td>
                          ) : null}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div>
                  {this.props.title === "Shipment " ? (
                    <ControlLabel>
                      {this.props.title}
                      {DisplayMessages.Shipment_NoShipment}
                    </ControlLabel>
                  ) : (
                    <ControlLabel>
                      {this.props.title}
                      {DisplayMessages.Shipment_NoDetails}
                    </ControlLabel>
                  )}
                </div>
              )}
            </FormGroup>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
  handleOnClose() {
    this.props.handleOnClose();
  }
}

export default ShipmentPopUp;
